import React, { useState, useEffect } from "react";
import OpsBreadcrumb from "../../../components/NewBreadcrumbs";
import { useLocation } from "react-router-dom/cjs/react-router-dom";
import PageHeader from "../../../components/PageHeader";
import { getVehiclesDiscovery } from "../vehicleDiscoveryServices";
import DepotSessions from "./table";
import ComponentLoader from "../../../components/ComponentLoader";
import "../vehicleDiscovery.scss";
import { Grid, Tooltip, Divider } from "@mui/material";
import InfoIcon from "@mui/icons-material/Info";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelIcon from "@mui/icons-material/Cancel";
import ContentCard from "../../../components/ContentCard";
import MiniChart from "./mini-chart";
import ToastMessage from "../../../components/ToastMessage";

const AllBreadcrumbsLinks = [
  {
    link: "/vehicles",
    title: "Vehicle Discovery",
  },
];

export default function DepotDetailsPage() {
  const search = useLocation().search;
  const searchParams = new URLSearchParams(search);
  const depotId = searchParams.get("depotId");
  const depotName = searchParams.get("depotName");
  const fromDate = searchParams.get("fromDate");
  const toDate = searchParams.get("toDate");

  const [isLoading, setLoading] = React.useState(false);
  const [toast, setToast] = React.useState({
    isOpen: false,
    message: "",
    type: "",
  });
  const [vehicleDiscoveryData, setVehicleDiscoveryData] = useState([]);
  const [vehicleDiscoveryMetrics, setVehicleDiscoveryMetrics] = useState({
    depotSessionMetrics: [],
    makeModelSessionMetrics: [],
  });

  useEffect(() => {
    apiCall();
  }, []);
  const apiCall = async (
    payload = {
      depotId: [depotId],
      fromDate: fromDate,
      toDate: toDate,
    }
  ) => {
    setLoading(true);
    const res = await getVehiclesDiscovery(payload);
    if (res.statusCode === 200) {
      const { ["allSessionData"]: allSessionData, ...metrics } = res.data;
      setVehicleDiscoveryData(allSessionData);
      setVehicleDiscoveryMetrics(metrics);
    } else {
      setToast({
        isOpen: true,
        message: "Internal Service Failure",
        type: "error",
      });
    }
    setLoading(false);
  };

  return (
    <>
      <OpsBreadcrumb
        AllBreadcrumbsLinks={AllBreadcrumbsLinks}
        title={depotName}
      />
      <PageHeader title={depotName} />
      <ContentCard>
        <Grid container alignItems={"center"} spacing={1} m={0}>
          <ComponentLoader isLoading={isLoading}>
            <Grid item xs>
              <div>
                <div className="title_for_chargersCount">
                  <p className="metrics_titles">
                    Average success %
                    <Tooltip title="Important information">
                      <span className="info">
                        <InfoIcon fontSize="small" className="infoIconColor" />
                      </span>
                    </Tooltip>
                  </p>
                  <div className="chartPosition">
                    <MiniChart chartType={"up"} />
                  </div>
                </div>
                <div className="fault_num_and_select">
                  <p id="chargers_count">
                    {
                      vehicleDiscoveryMetrics?.averageMetrics
                        ?.averageVehicleDiscoveryPercentage
                    }
                    %
                  </p>
                </div>
              </div>
            </Grid>
            <Divider orientation="vertical" variant="middle" flexItem />
            <Grid item xs>
              <div>
                <div className="title_for_chargersCount">
                  <p className="metrics_titles">
                    Average discovery attempts
                    <Tooltip title="Important information">
                      <span className="info">
                        <InfoIcon fontSize="small" className="infoIconColor" />
                      </span>
                    </Tooltip>
                  </p>
                  <div className="chartPosition">
                    <MiniChart chartType={"down"} />
                  </div>
                </div>
                <div className="fault_num_and_select">
                  <p id="chargers_count">
                    {
                      vehicleDiscoveryMetrics?.averageMetrics
                        ?.averageDiscoveryAttempts
                    }
                  </p>
                </div>
              </div>
            </Grid>
            <Divider orientation="vertical" variant="middle" flexItem />
            <Grid item xs>
              <div>
                <div className="title_for_chargersCount">
                  <p className="metrics_titles">
                    Average session duration
                    <Tooltip title="Important information">
                      <span className="info">
                        <InfoIcon fontSize="small" className="infoIconColor" />
                      </span>
                    </Tooltip>
                  </p>
                </div>
                <div className="fault_num_and_select">
                  <p id="chargers_count">
                    {
                      vehicleDiscoveryMetrics?.averageMetrics
                        ?.averageSessionDuration
                    }
                  </p>
                  <p className="psub">seconds</p>
                </div>
              </div>
            </Grid>
          </ComponentLoader>
        </Grid>
      </ContentCard>
      <ContentCard>
        <Grid container mb={1}>
          <Grid item xs>
            <div className="title_for_chargersCount">
              <p className="metrics_titles">
                Debugging checklist
                <Tooltip title="Important information">
                  <span className="info">
                    <InfoIcon fontSize="small" className="infoIconColor" />
                  </span>
                </Tooltip>
              </p>
            </div>
            <div className="fault_num_and_select">
              <p id="chargers_count">2</p>
              <p className="psub">of 4 completed</p>
            </div>
          </Grid>
        </Grid>
        <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
          <Grid item xs={6}>
            <div className="title_for_checkList">
              <p className="metrics_titles">
                <Tooltip title="Important information">
                  <span className="success">
                    <CheckCircleIcon
                      fontSize="small"
                      className="successIconColor"
                    />
                  </span>
                </Tooltip>
                Checklist item 1
              </p>
            </div>
            <p className="metrics_description">
              A short description on how to debug this item.
            </p>
          </Grid>
          <Divider
            orientation="vertical"
            flexItem
            className="dividerVertical"
          />
          <Grid item xs={6}>
            <div className="title_for_checkList">
              <p className="metrics_titles">
                <Tooltip title="Important information">
                  <span className="error">
                    <CancelIcon fontSize="small" className="errorIconColor" />
                  </span>
                </Tooltip>
                Checklist item 2
              </p>
            </div>
            <p className="metrics_description">
              A short description on how to debug this item.
            </p>
          </Grid>
          <Divider variant="middle" className="dividerHorizontal" />
          <Grid item xs={6}>
            <div className="title_for_checkList">
              <p className="metrics_titles">
                <Tooltip title="Important information">
                  <span className="error">
                    <CancelIcon fontSize="small" className="errorIconColor" />
                  </span>
                </Tooltip>
                Checklist item 2
              </p>
            </div>
            <p className="metrics_description">
              A short description on how to debug this item.
            </p>
          </Grid>
          <Divider
            orientation="vertical"
            flexItem
            className="dividerVertical"
          />
          <Grid item xs={6}>
            <div className="title_for_checkList">
              <p className="metrics_titles">
                <Tooltip title="Important information">
                  <span className="success">
                    <CheckCircleIcon
                      fontSize="small"
                      className="successIconColor"
                    />
                  </span>
                </Tooltip>
                Checklist item 1
              </p>
            </div>
            <p className="metrics_description">
              A short description on how to debug this item.
            </p>
          </Grid>
        </Grid>
      </ContentCard>
      <DepotSessions
        isLoading={isLoading}
        vehicleDiscoveryData={vehicleDiscoveryData}
      />
      <ToastMessage toast={toast} setToast={setToast} />
    </>
  );
}
