import React from "react";
import { Paper } from "@mui/material";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  pageContent: {
    padding: theme.spacing(2),
    minHeight: "calc(100vh - 75px)",
    background: "#EFF2F5",
    boxShadow: "none",
  },
}));

const NewPageMainContent = (props) => {
  const classes = useStyles();
  return <Paper className={classes.pageContent}>{props.children}</Paper>;
};

export default NewPageMainContent;
