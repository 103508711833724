import React from "react";
import FileCopyOutlinedIcon from "@mui/icons-material/FileCopyOutlined";
import { Tooltip } from "@mui/material";

const NewCopyToClipboard = (props) => {
  const { name, setToast, color, max_Height, ...other } = props;
  return (
    <div className="copy_to_clipboard_new">
      <p>{name}</p>

      <div className="copy-icon-new">
        <Tooltip title="Copy">
          <FileCopyOutlinedIcon
            onClick={() => {
              navigator.clipboard.writeText(name);
              setToast({
                isOpen: true,
                message: "Copied",
                type: "success",
              });
            }}
          />
        </Tooltip>
      </div>
    </div>
  );
};

export default NewCopyToClipboard;
