import React, { useEffect, useMemo, useState } from "react";
import Loader from "../../../components/Loader";
import Popup from "../../../components/Popup";
import useTable from "../../../components/UseTable";
import {
  apiProvider,
  indexedEndPoints,
} from "../../../services/api/utilities/provider";

const get12HourFormatTime = (hr) => {
  let result = "";
  if (hr === 0 || hr === 24) result = "12:00 AM";
  else if (hr < 12) result = hr + ":00 AM";
  else if (hr === 12) result = hr + ":00 PM";
  else result = hr - 12 + ":00 PM";
  return result;
};

const headers = [
  {
    id: "startDate",
    label: "Start Date",
    render: (row, col) => row[col]?.split(" ")?.slice(0, 4)?.join(" "),
    type: "date",
  },
  {
    id: "startTime",
    label: "Start Time",
    render: (row, col) => get12HourFormatTime(row[col]),
  },
  {
    id: "endDate",
    label: "End Date",
    render: (row, col) => row[col]?.split(" ")?.slice(0, 4)?.join(" "),
    type: "date",
  },
  {
    id: "endTime",
    label: "End Time",
    render: (row, col) => get12HourFormatTime(row[col]),
  },
  { id: "price", label: "Price ($ per kWh)" },
  { id: "demandCharges", label: "Demand Charges ($ per kW)" },
];

export default function ViewPlanRates({ openPopup, setOpenPopup }) {
  const { UTILITY_PLAN_RATES } = indexedEndPoints;
  const filterFn = useMemo(() => ({ fn: (items) => items }), []);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);

  useEffect(() => {
    const fetchRates = async () => {
      setLoading(true);
      const response = await apiProvider.getAll(
        `${UTILITY_PLAN_RATES}/${openPopup?.item}`
      );
      setLoading(false);
      setData(response?.data);
    };
    fetchRates();
  }, [openPopup?.item]);

  const {
    tableContainer: TableContainer,
    tableHead: TableHead,
    tableBody: TableBody,
  } = useTable(data, headers, filterFn, false, true);

  return (
    <>
      <Popup openPopup={openPopup} setOpenPopup={setOpenPopup} maxWidth="xl">
        <Loader isLoading={loading} />
        <TableContainer>
          <TableHead />
          {TableBody}
        </TableContainer>
      </Popup>
    </>
  );
}
