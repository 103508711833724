import "./MoreInfo.scss";
import { Divider, Grid } from "@mui/material";
import React from "react";
import "./billing.scss";

const DepotPlanDetails = (props) => {
  const { DrawerOC, setDrawerOC, values, setValues, packageDetails } = props;
  React.useEffect(() => {
    setRatePlanValues();
  }, []);
  const RatePlansList = (props) => {
    const { array } = props;
    if (array.length) {
      return array.map((element) => {
        return (
          <div key={element[0]} className="ratePlansList">
            <Grid container>
              <Grid item xs={11}>
                <Divider />
                <p className="ratePlan0">{element[0]}</p>
                <p className="ratePlan1">{element[1]}</p>
              </Grid>
              <Grid item xs={1}>
                <Divider />
              </Grid>
            </Grid>
          </div>
        );
      });
    } else {
      return (
        <>
          <Divider />
          <p className="nonBillable" key={"Nonbillable"}>
            Non billable
          </p>
        </>
      );
    }
  };

  const Grids = (props) => {
    const { title, value } = props;
    return (
      <Grid container className="depotPlanGrids">
        <Grid item xs={12}>
          <p className="depotPlanTitle">{title}</p>
        </Grid>
        <Grid item xs={12}>
          <RatePlansList array={values[value]} />
        </Grid>
      </Grid>
    );
  };
  const setRatePlanValues = () => {
    let constructRatePlans = {
      management: [],
      preventativeMaintenance: [],
      premiumWarranty: [],
    };
    let skuBillingTypePlanmap = {};
    packageDetails.forEach((ele) => {
      skuBillingTypePlanmap[ele.sku] = ele.package;
    });
    DrawerOC.data.ratePlan.forEach((ele) => {
      constructRatePlans[skuBillingTypePlanmap[ele.sku]].push([
        ele.sku,
        ele.ratePlanName,
      ]);
    });
    setValues(constructRatePlans);
  };

  return (
    <div className="depotPlanDetails">
      <Grids title={"MANAGEMENT"} value={"management"} />
      <Grids
        title={"PREVENTATIVE MAINTENANCE"}
        value={"preventativeMaintenance"}
      />
      <Grids title={"WARRANTY"} value={"premiumWarranty"} />
    </div>
  );
};

export default DepotPlanDetails;
