export const getBillablePorts_Global = (
  state = { allBillingPortsResponse: [], isBilliablePortsCountLoaded: false },
  action
) => {
  switch (action.type) {
    case "Billable_Ports_Count":
      return { ...action.payload };
    default:
      return state;
  }
};
export const getBillableCustomersCount_Global = (
  state = { allBillingCustomersResponse: [], isBillableCustomersLoaded: false },
  action
) => {
  switch (action.type) {
    case "Billable_Customers_Count":
      return { ...action.payload };
    default:
      return state;
  }
};
export const getBillingPackage_Global = (
  state = { allBillingPackageResponse: [], isBillingPackageLoaded: false },
  action
) => {
  switch (action.type) {
    case "Billing_Package":
      return { ...action.payload };
    default:
      return state;
  }
};
export const getBillingRatePlans_Global = (
  state = { allBillingRatePlansResponse: [], isBillingRatePlansLoaded: false },
  action
) => {
  switch (action.type) {
    case "Billing_RatePlans":
      return { ...action.payload };
    default:
      return state;
  }
};
export default {
  getBillablePorts_Global,
  getBillableCustomersCount_Global,
  getBillingPackage_Global,
  getBillingRatePlans_Global,
};
