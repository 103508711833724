import {
  Chip,
  Divider,
  Menu,
  MenuItem,
  Tooltip,
  Zoom,
  Badge,
  Grid,
} from "@mui/material";
import WarningIcon from "@mui/icons-material/Warning";
import React, { useState, useCallback, useEffect } from "react";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import Controls from "../../../../components/controls/Controls";
import "../../../billing/MoreInfo.scss";
import ToastMessage from "../../../../components/ToastMessage";
import { chargingModeEnum, firmwareStatusEnum } from "../../CHDEnum";
import { titleLabels } from "../../../../constants/TitleLabels";
import chargerImage from "../../../../assets/images/png/petrol.png";
import busImage from "../../../../assets/images/svg/bus.svg";
import publicCarImage from "../../../../assets/images/svg/public-car.svg";
import { format } from "date-fns";
import {
  apiProvider,
  indexedEndPoints,
} from "../../../../services/api/utilities/provider";
import "../../ChargerHealth.scss";
import AddIcon from "@mui/icons-material/Add";
import InfoIcon from "@mui/icons-material/Info";
import AddEditChargerPort from "./AddEditChargerPort";
import NewCommonFilterDrawer from "../../../../components/NewSideFIlterDrawer";
import AlertDialog from "../../../../components/NewAlertDialog";
import DeleteConfirmDialog from "../../../../components/NewDeleteConfirmDialog";
import { PrivilegedComponent } from "../../../../utils/PrivilegedComponent";

const colors = { Enabled: "#00765E", Capable: "#22539B", Incapable: "#18191E" };
const backgrounds = {
  Enabled: "#E5F1EF",
  Capable: "#E7EBF3",
  Incapable: "#F0F1F8",
};

const rfidColors = (rfidObj) => {
  if (rfidObj?.rfidConfigMode && rfidObj?.isConfigMatched == false) {
    return "#916D1F";
  } else {
    return colors[rfidObj?.status];
  }
};

const rfidBGColor = (rfidObj) => {
  if (rfidObj?.rfidConfigMode && rfidObj?.isConfigMatched == false) {
    return "#FEF8EB";
  } else {
    return backgrounds[rfidObj?.status];
  }
};

const ChargerdetailsSideBar = (props) => {
  const data = props.DraweChargerDetails.data;
  const { CHARGER_EXTRA_DETIALS, CHARGER_PROFILE, GET_DRIVERS } =
    indexedEndPoints;
  const [details, setDetails] = useState([]);
  const [lists, setLists] = useState([]);
  const [driver, setDriver] = useState();

  const constructChargePortDetails = () => {
    let list = [];
    if (data.chargeports) {
      data.chargeports.map((element) => {
        if (element.portId) {
          list.push({
            key: element.portId,
            label: element.portName,
            items: [
              {
                key: element.typeConnector,
                label: "Connector type",
                value: element.typeConnector,
              },
              {
                key: element.maxAmps,
                label: "Max Amps (A)",
                value: element.maxAmps,
              },
              {
                key: element.minimumChargingRate,
                label: "Min charging rate(kW)",
                value: element.minimumChargingRate,
              },
              {
                key: element.maxCapacity,
                label: "Max charging rate(kW)",
                value: element.maxCapacity,
              },
            ],
          });
        }
        setLists(list);
      });
    }
  };

  React.useEffect(constructChargePortDetails, []);

  useEffect(() => {
    const fetchDrivers = async () => {
      const response = await apiProvider.getAll(
        `${GET_DRIVERS}${data.depotId}`
      );
      const driverObj = response?.data?.find(
        (item) => item?._id === data?.driver
      );
      if (driverObj)
        setDriver(
          driverObj?.firstName?.trim() + " " + driverObj?.lastName?.trim()
        );
    };
    fetchDrivers();
  }, []);

  React.useEffect(() => {
    if (data.depotId && data.chargerId) {
      getOtherChargerDetails(data.depotId, data.chargerId);
    }
  }, [data.depotId, data.chargerId]);

  const getOtherChargerDetails = async (depotId, chargerId) => {
    const response = await apiProvider.getAll(
      `${CHARGER_EXTRA_DETIALS}/${depotId}/${chargerId}/details`
    );
    setDetails(response.data);
  };
  const [toast, setToast] = React.useState({
    isOpen: false,
    message: "",
    type: "",
  });

  const toggleDetailDrawer = (openClose, title, child, item) => {
    setDrawerOC({ isOpen: openClose, title: title, child: child, data: item });
  };

  const [DrawerOC, setDrawerOC] = React.useState({
    isOpen: false,
    title: "",
    data: {},
  });

  const [anchorEl, setAnchorEl] = React.useState([]);
  const open = Boolean(anchorEl);
  const handleClick = (event, index) => {
    const updatedAnchorElArr = [...anchorEl];
    updatedAnchorElArr[index] = event.currentTarget;
    setAnchorEl(updatedAnchorElArr);
  };
  const handleClose = (index) => {
    const updatedAnchorElArr = [...anchorEl];
    updatedAnchorElArr[index] = null;
    setAnchorEl(updatedAnchorElArr);
  };
  const [deleteConfirmDialog, setDeleteConfirmDialog] = React.useState({
    isOpen: false,
    type: "primary",
  });

  const [alertConfirmDialog, setAlertConfirmDialog] = React.useState({
    isOpen: false,
    type: "primary",
  });

  const deletePort = useCallback(async (item, data) => {
    const response = await apiProvider.del(
      `${CHARGER_PROFILE}/${data.depotId}/${data.objectId}/chargeports/${item.connectorId}`
    );

    if (response.statusCode >= 200 && response.statusCode <= 299) {
      setToast({
        isOpen: true,
        message: "Port Deleted",
        type: "success",
      });
      getOtherChargerDetails(data.depotId, data.chargerId);
    } else {
      setToast({
        isOpen: true,
        message: "Port deleting failed",
        type: "error",
      });
    }
    setDeleteConfirmDialog({ ...deleteConfirmDialog, isOpen: false });
  }, []);

  return (
    <>
      <div className="chargeDetailsSideDrawerContainer">
        <div className="chargeDetailsSideDrawer">
          <div className="charge_name_section">
            <div className="charge_name">
              <div className="charge_img">
                <img src={chargerImage} alt="Depot" className="depot" />
              </div>
              <div className="charge_name_and_number">
                <p className="name">
                  {data.chargerName || titleLabels.NOT_AVAILABLE}
                </p>
                <p className="number">
                  {data.serialNumber || titleLabels.NOT_AVAILABLE}
                </p>
              </div>
            </div>

            <div className="charge_connect">
              <div className="charger_type">
                <p>{data.networkType || titleLabels.NOT_AVAILABLE}</p>
              </div>
              <div
                className="charger_status"
                id={
                  data.connectivityStatus
                    ? data.connectivityStatus == "Not Connected"
                      ? "RED"
                      : "GREEN"
                    : "NA"
                }
              >
                <p> {data.connectivityStatus || titleLabels.NOT_AVAILABLE}</p>
              </div>
            </div>
          </div>
          <div className="charger_details_section">
            <div className="charger_heading">
              <p>CHARGER DETAILS</p>
            </div>
            <div className="all_details">
              <div className="single_detail">
                <p className="c_title">Charger ID:</p>
                <p className="description">
                  {data.chargerId || titleLabels.NOT_AVAILABLE}
                </p>
              </div>

              <div className="single_detail">
                <p className="c_title">Account:</p>
                <p className="description">
                  {data.accountName || titleLabels.NOT_AVAILABLE}
                </p>
              </div>
              <div className="single_detail">
                <p className="c_title">Depot:</p>
                <p className="description">
                  {" "}
                  {data.depotName || titleLabels.NOT_AVAILABLE}
                </p>
              </div>
              <div className="single_detail">
                <p className="c_title">Site type:</p>
                <p className="description">
                  {data.siteType || titleLabels.NOT_AVAILABLE}
                </p>
              </div>
              <div className="single_detail">
                <p className="c_title">Manufacturer (OEM):</p>
                <p className="description">
                  {data.vendorManufacturer || titleLabels.NOT_AVAILABLE}
                </p>
              </div>
              <div className="single_detail">
                <p className="c_title">Model (OEM):</p>
                <p className="description">
                  {data.vendorModel || titleLabels.NOT_AVAILABLE}
                </p>
              </div>
              <div className="single_detail">
                <p className="c_title">Sold as manufacturer:</p>
                <p className="description">
                  {data.chargerManufacturer || titleLabels.NOT_AVAILABLE}
                </p>
              </div>
              <div className="single_detail">
                <p className="c_title">Sold as model:</p>
                <p className="description">
                  {data.chargerModel || titleLabels.NOT_AVAILABLE}
                </p>
              </div>
              <div className="single_detail">
                <p className="c_title">Charger driver:</p>
                <p className="description">
                  {driver || titleLabels.NOT_AVAILABLE}
                </p>
              </div>
              <div className="single_detail">
                <p className="c_title">Charger commissioned:</p>
                <p className="description">
                  <Chip
                    label={data.firstCommunication ? "Yes" : "No"}
                    style={{
                      color: data.firstCommunication ? "#00765E" : "#D01300",
                      background: data.firstCommunication
                        ? "#E5F1EF"
                        : "#FAE7E5",
                    }}
                  />
                </p>
              </div>
              {data.isHomeChargingDepot && (
                <div className="single_detail">
                  <p className="c_title">Mina Enrolled:</p>
                  <p className="description">
                    <Chip
                      label={data.isMinaEnrolled ? "Yes" : "No"}
                      style={{
                        color: data.isMinaEnrolled ? "#00765E" : "#D01300",
                        background: data.isMinaEnrolled ? "#E5F1EF" : "#FAE7E5",
                      }}
                    />
                  </p>
                </div>
              )}
              <div className="single_detail">
                <p className="c_title">
                  <div className="rfidStatus">
                    <p>RFID status:</p>
                    <Tooltip
                      arrow
                      TransitionComponent={Zoom}
                      title=<ul>
                        <li>
                          <b>Enabled:</b> This charger currently requires the
                          tap of an authorized RFID card to start dispensing
                          energy in a charge session.
                        </li>
                        <li>
                          <b>Capable:</b> This charger does have a RFID reader,
                          and will be RFID-enabled when the RFID controls are
                          enabled for the profile.
                        </li>
                        <li>
                          <b>Incapable:</b> This charger does not have a RFID
                          reader, and will not be RFID-enabled, even if the
                          profile has RFID access controls enabled.
                        </li>
                      </ul>
                    >
                      <InfoIcon
                        fontSize="small"
                        className="rfidStatusInfoIcon"
                      />
                    </Tooltip>
                  </div>
                </p>
                <p className="description">
                  <Chip
                    avatar={
                      data?.rfidDetails?.rfidConfigMode &&
                      data?.rfidDetails?.isConfigMatched == false && (
                        <WarningIcon
                          fontSize="small"
                          style={{ color: "#916D1F" }}
                        />
                      )
                    }
                    label={data?.rfidDetails?.status}
                    style={{
                      color: rfidColors(data?.rfidDetails),
                      background: rfidBGColor(data?.rfidDetails),
                    }}
                  />
                  {data?.rfidDetails?.rfidConfigMode &&
                    data?.rfidDetails?.isConfigMatched == false && (
                      <span className="RFID_color_sub_title">
                        {data?.rfidDetails?.rfidConfigMode} config needed
                      </span>
                    )}
                </p>
              </div>
              <Divider variant="fullWidth" className="charger_divider" />
              <div className="single_detail">
                <p className="c_title">Charging mode:</p>
                <p className="description">
                  {chargingModeEnum[data.chargingMode] ||
                    titleLabels.NOT_AVAILABLE}
                </p>
              </div>
              <div className="single_detail">
                <p className="c_title">Power type:</p>
                <p className="description">
                  {data.powerType || titleLabels.NOT_AVAILABLE}
                </p>
              </div>
              <div className="single_detail">
                <p className="c_title">Min charging rate(kW):</p>
                <p className="description">
                  {data.minChargingRate >= 0
                    ? data.minChargingRate
                    : titleLabels.NOT_AVAILABLE}
                </p>
              </div>
              <div className="single_detail">
                <p className="c_title">Max charging rate(kW):</p>
                <p className="description">
                  {data.maxChargingRate >= 0
                    ? data.maxChargingRate
                    : titleLabels.NOT_AVAILABLE}
                </p>
              </div>
              <div className="single_detail">
                <p className="c_title">Charger model min charging rate(kW):</p>
                <p className="description">
                  {data.modelMinChargingRate >= 0
                    ? data.modelMinChargingRate
                    : titleLabels.NOT_AVAILABLE}
                </p>
              </div>
              <div className="single_detail">
                <p className="c_title">Charger model max charging rate(kW):</p>
                <p className="description">
                  {data.modelMaxChargingRate >= 0
                    ? data.modelMaxChargingRate
                    : titleLabels.NOT_AVAILABLE}
                </p>
              </div>
              <div className="single_detail">
                <p className="c_title">Charger model max amps(A):</p>
                <p className="description">
                  {data.modelMaxAmps >= 0
                    ? data.modelMaxAmps
                    : titleLabels.NOT_AVAILABLE}
                </p>
              </div>
              {data?.derateConfig?.isChargerDerated && (
                <div className="chargerDerateBanner">
                  <InfoIcon fontSize="small" className="leftInfoIcon" />
                  <p>
                    Charger and its associated ports were derated to{" "}
                    {data?.derateConfig?.deratedMaxAmps} A{" "}
                    <span className="timestamp">
                      on {data?.derateConfig?.timestamp?.replace("GMT", "UTC")}
                    </span>
                  </p>
                  <Tooltip
                    arrow
                    TransitionComponent={Zoom}
                    title={
                      "Indicates a hardware derate, which is set by sending a changeConfiguration command to the charger."
                    }
                  >
                    <InfoIcon fontSize="small" className="rightInfoIcon" />
                  </Tooltip>
                </div>
              )}
              {data?.maxAmps < data?.modelMaxAmps && (
                <div className="chargerDerateBanner">
                  <InfoIcon fontSize="small" className="leftInfoIcon" />
                  <p>Charger and its ports limited to {data?.maxAmps} A</p>
                  <Tooltip
                    arrow
                    TransitionComponent={Zoom}
                    title={
                      "Indicates a software limit, which is set via Ops Console or Depot Charging software. The software limit is used by the charging algorithm and will not apply if the charger is offline."
                    }
                  >
                    <InfoIcon fontSize="small" className="rightInfoIcon" />
                  </Tooltip>
                </div>
              )}
              <Divider variant="fullWidth" className="charger_divider" />
              {/* <div className="single_detail">
                <p className="c_title">Warranty:</p>
                <p className="description">
                  {data.warranty || titleLabels.NOT_AVAILABLE}
                </p>
              </div> */}
              <div className="single_detail">
                <p className="c_title">Firmware version:</p>
                <p className="description">
                  {data.firmwareVersion || titleLabels.NOT_AVAILABLE}
                </p>
              </div>
              <div className="single_detail">
                <p className="c_title">Firmware status:</p>
                <p className="description">
                  {firmwareStatusEnum[data.firmwareStatus] ||
                    titleLabels.NOT_AVAILABLE}
                </p>
              </div>
              <div className="single_detail">
                <p className="c_title">Configuration URL:</p>
                <p className="description">
                  <Controls.NewCopyToClipboard
                    name={data.configurationUrl}
                    setToast={setToast}
                  />
                </p>
              </div>
              <Divider variant="fullWidth" className="charger_divider" />
              <div className="single_detail">
                <p className="c_title">Charger commissioned on:</p>
                <p className="description">
                  {data?.firstCommunication
                    ? new Date(
                        format(new Date(`${data?.firstCommunication}`), "PPpp")
                      )
                        .toUTCString()
                        .slice(0, -7) + "   UTC"
                    : titleLabels.NOT_AVAILABLE}
                </p>
              </div>
              <div className="single_detail">
                <p className="c_title">Billing activated on:</p>
                <p className="description">
                  {(details?.billingActivated &&
                    new Date(
                      format(new Date(`${details?.billingActivated}`), "PPpp")
                    )
                      .toUTCString()
                      .slice(0, -7) + "   UTC") ||
                    titleLabels.NOT_AVAILABLE}
                </p>
              </div>
              <div className="single_detail">
                <p className="c_title">Last assembly line check in:</p>
                <p className="description">
                  {(details?.lastAssemblyLineCheckIn &&
                    new Date(
                      format(
                        new Date(`${details?.lastAssemblyLineCheckIn}`),
                        "PPpp"
                      )
                    )
                      .toUTCString()
                      .slice(0, -7) + "   UTC") ||
                    titleLabels.NOT_AVAILABLE}
                </p>
              </div>
            </div>
          </div>

          <div className="ports_details_section">
            <div className="ports_heading">
              <p>PORT DETAILS</p>
              <PrivilegedComponent
                permission="Manage Port"
                module="chargerHealth"
              >
                <Tooltip
                  arrow
                  TransitionComponent={Zoom}
                  title={`This charger model supports a maximum of ${
                    data?.numberOfPorts
                  } port${data?.numberOfPorts > 1 ? "s" : ""}.`}
                >
                  <span>
                    <Controls.Button
                      text="Port"
                      disabled={
                        details?.portsDetails?.length >= data?.numberOfPorts
                      }
                      variant="outlined"
                      startIcon={<AddIcon />}
                      onClick={() => {
                        let totalCount = details?.portsDetails
                          ?.map((obj) => obj["minimumChargingRate"])
                          ?.reduce(
                            (accumulator, currentValue) =>
                              accumulator + currentValue,
                            0
                          );

                        if (
                          parseInt(totalCount) >=
                          parseInt(data?.maxChargingRate)
                        ) {
                          setAlertConfirmDialog({
                            isOpen: true,
                            title: "This action is not allowed",
                            subTitle:
                              "Cannot add more ports as the number of ports and capacity of each port reached the max capacity of the charger",
                            type: "secondary",
                          });
                        } else {
                          toggleDetailDrawer(
                            true,
                            "ADD CHARGER PORT",
                            "addChargerPort",
                            {}
                          );
                        }

                        handleClose();
                      }}
                      style={{
                        border: "1px solid #2770D8",
                        padding: "3px 5px",
                      }}
                    />
                  </span>
                </Tooltip>
              </PrivilegedComponent>
            </div>
            {data?.derateConfig?.isChargerDerated && (
              <div className="chargerDerateBanner">
                <InfoIcon fontSize="small" className="leftInfoIcon" />
                <p>
                  All ports are derated to {data?.derateConfig?.deratedMaxAmps}{" "}
                  A{" "}
                  <span className="timestamp">
                    on {data?.derateConfig?.timestamp?.replace("GMT", "UTC")}
                  </span>
                </p>
                <Tooltip
                  arrow
                  TransitionComponent={Zoom}
                  title={
                    "Indicates a hardware derate, which is set by sending a changeConfiguration command to the charger."
                  }
                >
                  <InfoIcon fontSize="small" className="rightInfoIcon" />
                </Tooltip>
              </div>
            )}
            {details?.portsDetails?.length !== 0 && (
              <div className="all_ports_details">
                {details?.portsDetails?.map((single, index) => {
                  return (
                    <div className="single_port" key={single.portId}>
                      <div className="name_status_section">
                        <div className="name_gid">
                          <p className="port_Name">
                            {single.portName || titleLabels.NOT_AVAILABLE}
                          </p>
                          <p className="gid">
                            {single.connectorId || titleLabels.NOT_AVAILABLE}
                          </p>
                        </div>

                        <div className="status_vname">
                          {/* When this comes play wth CSS to chage color */}
                          <p className="charged" id="status_one">
                            {single.chargingStatus || titleLabels.NOT_AVAILABLE}
                          </p>
                          <div className="vname">
                            {single.isPublicSession === true ? (
                              <>
                                <div className="vimg">
                                  <img
                                    src={publicCarImage}
                                    alt="publicCar"
                                    className="bus"
                                  />
                                </div>
                                <p className="publicName">Public vehicle</p>
                              </>
                            ) : (
                              <>
                                <div className="vimg">
                                  <img
                                    src={busImage}
                                    alt="Bus"
                                    className="bus"
                                  />
                                </div>
                                <p className="name">
                                  {single.vehicleName ||
                                    titleLabels.NOT_AVAILABLE}
                                </p>
                              </>
                            )}
                          </div>
                          <div>
                            <PrivilegedComponent
                              permission="Manage Port"
                              module="chargerHealth"
                            >
                              <Tooltip
                                arrow
                                TransitionComponent={Zoom}
                                title={"Edit or Delete Port"}
                              >
                                <span>
                                  <Controls.ActionButton
                                    // color="primary"
                                    onClick={(e) => handleClick(e, index)}
                                  >
                                    <MoreVertIcon
                                      fontSize="small"
                                      color={"#1DB954"}
                                    />
                                  </Controls.ActionButton>
                                </span>
                              </Tooltip>
                            </PrivilegedComponent>

                            <Menu
                              id={`basic-menu-${index}`}
                              anchorEl={anchorEl[index]}
                              open={Boolean(anchorEl[index])}
                              onClose={() => handleClose(index)}
                              MenuListProps={{
                                "aria-labelledby": `basic-button-${index}`,
                              }}
                            >
                              <MenuItem
                                onClick={() => {
                                  toggleDetailDrawer(
                                    true,
                                    "EDIT CHARGER PORT",
                                    "editChargerPort",
                                    single
                                  ),
                                    handleClose(index);
                                }}
                              >
                                Edit port details
                              </MenuItem>
                              <MenuItem
                                onClick={() => {
                                  if (
                                    single.chargingStatus === "Not Charging"
                                  ) {
                                    setDeleteConfirmDialog({
                                      isOpen: true,
                                      title: "Delete charger port",
                                      subTitle:
                                        "Are you sure you want to delete the selected charger port?",
                                      type: "secondary",
                                      onConfirm: () => deletePort(single, data),
                                    });
                                  } else {
                                    setAlertConfirmDialog({
                                      isOpen: true,
                                      title:
                                        "This port cannot be deleted while a vehicle is plugged into it.",
                                      subTitle:
                                        "This port is plugged into a vehicle. To delete this port, please unplug the vehicle and try again.",
                                      type: "secondary",
                                    });
                                  }

                                  handleClose(index);
                                }}
                              >
                                Delete port
                              </MenuItem>
                            </Menu>
                          </div>
                        </div>
                      </div>
                      {single?.maxAmps < data?.modelMaxAmps && (
                        <div className="chargerDerateBanner">
                          <InfoIcon fontSize="small" className="leftInfoIcon" />
                          <p>Port is limited to {data?.maxAmps} A</p>
                          <Tooltip
                            arrow
                            TransitionComponent={Zoom}
                            title={
                              "Indicates a software limit, which is set via Ops Console or Depot Charging software. The software limit is used by the charging algorithm and will not apply if the charger is offline."
                            }
                          >
                            <InfoIcon
                              fontSize="small"
                              className="rightInfoIcon"
                            />
                          </Tooltip>
                        </div>
                      )}
                      <div className="type_min_max_section">
                        <div className="type">
                          <p className="title">Connector type</p>
                          <p className="disc">
                            {single.typeConnector || titleLabels.NOT_AVAILABLE}
                          </p>
                        </div>
                        <div className="min">
                          <p className="title">Min charging rate(kW)</p>
                          <p className="disc">
                            {single.minimumChargingRate >= 0
                              ? single.minimumChargingRate
                              : titleLabels.NOT_AVAILABLE}
                          </p>
                        </div>
                        <div className="max">
                          <p className="title">Max charging rate(kW)</p>
                          <p className="disc">
                            {single.maxCapacity >= 0
                              ? single.maxCapacity
                              : titleLabels.NOT_AVAILABLE}
                          </p>
                        </div>
                      </div>
                      <div className="description_section">
                        <p className="d_title">Description</p>
                        <p className="d_disc">
                          {single.description || titleLabels.NOT_AVAILABLE}
                        </p>
                      </div>
                    </div>
                  );
                })}
              </div>
            )}
          </div>
          {data?.RFIDConfig?.length > 0 &&
            data?.RFIDConfig[0]?.key?.length > 0 && (
              <div
                className="charger_details_section"
                id="rfidAuthModeContainerInChargerDeatils"
              >
                <div className="charger_heading">
                  <p>RFID AUTHENTICATION CONFIGURATION</p>
                </div>
                <div className="rfidTable">
                  <Grid container className="rfidAuthModeHeading">
                    <Grid item xs={4}></Grid>
                    <Grid item xs={4}>
                      Freevend mode
                    </Grid>
                    <Grid item xs={4}>
                      Authentication mode
                    </Grid>
                  </Grid>
                  {data?.RFIDConfig?.map((row, ind) => (
                    <Grid
                      container
                      key={ind}
                      spacing={0}
                      className="rfidAuthModeRows"
                    >
                      <Grid item xs={4} className="rfidAuthModeKey rowHeight">
                        <p>{row.key?.toString()}</p>
                      </Grid>
                      <Grid item xs={4}>
                        <p>{row.freevendValue?.toString()}</p>
                      </Grid>
                      <Grid item xs={4}>
                        <p>{row.authValue?.toString()}</p>
                      </Grid>
                    </Grid>
                  ))}
                </div>
              </div>
            )}
        </div>
      </div>

      <ToastMessage toast={toast} setToast={setToast} />
      {deleteConfirmDialog.isOpen && (
        <DeleteConfirmDialog
          deleteConfirmDialog={deleteConfirmDialog}
          setDeleteConfirmDialog={setDeleteConfirmDialog}
        />
      )}

      {alertConfirmDialog.isOpen && (
        <AlertDialog
          alertConfirmDialog={alertConfirmDialog}
          setAlertConfirmDialog={setAlertConfirmDialog}
        />
      )}
      <NewCommonFilterDrawer
        DrawerOC={DrawerOC}
        toggleDrawer={toggleDetailDrawer}
      >
        {(DrawerOC.title === "EDIT CHARGER PORT" ||
          DrawerOC.title === "ADD CHARGER PORT") && (
          <AddEditChargerPort
            DrawerOC={DrawerOC}
            parentCharger={data}
            getOtherChargerDetails={getOtherChargerDetails}
            details={details}
            setToast={setToast}
            setLoading={props.setLoading}
            toggleDetailDrawer={toggleDetailDrawer}
            portCount={details?.portsDetails?.length}
          />
        )}
      </NewCommonFilterDrawer>
    </>
  );
};
export default ChargerdetailsSideBar;
