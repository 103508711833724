import * as React from "react";
import PropTypes from "prop-types";

import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import Controls from "../../../components/controls/Controls";
import SingleTypeFilter from "../../../components/all-filter-types/SingleFilter";
import TabPanel from "../../../components/all-filter-types/FilterTabPanel";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import { checkSingleFilterSelected } from "../../../components/all-filter-types/isFilteredCheckFunctions";

const BillingDepotsFilter = (props) => {
  const { applyFilter, depotStatus } = props;
  const [value, setValue] = React.useState("Depot Status");
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const [tempAllDepotStatus, setTempAllDepotStatus] = React.useState(
    JSON.parse(JSON.stringify(depotStatus))
  );

  const resetFilterFields = (field) => {
    field[0].forEach((parent) => {
      parent.checked = false;
      parent.children.forEach((child) => {
        if (child.children) {
          child.children.forEach((grandChild) => {
            grandChild.checked = false;
          });
        }
        child.checked = false;
      });
    });
    field[1]([...field[0]]);
  };

  const clearAll = () => {
    const fields = [[tempAllDepotStatus, setTempAllDepotStatus]];
    fields.forEach((field) => resetFilterFields(field));
  };

  return (
    <>
      <div className="filters_with_vertical_tab">
        <Box sx={{ flexGrow: 1, bgcolor: "background.paper", display: "flex" }}>
          <Tabs
            orientation="vertical"
            variant="scrollable"
            value={value}
            onChange={handleChange}
            aria-label="Vertical tabs example"
            sx={{ borderRight: 1, borderColor: "divider" }}
          >
            <Tab
              label="Depot Status"
              value={"Depot Status"}
              icon={
                checkSingleFilterSelected(tempAllDepotStatus) ? (
                  <FiberManualRecordIcon className="filter_dot" />
                ) : null
              }
              iconPosition="end"
            />
          </Tabs>
          <TabPanel value={value} index={"Depot Status"}>
            <div className="filter_tab_vertical_body">
              <SingleTypeFilter
                _stateVariable={tempAllDepotStatus}
                setFuntion={setTempAllDepotStatus}
              />
            </div>
          </TabPanel>
        </Box>
        <div className="filter_action_btn">
          <Controls.Button
            disabled={!checkSingleFilterSelected(tempAllDepotStatus)}
            text="Clear All"
            variant="outlined"
            onClick={() => clearAll()}
            className="btn_font_size"
          />
          <Controls.Button
            text="Apply"
            color="primary"
            onClick={() => applyFilter(tempAllDepotStatus)}
            className="btn_font_size"
          />
        </div>
      </div>
    </>
  );
};

export default BillingDepotsFilter;
