import { CircularProgress } from "@mui/material";
import React from "react";

export default function ComponentLoader(props) {
  const { isLoading, children = null } = props;

  return isLoading ? (
    <div className="common_component_loader">
      <CircularProgress size={30} />
    </div>
  ) : (
    children
  );
}
