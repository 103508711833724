import React from "react";
import ErrorCodeDrawerDetails from "../../../data-catalog/errorCodes/ErrorCodeDrawerDetails";

const FaultsTroubleShoot = (props) => {
  const { troubleShootData, DrawerTCG } = props;
  const [isTS, setIsTS] = React.useState({});
  React.useEffect(() => {
    if (troubleShootData) {
      setIsTS(
        troubleShootData.find(
          (single) =>
            single.errorCode === DrawerTCG.data.errorCode &&
            single.manufacturer === DrawerTCG.data.make &&
            single.model === DrawerTCG.data.model
        )
      );
    }
  }, [troubleShootData]);

  return isTS ? (
    <ErrorCodeDrawerDetails drawerObj={isTS} />
  ) : (
    <div className="troubleShoot_sec_bg">
      <p className="no_data">
        There are no possible resolutions provided by the manufacturer.
      </p>
    </div>
  );
};

export default FaultsTroubleShoot;
