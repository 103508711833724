import React from "react";
import Controls from "../../../components/controls/Controls";
import { Grid, InputAdornment, Toolbar, Badge } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import PageMainContent from "../../../components/PageMainContent";
import Loader from "../../../components/Loader";
import Popup from "../../../components/Popup";
import ExportCSV from "../../../components/ExportCSV";
import no_Data from "./../../../assets/images/png/Vector.png";
import "../vehicleDiscovery.scss";
import { useVehiclesDiscovery } from "../VehiclesDiscoveryContext";

const ToolbarMemoised = React.memo(({ handleSearch, setOpenPopup }) => {
  return (
    <Toolbar className="table_toolbar">
      <Controls.Input
        label={"Search"}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <SearchIcon />
            </InputAdornment>
          ),
        }}
        onChange={handleSearch}
      />
      <Grid item sm />
      <Badge color="primary">
        <Controls.Button
          text="Export CSV"
          variant="outlined"
          id="vehicleExportCSV"
          onClick={() => {
            setOpenPopup({
              isOpen: true,
              title: "Export CSV",
              child: "exportCSV",
            });
          }}
        />
      </Badge>
    </Toolbar>
  );
});

const AllSessions = () => {
  const {
    isLoading,
    handleSearch,
    vehicleDiscoveryData,
    setOpenPopup,
    recordsAfterPagingAndSorting,
    TablePagination,
    TableContainer,
    TableHead,
    TableBody,
    openPopup,
    allColumnsHeaders,
    headers,
    setHeaders,
  } = useVehiclesDiscovery();

  if (isLoading) {
    return <Loader isLoading={isLoading} />;
  } else {
    return (
      <>
        <PageMainContent>
          <ToolbarMemoised
            handleSearch={handleSearch}
            setOpenPopup={setOpenPopup}
          />
          <TableContainer>
            <TableHead />
            {TableBody}
          </TableContainer>
          {recordsAfterPagingAndSorting.length === 0 && (
            <div className="no_data_in_table">
              <div className="img_title">
                <img src={no_Data} alt="No data available" />
                <p className="no_data_title">No data available</p>
              </div>
            </div>
          )}
          {TablePagination}
        </PageMainContent>
        <Popup openPopup={openPopup} setOpenPopup={setOpenPopup} minWidth="50%">
          {openPopup.child === "exportCSV" ? (
            <ExportCSV
              setOpenPopup={setOpenPopup}
              data={vehicleDiscoveryData}
              allColumnsHeaders={allColumnsHeaders}
              headers={headers}
              setHeaders={setHeaders}
              fileName={"Vehicle-Discovery.csv"}
            />
          ) : null}
        </Popup>
      </>
    );
  }
};

export default AllSessions;
