import React, { useCallback, useEffect, useMemo, useState } from "react";
import Loader from "../../../components/Loader";
import OpsBreadcrumb from "../../../components/NewBreadcrumbs";
import PageHeader from "../../../components/PageHeader";
import useTable from "../../../components/UseTable";
import {
  apiProvider,
  indexedEndPoints,
} from "../../../services/api/utilities/provider";
import SearchIcon from "@mui/icons-material/Search";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import EditOutlined from "@mui/icons-material/EditOutlined";
import DeleteIcon from "@mui/icons-material/Delete";
import { Grid, InputAdornment, Toolbar, Tooltip, Zoom } from "@mui/material";
import { makeStyles } from "@mui/styles";
import Controls from "../../../components/controls/Controls";
import PageMainContent from "../../../components/PageMainContent";
import { Link, useHistory } from "react-router-dom";
import { routePaths } from "../../../constants/RoutePaths";
import { PrivilegedComponent } from "../../../utils/PrivilegedComponent";
import Popup from "../../../components/Popup";
import ToastMessage from "../../../components/ToastMessage";
import AddEditProvider from "./AddEditProvider";
import { useSelector } from "react-redux";
import ConfirmDialog from "../../../components/ConfirmDialog";
import UtilityPlanExpiryStatus from "./UtilityPlanExpiryStatus";

const allBreadcrumbsLinks = [
  {
    link: routePaths.DATA_CATALOG,
    title: "Data Catalog",
  },
];

const useStyles = makeStyles(() => ({
  "@global": {
    "*::-webkit-scrollbar": {
      width: "0.4em",
    },
    "*::-webkit-scrollbar-track": {
      "-webkit-box-shadow": "inset 0 0 6px rgba(0,0,0,0.00)",
    },
    "*::-webkit-scrollbar-thumb": {
      backgroundColor: "rgba(0,0,0,.1)",
      outline: "0px solid slategrey",
    },
  },
}));

export default function UtilityProvider() {
  const [loading, setLoading] = useState(false);
  const [countryLoading, setCountryLoading] = useState(false);
  const [filterFn, setFilterFn] = useState({ fn: (items) => items });
  const [regions, setRegions] = useState("");
  const [refreshProviders, setRefreshProviders] = useState(true);
  const [data, setData] = useState([]);
  const [openPopup, setOpenPopup] = useState({
    isOpen: false,
    title: "Add Provider",
    child: "",
    item: {},
  });
  const [confirmDialog, setConfirmDialog] = useState({
    isOpen: false,
    type: "primary",
  });
  const [toast, setToast] = useState({
    isOpen: false,
    message: "",
    type: "",
  });
  const componentPrivileges = useSelector(
    (state) => state?.user?.componentPrivilege?.dataCatalogUtilityRate
  );
  const history = useHistory();
  const classes = useStyles();

  const { GET_UTILITY_PROVIDERS, UTILITY_PROVIDERS, UTILITY_PROVIDER_REGIONS } =
    indexedEndPoints;

  useEffect(() => {
    const fetchProviders = async () => {
      setLoading(true);
      const response = await apiProvider.getAll(GET_UTILITY_PROVIDERS);
      setLoading(false);
      setData(response?.data);
      setRefreshProviders(false);
    };
    refreshProviders && fetchProviders();
  }, [refreshProviders]);

  useEffect(() => {
    const fetchProviderRegions = async () => {
      setCountryLoading(true);
      const response = await apiProvider.getAll(UTILITY_PROVIDER_REGIONS);
      setCountryLoading(false);
      setRegions(response?.data?.map((item) => item.country));
    };
    fetchProviderRegions();
  }, []);

  const handleSearch = (e) => {
    setFilterFn({
      fn: (items) => {
        if (e.target.value?.trim() === "") return items;
        else
          return items?.filter((row) =>
            Object.values(row)?.some((val) =>
              val
                ?.toString()
                ?.toLowerCase()
                ?.includes(e.target.value?.trim().toLowerCase())
            )
          );
      },
    });
  };

  const handleEditProvider = (row) => {
    setOpenPopup({
      isOpen: true,
      title: "Update Provider",
      child: "updateProvider",
      item: row,
    });
  };

  const handleDeleteProvider = (row) => {
    setConfirmDialog({
      ...confirmDialog,
      isOpen: true,
      title: "Are you sure ?",
      subTitle: "You want to Delete this Provider",
      type: "secondary",
      onConfirm: () => deleteProvider(row.providerMetaId),
    });
  };

  const deleteProvider = useCallback(async (providerMetaId) => {
    setLoading(true);
    const response = await apiProvider.del(
      `${UTILITY_PROVIDERS}/${providerMetaId}`
    );
    setLoading(false);
    if (response.statusCode >= 200 && response.statusCode <= 299) {
      setToast({
        isOpen: true,
        message: "Utility Provider and its plans deleted successfully",
        type: "success",
      });
      setConfirmDialog({ ...confirmDialog, isOpen: false });
      setRefreshProviders(true);
    } else {
      setToast({
        isOpen: true,
        message: response?.data?.data || "Utility Provider deletion failed",
        type: "error",
      });
    }
  }, []);

  const actionItems = useMemo(() => {
    let actions = [];
    if (componentPrivileges?.includes("Update Provider"))
      actions.push({
        type: "Edit",
        label: "Edit",
        testid: "editUtilityProviderBtn",
        icon: () => (
          <Tooltip arrow TransitionComponent={Zoom} title="Edit">
            <EditOutlined fontSize="small" />
          </Tooltip>
        ),
        onClick: handleEditProvider,
      });
    if (componentPrivileges?.includes("Delete Provider"))
      actions.push({
        type: "Delete",
        label: "Delete",
        testid: "deleteUtilityProviderBtn",
        icon: () => (
          <Tooltip arrow TransitionComponent={Zoom} title="Delete">
            <DeleteIcon fontSize="small" />
          </Tooltip>
        ),
        onClick: handleDeleteProvider,
      });
    return actions?.length > 0 ? actions : null;
  }, [componentPrivileges]);

  const headCells = useMemo(() => {
    let headers = [
      {
        id: "providerName",
        label: "Provider Name",
        render: (row, col) => (
          <Link
            to={`${routePaths.UTILITY_PROVIDER_PLANS}?providerId=${row?.providerMetaId}&providerName=${row?.providerName}`}
          >
            {row[col]}
          </Link>
        ),
      },
      {
        id: "region",
        label: "Region",
        render: (row, col) =>
          row[col] ? row[col]?.map((item, i) => <div key={i}>{item}</div>) : "",
      },
      { id: "planCount", label: "Number of Plans" },
    ];
    if (actionItems)
      headers.push({ disableSorting: true, id: "action", label: "Action" });
    return headers;
  }, [actionItems]);

  const {
    tableContainer: TableContainer,
    tableHead: TableHead,
    tableBody: TableBody,
    tablePagination: TablePagination,
  } = useTable(data, headCells, filterFn, false, null, actionItems);

  const ToolbarMemoised = useCallback(({ handleSearch, classes }) => {
    return (
      <Toolbar className="table_toolbar">
        <Controls.Input
          label={"Search"}
          className="searchInput"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
          }}
          onChange={handleSearch}
        />
        <Controls.Button
          text="Search Plans by Zipcode"
          variant="outlined"
          startIcon={<SearchIcon />}
          onClick={() => history.push(routePaths.UTILITY_PLAN_SEARCH)}
        />
        <Grid sm item />
        <PrivilegedComponent
          permission="Add Provider"
          module="dataCatalogUtilityRate"
        >
          <Controls.Button
            text="Add Provider"
            variant="outlined"
            data-testid="addUtilityProviderBtn"
            startIcon={<AddCircleOutlineIcon />}
            onClick={() =>
              setOpenPopup({
                isOpen: true,
                title: "Add Provider",
                child: "addProvider",
                item: {},
              })
            }
          />
        </PrivilegedComponent>
      </Toolbar>
    );
  }, []);

  return (
    <>
      <OpsBreadcrumb
        AllBreadcrumbsLinks={allBreadcrumbsLinks}
        title="Utility Providers"
      />
      <PageHeader title="Utility Providers" />
      <PageMainContent>
        <ToolbarMemoised handleSearch={handleSearch} classes={classes} />
        <Loader isLoading={loading || countryLoading} />
        <TableContainer>
          <TableHead />
          {TableBody}
        </TableContainer>
        {TablePagination}
      </PageMainContent>
      <UtilityPlanExpiryStatus />
      <Popup openPopup={openPopup} setOpenPopup={setOpenPopup}>
        <AddEditProvider
          type={openPopup.child}
          openPopup={openPopup}
          setOpenPopup={setOpenPopup}
          setRefreshProviders={setRefreshProviders}
          setToast={setToast}
          providerMetaId={openPopup?.item?.providerMetaId}
          regions={regions}
        />
      </Popup>
      <ConfirmDialog
        confirmDialog={confirmDialog}
        setConfirmDialog={setConfirmDialog}
      />
      <ToastMessage toast={toast} setToast={setToast} />
    </>
  );
}
