import React from "react";
import { Paper, Card, Typography, Grid } from "@mui/material";
import "./components-scss/PageHeader.scss";

const PageHeader = (props) => {
  const { title, subTitle, icon } = props;
  return (
    <div className="pageHeader">
      <Grid container>
        <Grid item xs={12}>
          <p className="headline">{title}</p>
        </Grid>
      </Grid>
    </div>
  );
};

export default PageHeader;
