import "./../billing/MoreInfo.scss";
import {} from "@mui/material";

import React, { memo, useEffect, useState } from "react";

import Controls from "../../components/controls/Controls";
import ToastMessage from "../../components/ToastMessage";
import { getDepotDeployment } from "./companiesServices";

const DeploymentDetails = (props) => {
  const { DrawerOC } = props;
  const [isLoaded, setIsloaded] = React.useState(false);
  const [rawData, setRawData] = React.useState({});
  const [toast, setToast] = React.useState({
    isOpen: false,
    message: "",
    type: "",
  });
  const [depolymentData, setDepolymentData] = React.useState({});
  useEffect(() => {
    getDepotDeploymentDetails();
  }, []);

  const getDepotDeploymentDetails = async () => {
    setIsloaded(true);
    const res = await getDepotDeployment(DrawerOC.data.depotId);
    if (res?.statusCode === 200) {
      setRawData(res?.data[0]);
      const keys = [
        "cms_api_environment_variables",
        "cms_domain",
        "customer_account_name",
        "depot_id",
        "depot_info",
        "depot_name",
        "enable_ocpp_simulator",
        "environment",
      ];
      let tempRawData = {};
      tempRawData["central_server_config"] = {
        ...res?.data[0].central_server_config_bool,
        ...res.data[0].central_server_config_number,
        ...res.data[0].central_server_config_string,
      };
      keys.map((single) => {
        tempRawData[single] = res?.data[0][single];
      });
      setDepolymentData(tempRawData);
    }
    setIsloaded(false);
  };

  return (
    <>
      <div>
        <div className="single_details">
          <p className="title">Depot Name</p>
          <p className="title_value">{DrawerOC.data.depotName}</p>
        </div>
        <Controls.CopyToClipboard
          name={depolymentData}
          isJson={true}
          setToast={setToast}
          max_Height={"600px"}
        />
      </div>
      <ToastMessage toast={toast} setToast={setToast} />
    </>
  );
};

export default DeploymentDetails;
