import React from "react";
import {
  Dialog,
  DialogContent,
  DialogActions,
  Typography,
} from "@mui/material";
import { makeStyles } from "@mui/styles";

import Controls from "./controls/Controls";
import Slide from "@mui/material/Slide";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const useStyles = makeStyles((theme) => ({
  dialog: {
    padding: theme.spacing(2),
    position: "absolute",
    top: theme.spacing(15),
    borderRadius: "2px",
    minWidth: "500px",
  },
  dialogContent: {
    textAlign: "left",
    padding: "0",
    marginBottom: "10px",
  },
  dialogAction: {
    justifyContent: "end",
  },
  reverseActionBtns: {
    flexDirection: "row-reverse",
    columnGap: "8px",
  },
  dialogTitle: {
    textAlign: "center",
  },
  title: {
    marginBottom: "10px",
  },
  subTitle: {},
  redColor: {
    color: "#d01300",
  },
  titleIcon: {
    backgroundColor: ({ type }) => theme.palette[type].light,
    color: ({ type }) => theme.palette[type].main,
    "&:hover": {
      backgroundColor: ({ type }) => theme.palette[type].light,
      cursor: "default",
    },
    "& .MuiSvgIcon-root": {
      fontSize: "8rem",
    },
  },
}));

const ConfirmDialog = (props) => {
  const { confirmDialog, setConfirmDialog, buttonsPosition = "" } = props;
  const classes = useStyles({ type: confirmDialog.type });
  return (
    <Dialog
      TransitionComponent={Transition}
      open={confirmDialog.isOpen}
      classes={{ paper: classes.dialog }}
    >
      <DialogContent className={classes.dialogContent}>
        <Typography variant="h6" className={classes.title}>
          {confirmDialog.title}
        </Typography>
        <Typography
          variant="subtitle2"
          className={
            confirmDialog?.critical == true
              ? classes.redColor
              : classes.subTitle
          }
        >
          {confirmDialog.subTitle}
        </Typography>
        {confirmDialog?.subTitleOne && (
          <Typography
            variant="subtitle2"
            style={{ marginTop: "20px" }}
            className={classes.subTitle}
          >
            {confirmDialog?.subTitleOne}
          </Typography>
        )}
      </DialogContent>
      <DialogActions
        className={`${classes.dialogAction} ${
          buttonsPosition === "reverse" // to reverse the position of Yes/No buttons
            ? classes.reverseActionBtns
            : ""
        }`}
      >
        <Controls.Button
          variant={
            buttonsPosition === "reverse" // in reverse position, NO will not be outlined and on the right side
              ? "contained"
              : "outlined"
          }
          text="No"
          color="primary"
          onClick={() => setConfirmDialog({ ...confirmDialog, isOpen: false })}
        ></Controls.Button>
        <Controls.Button
          variant={
            buttonsPosition === "reverse" // in reverse position, YES will be outlined and on left side of NO
              ? "outlined"
              : "contained"
          }
          text="Yes"
          color={
            confirmDialog.type === "success" ? "primary" : confirmDialog.type
          }
          onClick={confirmDialog.onConfirm}
        ></Controls.Button>
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmDialog;
