import React, { useCallback, useEffect, useState } from "react";
import {
  Grid,
  Switch,
  FormGroup,
  FormControlLabel,
  Checkbox,
  InputAdornment,
  Badge,
} from "@mui/material";
import { Box } from "@mui/system";
import TuneIcon from "@mui/icons-material/Tune";
import AccessTimeOutlinedIcon from "@mui/icons-material/AccessTimeOutlined";
import Controls from "../../components/controls/Controls";
import PageMainContent from "../../components/PageMainContent";
import { useSelector } from "react-redux";
import {
  apiProvider,
  indexedEndPoints,
} from "../../services/api/utilities/provider";
import Loader from "../../components/Loader";
import ToastMessage from "../../components/ToastMessage";
import OpsBreadcrumb from "../../components/NewBreadcrumbs";
import PageHeader from "../../components/PageHeader";
import { DepotType } from "../../constants/Variables";
import UserAlertSettingsFilter from "./UserAlertSettingsFilter";
import NewCommonFilterDrawer from "../../components/NewSideFIlterDrawer";
import "./users.scss";
import { PrivilegedComponent } from "../../utils/PrivilegedComponent";

export default function UserAlertSettings() {
  const [isFiltered, setIsFiltered] = useState(false);
  const [loading, setLoading] = useState(false);
  const [checked, setChecked] = useState({
    faultAlerts: false,
    connectivityAlerts: false,
    maxChargerTempAlerts: false,
    minChargerTempAlerts: false,
  });
  const [alertTimeout, setAlertTimeout] = useState(5);
  const [errors, setErrors] = useState("");
  const [severity, setSeverity] = useState([]);
  const severityList = [
    "High Severity",
    "Medium Severity",
    "Low Severity",
    "Not Available",
  ];
  const [toast, setToast] = useState({
    isOpen: false,
    message: "",
    type: "",
  });
  const [drawerObj, setDrawerObj] = useState({
    isOpen: false,
    title: "",
    data: {},
  });
  const toggleDrawer = (openClose, title, item) => {
    setDrawerObj({ isOpen: openClose, title: title, data: item });
  };
  const handleFilterDrawer = () => {
    setDrawerObj({
      isOpen: true,
      title: "Customer Accounts",
      data: {},
    });
  };
  const depotTypeList = [
    DepotType.TEST,
    DepotType.INTERNAL,
    DepotType.PILOT,
    DepotType.CUSTOMER,
    DepotType.DEALER,
  ];
  const [depotTypes, setDepotTypes] = useState([
    {
      name: "Depot Type",
      isExpanded: true,
      checked: false,
      children: depotTypeList?.map((item) => ({ checked: false, name: item })),
    },
  ]);
  const [custAccounts, setCustAccounts] = useState([
    {
      name: "Customers",
      isExpanded: true,
      checked: false,
      children: [],
    },
  ]);
  const [allCustAcc, setAllCustAcc] = useState([]);
  const [custAccReady, setCustAccReady] = useState(false);

  const userInfo = useSelector((state) => state.user);
  const { userEmail = "" } = userInfo;

  const { USER_ALERT_SETTINGS, GET_ALL_V2_COMPANIES } = indexedEndPoints;
  const endPoint = USER_ALERT_SETTINGS.replace("email", userEmail);

  const handleChange = (event, type) =>
    setChecked({ ...checked, [type]: event.target.checked });

  useEffect(() => {
    const fetchCustomerAccounts = async () => {
      setLoading(true);
      const response = await apiProvider.getAll(GET_ALL_V2_COMPANIES);
      setAllCustAcc(
        response?.data?.map((row) => ({
          checked: false,
          name: row.companyName,
          id: row.companyId,
        }))
      );
      setCustAccounts([
        {
          ...custAccounts[0],
          children: response?.data?.map((row) => ({
            checked: false,
            name: row.companyName,
            id: row.companyId,
          })),
        },
      ]);
      setCustAccReady(true);
    };
    fetchCustomerAccounts();
  }, []);

  useEffect(() => {
    const fetchSettings = async () => {
      const response = await apiProvider.getAll(endPoint);
      setLoading(false);
      const notifications = response?.data?.notifications;
      setIsFiltered(
        notifications?.depotTypes?.length > 0 ||
          notifications?.companyIds?.length > 0 ||
          notifications?.isAllCustomersSelected
      );

      setChecked({
        faultAlerts: notifications?.chargerFaults?.isEnabled,
        connectivityAlerts: notifications?.chargerConnectivity?.isEnabled,
        maxChargerTempAlerts:
          notifications?.chargerTemperature?.maxTemperature || false,
        minChargerTempAlerts:
          notifications?.chargerTemperature?.minTemperature || false,
      });
      setAlertTimeout(notifications?.chargerConnectivity?.intervalInMin);
      const faultSeverity = notifications?.chargerFaults;
      const severityDefaultValues = [];
      faultSeverity?.isHigh && severityDefaultValues.push("High Severity");
      faultSeverity?.isLow && severityDefaultValues.push("Low Severity");
      faultSeverity?.isMedium && severityDefaultValues.push("Medium Severity");
      faultSeverity?.isNa && severityDefaultValues.push("Not Available");
      setSeverity(severityDefaultValues);
      setDepotTypes([
        {
          ...depotTypes[0],
          checked:
            depotTypes[0]?.children?.length ===
            notifications?.depotTypes?.length,
          children: depotTypes[0]?.children?.map((item) => ({
            ...item,
            checked: notifications?.depotTypes?.includes(item.name),
          })),
        },
      ]);
      custAccounts[0]?.children?.length &&
        setCustAccounts([
          {
            ...custAccounts[0],
            checked: notifications?.isAllCustomersSelected,
            children: custAccounts[0]?.children?.map((item) => ({
              ...item,
              checked:
                notifications?.isAllCustomersSelected ||
                notifications?.companyIds?.includes(item.id),
            })),
          },
        ]);
    };

    custAccReady && fetchSettings();
  }, [custAccReady]);

  const handleSubmit = useCallback(async () => {
    setLoading(true);
    const payload = {
      chargerFaults: {
        isEnabled: checked?.faultAlerts,
        isHigh: severity?.includes("High Severity"),
        isLow: severity?.includes("Low Severity"),
        isMedium: severity?.includes("Medium Severity"),
        isNa: severity?.includes("Not Available"),
      },
      chargerConnectivity: {
        isEnabled: checked?.connectivityAlerts,
        intervalInMin: alertTimeout,
      },
      chargerTemperature: {
        maxTemperature: checked?.maxChargerTempAlerts,
        minTemperature: checked?.minChargerTempAlerts,
      },
      depotTypes: depotTypes[0]?.children
        ?.filter((item) => item.checked)
        ?.map((item) => item.name),
      companyIds: custAccounts[0]?.children
        ?.filter((item) => item.checked)
        ?.map((item) => item.id),
      isAllCustomersSelected:
        custAccounts[0]?.children?.filter((item) => item.checked)?.length ===
        allCustAcc?.length,
    };
    const response = await apiProvider.put(endPoint, payload);
    setLoading(false);
    if (response.statusCode >= 200 && response.statusCode <= 299) {
      setToast({
        isOpen: true,
        message: "User Alert Settings updated successfully",
        type: "success",
      });
    } else {
      setToast({
        isOpen: true,
        message: "User Alert Settings updation failed",
        type: "error",
      });
    }
  }, [checked, severity, alertTimeout, depotTypes, custAccounts]);

  useEffect(
    () =>
      setErrors(
        checked?.connectivityAlerts && (alertTimeout < 5 || alertTimeout > 1440)
          ? "Please enter duration between 5 minutes and 1440 minutes"
          : ""
      ),
    [checked, alertTimeout]
  );

  return (
    <>
      <OpsBreadcrumb AllBreadcrumbsLinks={[]} title="User Alert Settings" />
      <div className="topPadding">
        <PageHeader title="User Alert Settings" />
      </div>
      <PageMainContent>
        <Loader isLoading={loading} />
        <Grid container className="error_code_drawer" id="justifyBetween">
          <Grid item>
            <p className="title" id="alertTitle">
              ALERTS
            </p>
          </Grid>
          <Grid item>
            <Badge
              color="primary"
              variant="dot"
              invisible={isFiltered ? false : true}
            >
              <Controls.Button
                text="Select Accounts to receive alerts for"
                variant="outlined"
                startIcon={
                  <TuneIcon
                    style={{ color: isFiltered ? "#2770D8" : "#A3B6C7" }}
                  />
                }
                onClick={handleFilterDrawer}
                style={{
                  border: isFiltered
                    ? "1px solid #2770D8"
                    : "1px solid #A3B6C7",
                  padding: "4px 10px",
                  color: "#3C5164",
                }}
              />
            </Badge>
          </Grid>
          <Grid container>
            <p className="title" id="topMargin">
              CHARGE PORT FAULT ALERTS
            </p>
            <Switch
              checked={checked?.faultAlerts}
              onChange={(e) => handleChange(e, "faultAlerts")}
              inputProps={{ "aria-label": "controlled" }}
            />
          </Grid>
          <div className="error_code_drawer">
            <p className="title">Severity</p>
            {severityList?.map((item, i) => (
              <FormGroup key={"severity-row" + i}>
                <FormControlLabel
                  control={
                    <Checkbox
                      disabled={!checked?.faultAlerts}
                      checked={severity?.includes(item)}
                      onChange={() => {
                        let sv = severity?.includes(item)
                          ? severity?.filter((key) => key !== item)
                          : [...severity, item];
                        setSeverity(sv);
                      }}
                    />
                  }
                  label={item}
                />
              </FormGroup>
            ))}
          </div>
          <Grid container className="topPadding">
            <p className="title" id="topMargin">
              CHARGE PORT CONNECTIVITY LOSS ALERTS
            </p>
            <Switch
              checked={checked?.connectivityAlerts}
              onChange={(e) => handleChange(e, "connectivityAlerts")}
              inputProps={{ "aria-label": "controlled" }}
            />
          </Grid>
          <Grid container>
            <Grid item xs={7}>
              <p>
                Enter amount of time a charger can be offline before generating
                an alert:
              </p>
            </Grid>
            <Grid item xs={3}>
              <Controls.Input
                disabled={!checked?.connectivityAlerts}
                name="alertTimeout"
                value={alertTimeout}
                type="number"
                onChange={(ev) => {
                  setAlertTimeout(ev.target.value);
                }}
                error={errors}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <AccessTimeOutlinedIcon />
                    </InputAdornment>
                  ),
                  endAdornment: (
                    <InputAdornment position="end">minutes</InputAdornment>
                  ),
                }}
              />
            </Grid>
          </Grid>
          <PrivilegedComponent
            permission="Temperature Alert Settings"
            module="chargerHealth"
          >
            <Grid container className="topPadding">
              <p className="title" id="topMargin">
                MAXIMUM CHARGER TEMPERATURE ALERT
              </p>
              <Switch
                checked={checked?.maxChargerTempAlerts}
                onChange={(e) => handleChange(e, "maxChargerTempAlerts")}
                inputProps={{ "aria-label": "controlled" }}
              />
            </Grid>
            <Grid container className="topPadding">
              <p className="title" id="topMargin">
                MINIMUM CHARGER TEMPERATURE ALERT
              </p>
              <Switch
                checked={checked?.minChargerTempAlerts}
                onChange={(e) => handleChange(e, "minChargerTempAlerts")}
                inputProps={{ "aria-label": "controlled" }}
              />
            </Grid>
          </PrivilegedComponent>
          <div className="userAlertSubmit">
            <Controls.Button
              disabled={errors !== ""}
              type="submit"
              text="Apply"
              onClick={handleSubmit}
            />
          </div>
        </Grid>
        <ToastMessage toast={toast} setToast={setToast} />
        <NewCommonFilterDrawer DrawerOC={drawerObj} toggleDrawer={toggleDrawer}>
          <UserAlertSettingsFilter
            depotTypes={depotTypes}
            setDepotTypes={setDepotTypes}
            custAcc={custAccounts}
            setCustAcc={setCustAccounts}
            setIsFiltered={setIsFiltered}
            toggleDrawer={toggleDrawer}
            handleSubmit={handleSubmit}
          />
        </NewCommonFilterDrawer>
      </PageMainContent>
    </>
  );
}
