import React, { useCallback, useMemo, useState } from "react";
import UploadAndPreviewCSV from "../../../components/UploadAndPreviewCSV";
import OpsBreadcrumb from "../../../components/NewBreadcrumbs";
import PageHeader from "../../../components/PageHeader";
import PageMainContent from "../../../components/PageMainContent";
import {
  apiProvider,
  indexedEndPoints,
} from "../../../services/api/utilities/provider";
import Loader from "../../../components/Loader";
import Controls from "../../../components/controls/Controls";
import ToastMessage from "../../../components/ToastMessage";
import { useHistory, useLocation } from "react-router-dom";
import { routePaths } from "../../../constants/RoutePaths";
import BulkUploadSteps from "../BulkUploadSteps";
import "../dataCatalog.scss";

const headers = [
  { key: "model", label: "model" },
  { key: "networkType", label: "networkType" },
  { key: "minChargingRate", label: "minChargingRate" },
  { key: "maxChargingRate", label: "maxChargingRate" },
  { key: "maxAmps", label: "maxAmps" },
  { key: "maxVoltage", label: "maxVoltage" },
  { key: "chargingRateUnit", label: "chargingRateUnit" },
  { key: "numberOfPorts", label: "numberOfPorts" },
  { key: "powerType", label: "powerType" },
  { key: "isRfidCapable", label: "isRfidCapable" },
  { key: "typeOfConnectorList", label: "typeOfConnectorList" },
  { key: "isCustomerVisible", label: "isCustomerVisible" },
  { key: "maxTemperature", label: "maxTemperature" },
  { key: "minTemperature", label: "minTemperature" },
];

const BulkUploadChargers = () => {
  const { CHARGERS } = indexedEndPoints;
  const { tableData, UploadPreviewCSV } = UploadAndPreviewCSV();
  const [loading, setLoading] = useState(false);
  const [toast, setToast] = useState({
    isOpen: false,
    message: "",
    type: "",
  });
  const history = useHistory();
  const search = useLocation().search;
  const searchParams = new URLSearchParams(search);
  const manufacturer = searchParams.get("manufacturer");

  const allBreadcrumbsLinks = useMemo(
    () => [
      {
        link: routePaths.DATA_CATALOG,
        title: "Data Catalog",
      },
      {
        link: `${routePaths.CHARGERS_DASHBOARD}${search}`,
        title: "Chargers Dashboard",
      },
    ],
    []
  );

  const handleBulkUpload = useCallback(async () => {
    const data = tableData?.map((row) => {
      const rowObj = {
        ...row,
        soldAs: [
          { whiteLabelManufacturer: manufacturer, whiteLabelModel: row.model },
        ],
      };
      rowObj.manufacturer = manufacturer;
      if (rowObj?.maxTemperature && rowObj.minTemperature) {
        rowObj.temperatureRange = {
          max: rowObj?.maxTemperature,
          min: rowObj?.minTemperature,
          unit: "celsius",
        };
        delete rowObj.maxTemperature;
        delete rowObj.minTemperature;
      }
      rowObj.typeOfConnectorList = rowObj.typeOfConnectorList
        ?.trim()
        ?.split(";");
      rowObj.isRfidCapable =
        rowObj.isRfidCapable?.trim()?.toLowerCase() === "true";
      rowObj.isCustomerVisible =
        rowObj.isCustomerVisible?.trim()?.toLowerCase() === "false"
          ? false
          : true;
      return rowObj;
    });
    setLoading(true);
    const response = await apiProvider.post(CHARGERS, data);
    setLoading(false);
    if (response.statusCode >= 200 && response.statusCode <= 299) {
      setToast({
        isOpen: true,
        message: "Bulk upload chargers successful",
        type: "success",
      });
      setTimeout(() => history.push(`${routePaths.CHARGERS}${search}`), [3000]);
    } else {
      setToast({
        isOpen: true,
        message: response?.data?.data,
        type: "error",
      });
    }
  }, [tableData]);

  return (
    <>
      <OpsBreadcrumb
        AllBreadcrumbsLinks={allBreadcrumbsLinks}
        title="Bulk Upload Chargers"
      />
      <PageHeader title="Bulk Upload Chargers" />
      <PageMainContent>
        <Loader isLoading={loading} />
        <BulkUploadSteps headers={headers} filename="Chargers.csv" />
        <UploadPreviewCSV headers={headers?.map((item) => item.key)} />
        {tableData?.length > 0 && (
          <div className="bulkUploadSubmit">
            <Controls.Button
              text="Submit"
              type="submit"
              onClick={handleBulkUpload}
            />
          </div>
        )}
      </PageMainContent>
      <ToastMessage toast={toast} setToast={setToast} autoHideDuration={2000} />
    </>
  );
};

export default BulkUploadChargers;
