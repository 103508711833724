import {
  Tooltip,
  ResponsiveContainer,
  PieChart,
  Pie,
  Cell,
  Legend,
} from "recharts";
import { Tooltip as Muitooltip } from "@mui/material";
import "../Analytics.scss";

const ChargerTypeGraph = (props) => {
  const { chargersTypeCount } = props;
  const colors = ["#2770D8", "#23A469", "#FAE100"];
  return (
    <div className="chargerTypeGraph">
      <Muitooltip title={"Charge Ports types across all depots"}>
        <p className="graphTooltip">Charge Port Type Distribution</p>
      </Muitooltip>
      <ResponsiveContainer>
        <PieChart width={730} height={50}>
          <Pie
            cx="50%"
            cy="50%"
            data={chargersTypeCount}
            dataKey="value"
            nameKey="name"
            innerRadius={"55%"}
            paddingAngle={2}
            fill="#8884d8"
            isAnimationActive={false}
          >
            {chargersTypeCount.map((_, index) => (
              <Cell
                key={`cell-${index}`}
                fill={colors[index % colors.length]}
              />
            ))}
          </Pie>
          <Tooltip />
          <Legend
            align="right"
            verticalAlign="middle"
            layout="vertical"
            iconSize="15"
            iconType="circle"
            wrapperStyle={{ margin: "0 0 0 15px" }}
            formatter={(value, entry) => (
              <>
                <span className="graphLegendKey">{`${value} - `}</span>
                <span className="graphLegendValue">{entry.payload.value}</span>
              </>
            )}
          />
        </PieChart>
      </ResponsiveContainer>
    </div>
  );
};
export default ChargerTypeGraph;
