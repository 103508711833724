import React, { useCallback, useEffect, useState, useMemo } from "react";
import { InputAdornment, Toolbar, Tooltip, Zoom } from "@mui/material";
import { makeStyles } from "@mui/styles";
import SearchIcon from "@mui/icons-material/Search";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import Controls from "../../../components/controls/Controls";
import Loader from "../../../components/Loader";
import OpsBreadcrumb from "../../../components/NewBreadcrumbs";
import PageHeader from "../../../components/PageHeader";
import PageMainContent from "../../../components/PageMainContent";
import ToastMessage from "../../../components/ToastMessage";
import useTable from "../../../components/UseTable";
import { routePaths } from "../../../constants/RoutePaths";
import {
  apiProvider,
  indexedEndPoints,
} from "../../../services/api/utilities/provider";
import CommonDrawer from "../../../components/SideDrawer";
import ChargerRegistrationHistoryDrawer from "./ChargerRegistrationHistoryDrawer";
import "../ChargerHealth.scss";

const allBreadcrumbsLinks = [
  {
    link: routePaths.CHARGERHEALTH,
    title: "Charger Health",
  },
  {
    link: routePaths.CHARGERFIRMWAREDETAILS,
    title: "Charger Details",
  },
];

const useStyles = makeStyles((theme) => ({
  "@global": {
    "*::-webkit-scrollbar": {
      width: "0.4em",
    },
    "*::-webkit-scrollbar-track": {
      "-webkit-box-shadow": "inset 0 0 6px rgba(0,0,0,0.00)",
    },
    "*::-webkit-scrollbar-thumb": {
      backgroundColor: "rgba(0,0,0,.1)",
      outline: "0px solid slategrey",
    },
  },
}));

const headCells = [
  { id: "companyName", label: "Company Name" },
  { id: "depotName", label: "Depot Name" },
  { id: "modelName", label: "Model Name" },
  { id: "user", label: "User" },
  {
    id: "status",
    label: "Status",
    render: (row) => {
      let respCode1 =
        row?.responseMessage?.chargerRegistrationToChargerMicroservice
          ?.statusCode;
      let respCode2 = row?.responseMessage?.chargerUpdateToFDC?.statusCode;
      if (
        respCode1 >= 200 &&
        respCode1 <= 299 &&
        respCode2 >= 200 &&
        respCode2 <= 299
      )
        return <div className="chargerRegnSuccess">Successful</div>;
      else return <div className="chargerRegnFailure">Failure</div>;
    },
  },
  { disableSorting: true, id: "action", label: "Action" },
];

export default function ChargerRegistrationHistory() {
  const [loading, setLoading] = useState(false);
  const [filterFn, setFilterFn] = useState({ fn: (items) => items });
  const [data, setData] = useState([]);
  const [toast, setToast] = useState({
    isOpen: false,
    message: "",
    type: "",
  });
  const [drawerObj, setDrawerObj] = useState({
    isOpen: false,
    title: "",
    data: {},
  });
  const toggleDrawer = (openClose, title, item) => {
    setDrawerObj({ isOpen: openClose, title: title, data: item, type: "" });
  };
  const classes = useStyles();
  const { CHARGER_REGISTRATION_HISTORY } = indexedEndPoints;

  useEffect(() => {
    const fetchHistory = async () => {
      setLoading(true);
      const response = await apiProvider.getAll(
        `${CHARGER_REGISTRATION_HISTORY}`
      );
      setLoading(false);
      setData(response?.data?.history);
    };
    fetchHistory();
  }, []);

  const handleSearch = (e) => {
    setFilterFn({
      fn: (items) => {
        if (e.target.value?.trim() === "") return items;
        else
          return items.filter((row) =>
            Object.values(row)?.some((val) =>
              val
                ?.toString()
                ?.toLowerCase()
                ?.includes(e.target.value?.trim().toLowerCase())
            )
          );
      },
    });
  };

  const handleViewMoreInfo = (row) => {
    setDrawerObj({
      isOpen: true,
      title: "Charger Registration Details",
      data: row,
      type: "viewData",
    });
  };

  const actionItems = useMemo(
    () => [
      {
        type: "View More",
        label: "View More",
        icon: () => (
          <Tooltip arrow TransitionComponent={Zoom} title="View More Details">
            <InfoOutlinedIcon fontSize="small" />
          </Tooltip>
        ),
        onClick: handleViewMoreInfo,
      },
    ],
    []
  );

  const {
    tableContainer: TableContainer,
    tableHead: TableHead,
    tableBody: TableBody,
    tablePagination: TablePagination,
  } = useTable(data, headCells, filterFn, false, false, actionItems);

  const ToolbarMemoised = useCallback(
    ({ handleSearch, classes }) => {
      return (
        <Toolbar className="table_toolbar">
          <Controls.Input
            label={"Search"}
            className="searchInput"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
            onChange={handleSearch}
          />
        </Toolbar>
      );
    },
    [data]
  );

  return (
    <>
      <OpsBreadcrumb
        AllBreadcrumbsLinks={allBreadcrumbsLinks}
        title="Charger Registration History"
      />
      <PageHeader title="Charger Registration History" />
      <PageMainContent>
        <ToolbarMemoised handleSearch={handleSearch} classes={classes} />
        <Loader isLoading={loading} />
        <TableContainer>
          <TableHead />
          {TableBody}
        </TableContainer>
        {TablePagination}
      </PageMainContent>
      <ToastMessage toast={toast} setToast={setToast} />
      <CommonDrawer DrawerOC={drawerObj} toggleDrawer={toggleDrawer}>
        <ChargerRegistrationHistoryDrawer
          drawerObj={drawerObj?.data}
          setToast={setToast}
        />
      </CommonDrawer>
    </>
  );
}
