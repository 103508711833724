import React, { useState, useEffect } from "react";
import Map from "./Map";
import MapMarkerActiveDepot from "../../../assets/images/svg/MapMarkerActiveDepot.svg";
import MapMarkerInactiveDepot from "../../../assets/images/svg/MapMarkerInactiveDepot.svg";
import "../Analytics.scss";

export default function DepotMap({
  rawData,
  selectedDepotTypes,
  selectedCountryCodes,
  selectedDepotStatus,
  selectedDepots,
  depotStatusList,
  depotStatus,
}) {
  const [depotsLoaded, setDepotsLoaded] = useState(false);
  const [depotsLocation, setDepotsLocation] = useState([]);

  useEffect(() => {
    let depotLocations = [];
    rawData?.forEach((row) => {
      (!selectedDepotTypes?.length ||
        selectedDepotTypes?.includes(row.depotType)) &&
        (!selectedCountryCodes?.length ||
          selectedCountryCodes?.includes(row?.address?.countryCode)) &&
        (!selectedDepotStatus?.length ||
          (depotStatus[0].children[0].checked === true && row.isActive) ||
          (depotStatus[0].children[1].checked === true &&
            row.isBillingActivated) ||
          (depotStatus[0].children[2].checked === true && row.inactive)) &&
        (!selectedDepots?.length || selectedDepots?.includes(row.depotId)) &&
        depotLocations.push({
          companyId: row?.companyId,
          companyName: row?.companyName,
          depotId: row?.depotId,
          depotName: row?.depotName,
          chargers: row?.commissionedChargers,
          ports: row?.commissionedPorts,
          streetAddress: row?.address?.streetAddress,
          status: !row?.inactive,
          position: {
            lat: parseFloat(row?.address?.latitude),
            lng: parseFloat(row?.address?.longitude),
          },
        });
    });
    setDepotsLocation(depotLocations);
    setDepotsLoaded(true);
  }, [
    rawData,
    selectedDepotTypes,
    selectedCountryCodes,
    selectedDepotStatus,
    selectedDepots,
    depotStatusList,
  ]);

  return (
    <div className="analyticsMap">
      <p className="body_one mapTitle">Ford Pro Charging sites</p>
      {depotsLoaded ? (
        <Map
          allDepotsLocation={depotsLocation}
          selectedDepotTypes={selectedDepotTypes}
        />
      ) : (
        <h1>Loading . . .</h1>
      )}
      <div className="centerAligned">
        <img src={MapMarkerActiveDepot} height="40px" width="40px" />
        <p className="markerLegend">
          Active depots: Depots with commissioned chargers
        </p>
      </div>
      <div className="centerAligned">
        <img src={MapMarkerInactiveDepot} height="40px" width="40px" />
        <p className="markerLegend">
          Inactive depots: Depot is set up but no chargers are commissioned
        </p>
      </div>
    </div>
  );
}
