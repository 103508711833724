import {
  Grid,
  InputAdornment,
  Toolbar,
  Tooltip,
  Zoom,
  Chip,
  Menu,
  MenuItem,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import {
  apiProvider,
  indexedEndPoints,
} from "../../../services/api/utilities/provider";
import SearchIcon from "@mui/icons-material/Search";
import EditOutlined from "@mui/icons-material/EditOutlined";
import DeleteIcon from "@mui/icons-material/Delete";
import ListIcon from "@mui/icons-material/List";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import VerifiedIcon from "@mui/icons-material/Verified";
import { useSelector } from "react-redux";
import useTable from "../../../components/UseTable";
import OpsBreadcrumb from "../../../components/NewBreadcrumbs";
import PageHeader from "../../../components/PageHeader";
import PageMainContent from "../../../components/PageMainContent";
import Loader from "../../../components/Loader";
import { routePaths } from "../../../constants/RoutePaths";
import Controls from "../../../components/controls/Controls";
import Popup from "../../../components/Popup";
import { useHistory, useLocation } from "react-router-dom";
import ConfirmDialog from "../../../components/ConfirmDialog";
import AddEditCharger, { UploadFirmware } from "./AddEditChargers";
import ChargerDrawerDetails from "./ChargerDrawerDetails";
import ToastMessage from "../../../components/ToastMessage";
import { PrivilegedComponent } from "../../../utils/PrivilegedComponent";
import "../dataCatalog.scss";
import GetAppIcon from "@mui/icons-material/GetApp";
import ExportCSV from "../../../components/ExportCSV";
import ComponentLoader from "../../../components/ComponentLoader";
import ChargerDetailDrawer from "../../../components/SideDrawerForChargerDetails";

const allBreadcrumbsLinks = [
  {
    link: routePaths.DATA_CATALOG,
    title: "Data Catalog",
  },
  {
    link: routePaths.CHARGERS_DASHBOARD,
    title: "Chargers Dashboard",
  },
];

const useStyles = makeStyles((theme) => ({
  "@global": {
    "*::-webkit-scrollbar": {
      width: "0.4em",
    },
    "*::-webkit-scrollbar-track": {
      "-webkit-box-shadow": "inset 0 0 6px rgba(0,0,0,0.00)",
    },
    "*::-webkit-scrollbar-thumb": {
      backgroundColor: "rgba(0,0,0,.1)",
      outline: "0px solid slategrey",
    },
  },
}));

const Chargers = () => {
  const [loading, setLoading] = useState(false);
  const [filterFn, setFilterFn] = useState({ fn: (items) => items });
  const [chargerData, setChargerData] = useState([]);
  const [data, setData] = useState([]);
  const [refreshChargers, setRefreshChargers] = useState(true);
  const [openPopup, setOpenPopup] = useState({
    isOpen: false,
    title: "",
    child: "",
    item: {},
  });
  const [confirmDialog, setConfirmDialog] = useState({
    isOpen: false,
    type: "primary",
  });
  const [toast, setToast] = useState({
    isOpen: false,
    message: "",
    type: "",
  });
  const [drawerObj, setDrawerObj] = useState({
    isOpen: false,
    title: "",
    data: {},
  });
  const [drawerOC, setDrawerOC] = useState({
    isOpen: false,
    title: "",
    data: {},
    child: "",
  });

  const componentPrivileges = useSelector(
    (state) => state?.user?.componentPrivilege?.dataCatalogCharger
  );

  const classes = useStyles();

  const history = useHistory();
  const search = useLocation().search;
  const searchParams = new URLSearchParams(search);
  const manufacturer = searchParams.get("manufacturer");
  const [selectedModel, setSelectedModel] = useState("");
  const [modelFirmwares, setModelFirmwares] = useState("");
  const [firmwareChargers, setFirmwareChargers] = useState({});

  const [headers, setHeaders] = React.useState([
    { key: "manufacturer", name: "Manufacturer", checked: false },
    { key: "model", name: "Model", checked: false },
    {
      key: "powerType",
      name: "Power Type",
      checked: false,
    },
    {
      key: "typeOfConnectorList",
      name: "Type Of Connector",
      checked: false,
    },
    {
      key: "isRfidCapable",
      name: "Is Rfid Capable",
      checked: false,
    },
    { key: "firmwareVersions", name: "Firmware Version", checked: false },
    { key: "isCustomerVisible", name: "Is Customer Visible", checked: false },
    { key: "isVerified", name: "Is Verified", checked: false },
    { key: "networkType", name: "Network Type", checked: false },
    { key: "numberOfPorts", name: "Number Of Ports", checked: false },
    { key: "chargingRateUnit", name: "Charging Rate Unit", checked: false },
    { key: "maxChargingRate", name: "Max Charging Rate", checked: false },
    { key: "minChargingRate", name: "Min Charging Rate", checked: false },
    { key: "maxVoltage", name: "Max Voltage", checked: false },
    { key: "maxAmps", name: "Max Amps", checked: false },
    { key: "temperatureRange.max", name: "Max Temperature", checked: false },
    { key: "temperatureRange.min", name: "Min Temperature", checked: false },
  ]);
  const {
    GET_CHARGERS,
    CHARGERS,
    CHARGER_MANUFACTURER_MODELS,
    CHARGER_MODEL_FIRMWARES,
    CHARGER_FIRMWARE,
  } = indexedEndPoints;

  useEffect(() => {
    const fetchChargers = async () => {
      setLoading(true);
      const response = await apiProvider.getAll(
        `${GET_CHARGERS}/${manufacturer}`
      );
      setLoading(false);
      setChargerData(response?.data);
      setRefreshChargers(false);
    };
    refreshChargers && fetchChargers();
  }, [refreshChargers]);

  useEffect(() => {
    const fetchChargerData = async () => {
      setLoading(true);
      const response = await apiProvider.getAll(
        `${CHARGER_MANUFACTURER_MODELS}/${manufacturer}`
      );
      setLoading(false);
      let aggData = [];
      response?.data?.forEach((row) =>
        aggData.push({
          ...chargerData?.find(
            (item) => item.chargerMetaId === row.chargerMetaId
          ),
          ...row,
        })
      );
      setData(aggData);
      setRefreshChargers(false);
    };
    chargerData?.length && fetchChargerData();
  }, [chargerData]);

  const handleSearch = (e) => {
    setFilterFn({
      fn: (items) => {
        if (e.target.value?.trim() === "") return items;
        else
          return items?.filter((row) =>
            Object.values(row)?.some((val) =>
              val
                ?.toString()
                ?.toLowerCase()
                ?.includes(e.target.value?.trim().toLowerCase())
            )
          );
      },
    });
  };

  const headCells = useMemo(() => {
    let hc = [
      {
        id: "vendorModel",
        label: "Model (OEM)",
        render: (row, col) => (
          <span className="centerAligned">
            {row[col]}&nbsp;
            {row?.isVerified && (
              <Tooltip
                arrow
                TransitionComponent={Zoom}
                title="Charger Verified"
              >
                <VerifiedIcon fontSize="small" className="verifiedIcon" />
              </Tooltip>
            )}
          </span>
        ),
      },
      {
        id: "soldAs",
        label: "Sold as manufacturer/model",
        render: (row, col) =>
          row[col]?.map((item) => (
            <div>
              <Chip
                label={`${item.whiteLabelManufacturer} | ${item.whiteLabelModel}`}
                className="soldAsMakeModel"
              />
            </div>
          )),
      },
      { id: "networkType", label: "Network" },
      { id: "powerType", label: "Power" },
      {
        id: "typeOfConnectorList",
        label: "Connector",
        render: (row, col) =>
          row[col]?.map((item, i) => <div key={`connector-${i}`}>{item}</div>),
      },
      {
        id: "firmwareDetails",
        label: "Available Firmwares",
        render: (row, col) =>
          row[col]?.length
            ? row[col]?.map((item) => item.version)?.join(", ")
            : "N/A",
      },
      {
        id: "isRfidCapable",
        label: "RFID capable",
        render: (row, col) => (
          <Chip
            label={row[col] === true ? "True" : "False"}
            style={{
              color: row[col] === true ? "#00765E" : "#D01300",
              background: row[col] === true ? "#E5F1EF" : "#FAE7E5",
            }}
          />
        ),
      },
      { disableSorting: true, id: "action", label: "Action" },
    ];
    return hc;
  }, [componentPrivileges]);

  const allColumnsHeaders = [
    { key: "manufacturer", label: "Manufacturer" },
    { key: "model", label: "Model" },
    {
      key: "powerType",
      label: "Power Type",
    },
    {
      key: "typeOfConnectorList",
      label: "Type Of Connector",
    },
    {
      key: "isRfidCapable",
      label: "Is Rfid Capable",
    },
    { key: "firmwareVersions", label: "Firmware Version" },
    { key: "isCustomerVisible", label: "Is Customer Visible" },
    { key: "isVerified", label: "Is Verified" },
    { key: "networkType", label: "Network Type" },
    { key: "numberOfPorts", label: "Number Of Ports" },

    { key: "chargingRateUnit", label: "Charging Rate Unit" },
    { key: "maxChargingRate", label: "Max Charging Rate" },
    { key: "minChargingRate", label: "Min Charging Rate" },
    { key: "maxVoltage", label: "Max Voltage" },
    { key: "maxAmps", label: "Max Amps" },
    { key: "temperatureRange.max", label: "Max Temperature" },
    { key: "temperatureRange.min", label: "Min Temperature" },
  ];

  const toggleDrawer = (openClose, title, item) => {
    setDrawerObj({ isOpen: openClose, title: title, data: item, type: "" });
  };
  const toggleDrawerOC = (openClose, title, item, child) => {
    setDrawerOC({ isOpen: openClose, title: title, data: item, child: child });
  };

  const handleViewMoreInfo = (row) => {
    setSelectedModel(row.model);
    setModelFirmwares(row.firmwareDetails?.map((item) => item.version));
    setDrawerObj({
      isOpen: true,
      title: "Charger Model Details",
      data: row,
      type: "viewData",
    });
  };

  useEffect(() => {
    const fetchFirmwareChargers = async () => {
      setLoading(true);
      const response = await apiProvider.getAll(
        `${CHARGER_MODEL_FIRMWARES}/${manufacturer}/${JSON.stringify(
          modelFirmwares
        )}`
      );
      setLoading(false);
      setFirmwareChargers(response?.data);
    };
    selectedModel?.length && modelFirmwares?.length && fetchFirmwareChargers();
  }, [selectedModel, modelFirmwares]);

  const handleDeleteFirmware = (chargers, version, chargerMetaId) => {
    if (chargers?.length)
      setOpenPopup({
        isOpen: true,
        title:
          "Firmware version cannot be removed from the charger catalog while there are chargers on this version",
        child: "firmwareChargers",
        item: { version, chargers },
      });
    else
      setConfirmDialog({
        ...confirmDialog,
        isOpen: true,
        title: "Are you sure you want to remove this firmware version ?",
        subTitle: `This firmware version will no longer be available for ${manufacturer} - ${selectedModel}`,
        type: "secondary",
        onConfirm: () => deleteFirmware(version, chargerMetaId),
      });
  };

  const deleteFirmware = useCallback(async (version, chargerMetaId) => {
    setLoading(true);
    const response = await apiProvider.del(CHARGER_FIRMWARE, [
      {
        version,
        chargerMetaId,
      },
    ]);
    setLoading(false);
    if (response.statusCode >= 200 && response.statusCode <= 299) {
      toggleDrawer(false, "", {});
      setToast({
        isOpen: true,
        message: "Firmware deleted successfully",
        type: "success",
      });
      setConfirmDialog({ ...confirmDialog, isOpen: false });
      setRefreshChargers(true);
    } else {
      setToast({
        isOpen: true,
        message: "Firmware deletion failed",
        type: "error",
      });
    }
  }, []);

  const handleEditCharger = (row) => {
    setDrawerOC({
      isOpen: true,
      title: "Update Charger",
      child: "updateCharger",
      item: row,
    });
  };

  const handleDeleteCharger = (row) => {
    setConfirmDialog({
      ...confirmDialog,
      isOpen: true,
      title: "Are you sure ?",
      subTitle: "You want to Delete this Charger",
      type: "secondary",
      onConfirm: () => deleteCharger(row.chargerMetaId),
    });
  };
  const deleteCharger = useCallback(async (chargerMetaId) => {
    setLoading(true);
    const response = await apiProvider.del(`${CHARGERS}/${chargerMetaId}`);
    setLoading(false);
    if (response.statusCode >= 200 && response.statusCode <= 299) {
      setToast({
        isOpen: true,
        message: "Charger deleted successfully",
        type: "success",
      });
      setConfirmDialog({ ...confirmDialog, isOpen: false });
      setRefreshChargers(true);
    } else {
      setToast({
        isOpen: true,
        message: "Charger deletion failed",
        type: "error",
      });
    }
  }, []);

  const actionItems = useMemo(() => {
    let actions = [
      {
        type: "View More",
        label: "View More",
        testid: "viewMoreChargerModelBtn",
        icon: () => (
          <Tooltip arrow TransitionComponent={Zoom} title="View More Details">
            <InfoOutlinedIcon fontSize="small" />
          </Tooltip>
        ),
        onClick: handleViewMoreInfo,
      },
    ];
    // if (componentPrivileges?.includes("Update Charger"))
    //   actions.push({
    //     type: "Edit",
    //     label: "Edit",
    //     icon: () => (
    //       <Tooltip arrow TransitionComponent={Zoom} title="Edit">
    //         <EditOutlined fontSize="small" />
    //       </Tooltip>
    //     ),
    //     onClick: handleEditCharger,
    //   });
    if (componentPrivileges?.includes("Delete Charger"))
      actions.push({
        type: "Delete",
        label: "Delete",
        icon: () => (
          <Tooltip arrow TransitionComponent={Zoom} title="Delete">
            <DeleteIcon fontSize="small" />
          </Tooltip>
        ),
        onClick: handleDeleteCharger,
      });
    actions.push({
      type: "View Integration Test Report",
      label: "View Integration Test Report",
      icon: () => (
        <Tooltip
          arrow
          TransitionComponent={Zoom}
          title="View Integration Test Report"
        >
          <ListIcon fontSize="small" />
        </Tooltip>
      ),
      onClick: (row) =>
        history.push(
          `${routePaths.INTEGRATION_TEST_REPORT}?chargerId=${
            row.chargerMetaId
          }&manufacturer=${manufacturer}&model=${row.model}&firmware=${
            row.firmwareVersion || ""
          }`
        ),
    });
    return actions?.length > 0 ? actions : null;
  }, [componentPrivileges]);

  const {
    tableContainer: TableContainer,
    tableHead: TableHead,
    tableBody: TableBody,
    tablePagination: TablePagination,
    searchData: searchData,
  } = useTable(data, headCells, filterFn, false, false, actionItems);
  const ToolbarMemoised = useCallback(
    ({ handleSearch, classes, loading, data }) => {
      const [anchorEl, setAnchorEl] = React.useState(null);
      const open = Boolean(anchorEl);
      const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
      };
      const handleClose = () => {
        setAnchorEl(null);
      };

      return (
        <Toolbar className="table_toolbar">
          <Controls.Input
            label={"Search"}
            className="searchInput"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
            onChange={handleSearch}
          />
          <Grid sm item />
          <Grid>
            <ComponentLoader isLoading={loading}>
              <Controls.Button
                text="Download"
                variant="outlined"
                disabled={data.length === 0}
                startIcon={<GetAppIcon className="chdDownloadIcon" />}
                onClick={() =>
                  setOpenPopup({
                    isOpen: true,
                    title: "Export CSV",
                    child: "exportCSV",
                  })
                }
              />
            </ComponentLoader>
          </Grid>
          <PrivilegedComponent
            permission="Upload Firmware"
            module="dataCatalogCharger"
          >
            <Controls.Button
              text="Upload Firmware"
              variant="outlined"
              startIcon={<ArrowUpwardIcon />}
              onClick={() =>
                setOpenPopup({
                  isOpen: true,
                  title: "Upload Firmware",
                  child: "uploadFirmware",
                  item: {},
                })
              }
            />
          </PrivilegedComponent>
          <PrivilegedComponent
            permission="Add Charger"
            module="dataCatalogCharger"
          >
            <Controls.Button
              text="Add Model"
              variant="outlined"
              testid="addChargerModelBtn"
              startIcon={<AddCircleOutlineIcon />}
              id="add-charger-model"
              aria-controls={open ? "add-charger-model-menu" : undefined}
              aria-haspopup="true"
              aria-expanded={open ? "true" : undefined}
              onClick={handleClick}
            />
            <Menu
              id="add-charger-model-menu"
              MenuListProps={{
                "aria-labelledby": "add-charger-model",
              }}
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
            >
              <MenuItem
                onClick={() => {
                  setDrawerOC({
                    isOpen: true,
                    title: "Add Charger",
                    child: "addCharger",
                    item: {},
                  });
                  handleClose();
                }}
              >
                Add a single model
              </MenuItem>
              <PrivilegedComponent
                permission="Bulk Upload"
                module="dataCatalogCharger"
              >
                <MenuItem
                  onClick={() => {
                    history.push(`${routePaths.BULK_UPLOAD_CHARGERS}${search}`);
                    handleClose();
                  }}
                >
                  Bulk upload models
                </MenuItem>
              </PrivilegedComponent>
            </Menu>
          </PrivilegedComponent>
        </Toolbar>
      );
    },
    [data]
  );

  const formattingData = (data) => {
    const formattedChargers = data.map((charger) => {
      // Convert typeOfConnectorList array to a single string
      const connectorList = charger?.typeOfConnectorList?.join(",");

      // Extract firmware versions and join them into a single string
      const firmwareVersions = charger?.firmwareDetails
        ?.map((firmware) => firmware.version)
        ?.join(",");

      return {
        ...charger,
        typeOfConnectorList: connectorList,
        firmwareVersions: firmwareVersions,
      };
    });

    return formattedChargers;
  };

  return (
    <>
      <OpsBreadcrumb
        AllBreadcrumbsLinks={allBreadcrumbsLinks}
        title="Chargers"
      />
      <PageHeader title="Chargers" />
      <PageMainContent>
        <div className="company_details" id="chargerManufacturerClipboard">
          <Grid container>
            <Grid item xs={5}>
              <div className="single_details">
                <p className="title">Manufacturer (OEM)</p>
                <Controls.CopyToClipboard
                  name={manufacturer}
                  setToast={setToast}
                />
              </div>
            </Grid>
          </Grid>
        </div>
        <ToolbarMemoised
          handleSearch={handleSearch}
          classes={classes}
          setOpenPopup={setOpenPopup}
          loading={loading}
          data={searchData}
        />
        <Loader isLoading={loading} />
        <TableContainer>
          <TableHead />
          {TableBody}
        </TableContainer>
        {TablePagination}
      </PageMainContent>
      <Popup openPopup={openPopup} setOpenPopup={setOpenPopup}>
        {openPopup.child === "uploadFirmware" ? (
          <UploadFirmware
            setOpenPopup={setOpenPopup}
            setRefreshChargers={setRefreshChargers}
            setToast={setToast}
            data={data}
          />
        ) : openPopup.child === "firmwareChargers" ? (
          <div>
            <p>
              The following chargers are still on version{" "}
              {openPopup.item?.version}
            </p>
            <ul>
              {openPopup.item?.chargers?.slice(0, 10)?.map((item, i) => (
                <li key={`charger-${i}`}>{item}</li>
              ))}
            </ul>
            {openPopup.item?.chargers?.length > 10 && (
              <p>+ {openPopup.item?.chargers?.length - 10} more</p>
            )}
            <div className="endTextAligned">
              <Controls.Button
                text="Back"
                color="primary"
                onClick={() => setOpenPopup({ ...openPopup, isOpen: false })}
              />
            </div>
          </div>
        ) : openPopup.child === "exportCSV" ? (
          <ExportCSV
            setOpenPopup={setOpenPopup}
            data={formattingData(searchData)}
            allColumnsHeaders={allColumnsHeaders}
            headers={headers}
            setHeaders={setHeaders}
            fileName={"Chargers.csv"}
          />
        ) : (
          ""
        )}
      </Popup>
      {confirmDialog.isOpen && (
        <ConfirmDialog
          confirmDialog={confirmDialog}
          setConfirmDialog={setConfirmDialog}
        />
      )}
      <ToastMessage toast={toast} setToast={setToast} />
      <ChargerDetailDrawer
        DraweChargerDetails={drawerObj}
        toggleDrawer={toggleDrawer}
      >
        <ChargerDrawerDetails
          drawerObj={drawerObj?.data}
          firmwareChargers={firmwareChargers}
          handleDeleteFirmware={handleDeleteFirmware}
        />
      </ChargerDetailDrawer>
      <ChargerDetailDrawer
        DraweChargerDetails={drawerOC}
        toggleDrawer={toggleDrawerOC}
      >
        <AddEditCharger
          type={drawerOC.child}
          openPopup={drawerOC}
          setOpenPopup={setDrawerOC}
          setRefreshChargers={setRefreshChargers}
          setToast={setToast}
          chargerId={
            drawerOC.child === "updateCharger"
              ? drawerOC.item.chargerMetaId
              : null
          }
        />
      </ChargerDetailDrawer>
    </>
  );
};

export default Chargers;
