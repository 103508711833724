import { applyMiddleware, createStore, compose } from "redux";
import thunk from "redux-thunk";
import reducers from "./reducers";

const configureStore = () => {
  const composedEnhancer = compose(
    applyMiddleware(thunk)
  );

  return createStore(reducers, {}, composedEnhancer);
};

export default configureStore;
