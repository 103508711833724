import Input from "./Input";
import TextArea from "./TextArea";
import Select from "./Select";
import AutoSuggestion from "./AutoSuggestion";
import AddressInput from "./AddressInput";
import Button from "./Button";
import ActionButton from "./ActionButton";
import MultiSelect from "./MultiSelectWithTypography";
import CopyToClipboard from "./CopyToClipboard";
import RadioButtons from "./RadioButtons";
import NewCopyToClipboard from "./CopyToClipboardNew";
import NewSelect from "./NewSelect";

const Controls = {
  Input,
  Select,
  AutoSuggestion,
  AddressInput,
  Button,
  ActionButton,
  TextArea,
  MultiSelect,
  CopyToClipboard,
  NewCopyToClipboard,
  RadioButtons,
  NewSelect,
};

export default Controls;
