import React from "react";
import { useDispatch } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import { Grid, Paper, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import Loader from "../../components/Loader";
import Controls from "../../components/controls/Controls";
import apiUsers from "../../services/api/Users";
import { addUser } from "../../redux-state/actions/UserAction";
import { UseForm, Form } from "../../components/UseForm";
import { setData } from "../../utils/Storage";
import { messages } from "../../constants/Messages";
import { validateEmail } from "../../utils/FieldValidations";
import { variables } from "../../constants/Variables";
import ElectriphiLoginLogo from "../../assets/images/png/ford-logo-dark.png";

import "./login.scss";
import LoaderForLazy from "../../components/LoaderForLazy";
const useStyles = makeStyles(() => ({
  paper: {
    padding: 20,
    width: 350,
    margin: "100px auto",
  },
  logo: {
    margin: "1em 20px",
  },
  loginButton: {
    margin: "2em 0px",
  },
}));

const initialFieldValues = {
  email: "",
  password: "",
};

const Login = (props) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [message, setMessage] = React.useState("");
  const [isLoading, setLoading] = React.useState(false);
  const history = useHistory();

  const validate = (fieldValues = values) => {
    let temp = { ...errors };
    if ("email" in fieldValues)
      temp.email = fieldValues.email
        ? validateEmail(fieldValues.email)
          ? ""
          : messages.INVALID_EMAIL
        : messages.FIELD_REQUIRED;
    if ("password" in fieldValues)
      temp.password = fieldValues.password ? "" : messages.FIELD_REQUIRED;

    setErrors({ ...temp });
    if (fieldValues === values)
      return Object.values(temp).every((x) => x === "");
    else return false;
  };

  const { values, errors, setErrors, handleFormInputChange } = UseForm(
    initialFieldValues,
    true,
    validate
  );

  const handleSubmit = async (e) => {
    setLoading(true);
    e.preventDefault();
    let userData, roleData;
    if (validate()) {
      const userResponse = await apiUsers.login({
        username: values.email,
        password: values.password,
      });
      if (userResponse.code === 200) {
        const { data } = userResponse;
        userData = data;
        setData(variables.ACCESS_TOKEN, userData.accessToken);
        if (userResponse.data) {
          const rolePrivilegeResponse = await apiUsers.roleAllPrivilege(
            userResponse.data.roleId
          );
          // Once we receive role preivileges for the user then store everything in redux and local storage
          if (rolePrivilegeResponse.code === 200) {
            const { data } = rolePrivilegeResponse;
            roleData = data;
            setData(variables.USER_FIRST_NAME, userData.firstname);
            setData(variables.USER_LAST_NAME, userData.lastname);
            setData(variables.USER_EMAIL, userData.email);
            setData(variables.USER_PHONE_NUMBER, userData.phoneNumber);
            setData(variables.USER_ROLE, userData.roleName);
            setData(variables.USER_ROLE_ID, userData.roleId);
            setData(variables.IS_USER_LOGGED_IN, true);
            setData(
              variables.USER_COMPONENT_PRIVILEGE,
              JSON.stringify(roleData.componentPrivilege)
            );
            if (localStorage.getItem("redirectUri")) {
              history.push(localStorage.getItem("redirectUri"));
              localStorage.removeItem("redirectUri");
            }
            dispatch(
              addUser({
                accessToken: userData.accessToken,
                userFirstName: userData.firstname,
                userLastName: userData.lastname,
                userEmail: userData.email,
                userPhoneNumber: userData.phoneNumber,
                userRole: userData.roleName,
                userRoleId: userData.roleId,
                componentPrivilege: roleData.componentPrivilege,
                rolePrivilege: roleData.rolePrivilege,
              })
            );
            props.setIsLoggedIn(true);
          } else {
            setMessage(
              "Could not load role privileges for this user. Please contact admin"
            );
          }
        }
      } else if (userResponse.code === 401) {
        setMessage("Invalid Email or Password");
        setLoading(false);
        return;
      } else if (userResponse.code === 400) {
        setMessage("Email not registered");
        setLoading(false);
        return;
      } else {
        setLoading(false);
        setMessage("Invalid Email or Password");
      }
    }
    setLoading(false);
  };

  return (
    <Grid>
      {/* {<Loader isLoading={isLoading} />}  */}
      {<LoaderForLazy isLoading={isLoading} />}
      <Paper elevation={10} className={classes.paper}>
        <Grid align="center" className={classes.logo}>
          <img
            style={{ width: "100%" }}
            alt="Electriphi Login Logo"
            src={ElectriphiLoginLogo}
          />
        </Grid>
        <Form onSubmit={handleSubmit} data-testid="login-screen">
          <Controls.Input
            name="email"
            label="Email"
            value={values.email}
            error={errors.email}
            onChange={handleFormInputChange()}
            required
            autoComplete="username"
            testid="login-email"
          />
          <Controls.Input
            name="password"
            label="Password"
            value={values.password}
            onChange={handleFormInputChange()}
            error={errors.password}
            type="password"
            required
            autoComplete="current-password"
            testid="login-password"
          />
          <Typography align="center" variant="subtitle2" color="error">
            {message && message}
          </Typography>
          <Grid className={classes.loginButton}>
            <Controls.Button
              color="primary"
              text="Login"
              type="submit"
              fullWidth
              data-testid="login-btn"
            />
          </Grid>
          <div className="forgotPassword">
            <Link to="/forgot-password-email" className="caption">
              Forgot password?
            </Link>
          </div>
        </Form>
      </Paper>
    </Grid>
  );
};

export default Login;
