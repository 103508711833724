import React, { useCallback, useEffect, useMemo, useState } from "react";
import UploadAndPreviewCSV from "../../../components/UploadAndPreviewCSV";
import OpsBreadcrumb from "../../../components/NewBreadcrumbs";
import PageHeader from "../../../components/PageHeader";
import PageMainContent from "../../../components/PageMainContent";
import {
  apiProvider,
  indexedEndPoints,
} from "../../../services/api/utilities/provider";
import Loader from "../../../components/Loader";
import Controls from "../../../components/controls/Controls";
import ToastMessage from "../../../components/ToastMessage";
import { useHistory, useLocation } from "react-router-dom";
import { routePaths } from "../../../constants/RoutePaths";
import BulkUploadSteps from "../BulkUploadSteps";

const headers = [
  { key: "model", label: "model" },
  { key: "batteryCapacity", label: "batteryCapacity" },
  { key: "efficiency", label: "efficiency" },
];

const BulkUploadVehicles = () => {
  const { VEHICLES } = indexedEndPoints;
  const { tableData, setTableData, setErrors, UploadPreviewCSV } =
    UploadAndPreviewCSV();
  const [loading, setLoading] = useState(false);
  const [toast, setToast] = useState({
    isOpen: false,
    message: "",
    type: "",
  });
  const history = useHistory();
  const search = useLocation().search;
  const searchParams = new URLSearchParams(search);
  const manufacturer = searchParams.get("manufacturer");

  const allBreadcrumbsLinks = useMemo(
    () => [
      {
        link: routePaths.DATA_CATALOG,
        title: "Data Catalog",
      },
      {
        link: `${routePaths.VEHICLES_DASHBOARD}${search}`,
        title: "Vehicles Dashboard",
      },
    ],
    []
  );

  useEffect(() => {
    if (!tableData?.length) return;
    const validRows = tableData?.filter(
      (row) =>
        row.model?.length > 0 &&
        row.batteryCapacity?.length > 0 &&
        !isNaN(row.batteryCapacity) &&
        row.efficiency?.length > 0 &&
        !isNaN(row.efficiency)
    );
    if (validRows?.length < tableData?.length) {
      setErrors(
        `${
          tableData?.length - validRows?.length
        } rows are missing mandatory data or have been incorrectly formatted. Please correct and re-select the file or submit the valid rows.`
      );
      setTableData(validRows);
    }
    if (!validRows?.length) setTableData([]);
  }, [tableData]);

  const handleBulkUpload = useCallback(async () => {
    const data = tableData?.map((row) => {
      const rowObj = { ...row };
      rowObj.manufacturer = manufacturer;
      return rowObj;
    });
    setLoading(true);
    const response = await apiProvider.post(VEHICLES, data);
    setLoading(false);
    if (response.statusCode >= 200 && response.statusCode <= 299) {
      setToast({
        isOpen: true,
        message: "Bulk upload vehicles successful",
        type: "success",
      });
      setTimeout(() => history.push(`${routePaths.VEHICLES}${search}`), [3000]);
    } else {
      setToast({
        isOpen: true,
        message: response?.data?.data,
        type: "error",
      });
    }
  }, [tableData]);

  return (
    <>
      <OpsBreadcrumb
        AllBreadcrumbsLinks={allBreadcrumbsLinks}
        title="Bulk Upload Vehicles"
      />
      <PageHeader title="Bulk Upload Vehicles" />
      <PageMainContent>
        <Loader isLoading={loading} />
        <BulkUploadSteps headers={headers} filename="Vehicles.csv" />
        <UploadPreviewCSV
          headers={headers?.map((item) => item.key)}
          showErrorsWithPreview={true}
        />
        {tableData?.length > 0 && (
          <div className="bulkUploadSubmit">
            <Controls.Button
              disabled={!tableData?.length}
              text="Submit"
              type="submit"
              onClick={handleBulkUpload}
            />
          </div>
        )}
      </PageMainContent>
      <ToastMessage toast={toast} setToast={setToast} autoHideDuration={2000} />
    </>
  );
};

export default BulkUploadVehicles;
