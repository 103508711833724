import React from "react";
import { useLocation } from "react-router-dom";
import queryString from "query-string";
import { Grid, Paper, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import Loader from "../../components/Loader";
import Controls from "../../components/controls/Controls";

import { UseForm, Form } from "../../components/UseForm";
import { messages } from "../../constants/Messages";
import ElectriphiLoginLogo from "../../assets/images/png/ford-logo-dark.png";
import apiUsers from "../../services/api/Users";

const useStyles = makeStyles(() => ({
  paper: {
    padding: 20,
    width: 350,
    margin: "100px auto",
  },
  logo: {
    margin: "1em 20px",
  },
  loginButton: {
    margin: "2em 0px",
  },
}));

const initialFieldValues = {
  //   email: "",
  password: "",
  repassword: "",
};

const ForgotPassword = (props) => {
  const classes = useStyles();
  const location = useLocation();
  const queryParam = queryString.parse(location.search);
  const [isLoading, setLoading] = React.useState(false);
  const [passwordChanged, setpasswordChanged] = React.useState(false);
  const [message, setMessage] = React.useState("");
  const [isValid, setIsValid] = React.useState(false);
  const validate = (fieldValues = values) => {
    let temp = { ...errors };
    if ("password" in fieldValues)
      temp.password = fieldValues.password ? "" : messages.FIELD_REQUIRED;
    if ("repassword" in fieldValues) {
      temp.repassword = fieldValues.repassword ? "" : messages.FIELD_REQUIRED;
    }

    if ("password" in fieldValues && "repassword" in fieldValues) {
      temp.reCheck =
        fieldValues.password === fieldValues.repassword
          ? ""
          : messages.BOTH_PASSWORD_SAME;
      if (temp.reCheck === "") {
        setMessage("");
      } else {
        setMessage("Both Should be Same");
      }
    }

    setErrors({ ...temp });
    if (fieldValues === values)
      return Object.values(temp).every((x) => x === "");
    else return false;
  };

  const { values, errors, setErrors, handleFormInputChange } = UseForm(
    initialFieldValues,
    true,
    validate
  );

  const handleBackToLogin = () => {
    window.location.href = "/";
  };

  const handleSubmit = () => {
    const { email } = queryParam;
    if (validate()) {
      setLoading(true);
      apiUsers
        .forgotPasswordUpdate({ email, newPassword: values.password })
        .then((res) => {
          setLoading(false);
          setpasswordChanged(true);
        })
        .catch((error) => {
          setLoading(false);
        });
    }
  };

  React.useEffect(() => {
    // loding = true
    setLoading(true);
    setpasswordChanged(false);
    const { email, pt } = queryParam;
    apiUsers
      .forgotPasswordVerifyToken({ email, pt })
      .then((res) => {
        if (res.code === 200) {
          setIsValid(true);
        } else {
          setIsValid(false);
        }
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        setLoading(false);
      });
  }, []);

  return (
    <Grid>
      {<Loader isLoading={isLoading} />}
      <Paper elevation={10} className={classes.paper}>
        <Grid align="center" className={classes.logo}>
          <img
            style={{ width: "100%" }}
            alt="Electriphi Login Logo"
            src={ElectriphiLoginLogo}
          />
        </Grid>
        {isValid === true ? (
          passwordChanged === false ? (
            <>
              {" "}
              <Controls.Input
                name="password"
                label="Password"
                value={values.password}
                onChange={handleFormInputChange()}
                error={errors.password}
                type="password"
                required
                style={{ margin: "0 0 20px 0" }}
                autoComplete="current-password"
                fullWidth
              />
              <Controls.Input
                name="repassword"
                label="Re-type Password"
                value={values.repassword}
                onChange={handleFormInputChange()}
                error={errors.repassword}
                type="password"
                required
                autoComplete="current-password"
                fullWidth
              />
              <Grid className={classes.loginButton}>
                <Controls.Button
                  color="primary"
                  text="Reset Password"
                  onClick={handleSubmit}
                  fullWidth
                />
              </Grid>
              <Typography align="center" variant="subtitle2" color="error">
                {message != "" && message}
              </Typography>
            </>
          ) : (
            <>
              <Grid align="center">
                <p>
                  Password Changed Successfully Click Below to Redirect on Login
                  Page.
                </p>
              </Grid>
              <Grid className={classes.loginButton}>
                <Controls.Button
                  color="primary"
                  text="Back To Login"
                  onClick={handleBackToLogin}
                  fullWidth
                />
              </Grid>
            </>
          )
        ) : (
          <>
            <Grid align="center">
              <p>Your Session Is Expired or Invalid Please Try Again</p>
            </Grid>
            <Grid className={classes.loginButton}>
              <Controls.Button
                color="primary"
                text="Back To Login"
                onClick={handleBackToLogin}
                fullWidth
              />
            </Grid>
          </>
        )}
      </Paper>
    </Grid>
  );
};

export default ForgotPassword;
