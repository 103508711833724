export const messages = {
  FIELD_REQUIRED: "This field is required",
  BOTH_PASSWORD_SAME: "Both Password Should Be same",
  INVALID_EMAIL: "Please enter a valid email",
  INVALID_NUMBER: "Please enter a valid contact number",
  ACCOUNT_ID_EXISTS: "Account ID already exits",
  INVALID_NAME: "Please enter a valid name",
  INVALID_COUNTRY_CODE: "Please enter a valid country code",
  PASSWORD_MISSMATCH: "Passwords are not matching",
  INTERNAL_SERVER_ERROR: "Internal Server Error",
  FIELD_CANNOT_BE_NEGATIVE: "Invalid Quantity",
};
