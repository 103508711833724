import {
  apiProvider,
  indexedEndPoints,
} from "../../services/api/utilities/provider";

const { LOAD_MONITOR_GET_CIRCUIT, GET_LOAD_MONITOR } = indexedEndPoints;

export const getAllCircuits = async (id) =>
  await apiProvider.getAll(`${LOAD_MONITOR_GET_CIRCUIT}/${id}/circuit`);

export const getDepotMaxValues = async (id) =>
  await apiProvider.getAll(`${LOAD_MONITOR_GET_CIRCUIT}/${id}/max-load`);

export const getLoadMonitoring = async (payload = {}) =>
  await apiProvider.post(GET_LOAD_MONITOR, payload);

export default {
  getAllCircuits,
  getLoadMonitoring,
  getDepotMaxValues,
};
