import * as React from "react";
import { InputAdornment } from "@mui/material";

import SearchIcon from "@mui/icons-material/Search";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import { Checkbox, Autocomplete, TextField } from "@mui/material";
import Controls from "../controls/Controls";

const ChargerMakeAndModelFilter = (props) => {
  let { _allMakes, setFuntion } = props;
  const [searchText, setSearchText] = React.useState("");
  const [allMakes, setAllMakes] = React.useState([]);

  React.useEffect(() => {
    if (_allMakes.length) {
      for (var i = 0; i < _allMakes.length; i++) {
        _allMakes[i].index = i;
        for (var j = 0; j < _allMakes[i].children.length; j++) {
          _allMakes[i].children[j].index = j;
          for (var k = 0; k < _allMakes[i].children[j].chargers.length; k++) {
            _allMakes[i].children[j].chargers[k].index = k;
          }
        }
      }
      setFuntion([..._allMakes]);
    }
  }, []);

  React.useEffect(() => {
    if (_allMakes.length) {
      let filtered = [];
      const _allMakes_ = JSON.parse(JSON.stringify(_allMakes));

      for (const name of _allMakes_[0].children) {
        const matchedChargers = name.chargers?.filter((models) =>
          models.model.toLowerCase().includes(searchText.toLowerCase())
        );
        const matchedName = name.name
          .toLowerCase()
          .includes(searchText.toLowerCase());
        if (matchedName) {
          filtered.push(name);
        } else if (matchedChargers.length) {
          filtered.push({
            ...name,
            chargers: matchedChargers,
          });
        }
      }

      setAllMakes([
        {
          ..._allMakes_[0],
          children: filtered,
        },
      ]);
    }
  }, [searchText, setFuntion, _allMakes]);

  const expandCollapse = (
    parentInd,
    firstChildIndex = null,
    secondChildIndex = null
  ) => {
    if (secondChildIndex !== null) {
      _allMakes[parentInd].children[firstChildIndex].chargers[
        secondChildIndex
      ].isExpanded =
        !_allMakes[parentInd].children[firstChildIndex].chargers[
          secondChildIndex
        ].isExpanded;
      setFuntion([..._allMakes]);
    } else if (firstChildIndex !== null) {
      _allMakes[parentInd].children[firstChildIndex].isExpanded =
        !_allMakes[parentInd].children[firstChildIndex].isExpanded;
      setFuntion([..._allMakes]);
    } else {
      _allMakes[parentInd].isExpanded = !_allMakes[parentInd].isExpanded;
      setFuntion([..._allMakes]);
    }
  };

  const parentClicked = (accountIndex, visAccountIndex) => {
    const isChecked = !_allMakes[accountIndex].checked;
    _allMakes[accountIndex].checked = isChecked;

    for (let j = 0; j < allMakes[visAccountIndex].children.length; j++) {
      const j_index = allMakes[visAccountIndex].children[j].index;
      _allMakes[accountIndex].children[j_index].checked = isChecked;

      for (
        let k = 0;
        k < allMakes[visAccountIndex].children[j].chargers.length;
        k++
      ) {
        const k_index = allMakes[visAccountIndex].children[j].chargers[k].index;
        _allMakes[accountIndex].children[j_index].chargers[k_index].checked =
          isChecked;
      }
    }
    setFuntion([..._allMakes]);
  };

  const childClicked = (
    accountIndex,
    visAccountIndex,
    companyIndex,
    visCompanyIndex
  ) => {
    const isChecked = !_allMakes[accountIndex].children[companyIndex].checked;
    _allMakes[accountIndex].children[companyIndex].checked = isChecked;
    _allMakes[accountIndex].checked = _allMakes[accountIndex].children.every(
      (company) => company.checked
    );

    for (
      let k = 0;
      k < allMakes[visAccountIndex].children[visCompanyIndex].chargers.length;
      k++
    ) {
      const k_index =
        allMakes[visAccountIndex].children[visCompanyIndex].chargers[k].index;
      _allMakes[accountIndex].children[companyIndex].chargers[k_index].checked =
        isChecked;
    }
    setFuntion([..._allMakes]);
  };

  const grandChildClicked = (
    accountIndex,
    visAccountIndex,
    companyIndex,
    visCompanyIndex,
    depotIndex,
    visDepotIndex
  ) => {
    _allMakes[accountIndex].children[companyIndex].chargers[
      depotIndex
    ].checked =
      !_allMakes[accountIndex].children[companyIndex].chargers[depotIndex]
        .checked;

    _allMakes[accountIndex].children[companyIndex].checked = _allMakes[
      accountIndex
    ].children[companyIndex].chargers.every((model) => model.checked);
    _allMakes[accountIndex].checked = _allMakes[accountIndex].children.every(
      (company) => company.checked
    );
    setFuntion([..._allMakes]);
  };

  const handleSearch = (e) => {
    let val = e.target.value;
    setSearchText(e.target.value?.trim() ?? "");
  };

  return (
    <>
      <div className="filter_Search">
        <Controls.Input
          label={"Search"}
          id="charger_make_and_model_search"
          style={{ width: "100%" }}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
          }}
          onChange={handleSearch}
        />
      </div>
      <div>
        {allMakes[0]?.children.length > 0 ? (
          allMakes.length > 0 &&
          allMakes.map((acc, index) => (
            <div
              className="side_filter_new"
              key={`allMakes-${index}`}
              style={{
                border: "1px solid #DBE3EA",
                marginBottom: "15px",
                borderRadius: "2px",
              }}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-start",
                  alignItems: "center",
                }}
                className="filter_parent"
              >
                <Checkbox
                  checked={acc.checked}
                  id="charger_make_and_model_name"
                  indeterminate={
                    _allMakes[0]?.children?.length >
                      _allMakes[0]?.children?.filter((make) => make.checked)
                        ?.length &&
                    _allMakes[0]?.children?.some((make) => make.checked)
                  }
                  onClick={() => parentClicked(acc.index, index)}
                  inputProps={{ "aria-label": "primary checkbox" }}
                />
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    width: "100%",
                    cursor: "pointer",
                  }}
                  onClick={() => expandCollapse(acc.index)}
                >
                  <p className="parent_p">{acc.name}</p>
                  {acc.isExpanded === false ? (
                    <ExpandMoreIcon className="accordion_arrows" />
                  ) : (
                    <ExpandLessIcon className="accordion_arrows" />
                  )}
                </div>
              </div>

              {acc.isExpanded && (
                <>
                  {acc.children &&
                    acc.children.length > 0 &&
                    acc.children.map((acch, ind) => (
                      <div key={ind} style={{ border: "1px solid #DBE3EA" }}>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "flex-start",
                            alignItems: "center",
                            cursor: "pointer",
                          }}
                          className="filter_child"
                        >
                          <Checkbox
                            checked={acch.checked}
                            id={"make_" + acch?.name}
                            indeterminate={
                              _allMakes[0]?.children?.[acch.index]?.chargers
                                ?.length >
                                _allMakes[0]?.children?.[
                                  acch.index
                                ]?.chargers?.filter((make) => make.checked)
                                  ?.length &&
                              _allMakes[0]?.children?.[
                                acch.index
                              ]?.chargers?.some((make) => make.checked)
                            }
                            onClick={() =>
                              childClicked(acc.index, index, acch.index, ind)
                            }
                            inputProps={{ "aria-label": "primary checkbox" }}
                          />
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                              alignItems: "center",
                              width: "100%",
                            }}
                            className={acch.children && "manual_pointer"}
                            onClick={() =>
                              expandCollapse(acc.index, acch.index)
                            }
                          >
                            <p className="child_p"> {acch.name}</p>
                            {acch.chargers && (
                              <>
                                {acch.isExpanded === false ? (
                                  <AddIcon className="accordion_arrows" />
                                ) : (
                                  <RemoveIcon className="accordion_arrows" />
                                )}
                              </>
                            )}
                          </div>
                        </div>

                        {acch.isExpanded && (
                          <div
                            style={{
                              padding: "0px 30px",
                            }}
                            className="filter_grand_child"
                          >
                            {acch.chargers &&
                              acch.chargers.length > 0 &&
                              acch.chargers.map((model, indx) => (
                                <div key={`acch-${indx}`}>
                                  <div
                                    style={{
                                      display: "flex",
                                      justifyContent: "flex-start",
                                      alignItems: "center",
                                    }}
                                  >
                                    <Checkbox
                                      checked={model.checked}
                                      id={"model_" + model?.chargerMetaId}
                                      onClick={() =>
                                        grandChildClicked(
                                          acc.index,
                                          index,
                                          acch.index,
                                          ind,
                                          model.index,
                                          indx
                                        )
                                      }
                                      inputProps={{
                                        "aria-label": "primary checkbox",
                                      }}
                                    />
                                    <p className="grand_child_p">
                                      {model.model}
                                    </p>
                                  </div>
                                </div>
                              ))}
                          </div>
                        )}
                      </div>
                    ))}
                </>
              )}
            </div>
          ))
        ) : (
          <p className="no_data_in_filter">No Data</p>
        )}
      </div>
    </>
  );
};
export default ChargerMakeAndModelFilter;
