import React from "react";
import OpsBreadcrumb from "../../components/NewBreadcrumbs";

import NewPageMainContent from "../../components/NewPageMainContent";
import CircuitMonitorModel from "./circuitMonitor";

const CircuitMonitor = () => {
  return (
    <>
      <NewPageMainContent>
        <OpsBreadcrumb title="Charger Health" />
        <CircuitMonitorModel />
      </NewPageMainContent>
    </>
  );
};

export default CircuitMonitor;
