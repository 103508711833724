import React from "react";
import { Link, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import { List, ListItem, ListItemIcon, ListItemText } from "@mui/material";
import { makeStyles } from "@mui/styles";
import "./components-scss/SideBar.scss";
import HomeIcon from "@mui/icons-material/Home";
import PeopleSharpIcon from "@mui/icons-material/PeopleSharp";
import SettingsIcon from "@mui/icons-material/Settings";
import CodeIcon from "@mui/icons-material/Code";
import MonitorHeartIcon from "@mui/icons-material/MonitorHeart";
import AssessmentIcon from "@mui/icons-material/Assessment";
import LineStyleIcon from "@mui/icons-material/LineStyle";
import LocationCity from "@mui/icons-material/LocationCity";
import CHD_icon from "./../assets/images/png/chd_icon.png";
import PersonIcon from "@mui/icons-material/Person";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import ListIcon from "@mui/icons-material/List";
import { routePaths } from "../constants/RoutePaths";
import DirectionsCarIcon from "@mui/icons-material/DirectionsCar";
import InsightsIcon from "@mui/icons-material/Insights";
import MonitorIcon from "@mui/icons-material/Monitor";

const useStyles = makeStyles((theme) => ({
  drawerPaper: {
    backgroundColor: "#0051A2",
    width: "inherit",
    marginTop: "60px",
    marginBottom: "60px",
    padding: "10px",
    borderLeft: "4px solid white",
    height: "93vh",
    borderRadius: "10px",
  },
  link: { textDecoration: "none", color: theme.palette.text.secondary },
  listItem: {
    color: "#fff",
  },
  icons: {
    fill: "#fff",
  },
  box: {
    display: "flex",
    backgroundColor: "#fff",
  },
  listPaddingLeft: {
    paddingLeft: "28px",
  },
}));

const getTabList = (tabs, classes) => {
  const tabList = {
    Home: {
      name: "Home",
      icon: () => <HomeIcon className={classes.icons} />,
      routePath: routePaths.HOME,
    },
    Customers: {
      name: "Customers",
      icon: () => <PeopleSharpIcon className={classes.icons} />,
      routePath: routePaths.CUSTOMERS,
    },
    // Instances: {
    //   name: "Instances",
    //   icon: () => <AccountTreeIcon className={classes.icons} />,
    //   routePath: routePaths.INSTANCES
    // },
    // "Service Status": {
    //   name: "Service Status",
    //   icon: () => <DesktopWindowsIcon className={classes.icons} />,
    //   routePath: routePaths.SERVICE_STATUS
    // },
    "Charger Health": {
      name: "Charger Health",
      // icon: () => <EvStationIcon className={classes.icons} />,
      icon: () => <img src={CHD_icon} className={classes.icons} />,
      routePath: routePaths.CHARGERHEALTH,
    },
    "Usage Analytics": {
      name: "Usage Analytics",
      icon: () => <InsightsIcon className={classes.icons} />,
      routePath: routePaths.USAGE_ANALYTICS,
    },
    "Circuit Monitor": {
      name: "Circuit Monitor",
      icon: () => <MonitorIcon className={classes.icons} />,
      routePath: routePaths.CIRCUITMONITOR,
    },
    Users: {
      name: "Users",
      icon: () => <PersonIcon className={classes.icons} />,
      routePath: routePaths.USERS,
    },
    Requests: {
      name: "Requests",
      icon: () => <LineStyleIcon className={classes.icons} />,
      routePath: routePaths.REQUESTS,
    },
    Companies: {
      name: "Companies",
      icon: () => <LocationCity className={classes.icons} />,
      routePath: routePaths.COMPANIES,
    },
    Billing: {
      name: "Billing",
      icon: () => <AttachMoneyIcon className={classes.icons} />,
      routePath: routePaths.BILLING,
    },
    Services: {
      name: "Services",
      icon: () => <CodeIcon className={classes.icons} />,
      routePath: routePaths.SERVICES,
    },
    "Pre Provisioning": {
      name: "Pre Provisioning",
      icon: () => <MonitorHeartIcon className={classes.icons} />,
      routePath: routePaths.PREPROVISIONING,
    },
    Reports: {
      name: "Reports",
      icon: () => <AssessmentIcon className={classes.icons} />,
      routePath: routePaths.REPORTS,
    },
    "Data Catalog": {
      name: "Data Catalog",
      icon: () => <ListIcon className={classes.icons} />,
      routePath: routePaths.DATA_CATALOG,
    },
    "Vehicle Discovery": {
      name: "Vehicle Discovery",
      icon: () => <DirectionsCarIcon className={classes.icons} />,
      routePath: routePaths.VEHICLES_DISCOVERY,
    },
    Configurations: {
      name: "Configurations",
      icon: () => <SettingsIcon className={classes.icons} />,
      routePath: routePaths.CONFIGURATIONS,
    },
  };
  const tabArray = Object.keys(tabList).reduce((acc, item) => {
    if (tabs && tabs.includes(item)) {
      acc.push(tabList[item]);
    }
    return acc;
  }, []);
  return tabArray;
};

const SideBar = (props) => {
  const classes = useStyles();
  const allTemp = useSelector((state) => state.user.rolePrivilege);

  const tabList = getTabList(allTemp, classes);
  const location = useLocation();

  const [locationPath, setLocationPath] = React.useState("/");

  React.useEffect(() => {
    setLocationPath(location.pathname);
  }, [location.pathname]);

  return (
    <div className="sideBar">
      <List className="ListBottomPadding">
        {tabList.map((item, index) => (
          <Link to={item.routePath} className={classes.link} key={index}>
            <ListItem
              button
              selected={locationPath === item.routePath}
              className={classes.listPaddingLeft}
            >
              <ListItemIcon>{item.icon()}</ListItemIcon>
              <ListItemText primary={item.name} className={classes.listItem} />
            </ListItem>
          </Link>
        ))}
      </List>
    </div>
  );
};

export default SideBar;
