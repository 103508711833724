import React, { useEffect, useState, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import OpsBreadcrumb from "../../components/NewBreadcrumbs";
import PageMainContent from "../../components/PageMainContent";
import {
  downloadDataCatalogMonthlyHistoryApi,
  getDataCatalogHistoryApiCall,
} from "./DataCatalogServices";
import GetAppIcon from "@mui/icons-material/GetApp";
import useTable from "../../components/UseTable";
import no_Data from "../../assets/images/png/Vector.png";
import { bindActionCreators } from "redux";
import { isEqual } from "lodash";
import VisibilityIcon from "@mui/icons-material/Visibility";
import NewCommonFilterDrawer from "../../components/NewSideFIlterDrawer";
import {
  InputAdornment,
  TableBody,
  TableCell,
  TableRow,
  Toolbar,
  Grid,
  Tooltip,
  Badge,
  Button,
} from "@mui/material";
import Controls from "../../components/controls/Controls";
import TuneIcon from "@mui/icons-material/Tune";
import Loader from "../../components/Loader";
import DataCatalogHistoryFilter from "./data-catalog-history/DataCatalogHistoryFilter";
import {
  commonApiActionCreator,
  usersActionCreator,
} from "../../redux-state/actions";
import ToastMessage from "../../components/ToastMessage";
import { dataCatalogHistoryOperation } from "../../constants/FilterConstants";
import CommonDrawer from "../../components/SideDrawer";
import { format } from "date-fns";
import PageHeader from "../../components/PageHeader";
const AllBreadcrumbsLinks = [
  {
    link: "/dataCatalog",
    title: "Data Catalog",
  },
];

const ToolbarMemoised = React.memo(
  ({ toggleDrawer, isFiltered, setIsLoading, setToast }) => {
    const downloadDataCatalogMonthlyHistory = async () => {
      setIsLoading(true);
      try {
        const res = await downloadDataCatalogMonthlyHistoryApi();
        if (res !== 0) {
          setToast({
            isOpen: true,
            message: `File Downloaded Successfully`,
            type: "success",
          });
        } else {
          setToast({
            isOpen: true,
            message: `Sorry, File did not download`,
            type: "error",
          });
        }
      } catch (err) {
        console.log(err);
        setToast({
          isOpen: true,
          message: `Sorry, File did not download`,
          type: "error",
        });
      } finally {
        setIsLoading(false);
      }
    };
    return (
      <Toolbar className="table_toolbar">
        <Grid sm item />
        <Controls.Button
          text="DOWNLOAD"
          variant="outlined"
          startIcon={<GetAppIcon className="download_icon" />}
          onClick={() => {
            downloadDataCatalogMonthlyHistory();
          }}
          className="filter"
          style={{
            border: "1px solid #A3B6C7",
            margin: "12px",
          }}
        />
        <Badge
          color="primary"
          variant="dot"
          invisible={isFiltered === true ? false : true}
        >
          <Controls.Button
            text="Filter"
            variant="outlined"
            startIcon={
              <TuneIcon
                style={{ color: isFiltered === true ? "#2770D8" : "#A3B6C7" }}
              />
            }
            onClick={() => {
              toggleDrawer(true, "FILTER", {});
            }}
            className="filter"
            style={{
              border:
                isFiltered === true ? "1px solid #2770D8" : "1px solid #A3B6C7",
            }}
          />
        </Badge>
      </Toolbar>
    );
  }
);

const DataCatalogHistory = () => {
  const dispatch = useDispatch();
  const { saveAllUsersGlobal } = bindActionCreators(
    usersActionCreator,
    dispatch
  );
  const { allUsers, isUsersLoaded } = useSelector(
    (state) => state.allUsersGlobal
  );

  //  variables being used
  const [dataCatalogData, setDataCatalogHistoryData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [filterFn, setFilterFn] = React.useState({ fn: (items) => items });
  const [userDataFilter, setUserDataFilter] = React.useState(null);
  const [fromDateFilter, setFromDateFilter] = React.useState("");
  const [toDateFilter, setToDateFilter] = React.useState("");
  const [operationFilter, setOperationFilter] = React.useState(
    Object.values(JSON.parse(JSON.stringify(dataCatalogHistoryOperation)))
  );
  const [isFiltered, setIsFiltered] = useState(false);
  const [totalCount, setTotalCount] = useState(0);
  const [DrawerOC, setDrawerOC] = React.useState({
    isOpen: false,
    tilte: "",
    data: {},
  });
  const [userData, setUserData] = useState([]);
  const toggleDrawer = (openClose, title, item) => {
    setDrawerOC({ isOpen: openClose, title: title, data: item });
  };
  const [toast, setToast] = React.useState({
    isOpen: false,
    message: "",
    type: "",
  });

  // mounting api call

  React.useEffect(() => {
    if (isUsersLoaded === false) {
      saveAllUsersGlobal();
    }
    getUserData();
  }, [isUsersLoaded]);

  const getUserData = () => {
    let allEmails = [];
    allUsers?.map((single) => {
      allEmails.push(`${single.email}-${single.firstname} ${single.lastname}`);
    });
    setUserData(allEmails);
  };

  // Table buliding code
  const mapping = { POST: "Add", PUT: "Update", DELETE: "Delete" };
  const headCells = useMemo(
    () => [
      {
        id: "method",
        label: "Method",
        disableSorting: true,
        render: (row, col) => {
          return row.method ? mapping[row.method] : titleLabels.NOT_AVAILABLE;
        },
      },
      { id: "performedOn", label: "Performed On", disableSorting: true },
      { id: "user", label: "User", disableSorting: true },
      {
        id: "performedAt",
        label: "Performed At",
        disableSorting: true,
        render: (row, col) => {
          return (
            new Date(format(new Date(`${row?.performedAt}`), "PPpp"))
              .toUTCString()
              .slice(0, -7) + "   UTC" || titleLabels.NOT_AVAILABLE
          );
        },
      },
      {
        id: "",
        label: "Action",
        disableSorting: true,
        render: (row, col) => {
          return (
            <Tooltip title="More details">
              <Button
                onClick={() =>
                  toggleDrawer(true, "Request and Response details", row)
                }
              >
                <VisibilityIcon className="viewDetailsIcon" />
              </Button>
            </Tooltip>
          );
        },
      },
    ],
    []
  );
  const {
    tableContainer: TableContainer,
    tableHead: TableHead,
    tableBody: TableBody,
    tablePagination: TablePagination,
    recordsAfterPagingAndSorting,
    page,
    rowsPerPage,
    setPage,
  } = useTable(
    dataCatalogData,
    headCells,
    filterFn,
    false,
    true,
    null,
    null,
    totalCount,
    true
  );

  useEffect(() => {
    getDataCatalogHistory();
  }, [
    page,
    rowsPerPage,
    userDataFilter,
    fromDateFilter,
    toDateFilter,
    operationFilter,
  ]);
  const makePayload = () => {
    let payload = {};
    payload["page"] = page;
    payload["count"] = rowsPerPage;
    if (userDataFilter) payload["user"] = userDataFilter.split("-")[0];
    if (fromDateFilter && toDateFilter) {
      payload["fromDate"] = fromDateFilter;
      payload["toDate"] = toDateFilter;
    }
    let operationsArray = [];
    operationFilter[0].children.map((operation) => {
      if (operation.checked) {
        operationsArray.push(operation.value);
      }
    });
    if (operationsArray.length) payload["operation"] = operationsArray;

    return payload;
  };
  //   fetching data for data catalog history
  const getDataCatalogHistory = async () => {
    setIsLoading(true);
    const payload = makePayload();
    if (Object.keys(payload).length > 2) setIsFiltered(true);
    const dataCatalogHistoryResponse = await getDataCatalogHistoryApiCall(
      payload
    );
    if (dataCatalogHistoryResponse?.code === 200) {
      setTotalCount(dataCatalogHistoryResponse?.data?.total);
      setDataCatalogHistoryData(dataCatalogHistoryResponse?.data?.data);
    }
    setIsLoading(false);
  };

  const applyFilter = (
    tempUserDataFilter,
    tempFromDateFilter,
    tempToDateFilter,
    tempOperationFilter
  ) => {
    setIsFiltered(false);

    setUserDataFilter(tempUserDataFilter);
    setFromDateFilter(tempFromDateFilter);
    setToDateFilter(tempToDateFilter);
    setOperationFilter(tempOperationFilter);
    toggleDrawer(false, "FILTER", {});
    setPage(0);
  };

  return (
    <>
      <React.Fragment>
        <OpsBreadcrumb
          AllBreadcrumbsLinks={AllBreadcrumbsLinks}
          title="History"
        />
        <PageHeader title={"History"} />
        <PageMainContent>
          <ToolbarMemoised
            toggleDrawer={toggleDrawer}
            isFiltered={isFiltered}
            setIsLoading={setIsLoading}
            setToast={setToast}
          />
          <TableContainer>
            <TableHead />
            <React.Fragment>{TableBody}</React.Fragment>
          </TableContainer>
          {recordsAfterPagingAndSorting.length === 0 && (
            <div className="no_data_in_table">
              <div className="img_title">
                <img src={no_Data} alt="No data available" />
                <p className="no_data_title">No data available</p>
              </div>
            </div>
          )}

          {TablePagination}
          {DrawerOC.title === "FILTER" ? (
            <NewCommonFilterDrawer
              DrawerOC={DrawerOC}
              toggleDrawer={toggleDrawer}
            >
              <DataCatalogHistoryFilter
                userData={userData}
                userDataFilter={userDataFilter}
                setUserDataFilter={setUserDataFilter}
                setFromDateFilter={setFromDateFilter}
                setToDateFilter={setToDateFilter}
                setOperationFilter={setOperationFilter}
                fromDateFilter={fromDateFilter}
                toDateFilter={toDateFilter}
                operationFilter={operationFilter}
                applyFilter={applyFilter}
              />
            </NewCommonFilterDrawer>
          ) : (
            <CommonDrawer DrawerOC={DrawerOC} toggleDrawer={toggleDrawer}>
              <Grid item xs={12}>
                <div className="single_details">
                  <p className="title">Request Body</p>
                  <Controls.CopyToClipboard
                    name={DrawerOC?.data?.requestBody}
                    isJson={
                      typeof DrawerOC?.data?.requestBody === "string"
                        ? false
                        : true
                    }
                    setToast={setToast}
                  />
                </div>
              </Grid>
              <Grid item xs={12}>
                <div className="single_details">
                  <p className="title">Response</p>
                  <Controls.CopyToClipboard
                    name={DrawerOC.data.response}
                    setToast={setToast}
                    isJson={
                      typeof DrawerOC?.data?.response === "string"
                        ? false
                        : true
                    }
                  />
                </div>
              </Grid>
              {DrawerOC.data?.endpoint && (
                <Grid item xs={12}>
                  <div className="single_details">
                    <p className="title">Endpoint</p>
                    <Controls.CopyToClipboard
                      name={DrawerOC.data?.endpoint}
                      setToast={setToast}
                    />
                  </div>
                </Grid>
              )}
            </CommonDrawer>
          )}
        </PageMainContent>
        <Loader isLoading={isLoading} />
        <ToastMessage toast={toast} setToast={setToast} />
      </React.Fragment>
    </>
  );
};
export default DataCatalogHistory;
