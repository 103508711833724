import { useState } from "react";
import "./ChargerHealth.scss";
import { useHistory } from "react-router-dom";
import { titleLabels } from "../../constants/TitleLabels";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { Tooltip } from "@mui/material";

const FirmwareManagment = ({ firmwareMgmtCount }) => {
  const history = useHistory();
  return (
    <>
      <p className="section_title">{titleLabels.FIRMWAREMANAGMENT}</p>
      <div className="firmware_section">
        <div className="title_with_icon blockDisplay">
          <p className="module_titles">
            UPDATE AVAILABLE{" "}
            <Tooltip title={titleLabels.UPDATEAVAILABLE}>
              <InfoOutlinedIcon />
            </Tooltip>
          </p>
          <p
            className="firmware_Count"
            onClick={() => {
              history.push({
                pathname: `/charger-health/charger-details`,
                search: `?firmwareStatus=updateAvailable`,
              });
            }}
          >
            {"updateAvailable" in firmwareMgmtCount
              ? firmwareMgmtCount.updateAvailable
              : "-"}
          </p>
        </div>
        <div className="title_with_icon blockWithMargin">
          <p className="module_titles">
            UP TO DATE{" "}
            <Tooltip title={titleLabels.UPTODATE}>
              <InfoOutlinedIcon />
            </Tooltip>
          </p>
          <p
            className="firmware_Count"
            onClick={() => {
              history.push({
                pathname: `/charger-health/charger-details`,
                search: `?firmwareStatus=upToDate`,
              });
            }}
          >
            {"updated" in firmwareMgmtCount ? firmwareMgmtCount.updated : "-"}
          </p>
        </div>
        <div className="title_with_icon blockWithMargin">
          <p className="module_titles">
            UPDATE FAILED{" "}
            <Tooltip title={titleLabels.UPDATEFAILED}>
              <InfoOutlinedIcon />
            </Tooltip>
          </p>
          <p
            className="firmware_Count"
            onClick={() => {
              history.push({
                pathname: `/charger-health/charger-details`,
                search: `?firmwareStatus=updateFailed`,
              });
            }}
          >
            {"updateFailed" in firmwareMgmtCount
              ? firmwareMgmtCount.updateFailed
              : "-"}
          </p>
        </div>
        <div className="title_with_icon blockWithMargin">
          <p className="module_titles">
            N/A{" "}
            <Tooltip title={titleLabels.FIRMWARESTATUSNA}>
              <InfoOutlinedIcon />
            </Tooltip>
          </p>
          <p
            className="firmware_Count"
            onClick={() => {
              history.push({
                pathname: `/charger-health/charger-details`,
                search: `?firmwareStatus=null`,
              });
            }}
          >
            {"notApplicable" in firmwareMgmtCount
              ? firmwareMgmtCount.notApplicable
              : "-"}
          </p>
        </div>
      </div>
    </>
  );
};
export default FirmwareManagment;
