import { Box, Grid } from "@mui/material";
import React, { useCallback, useState } from "react";
import Controls from "../../../components/controls/Controls";
import Loader from "../../../components/Loader";
import { Form, UseForm } from "../../../components/UseForm";
import {
  apiProvider,
  indexedEndPoints,
} from "../../../services/api/utilities/provider";
import { validateNonEmptyFields } from "../../../utils/FieldValidations";

export default function AddEditManufacturer({
  setRefreshChargers,
  setOpenPopup,
  setToast,
}) {
  const [loading, setLoading] = useState(false);
  const { values, errors, setErrors, handleFormInputChange } = UseForm({
    manufacturer: "",
    address: "",
  });

  const { ADD_CHARGER_MANUFACTURER } = indexedEndPoints;

  const handleSubmit = useCallback(
    async (e) => {
      e.preventDefault();
      const validData = validateNonEmptyFields(
        ["manufacturer", "address"],
        values,
        setErrors
      );
      if (validData) setErrors({});
      else return;

      const payload = {
        manufacturer: values.manufacturer?.trim(),
        address: {
          streetAddress: values.address?.trim(),
        },
      };
      setLoading(true);
      const response = await apiProvider.post(
        ADD_CHARGER_MANUFACTURER,
        payload
      );
      if (response.statusCode >= 200 && response.statusCode <= 299) {
        setToast({
          isOpen: true,
          message: "Manufacturer added successfully",
          type: "success",
        });
        setOpenPopup((op) => ({ ...op, isOpen: false }));
        setRefreshChargers(true);
      } else {
        setToast({
          isOpen: true,
          message: response?.data?.data,
          type: "error",
        });
      }
      setLoading(false);
    },
    [values]
  );

  return (
    <Form onSubmit={handleSubmit}>
      <Loader isLoading={loading} />
      <Grid container>
        <Grid item xs={12}>
          <Controls.Input
            name="manufacturer"
            label="Manufacturer"
            value={values.manufacturer}
            error={errors.manufacturer}
            onChange={handleFormInputChange()}
          />
        </Grid>
        <Grid item xs={12}>
          <Controls.Input
            name="address"
            label="Address"
            value={values.address}
            error={errors.address}
            onChange={handleFormInputChange()}
          />
        </Grid>
        <Grid item xs={12}>
          <Box display="flex" justifyContent="flex-end">
            <Controls.Button
              type="submit"
              text={"Submit"}
              testid="addManufacturerSubmitBtn"
            />
          </Box>
        </Grid>
      </Grid>
    </Form>
  );
}
