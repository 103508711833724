export const getAllCompaniesV2_Global = (
  state = { allCompaniesV2: [], isCompaniesLoaded: false },
  action
) => {
  switch (action.type) {
    case "GLOBAL_COMPANIES_V2":
      return { ...action.payload };
    default:
      return state;
  }
};

export const getAllChargersMeta = (
  state = { allChargersMeta: [], isChargersMetaLoaded: false },
  action
) => {
  switch (action.type) {
    case "GLOBAL_CHARGERS_META":
      return { ...action.payload };
    default:
      return state;
  }
};
export const getAllCompaniesV1_Global = (
  state = { allCompaniesV1: [], isCompaniesV1Loaded: false },
  action
) => {
  switch (action.type) {
    case "GLOBAL_COMPANIES_V1":
      return { ...action.payload };
    default:
      return state;
  }
};
export const getAllDepotsInfo_Global = (
  state = { allDepotInfo: [], isDepotsInfoLoaded: false },
  action
) => {
  switch (action.type) {
    case "DEPOTS_INFO":
      return { ...action.payload };
    default:
      return state;
  }
};
export const getAllTheDepots_Global = (
  state = { allTheDepotsResponse: [], isAllTheDepotsLoaded: false },
  action
) => {
  switch (action.type) {
    case "All_THE_DEPOTS":
      return { ...action.payload };
    default:
      return state;
  }
};
export const getAllServiceApiSignature = (
  state = { allServiceApiSignature: [], isAllServiceApiSignatureLoaded: false },
  action
) => {
  switch (action.type) {
    case "SERVICE_API_SIGNATURE":
      return { ...action.payload };
    default:
      return state;
  }
};

export default {
  getAllCompaniesV2_Global,
  getAllChargersMeta,
  getAllCompaniesV1_Global,
  getAllDepotsInfo_Global,
  getAllTheDepots_Global,
  getAllServiceApiSignature,
};
