import axios from "axios";
import { handleError, handleResponse } from "./response";
import { getData } from "../../../utils/Storage";
import { variables } from "../../../constants/Variables";
import environment from "../../../environment";

export const BASE_URL =
  environment[process.env.REACT_APP_NODE_ENV].BACK_END_URL;
export const CHD_BASE_URL = environment[process.env.REACT_APP_NODE_ENV].CHD_URL;

export const indexedEndPoints = {
  GET_ALL_V2_COMPANIES: "ford/v2/companies",
  GET_MAP_DEPOTS: "platform/analytics/map",
  DEPOTS: "depots",
  ACTIVE_DEPOTS: "chargers/v2/active-depots",
  PLATFORM_INSTANCES: "platform/instances/",
  START_PLATFORM_INSTANCES: "platform/instances/start/",
  STOP_PLATFORM_INSTANCES: "platform/instances/stop/",
  PLATFORM_SERVICES: "platform/services/",
  PLATFORM_SERVICES_RESTART: "platform/services/restart-service",
  PLATFORM_SERVICES_INFO: "platform/services/platform-info",
  PLATFORM_SERVICES_SYS_PERF: "platform/services/system-performance",
  PLATFORM_SERVICES_AVAILABILITY: "platform/services/availability",
  PLATFORM_CUSTOMERS: "platform/customers/",
  PLATFORM_CUSTOMERS_ACCOUNTS: "platform/customers/accounts",
  DEPOT_REQUESTS: "ford/depot-requests",
  COMPANIES: "ford/companies",
  DECLINED_REQUESTS: "ford/declined-requests",
  PROVISION: "depots/provision",
  UPDATE_DATA_SYNC: "depots/datasync",
  BILLING_RATEPLANS: "billing/rateplans",
  DEPOTS_INFO: "depots/depots-info",
  ANALYTICS_DASHBOARD: "platform/analytics/dashboard",
  OVERALL_ANALYTICS: "platform/analytics/v2/overall",
  PERIODICAL_ANALYTICS: "platform/analytics/v2/periodical",
  CHARGING_MODE: "platform/analytics/ports-charging-mode",
  PORT_SUMMARY: "platform/analytics/ports/summary",
  INSIGHTS: "platform/analytics/insights",
  CHARGER_TYPE_COUNT: "platform/analytics/v2/chargers-type-count",
  GET_CONNECTIVITY_DETAILS: "api/v1/dashboard/connectivity/data",
  GET_FAULTS_DETAILS: "api/v1/dashboard/faults/data",
  UPDATE_FAULTS_STATUS: "faults",
  GET_MANAGED_PORTS: "platform/analytics/overall/ports",
  CHD_FAULTS_COUNT: "faults/count",
  CHD_OPEN_FAULTS: "faults",
  CHD_DOWNLOAD_REPORT: `${BASE_URL}/charger-health/hardware-report`,
  // CHD_TEMPERATURE: "charger-health/v2/temperature",
  CHD_CONNECTIVITY_NOTES: "charger-health/connectivity-notes",
  CHD_FAULT_NOTES: "charger-health/fault-notes",
  GET_ALL_TROUBLESHOOT: "errorcodes/readerrors",
  GET_CHARGERS_FIRMWARE_DETAILS: "chargers/v2",
  GET_CHARGERS_HISTORY: "chargers/history",
  GET_CHARGERS_PORTS_COUNTS: "chargers/chargers-ports-count",
  GET_FIRMWARE_DETAILS_COUNT: "chargers/v2/firmware-stats",
  HOME_CHARGING_REGISTRATION:
    "chargers/register-charger?chargerMicroservices=true",
  CHARGER_REGISTRATION_HISTORY: "chargers/inventory-register-history",
  CHARGER_REPLACEMENT: "chargers/replace",
  CHARGER_REPLACEMENT_HISTORY: "chargers/replace/history",
  GET_DEPOT_DEPLOYMENT_DETAILS: "deployments/fdc",
  GET_DEPOT_DETAILS: "depots/id/details",
  FDC_LOGIN: "fdc-auth/login/id",
  GET_REPORTS_HISTORY: "reports/history",
  POST_REPORTS_GENERATE: "reports/v2/generate",
  GENERATE_REPORTS: "reports/generate",
  DATA_CATALOG_COUNTS: "platform/analytics/data-catalog/count",
  GET_ERROR_CODES: "errorcodes/readerrors",
  ADD_ERROR_CODE: "errorcodes/inserterrorcode",
  ERROR_CODE_LANGUAGES: "errorcodes/language",
  ERROR_CODE: "errorcodes",
  AGG_ERROR_CODES: "errorcodes/aggregate",
  FILTERED_ERROR_CODES: "errorcodes/specificerror",
  ADD_CHARGER_MANUFACTURER: "chargers-meta/manufacturer",
  ADD_VEHICLE_MANUFACTURER: "vehicles-meta/manufacturer",
  GET_CHARGERS: "chargers-meta/manufacturers",
  GET_VEHICLES: "vehicles-meta/manufacturers",
  CHARGER_MANUFACTURER_MODELS: "chargers-meta/integration-test/manufacturer",
  CHARGER_MODEL_FIRMWARES: "chargers-meta/firmware/manufacturer",
  CHARGERS: "chargers-meta",
  SOLD_AS_CHARGERS: "i18n-charge-port-models-extended",
  VEHICLES: "vehicles-meta",
  CHARGER_INTEGRATION_TEST: "/chargers-meta/integration-test",
  CHARGER_INTEGRATION_HISTORY: "/chargers-meta/integration-test/history",
  CHARGER_FIRMWARE: "chargers-meta/firmware",
  GET_VIN_VID_MAPPING: "vintovid/readvintovid",
  ADD_VIN_VID_MAPPING: "vintovid/insertvintovid",
  VIN_VID: "vintovid",
  GET_UTILITY_PROVIDERS: "utility-rates/utilityproviders/count",
  UTILITY_PROVIDERS: "utility-rates/utilityproviders",
  UTILITY_PLANS: "utility-rates/utilityplans",
  UTILITY_PLAN_RATES: "utility-rates/planrates",
  UTILITY_PROVIDER_REGIONS: "utility-rates/utilityproviders/countries",
  UTILITY_PLAN_SEARCH: "utility-rates/zipcodes",
  UTILITY_PLAN_EXPIRY_STATUS: "utility-rates/utilityplans/expirystatus",
  GET_FAULTS_COUNT: "platform/analytics/fault-analysis/count",
  GET_ALL_DEPOT_FAULTS: "platform/analytics/fault-analysis",
  PRE_PROVISIONING: "chargers/v2/pre-provisioning",
  AUTH_USERS: "auth/users",
  ROLES: "roles",
  USER_LOGIN: "auth/users/login",
  USER_REGISTER: "auth/users/register",
  USER_ALERT_SETTINGS: "auth/users/email/notifications",
  CHARGER_UPTIME_METRICS: "chargers/v2/uptime",
  CHARGER_EXTRA_DETIALS: "chargers",
  USER_UPDATE_PASSWORD: "auth/users/update-password",
  USER_FORGOT_PASSWORD: "auth/users/forgot-password/",
  GET_SERVICE_COMPANIES: "ford/companies",
  SERVICE_API_SIGNATURE: "internal-apis/CS",
  SERVICE_CHARGE_PORTS: "internal-apis/ports",
  SERVICE_BULK_FIRMWARE_LAST_ATTEMPTED: "internal-apis/get-firmware-update",
  SERVICE_BULK_FIRMWARE_REQ_DETAILS:
    "internal-apis/bulk-firmware-request-details",
  SCHEDULED_SERVICES: "scheduler/fetch",
  SERVICE_HISTORY: "internal-apis/history",
  SERVICE_EXECUTE: "internal-apis/execute",
  SERVICE_BULK_EXECUTE: "internal-apis/bulk-execute",
  UPDATE_FIRMWARE_EXECUTE: "scheduler/add-job",
  SERVICE_DEPOTS: "company/id/depots",
  UPDATE_DEPOTS: "update",
  DEPOT_STATUS_HISTORY: "depots/depotId/statusHistory",
  USAGE: "billing/usages",
  BILLING: "ford/billing",
  COMPANY: "company",
  FORD_COMPANY: "ford/company",
  CHARGER_PROFILE: "chargers",
  ADD_CHARGER: "charger/",
  COMPANY_PORTS: "company/id/ports",
  BILLING_DEPOTS: "billing/depots-info",
  UPDATE_BILLING_DEPOTS: "billing/v2/depots-info",
  BILLING_COMPANIES: "billing/v2/companies",
  V3_BILLING_COMPANIES: "billing/v3/companies",
  BILLING_PACKAGE: "billing/package",
  BILLABLE_PORTS: "billing/v2/billable-ports",
  BILLABLE_CUSTOMERS_COUNT: "billing/v2/billable-customers-count",
  DEPOTS_CHARGING_MODE_CHARGER_COUNT: "depots/charging-mode-charger-count",
  CHARGER_UTILIZATION: "platform/analytics/charger-utilization",
  CHARGER_CONNECTIVITY_V2: "chargers/connectivity-data",
  CHARGER_UTILIZATION_HOURLY: "platform/analytics/charger-utilization-hourly",
  VEHICLES_DISCOVERY: "vehicles/discovery",
  VEHICLES_DISCOVERY_METRICS: "vehicles/discovery-metrics",
  FDC_USER_ALERT_METRICS: "faults/subscription-metrics",
  REJECT_DELETE: "depots/reject-delete",
  DELETE_DEPOT: "depots/delete-depot",
  DEPOT_CHARGERS: "chargers/depot-chargers",
  CHARGER_META_FIRMWARE: "chargers-meta/firmware/",
  DATA_CATALOG_HISTORY: "errorcodes/history",
  DATA_CATALOG_MONTH_HISTORY: `${BASE_URL}/errorcodes/month-history`,
  COMPANIES_V3_API: "company/basic-details",
  COMPANIES_EXPORT_CSV: `${BASE_URL}/company/export-csv`,
  SERVICE_HISTORY_EXPORT_CSV: `${BASE_URL}/internal-apis/history/export-csv`,
  BILLING_COMPANY_DETAILS: "billing/id/depots",
  GET_REPORTS_COLUMNS: "/reports/get-columns/",
  PRE_PROVISIONING_CRON: "/crons/pre-provisioning-cron",
  GET_DRIVERS: "chargers/drivers/",
  GET_CIRCUITS: "",
  LOAD_MONITOR_GET_CIRCUIT: "load-monitor",
  GET_LOAD_MONITOR: "load-monitor/graph",
};

const getHeaders = (xApiKey = "123456") => {
  return {
    headers: {
      Authorization: "Bearer " + getData(variables.ACCESS_TOKEN),
      "Content-Type": "application/json",
      "x-api-key": xApiKey,
    },
  };
};
const getHeadersWithAccessKey = (accessKey) => {
  return {
    headers: {
      "x-data-sync-secret-key": accessKey,
      "Content-Type": "application/json",
    },
  };
};
// get call with different Header (Count)
const getAllBilling = async (resource, accessKey) => {
  return await axios
    .get(`${resource}/ops-console/ports`, getHeadersWithAccessKey(accessKey))
    .then(handleResponse)
    .catch(handleError);
};

// get call with different Header END

const getAll = async (resource, baseUrl = BASE_URL, xApiKey) => {
  const url = resource?.startsWith("http")
    ? resource
    : `${baseUrl}/${resource}`;

  return xApiKey
    ? await axios
        .get(url, getHeaders(xApiKey))
        .then(handleResponse)
        .catch(handleError)
    : await axios.get(url).then(handleResponse).catch(handleError);
};

const getSingle = async (resource, id, baseUrl = BASE_URL) => {
  const url = resource?.startsWith("http")
    ? resource
    : `${baseUrl}/${resource}`;
  return await axios
    .get(`${url}/${id}`, getHeaders())
    .then(handleResponse)
    .catch(handleError);
};

const login = async (resource, payload) => {
  return await axios
    .post(`${BASE_URL}/${resource}`, payload)
    .then(handleResponse)
    .catch(handleError);
};

const forgotPasswordEmail = async (resource, payload) => {
  return await axios
    .post(`${BASE_URL}/${resource}`, payload)
    .then(handleResponse)
    .catch(handleError);
};

const forgotPasswordUpdate = async (resource, payload) => {
  return await axios
    .put(`${BASE_URL}/${resource}`, payload)
    .then(handleResponse)
    .catch(handleError);
};

const forgotPasswordVerifyToken = async (resource) => {
  return await axios
    .get(`${BASE_URL}/${resource}`)
    .then(handleResponse)
    .catch(handleError);
};

const post = async (resource, payload, baseUrl = BASE_URL) => {
  const url = resource?.startsWith("http")
    ? resource
    : `${baseUrl}/${resource}`;
  return await axios
    .post(url, payload, getHeaders())
    .then(handleResponse)
    .catch(handleError);
};

const put = async (resource, payload, baseUrl = BASE_URL) => {
  const url = resource?.startsWith("http")
    ? resource
    : `${baseUrl}/${resource}`;
  return await axios
    .put(url, payload, getHeaders())
    .then(handleResponse)
    .catch(handleError);
};
const del = async (resource, payload = null, baseUrl = BASE_URL) => {
  const url = resource?.startsWith("http")
    ? resource
    : `${baseUrl}/${resource}`;
  const configs = getHeaders();
  if (payload) configs.data = payload;
  return await axios
    .delete(url, configs)
    .then(handleResponse)
    .catch(handleError);
};

export const apiProvider = {
  getAll,
  getSingle,
  post,
  put,
  login,
  del,
  getHeaders,
  getAllBilling,
  forgotPasswordEmail,
  forgotPasswordVerifyToken,
  forgotPasswordUpdate,
};
