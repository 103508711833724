import React, { useMemo, useEffect, useState } from "react";
import OpsBreadcrumb from "../../../components/NewBreadcrumbs";
import PageMainContent from "../../../components/PageMainContent";
import { getChargersAndFirmware } from "./chargerFirmwareService";
import ChargerFirmwareTable from "./chargerFirmwareTable";
import Loader from "../../../components/Loader";
import { useHistory, useLocation } from "react-router-dom";
import ToastMessage from "../../../components/ToastMessage";
import ConfirmDialog from "../../../components/ConfirmDialog";
import {
  apiProvider,
  indexedEndPoints,
} from "../../../services/api/utilities/provider";

import NewChargerFirmwareFilter from "./New_Charger_Firmware_Filter";
import NewCommonFilterDrawer from "../../../components/NewSideFIlterDrawer";
import { useDispatch, useSelector } from "react-redux";
import { bindActionCreators } from "redux";
import {
  allChargingModeFilter,
  allConnectivityStatusFilter,
  allCountryCodeForFilter,
  allDepotTypesForFilter,
  allPowerTypeFilter,
} from "../../../constants/FilterConstants";
import { commonApiActionCreator } from "../../../redux-state/actions";
import { getCheckedValues } from "../../../components/common-functions/CommonFunctions";

const AllBreadcrumbsLinks = [
  {
    link: "/charger-health",
    title: "Charger Health",
  },
];

const ChargerFirmwareDetials = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { saveAllCompaniesV2Global, saveAllChargersMetaGlobal } =
    bindActionCreators(commonApiActionCreator, dispatch);
  const { allCompaniesV2, isCompaniesLoaded } = useSelector(
    (state) => state.getAllCompaniesV2_Global
  );
  const { allChargersMeta, isChargersMetaLoaded } = useSelector(
    (state) => state.getAllChargersMeta
  );

  const { depotId, model, powerType, depotType, countryCode } = useSelector(
    (state) => state.chdFilter
  );
  const { HOME_CHARGING_REGISTRATION, CHARGER_REPLACEMENT } = indexedEndPoints;
  const [tableData, setTableData] = React.useState([]);
  const [isFilterDataLoaded, setFilterLoaded] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [companiesDataLoading, setCompaniesDataLoading] = React.useState(false);
  const [companiesRawData, setCompaniesRawData] = React.useState([]);
  const [depotsRawData, setDepotsRawData] = React.useState([]);
  const [isFiltered, setIsFiltered] = React.useState(false);
  const [allDepotType, setAllDepotType] = React.useState(
    JSON.parse(JSON.stringify(allDepotTypesForFilter))
  );
  const [DrawerOC, setDrawerOC] = React.useState({
    isOpen: false,
    tilte: "",
    data: {},
  });

  const [allAccounts, setAllAccounts] = useState([]);
  const [allMakes, setAllMakes] = useState([]);
  const [allPowerType, setAllPowerType] = useState(
    JSON.parse(JSON.stringify(allPowerTypeFilter))
  );
  const [countryCodes, setCountryCode] = React.useState(
    JSON.parse(JSON.stringify(allCountryCodeForFilter))
  );
  const [firmwareStatusFilter, setFirmwareStatusFilter] = useState([
    {
      name: "Firmware Status",
      isExpanded: true,
      checked: false,
      children: [
        {
          name: "Update available",
          checked: false,
          value: "updateAvailable",
        },
        {
          name: "Up to date",
          checked: false,
          value: "upToDate",
        },
        {
          name: "Failed",
          checked: false,
          value: "updateFailed",
        },
        {
          name: "N/A",
          checked: false,
          value: null,
        },
      ],
    },
  ]);

  const [connectivityStatusFilter, setConnectivityStatusFilter] = useState([
    {
      name: "Connectivity Status",
      isExpanded: true,
      checked: false,
      children: [
        {
          name: "Connected",
          checked: false,
          value: "Connected",
        },
        {
          name: "Not Connected",
          checked: false,
          value: "Not Connected",
        },
      ],
    },
  ]);

  const [isRFIDcapable, setIsRFIDcapableFilter] = useState([
    {
      name: "RFID Status",
      isExpanded: true,
      checked: false,
      children: [
        {
          name: "Enabled",
          checked: false,
          value: "Enabled",
        },
        {
          name: "Capable",
          checked: false,
          value: "Capable",
        },
        {
          name: "Incapable",
          checked: false,
          value: "Incapable",
        },
      ],
    },
  ]);
  const [isReconfigureNeeded, setIsReconfigureNeeded] = React.useState(false);
  const [commissionStatus, setCommissionStatus] = useState([
    {
      name: "Commission Status",
      isExpanded: true,
      checked: false,
      children: [
        {
          name: "Commissioned",
          checked: true,
          value: true,
        },
        {
          name: "Pre-commissioning",
          checked: false,
          value: false,
        },
      ],
    },
  ]);

  const [chargingModeFilter, setChargingModeFilter] = useState(
    JSON.parse(JSON.stringify(allChargingModeFilter))
  );
  const [filterObject, setFilterObject] = React.useState({});
  const [confirmDialog, setConfirmDialog] = useState({
    isOpen: false,
    type: "primary",
  });
  const [toast, setToast] = useState({
    isOpen: false,
    message: "",
    type: "",
  });

  const search = useLocation().search;
  const searchParams = new URLSearchParams(search);
  const previousUrl = useHistory();
  const depotIdParam = searchParams.get("depotID")?.split(",");
  const firmwareStatusParams = searchParams.get("firmwareStatus")?.split(",");

  const makeModelParam = searchParams.get("makeModel")?.split(",");
  const connectivityStatusParam = searchParams
    .get("connectivityStatus")
    ?.split(",");
  const isRfidCapableParam = searchParams.get("isRfidCapable")?.split(",");
  const chargingModeParam = searchParams.get("chargingMode")?.split(",");
  const countryCodeParam = searchParams.get("countryCode")?.split(",");
  const depotTypeParam = searchParams.get("depotType")?.split(",");
  const isReconfigureNeededParam = searchParams
    .get("isReconfigureNeeded")
    ?.split(",");
  const commissionStatusParam = searchParams
    .get("commissionStatus")
    ?.split(",");

  useEffect(() => {
    getAllFilter();
  }, []);
  React.useEffect(() => {
    if (isCompaniesLoaded === false) {
      saveAllCompaniesV2Global();
    }
    getCompaniesRawData();
  }, [isCompaniesLoaded]);

  React.useEffect(() => {
    if (isChargersMetaLoaded === false) {
      saveAllChargersMetaGlobal();
    }
    getChargersFilterRawData();
  }, [isChargersMetaLoaded]);

  const getAllChargersAndFirmware = async (queryparam) => {
    if (!queryparam) {
      setLoading(true);
      const res = await getChargersAndFirmware();
      if (res.statusCode >= 200 && res.statusCode <= 299) {
        setTableData(res.data);
      }
      setLoading(false);
    } else {
      for (const key in queryparam) {
        if (
          Array.isArray(queryparam[key]) &&
          queryparam[key].length === 1 &&
          queryparam[key][0] === "null"
        ) {
          delete queryparam[key];
        }
      }
      setLoading(true);
      const res = await getChargersAndFirmware(queryparam);
      if (res.statusCode >= 200 && res.statusCode <= 299) {
        setTableData(res.data);
      }
      setLoading(false);
    }
  };

  const getAllFilter = async () => {
    let queryParam = {};

    let selectedCommissionStatus = [];
    if (depotId?.length && !depotIdParam?.length) {
      queryParam["depotID"] = depotId;
    }
    if (depotIdParam?.length) {
      queryParam["depotID"] = depotIdParam;
    }
    if (firmwareStatusParams?.length) {
      let temp = firmwareStatusParams?.map((x) => (x == "null" ? null : x));
      queryParam["firmwareStatus"] = temp;
      firmwareStatusFilter[0].children.map((single) => {
        temp.includes(single.value)
          ? (single.checked = true)
          : (single.checked = false);
      });
      setFirmwareStatusFilter([...firmwareStatusFilter]);
    }
    if (model?.length && !makeModelParam?.length) {
      queryParam["makeModel"] = model;
    }
    if (makeModelParam?.length) {
      queryParam["makeModel"] = makeModelParam;
    }
    if (powerType.length !== 0) {
      queryParam["powerType"] = [...powerType];
      allPowerType[0].children.map((single) =>
        powerType.includes(single.name)
          ? (single.checked = true)
          : (single.checked = false)
      );
      setAllPowerType([...allPowerType]);
    }
    if (depotType.length !== 0) {
      queryParam["depotType"] = [...depotType];
      allDepotType[0].children.map((single) =>
        depotType.includes(single.name)
          ? (single.checked = true)
          : (single.checked = false)
      );
      setAllDepotType([...allDepotType]);
    }

    if (depotTypeParam?.length) {
      queryParam["depotType"] = depotTypeParam;
      allDepotType[0].children.map((single) =>
        depotTypeParam.includes(single.name)
          ? (single.checked = true)
          : (single.checked = false)
      );
      setAllDepotType([...allDepotType]);
      if (depotTypeParam[0] == "null") {
        if ("depotType" in queryParam) {
          delete queryParam["depotType"];
        }
        allDepotType[0].children.map((single) => (single.checked = false));
        setAllDepotType([...allDepotType]);
      }
    }

    if (
      previousUrl?.location?.state?.from == "CompanyDepotDetails" ||
      previousUrl?.location?.state?.from == "billing"
    ) {
      if ("depotType" in queryParam) {
        delete queryParam["depotType"];
      }
      allDepotType[0].children.map((single) => (single.checked = false));
      setAllDepotType([...allDepotType]);
    }
    if (countryCode.length !== 0) {
      queryParam["countryCode"] = [...countryCode];
      countryCodes[0]?.children?.map((parent) => {
        parent?.children?.map((child) => {
          if (countryCode?.includes(child?.value)) {
            child.checked = true;
          }
        });
      });
      setCountryCode([...countryCodes]);
    }
    if (countryCodeParam?.length) {
      queryParam["countryCode"] = countryCodeParam;
      countryCodes[0]?.children?.map((parent) => {
        parent?.children?.map((child) => {
          if (countryCodeParam?.includes(child?.value)) {
            child.checked = true;
          }
        });
      });
      setCountryCode([...countryCodes]);
    }
    if (connectivityStatusParam?.length) {
      queryParam["connectivityStatus"] = connectivityStatusParam;
      connectivityStatusFilter[0].children.map((single) =>
        connectivityStatusParam.includes(single.name)
          ? (single.checked = true)
          : (single.checked = false)
      );
      setConnectivityStatusFilter([...connectivityStatusFilter]);
    }

    if (isRfidCapableParam?.length) {
      queryParam["isRfidCapable"] = isRfidCapableParam;
      isRFIDcapable[0].children.map((single) =>
        isRfidCapableParam.includes(single.name)
          ? (single.checked = true)
          : (single.checked = false)
      );
      setIsRFIDcapableFilter([...isRFIDcapable]);
    }

    if (commissionStatus[0].children.length) {
      selectedCommissionStatus = commissionStatus[0].children.reduce(
        (acc, cur) => {
          if (cur.checked) {
            acc.push(cur.value);
          }
          return acc;
        },
        []
      );
    }

    if (selectedCommissionStatus.length !== 0) {
      queryParam["commissionStatus"] = selectedCommissionStatus;
    }

    if (isReconfigureNeededParam?.length) {
      if (isReconfigureNeededParam[0] == "true") {
        queryParam["isReconfigureNeeded"] = true;
        setIsReconfigureNeeded(true);
      }
    }

    if (commissionStatusParam?.length) {
      queryParam["commissionStatus"] = commissionStatusParam?.map(
        (str) => str === "true"
      );
      commissionStatus[0].children.map((single) =>
        commissionStatusParam.includes(single.value?.toString())
          ? (single.checked = true)
          : (single.checked = false)
      );
      setCommissionStatus([...commissionStatus]);
      if (commissionStatusParam[0] == "null") {
        if ("commissionStatus" in queryParam) {
          delete queryParam["commissionStatus"];
        }
        commissionStatus[0].children.map((single) => (single.checked = false));
        setCommissionStatus([...commissionStatus]);
      }
    }
    setLoading(true);
    setFilterObject(queryParam);

    const res = await getChargersAndFirmware(queryParam);
    if (res.statusCode >= 200 && res.statusCode <= 299) {
      setTableData(res.data);
      setFilterLoaded(true);
      if (Object.keys(queryParam).length !== 0) {
        setIsFiltered(true);
      }
    }
    setLoading(false);
  };

  const getCompaniesRawData = async () => {
    setCompaniesDataLoading(true);
    let tempAllCompaniesV2 = JSON.parse(JSON.stringify(allCompaniesV2));
    if (tempAllCompaniesV2.length !== 0) {
      setCompaniesRawData(tempAllCompaniesV2);
      const onlyDepotsList = [];
      tempAllCompaniesV2.map((single) => {
        if (single.depotsList) {
          onlyDepotsList.push(...single.depotsList);
        }
      });
      setDepotsRawData([...onlyDepotsList]);

      tempAllCompaniesV2?.map((single) => {
        single.checked = false;
        single.isExpanded = true;
        if ("depotsList" in single) {
          single.depotsList?.map((singleChild) => {
            singleChild["checked"] = false;
            if (
              depotIdParam?.length &&
              depotIdParam?.includes(singleChild.depotId)
            )
              // To select particular depot in filters to show its home chargers when navigated from companies page
              singleChild["checked"] = true;
            else if (
              !depotIdParam?.length &&
              depotId?.includes(singleChild.depotId)
            )
              singleChild["checked"] = true;
          });
          if (
            getCheckedValues(single?.depotsList, "checked")?.length ==
            single?.depotsList?.length
          ) {
            single.checked = true;
          }
        }
      });
      let tempAccounts = [
        {
          name: "Account and Depot",
          isExpanded: true,
          checked: false,
          children: tempAllCompaniesV2,
        },
      ];
      setAllAccounts(tempAccounts);
    }
    setCompaniesDataLoading(false);
  };

  const getChargersFilterRawData = async () => {
    let tempAllChargersMeta = JSON.parse(JSON.stringify(allChargersMeta));
    if (tempAllChargersMeta.length !== 0) {
      tempAllChargersMeta?.map((single) => {
        single.checked = false;
        single.isExpanded = true;
        if ("chargers" in single) {
          single.chargers?.map((singleChild) => {
            let checkedResult =
              model?.includes(single?.name + "-" + singleChild?.model) ||
              makeModelParam?.includes(single?.name + "-" + singleChild?.model);
            singleChild["checked"] =
              checkedResult == undefined ? false : checkedResult;
          });
          if (
            getCheckedValues(single?.chargers, "checked")?.length ==
            single?.chargers?.length
          ) {
            single.checked = true;
          }
        }
      });
      let tempMakes = [
        {
          name: "Charger make and model",
          isExpanded: true,
          checked: false,
          children: tempAllChargersMeta,
        },
      ];
      setAllMakes(tempMakes);
    }
  };

  const applyFilter = (
    tempAllAccounts,
    tempAllMakeModel,
    tempAllPowerType,
    tempAllDepotType,
    tempAllFirmwareStatusFilter,
    tempAllConnectivityStatusFilter,
    tempIsRFIDcapable,
    tempChargingModeFilter,
    tempAllCountryCode,
    tempAllCommissionStatus,
    tempIsReconfigureNeeded
  ) => {
    toggleDrawer(false, "FILTER", {});
    let selectedAccFilters = [];
    let selectedMakeFilters = [];
    let selectedPowerType = [];
    let selectedFirmwareStatusFilter = [];
    let selectedConnectivityStatusFilter = [];
    let selectedChargingModeFilter = [];
    let selectedCountryCodesFilter = [];
    let selectedDepotType = [];
    let selectedRFIDcapable = [];
    let selectedCommissionStatus = [];
    if (tempAllAccounts[0].children.length) {
      selectedAccFilters = tempAllAccounts[0].children.reduce((acc, cur) => {
        const selectedChildElements = cur.depotsList.reduce((accCh, curCh) => {
          if (curCh.checked) {
            accCh.push(curCh.depotId);
          }
          return accCh;
        }, []);
        acc.push(...selectedChildElements);
        return acc;
      }, []);
    }
    setAllAccounts([...tempAllAccounts]);
    if (tempAllMakeModel[0].children.length) {
      selectedMakeFilters = tempAllMakeModel[0].checked
        ? []
        : tempAllMakeModel[0].children.reduce((acc, cur) => {
            const selectedChildElements = cur.chargers.reduce(
              (accCh, curCh) => {
                if (curCh.checked) {
                  accCh.push(cur.name + "-" + curCh.model); // prepend make to model
                }
                return accCh;
              },
              []
            );
            acc.push(...selectedChildElements);
            return acc;
          }, []);
    }
    setAllMakes([...tempAllMakeModel]);
    if (tempAllPowerType[0].children.length) {
      selectedPowerType = tempAllPowerType[0].children.reduce((acc, cur) => {
        if (cur.checked) {
          acc.push(cur.name);
        }
        return acc;
      }, []);
    }
    setAllPowerType([...tempAllPowerType]);
    if (tempAllFirmwareStatusFilter[0].children.length) {
      selectedFirmwareStatusFilter =
        tempAllFirmwareStatusFilter[0].children.reduce((acc, cur) => {
          if (cur.checked) {
            acc.push(cur.value);
          }
          return acc;
        }, []);
    }
    setFirmwareStatusFilter([...tempAllFirmwareStatusFilter]);
    if (tempAllCountryCode[0].children.length) {
      selectedCountryCodesFilter = tempAllCountryCode[0].children.reduce(
        (codes, countryCodess) => {
          const selectedChildElements = countryCodess.children.reduce(
            (CntryCode, curCh) => {
              if (curCh.checked) {
                CntryCode.push(curCh.value);
              }
              return CntryCode;
            },
            []
          );
          codes.push(...selectedChildElements);
          return codes;
        },
        []
      );
    }
    setCountryCode([...tempAllCountryCode]);
    if (tempAllConnectivityStatusFilter[0].children.length) {
      selectedConnectivityStatusFilter =
        tempAllConnectivityStatusFilter[0].children.reduce((acc, cur) => {
          if (cur.checked) {
            acc.push(cur.value);
          }
          return acc;
        }, []);
    }
    setConnectivityStatusFilter([...tempAllConnectivityStatusFilter]);
    if (tempIsRFIDcapable[0].children.length) {
      selectedRFIDcapable = tempIsRFIDcapable[0].children.reduce((acc, cur) => {
        if (cur.checked) {
          acc.push(cur.value);
        }
        return acc;
      }, []);
    }
    setIsRFIDcapableFilter([...tempIsRFIDcapable]);
    if (tempAllDepotType[0].children.length) {
      selectedDepotType = tempAllDepotType[0].children.reduce((acc, cur) => {
        if (cur.checked) {
          acc.push(cur.name);
        }
        return acc;
      }, []);
    }
    setAllDepotType([...tempAllDepotType]);

    if (tempChargingModeFilter[0].children.length) {
      selectedChargingModeFilter = tempChargingModeFilter[0].children.reduce(
        (acc, cur) => {
          if (cur.checked) {
            acc.push(cur.value);
          }
          return acc;
        },
        []
      );
    }
    setChargingModeFilter([...tempChargingModeFilter]);
    if (tempAllCommissionStatus[0].children.length) {
      selectedCommissionStatus = tempAllCommissionStatus[0].children.reduce(
        (acc, cur) => {
          if (cur.checked) {
            acc.push(cur.value);
          }
          return acc;
        },
        []
      );
    }
    setCommissionStatus([...tempAllCommissionStatus]);
    setIsReconfigureNeeded(tempIsReconfigureNeeded);
    if (
      selectedAccFilters.length !== 0 ||
      selectedMakeFilters.length !== 0 ||
      selectedPowerType.length !== 0 ||
      selectedFirmwareStatusFilter.length !== 0 ||
      selectedConnectivityStatusFilter.length !== 0 ||
      selectedChargingModeFilter.length !== 0 ||
      selectedCountryCodesFilter.length !== 0 ||
      selectedDepotType.length !== 0 ||
      selectedRFIDcapable.length !== 0 ||
      selectedCommissionStatus.length !== 0 ||
      tempIsReconfigureNeeded == true
    ) {
      let queryParamTempObj = {};
      if (selectedAccFilters.length !== 0) {
        queryParamTempObj["depotID"] = selectedAccFilters;
      }
      if (selectedMakeFilters.length !== 0) {
        queryParamTempObj["makeModel"] = selectedMakeFilters;
      }
      if (selectedPowerType.length !== 0) {
        queryParamTempObj["powerType"] = selectedPowerType;
      }
      if (selectedFirmwareStatusFilter.length !== 0) {
        queryParamTempObj["firmwareStatus"] = selectedFirmwareStatusFilter;
      }
      if (selectedConnectivityStatusFilter.length !== 0) {
        queryParamTempObj["connectivityStatus"] =
          selectedConnectivityStatusFilter;
      }
      if (selectedRFIDcapable.length !== 0) {
        queryParamTempObj["isRfidCapable"] = selectedRFIDcapable;
      }
      if (selectedChargingModeFilter.length !== 0) {
        queryParamTempObj["chargingMode"] = selectedChargingModeFilter;
      }
      if (selectedCountryCodesFilter.length !== 0) {
        queryParamTempObj["countryCode"] = selectedCountryCodesFilter;
      }
      if (selectedDepotType.length !== 0) {
        queryParamTempObj["depotType"] = selectedDepotType;
      }
      if (selectedCommissionStatus.length !== 0) {
        queryParamTempObj["commissionStatus"] = selectedCommissionStatus;
      }
      if (tempIsReconfigureNeeded) {
        queryParamTempObj["isReconfigureNeeded"] = true;
      }

      setIsFiltered(true);
      setFilterObject(queryParamTempObj);
      getAllChargersAndFirmware(queryParamTempObj);

      let temp = queryParamTempObj;
      if (queryParamTempObj["firmwareStatus"]) {
        temp["firmwareStatus"] = queryParamTempObj["firmwareStatus"]?.map((x) =>
          x === null ? "null" : x
        );
      }

      if (!("depotType" in temp)) {
        temp["depotType"] = ["null"];
      }
      if (!("commissionStatus" in temp)) {
        temp["commissionStatus"] = ["null"];
      }
      // Kep this code if below logic fails any time

      // const res = Object.keys(temp)
      //   .map((key) => `${key}=${temp[key].join(",")}`)
      //   .join("&");

      const res = Object.keys(temp)
        .map(
          (key) =>
            `${key}=${
              Array.isArray(temp[key]) ? temp[key].join(",") : temp[key]
            }`
        )
        .join("&");
      history.replace(`/charger-health/charger-details?${res}`);
    } else {
      getAllChargersAndFirmware();
      setFilterObject({});
      setIsFiltered(false);
      history.replace(`/charger-health/charger-details`);
    }
    if (allMakes[0].checked) setIsFiltered(true);
  };

  const toggleDrawer = (openClose, title, item) => {
    setDrawerOC({ isOpen: openClose, title: title, data: item });
  };

  const registerCharger = async (item) => {
    setLoading(true);
    const payload = {
      companyId: item?.accountId,
      companyName: item?.accountName,
      depotId: item?.depotId,
      depotName: item?.depotName,
      networkType: item?.networkType,
      apiUrl: item?.apiUrl,
      chargerName: item?.chargerName,
      chargerManufacturer: item?.chargerManufacturer,
      serialNumber: item?.serialNumber,
      maxAmps: item?.maxAmps,
      maxChargingRate: item?.maxChargingRate,
      minChargingRate: item?.minChargingRate,
      powerType: item?.powerType,
      chargingRateUnit: item?.chargingRateUnit,
      networkType: item?.networkType,
      inventoryPayload: {
        evseList: [
          {
            evseId: item?.chargerId,
            modelName: item?.chargerModel,
          },
        ],
      },
    };
    const response = await apiProvider.post(
      HOME_CHARGING_REGISTRATION,
      payload
    );
    const msg = response?.data?.error?.status;
    if (response.statusCode >= 200 && response.statusCode <= 299) {
      setToast({
        isOpen: true,
        message: "Charger registration successful",
        type: "success",
      });
    } else {
      setToast({
        isOpen: true,
        message:
          msg && typeof msg === "string" ? msg : "Charger registration failed",
        type: "error",
      });
    }
    setConfirmDialog({ ...confirmDialog, isOpen: false });
    setLoading(false);
  };

  const replaceCharger = async (item) => {
    setLoading(true);
    const payload = {
      depotId: item?.depotId,
      chargerId: item?.chargerId,
      chargerName: item?.chargerName,
      chargerObjectId: item?.objectId,
    };
    const response = await apiProvider.put(CHARGER_REPLACEMENT, payload);
    if (response.statusCode >= 200 && response.statusCode <= 299) {
      setToast({
        isOpen: true,
        message: "Charger replacement successful",
        type: "success",
      });
      let data = tableData?.map((row) => {
        if (
          row.chargerId === item.chargerId &&
          row.depotId === item.depotId &&
          row.objectId === item.objectId &&
          row.chargerName === item.chargerName
        )
          row.isChargerReplaced = true;
        return row;
      });
      setTableData(data);
    } else {
      setToast({
        isOpen: true,
        message: "Charger replacement failed",
        type: "error",
      });
    }
    setConfirmDialog({ ...confirmDialog, isOpen: false });
    setLoading(false);
  };

  const chargerData = useMemo(() => {
    let combinedData = [];
    if (!tableData?.length || !allMakes[0]?.children?.length)
      return combinedData;
    tableData?.forEach((row) => {
      let chargerModelInfo = {};
      allMakes[0]?.children?.some((make) => {
        const matched = make.chargers?.find(
          (item) =>
            item.manufacturer === row.chargerManufacturer &&
            item.model === row.chargerModel
        );
        if (matched) {
          chargerModelInfo = matched;
          return true;
        }
        return false;
      });
      let combinedObj = {
        ...row,
        firmwareStatus: row.firmwareStatus ? row.firmwareStatus : "na",
        modelMaxAmps: chargerModelInfo.maxAmps
          ? Number(chargerModelInfo.maxAmps)
          : chargerModelInfo.maxAmps,
        modelMinChargingRate: chargerModelInfo.minChargingRate
          ? Number(chargerModelInfo.minChargingRate)
          : chargerModelInfo.minChargingRate,
        modelMaxChargingRate: chargerModelInfo.maxChargingRate
          ? Number(chargerModelInfo.maxChargingRate)
          : chargerModelInfo.maxChargingRate,
        RFIDConfig: chargerModelInfo?.rfidConfig
          ? chargerModelInfo.rfidConfig
          : [],
        typeConnector: chargerModelInfo.typeOfConnectorList,
        numberOfPorts: chargerModelInfo.numberOfPorts || 0,
        chargerRfidStatus: row?.rfidDetails?.status,
      };
      if (row?.derateConfig?.isChargerDerated)
        combinedObj["deratedMaxAmps"] = row?.derateConfig?.deratedMaxAmps;
      combinedData.push(combinedObj);
    });

    return combinedData;
  }, [tableData, allMakes]);

  return (
    <>
      <PageMainContent>
        <OpsBreadcrumb
          AllBreadcrumbsLinks={AllBreadcrumbsLinks}
          title="Charger Details"
        />
        <ChargerFirmwareTable
          allChargers={chargerData}
          toggleDrawer={toggleDrawer}
          isFiltered={isFiltered}
          setConfirmDialog={setConfirmDialog}
          registerCharger={registerCharger}
          replaceCharger={replaceCharger}
          allMakes={allMakes}
          allAccounts={allAccounts}
          setToast={setToast}
          setLoading={setLoading}
          getAllChargersAndFirmware={getAllChargersAndFirmware}
          filterObject={filterObject}
        />
        <Loader isLoading={loading || companiesDataLoading} />
        <NewCommonFilterDrawer DrawerOC={DrawerOC} toggleDrawer={toggleDrawer}>
          {DrawerOC.title === "FILTER" && (
            <NewChargerFirmwareFilter
              firmwareStatusFilter={firmwareStatusFilter}
              connectivityStatusFilter={connectivityStatusFilter}
              isRFIDcapable={isRFIDcapable}
              isReconfigureNeeded={isReconfigureNeeded}
              chargingModeFilter={chargingModeFilter}
              allAccounts={allAccounts}
              allDepotType={allDepotType}
              allMakes={allMakes}
              allPowerType={allPowerType}
              applyFilter={applyFilter}
              countryCode={countryCodes}
              commissionStatus={commissionStatus}
            />
          )}
        </NewCommonFilterDrawer>
      </PageMainContent>
      {confirmDialog.isOpen && (
        <ConfirmDialog
          confirmDialog={confirmDialog}
          setConfirmDialog={setConfirmDialog}
        />
      )}
      <ToastMessage toast={toast} setToast={setToast} />
    </>
  );
};

export default ChargerFirmwareDetials;
