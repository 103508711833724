import React, { useState, useEffect } from "react";
import Controls from "../../../../components/controls/Controls";
import { Grid, InputAdornment, Toolbar, Badge } from "@mui/material";
import { Tooltip as MuiToolTip } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import InfoIcon from "@mui/icons-material/Info";
import PageMainContent from "../../../../components/PageMainContent";
import Loader from "../../../../components/Loader";
import useTable from "../../../../components/UseTable";
import Popup from "../../../../components/Popup";
import ExportCSV from "../../../../components/ExportCSV";
import no_Data from "./../../../../assets/images/png/Vector.png";
import format from "date-fns/format";
import { useHistory } from "react-router-dom";
import { modelSuccessRateInfo, depotSuccessRateInfo } from "../../constants";
import { routePaths } from "../../../../constants/RoutePaths";
import "../../vehicleDiscovery.scss";

const headerComponent = (props) => {
  return (
    <div>
      <Grid container mb={1}>
        <Grid item xs={12} className={props?.headerClassName}>
          <p className="title_for_successRate">
            {props?.headerTitle}
            <MuiToolTip
              title={
                props?.powerTypePercentages && props?.showStats
                  ? modelSuccessRateInfo
                  : depotSuccessRateInfo
              }
            >
              <span className="info">
                <InfoIcon fontSize="small" className="infoIconColor" />
              </span>
            </MuiToolTip>
          </p>
          {props?.powerTypePercentages && props?.showStats ? (
            <p>
              AC - {props?.powerTypePercentages?.AC} | DC -{" "}
              {props?.powerTypePercentages?.DC}
            </p>
          ) : (
            <></>
          )}
        </Grid>
      </Grid>
    </div>
  );
};

const ToolbarMemoised = React.memo(({ handleSearch, setOpenPopup }) => {
  return (
    <Toolbar className="table_toolbar">
      <Controls.Input
        label={"Search"}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <SearchIcon />
            </InputAdornment>
          ),
        }}
        onChange={handleSearch}
      />
      <Grid item sm />
      <Badge color="primary">
        <Controls.Button
          text="Export CSV"
          variant="outlined"
          id="vehicleExportCSV"
          onClick={() => {
            setOpenPopup({
              isOpen: true,
              title: "Export CSV",
              child: "exportCSV",
            });
          }}
        />
      </Badge>
    </Toolbar>
  );
});

const DataTable = (props) => {
  const history = useHistory();

  const onClickDepot = (row) => {
    history.push(
      `${routePaths.VEHICLES_DISCOVERY_DEPOT_METRICS}?depotId=${row.depotId}&depotName=${row.depotName}&fromDate=${fDate}&toDate=${tDate}`
    );
  };

  const { isLoading, vehicleDiscoveryMetrics, fromDate, toDate } = props;
  const fDate = format(new Date(fromDate), "yyyy-MM-dd");
  const tDate = format(new Date(toDate), "yyyy-MM-dd");

  const [filterFn, setFilterFn] = React.useState({ fn: (items) => items });
  const [TableContainer, setTableContainer] = React.useState("table");
  const [TableHead, setTableHead] = React.useState("thead");
  const [TableBody, setTableBody] = React.useState("tbody");
  const [TablePagination, setTablePagination] = React.useState(() => <></>);
  const [recordsAfterPagingAndSorting, setRecordsAfterPagingAndSorting] =
    React.useState([]);
  const [headers, setHeaders] = React.useState(props?.insightsTableHeaders);
  const [tableHeadCells, setTableHeadCells] = useState(
    props?.insightsTableHeaderCells
  );
  const allColumnsHeaders = props?.insightsTableColumnHeaders;
  let result;
  if (props?.isDepotSuccessRateTable) {
    result = useTable(
      vehicleDiscoveryMetrics?.depotSessionMetrics,
      tableHeadCells,
      filterFn,
      false,
      false,
      null,
      onClickDepot,
      0,
      false,
      null,
      0
    );
  } else {
    // only other case is model success rate, if more components to be added can be specified here
    result = useTable(
      vehicleDiscoveryMetrics?.makeModelSessionMetrics,
      tableHeadCells,
      filterFn
    );
  }

  useEffect(() => {
    setTableContainer(() => result.tableContainer);
  }, [result.tableContainer]);
  useEffect(() => {
    setTableHead(() => result.tableHead);
  }, [result.tableHead]);
  useEffect(() => {
    setTableBody(() => result.tableBody);
  }, [result.tableBody]);
  useEffect(() => {
    setTablePagination(result.tablePagination);
  }, [result.tablePagination]);

  useEffect(() => {
    setRecordsAfterPagingAndSorting(result.recordsAfterPagingAndSorting);
  }, [result.recordsAfterPagingAndSorting]);

  const [openPopup, setOpenPopup] = React.useState({
    isOpen: false,
    title: "",
    child: "",
    item: {},
  });

  const handleSearch = (e) => {
    let target = e.target.value.trim();
    setFilterFn({
      fn: (items) => {
        if (target === "") return items;
        else if (props?.isDepotSuccessRateTable) {
          return items.filter(
            (x) =>
              x.depotName.toLowerCase().includes(target.toLowerCase()) ||
              x.organizationName.toLowerCase().includes(target.toLowerCase())
          );
        } else if (props?.isModelSuccessRateTable) {
          return items.filter(
            (x) =>
              x.chargerMake.toLowerCase().includes(target.toLowerCase()) ||
              x.chargerModel.toLowerCase().includes(target.toLowerCase())
          );
        }
      },
    });
  };
  const [powerTypePercentages, setPowerTypePercentages] = React.useState({
    AC: "%",
    DC: "%",
  });
  async function getData() {
    let total = 0;
    let length = vehicleDiscoveryMetrics?.makeModelSessionMetrics?.length;
    if (length > 0) {
      vehicleDiscoveryMetrics?.makeModelSessionMetrics?.forEach(
        ({ powerType }) => {
          if (powerType === "AC") total += 1;
        }
      );
      const acPercentage = (total / length) * 100;
      let acType = Math.round(Number(acPercentage), 2) + "%";
      let dcType = Math.round(Number(100 - acPercentage), 2) + "%";
      setPowerTypePercentages({ AC: acType, DC: dcType });
    } else {
      setPowerTypePercentages({
        AC: "%",
        DC: "%",
      });
    }
  }
  useEffect(() => {
    if (props.isModelSuccessRateTable) getData();
  }, [vehicleDiscoveryMetrics]);

  return (
    <>
      {<Loader isLoading={isLoading} />}
      <PageMainContent>
        <div>
          {headerComponent({
            powerTypePercentages: powerTypePercentages,
            headerClassName: props?.headerClassName,
            headerTitle: props?.headerTitle,
            showStats: props?.isModelSuccessRateTable,
          })}
        </div>
        <ToolbarMemoised
          handleSearch={handleSearch}
          setOpenPopup={setOpenPopup}
        />
        <TableContainer>
          <TableHead />
          {TableBody}
        </TableContainer>
        {recordsAfterPagingAndSorting.length === 0 && (
          <div className="no_data_in_table">
            <div className="img_title">
              <img src={no_Data} alt="No data available" />
              <p className="no_data_title">No data available</p>
            </div>
          </div>
        )}
        {TablePagination}
      </PageMainContent>
      <Popup openPopup={openPopup} setOpenPopup={setOpenPopup} minWidth="50%">
        {openPopup.child === "exportCSV" ? (
          <ExportCSV
            setOpenPopup={setOpenPopup}
            data={
              props.isDepotSuccessRateTable
                ? vehicleDiscoveryMetrics?.depotSessionMetrics
                : props.isModelSuccessRateTable
                ? vehicleDiscoveryMetrics.makeModelSessionMetrics
                : null
            }
            allColumnsHeaders={allColumnsHeaders}
            headers={headers}
            setHeaders={setHeaders}
            fileName={props.fileName}
          />
        ) : null}
      </Popup>
    </>
  );
};

export default DataTable;
