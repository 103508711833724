import React, { useCallback, useEffect, useMemo, useState } from "react";
import Loader from "../../../components/Loader";
import OpsBreadcrumb from "../../../components/NewBreadcrumbs";
import PageHeader from "../../../components/PageHeader";
import useTable from "../../../components/UseTable";
import {
  apiProvider,
  indexedEndPoints,
} from "../../../services/api/utilities/provider";
import SearchIcon from "@mui/icons-material/Search";
import { InputAdornment, Toolbar } from "@mui/material";
import { makeStyles } from "@mui/styles";
import Controls from "../../../components/controls/Controls";
import PageMainContent from "../../../components/PageMainContent";
import { useHistory } from "react-router-dom";
import { routePaths } from "../../../constants/RoutePaths";

const allBreadcrumbsLinks = [
  {
    link: routePaths.DATA_CATALOG,
    title: "Data Catalog",
  },
];

const useStyles = makeStyles((theme) => ({
  "@global": {
    "*::-webkit-scrollbar": {
      width: "0.4em",
    },
    "*::-webkit-scrollbar-track": {
      "-webkit-box-shadow": "inset 0 0 6px rgba(0,0,0,0.00)",
    },
    "*::-webkit-scrollbar-thumb": {
      backgroundColor: "rgba(0,0,0,.1)",
      outline: "0px solid slategrey",
    },
  },
}));

const AggErrorCodes = () => {
  const [loading, setLoading] = useState(false);
  const [filterFn, setFilterFn] = useState({ fn: (items) => items });
  const [data, setData] = useState([]);
  const history = useHistory();

  const classes = useStyles();

  const headCells = useMemo(
    () => [
      { id: "manufacturer", label: "Manufacturer" },
      { id: "model", label: "Model" },
      { id: "errorcodes", label: "Error Codes Count" },
    ],
    []
  );

  const { AGG_ERROR_CODES } = indexedEndPoints;
  useEffect(() => {
    const fetchErrorCodes = async () => {
      setLoading(true);
      const response = await apiProvider.getAll(`${AGG_ERROR_CODES}`);
      setLoading(false);
      setData(response?.data);
    };
    fetchErrorCodes();
  }, []);

  const handleSearch = (e) => {
    setFilterFn({
      fn: (items) => {
        if (e.target.value?.trim() === "") return items;
        else
          return items?.filter((row) =>
            Object.values(row)?.some((val) =>
              val
                ?.toString()
                ?.toLowerCase()
                ?.includes(e.target.value?.trim().toLowerCase())
            )
          );
      },
    });
  };

  const onTableRowClick = (row) => {
    history.push(
      `${routePaths.ERROR_CODES}?manufacturer=${encodeURIComponent(
        row.manufacturer
      )}&model=${encodeURIComponent(
        row.model
      )}&chargerMetaId=${encodeURIComponent(row.chargerMetaId)}`
    );
  };

  const {
    tableContainer: TableContainer,
    tableHead: TableHead,
    tableBody: TableBody,
    tablePagination: TablePagination,
  } = useTable(data, headCells, filterFn, false, false, null, onTableRowClick);

  const ToolbarMemoised = useCallback(({ handleSearch, classes }) => {
    return (
      <Toolbar className="table_toolbar">
        <Controls.Input
          label={"Search"}
          className="searchInput"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
          }}
          onChange={handleSearch}
        />
      </Toolbar>
    );
  }, []);

  return (
    <>
      <OpsBreadcrumb
        AllBreadcrumbsLinks={allBreadcrumbsLinks}
        title="Error Codes Dashboard"
      />
      <PageHeader title="Error Codes" />
      <PageMainContent>
        <ToolbarMemoised handleSearch={handleSearch} classes={classes} />
        <Loader isLoading={loading} />
        <TableContainer>
          <TableHead />
          {TableBody}
        </TableContainer>
        {TablePagination}
      </PageMainContent>
    </>
  );
};

export default AggErrorCodes;
