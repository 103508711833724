import React from "react";
import { Drawer } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import "./components-scss/SideDrawer.scss";
// import Controls from "./controls/Controls";

const ChargerDetailDrawer = (props) => {
  const { children, DraweChargerDetails, toggleDrawer } = props;
  return (
    <Drawer
      anchor="right"
      open={DraweChargerDetails.isOpen}
      onClose={() => toggleDrawer(false, "", {})}
      className="op_side_DrawerRoot_for_charger"
    >
      <div className="op_side_common_drawer_for_charger">
        <div className="op_side_title_section">
          <p className="op_side_drawer_title">{DraweChargerDetails.title}</p>
          <CloseIcon onClick={() => toggleDrawer(false, "", {})} />
        </div>
        {children}
      </div>
    </Drawer>
  );
};

export default ChargerDetailDrawer;
