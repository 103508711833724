import React from "react";
import Controls from "../../../components/controls/Controls";
import "../../data-catalog/errorCodes/ErrorCodeStyles.scss";

const ChargerRegistrationHistoryDrawer = ({ drawerObj, setToast }) => {
  return (
    <div className="error_code_drawer">
      <p className="title">Company ID</p>
      <p className="value">{drawerObj.companyId}</p>
      <p className="title">Company Name</p>
      <p className="value">{drawerObj.companyName}</p>
      <p className="title">Depot ID</p>
      <p className="value">{drawerObj.depotId}</p>
      <p className="title">Depot Name</p>
      <p className="value">{drawerObj.depotName}</p>
      <p className="title">EVSE ID</p>
      <p className="value">{drawerObj.evseId}</p>
      <p className="title">Model Name</p>
      <p className="value">{drawerObj.modelName}</p>
      <p className="title">Registration Date</p>
      <p className="value">{drawerObj.createdAt}</p>
      <p className="title">User</p>
      <p className="value">{drawerObj.user}</p>
      <p className="title">Response Object</p>
      <Controls.CopyToClipboard
        name={drawerObj.responseMessage}
        isJson={true}
        setToast={setToast}
      />
    </div>
  );
};

export default ChargerRegistrationHistoryDrawer;
