import {
  Grid,
  InputAdornment,
  TableBody,
  TableCell,
  TableRow,
  Toolbar,
  Tooltip,
  Badge,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import {
  getBillingCompanyDepots,
  getBillingDepotsPlanDetails,
  getActiveDepots,
  getBasicCompaniesBillingDetails,
} from "./BillingServices";

import CommonDrawer from "../../components/SideDrawer";
import CompanyDetails from "../request/CompanyDetails";
import Controls from "../../components/controls/Controls";
import CountTableCell from "./CountTableCell";
import DepotPlanDetails from "./DepotPlanDetails";
import DescriptionIcon from "@mui/icons-material/Description";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import HomeRoundedIcon from "@mui/icons-material/HomeRounded";
import Loader from "../../components/Loader";
import OpsBreadcrumb from "../../components/NewBreadcrumbs";
import PageHeader from "../../components/PageHeader";
import PageMainContent from "../../components/PageMainContent";
import Popup from "../../components/Popup";
import { PrivilegedComponent } from "../../utils/PrivilegedComponent";
import ProcessForm from "./ProcessForm";
import ReceiptIcon from "@mui/icons-material/Receipt";
import SearchIcon from "@mui/icons-material/Search";
import ToastMessage from "../../components/ToastMessage";
import UpdateBillingPackageForm from "./UpdateBillingPackageForm";
import UpdateCustomerBillingPlan from "./UpdateCustomerBillingPlan";
import { isEqual } from "lodash";
import useTable from "../../components/UseTable";
import HistoryIcon from "@mui/icons-material/History";
import { useHistory } from "react-router-dom";
import BillingDepotsFilter from "./filter/BillingDepotsFilter";
import NewCommonFilterDrawer from "../../components/NewSideFIlterDrawer";
import TuneIcon from "@mui/icons-material/Tune";
import { billingActionCreator } from "../../redux-state/actions";
import { bindActionCreators } from "redux";
import { useDispatch, useSelector } from "react-redux";
import { allDepotStatus } from "../../constants/FilterConstants";
import "./billing.scss";
import { fetchDepotDetails } from "../companies/companiesServices";
import ComponentLoader from "../../components/ComponentLoader";
import SuccessDialog from "../../components/NewSucessDialog";

const AllBreadcrumbsLinks = [
  {
    link: "/billing",
    title: "Billing",
  },
];

const monthly = ["A", "B", "C", "D", "E", "F", "G"];
const prepaid = [
  "H",
  "I",
  "J",
  "K",
  "L",
  "M",
  "N",
  "O",
  "P",
  "L1y",
  "FR",
  "Prepaid36m",
  "WMT",
  "Prepaid12m",
  "Annual",
];

const headCells = (componentPrivileges) => {
  const hc = [
    { id: "depotName", label: "Depot Name", disableSorting: true },
    {
      id: "ports",
      label: "Quantity (Commissioned Ports)",
      disableSorting: true,
    },
    { id: "billingCycle", label: "Billing Cycle", disableSorting: true },
  ];
  if (componentPrivileges) {
    if (componentPrivileges.includes("Update Billing Package")) {
      hc.push({ id: "action", label: "Action", disableSorting: true });
    }
  }
  hc.push({ id: "plandetails", label: "", disableSorting: true });
  return hc;
};
const Rows = (props) => {
  const isMonthlyOrPrepaid = (ratePlan) => {
    for (let i = 0; i < ratePlan.length; i++) {
      if (
        ratePlan[i].sku === "SKU-00000294" ||
        ratePlan[i].sku === "SKU-00000295" ||
        ratePlan[i].sku === "SKU-P0000544"
      ) {
        if (
          prepaid.includes(ratePlan[i].ratePlanName.split("- ").pop()) ||
          ratePlan[i].ratePlanName.toLowerCase().split(" ").includes("year") ||
          ratePlan[i].ratePlanName.toLowerCase().split(" ").includes("prepaid")
        ) {
          return "Prepaid";
        } else {
          return "Monthly";
        }
      }
    }
    return "Non Billable";
  };
  const returnProductSku = (ratePlan) => {
    for (let i = 0; i < ratePlan.length; i++) {
      if (
        ratePlan[i].sku === "SKU-00000294" ||
        ratePlan[i].sku === "SKU-00000295" ||
        ratePlan[i].sku === "SKU-P0000544"
      )
        return ratePlan[i].sku;
    }
    return "Non Billable";
  };

  const {
    item,
    toggleDrawer,
    setOpenPopup,
    componentPrivileges,
    setDepotWiseQuantity,
    setQuantityTotals,
    companyInfo,
    allDepotsPlanDetails,
    getDepotDetails,
  } = props;

  const sideDrawerData = {
    depotName: item.depotName,
    depotId: item.depotId,
    ratePlan: item.ratePlanList,
    countryCode: companyInfo.companyId,
    countryCode: companyInfo.countryCode,
    portsCount: item?.ports,
  };
  const productSku = returnProductSku(item.ratePlanList);
  return (
    <>
      <TableRow hover={true}>
        <TableCell>
          <a
            className="table-onclick-col"
            id="centerAligned"
            onClick={() => {
              getDepotDetails(item.depotId);
            }}
          >
            {item.depotName}
            {item.isHomeChargingDepot ? (
              <HomeRoundedIcon
                fontSize="small"
                title="Home Charging Depot"
                className="iconMargin"
              ></HomeRoundedIcon>
            ) : (
              ""
            )}
            {item.isBillingActivated && (
              <Tooltip title="Indicates that this depot has met the energy dispensed threshold (>100kWh) to activate billing">
                <ReceiptIcon
                  fontSize="small"
                  className="iconMargin"
                  color={"success"}
                />
              </Tooltip>
            )}
          </a>
        </TableCell>

        {
          <CountTableCell
            isBillabe={item.productSku !== "Non Billable"}
            setDepotWiseQuantity={setDepotWiseQuantity}
            companyId={companyInfo.companyId}
            depotId={item.depotId}
            productSku={productSku}
            // ratePlanName={}
            quantity={item.ports}
            setQuantityTotals={setQuantityTotals}
          />
        }

        <TableCell> {isMonthlyOrPrepaid(item.ratePlanList)}</TableCell>
        <PrivilegedComponent
          permission="Update Billing Package"
          module="billing"
        >
          <TableCell>
            <Controls.ActionButton
              color="primary"
              onClick={() => {
                toggleDrawer(true, "Edit Plan details", sideDrawerData);
              }}
            >
              <Tooltip title="Update Billing Package">
                <EditOutlinedIcon fontSize="small" />
              </Tooltip>
            </Controls.ActionButton>
          </TableCell>
        </PrivilegedComponent>
        <TableCell>
          <a
            className="table-onclick-col"
            onClick={() => {
              toggleDrawer(true, "Plan details", sideDrawerData);
            }}
          >
            Plan details
          </a>
        </TableCell>
      </TableRow>
    </>
  );
};

const ToolbarMemoised = React.memo(
  ({
    handleSearch,
    className,
    handleProcess,
    handleChangeRatePlan,
    filterToggleDrawer,
    isFiltered,
    getCompanyDepotsInfo,
    setPage,
  }) => {
    return (
      <Toolbar className="table_toolbar">
        <Controls.Input
          label={"Search Depot"}
          className={className}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
          }}
          onChange={handleSearch}
          onKeyDown={(event) => {
            if (event.key === "Enter") {
              getCompanyDepotsInfo();
              setPage(0);
            }
          }}
        />
        <Controls.Button
          type="button"
          text="Search"
          size="small"
          onClick={() => {
            getCompanyDepotsInfo();
            setPage(0);
          }}
        />
        <Grid sm item />
        <PrivilegedComponent permission="Change Rate Plan" module="billing">
          <Controls.Button
            text="Change Rate Plan"
            variant="outlined"
            startIcon={<DescriptionIcon />}
            onClick={() => handleChangeRatePlan()}
          />
        </PrivilegedComponent>
        <Badge
          color="primary"
          variant="dot"
          invisible={isFiltered ? false : true}
        >
          <Controls.Button
            text="Filter"
            variant="outlined"
            startIcon={
              <TuneIcon
                style={{ color: isFiltered === true ? "#2770D8" : "#A3B6C7" }}
              />
            }
            onClick={() => {
              filterToggleDrawer(true, "FILTER", {});
            }}
            className="filter"
            style={{
              border:
                isFiltered === true ? "1px solid #2770D8" : "1px solid #A3B6C7",
            }}
          />
        </Badge>
      </Toolbar>
    );
  }
);

const RowsMemoised = React.memo((props) => {
  return <Rows {...props} />;
});

const TableBodyMemoised = React.memo(
  ({
    data,
    allDepotsPlanDetails,
    toggleDrawer,
    setLoading,
    setToast,
    setOpenPopup,
    componentPrivileges,
    setDepotWiseQuantity,
    setQuantityTotals,
    companyInfo,
    getDepotDetails,
  }) => {
    return (
      <TableBody>
        {data.map((item, index) => (
          <RowsMemoised
            item={item}
            key={`${item.email}_${index}`}
            toggleDrawer={toggleDrawer}
            allDepotsPlanDetails={allDepotsPlanDetails}
            setToast={setToast}
            setOpenPopup={setOpenPopup}
            componentPrivileges={componentPrivileges}
            setLoading={setLoading}
            setDepotWiseQuantity={setDepotWiseQuantity}
            setQuantityTotals={setQuantityTotals}
            companyInfo={companyInfo}
            getDepotDetails={getDepotDetails}
          />
        ))}
      </TableBody>
    );
  },
  function (prevProps, nextProps) {
    if (isEqual(prevProps.data, nextProps.data)) {
      return true;
    }
    return false;
  }
);

const BillingCompanyDepot = (props) => {
  const componentPrivilege = useSelector(
    (state) => state.user.componentPrivilege.billing
  );
  const history = useHistory();
  const handleHistory = () => {
    history.push({
      pathname: `/billing/${companyInfo.companyId}/history`,
    });
  };
  const {
    match: {
      params: { id },
    },
  } = props;

  const [openPopup, setOpenPopup] = React.useState({
    isOpen: false,
    title: "",
    child: "",
    item: {},
  });

  const [DrawerOC, setDrawerOC] = React.useState({
    isOpen: false,
    tilte: "",
    data: {},
  });
  const [toast, setToast] = React.useState({
    isOpen: false,
    message: "",
    type: "",
  });
  const dispatch = useDispatch();
  const { saveAllBillingPackageGlobal } = bindActionCreators(
    billingActionCreator,
    dispatch
  );
  const { allBillingPackageResponse, isBillingPackageLoaded } = useSelector(
    (state) => state.getBillingPackage_Global
  );

  const { saveAllBillingRatePlansGlobal } = bindActionCreators(
    billingActionCreator,
    dispatch
  );
  const { allBillingRatePlansResponse, isBillingRatePlansLoaded } = useSelector(
    (state) => state.getBillingRatePlans_Global
  );
  const [allDepotsPlanDetails, setAllDepotsPlanDetails] = React.useState([]);
  const [filterFn, setFilterFn] = React.useState({ fn: (items) => items });
  const [requests, setRequests] = React.useState([]);
  const [AllRatePlans, setAllRatePlans] = useState([]);
  const [depotWiseQuantity, setDepotWiseQuantity] = React.useState([]);
  const [quantityTotals, setQuantityTotals] = useState([]);
  const [recordForEdit, setRecordForEdit] = React.useState(null);
  const [TableContainer, setTableContainer] = React.useState("table");
  const [TableHead, setTableHead] = React.useState("thead");
  const [isLoading, setLoading] = React.useState(false);
  const [isLoading2, setLoading2] = React.useState(false);
  const [TablePagination, setTablePagination] = React.useState(() => <></>);
  const [packageDetails, setPackageDetails] = React.useState();
  const [requestsForFilter, setRequestsForFilter] = React.useState();
  const [recordsAfterPagingAndSorting, setRecordsAfterPagingAndSorting] =
    React.useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [isFiltered, setIsFiltered] = React.useState(false);
  const [companyInfo, setCompanyInfo] = useState({});
  const [searchedString, setSearchedString] = React.useState("");
  const [values, setValues] = React.useState({
    management: [],
    preventativeMaintenance: [],
    premiumWarranty: [],
  });

  const [successDialog, setSuccessDialog] = React.useState({
    isOpen: false,
    type: "primary",
  });

  const [depotStatus, setDepotStatus] = React.useState(
    JSON.parse(JSON.stringify(allDepotStatus))
  );
  const [tableHeadCells, setTableheadCells] = React.useState(
    headCells(componentPrivilege)
  );
  const [FilterDrawerOC, setFilterDrawerOC] = React.useState({
    isOpen: false,
    title: "",
    data: {},
  });
  const filterToggleDrawer = (openClose, title, item) => {
    setFilterDrawerOC({ isOpen: openClose, title: title, data: item });
  };
  const {
    tableContainer,
    tableHead,
    tableBody,
    tablePagination,
    recordsAfterPagingAndSorting: paginatedAndSortingrecords,
    page,
    rowsPerPage,
    setPage,
  } = useTable(
    requests,
    tableHeadCells,
    filterFn,
    false,
    true,
    null,
    null,
    totalCount,
    true
  );

  useEffect(() => {
    getPackageInfo();
    fetchAllRatePlans();
  }, []);

  useEffect(() => {
    getCompanyDepotsInfo();
  }, [page, rowsPerPage]);

  useEffect(() => {
    setTableContainer(() => tableContainer);
  }, [tableContainer]);
  useEffect(() => {
    setTableHead(() => tableHead);
  }, [tableHead]);
  useEffect(() => {
    setTablePagination(tablePagination);
  }, [tablePagination]);
  useEffect(() => {
    setRecordsAfterPagingAndSorting(paginatedAndSortingrecords);
  }, [paginatedAndSortingrecords]);

  const getPackageDetailsResponse = () => {
    if (isBillingPackageLoaded === false) {
      return saveAllBillingPackageGlobal();
    }
    return allBillingPackageResponse;
  };
  const getRatePlansResponse = () => {
    if (isBillingRatePlansLoaded === false) {
      return saveAllBillingRatePlansGlobal();
    }
    return allBillingRatePlansResponse;
  };

  const getPackageInfo = async () => {
    const res = await getPackageDetailsResponse();
    if (res?.statusCode === 200) setPackageDetails(res?.data);
  };
  const makePayload = (tempAllDepotStatus) => {
    setIsFiltered(false);
    let payload = `?page=${page}&count=${rowsPerPage}`;
    if (searchedString !== "") payload += `&search=${searchedString}`;
    let depotStatusPayload = [];
    tempAllDepotStatus[0].children.forEach((item) => {
      if (item.checked) depotStatusPayload.push(item.value);
    });
    if (depotStatusPayload.length > 0) {
      setIsFiltered(true);
      payload += `&activeStatus=${depotStatusPayload.join(",")}`;
    }
    return payload;
  };
  const getCompanyDepotsInfo = async (tempAllDepotStatus = depotStatus) => {
    setLoading(true);
    const payload = makePayload(tempAllDepotStatus);
    const companiesResponse = await getBasicCompaniesBillingDetails(
      id,
      payload
    );
    if (companiesResponse?.code === 200) {
      // setLoading(false);
      const data = companiesResponse?.data?.paginatedDocuments
        ? companiesResponse?.data?.paginatedDocuments
        : { depotsList: [] };
      setTotalCount(companiesResponse?.data?.totalDocuments);
      setRequests([...data?.depotsList]);
      setRequestsForFilter([...data?.depotsList]);
      setCompanyInfo({ ...data });
      setLoading(false);
      getDepotWiseQuantity([...data?.depotsList]);
    } else {
      setTotalCount(0);
      setRequests([]);
      setRequestsForFilter([]);
      setCompanyInfo({});
      setLoading(false);
      getDepotWiseQuantity([]);
    }
    setLoading(false);
  };
  const isOneFieldSelected = (type) => {
    let bool = false;
    type.forEach((parent) => {
      parent.children.forEach((child) => {
        if (child.children) {
          child.children.forEach((grandChild) => {
            bool = grandChild.checked || bool;
          });
        } else {
          bool = child.checked || bool;
        }
      });
    });
    return bool;
  };

  const applyFilter = (tempAllDepotStatus) => {
    setIsFiltered(false);
    getCompanyDepotsInfo(tempAllDepotStatus);
    setDepotStatus([...tempAllDepotStatus]);
    setFilterDrawerOC(false, "", {});
  };

  const getDepotWiseQuantity = (allRequests) => {
    let temp = [];
    allRequests.map((request) => {
      if ("portDetails" in request) {
        if (request.portDetails && request.productSku !== "Non Billable") {
          if (request.portDetails.ports) {
            temp = temp.concat(request.portDetails.ports);
          }
        }
      }
    });
    setDepotWiseQuantity(temp);
  };
  const getDepotDetails = async (depotId) => {
    const singleDepotDetailsResponse = await fetchDepotDetails(depotId);
    if (singleDepotDetailsResponse?.code === 200) {
      toggleDrawer(true, "Depots Details", {
        ...singleDepotDetailsResponse.data,
        ...singleDepotDetailsResponse.data.depotsInfo,
      });
    } else {
      setToast({
        isOpen: true,
        message: "Internal Service Failure",
        type: "error",
      });
    }
  };
  const fetchAllRatePlans = async () => {
    setLoading2(true);
    const res = await getRatePlansResponse();
    if (res.statusCode === 200) {
      setAllRatePlans(res.data);
    } else {
      setToast({
        isOpen: true,
        message: "Failed to fetch Rate plans",
        type: "error",
      });
    }
    setLoading2(false);
  };

  const handleSearch = (e) => {
    let target = e.target?.value?.trim();
    setSearchedString(target);
  };

  const toggleDrawer = (openClose, title, item) => {
    setDrawerOC({ isOpen: openClose, title: title, data: item });
  };

  const handleToast = React.useCallback(
    (params) => {
      setToast(params);
    },
    [toast]
  );

  const handleLoading = React.useCallback(
    (params) => {
      setLoading(params);
    },
    [isLoading]
  );

  const redirectToHistory = () => {
    handleHistory();
  };

  const handleProcess = () => {
    setRecordForEdit({
      companyId: companyInfo.companyId,
    });
    setOpenPopup({
      isOpen: true,
      title: "Request To Raise Invoice",
      companyId: companyInfo.companyId,
      companyInfo,
      data: companyInfo,
    });
  };
  const handleChangeRatePlan = () => {
    setOpenPopup({
      isOpen: true,
      title: "Change Customer Rate Plan",
      child: "UpdateCustomerBillingPlan",
    });
  };
  const handleRecordForEdit = React.useCallback(
    (params) => {
      setRecordForEdit(params);
    },
    [recordForEdit]
  );
  const handlePopup = React.useCallback(
    (parms) => {
      setOpenPopup(parms);
    },
    [openPopup]
  );
  return (
    <>
      {<Loader isLoading={isLoading || isLoading2} />}
      <OpsBreadcrumb AllBreadcrumbsLinks={AllBreadcrumbsLinks} title="Depots" />
      <Grid container>
        <Grid item xs={6}>
          <PageHeader title={companyInfo.companyName} />
        </Grid>
        <Grid item sm />
        <div className="companyDepotButton">
          <PrivilegedComponent permission="Billing History" module="billing">
            <Controls.Button
              text="History"
              variant="outlined"
              startIcon={<HistoryIcon />}
              onClick={() => handleHistory()}
              size="small"
            />
          </PrivilegedComponent>
          <PrivilegedComponent permission="Raise Invoice" module="billing">
            <ComponentLoader isLoading={isLoading}>
              <Controls.Button
                text="Raise Invoice"
                variant="contained"
                disabled={companyInfo === undefined}
                startIcon={<ReceiptIcon />}
                onClick={() => handleProcess()}
                size="small"
              />
            </ComponentLoader>
          </PrivilegedComponent>
        </div>
      </Grid>
      <div className="company_details">
        <Grid container spacing={5}>
          <Grid item xs={6}>
            <div className="single_details">
              <p className="title">Company Id</p>
              <Controls.CopyToClipboard
                name={
                  companyInfo.companyId === null ? "-" : companyInfo.companyId
                }
                setToast={setToast}
              />
            </div>
          </Grid>
          <Grid item xs={6}>
            <div className="single_details">
              <p className="title">Zuora Id</p>
              <Controls.CopyToClipboard
                name={companyInfo.zuoraId === null ? "-" : companyInfo.zuoraId}
                setToast={setToast}
              />
            </div>
          </Grid>
        </Grid>
        <Grid container spacing={5}>
          <Grid item xs={6}>
            <div className="single_details">
              <p className="title">Billing Address</p>
              <Controls.CopyToClipboard
                name={
                  companyInfo && companyInfo.address ? companyInfo.address : "-"
                }
                setToast={setToast}
              />
            </div>
          </Grid>
          <Grid item xs={6}>
            <div className="single_details">
              <p className="title">Rate Plan</p>
              <Controls.CopyToClipboard
                name={
                  companyInfo.onboardingRatePlan === undefined
                    ? "-"
                    : companyInfo.onboardingRatePlan
                }
                setToast={setToast}
              />
            </div>
          </Grid>
        </Grid>
      </div>
      <PageMainContent>
        <ToolbarMemoised
          handleSearch={handleSearch}
          className="searchInput"
          handleProcess={handleProcess}
          handleChangeRatePlan={handleChangeRatePlan}
          filterToggleDrawer={filterToggleDrawer}
          isFiltered={isFiltered}
          getCompanyDepotsInfo={getCompanyDepotsInfo}
          setPage={setPage}
        />
        <TableContainer>
          <TableHead />
          <React.Fragment>
            <TableBodyMemoised
              data={recordsAfterPagingAndSorting}
              allDepotsPlanDetails={allDepotsPlanDetails}
              toggleDrawer={toggleDrawer}
              setToast={handleToast}
              setLoading={handleLoading}
              setOpenPopup={setOpenPopup}
              componentPrivileges={componentPrivilege}
              setDepotWiseQuantity={setDepotWiseQuantity}
              setQuantityTotals={setQuantityTotals}
              companyInfo={companyInfo}
              getDepotDetails={getDepotDetails}
            />
          </React.Fragment>
        </TableContainer>
        {TablePagination}

        <CommonDrawer DrawerOC={DrawerOC} toggleDrawer={toggleDrawer}>
          {DrawerOC.title === "Plan details" && (
            <DepotPlanDetails
              DrawerOC={DrawerOC}
              setDrawerOC={setDrawerOC}
              values={values}
              setValues={setValues}
              packageDetails={packageDetails}
            />
          )}
          {DrawerOC.title === "Depots Details" && (
            <CompanyDetails
              setToast={setToast}
              DrawerOC={DrawerOC}
              getCompanyDepotsInfo={getCompanyDepotsInfo}
              setDrawerOC={setDrawerOC}
            />
          )}
          {DrawerOC.title === "Edit Plan details" && (
            <UpdateBillingPackageForm
              setLoading={setLoading}
              setToast={setToast}
              getCompanyDepotsInfo={getCompanyDepotsInfo}
              DrawerOC={DrawerOC}
              setDrawerOC={setDrawerOC}
              refreshTable={getCompanyDepotsInfo}
              AllRatePlans={AllRatePlans}
              companyInfo={companyInfo}
              packageDetails={packageDetails}
              values={values}
              setValues={setValues}
            />
          )}
        </CommonDrawer>
        <NewCommonFilterDrawer
          DrawerOC={FilterDrawerOC}
          toggleDrawer={filterToggleDrawer}
        >
          {FilterDrawerOC.title === "FILTER" && (
            <BillingDepotsFilter
              applyFilter={applyFilter}
              depotStatus={depotStatus}
            />
          )}
        </NewCommonFilterDrawer>
        <Popup openPopup={openPopup} setOpenPopup={setOpenPopup} maxWidth="sm">
          {openPopup.title === "Request To Raise Invoice" && (
            <ProcessForm
              setLoading={handleLoading}
              setOpenPopup={handlePopup}
              AllRatePlans={AllRatePlans}
              setToast={handleToast}
              openPopup={openPopup}
              item={openPopup.companyInfo}
              recordForEdit={recordForEdit}
              depotWiseQuantity={depotWiseQuantity}
              quantityTotals={quantityTotals}
              setSuccessDialog={setSuccessDialog}
              redirectToHistory={redirectToHistory}
            />
          )}
          {openPopup.title === "Change Customer Rate Plan" && (
            <UpdateCustomerBillingPlan
              setLoading={setLoading}
              setToast={setToast}
              setOpenPopup={setOpenPopup}
              refreshTable={getCompanyDepotsInfo}
              openPopup={openPopup}
              AllRatePlans={AllRatePlans}
              companyInfo={companyInfo}
            />
          )}
        </Popup>
        {successDialog.isOpen && (
          <SuccessDialog
            successDialog={successDialog}
            setSuccessDialog={setSuccessDialog}
          />
        )}
        <ToastMessage toast={toast} setToast={setToast} />
      </PageMainContent>
    </>
  );
};

export default BillingCompanyDepot;
