import environment from "../environment";
import { apiProvider } from "../services/api/utilities/provider";

export const getZipcode = (placeId, setValues) => {
  const sessionToken = new window.google.maps.places.AutocompleteSessionToken();

  var request = {
    placeId: placeId,
    fields: ["address_components", "utc_offset"],
    sessionToken: sessionToken,
  };
  const service = new window.google.maps.places.PlacesService(
    document.createElement("div")
  );

  service.getDetails(request, callback);

  function callback(place, status) {
    if (status === window.google.maps.places.PlacesServiceStatus.OK) {
      place.address_components.forEach((address) => {
        if (address.types.includes("postal_code")) {
          setValues((prevState) => ({
            ...prevState,
            zipcode: address.long_name,
          }));
        }
      });
    }

    return place;
  }
};

export const getLatLng = (placeId, setLat, setLng) => {
  const geoCoder = new window.google.maps.Geocoder();

  geoCoder.geocode({ placeId }, callback);
  function callback(geocode, status) {
    if (status === window.google.maps.GeocoderStatus.OK) {
      setLat(geocode[0].geometry.location.lat());
      setLng(geocode[0].geometry.location.lng());
    }
  }
};

export const getTimezoneAndZipcode = (
  place_id,
  setValues,
  setAdditionalInfoLoader
) => {
  setAdditionalInfoLoader(true);
  const raw = JSON.stringify({ place_id });

  const requestOptions = {
    method: "POST",
    headers: apiProvider.getHeaders().headers,
    body: raw,
  };

  fetch(
    `${
      environment[process.env.REACT_APP_NODE_ENV].BACK_END_URL
    }/platform/analytics/place-details`,
    requestOptions
  )
    .then((response) => {
      setAdditionalInfoLoader(false);
      return response.json();
    })
    .then((data) => {
      return data.data;
    })
    .then((result) => {
      const {
        timeZoneId,
        timeZoneOffset,
        latitude,
        longitude,
        city,
        country,
        state,
        postalCode,
        countryCode,
        streetAddress,
      } = result;
      setValues((prevState) => ({
        ...prevState,
        timeZoneId,
        timeZoneOffset,
        latitude,
        longitude,
        city,
        country,
        state,
        postalCode,
        countryCode,
        streetAddress,
      }));
    })
    .catch((error) =>
      console.log("error while firing getTimezoneAndZipcode API: ", error)
    );
};

export const getTimezoneAndZipcodeForBilling = (
  place_id,
  setValues,
  setAdditionalInfoLoader,
  setIsCountryLoaded
) => {
  setIsCountryLoaded(true);
  setAdditionalInfoLoader(true);

  const raw = JSON.stringify({ place_id });
  const requestOptions = {
    method: "POST",
    headers: apiProvider.getHeaders().headers,
    body: raw,
  };
  fetch(
    `${
      environment[process.env.REACT_APP_NODE_ENV].BACK_END_URL
    }/platform/analytics/place-details`,
    requestOptions
  )
    .then((response) => {
      setAdditionalInfoLoader(false);
      setIsCountryLoaded(false);
      return response.json();
    })
    .then((data) => {
      return data.data;
    })
    .then((result) => {
      setValues((prevState) => ({
        ...prevState,
        billingAddress: result,
      }));
    })
    .catch((error) =>
      console.log("error while firing getTimezoneAndZipcode API: ", error)
    );
};
