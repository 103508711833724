import { useEffect, useState } from "react";

export const useDebounce = (value, delay = 2000) => {
  const [debouncedValue, setDebouncedValue] = useState(value);

  useEffect(() => {
    if (value !== null && value !== undefined) {
      const timeoutId = setTimeout(() => {
        setDebouncedValue(value);
      }, delay);

      return () => clearTimeout(timeoutId);
    }
  }, [value, delay]);

  return debouncedValue;
};

export const TimerFunction = (callback, interval) => {
  useEffect(() => {
    const timer = setInterval(() => {
      callback();
    }, interval);

    return () => clearInterval(timer);
  }, [callback, interval]);
};
