const userReducer = (state = {}, action) => {
  switch (action.type) {
    case "ADD_USER":
      return { ...state, ...action.payload };
    case "UPDATE_USER":
      return {
        ...state,
       [action.payload.key]: action.payload.data 
      };
    case "REMOVE_USER":
      return {};
    default:
      return state;
  }
};

export default userReducer;
