import React from "react";
import { TextField } from "@mui/material";

const Input = (props) => {
  const {
    name,
    label,
    onChange,
    error = null,
    value,
    editable = true,
    variant,
    InputProps,
    ...other
  } = props;

  const UpdatedInputProps = editable
    ? { ...InputProps }
    : { ...InputProps, className: "Mui-disabled" };

  return (
    <TextField
      {...other}
      variant={variant ? variant : "outlined"}
      
      name={name}
      label={label}
      value={value}
      size="small"
      onChange={onChange} 
      inputProps={{
        readOnly: !editable,
        "data-testid": other["testid"],
        min: other["min"],
        max: other["max"],
      }}
      InputProps={UpdatedInputProps}
      {...(error && { error: true, helperText: error })}
    />
  );
};

export default Input;
