import React from "react";
import { makeStyles } from "@mui/styles";
import {
  InputLabel,
  MenuItem,
  FormHelperText,
  FormControl,
  Select,
  Chip,
  Input,
  Grid,
  Typography,
  Divider,
} from "@mui/material";
const useStyles = makeStyles((theme) => ({
  chips: {
    display: "flex",
    flexWrap: "wrap",
  },
  chip: {
    margin: 2,
  },
  alignCenter: {
    display: "flex",
    alignItems: "center",
  },
  dividerMargin: {
    margin: "10px 0 5px 0",
  },
}));

const MultiSelect = (props) => {
  const classes = useStyles();
  const {
    name,
    value,
    onChange,
    options,
    caption,
    error = null,
    testid = "",
  } = props;

  return (
    <div>
      <Grid container style={{ paddingTop: "10px" }}>
        <Grid xs={2} className={classes.alignCenter}>
          <Typography variant="overline" display="block" gutterBottom>
            {name}
          </Typography>
        </Grid>
        <Grid xs={8}>
          <FormControl fullWidth>
            <InputLabel id="demo-mutiple-chip-label">{name}</InputLabel>
            <Select
              labelId="demo-mutiple-chip-label"
              id="demo-mutiple-chip"
              data-testid={testid}
              multiple
              value={value}
              onChange={onChange}
              input={<Input id="select-multiple-chip" />}
              MenuProps={{ "data-testid": `${name}Options` }}
              renderValue={(selected) => (
                <div className={classes.chips}>
                  {selected.map((value) => (
                    <Chip key={value} label={value} className={classes.chip} />
                  ))}
                </div>
              )}
            >
              {options.map((name) => (
                <MenuItem key={name} value={name} data-testid={name}>
                  {name}
                </MenuItem>
              ))}
            </Select>
            <FormHelperText>{caption}</FormHelperText>
          </FormControl>
        </Grid>
      </Grid>
      <Divider variant="middle" className={classes.dividerMargin} />
    </div>
  );
};

export default MultiSelect;
