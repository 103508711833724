import {
  apiProvider,
  indexedEndPoints,
} from "../../../services/api/utilities/provider";

const {
  CHARGER_PROFILE,
  GET_CHARGERS_FIRMWARE_DETAILS,
  GET_FIRMWARE_DETAILS_COUNT,
  GET_CHARGERS_PORTS_COUNTS,
  DEPOT_CHARGERS,
} = indexedEndPoints;

export const getChargerProfile = async (id) =>
  await apiProvider.getAll(`${CHARGER_PROFILE}/${id}/profiles`);

export const getChargerPortCells = async (id) =>
  await apiProvider.getAll(`${CHARGER_PROFILE}/${id}/cells`);

export const getChargersAndFirmware = async (body) =>
  body
    ? await apiProvider.post(GET_CHARGERS_FIRMWARE_DETAILS, body)
    : await apiProvider.post(GET_CHARGERS_FIRMWARE_DETAILS, {});

export const getFirmwareCountOnCHD = async (body) =>
  body
    ? await apiProvider.post(GET_FIRMWARE_DETAILS_COUNT, body)
    : await apiProvider.post(GET_FIRMWARE_DETAILS_COUNT, {});

export const getCommissionedChargerAndPortCount = async (body) => {
  return await apiProvider.post(GET_CHARGERS_PORTS_COUNTS, body);
};

export const getChargersListForDepot = async (depotId) => {
  return await apiProvider.getAll(`${DEPOT_CHARGERS}/${depotId}`);
};
export default {
  getChargersAndFirmware,
  getCommissionedChargerAndPortCount,
};
