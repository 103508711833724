export const statesList = {
  GBR: [
    "Aberconwy and Colwyn",
    "Aberdeen City",
    "Aberdeenshire",
    "Anglesey",
    "Angus",
    "Antrim",
    "Argyll and Bute",
    "Armagh",
    "Avon",
    "Ayrshire",
    "Bath and NE Somerset",
    "Bedfordshire",
    "Belfast",
    "Berkshire",
    "Berwickshire",
    "BFPO",
    "Blaenau Gwent",
    "Buckinghamshire",
    "Caernarfonshire",
    "Caerphilly",
    "Caithness",
    "Cambridgeshire",
    "Cardiff",
    "Cardiganshire",
    "Carmarthenshire",
    "Ceredigion",
    "Channel Islands",
    "Cheshire",
    "City of Bristol",
    "Clackmannanshire",
    "Clwyd",
    "Conwy",
    "Cornwall/Scilly",
    "Cumbria",
    "Denbighshire",
    "Derbyshire",
    "Derry/Londonderry",
    "Devon",
    "Dorset",
    "Down",
    "Dumfries and Galloway",
    "Dunbartonshire",
    "Dundee",
    "Durham",
    "Dyfed",
    "East Ayrshire",
    "East Dunbartonshire",
    "East Lothian",
    "East Renfrewshire",
    "East Riding Yorkshire",
    "East Sussex",
    "Edinburgh",
    "England",
    "Essex",
    "Falkirk",
    "Fermanagh",
    "Fife",
    "Flintshire",
    "Glasgow",
    "Gloucestershire",
    "Greater London",
    "Greater Manchester",
    "Gwent",
    "Gwynedd",
    "Hampshire",
    "Hartlepool",
    "Hereford and Worcester",
    "Hertfordshire",
    "Highlands",
    "Inverclyde",
    "Inverness-Shire",
    "Isle of Man",
    "Isle of Wight",
    "Kent",
    "Kincardinshire",
    "Kingston Upon Hull",
    "Kinross-Shire",
    "Kirklees",
    "Lanarkshire",
    "Lancashire",
    "Leicestershire",
    "Lincolnshire",
    "Londonderry",
    "Merseyside",
    "Merthyr Tydfil",
    "Mid Glamorgan",
    "Mid Lothian",
    "Middlesex",
    "Monmouthshire",
    "Moray",
    "Neath & Port Talbot",
    "Newport",
    "Norfolk",
    "North Ayrshire",
    "North East Lincolnshire",
    "North Lanarkshire",
    "North Lincolnshire",
    "North Somerset",
    "North Yorkshire",
    "Northamptonshire",
    "Northern Ireland",
    "Northumberland",
    "Nottinghamshire",
    "Orkney and Shetland Isles",
    "Oxfordshire",
    "Pembrokeshire",
    "Perth and Kinross",
    "Powys",
    "Redcar and Cleveland",
    "Renfrewshire",
    "Rhonda Cynon Taff",
    "Rutland",
    "Scottish Borders",
    "Shetland",
    "Shropshire",
    "Somerset",
    "South Ayrshire",
    "South Glamorgan",
    "South Gloucesteshire",
    "South Lanarkshire",
    "South Yorkshire",
    "Staffordshire",
    "Stirling",
    "Stockton On Tees",
    "Suffolk",
    "Surrey",
    "Swansea",
    "Torfaen",
    "Tyne and Wear",
    "Tyrone",
    "Vale Of Glamorgan",
    "Wales",
    "Warwickshire",
    "West Berkshire",
    "West Dunbartonshire",
    "West Glamorgan",
    "West Lothian",
    "West Midlands",
    "West Sussex",
    "West Yorkshire",
    "Western Isles",
    "Wiltshire",
    "Wirral",
    "Worcestershire",
    "Wrexham",
    "York"
  ],
  CAN: [
    "Alberta",
    "British Columbia",
    "Manitoba",
    "New Brunswick",
    "Newfoundland and Labrador",
    "Northwest Territories",
    "Nova Scotia",
    "Nunavut",
    "Ontario",
    "Prince Edward Island",
    "Quebec",
    "Saskatchewan",
    "Yukon Territory"
  ],
  POL: [
    "Greater Poland (Wielkopolskie)",
    "Kuyavian-Pomeranian (Kujawsko-Pomorskie)",
    "Lesser Poland (Malopolskie)",
    "Lodz (Lodzkie)",
    "Lower Silesian (Dolnoslaskie)",
    "Lublin (Lubelskie)",
    "Lubusz (Lubuskie)",
    "Masovian (Mazowieckie)",
    "Opole (Opolskie)",
    "Podlasie (Podlaskie)",
    "Pomeranian (Pomorskie)",
    "Silesian (Slaskie)",
    "Subcarpathian (Podkarpackie)",
    "Swietokrzyskie (Swietokrzyskie)",
    "Warmian-Masurian (Warminsko-Mazurskie)",
    "West Pomeranian (Zachodniopomorskie)"
  ],
  AUT: [
    "Burgenland",
    "Kaernten",
    "Niederoesterreich",
    "Oberoesterreich",
    "Salzburg",
    "Steiermark",
    "Tirol",
    "Vorarlberg",
    "Wien"
  ],
  IRL: [
    "Carlow",
    "Cavan",
    "Clare",
    "Cork",
    "Donegal",
    "Dublin",
    "Galway",
    "Kerry",
    "Kildare",
    "Kilkenny",
    "Laois",
    "Leitrim",
    "Limerick",
    "Longford",
    "Louth",
    "Mayo",
    "Meath",
    "Monaghan",
    "Offaly",
    "Roscommon",
    "Sligo",
    "Tipperary",
    "Waterford",
    "Westmeath",
    "Wexford",
    "Wicklow"
  ],
  ESP: [
    "Andalucia",
    "Aragon",
    "Asturias",
    "Baleares",
    "Ceuta",
    "Canarias",
    "Cantabria",
    "Castilla-La Mancha",
    "Castilla y Leon",
    "Cataluna",
    "Comunidad Valenciana",
    "Extremadura",
    "Galicia",
    "La Rioja",
    "Madrid",
    "Melilla",
    "Murcia",
    "Navarra",
    "Pais Vasco"
  ],
  LUX: ["Diekirch", "Grevenmacher", "Luxembourg"],
  NLD: [
    "Drenthe",
    "Flevoland",
    "Friesland",
    "Gelderland",
    "Groningen",
    "Limburg",
    "Noord-Brabant",
    "Noord-Holland",
    "Overijssel",
    "Utrecht",
    "Zeeland",
    "Zuid-Holland"
  ],
  NOR: [
    "Akershus",
    "Aust-Agder",
    "Buskerud",
    "Finnmark",
    "Hedmark",
    "Hordaland",
    "More og Romsdal",
    "Nordland",
    "Nord-Trondelag",
    "Oppland",
    "Oslo",
    "Ostfold",
    "Rogaland",
    "Sogn og Fjordane",
    "Sor-Trondelag",
    "Telemark",
    "Troms",
    "Vest-Agder",
    "Vestfold"
  ],
  CHN: [
    "Anhui",
    "Fujian",
    "Gansu",
    "Guangdong",
    "Guizhou",
    "Hainan",
    "Hebei",
    "Heilongjiang",
    "Henan",
    "Hubei",
    "Hunan",
    "Jiangsu",
    "Jiangxi",
    "Jilin",
    "Liaoning",
    "Qinghai",
    "Shaanxi",
    "Shandong",
    "Shanxi",
    "Sichuan",
    "Yunnan",
    "Zhejiang",
    "Guangxi",
    "Nei Mongol",
    "Ningxia",
    "Xinjiang",
    "Xizang (Tibet)",
    "Beijing",
    "Chongqing",
    "Shanghai",
    "Tianjin"
  ],
  DNK: [
    "Arhus",
    "Bornholm",
    "Frederiksberg",
    "Frederiksborg",
    "Fyn",
    "Kobenhavn",
    "Kobenhavns",
    "Nordjylland",
    "Ribe",
    "Ringkobing",
    "Roskilde",
    "Sonderjylland",
    "Storstrom",
    "Vejle",
    "Vestsjalland",
    "Viborg"
  ],
  BEL: [
    "Antwerpen",
    "Brabant Wallon",
    "Brussels",
    "Flanders",
    "Hainaut",
    "Liege",
    "Limburg",
    "Luxembourg",
    "Namur",
    "Oost-Vlaanderen",
    "Vlaams-Brabant",
    "Wallonia",
    "West-Vlaanderen"
  ],
  DEU: [
    "Baden-Wuerttemberg",
    "Bayern",
    "Berlin",
    "Brandenburg",
    "Bremen",
    "Hamburg",
    "Hessen",
    "Mecklenburg-Vorpommern",
    "Niedersachsen",
    "Nordrhein-Westfalen",
    "Rheinland-Pfalz",
    "Saarland",
    "Sachsen",
    "Sachsen-Anhalt",
    "Schleswig-Holstein",
    "Thueringen"
  ],
  FRA: [
    "Alsace",
    "Aquitaine",
    "Auvergne",
    "Basse-Normandie",
    "Bourgogne",
    "Bretagne",
    "Centre",
    "Champagne-Ardenne",
    "Corse",
    "Franche-Comte",
    "Haute-Normandie",
    "Ile-de-France",
    "Languedoc-Roussillon",
    "Limousin",
    "Lorraine",
    "Midi-Pyrenees",
    "Nord-Pas-de-Calais",
    "Pays de la Loire",
    "Picardie",
    "Poitou-Charentes",
    "Provence-Alpes-Cote d'Azur",
    "Rhone-Alpes"
  ],
  USA: [
    "Alabama",
    "Alaska",
    "Arizona",
    "Arkansas",
    "California",
    "Colorado",
    "Connecticut",
    "Delaware",
    "District of Columbia",
    "Florida",
    "Georgia",
    "Hawaii",
    "Idaho",
    "Illinois",
    "Indiana",
    "Iowa",
    "Kansas",
    "Kentucky",
    "Louisiana",
    "Maine",
    "Maryland",
    "Massachusetts",
    "Michigan",
    "Minnesota",
    "Mississippi",
    "Missouri",
    "Montana",
    "Nebraska",
    "Nevada",
    "New Hampshire",
    "New Jersey",
    "New Mexico",
    "New York",
    "North Carolina",
    "North Dakota",
    "Ohio",
    "Oklahoma",
    "Oregon",
    "Pennsylvania",
    "Rhode Island",
    "South Carolina",
    "South Dakota",
    "Tennessee",
    "Texas",
    "Utah",
    "Vermont",
    "Virginia",
    "Washington",
    "West Virginia",
    "Wisconsin",
    "Wyoming"
  ],
  ITA: [
    "Abruzzo",
    "Basilicata",
    "Calabria",
    "Campania",
    "Emilia-Romagna",
    "Friuli-Venezia Giulia",
    "Lazio",
    "Liguria",
    "Lombardia",
    "Marche",
    "Molise",
    "Piemonte",
    "Puglia",
    "Sardegna",
    "Sicilia",
    "Toscana",
    "Trentino-Alto Adige",
    "Umbria",
    "Valle d'Aosta",
    "Veneto"
  ]
};

export const countryList = [
  { value: "GBR", label: "United Kingdom, GBR" },
  { value: "CAN", label: "Canada, CAN" },
  { value: "POL", label: "Poland, POL" },
  { value: "AUT", label: "Austria, AUT" },
  { value: "IRL", label: "Ireland, IRL" },
  { value: "ESP", label: "Spain, ESP" },
  { value: "LUX", label: "Luxembourg, LUX" },
  { value: "NLD", label: "Netherlands, NLD" },
  { value: "NOR", label: "Norway, NOR" },
  { value: "CHN", label: "China, CHN" },
  { value: "DNK", label: "Denmark, DNK" },
  { value: "BEL", label: "Belgium, BEL" },
  { value: "DEU", label: "Germany, DEU" },
  { value: "FRA", label: "France, FRA" },
  { value: "USA", label: "United States, USA" },
  { value: "ITA", label: "Italy, ITA" }
];

export default { statesList, countryList };
