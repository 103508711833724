import React from "react";
import OpsBreadcrumb from "../../components/NewBreadcrumbs";
import PageHeader from "../../components/PageHeader";
import { Tab, Chip, Badge } from "@mui/material";
import TabPanel from "@mui/lab/TabPanel";
import TabList from "@mui/lab/TabList";
import TabContext from "@mui/lab/TabContext";
import NewCommonFilterDrawer from "../../components/NewSideFIlterDrawer";
import VehicleFilter from "./vehicleFilter";
import TuneIcon from "@mui/icons-material/Tune";
import Controls from "../../components/controls/Controls";
import ToastMessage from "../../components/ToastMessage";
import "./vehicleDiscovery.scss";

import Insights from "./insights";
import AllSessions from "./all-sessions";
import DateRangePicker from "./datepicker";
import {
  VehiclesDiscoveryProvider,
  useVehiclesDiscovery,
} from "./VehiclesDiscoveryContext";

const VehicleDiscoveryPage = () => {
  const allTabs = ["Insights", "All Sessions"];
  const [currentTab, setCurrentTab] = React.useState("Insights");
  const {
    fromDate,
    toDate,
    DrawerOC,
    vehicleDiscoveryData,
    toggleDrawer,
    applyFilter,
    allAccounts,
    allMakes,
    allPowerType,
    allDepotType,
    countryCodes,
    toast,
    setToast,
    isFiltered,
  } = useVehiclesDiscovery();

  const handleChange = (event, newValue) => {
    setCurrentTab(newValue);
  };

  return (
    <>
      <OpsBreadcrumb title="Vehicle Discovery" />
      <div className="wrap">
        <PageHeader title={"Vehicle Discovery"} />
        <div className="lwrap">
          <DateRangePicker />
          <Badge
            color="primary"
            variant="dot"
            invisible={isFiltered ? false : true}
          >
            <Controls.Button
              text="Filter"
              variant="outlined"
              startIcon={<TuneIcon className="filterIcon" />}
              onClick={() => {
                toggleDrawer(true, "FILTER", {});
              }}
              id="vehicleDiscoveryFilter"
              className="filterButton"
            />
          </Badge>
        </div>
      </div>
      <TabContext value={currentTab}>
        <TabList
          onChange={handleChange}
          aria-label="lab API tabs example"
          sx={{
            borderBottom: "1px solid #d6d7de",
            "& .MuiTabs-indicator": {
              backgroundColor: "#1890ff",
            },
          }}
        >
          {allTabs?.map((single) => {
            return <Tab key={single} label={single} value={single} />;
          })}
          <Chip
            label={vehicleDiscoveryData?.length || 0}
            className="chipStyle"
            size="small"
          />
        </TabList>
        {allTabs?.map((single) => {
          return (
            <TabPanel key={single} value={single}>
              {/** Placeholder Tab */}
              {single === "Insights" && <Insights />}
              {single === "All Sessions" && <AllSessions />}
            </TabPanel>
          );
        })}
      </TabContext>
      <NewCommonFilterDrawer DrawerOC={DrawerOC} toggleDrawer={toggleDrawer}>
        <VehicleFilter
          applyFilter={applyFilter}
          allAccounts={allAccounts}
          allMakes={allMakes}
          allPowerType={allPowerType}
          allDepotType={allDepotType}
          countryCode={countryCodes}
          fromDate={fromDate}
          toDate={toDate}
        />
      </NewCommonFilterDrawer>
      <ToastMessage toast={toast} setToast={setToast} />
    </>
  );
};

const VehicleDiscovery = () => {
  return (
    <div>
      <VehiclesDiscoveryProvider>
        <VehicleDiscoveryPage />
      </VehiclesDiscoveryProvider>
    </div>
  );
};

VehicleDiscovery.displayName = "VehicleDiscovery";

export default VehicleDiscovery;
