import {
  Card,
  CardActionArea,
  CardActions,
  CardContent,
  Grid,
  IconButton,
  Tooltip,
  Zoom,
} from "@mui/material";
import { ArrowForward } from "@mui/icons-material";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import Loader from "../../components/Loader";
import OpsBreadcrumb from "../../components/NewBreadcrumbs";
import PageMainContent from "../../components/PageMainContent";
import PageHeader from "../../components/PageHeader";
import {
  apiProvider,
  indexedEndPoints,
} from "../../services/api/utilities/provider";
import CountUp from "react-countup";
import { routePaths } from "../../constants/RoutePaths";
import "../home/Home.scss";
import Controls from "../../components/controls/Controls";
import HistoryIcon from "@mui/icons-material/History";

const categories = {
  errorCodes: {
    label: "Error Codes",
    link: routePaths.ERROR_CODES_DASHBOARD,
  },
  chargers: { label: "Chargers", link: routePaths.CHARGERS_DASHBOARD },
  vinVidMapping: {
    label: "Vehicles (VIN-VID Mapping)",
    link: routePaths.VIN_VID_MAPPING,
  },
  utilityRates: {
    label: "Utility Rates",
    link: routePaths.UTILITY_RATES,
  },
  vehicles: { label: "Vehicles", link: routePaths.VEHICLES_DASHBOARD },
};

const DataCatalog = () => {
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [counts, setCounts] = useState({
    errorCodes: 0,
    utilityRates: 0,
    chargers: 0,
    vinVidMapping: 0,
    vehicleCounts: 0,
  });

  const { DATA_CATALOG_COUNTS } = indexedEndPoints;
  useEffect(() => {
    const fetchCounts = async () => {
      setLoading(true);
      const counts = await apiProvider.getAll(`${DATA_CATALOG_COUNTS}`);
      setLoading(false);
      setCounts({
        ...counts,
        errorCodes: counts?.data?.errorcodecount,
        vinVidMapping: counts?.data?.vintovidcount,
        chargers: counts?.data?.chargercount,
        utilityRates: counts?.data?.utilityCount,
        vehicles: counts?.data?.vehicleCount,
      });
    };
    fetchCounts();
  }, []);

  return (
    <>
      <OpsBreadcrumb title="Data Catalog" />
      <PageHeader title="Data Catalog" />
      <PageMainContent>
        <div className="History-button">
          <Controls.Button
            text="History"
            variant="outlined"
            id="historyBtn"
            startIcon={<HistoryIcon className="historyIcon" />}
            onClick={() => history.push("/dataCatalog/history")}
          />
        </div>
        <Loader isLoading={loading} />
        <div className="home_Page">
          <Grid container spacing={3}>
            {Object.keys(categories)?.map((item, i) => (
              <Grid item xs={12} md={6} lg={3} key={i}>
                <div className="kpi_card">
                  <Card>
                    <CardActionArea
                      onClick={() => history.push(categories[item]?.link)}
                    >
                      <CardContent>
                        {item !== "utilityRates" ? (
                          <div className="card_inside_content">
                            <p className="count">
                              <CountUp
                                start={0}
                                end={counts[item]}
                                duration={0.5}
                              />
                            </p>
                            <p className="kpi_title">
                              {categories[item]?.label}
                            </p>
                          </div>
                        ) : (
                          <div className="spaceBetween">
                            <div className="card_inside_content">
                              <p className="count">
                                <CountUp
                                  start={0}
                                  end={counts[item]?.providerCount}
                                  duration={0.5}
                                />
                              </p>
                              <p className="kpi_title">Utility Providers</p>
                            </div>
                            <div className="card_inside_content">
                              <p className="count">
                                <CountUp
                                  start={0}
                                  end={counts[item]?.plansCount}
                                  duration={0.5}
                                />
                              </p>
                              <p className="kpi_title">Utility Plans</p>
                            </div>
                          </div>
                        )}
                      </CardContent>
                    </CardActionArea>
                    <Tooltip
                      arrow
                      TransitionComponent={Zoom}
                      title={categories[item]?.label}
                    >
                      <CardActions>
                        <IconButton
                          onClick={() => history.push(categories[item]?.link)}
                          size="large"
                        >
                          <ArrowForward />
                        </IconButton>
                      </CardActions>
                    </Tooltip>
                  </Card>
                </div>
              </Grid>
            ))}
          </Grid>
        </div>
      </PageMainContent>
    </>
  );
};

export default DataCatalog;
