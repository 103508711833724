import React, { useCallback, useEffect, useState } from "react";
import UploadAndPreviewCSV from "../../../components/UploadAndPreviewCSV";
import OpsBreadcrumb from "../../../components/NewBreadcrumbs";
import PageHeader from "../../../components/PageHeader";
import PageMainContent from "../../../components/PageMainContent";
import {
  apiProvider,
  indexedEndPoints,
} from "../../../services/api/utilities/provider";
import Loader from "../../../components/Loader";
import Controls from "../../../components/controls/Controls";
import ToastMessage from "../../../components/ToastMessage";
import { useHistory } from "react-router-dom";
import { routePaths } from "../../../constants/RoutePaths";
import BulkUploadSteps from "../BulkUploadSteps";

const headers = [
  { key: "modelYear", label: "modelYear" },
  { key: "obccESN", label: "obccESN" },
  { key: "obccMacAddress", label: "obccMacAddress" },
  { key: "programCode", label: "programCode" },
  { key: "region", label: "region" },
  { key: "vin", label: "vin" },
];

const allBreadcrumbsLinks = [
  {
    link: routePaths.DATA_CATALOG,
    title: "Data Catalog",
  },
  {
    link: routePaths.VIN_VID_MAPPING,
    title: "VIN-VID Mapping",
  },
];

const BulkUploadVinVidMappings = () => {
  const { ADD_VIN_VID_MAPPING } = indexedEndPoints;
  const { tableData, setTableData, setErrors, UploadPreviewCSV } =
    UploadAndPreviewCSV();
  const [loading, setLoading] = useState(false);
  const [toast, setToast] = useState({
    isOpen: false,
    message: "",
    type: "",
  });
  const history = useHistory();

  useEffect(() => {
    if (!tableData?.length) return;
    const validMac = /^([0-9A-Fa-f]{2}[:]){5}([0-9A-Fa-f]{2})$/;
    const validRows = tableData?.filter(
      (row) =>
        row.modelYear?.length > 0 &&
        !isNaN(row.modelYear) &&
        row.obccESN?.length > 0 &&
        validMac.test(row.obccMacAddress) &&
        row.programCode?.length > 0 &&
        row.region?.length > 0 &&
        row.vin?.length > 0
    );
    if (validRows?.length < tableData?.length) {
      setErrors(
        `${
          tableData?.length - validRows?.length
        } rows are missing mandatory data or have been incorrectly formatted. Please correct and re-select the file.`
      );
      setTableData(validRows);
    }
    if (!validRows?.length) setTableData([]);
  }, [tableData]);

  const handleBulkUpload = useCallback(async () => {
    setLoading(true);
    const response = await apiProvider.post(ADD_VIN_VID_MAPPING, tableData);
    setLoading(false);
    if (response.statusCode >= 200 && response.statusCode <= 299) {
      setToast({
        isOpen: true,
        message: "Bulk upload of VIN-VID Mapping successful",
        type: "success",
      });
      setTimeout(() => history.push(routePaths.VIN_VID_MAPPING), [3000]);
    } else {
      setToast({
        isOpen: true,
        message: response?.data?.data,
        type: "error",
      });
    }
  }, [tableData]);

  return (
    <>
      <OpsBreadcrumb
        AllBreadcrumbsLinks={allBreadcrumbsLinks}
        title="Bulk Upload VIN-VID Mapping"
      />
      <PageHeader title="Bulk Upload VIN-VID Mapping" />
      <PageMainContent>
        <Loader isLoading={loading} />
        <BulkUploadSteps headers={headers} filename="VinVidMapping.csv" />
        <UploadPreviewCSV
          headers={headers?.map((item) => item.key)}
          showErrorsWithPreview={true}
        />
        {tableData?.length > 0 && (
          <div className="bulkUploadSubmit">
            <Controls.Button
              disabled={!tableData?.length}
              text="Submit"
              type="submit"
              onClick={handleBulkUpload}
            />
          </div>
        )}
      </PageMainContent>
      <ToastMessage toast={toast} setToast={setToast} autoHideDuration={2000} />
    </>
  );
};

export default BulkUploadVinVidMappings;
