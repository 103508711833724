import React, { useMemo } from "react";
import { Grid } from "@mui/material";
import useTable from "../../../../components/UseTable";
import "../../../billing/MoreInfo.scss";
import { firmwareStatusEnum } from "../../CHDEnum";

const headCells = [
  { id: "firmwareVersion", label: "Firmware Version" },
  {
    id: "updatedAt",
    label: "Updated At",
    render: (row, col) => row[col]?.replace("GMT", "UTC"),
    type: "date",
  },
];

const FirmwareHistorySideBar = (props) => {
  const data = props.DrawerOC.data;
  const filterFn = useMemo(() => ({ fn: (items) => items }), []);
  const firmwareHistory = useMemo(() => data?.firmwareHistory || [], [data]);
  const {
    tableContainer: TableContainer,
    tableHead: TableHead,
    tableBody: TableBody,
    tablePagination: TablePagination,
  } = useTable(firmwareHistory, headCells, filterFn);

  return (
    <>
      <Grid container>
        <Grid item xs={6}>
          <div className="single_plan_details_section">
            <p className="plan_title">ACCOUNT</p>
            <p className="plan_value">{data.accountName || "NA"}</p>
          </div>
        </Grid>

        <Grid item xs={6}>
          <div className="single_plan_details_section">
            <p className="plan_title">DEPOT</p>
            <p className="plan_value">{data.depotName || "NA"}</p>
          </div>
        </Grid>
        <Grid item xs={6}>
          <div className="single_plan_details_section">
            <p className="plan_title">MANUFACTURER</p>
            <p className="plan_value">{data.chargerManufacturer || "NA"}</p>
          </div>
        </Grid>
        <Grid item xs={6}>
          <div className="single_plan_details_section">
            <p className="plan_title">MODEL</p>
            <p className="plan_value">{data.chargerModel || "NA"}</p>
          </div>
        </Grid>
        <Grid item xs={6}>
          <div className="single_plan_details_section">
            <p className="plan_title">CURRENT FIRMWARE VERSION</p>
            <p className="plan_value">{data.firmwareVersion || "NA"}</p>
          </div>
        </Grid>
        <Grid item xs={6}>
          <div className="single_plan_details_section">
            <p className="plan_title">FIRMWARE STATUS</p>
            <p className="plan_value">
              {firmwareStatusEnum[data.firmwareStatus] || "NA"}
            </p>
          </div>
        </Grid>
      </Grid>
      {firmwareHistory?.length > 0 ? (
        <>
          <TableContainer>
            <TableHead />
            {TableBody}
          </TableContainer>
          {TablePagination}
        </>
      ) : (
        <p>No Firmware history available</p>
      )}
    </>
  );
};
export default FirmwareHistorySideBar;
