import ApiCore from "./utilities/core.js";
import { apiProvider } from "./utilities/provider";

const apiConfigurations = new ApiCore({
 
});

apiConfigurations.getRoles = (resource) => {
  return apiProvider.getAll(resource);
};

apiConfigurations.updateRole = (id, payload) => {
    return apiProvider.put(id, payload);
};

export default apiConfigurations;
