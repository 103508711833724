import React, { useCallback, useMemo, useState } from "react";
import UploadAndPreviewCSV from "../../../components/UploadAndPreviewCSV";
import OpsBreadcrumb from "../../../components/NewBreadcrumbs";
import PageHeader from "../../../components/PageHeader";
import PageMainContent from "../../../components/PageMainContent";
import {
  apiProvider,
  indexedEndPoints,
} from "../../../services/api/utilities/provider";
import Loader from "../../../components/Loader";
import Controls from "../../../components/controls/Controls";
import ToastMessage from "../../../components/ToastMessage";
import { useHistory, useLocation } from "react-router-dom";
import { routePaths } from "../../../constants/RoutePaths";
import BulkUploadSteps from "../BulkUploadSteps";
import { startCase } from "lodash";

const headers = [
  { key: "errorCode", label: "errorCode" },
  { key: "description", label: "description" },
  { key: "faultSource", label: "faultSource" },
  { key: "faultTitle", label: "faultTitle" },
  { key: "faultDefinition", label: "faultDefinition" },
  { key: "language", label: "language" },
  { key: "resolution1", label: "resolution1" },
  { key: "resolution2", label: "resolution2" },
  { key: "resolution3", label: "resolution3" },
  { key: "severity", label: "severity" },
  { key: "vendorErrorCode", label: "vendorErrorCode" },
  { key: "performanceImpact", label: "performanceImpact" },
  { key: "dangerPotentialDetected", label: "dangerPotentialDetected" },
  { key: "resolutionCategory", label: "resolutionCategory" },
  {
    key: "onsiteOrRemoteInterventionRequirement",
    label: "onsiteOrRemoteInterventionRequirement",
  },
];

const BulkUploadErrorCodes = () => {
  const { ADD_ERROR_CODE } = indexedEndPoints;
  const { tableData, UploadPreviewCSV } = UploadAndPreviewCSV();
  const [loading, setLoading] = useState(false);
  const [toast, setToast] = useState({
    isOpen: false,
    message: "",
    type: "",
  });
  const history = useHistory();
  const search = useLocation().search;
  const searchParams = new URLSearchParams(search);
  const manufacturer = searchParams.get("manufacturer");
  const model = searchParams.get("model");
  const chargerMetaId = searchParams.get("chargerMetaId");

  const allBreadcrumbsLinks = useMemo(
    () => [
      {
        link: routePaths.DATA_CATALOG,
        title: "Data Catalog",
      },
      {
        link: routePaths.ERROR_CODES_DASHBOARD,
        title: "Error Codes Dashboard",
      },
      {
        link: `${routePaths.ERROR_CODES}?${searchParams}`,
        title: "Error Codes",
      },
    ],
    []
  );

  const handleBulkUpload = useCallback(async () => {
    const data = tableData?.map((row) => {
      const rowObj = { ...row };
      rowObj.manufacturer = manufacturer;
      rowObj.model = model;
      rowObj.chargerMetaId = chargerMetaId;
      rowObj.resolution = {
        resolution1: row.resolution1,
        resolution2: row.resolution2,
        resolution3: row.resolution3,
      };
      rowObj.severity = startCase(row.severity);
      return rowObj;
    });
    setLoading(true);
    const response = await apiProvider.post(ADD_ERROR_CODE, data);
    setLoading(false);
    if (response.statusCode >= 200 && response.statusCode <= 299) {
      setToast({
        isOpen: true,
        message: "Bulk upload Error codes successful",
        type: "success",
      });
      setTimeout(
        () => history.push(`${routePaths.ERROR_CODES}?${searchParams}`),
        [3000]
      );
    } else {
      setToast({
        isOpen: true,
        message: response?.data?.data,
        type: "error",
      });
    }
  }, [tableData]);

  return (
    <>
      <OpsBreadcrumb
        AllBreadcrumbsLinks={allBreadcrumbsLinks}
        title="Bulk Upload Error Codes"
      />
      <PageHeader title="Bulk Upload Error Codes" />
      <PageMainContent>
        <Loader isLoading={loading} />
        <BulkUploadSteps headers={headers} filename="ErrorCodes.csv" />
        <UploadPreviewCSV headers={headers?.map((item) => item.key)} />
        {tableData?.length > 0 && (
          <div className="bulkUploadSubmit">
            <Controls.Button
              text="Submit"
              type="submit"
              onClick={handleBulkUpload}
            />
          </div>
        )}
      </PageMainContent>
      <ToastMessage toast={toast} setToast={setToast} autoHideDuration={2000} />
    </>
  );
};

export default BulkUploadErrorCodes;
