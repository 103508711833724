import * as React from "react";
import Autocomplete from "@mui/material/Autocomplete";
import Tabs from "@mui/material/Tabs";
import {
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
} from "@mui/material";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import SingleTypeFilter from "../../../components/all-filter-types/SingleFilter";
import TabPanel from "../../../components/all-filter-types/FilterTabPanel";
import Controls from "../../../components/controls/Controls";
import AccountsAndDepotsFilter from "../../../components/all-filter-types/accountAndDepot";
import {
  checkDepotSelected,
  checkSingleFilterSelected,
} from "../../../components/all-filter-types/isFilteredCheckFunctions";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";

const NewFilterReports = (props) => {
  const {
    userData,
    searchedBy,
    allAccounts,
    reportType,
    generatedOnType,
    customFromDate,
    customToDate,
    setCustomFromDate,
    setCustomToDate,
    applyFilter,
  } = props;
  const [value, setValue] = React.useState("Requested by");
  const [searcheddBy, setSearcheddBy] = React.useState(null);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const [tempAllAccounts, setTempAllAccounts] = React.useState(
    JSON.parse(JSON.stringify(allAccounts))
  );
  const [tempAllReportType, setTempAllReportType] = React.useState(
    JSON.parse(JSON.stringify(reportType))
  );
  const [tempGeneratedOnType, setTempGeneratedOnType] = React.useState(
    JSON.parse(JSON.stringify(generatedOnType))
  );
  const [tempSearchedBy, setTempSearchedBy] = React.useState(
    JSON.parse(JSON.stringify(searchedBy))
  );

  const clearAll = () => {
    if (tempAllAccounts[0].children) {
      tempAllAccounts[0].checked = false;
      tempAllAccounts[0].children.map((child) => {
        child.checked = false;
        if (child.depotsList) {
          child.depotsList.map((grandChild) => {
            grandChild.checked = false;
          });
        }
      });
    }
    if (tempAllReportType[0].children) {
      tempAllReportType[0].checked = false;
      tempAllReportType[0].children.map((child) => {
        child.checked = false;
      });
    }
    setTempAllAccounts([...tempAllAccounts]);
    setTempAllReportType([...tempAllReportType]);

    setTempSearchedBy("");
    setTempGeneratedOnType("");
    setCustomFromDate("");
    setCustomToDate("");
  };

  return (
    <>
      <div className="filters_with_vertical_tab">
        <Box sx={{ flexGrow: 1, bgcolor: "background.paper", display: "flex" }}>
          <Tabs
            orientation="vertical"
            variant="scrollable"
            value={value}
            onChange={handleChange}
            aria-label="Vertical tabs example"
            sx={{ borderRight: 1, borderColor: "divider" }}
          >
            <Tab
              label="Requested by"
              value={"Requested by"}
              icon={
                tempSearchedBy ? (
                  <FiberManualRecordIcon className="filter_dot" />
                ) : null
              }
              iconPosition="end"
            />
            <Tab
              label="Account and depot"
              value={"Account and depot"}
              icon={
                checkDepotSelected(tempAllAccounts) ? (
                  <FiberManualRecordIcon className="filter_dot" />
                ) : null
              }
              iconPosition="end"
            />
            <Tab
              label="Report type"
              value={"Report type"}
              icon={
                checkSingleFilterSelected(tempAllReportType) ? (
                  <FiberManualRecordIcon className="filter_dot" />
                ) : null
              }
              iconPosition="end"
            />
            <Tab
              label="Generated on"
              value={"Generated on"}
              icon={
                tempGeneratedOnType ? (
                  <FiberManualRecordIcon className="filter_dot" />
                ) : null
              }
              iconPosition="end"
            />
          </Tabs>
          <TabPanel value={value} index={"Requested by"}>
            <div className="filter_tab_vertical_body">
              <Autocomplete
                options={userData.reduce((acc, cur) => {
                  if (acc.indexOf(cur.email) === -1) {
                    acc.push(cur.email);
                  }
                  return acc;
                }, [])}
                onInputChange={(event) => {
                  if (event) {
                    setSearcheddBy(event.target.value);
                  }
                }}
                autoComplete
                id="requestedByFilter"
                className="searchWithIconWithoutBorder"
                value={tempSearchedBy}
                onChange={(event, value) => {
                  setTempSearchedBy(value);
                }}
                popupIcon=""
                open={
                  searcheddBy
                    ? searcheddBy.length >= 1 && tempSearchedBy !== searcheddBy
                    : false
                }
                renderInput={(params) => (
                  <TextField {...params} label="Search" size="=small" />
                )}
              />
            </div>
          </TabPanel>
          <TabPanel value={value} index={"Account and depot"}>
            <div className="filter_tab_vertical_body">
              <AccountsAndDepotsFilter
                _allAccounts={tempAllAccounts}
                setFuntion={setTempAllAccounts}
              />
            </div>
          </TabPanel>
          <TabPanel value={value} index={"Report type"}>
            <div className="filter_tab_vertical_body">
              <SingleTypeFilter
                _stateVariable={tempAllReportType}
                setFuntion={setTempAllReportType}
              />
            </div>
          </TabPanel>
          <TabPanel value={value} index={"Generated on"}>
            <div className="filter_tab_vertical_body">
              <div className="filter-radio-btns">
                <FormControl>
                  <RadioGroup
                    aria-label="gender"
                    name="gender1"
                    value={tempGeneratedOnType}
                    onChange={(e) => setTempGeneratedOnType(e.target.value)}
                    color="black"
                  >
                    <FormControlLabel
                      value="today"
                      control={<Radio />}
                      label="Today"
                    />
                    <FormControlLabel
                      value="Last7days"
                      control={<Radio />}
                      label="Last 7 days"
                    />
                    <FormControlLabel
                      value="Last30days"
                      control={<Radio />}
                      label="Last 30 days"
                    />
                    <FormControlLabel
                      value="Last90days"
                      control={<Radio />}
                      label="Last 90 days"
                    />
                    <FormControlLabel
                      value="Last6months"
                      control={<Radio />}
                      label="Last 6 months"
                    />
                    <FormControlLabel
                      value="Last12months"
                      control={<Radio />}
                      label="Last 12 months"
                    />
                    <FormControlLabel
                      value="custom"
                      control={<Radio />}
                      label="Custom"
                    />
                  </RadioGroup>
                </FormControl>
                {tempGeneratedOnType === "custom" && (
                  <>
                    <div className="custom-date-range">
                      <div className="single-date">
                        <TextField
                          label="From Date"
                          type="date"
                          InputLabelProps={{
                            shrink: true,
                          }}
                          value={customFromDate}
                          onChange={(event) => {
                            setCustomFromDate(event.target.value);
                          }}
                          inputProps={{
                            max: customToDate ? customToDate : null,
                          }}
                        />
                      </div>
                      <div className="single-date">
                        <TextField
                          label="To Date"
                          type="date"
                          InputLabelProps={{
                            shrink: true,
                          }}
                          value={customToDate}
                          onChange={(event) => {
                            setCustomToDate(event.target.value);
                          }}
                          inputProps={{
                            min: customFromDate ? customFromDate : null,
                          }}
                        />
                      </div>
                    </div>
                  </>
                )}
              </div>
            </div>
          </TabPanel>
        </Box>
        <div className="filter_action_btn">
          <Controls.Button
            disabled={
              !(
                tempSearchedBy ||
                checkDepotSelected(tempAllAccounts) ||
                checkSingleFilterSelected(tempAllReportType) ||
                tempGeneratedOnType
              )
            }
            text="Clear All"
            variant="outlined"
            onClick={() => clearAll()}
            className="btn_font_size"
          />
          <Controls.Button
            text="Apply"
            color="primary"
            onClick={() =>
              applyFilter(
                tempAllAccounts,
                tempAllReportType,
                tempGeneratedOnType,
                tempSearchedBy
              )
            }
            className="btn_font_size"
          />
        </div>
      </div>
    </>
  );
};

export default NewFilterReports;
