export const getAllUsers_Global = (
  state = { allUsers: [], isUsersLoaded: false },
  action
) => {
  switch (action.type) {
    case "GLOBAL_USERS":
      return { ...action.payload };
    default:
      return state;
  }
};

export const getAllRoles_Global = (
  state = { allRoles: [], isRolesLoaded: false },
  action
) => {
  switch (action.type) {
    case "GLOBAL_ROLES":
      return { ...action.payload };
    default:
      return state;
  }
};

export default { getAllUsers_Global, getAllRoles_Global };
