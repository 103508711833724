import React, { useEffect } from "react";
import {
  InputAdornment,
  TableBody,
  TableCell,
  TableRow,
  Toolbar,
  Grid,
  Tooltip,
  Badge,
} from "@mui/material";
import DownloadIcon from "@mui/icons-material/Download";
import FileDownloadOffSharpIcon from "@mui/icons-material/FileDownloadOffSharp";
import { isEqual } from "lodash";
import SearchIcon from "@mui/icons-material/Search";
import useTable from "../../../components/UseTable";
import { titleLabels } from "../../../constants/TitleLabels";
import no_Data from "./../../../assets/images/png/Vector.png";
import Controls from "../../../components/controls/Controls";
import TuneIcon from "@mui/icons-material/Tune";
import { format } from "date-fns";
import RefreshIcon from "@mui/icons-material/Refresh";
import "../reports.scss";
import ComponentLoader from "../../../components/ComponentLoader";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
const headCells = [
  { id: "user", label: "Requested by" },
  { id: "accountName", label: "Account" },
  { id: "depotName", label: "Depot" },
  { id: "reportTypeName", label: "Report type" },
  { id: "downloadLink", label: "Download link" },
  { id: "status", label: "Report Status" },
  { id: "reportId", label: "Report Id" },
  { id: "generatedOn", label: "Generated on" },
];

const Rows = React.memo((props) => {
  const { item } = props;
  const currentTime = new Date();
  const generatedOnTime = new Date(item.generatedOn);
  const isPendingAndFailed =
    item.status === "Processing" && (currentTime - generatedOnTime) / 60000 > 5;

  let content = null;
  let statusContent = null;

  if (item.status === "Successful") {
    if (item.url === "No data found in this date range") {
      content = item.url;
      statusContent = "Successful";
    } else {
      content = (
        <a href={item.url}>
          <DownloadIcon />
        </a>
      );
      statusContent = "Successful";
    }
  } else if (item.status === "Failed") {
    if (item.url === "Failed") {
      content = <FileDownloadOffSharpIcon />;
      statusContent = "Failed";
    } else {
      content = "Failed";
      statusContent = "Failed";
    }
  } else if (isPendingAndFailed) {
    content = <FileDownloadOffSharpIcon />;
    statusContent = "REQUEST TIMED OUT";
  } else if (item.status === "Processing") {
    if ((currentTime - generatedOnTime) / 60000 > 5) {
      content = <FileDownloadOffSharpIcon />;
      statusContent = "REQUEST TIMED OUT";
    } else {
      content = ""; // Empty string as per the requirement
      statusContent = "Processing";
    }
  }

  return (
    <>
      <TableRow hover={true} className="opentable">
        <TableCell>{item.user || titleLabels.NOT_AVAILABLE}</TableCell>
        <TableCell>
          {/* {item.accountName === "Terminated" ? (
            <Tooltip title={`Company Id - ${item.companyId}`}>
              <span style={{ color: "red" }}>
                {item.accountName} <HelpOutlineIcon fontSize="small" />{" "}
              </span>
            </Tooltip>
          ) : ( */}
          {item.accountName.length
            ? item.accountName.map((single) => {
                return <p className="zeroMargin">{single}</p>;
              })
            : titleLabels.NOT_AVAILABLE}
          {/* <p>

           </p> */}
          {/* )} */}
        </TableCell>
        <TableCell>
          {/* {item.depotName === "Terminated" ? (
            <Tooltip title={`Depot Id - ${item.depotId}`}>
              <span style={{ color: "red" }}>
                {item.depotName} <HelpOutlineIcon fontSize="small" />{" "}
              </span>
            </Tooltip>
          ) : ( */}
          {/* {item.depotName || titleLabels.NOT_AVAILABLE} */}
          {item.depotName.length
            ? item.depotName.map((single) => {
                return <p className="zeroMargin">{single}</p>;
              })
            : titleLabels.NOT_AVAILABLE}
          {/* )} */}
        </TableCell>
        <TableCell>
          {item.reportTypeName || titleLabels.NOT_AVAILABLE}
        </TableCell>

        <TableCell className="reportHistoryTable">{content}</TableCell>
        <TableCell className="reportHistoryTable">
          <span className={`bulb ${statusContent}`}></span>
          <Tooltip title={statusContent}>
            <span>{statusContent}</span>
          </Tooltip>
        </TableCell>
        <TableCell>{item.reportId || titleLabels.NOT_AVAILABLE}</TableCell>
        <TableCell>
          {(
            <>
              {" "}
              {format(
                new Date(item.generatedOn.slice(0, -1)),
                "dd-MMM-yyyy h.mm a"
              )}{" "}
              UTC{" "}
            </>
          ) || titleLabels.NOT_AVAILABLE}
        </TableCell>
      </TableRow>
    </>
  );
});
const ToolbarMemoised = React.memo(
  ({
    handleSearch,
    className,
    toggleDrawer,
    isFiltered,
    isAccountsLoaded,
    getReportsForTable,
    tempQueryParamTempObj,
  }) => {
    const handleRefresh = () => {
      if (isFiltered) {
        getReportsForTable(tempQueryParamTempObj);
      } else {
        getReportsForTable();
      }
    };
    return (
      <Toolbar className="table_toolbar">
        <Controls.Input
          label={"Search"}
          className={className}
          inputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
          }}
          onChange={handleSearch}
        />
        <Grid sm item />
        <Controls.Button
          text="Refresh"
          variant="outlined"
          startIcon={<RefreshIcon style={{ color: "#A3B6C7" }} />}
          onClick={() => {
            handleRefresh();
          }}
          className="filter"
          style={{
            border: "1px solid #A3B6C7",
            marginRight: "2px",
          }}
        />
        <Tooltip title="To obtain the latest report status, kindly refresh the page by clicking on the Refresh button.">
          <InfoOutlinedIcon
            fontSize="small"
            style={{ marginRight: "10px", color: "#A3B6C7" }}
          />
        </Tooltip>
        <Badge
          color="primary"
          variant="dot"
          invisible={isFiltered === true ? false : true}
        >
          <ComponentLoader isLoading={!isAccountsLoaded}>
            <Controls.Button
              text="Filter"
              variant="outlined"
              startIcon={
                <TuneIcon
                  style={{ color: isFiltered === true ? "#2770D8" : "#A3B6C7" }}
                />
              }
              onClick={() => {
                toggleDrawer(true, "FILTER", {});
              }}
              className="filter"
              style={{
                border:
                  isFiltered === true
                    ? "1px solid #2770D8"
                    : "1px solid #A3B6C7",
              }}
            />
          </ComponentLoader>
        </Badge>
      </Toolbar>
    );
  }
);

const RowsMemoised = React.memo((props) => {
  return <Rows {...props} />;
});

const TableBodyMemoised = React.memo(
  ({ data, callAPI }) => {
    return (
      <TableBody>
        {data.map((item, index) => (
          <RowsMemoised
            item={item}
            key={`${item.email}_${index}`}
            callAPI={callAPI}
          />
        ))}
      </TableBody>
    );
  },
  function (prevProps, nextProps) {
    if (isEqual(prevProps.data, nextProps.data)) {
      return true;
    }
    return false;
  }
);

const ReportsHostoryTable = (props) => {
  const {
    reportsHistory,
    toggleDrawer,
    isFiltered,
    isAccountsLoaded,
    getReportsForTable,
    tempQueryParamTempObj,
  } = props;

  reportsHistory.map((single) => {
    if (single.reportType === "SESSIONS") {
      single["reportTypeName"] = "Energy Dispensed - Sessions report";
      return single;
    } else if (single.reportType === "INTERVAL") {
      single["reportTypeName"] =
        "Energy Dispensed - Interval (15 minute) report";
      return single;
    } else if (single.reportType === "LCFS") {
      single["reportTypeName"] = "Carbon credit report";
      return single;
    } else if (single.reportType === "SESSIONS_FAULTS") {
      single["reportTypeName"] = "Charge Sessions Faults";
      return single;
    } else if (single.reportType === "PORT_TABLE") {
      single["reportTypeName"] = "Port Table";
      return single;
    } else if (single.reportType === "STATION_TABLE") {
      single["reportTypeName"] = "Station Table";
      return single;
    } else {
      single["reportTypeName"] = "";
      return single;
    }
  });
  const [filterFn, setFilterFn] = React.useState({ fn: (items) => items });
  const [TableContainer, setTableContainer] = React.useState("table");
  const [TableHead, setTableHead] = React.useState("thead");
  const [TablePagination, setTablePagination] = React.useState(() => <></>);
  const [recordsAfterPagingAndSorting, setRecordsAfterPagingAndSorting] =
    React.useState([]);

  const result = useTable(reportsHistory, headCells, filterFn, true);
  React.useEffect(() => {
    setTableContainer(() => result.tableContainer);
  }, [result.tableContainer]);
  React.useEffect(() => {
    setTableHead(() => result.tableHead);
  }, [result.tableHead]);
  React.useEffect(() => {
    setTablePagination(result.tablePagination);
  }, [result.tablePagination]);
  React.useEffect(() => {
    setRecordsAfterPagingAndSorting(result.recordsAfterPagingAndSorting);
  }, [result.recordsAfterPagingAndSorting]);

  const handleSearch = (e) => {
    setFilterFn({
      fn: (items) => {
        if (e.target.value?.trim() === "") return items;
        else
          return items.filter((row) =>
            Object.values(row)?.some((val) =>
              val
                ?.toString()
                ?.toLowerCase()
                ?.includes(e?.target?.value?.trim().toLowerCase())
            )
          );
      },
    });
  };

  return (
    <>
      <ToolbarMemoised
        handleSearch={handleSearch}
        className="searchInput"
        toggleDrawer={toggleDrawer}
        isFiltered={isFiltered}
        getReportsForTable={getReportsForTable}
        tempQueryParamTempObj={tempQueryParamTempObj}
        isAccountsLoaded={isAccountsLoaded}
      />

      <TableContainer>
        <TableHead />
        <React.Fragment>
          <TableBodyMemoised data={recordsAfterPagingAndSorting} />
        </React.Fragment>
      </TableContainer>
      {recordsAfterPagingAndSorting.length === 0 && (
        <div className="no_data_in_table">
          <div className="img_title">
            <img src={no_Data} alt="No data available" />
            <p className="no_data_title">No data available</p>
          </div>
        </div>
      )}

      {TablePagination}
    </>
  );
};

export default ReportsHostoryTable;
