import React from "react";

import {
  FormControl,
  InputLabel,
  Select as MuiSelect,
  MenuItem,
  FormHelperText,
} from "@mui/material";

const Select = (props) => {
  const {
    name,
    label,
    value,
    onChange,
    options,
    error = null,
    ...other
  } = props;

  return (
    <FormControl
      variant="outlined"
      error={error ? true : false}
      fullWidth="true"
      size="small"
    >
      <InputLabel>{label}</InputLabel>
      <MuiSelect
        label={label}
        name={name}
        value={value}
        onChange={onChange}
        {...other}
        MenuProps={{ "data-testid": `${name}Options` }}
      >
        {options?.map((item, index) => (
          <MenuItem key={index} value={item} data-testid={item}>
            {item}
          </MenuItem>
        ))}
      </MuiSelect>
      {error && <FormHelperText>{error}</FormHelperText>}
    </FormControl>
  );
};

export default Select;
