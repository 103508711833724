import * as React from "react";
import { Autocomplete, TextField } from "@mui/material";

const FilterMultiSelect = (props) => {
  let { _allValues, setFuntion, name, selectedValue } = props;
  const handleChange = (event, value) => {
    setFuntion(value);
  };
  return (
    <>
      <Autocomplete
        multiple
        name={name}
        disableCloseOnSelect
        value={selectedValue}
        onChange={handleChange}
        options={_allValues}
        renderInput={(params) => (
          <TextField {...params} variant="outlined" size="small" label={name} />
        )}
      />
    </>
  );
};
export default FilterMultiSelect;
