import React, { useEffect, useMemo, useCallback, useState } from "react";
import {
  Tooltip,
  Toolbar,
  InputAdornment,
  Menu,
  Grid,
  ListItemText,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import apiBilling from "../../services/api/Billing";
import SearchIcon from "@mui/icons-material/Search";
import ArrowDownwardSharpIcon from "@mui/icons-material/ArrowDownwardSharp";
import Loader from "../../components/Loader";
import Controls from "../../components/controls/Controls";
import useTable from "../../components/UseTable";
import ToastMessage from "../../components/ToastMessage";
import { CSVLink } from "react-csv";
import PageHeader from "../../components/PageHeader";
import { getCompanyDetails } from "./BillingServices";
import PageMainContent from "../../components/PageMainContent";
import OpsBreadcrumb from "../../components/NewBreadcrumbs";
import { bindActionCreators } from "redux";
import { useDispatch, useSelector } from "react-redux";
import { billingActionCreator } from "../../redux-state/actions";
import ComponentLoader from "../../components/ComponentLoader";

const AllBreadcrumbsLinks = [
  {
    link: "/billing",
    title: "Billing",
  },
];

const useStyles = makeStyles((theme) => ({
  "@global": {
    "*::-webkit-scrollbar": {
      width: "0.4em",
    },
    "*::-webkit-scrollbar-track": {
      "-webkit-box-shadow": "inset 0 0 6px rgba(0,0,0,0.00)",
    },
    "*::-webkit-scrollbar-thumb": {
      backgroundColor: "rgba(0,0,0,.1)",
      outline: "0px solid slategrey",
    },
  },
}));

const DHeaders = [
  { label: "Company Id", key: "companyId" },
  { label: "Customer Id", key: "customerId" },
  { label: "Start Date", key: "startDate" },
  { label: "End Date", key: "endDate" },
  { label: "Post Usage Status", key: "postUsageStatus" },
  { label: "Product Charge Id", key: "productChargeId" },
  { label: "Product Rate Plan Name", key: "productRatePlanChargeName" },
  { label: "Product SKU", key: "productSku" },
  { label: "Quantity", key: "quantity" },
  { label: "Requested By", key: "requestedBy" },
];

const tableMessage = (res) => {
  let status = "Successful";
  if (
    res.data.value.usageEntries[0].failureReason ===
    "No Matching Subscription Information found"
  ) {
    status = `Failed - [No Matching Subscription Information found]`;
  } else if (
    res.data.value.usageEntries[0].failureReason &&
    res.data.value.usageEntries[0].failureReason !==
      "No Matching Subscription Information found"
  ) {
    status = `Failed - [${res.data.value.usageEntries[0].failureReason}]`;
  }
  return status;
};

const tooltipMessage = (status) => {
  return status === "Failed - [No Matching Subscription Information found]"
    ? "Please check that customer SKU and rate plans in Ops Console are correct. If they are, reach out to mbangal@ford.com to confirm Zuora SKU and rate plans are aligned and then resubmit usage manually."
    : status === "Successful"
    ? "Inspect"
    : status === "Failed to retrieve post through API"
    ? "couldn't fetch the post you requested. Please check the API usage or try again later"
    : "Please resubmit usage manually. If that doesn’t work, create a L3 support ticket with customer details.";
};

const PostUsageStatus = ({
  setPostUsageLoading,
  item,
  billingHistory,
  setBillingHistory,
}) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [loadingResult, setLoadingResult] = useState(false);
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const [postUsageStatus, setPostUsageStatus] = useState("");
  const [failureReasons, setFailureReasons] = useState("");

  useEffect(() => {
    PostUsageResult();
  }, []);

  const PostUsageResult = async () => {
    let newStatus;
    let failureReason;
    setLoadingResult(true);
    if (item.postUsageBatchId) {
      setPostUsageLoading((prev) => ({
        ...prev,
        [item.postUsageBatchId]: true,
      }));
      await apiBilling.inspectFailure(item.postUsageBatchId).then((res) => {
        setPostUsageLoading((prev) => ({
          ...prev,
          [item.postUsageBatchId]: false,
        }));
        if (res.code === 200) {
          newStatus = tableMessage(res);
          failureReason = res.data.value;
          setLoadingResult(false);
        } else {
          newStatus = "Failed to retrieve post through API";
          failureReason = "Could not inspect at this moment";
          setLoadingResult(false);
        }
      });
    } else if (item.postUsageError) {
      failureReason = item?.postUsageError;
      setLoadingResult(false);
    } else {
      failureReason = "Input Error";
      setLoadingResult(false);
    }

    setPostUsageStatus(
      newStatus
        ? newStatus
        : item.item.response.error
        ? "Failed - API call failure"
        : "Successful"
    );
    setFailureReasons(failureReason);

    const updatedHistory = billingHistory.map((single) => {
      if (single.postUsageBatchId == item.postUsageBatchId) {
        single["postUsageStatus"] = newStatus
          ? newStatus
          : item.item.response.error
          ? "Failed - API call failure"
          : "Successful";
      }
      return single;
    });
    setBillingHistory(updatedHistory);
  };

  return (
    <>
      {loadingResult ? (
        <p className="blueColorLoading">Loading...</p>
      ) : (
        <>
          <Tooltip title={tooltipMessage(postUsageStatus)}>
            <span
              style={{
                color: postUsageStatus?.includes("Failed")
                  ? "#d01300"
                  : "#12468F",
                cursor: "grabbing",
              }}
              onClick={handleClick}
            >
              {postUsageStatus}
            </span>
          </Tooltip>
          <Menu
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleClose}
          >
            <pre className="postUsageStatus">
              {JSON.stringify(failureReasons, null, "\t")}
            </pre>
          </Menu>
        </>
      )}
    </>
  );
};

const ToolbarMemoised = React.memo(
  ({ handleSearch, className, isLoading, data }) => {
    return (
      <Toolbar className="billingHistoryToolbar">
        <Controls.Input
          label={"Search Bills"}
          className={className}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
          }}
          onChange={handleSearch}
        />
        <Grid sm item />
        <Grid>
          <ComponentLoader isLoading={isLoading}>
            <CSVLink
              filename="Billing-History.csv"
              data={data}
              headers={DHeaders}
              className="billingHistoryDownload"
            >
              <Controls.Button
                text="Download Report"
                disabled={data.length === 0}
                variant="contained"
                color="primary"
                startIcon={<ArrowDownwardSharpIcon />}
              />
            </CSVLink>
          </ComponentLoader>
        </Grid>
      </Toolbar>
    );
  }
);

const BillingHistoryTable = (props) => {
  const classes = useStyles();
  const {
    match: {
      params: { id },
    },
  } = props;

  const dispatch = useDispatch();
  const { saveAllBillingRatePlansGlobal } = bindActionCreators(
    billingActionCreator,
    dispatch
  );
  const { allBillingRatePlansResponse, isBillingRatePlansLoaded } = useSelector(
    (state) => state.getBillingRatePlans_Global
  );
  const getRatePlansResponse = () => {
    if (isBillingRatePlansLoaded === false) {
      return saveAllBillingRatePlansGlobal();
    }
    return allBillingRatePlansResponse;
  };
  const [filterFn, setFilterFn] = useState({ fn: (items) => items });
  const [billingHistory, setBillingHistory] = useState([]);
  const [companyDetails, setCompanyDetails] = useState({});
  const [isLoading, setLoading] = useState(false);
  const [postUsageLoading, setPostUsageLoading] = useState({});
  const [toast, setToast] = useState({
    isOpen: false,
    message: "",
    type: "",
  });

  const headCells = useMemo(
    () => [
      { id: "customerId", label: "Customer ID" },
      { id: "startDate", label: "Start Date", type: "date" },
      { id: "endDate", label: "End Date", type: "date" },
      {
        id: "productRatePlanChargeName",
        label: "Product Rate Plan Name",
        render: (row) => (
          <ListItemText
            primary={row.productRatePlanChargeName}
            secondary={row.productChargeId}
          />
        ),
      },
      { id: "productSku", label: "Product SKU" },
      { id: "quantity", label: "Quantity" },
      {
        id: "postUsageStatus",
        label: "Post Usage Status",
        disableSorting: "true",
        render: (row) => (
          <PostUsageStatus
            setPostUsageLoading={setPostUsageLoading}
            item={row}
            setBillingHistory={setBillingHistory}
            billingHistory={billingHistory}
          />
        ),
      },
      { id: "requestedBy", label: "Requested By" },
    ],
    [billingHistory]
  );

  const {
    tableContainer: TableContainer,
    tableHead: TableHead,
    tableBody: TableBody,
    tablePagination: TablePagination,
    searchData: searchData,
  } = useTable(billingHistory, headCells, filterFn, true, true);

  useEffect(() => {
    getCompanyDetailsBasedOnId();
  }, []);

  const getCompanyDetailsBasedOnId = async () => {
    const res = await getCompanyDetails(id);
    if (res?.statusCode === 200) setCompanyDetails({ ...res?.data });
  };

  const handleSearch = useCallback((e) => {
    let target = e.target.value?.trim();
    setFilterFn({
      fn: (items) => {
        if (target === "") return items;
        else
          return items.filter(
            (x) =>
              (x.customerId &&
                x.customerId.toLowerCase().includes(target.toLowerCase())) ||
              (x.startDate &&
                x.startDate.toLowerCase().includes(target.toLowerCase())) ||
              (x.endDate &&
                x.endDate.toLowerCase().includes(target.toLowerCase())) ||
              (x.productChargeId &&
                x.productChargeId
                  .toLowerCase()
                  .includes(target.toLowerCase())) ||
              x?.productRatePlanChargeName
                ?.toLowerCase()
                ?.includes(target?.toLowerCase()) ||
              (x.productSku &&
                x.productSku.toLowerCase().includes(target.toLowerCase())) ||
              (x.quantity &&
                x.quantity
                  .toString()
                  .toLowerCase()
                  .includes(target.toLowerCase())) ||
              (x.requestedBy &&
                x.requestedBy
                  .toString()
                  .toLowerCase()
                  .includes(target.toLowerCase()))
            // (x.postUsageStatus &&
            //   x.postUsageStatus.toLowerCase().includes(target.toLowerCase()))
          );
      },
    });
  }, []);

  useEffect(() => {
    setLoading(true);
    apiBilling.billingHistory(id).then(async (res) => {
      if (res.code === 200) {
        let records = res.data.map((item) => {
          return {
            companyId: item.companyId,
            customerId: item.value[0].customerId,
            startDate: item.value[0].startDate,
            endDate: item.value[0].endDate,
            productChargeId: item.value[0].productChargeId,
            productSku: item.value[0].productSku,
            quantity: item.value[0].quantity,
            item: item,
            requestedBy: item.requestedBy ? item.requestedBy : "N/A",
            error: item.response.error ? item.response.error : "",
            postUsageBatchId: item.response.value
              ? item.response.value.postUsageBatchId
              : null,
            postUsageError: item.response.message,
          };
        });

        const ratePlansResponse = await getRatePlansResponse();
        setLoading(false);
        if (ratePlansResponse.code === 200) {
          records = records.map((itm) => ({
            ...ratePlansResponse.data.find(
              (item) =>
                item.productRatePlanChargeId === itm.productChargeId && item
            ),
            ...itm,
          }));
          setBillingHistory(records);
        } else {
          setBillingHistory(records);
          setToast({
            isOpen: true,
            message: "Failed to fetch Rate plans",
            type: "error",
          });
        }
      } else {
        setToast({
          isOpen: true,
          message: "Internal Service Failure",
          type: "error",
        });
      }
    });
  }, []);

  return (
    <>
      <Loader
        isLoading={isLoading || Object.values(postUsageLoading)?.includes(true)}
      />
      <OpsBreadcrumb
        AllBreadcrumbsLinks={AllBreadcrumbsLinks}
        title="Billing History"
      />
      <PageHeader
        title={`Billing history - ${companyDetails.companyName || ""}`}
      />
      <PageMainContent>
        <ToolbarMemoised
          handleSearch={handleSearch}
          className="searchInput"
          isLoading={isLoading}
          data={searchData}
        />
        <TableContainer>
          <TableHead />
          {TableBody}
        </TableContainer>
        {/* {TablePagination} */}
        <ToastMessage toast={toast} setToast={setToast} />
      </PageMainContent>
    </>
  );
};

export default BillingHistoryTable;
