import React, { lazy, Suspense } from "react";
import { useDispatch } from "react-redux";
import {
  Switch,
  Route,
  Redirect,
  useLocation,
  useHistory,
} from "react-router-dom";
import ProctectedRoute from "./ProtectedRoute";
import { CssBaseline, Drawer, IconButton, Divider } from "@mui/material";
import { ThemeProvider, makeStyles } from "@mui/styles";
import { StyledEngineProvider } from "@mui/material/styles";
import { createTheme } from "@mui/material/styles";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import clsx from "clsx";
import { getData } from "../utils/Storage";
import { variables } from "../constants/Variables";
import { addUser, removeUser } from "../redux-state/actions/UserAction";
import { routePaths } from "../constants/RoutePaths";
import { clearLocalStorage } from "../utils/Storage";

import SideBar from "../components/SideBar";
import Header from "../components/Header";
import Login from "../pages/login/login";
import ForgotPasswordEmail from "../pages/forgot-password-email/forgotPasswordEmail";
import ForgotPassword from "../pages/forgot-password/forgotPassword";
const billing = lazy(() => import("../pages/billing/index"));
const users = lazy(() => import("../pages/users/index"));

const Requests = lazy(() => import("../pages/request/index"));
const Companies = lazy(() => import("../pages/companies"));
const usageAnalytics = lazy(() => import("../pages/usage-analytics/index"));
import ReviewConfirm from "../pages/request/review-confirm/index";
import CircuitMonitor from "./../pages/circuit-monitor/index";
import CompanyDepotDetails from "../pages/companies/CompanyDepotDetails";
import BillingCompanyDepot from "../pages/billing/BillingCompanyDepots";
import Analytics from "../pages/analytics/index";
const ChargerHealthDashboard = lazy(() => import("../pages/charger-health"));
const InternalApis = lazy(() => import("../pages/services"));
const PreProvisioningMain = lazy(() => import("../pages/pre-provisioning"));
import BillingHistoryTable from "../pages/billing/BillingHistoryTable";
import NotFound from "../components/NotFound";
import "./../App.scss";
const InternalApisHistory = lazy(() =>
  import("../pages/services/InternalApisHistory")
);
import FaultsOpenFaults from "../pages/charger-health/charger-health-modules/FaultsOpenFaults";
import ConnectivityOfflinePorts from "../pages/charger-health/charger-health-modules/ConnectivityOfflinePorts";
const Reports = lazy(() => import("../pages/reports/index"));
import ReportsHistory from "../pages/reports/reports-history/ReportsHistory";
import VehicleDiscovery from "../pages/vehicleDiscovery";
import DepotDetailsPage from "../pages/vehicleDiscovery/depot";
import LoaderForLazy from "../components/LoaderForLazy";
import DataCatalog from "../pages/data-catalog/DataCatalog";
import ErrorCodes from "../pages/data-catalog/errorCodes/ErrorCodes";
import AggErrorCodes from "../pages/data-catalog/errorCodes/AggErrorCodes";
import BulkUploadErrorCodes from "../pages/data-catalog/errorCodes/BulkUploadErrorCodes";
import VinVidMapping from "../pages/data-catalog/vinVidMapping/VinVidMapping";
import BulkUploadVinVidMappings from "../pages/data-catalog/vinVidMapping/BulkUploadVinVidMappings";
import Chargers from "../pages/data-catalog/chargers/Chargers";
import AggChargers from "../pages/data-catalog/chargers/AggChargers";
import BulkUploadChargers from "../pages/data-catalog/chargers/BulkUploadChargers";
import ChargerFirmwareDetials from "../pages/charger-health/charger-firmware-details";
import ChargerRegistrationHistory from "../pages/charger-health/charger-firmware-details/ChargerRegistrationHistory";
import Depots from "../pages/analytics/Depots";
import DepotDetails from "../pages/analytics/DepotDetails";
import UserAlertSettings from "../pages/users/UserAlertSettings";
import UtilityProvider from "../pages/data-catalog/utilityRates/UtilityProvider";
import UtilityPlan from "../pages/data-catalog/utilityRates/UtilityPlan";
import UtilityPlanSearch from "../pages/data-catalog/utilityRates/UtilityPlanSeach";
import IntegrationTestReport from "../pages/data-catalog/chargers/IntergrationTestReport";
import VehiclesIndex from "../pages/data-catalog/vehicles/vehiclesIndex";
import Vehicles from "../pages/data-catalog/vehicles/Vehicles";
import BulkUploadVehicles from "../pages/data-catalog/vehicles/BulkUploadVehicles";
import ChargerHistory from "../pages/charger-health/charger-firmware-details/ChargerHistory";
import ChargerReplacementHistory from "../pages/charger-health/charger-firmware-details/ChargerReplacementHistory";
import RolesModules from "../pages/configurations/RolesModules";
import ScheduledServices from "../pages/services/ScheduledServices";
import DataCatalogHistory from "../pages/data-catalog/DataCatalogHistory";

const drawerWidth = 240;
const theme = createTheme();

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 36,
  },
  hide: {
    display: "none",
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: "nowrap",
    background: "red",
  },
  drawerOpen: {
    width: drawerWidth,
    marginTop: "70px",
    borderRadius: "4px",
    background: "linear-gradient(135deg, #002F6C 0%, #17498F 100%)",
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    background: "linear-gradient(135deg, #002F6C 0%, #17498F 100%)",
    overflowX: "hidden",
    marginTop: "70px",
    borderRadius: "4px",
    width: `calc(${theme.spacing(7)} + 1px)`,
    [theme.breakpoints.up("sm")]: {
      width: `calc(${theme.spacing(9)} + 1px)`,
    },
  },
  toolbar: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
  },
  content: {
    flexGrow: 1,
    marginTop: "70px",
    overflowX: "auto",
    height: "calc(100vh - 70px)",
    width: `calc(100% - 73px)`,
  },
  shrink: {
    width: `calc(100% - ${drawerWidth}px)`,
  },
}));

const AppContent = ({ setIsLoggedIn }) => {
  const classes = useStyles();
  const [open, setOpen] = React.useState(true);
  const handleDrawerAll = () => {
    setOpen(!open);
  };

  return (
    <>
      <Header setIsLoggedIn={setIsLoggedIn} />
      <div className={classes.root}>
        <Drawer
          variant="permanent"
          className={clsx(classes.drawer, {
            [classes.drawerOpen]: open,
            [classes.drawerClose]: !open,
          })}
          classes={{
            paper: clsx({
              [classes.drawerOpen]: open,
              [classes.drawerClose]: !open,
            }),
          }}
        >
          <div className={classes.toolbar}>
            <IconButton onClick={handleDrawerAll} size="large">
              {open === true ? (
                <ChevronLeftIcon style={{ color: "white" }} />
              ) : (
                <ChevronRightIcon style={{ color: "white" }} />
              )}
            </IconButton>
          </div>
          <Divider style={{ background: "white" }} />
          <SideBar />
        </Drawer>
        <main className={`${classes.content} ${open ? classes.shrink : ""}`}>
          <Switch>
            <Redirect exact from="/login" to="/home" />
            <Route exact path="/home" component={Analytics} />
            <ProctectedRoute path={routePaths.DEPOTS} component={Depots} />
            <ProctectedRoute
              path={routePaths.DEPOT_DETAILS}
              component={DepotDetails}
            />
            <ProctectedRoute
              path={routePaths.USER_ALERT_SETTINGS}
              component={UserAlertSettings}
            />
            <ProctectedRoute path={routePaths.DEPOTS} component={Depots} />
            <ProctectedRoute
              path={routePaths.DEPOT_DETAILS}
              component={DepotDetails}
            />
            <ProctectedRoute
              exact
              path={routePaths.CHARGERHEALTH}
              component={ChargerHealthDashboard}
            />
            <ProctectedRoute
              exact
              path={routePaths.USAGE_ANALYTICS}
              component={usageAnalytics}
            />
            <ProctectedRoute
              exact
              path={routePaths.CHARGERFIRMWAREDETAILS}
              component={ChargerFirmwareDetials}
            />
            <ProctectedRoute
              exact
              path={routePaths.CHARGER_HISTORY}
              component={ChargerHistory}
            />
            <ProctectedRoute
              exact
              path={routePaths.CHARGER_REGISTRATION_HISTORY}
              component={ChargerRegistrationHistory}
            />
            <ProctectedRoute
              exact
              path={routePaths.CHARGER_REPLACEMENT_HISTORY}
              component={ChargerReplacementHistory}
            />
            <Route
              exact
              path={routePaths.OFFLINEPORTS}
              component={ConnectivityOfflinePorts}
            />
            <Route
              exact
              path={routePaths.OPENFAULTS}
              component={FaultsOpenFaults}
            />
            <ProctectedRoute path={routePaths.USERS} component={users} />
            <Route
              path={routePaths.CIRCUITMONITOR}
              component={CircuitMonitor}
            />

            <ProctectedRoute
              exact
              path={routePaths.REQUESTS}
              component={Requests}
            />
            <Route
              exact
              path={routePaths.REVIEWCONFIRM}
              component={ReviewConfirm}
            />
            <ProctectedRoute
              exact
              path={routePaths.COMPANIES}
              component={Companies}
            />
            <Route
              exact
              path={routePaths.COMPANYDEPOTDETAILS}
              component={CompanyDepotDetails}
            />
            <ProctectedRoute
              path={routePaths.BILLING}
              exact
              component={billing}
            />
            <Route
              exact
              path={routePaths.BILLINGDEPOTDETAILS}
              component={BillingCompanyDepot}
            />
            <Route
              path={routePaths.BILLING_HISTORY}
              exact
              component={BillingHistoryTable}
            />
            <ProctectedRoute
              exact
              path={routePaths.DATA_CATALOG}
              component={DataCatalog}
            />
            <ProctectedRoute
              exact
              path={routePaths.ERROR_CODES_DASHBOARD}
              component={AggErrorCodes}
            />
            <ProctectedRoute
              exact
              path={routePaths.ERROR_CODES}
              component={ErrorCodes}
            />
            <ProctectedRoute
              exact
              path={routePaths.BULK_UPLOAD_ERROR_CODES}
              component={BulkUploadErrorCodes}
            />
            <ProctectedRoute
              exact
              path={routePaths.CHARGERS_DASHBOARD}
              component={AggChargers}
            />
            <Route
              exact
              path={routePaths.VEHICLES_DASHBOARD}
              component={VehiclesIndex}
            />
            <Route exact path={routePaths.VEHICLES} component={Vehicles} />
            <Route
              exact
              path={routePaths.BULK_UPLOAD_VEHICLES}
              component={BulkUploadVehicles}
            />
            <ProctectedRoute
              exact
              path={routePaths.CHARGERS}
              component={Chargers}
            />
            <ProctectedRoute
              exact
              path={routePaths.BULK_UPLOAD_CHARGERS}
              component={BulkUploadChargers}
            />
            <ProctectedRoute
              exact
              path={routePaths.INTEGRATION_TEST_REPORT}
              component={IntegrationTestReport}
            />
            <ProctectedRoute
              exact
              path={routePaths.VIN_VID_MAPPING}
              component={VinVidMapping}
            />
            <ProctectedRoute
              exact
              path={routePaths.BULK_UPLOAD_VIN_VID_MAPPINGS}
              component={BulkUploadVinVidMappings}
            />
            <ProctectedRoute
              exact
              path={routePaths.UTILITY_RATES}
              component={UtilityProvider}
            />
            <ProctectedRoute
              exact
              path={routePaths.UTILITY_PROVIDER_PLANS}
              component={UtilityPlan}
            />
            <ProctectedRoute
              path={routePaths.CONFIGURATIONS}
              component={RolesModules}
            />
            {/*Need to add protected  route */}
            <ProctectedRoute
              exact
              path={routePaths.SERVICES}
              component={InternalApis}
            />
            <ProctectedRoute
              exact
              path={routePaths.SERVICES_SCHEDULED}
              component={ScheduledServices}
            />
            <ProctectedRoute
              exact
              path={routePaths.PREPROVISIONING}
              component={PreProvisioningMain}
            />
            <ProctectedRoute
              exact
              path={routePaths.UTILITY_PLAN_SEARCH}
              component={UtilityPlanSearch}
            />
            <ProctectedRoute
              exact
              path={routePaths.REPORTS}
              component={Reports}
            />
            <Route
              exact
              path={routePaths.DATA_CATALOG_HISTORY}
              component={DataCatalogHistory}
            />
            <Route
              exact
              path={routePaths.SERVICES_HISTORY}
              component={(props) => (
                <Suspense fallback={<LoaderForLazy isLoading={true} />}>
                  <InternalApisHistory {...props} />
                </Suspense>
              )}
            />
            <Route
              exact
              path={routePaths.REPORTS_HISTORY}
              component={ReportsHistory}
            />
            <ProctectedRoute
              exact
              path={routePaths.VEHICLES_DISCOVERY}
              component={VehicleDiscovery}
            />
            <ProctectedRoute
              exact
              path={routePaths.VEHICLES_DISCOVERY_DEPOT_METRICS}
              component={DepotDetailsPage}
            />
            <Route component={NotFound} />
          </Switch>
        </main>
      </div>
    </>
  );
};

const App = () => {
  const dispatch = useDispatch();
  const [isLoggedIn, setIsLoggedIn] = React.useState(false);
  const { pathname } = useLocation();
  const { push } = useHistory();

  React.useEffect(() => {
    const accessToken = getData(variables.ACCESS_TOKEN);
    const userFirstName = getData(variables.USER_FIRST_NAME);
    const userLastName = getData(variables.USER_LAST_NAME);
    const userEmail = getData(variables.USER_EMAIL);
    const userPhoneNumber = getData(variables.USER_PHONE_NUMBER);
    const userRole = getData(variables.USER_ROLE);
    const userRoleId = getData(variables.USER_ROLE_ID);
    const componentPrivilege = JSON.parse(
      getData(variables.USER_COMPONENT_PRIVILEGE)
    );
    const rolePrivilege = JSON.parse(getData(variables.USER_ROLE_PRIVILEGE));

    if (accessToken && rolePrivilege) {
      dispatch(
        addUser({
          accessToken,
          userFirstName,
          userLastName,
          userEmail,
          userPhoneNumber,
          userRole,
          userRoleId,
          componentPrivilege,
          rolePrivilege,
        })
      );
      setIsLoggedIn(true);
    } else {
      clearLocalStorage();
      dispatch(removeUser());
      localStorage.setItem("redirectUri", pathname);
      if (
        !pathname.includes("/forgot-password-email") &&
        !pathname.includes("/forgot-password")
      ) {
        push("/login");
      }
      setIsLoggedIn(false);
    }
  }, [dispatch]);

  return (
    <>
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={theme}>
          {!isLoggedIn ? (
            <Switch>
              <Redirect exact from="/" to="/login" />
              <Route
                exact
                path="/login"
                render={() => <Login setIsLoggedIn={setIsLoggedIn}></Login>}
              />
              <Route
                path="/forgot-password-email"
                component={ForgotPasswordEmail}
              />
              <Route path="/forgot-password" component={ForgotPassword} />
            </Switch>
          ) : (
            <AppContent setIsLoggedIn={setIsLoggedIn} />
          )}
        </ThemeProvider>
      </StyledEngineProvider>
      <CssBaseline />
    </>
  );
};
export default App;
