import React, { Suspense } from "react";
import { Route, Redirect } from "react-router-dom";
import { useSelector } from "react-redux";
import { mapRouteToTabName } from "../constants/MapRouteToTabName";
import ErrorBoundary from "./ErrorBoundary";
import LoaderForLazy from "../components/LoaderForLazy";

function ProctectedRoute({ path, component: Component, ...rest }) {
  const roles = useSelector((state) => state.user.rolePrivilege);

  return (
    <>
      { (roles && roles.includes(mapRouteToTabName[path])) ? ( 
       
        <ErrorBoundary>
          <Route
            path={path}
            {...rest}
            render={(props) => (
              <Suspense fallback={<LoaderForLazy isLoading={true} />}>
                <Component {...props} {...rest} />
              </Suspense>
            )}
          />
        </ErrorBoundary>
      ) : (
        <Redirect to="/" />
      )}
    </>
  );
}

export default ProctectedRoute;
