import {
  apiProvider,
  indexedEndPoints,
} from "../../services/api/utilities/provider";
const {
  GET_DEPOT_DEPLOYMENT_DETAILS,
  ACTIVE_DEPOTS,
  COMPANIES_V3_API,
  GET_DEPOT_DETAILS,
  COMPANIES_EXPORT_CSV,
  FDC_LOGIN,
} = indexedEndPoints;

export const getDepotDeployment = async (depotId) =>
  await apiProvider.getAll(`${GET_DEPOT_DEPLOYMENT_DETAILS}/${depotId}`);

export const getBasicCompaniesDetails = async (payload = {}) =>
  await apiProvider.post(COMPANIES_V3_API, payload);

export const fetchDepotDetails = async (depotId) =>
  await apiProvider.getAll(GET_DEPOT_DETAILS.replace("id", depotId));

export const getFDCAccessToken = async (companyId) =>
  await apiProvider.getAll(FDC_LOGIN.replace("id", companyId));

export const companiesExportCsv = async (payload) => {
  let dataSize = 0;
  return fetch(COMPANIES_EXPORT_CSV, {
    method: "POST",
    headers: apiProvider.getHeaders().headers,
    body: JSON.stringify(payload),
  })
    .then(function (response) {
      if (response.status !== 200) {
        return "";
      } else {
        return response.blob();
      }
    })

    .then((data) => {
      dataSize = data.size;
      if (dataSize > 0) {
        var a = document.createElement("a");
        a.href = window.URL.createObjectURL(data);
        a.download = `Companies.csv`;
        a.click();
        return dataSize;
      } else return 0;
    });
};

export const getActiveDepots = async () =>
  await apiProvider.getAll(ACTIVE_DEPOTS);
export default {
  getDepotDeployment,
  getActiveDepots,
  companiesExportCsv,
};
