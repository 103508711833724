import React, { useState } from "react";
import { Box, Tab, Tabs } from "@mui/material";
import "../../charger-health/ChargerHealth.scss";
import { titleLabels } from "../../../constants/TitleLabels";
import TabPanel from "../../../components/all-filter-types/FilterTabPanel";
import Controls from "../../../components/controls/Controls";
import { checkSingleFilterSelected } from "../../../components/all-filter-types/isFilteredCheckFunctions";
import SingleTypeFilter from "../../../components/all-filter-types/SingleFilter";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";

const ErrorCodeDrawerDetails = ({ drawerObj }) => {
  return (
    <div>
      <div className="chargeDetailsSideDrawer">
        <div className="charger_details_section">
          <div className="charger_heading">
            <p>ERROR CODE DETAILS</p>
          </div>
          <div className="all_details">
            <div className="single_detail">
              <p className="c_title">Error Code:</p>
              <p className="description">
                {drawerObj.errorCode || titleLabels.NOT_AVAILABLE}
              </p>
            </div>
            <div className="single_detail">
              <p className="c_title">Vendor Error Code:</p>
              <p className="description">
                {drawerObj.vendorErrorCode || titleLabels.NOT_AVAILABLE}
              </p>
            </div>
            <div className="single_detail">
              <p className="c_title">Description:</p>
              <p className="description">
                {drawerObj.description || titleLabels.NOT_AVAILABLE}
              </p>
            </div>
            <div className="single_detail">
              <p className="c_title">Fault Source:</p>
              <p className="description">
                {drawerObj.faultSource || titleLabels.NOT_AVAILABLE}
              </p>
            </div>
            <div className="single_detail">
              <p className="c_title">Fault Title:</p>
              <p className="description">
                {drawerObj.faultTitle || titleLabels.NOT_AVAILABLE}
              </p>
            </div>
            <div className="single_detail">
              <p className="c_title">Fault Definition:</p>
              <p className="description">
                {drawerObj.faultDefinition || titleLabels.NOT_AVAILABLE}
              </p>
            </div>
            <div className="single_detail">
              <p className="c_title">Manufacturer:</p>
              <p className="description">
                {drawerObj.manufacturer || titleLabels.NOT_AVAILABLE}
              </p>
            </div>
            <div className="single_detail">
              <p className="c_title">Model:</p>
              <p className="description">
                {drawerObj.model || titleLabels.NOT_AVAILABLE}
              </p>
            </div>
            <div className="single_detail">
              <p className="c_title">Severity:</p>
              <p className="description">
                {drawerObj.severity || titleLabels.NOT_AVAILABLE}
              </p>
            </div>
            <div className="single_detail">
              <p className="c_title">Language:</p>
              <p className="description">
                {drawerObj.language || titleLabels.NOT_AVAILABLE}
              </p>
            </div>
            <div className="single_detail">
              <p className="c_title">Danger Potential Detected:</p>
              <p className="description">
                {drawerObj.dangerPotentialDetected || titleLabels.NOT_AVAILABLE}
              </p>
            </div>
            <div className="single_detail">
              <p className="c_title">
                Onsite Or Remote Intervention Requirement:
              </p>
              <p className="description">
                {drawerObj.onsiteOrRemoteInterventionRequirement ||
                  titleLabels.NOT_AVAILABLE}
              </p>
            </div>
            <div className="single_detail">
              <p className="c_title">Performance Impact:</p>
              <p className="description">
                {drawerObj.performanceImpact || titleLabels.NOT_AVAILABLE}
              </p>
            </div>
            <div className="single_detail">
              <p className="c_title">Resolution Category:</p>
              <p className="description">
                {drawerObj.resolutionCategory || titleLabels.NOT_AVAILABLE}
              </p>
            </div>
            <div className="single_detail">
              <p className="c_title">Resolution:</p>
              <p className="description">
                {drawerObj.resolution?.map((item, i) => (
                  <div key={i}>{item}</div>
                ))}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export const ErrorCodesFilter = ({
  severityFilter,
  setSeverityFilter,
  languageFilter,
  setLanguageFilter,
  applyFilter,
  clearAll,
}) => {
  const [value, setValue] = useState("Severity");
  const handleChange = (_, newValue) => {
    setValue(newValue);
  };

  return (
    <div className="filters_with_vertical_tab">
      <Box sx={{ flexGrow: 1, bgcolor: "background.paper", display: "flex" }}>
        <Tabs
          orientation="vertical"
          variant="scrollable"
          value={value}
          onChange={handleChange}
          aria-label="Vertical tabs example"
          sx={{ borderRight: 1, borderColor: "divider" }}
        >
          <Tab
            label="Severity"
            value={"Severity"}
            icon={
              checkSingleFilterSelected(severityFilter) ? (
                <FiberManualRecordIcon className="filter_dot" />
              ) : null
            }
            iconPosition="end"
          />
          <Tab
            label="Language"
            value={"Language"}
            icon={
              checkSingleFilterSelected(languageFilter) ? (
                <FiberManualRecordIcon className="filter_dot" />
              ) : null
            }
            iconPosition="end"
          />
        </Tabs>
        <TabPanel value={value} index={"Severity"}>
          <div className="filter_tab_vertical_body">
            <SingleTypeFilter
              _stateVariable={severityFilter}
              setFuntion={setSeverityFilter}
            />
          </div>
        </TabPanel>
        <TabPanel value={value} index={"Language"}>
          <div className="filter_tab_vertical_body">
            <SingleTypeFilter
              _stateVariable={languageFilter}
              setFuntion={setLanguageFilter}
            />
          </div>
        </TabPanel>
      </Box>
      <div className="filter_action_btn">
        <Controls.Button
          disabled={
            !(
              checkSingleFilterSelected(severityFilter) ||
              checkSingleFilterSelected(languageFilter)
            )
          }
          text="Clear All"
          variant="outlined"
          onClick={() => clearAll()}
          className="btn_font_size"
        />
        <Controls.Button
          text="Apply"
          color="primary"
          onClick={() => applyFilter()}
          className="btn_font_size"
        />
      </div>
    </div>
  );
};

export default ErrorCodeDrawerDetails;
