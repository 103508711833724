import React, { useState } from "react";
import {
  GoogleMap,
  InfoWindow,
  Marker,
  MarkerClusterer,
} from "@react-google-maps/api";
import MapMarkerActiveDepot from "../../../assets/images/svg/MapMarkerActiveDepot.svg";
import MapMarkerInactiveDepot from "../../../assets/images/svg/MapMarkerInactiveDepot.svg";
import { Link } from "react-router-dom";
import "../Analytics.scss";

const Map = (props) => {
  const { allDepotsLocation, selectedDepotTypes } = props;
  const [activeMarker, setActiveMarker] = useState(null);
  const handleActiveMarker = (marker) => {
    if (marker === activeMarker) {
      return;
    }
    setActiveMarker(marker);
  };

  const handleOnLoad = (map) => {
    const bounds = new google.maps.LatLngBounds();
    allDepotsLocation.forEach(({ position }) => bounds.extend(position));
    map.fitBounds(bounds);
  };

  return (
    <>
      <GoogleMap
        onLoad={handleOnLoad}
        zoom={10}
        onClick={() => setActiveMarker(null)}
        mapContainerStyle={{ width: "100%", height: "78%" }}
        // options={mapOptions}
      >
        <MarkerClusterer
          minimumClusterSize={5}
          averageCenter
          enableRetinaIcons
          gridSize={60}
        >
          {(clusterer) =>
            allDepotsLocation.map(
              ({
                companyName,
                companyId,
                depotId,
                depotName,
                chargers,
                ports,
                position,
                streetAddress,
                status,
              }) => (
                <Marker
                  icon={{
                    url: status ? MapMarkerActiveDepot : MapMarkerInactiveDepot,
                    scaledSize: { width: 70, height: 70 },
                  }}
                  key={depotId}
                  position={position}
                  clusterer={clusterer}
                  onClick={() => handleActiveMarker(depotId)}
                >
                  {activeMarker === depotId ? (
                    <InfoWindow onCloseClick={() => setActiveMarker(null)}>
                      <div>
                        <Link
                          to={`/companies/${companyId}/depots`}
                          className="noLinkUnderline"
                        >
                          {companyName}
                        </Link>
                        <div className="centerAligned">
                          <p>Depot: &nbsp;</p>
                          <Link
                            to={`/companies/${companyId}/depots/${depotId}`}
                            className="noLinkUnderline"
                          >
                            {depotName}
                          </Link>
                        </div>
                        {status && (
                          <Link
                            to={`/charger-health/charger-details?depotID=${depotId}&depotType=${
                              selectedDepotTypes.length
                                ? selectedDepotTypes
                                : null
                            }`}
                            className="noLinkUnderline"
                          >
                            {chargers} Chargers ({ports} Ports)
                          </Link>
                        )}
                        <p>{streetAddress}</p>
                      </div>
                    </InfoWindow>
                  ) : null}
                </Marker>
              )
            )
          }
        </MarkerClusterer>
      </GoogleMap>
    </>
  );
};

export default Map;
