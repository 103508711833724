import React, { useEffect, useState } from "react";
import { TableCell, CircularProgress } from "@mui/material";

import { routePaths } from "../../constants/RoutePaths";
import "./billing.scss";
import { useHistory } from "react-router-dom";
function CountTableCell({
  isBillabe,
  setDepotWiseQuantity,
  companyId,
  depotId,
  productSku,

  quantity,
  setQuantityTotals,
}) {
  const history = useHistory();
  useEffect(() => {
    setQuantityTotals((prevState) => {
      if (!prevState.find((ele) => ele.depotId === depotId)) {
        return [
          ...prevState,
          { productSku, totalCount: quantity, companyId, depotId },
        ];
      } else {
        return [...prevState];
      }
    });
  }, []);

  return (
    <>
      <TableCell>
        {
          <p
            onClick={() =>
              history.push(
                `${routePaths.CHARGERFIRMWAREDETAILS}?depotID=${depotId}`,
                { from: "billing" }
              )
            }
            className="countTableCell"
          >
            {quantity}
          </p>
        }
      </TableCell>
    </>
  );
}

export default CountTableCell;
