import React from "react";
import { Alert, Snackbar, Slide } from "@mui/material";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    top: theme.spacing(9),
  },
}));

const ToastMessageSecond = (props) => {
  const classes = useStyles();

  const handleClose = (event, reason) => {
    if (reason === "clickaway") return;
    setToast({ ...toast, isOpen: false });
  };

  const { toast, setToast, autoHideDuration = 8000 } = props;
  return (
    <Snackbar
      open={toast.isOpen}
      autoHideDuration={autoHideDuration}
      anchorOrigin={{ vertical: "top", horizontal: "center" }}
      className={classes.root}
      onClose={handleClose}
      TransitionComponent={Slide}
    >
      <Alert severity={toast.type || "success"} onClose={handleClose}>
        {toast.message}
      </Alert>
    </Snackbar>
  );
};

export default ToastMessageSecond;
