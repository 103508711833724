import ApiCore from "./utilities/core.js";
import { apiProvider, indexedEndPoints } from "./utilities/provider";

const url = "ford";
const billingUrl = "billing";
const { USAGE, BILLING, COMPANY, UPDATE_DEPOTS } = indexedEndPoints;

const apiBilling = new ApiCore({
  getAllBilling: true,
  getAll: true,
  url,
});

apiBilling.getCountsMode = (resource, url) => {
  return apiProvider.getAllBilling(resource, url);
};

apiBilling.postUsage = (payload) => {
  return apiProvider.post(USAGE, payload);
};

apiBilling.updateBillingPackage = (id, payload) => {
  return apiProvider.put(`depots/${id}/${UPDATE_DEPOTS}`, payload);
};
apiBilling.updateBillingPackage2 = (id, payload) => {
  return apiProvider.put(id, payload);
};

apiBilling.updateCustomerRatePlan = (id, payload) => {
  return apiProvider.put(`${COMPANY}/${id}`, payload);
};

apiBilling.billingHistory = (companyId) => {
  return apiProvider.getAll(`${billingUrl}/${companyId}/history`);
};

apiBilling.inspectFailure = (batchId) => {
  return apiProvider.getAll(`${USAGE}/${batchId}`);
};

export default apiBilling;
