import React from "react";
import {
  FormControl,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  Radio,
  RadioGroup,
} from "@mui/material";

export default function RadioButtons({
  value,
  error,
  onChange,
  itemList,
  direction = "vertical",
  groupLabel = "",
  helperText = "",
}) {
  return (
    <FormControl error={error}>
      <FormLabel id="demo-controlled-radio-buttons-group">
        {groupLabel}
      </FormLabel>
      <RadioGroup
        row={direction === "horizontal"}
        aria-labelledby="demo-controlled-radio-buttons-group"
        name="controlled-radio-buttons-group"
        value={value}
        onChange={onChange}
      >
        {itemList?.map((row, i) => (
          <FormControlLabel
            key={`radioButton-${i}`}
            value={row.value}
            control={<Radio />}
            label={row.label}
          />
        ))}
      </RadioGroup>
      <FormHelperText>{helperText}</FormHelperText>
    </FormControl>
  );
}
