export const chargingModeEnum = {
  optimal: "Fully Automated",
  simple: "Supervised",
  unmanaged: "Unmanaged",
};

export const firmwareStatusEnum = {
  updateAvailable: "Update Available",
  upToDate: "Up To Date",
  updateFailed: "Update Failed",
  na: "N/A",
};
