import React from "react";
import apiBilling from "../../services/api/Billing";
import { titleLabels } from "../../constants/TitleLabels";
import { messages } from "../../constants/Messages";
import { variables } from "../../constants/Variables";

import { UseForm, Form } from "../../components/UseForm";
import Controls from "../../components/controls/Controls";
import { format, lastDayOfMonth } from "date-fns";

import { Grid, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { countryList } from "../../services/StatesList";

import { getData } from "../../utils/Storage";

import BillingPackageForProcess from "./BillingPackageForProcess";

const useStyles = makeStyles((theme) => ({
  typography: {
    marginTop: theme.spacing(1),
  },
  buttonAlign: {
    marginTop: theme.spacing(1),
    marginLeft: theme.spacing(0.5),
  },
}));

const initialFieldValues = {
  countryCode: "",
  chargeNumber: "",
  customerId: "",
  description: "",
  endDate: "",
  productChargeId: null,
  productSku: null,
  quantity: "",
  serviceCode: "",
  serviceDateTime: "",
  serviceNumber: "",
  startDate: "",
  companyId: "",
};

const ProcessForm = (props) => {
  const classes = useStyles();
  const userEmail = getData(variables.USER_EMAIL);

  const {
    recordForEdit,
    setLoading,
    setToast,
    setOpenPopup,
    depotWiseQuantity,
    item,
    openPopup,
    quantityTotals,
    setSuccessDialog,
    redirectToHistory,
    AllRatePlans,
  } = props;

  const [countryOptions, setCountryOptions] = React.useState(countryList);
  const [zuoraId, setZuoraId] = React.useState(null);
  const [RatePlans, setRatePlans] = React.useState([]);

  const validate = (fieldValues = values) => {
    let temp = { ...errors };
    if ("countryCode" in fieldValues)
      temp.countryCode = fieldValues.countryCode ? "" : messages.FIELD_REQUIRED;
    if ("zuoraId" in fieldValues)
      temp.zuoraId = fieldValues.zuoraId ? "" : messages.FIELD_REQUIRED;
    if ("endDate" in fieldValues)
      temp.endDate = fieldValues.endDate ? "" : messages.FIELD_REQUIRED;
    if ("productChargeId" in fieldValues)
      temp.productChargeId = fieldValues.productChargeId
        ? ""
        : messages.FIELD_REQUIRED;
    if ("quantity" in fieldValues)
      temp.quantity = fieldValues.quantity
        ? fieldValues.quantity <= 0
          ? messages.FIELD_CANNOT_BE_NEGATIVE
          : ""
        : messages.FIELD_REQUIRED;
    if ("startDate" in fieldValues)
      temp.startDate = fieldValues.startDate ? "" : messages.FIELD_REQUIRED;

    setErrors({ ...temp });
    if (fieldValues === values)
      return Object.values(temp).every((x) => x === "");
    else return false;
  };

  const { values, setValues, errors, setErrors, handleFormInputChange } =
    UseForm(initialFieldValues, true, validate);

  React.useEffect(() => {
    const {
      depotsList: [{ zuoraId }],
    } = item;

    setZuoraId(item.zuoraId);
  }, []);

  React.useEffect(() => {
    if (values.countryCode) {
      let countryCodeToSearch = values.countryCode.split(", ")[1];
      const euroCountrys = [
        "POL",
        "AUT",
        "IRL",
        "ESP",
        "LUX",
        "NLD",
        "NOR",
        "DNK",
        "BEL",
        "DEU",
        "FRA",
        "ITA",
      ];
      if (euroCountrys.includes(countryCodeToSearch)) {
        countryCodeToSearch = "GBR";
      }
      if (countryCodeToSearch === undefined) {
        countryCodeToSearch = "USA";
      }
      setRatePlans(
        AllRatePlans.filter((x) => x.countryCode === countryCodeToSearch)
      );
    }
  }, [values.countryCode]);

  React.useEffect(() => {
    if (values.productSku === "SKU-00000296") {
      let filteredDepotWiseQuantity =
        depotWiseQuantity && depotWiseQuantity.length
          ? depotWiseQuantity.filter((ele) => {
              if (
                Date.parse(new Date(ele.createdAt)) >=
                  Date.parse(values.startDate) &&
                Date.parse(new Date(ele.createdAt)) <=
                  Date.parse(values.endDate)
              ) {
                return ele;
              }
            })
          : [];

      filteredDepotWiseQuantity = filteredDepotWiseQuantity.filter(
        (ele, ind, self) =>
          ind === self.findIndex((it) => it.portId === ele.portId)
      );

      setValues({
        ...values,
        quantity: filteredDepotWiseQuantity
          ? filteredDepotWiseQuantity.length
          : 0,
      });
    }
  }, [values.startDate, values.endDate]);

  React.useEffect(() => {
    if (values.productSku === "SKU-00000296") {
      let startMonth, startYear, endMonth, endYear, startDate, endDate;
      const date = new Date();
      [startMonth, startYear] = [date.getMonth() + 1, date.getFullYear()];
      startDate = new Date(startYear, startMonth - 1, 2)
        .toISOString()
        .split("T")[0];
      endDate = new Date(startYear, startMonth, 1).toISOString().split("T")[0];

      let filteredDepotWiseQuantity =
        depotWiseQuantity && depotWiseQuantity.length
          ? depotWiseQuantity.filter((ele) => {
              if (
                Date.parse(new Date(ele.createdAt)) >=
                  Date.parse(values.startDate) &&
                Date.parse(new Date(ele.createdAt)) <=
                  Date.parse(values.endDate)
              ) {
                return ele;
              }
            })
          : [];

      filteredDepotWiseQuantity = filteredDepotWiseQuantity.filter(
        (ele, ind, self) =>
          ind === self.findIndex((it) => it.portId === ele.portId)
      );
      setValues({
        ...values,
        quantity: filteredDepotWiseQuantity
          ? filteredDepotWiseQuantity.length
          : 0,
        productChargeId:
          item.ratePlan &&
          RatePlans.filter((ele) => ele.sku === values.productSku)
            .map((item) => item.productRatePlanChargeName)
            .includes(item.ratePlan)
            ? item.ratePlan
            : null,
        startDate,
        endDate,
      });
    } else if (
      values.productSku === "SKU-00000294" ||
      values.productSku === "SKU-00000295" ||
      values.productSku === "SKU-P0000544"
    ) {
      let startMonth, startYear, endMonth, endYear, startDate, endDate;
      const date = new Date();
      [startMonth, startYear] = [date.getMonth() + 1, date.getFullYear()];
      startDate = new Date(startYear, startMonth - 1, 2)
        .toISOString()
        .split("T")[0];
      endDate = new Date(startYear, startMonth, 1).toISOString().split("T")[0];

      const totalQuantity = quantityTotals.reduce((acc, curr) => {
        if (
          curr.productSku === values.productSku &&
          curr.companyId === item.companyId
        ) {
          acc += curr.totalCount;
        }
        return acc;
      }, 0);

      setValues({ ...values, quantity: totalQuantity, startDate, endDate });
    } else if (values.productSku === "Non Billable") {
      setValues({ ...values, quantity: 0 });
    } else {
      const totalQuantity = quantityTotals.reduce((acc, curr) => {
        if (
          curr.productSku === values.productSku &&
          curr.companyId === item.companyId
          //Rateplan
        ) {
          acc += curr.totalCount;
        }
        return acc;
      }, 0);
      setValues({ ...values, quantity: totalQuantity });
    }
  }, [values.productSku]);

  React.useEffect(() => {
    if (recordForEdit) {
      let countryCode;
      if (item.countryCode != null) {
        countryCode = countryOptions.find((x) =>
          x.label.includes(item.countryCode)
        );
      }
      setValues((prev) => ({
        ...prev,
        customerId: item.zuoraId,
        companyId: recordForEdit.companyId,
        countryCode: countryCode == null ? "" : countryCode.label,
      }));
    }
  }, [recordForEdit]);

  React.useEffect(() => {
    const countProductChargeId = item.depotsList.map(
      ({ productChargeId }) => productChargeId
    );
    const countProductSku = item.depotsList.map(({ productSku }) => productSku);
    const checkProductChargeId = countProductChargeId.every(
      (val, i, arr) => val === arr[0]
    );
    const checkProductSku = countProductSku.every(
      (val, i, arr) => val === arr[0]
    );

    if (recordForEdit) {
      if (
        checkProductChargeId === true &&
        checkProductSku === true &&
        RatePlans.length !== 0
      ) {
        if (
          item.depotsList[0].productRatePlanChargeName === null ||
          item.depotsList[0].productRatePlanChargeName === undefined
        ) {
          setValues((prev) => ({
            ...prev,
            productChargeId: null,
            productSku: item.depotsList[0].productSku,
          }));
        } else {
          const ratePlan = RatePlans.find(
            (x) =>
              x.productRatePlanChargeName ===
              item.depotsList[0].productRatePlanChargeName
          );
          setValues((prev) => ({
            ...prev,
            productChargeId:
              ratePlan === undefined
                ? null
                : ratePlan.productRatePlanChargeName,
            productSku: item.depotsList[0].productSku,
          }));
        }
      }
    }
  }, [RatePlans, recordForEdit]);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validate()) {
      const [{ productRatePlanChargeId }] = RatePlans.filter(
        (item) => item.productRatePlanChargeName === values.productChargeId
      );
      const payload = {
        value: [
          {
            customerId: values.customerId,
            quantity: values.quantity,
            startDate: values.startDate
              ? ((res) => `${res[1]}/${res[0]}/${res[2]}`)(
                  values.startDate.split("-").reverse()
                )
              : "",
            endDate: values.endDate
              ? ((res) => `${res[1]}/${res[0]}/${res[2]}`)(
                  values.endDate.split("-").reverse()
                )
              : "",
            productChargeId: productRatePlanChargeId,
            productSku: values.productSku,
            description: values.description,
            serviceNumber: values.serviceNumber,
            serviceCode: values.serviceCode,
            serviceDateTime: values.serviceDatTime
              ? ((res) => `${res[1]}/${res[0]}/${res[2]}`)(
                  values.serviceDateTime.split("-").reverse()
                )
              : "",
            chargeNumber: values.chargeNumber,
          },
        ],
        geoFilter: {
          countryCode: values.countryCode.split(", ")[1],
        },
        companyId: values.companyId,
        requestedBy: userEmail,
      };
      setLoading(true);
      setOpenPopup((prevState) => ({ ...prevState, isOpen: false }));
      apiBilling
        .postUsage(payload)
        .then((res) => {
          setLoading(false);
          if (res.statusCode === 202) {
            setToast({
              isOpen: true,
              message: "Post Usage Successful",
              type: "success",
            });
            setSuccessDialog({
              isOpen: true,
              title: "Successful",
              subTitle: "Request Submitted. Check Billing History for status",
              type: "secondary",
              btnTitle: "History",
              onConfirm: () => redirectToHistory(),
            });
          } else {
            setToast({
              isOpen: true,
              message: "Post Usage Failed",
              type: "error",
            });
          }
        })
        .catch((error) => console.log(error));
    }
  };

  const handleDateChange = (e) => {
    const { name, value } = e.target;

    if (name === "startDate") {
      setValues({
        ...values,
        startDate: value,
        endDate: value
          ? format(lastDayOfMonth(new Date(value)), "yyyy-MM-dd")
          : "",
      });
    } else {
      setValues({ ...values, [name]: value });
    }
  };

  const handleInputChange = (event) => {
    setValues({
      ...values,
      countryCode: event.target.value,
      productChargeId: null,
      productSku: null,
      quantity: "",
    });
  };

  return (
    // <h1>kdfiejfioe</h1>
    <Form onSubmit={handleSubmit}>
      <div>
        <Typography gutterBottom variant="h6">
          {titleLabels.GEOFILTER}
        </Typography>
        <Grid container>
          <Grid item xs={12}>
            <Controls.AutoSuggestion
              label="Country"
              name="countryCode"
              value={values.countryCode}
              onChange={handleInputChange}
              options={
                countryOptions.length && countryOptions.map((ele) => ele.label)
              }
              error={errors.countryCode}
            />
          </Grid>
        </Grid>
        <Typography gutterBottom variant="h6">
          {titleLabels.ADVANCED}
        </Typography>
        <Grid container>
          <Grid item xs={6}>
            <Controls.Input
              label="Zuora Customer ID"
              value={values.zuoraId || zuoraId}
              name={"zuoraId"}
              onChange={handleFormInputChange()}
              error={errors.zouraId}
              disabled={zuoraId}
            />
          </Grid>
          <BillingPackageForProcess
            values={values}
            handleFormInputChange={handleFormInputChange}
            setValues={setValues}
            errors={errors}
            RatePlans={RatePlans}
          />
          <Grid item xs={6}>
            <Controls.Input
              label="Quantity"
              value={values.quantity}
              name="quantity"
              type="number"
              onChange={handleFormInputChange()}
              error={errors.quantity}
            />
          </Grid>
          <Grid item xs={6}>
            <Controls.Input
              id="startDate"
              label="Start Date"
              type="date"
              name="startDate"
              value={values.startDate}
              onChange={handleDateChange}
              InputLabelProps={{
                shrink: true,
              }}
              error={errors.startDate}
            />
          </Grid>
          <Grid item xs={6}>
            <Controls.Input
              id="endDate"
              label="End Date"
              type="date"
              name="endDate"
              value={values.endDate}
              onChange={handleDateChange}
              InputLabelProps={{
                shrink: true,
              }}
              error={errors.endDate}
            />
          </Grid>
          <Grid item xs={12}>
            <Controls.Input
              label="Description"
              value={values.description}
              name="description"
              onChange={handleFormInputChange()}
            />
          </Grid>
        </Grid>
        <Grid container justifyContent="flex-end">
          <div className={classes.buttonAlign}>
            <Controls.Button type="submit" text={"Post Usage"} />
          </div>
        </Grid>
      </div>
    </Form>
  );
};

export default ProcessForm;
