import React, { useState, useEffect } from "react";
import {
  DEFAULT_DEPOTS,
  ALL_SESSIONS_TABLE_HEADERS,
  ALL_SESSIONS_TABLE_HEAD_CELLS,
  ALL_SESSIONS_COLUMN_HEADERS,
  TEST_DEPOT_ID_LIST,
  PROD_DEPOT_ID_LIST,
} from "./constants";
import { useDispatch, useSelector } from "react-redux";
import { getVehiclesDiscovery } from "./vehicleDiscoveryServices";
import useTable from "../../components/UseTable";
import { bindActionCreators } from "redux";
import { commonApiActionCreator } from "../../redux-state/actions";
import {
  allCountryCodeForFilter,
  allDepotTypesForFilter,
  allPowerTypeFilter,
} from "../../constants/FilterConstants";
import format from "date-fns/format";

export const VehiclesDiscoveryContext = React.createContext({
  isLoading: undefined,
  setLoading: undefined,
  fromDate: undefined,
  setFromDate: undefined,
  toDate: undefined,
  setToDate: undefined,
  toggleDrawer: undefined,
  handleSearch: undefined,
  vehicleDiscoveryData: undefined,
  vehicleDiscoveryMetrics: undefined,
  openPopup: undefined,
  setOpenPopup: undefined,
  recordsAfterPagingAndSorting: undefined,
  TableContainer: undefined,
  TableHead: undefined,
  TableBody: undefined,
  TablePagination: undefined,
  DrawerOC: undefined,
  applyFilter: undefined,
  allAccounts: undefined,
  setAllAccounts: undefined,
  allMakes: undefined,
  setAllMakes: undefined,
  allPowerType: undefined,
  setAllPowerType: undefined,
  allDepotType: undefined,
  setAllDepotType: undefined,
  depotType: undefined,
  countryCodes: undefined,
  setCountryCodes: undefined,
  allColumnsHeaders: undefined,
  headers: undefined,
  setHeaders: undefined,
  toast: undefined,
  setToast: undefined,
  isFiltered: undefined,
});

export const useVehiclesDiscovery = () =>
  React.useContext(VehiclesDiscoveryContext);

export const VehiclesDiscoveryProvider = ({ children }) => {
  const [vehicleDiscoveryData, setVehicleDiscoveryData] = useState([]);
  const [vehicleDiscoveryMetrics, setVehicleDiscoveryMetrics] = useState({
    depotSessionMetrics: [],
    makeModelSessionMetrics: [],
  });
  const [filterFn, setFilterFn] = React.useState({ fn: (items) => items });
  const [defaultDepots, setDefaultDepots] = React.useState(DEFAULT_DEPOTS);
  const date = new Date();
  const [fromDate, setFromDate] = useState(
    new Date(date.setDate(date.getDate() - 30))
  );

  const [toDate, setToDate] = useState(new Date());
  const [depotType, setDepotType] = useState(["TEST"]);
  const [isFiltered, setIsFiltered] = useState(true);

  const [DrawerOC, setDrawerOC] = useState({
    isOpen: false,
    tilte: "",
    data: {},
  });
  const [toast, setToast] = React.useState({
    isOpen: false,
    message: "",
    type: "",
  });

  const [allAccounts, setAllAccounts] = React.useState([]);
  const [allMakes, setAllMakes] = React.useState([]);
  const [allPowerType, setAllPowerType] = React.useState(
    JSON.parse(JSON.stringify(allPowerTypeFilter))
  );
  const dispatch = useDispatch();
  const { saveAllCompaniesV2Global, saveAllChargersMetaGlobal } =
    bindActionCreators(commonApiActionCreator, dispatch);
  const { allCompaniesV2, isCompaniesLoaded } = useSelector(
    (state) => state.getAllCompaniesV2_Global
  );
  const { allChargersMeta, isChargersMetaLoaded } = useSelector(
    (state) => state.getAllChargersMeta
  );

  const toggleDrawer = (openClose, title, item) => {
    setDrawerOC({ isOpen: openClose, title: title, data: item });
  };

  const getDepotTypeFilter = () => {
    let depotTypeData = JSON.parse(JSON.stringify(allDepotTypesForFilter));
    depotTypeData[0]?.children.map((single) => {
      if (
        single.name == "CUSTOMER" ||
        single.name == "PILOT" ||
        single.name == "DEALER"
      )
        single.checked = true;
    });
    return depotTypeData;
  };
  const [allDepotType, setAllDepotType] = React.useState(getDepotTypeFilter());
  const [countryCodes, setCountryCodes] = React.useState(
    JSON.parse(JSON.stringify(allCountryCodeForFilter))
  );
  const getAccountsAndFilter = async () => {
    let tempAllCompaniesV2 = JSON.parse(JSON.stringify(allCompaniesV2));
    if (tempAllCompaniesV2.length !== 0) {
      const onlyDepotsList = [];
      tempAllCompaniesV2.map((single) => {
        if (single.depotsList) {
          onlyDepotsList.push(...single.depotsList);
        }
      });

      tempAllCompaniesV2.map((single) => {
        single.checked = true;
        single.isExpanded = true;
        if ("depotsList" in single) {
          single.depotsList?.map((singleChild) => {
            if (defaultDepots.includes(singleChild?.depotId))
              singleChild["checked"] = true;
            else singleChild["checked"] = false;
            if (singleChild["checked"] === false) single.checked = false;
          });
        }
      });
      let tempAccounts = [
        {
          name: "Account and Depot",
          isExpanded: true,
          checked: false,
          children: tempAllCompaniesV2,
        },
      ];
      setAllAccounts(tempAccounts);
    }
  };

  const getChargersFilter = async () => {
    let tempAllChargersMeta = JSON.parse(JSON.stringify(allChargersMeta));
    if (tempAllChargersMeta.length !== 0) {
      tempAllChargersMeta?.map((single) => {
        single.checked = false;
        single.isExpanded = true;
        if ("chargers" in single) {
          single.chargers?.map((singleChild) => {
            singleChild["checked"] = false;
          });
        }
      });
      let tempMakes = [
        {
          name: "Charger make and model",
          isExpanded: true,
          checked: false,
          children: tempAllChargersMeta,
        },
      ];
      setAllMakes(tempMakes);
    }
  };

  React.useEffect(() => {
    if (isChargersMetaLoaded === false) {
      saveAllChargersMetaGlobal();
    }
    getChargersFilter();
  }, [isChargersMetaLoaded]);

  React.useEffect(() => {
    if (isCompaniesLoaded === false) {
      saveAllCompaniesV2Global();
    }
    getAccountsAndFilter();
  }, [isCompaniesLoaded]);

  const [isLoading, setLoading] = React.useState(false);
  const [TableContainer, setTableContainer] = React.useState("table");
  const [TableHead, setTableHead] = React.useState("thead");
  const [TableBody, setTableBody] = React.useState("tbody");
  const [TablePagination, setTablePagination] = React.useState(() => <></>);
  const [recordsAfterPagingAndSorting, setRecordsAfterPagingAndSorting] =
    React.useState([]);
  const [headers, setHeaders] = React.useState(ALL_SESSIONS_TABLE_HEADERS);
  const [tableHeadCells, setTableHeadCells] = useState(
    ALL_SESSIONS_TABLE_HEAD_CELLS
  );
  const allColumnsHeaders = ALL_SESSIONS_COLUMN_HEADERS;

  // Table component related variables
  const result = useTable(vehicleDiscoveryData, tableHeadCells, filterFn);
  useEffect(() => {
    setTableContainer(() => result.tableContainer);
  }, [result.tableContainer]);
  useEffect(() => {
    setTableHead(() => result.tableHead);
  }, [result.tableHead]);
  useEffect(() => {
    setTableBody(() => result.tableBody);
  }, [result.tableBody]);
  useEffect(() => {
    setTablePagination(result.tablePagination);
  }, [result.tablePagination]);

  useEffect(() => {
    setRecordsAfterPagingAndSorting(result.recordsAfterPagingAndSorting);
  }, [result.recordsAfterPagingAndSorting]);

  useEffect(() => {
    const selectedDepotTypes = allDepotType[0]?.children
      ?.filter((item) => item.checked)
      ?.map((item) => item.value);
    if (process.env.REACT_APP_NODE_ENV === "STAGE") {
      setDepotType(["TEST"]);
      const initialPayload = {
        depotType: ["TEST"], // depot type set to test as below mentioned depot Ids belong to test depots
        depotId: TEST_DEPOT_ID_LIST,
        fromDate: format(new Date(fromDate), "yyyy-MM-dd"),
        toDate: format(new Date(toDate), "yyyy-MM-dd"),
      };
      apiCall(initialPayload);
    } else if (process.env.REACT_APP_NODE_ENV === "PROD") {
      setDepotType(selectedDepotTypes);
      const initialPayload = {
        depotType: selectedDepotTypes,
        depotId: PROD_DEPOT_ID_LIST,
        fromDate: format(new Date(fromDate), "yyyy-MM-dd"),
        toDate: format(new Date(toDate), "yyyy-MM-dd"),
      };
      apiCall(initialPayload);
    } else {
      apiCall();
    }
  }, []);
  const apiCall = async (
    payload = {
      fromDate: format(new Date(fromDate), "yyyy-MM-dd"),
      toDate: format(new Date(toDate), "yyyy-MM-dd"),
    }
  ) => {
    setLoading(true);
    setIsFiltered(Object.keys(payload).length > 2);
    const res = await getVehiclesDiscovery(payload);
    if (res.statusCode == 200) {
      const { ["allSessionData"]: allSessionData, ...metrics } = res.data;
      setVehicleDiscoveryData(allSessionData);
      setVehicleDiscoveryMetrics(metrics);
    } else {
      setToast({
        isOpen: true,
        message: "Internal Service Failure",
        type: "error",
      });
    }
    setLoading(false);
  };

  const [openPopup, setOpenPopup] = React.useState({
    isOpen: false,
    title: "",
    child: "",
    item: {},
  });

  const handleSearch = (e) => {
    let target = e.target.value.trim();
    setFilterFn({
      fn: (items) => {
        if (target === "") return items;
        else
          return items.filter(
            (x) =>
              x.depotName.toLowerCase().includes(target.toLowerCase()) ||
              x.depotId.toLowerCase().includes(target.toLowerCase()) ||
              x.sessionId.toLowerCase().includes(target.toLowerCase()) ||
              x.chargerId.toLowerCase().includes(target.toLowerCase()) ||
              x.chargerModel.toLowerCase().includes(target.toLowerCase()) ||
              x.chargerMake.toLowerCase().includes(target.toLowerCase()) ||
              x.powerType.toLowerCase().includes(target.toLowerCase()) ||
              x.vin.toLowerCase().includes(target.toLowerCase())
          );
      },
    });
  };

  const applyFilter = (
    tempAllAccounts,
    tempAllMakeModel,
    tempAllPowerType,
    tempAllCountryCode,
    tempAllDepotType,
    tempFromDate,
    tempToDate
  ) => {
    const filterObj = createFilterPayload(
      tempAllAccounts,
      tempAllMakeModel,
      tempAllPowerType,
      tempAllCountryCode,
      tempAllDepotType,
      tempFromDate,
      tempToDate
    );
    apiCall(filterObj);
    setIsFiltered(Object.keys(filterObj).length > 2);
    toggleDrawer(false, "", {});
  };
  const createFilterPayload = (
    tempAllAccounts,
    tempAllMakeModel,
    tempAllPowerType,
    tempAllCountryCode,
    tempAllDepotType,
    tempFromDate,
    tempToDate
  ) => {
    let selectedAccFilters = [];
    let selectedModelFilters = [];
    let selectedPowerType = [];
    let selectedCountryCode = [];
    let selectedDepotType = [];
    let payload = {
      fromDate: format(new Date(tempFromDate), "yyyy-MM-dd"),
      toDate: format(new Date(tempToDate), "yyyy-MM-dd"),
    };
    if (tempAllAccounts[0].children.length) {
      selectedAccFilters = tempAllAccounts[0].children.reduce((acc, cur) => {
        const selectedChildElements = cur.depotsList.reduce((accCh, curCh) => {
          if (curCh.checked) {
            accCh.push(curCh.depotId);
          }
          return accCh;
        }, []);
        acc.push(...selectedChildElements);
        return acc;
      }, []);
    }
    if (tempAllMakeModel[0].children.length) {
      selectedModelFilters = tempAllMakeModel[0].checked
        ? []
        : tempAllMakeModel[0].children.reduce((acc, cur) => {
            const selectedChildElements = cur.chargers.reduce(
              (accCh, curCh) => {
                if (curCh.checked) {
                  accCh.push(cur.name + "-" + curCh.model); // prepend make to model
                }
                return accCh;
              },
              []
            );
            acc.push(...selectedChildElements);
            return acc;
          }, []);
    }
    if (tempAllPowerType[0].children.length) {
      selectedPowerType = tempAllPowerType[0].children.reduce((acc, cur) => {
        if (cur.checked) {
          acc.push(cur.name);
        }
        return acc;
      }, []);
    }
    if (tempAllDepotType[0].children.length) {
      selectedDepotType = tempAllDepotType[0].children.reduce((acc, cur) => {
        if (cur.checked) {
          acc.push(cur.name);
        }
        return acc;
      }, []);
    }
    if (tempAllCountryCode[0].children.length) {
      selectedCountryCode = tempAllCountryCode[0].children.reduce(
        (codes, countryCodess) => {
          const selectedChildElements = countryCodess.children.reduce(
            (CntryCode, curCh) => {
              if (curCh.checked) {
                CntryCode.push(curCh.value);
              }
              return CntryCode;
            },
            []
          );
          codes.push(...selectedChildElements);
          return codes;
        },
        []
      );
    }

    setFromDate(tempFromDate);
    setToDate(tempToDate);
    setAllAccounts([...tempAllAccounts]);
    setAllMakes([...tempAllMakeModel]);
    setAllPowerType([...tempAllPowerType]);
    setAllDepotType([...tempAllDepotType]);
    setCountryCodes([...tempAllCountryCode]);
    if (selectedCountryCode.length)
      payload = { ...payload, countryCode: [...selectedCountryCode] };
    if (selectedAccFilters.length)
      payload = { ...payload, depotId: [...selectedAccFilters] };
    if (selectedModelFilters.length)
      payload = { ...payload, makeModel: [...selectedModelFilters] };
    if (selectedPowerType.length)
      payload = { ...payload, powerType: [...selectedPowerType] };
    if (selectedDepotType.length)
      payload = { ...payload, depotType: [...selectedDepotType] };

    return payload;
  };

  let vehiclesDiscoveryValues = {
    isLoading,
    setLoading,
    fromDate,
    setFromDate,
    toDate,
    setToDate,
    toggleDrawer,
    handleSearch,
    vehicleDiscoveryData,
    vehicleDiscoveryMetrics,
    openPopup,
    setOpenPopup,
    recordsAfterPagingAndSorting,
    TableContainer,
    TableHead,
    TableBody,
    TablePagination,
    DrawerOC,
    applyFilter,
    allAccounts,
    setAllAccounts,
    allMakes,
    setAllMakes,
    allPowerType,
    setAllPowerType,
    allDepotType,
    setAllDepotType,
    depotType,
    countryCodes,
    setCountryCodes,
    allColumnsHeaders,
    headers,
    setHeaders,
    toast,
    setToast,
    isFiltered,
  };

  return (
    <VehiclesDiscoveryContext.Provider value={vehiclesDiscoveryValues}>
      {children}
    </VehiclesDiscoveryContext.Provider>
  );
};
