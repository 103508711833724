import React, { useEffect, useMemo, useState } from "react";
import { Chip, InputAdornment } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import Controls from "../../../components/controls/Controls";
import Loader from "../../../components/Loader";
import OpsBreadcrumb from "../../../components/NewBreadcrumbs";
import PageHeader from "../../../components/PageHeader";
import PageMainContent from "../../../components/PageMainContent";
import { routePaths } from "../../../constants/RoutePaths";
import {
  apiProvider,
  indexedEndPoints,
} from "../../../services/api/utilities/provider";
import useTable from "../../../components/UseTable";
import ViewPlanRates from "./ViewPlanRates";
import no_Data from "./../../../assets/images/png/Vector.png";

const allBreadcrumbsLinks = [
  {
    link: routePaths.DATA_CATALOG,
    title: "Data Catalog",
  },
  {
    link: routePaths.UTILITY_RATES,
    title: "Utility Provider",
  },
];

export default function UtilityPlanSearch() {
  const [loading, setLoading] = useState(false);
  const [searchText, setSearchText] = useState(false);
  const [fetchPlans, setFetchPlans] = useState(false);
  const [data, setData] = useState([]);
  const [showNoData, setShowNoData] = useState(false);
  const filterFn = useMemo(() => ({ fn: (items) => items }), []);
  const { UTILITY_PLAN_SEARCH } = indexedEndPoints;
  const [openPopup, setOpenPopup] = useState({
    isOpen: false,
    title: "Utility Rates",
    child: "",
    item: {},
  });

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      const response = await apiProvider.getAll(
        `${UTILITY_PLAN_SEARCH}/${searchText}`
      );
      setLoading(false);
      setData(response?.data);
      setFetchPlans(false);
      response?.data?.length === 0 && setShowNoData(true);
    };
    fetchPlans && fetchData();
  }, [fetchPlans]);

  const headers = useMemo(
    () => [
      { id: "provider", label: "Provider", styles: { width: "min-content" } },
      {
        id: "plans",
        label: "Plans",
        render: (row, col) => (
          <div className="utilityPlanChip">
            {row[col]?.map((item, ind) => (
              <Chip
                clickable
                sx={{ margin: "1px" }}
                color={"primary"}
                key={ind}
                label={item?.planName}
                onClick={() =>
                  setOpenPopup({
                    ...openPopup,
                    isOpen: true,
                    item: item?.planMetaId,
                  })
                }
              />
            ))}
          </div>
        ),
      },
    ],
    []
  );

  const {
    tableContainer: TableContainer,
    tableHead: TableHead,
    tableBody: TableBody,
    tablePagination: TablePagination,
  } = useTable(data, headers, filterFn);

  return (
    <>
      <OpsBreadcrumb
        AllBreadcrumbsLinks={allBreadcrumbsLinks}
        title="Utility Plans Search"
      />
      <PageHeader title="Utility Plans Search" />
      <PageMainContent>
        <Loader isLoading={loading} />
        <Controls.Input
          label={"Zipcode"}
          className="utilityPlanSearch"
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <SearchIcon
                  onClick={() => searchText?.length > 0 && setFetchPlans(true)}
                  className="iconCursor"
                />
              </InputAdornment>
            ),
          }}
          onKeyDown={(e) =>
            e.key === "Enter" && searchText?.length > 0 && setFetchPlans(true)
          }
          onChange={(e) => setSearchText(e.target.value)}
        />
        {data?.length ? (
          <>
            <TableContainer>
              <TableHead />
              {TableBody}
            </TableContainer>
            {TablePagination}
          </>
        ) : (
          showNoData && (
            <div className="no_data_with_vector">
              <div>
                <img src={no_Data} alt="No data available" />
              </div>
              <p>No data available</p>
            </div>
          )
        )}
      </PageMainContent>
      {openPopup.isOpen && (
        <ViewPlanRates openPopup={openPopup} setOpenPopup={setOpenPopup} />
      )}
    </>
  );
}
