import React from "react";

import { Button } from "@mui/material";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    minWidth: 0,
    margin: theme.spacing(0.5),
  },
  secondary: {
    backgroundColor: theme.palette.secondary.light,
    "& .MuiButton-label": {
      color: theme.palette.secondary.main,
    },
  },
  primary: {
    backgroundColor: theme.palette.primary.light,
    "& .MuiButton-label": {
      color: theme.palette.primary.man,
    },
  },
}));

const ActionButton = (props) => {
  const classes = useStyles();
  const { color, children, onClick, ...other } = props;
  return (
    <Button
      onClick={onClick}
      className={`${classes.root} ${classes[color]}`}
      {...other}
    >
      {children}
    </Button>
  );
};

export default ActionButton;
