import * as React from "react";

import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import { Checkbox } from "@mui/material";

const FaultStatusFilter = (props) => {
  let { _allFaultStatus, setFuntion } = props;

  const expandCollapse = (
    parentInd,
    firstChildIndex = null,
    secondChildIndex = null
  ) => {
    if (secondChildIndex !== null) {
      _allFaultStatus[parentInd].children[firstChildIndex].children[
        secondChildIndex
      ].isExpanded =
        !_allFaultStatus[parentInd].children[firstChildIndex].children[
          secondChildIndex
        ].isExpanded;
      setFuntion([..._allFaultStatus]);
    } else if (firstChildIndex !== null) {
      _allFaultStatus[parentInd].children[firstChildIndex].isExpanded =
        !_allFaultStatus[parentInd].children[firstChildIndex].isExpanded;
      setFuntion([..._allFaultStatus]);
    } else {
      _allFaultStatus[parentInd].isExpanded =
        !_allFaultStatus[parentInd].isExpanded;
      setFuntion([..._allFaultStatus]);
    }
  };

  const parentClicked = (parentIndex) => {
    _allFaultStatus[parentIndex].checked =
      !_allFaultStatus[parentIndex].checked;
    setFuntion([..._allFaultStatus]);
    if (_allFaultStatus[parentIndex].checked === true) {
      if (_allFaultStatus[parentIndex].children) {
        _allFaultStatus[parentIndex].children.map((single) => {
          single.checked = true;
          setFuntion([..._allFaultStatus]);
          if (single.children) {
            single.children.map((child) => {
              child.checked = true;
              setFuntion([..._allFaultStatus]);
            });
          }
        });
      }
    } else {
      if (_allFaultStatus[parentIndex].children) {
        _allFaultStatus[parentIndex].children.map((single) => {
          single.checked = false;
          setFuntion([..._allFaultStatus]);
          if (single.children) {
            single.children.map((child) => {
              child.checked = false;
              setFuntion([..._allFaultStatus]);
            });
          }
        });
      }
    }
  };

  const childClicked = (parent, child) => {
    _allFaultStatus[parent].children[child].checked =
      !_allFaultStatus[parent].children[child].checked;
    setFuntion([..._allFaultStatus]);

    if (_allFaultStatus[parent].children[child].checked === true) {
      if (_allFaultStatus[parent].children[child].children) {
        _allFaultStatus[parent].children[child].children.map((single) => {
          single.checked = true;
          setFuntion([..._allFaultStatus]);
        });
      }
    } else {
      if (_allFaultStatus[parent].children[child].children) {
        _allFaultStatus[parent].children[child].children.map((single) => {
          single.checked = false;
          setFuntion([..._allFaultStatus]);
        });
      }
    }

    if (_allFaultStatus[parent].children) {
      if (
        _allFaultStatus[parent].children.filter(
          (single) => single.checked === true
        ).length === _allFaultStatus[parent].children.length
      ) {
        _allFaultStatus[parent].checked = true;
      } else {
        _allFaultStatus[parent].checked = false;
      }
      setFuntion([..._allFaultStatus]);
    }
  };

  return (
    <>
      <div>
        {_allFaultStatus[0]?.children.length > 0 ? (
          _allFaultStatus.length > 0 &&
          _allFaultStatus.map((acc, index) => (
            <div
              className="side_filter_new"
              key={`allMakes-${index}`}
              style={{
                border: "1px solid #DBE3EA",
                marginBottom: "15px",
                borderRadius: "2px",
              }}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-start",
                  alignItems: "center",
                }}
                className="filter_parent"
              >
                <Checkbox
                  checked={acc.checked}
                  id="fault_Status_checkBox"
                  indeterminate={
                    acc.children?.length >
                      acc.children?.filter((value) => value.checked == true)
                        ?.length &&
                    acc.children?.some((value) => value.checked == true)
                  }
                  onClick={() => parentClicked(index)}
                  inputProps={{ "aria-label": "primary checkbox" }}
                />
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    width: "100%",
                    cursor: "pointer",
                  }}
                  onClick={() => expandCollapse(index)}
                >
                  <p className="parent_p">{acc.name}</p>
                  {acc.isExpanded === false ? (
                    <ExpandMoreIcon className="accordion_arrows" />
                  ) : (
                    <ExpandLessIcon className="accordion_arrows" />
                  )}
                </div>
              </div>

              {acc.isExpanded && (
                <>
                  {acc.children &&
                    acc.children.length > 0 &&
                    acc.children.map((acch, ind) => (
                      <div key={ind} style={{ border: "1px solid #DBE3EA" }}>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "flex-start",
                            alignItems: "center",
                            cursor: "pointer",
                          }}
                          className="filter_child"
                        >
                          <Checkbox
                            checked={acch.checked}
                            id={"checkbox" + acch?.name}
                            indeterminate={
                              acch.models?.length >
                                acch.models?.filter(
                                  (value) => value.checked == true
                                )?.length &&
                              acch.models?.some(
                                (value) => value.checked == true
                              )
                            }
                            onClick={() => childClicked(index, ind)}
                            inputProps={{ "aria-label": "primary checkbox" }}
                          />
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                              alignItems: "center",
                              width: "100%",
                            }}
                            className={acch.children && "manual_pointer"}
                            onClick={() => expandCollapse(index, ind)}
                          >
                            <p className="child_p"> {acch.name}</p>
                            {acch.models && (
                              <>
                                {acch.isExpanded === false ? (
                                  <AddIcon className="accordion_arrows" />
                                ) : (
                                  <RemoveIcon className="accordion_arrows" />
                                )}
                              </>
                            )}
                          </div>
                        </div>
                      </div>
                    ))}
                </>
              )}
            </div>
          ))
        ) : (
          <p className="no_data_in_filter">No Data</p>
        )}
      </div>
    </>
  );
};
export default FaultStatusFilter;
