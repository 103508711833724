import * as React from "react";
import Autocomplete from "@mui/material/Autocomplete";
import Tabs from "@mui/material/Tabs";
import {
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
} from "@mui/material";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import SingleTypeFilter from "../../../components/all-filter-types/SingleFilter";
import TabPanel from "../../../components/all-filter-types/FilterTabPanel";
import Controls from "../../../components/controls/Controls";
import AccountsAndDepotsFilter from "../../../components/all-filter-types/accountAndDepot";
import { checkSingleFilterSelected } from "../../../components/all-filter-types/isFilteredCheckFunctions";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";

const DataCatalogHistoryFilter = (props) => {
  const {
    userData,

    userDataFilter,
    fromDateFilter,
    toDateFilter,
    operationFilter,
    applyFilter,
  } = props;
  const [value, setValue] = React.useState("user");
  const [searcheddBy, setSearcheddBy] = React.useState(null);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const [tempUserDataFilter, setTempUserDataFilter] = React.useState(
    JSON.parse(JSON.stringify(userDataFilter))
  );
  const [tempFromDateFilter, setTempFromDateFilter] = React.useState(
    JSON.parse(JSON.stringify(fromDateFilter))
  );
  const [tempToDateFilter, setTempToDateFilter] = React.useState(
    JSON.parse(JSON.stringify(toDateFilter))
  );
  const [tempOperationFilter, setTempOperationFilter] = React.useState(
    JSON.parse(JSON.stringify(operationFilter))
  );

  const clearAll = () => {
    if (tempOperationFilter[0].children) {
      tempOperationFilter[0].checked = false;
      tempOperationFilter[0].children.map((child) => {
        child.checked = false;
      });
    }
    setTempOperationFilter([...tempOperationFilter]);
    setTempFromDateFilter("");
    setTempToDateFilter("");
    setTempUserDataFilter(null);
  };

  return (
    <>
      <div className="filters_with_vertical_tab">
        <Box sx={{ flexGrow: 1, bgcolor: "background.paper", display: "flex" }}>
          <Tabs
            orientation="vertical"
            variant="scrollable"
            value={value}
            onChange={handleChange}
            aria-label="Vertical tabs example"
            sx={{ borderRight: 1, borderColor: "divider" }}
          >
            <Tab
              label="User"
              value={"user"}
              icon={
                tempUserDataFilter ? (
                  <FiberManualRecordIcon className="filter_dot" />
                ) : null
              }
              iconPosition="end"
            />
            <Tab
              label="Operation"
              value={"Operation"}
              icon={
                checkSingleFilterSelected(tempOperationFilter) ? (
                  <FiberManualRecordIcon className="filter_dot" />
                ) : null
              }
              iconPosition="end"
            />
            <Tab
              label="Date Range"
              value={"Date Range"}
              icon={
                tempFromDateFilter && tempToDateFilter ? (
                  <FiberManualRecordIcon className="filter_dot" />
                ) : null
              }
              iconPosition="end"
            />
          </Tabs>
          <TabPanel value={value} index={"user"}>
            <div className="filter_tab_vertical_body">
              <Autocomplete
                options={userData}
                onInputChange={(event) => {
                  if (event) {
                    setSearcheddBy(event.target.value);
                  }
                }}
                autoComplete
                id="user-data-filter"
                className="searchWithIconWithoutBorder"
                value={tempUserDataFilter}
                onChange={(event, value) => {
                  setTempUserDataFilter(value);
                }}
                popupIcon=""
                open={
                  searcheddBy
                    ? searcheddBy.length >= 1 && userData !== searcheddBy
                    : false
                }
                renderInput={(params) => (
                  <TextField {...params} label="Search" size="=small" />
                )}
              />
            </div>
          </TabPanel>
          <TabPanel value={value} index={"Operation"}>
            <div className="filter_tab_vertical_body">
              <SingleTypeFilter
                _stateVariable={tempOperationFilter}
                setFuntion={setTempOperationFilter}
              />
            </div>
          </TabPanel>
          <TabPanel value={value} index={"Date Range"}>
            <div className="filter_tab_vertical_body">
              <div style={{ display: "flex", margin: "12px" }}>
                <div style={{ padding: "0 10px" }}>
                  <TextField
                    label="From Date"
                    type="date"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    value={tempFromDateFilter}
                    onChange={(event) => {
                      setTempFromDateFilter(event.target.value);
                    }}
                    inputProps={{
                      max: tempToDateFilter ? tempToDateFilter : null,
                    }}
                  />
                </div>
                <div style={{ padding: "0 10px" }}>
                  <TextField
                    label="To Date"
                    type="date"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    value={tempToDateFilter}
                    onChange={(event) => {
                      setTempToDateFilter(event.target.value);
                    }}
                    inputProps={{
                      min: tempFromDateFilter ? tempFromDateFilter : null,
                    }}
                  />
                </div>
              </div>
            </div>
          </TabPanel>
        </Box>
        <div className="filter_action_btn">
          <Controls.Button
            disabled={
              !(
                userData ||
                checkSingleFilterSelected(tempOperationFilter) ||
                tempFromDateFilter === "" ||
                tempToDateFilter === ""
              )
            }
            text="Clear All"
            variant="outlined"
            onClick={() => clearAll()}
            className="btn_font_size"
          />
          <Controls.Button
            text="Apply"
            color="primary"
            onClick={() =>
              applyFilter(
                tempUserDataFilter,
                tempFromDateFilter,
                tempToDateFilter,
                tempOperationFilter
              )
            }
            className="btn_font_size"
          />
        </div>
      </div>
    </>
  );
};
export default DataCatalogHistoryFilter;
