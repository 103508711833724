import React from "react";

import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";

const AutoSuggestion = (props) => {
  const {
    name,
    label,
    id,
    options,
    error = null,
    onChange,
    onBlur = null,
    value,
    freeSolo = false,
    disable = false,
  } = props;

  const [inputValue, setInputValue] = React.useState("");

  const convertToEventParam = (value) => ({
    target: {
      name,
      value,
    },
  });

  return (
    <Autocomplete
      isOptionEqualToValue={(option, value) => option?.value === value?.value}
      inputValue={inputValue}
      onInputChange={(event, newInputValue) => {
        setInputValue(newInputValue);
      }}
      onChange={(event, newValue) => {
        onChange(convertToEventParam(newValue));
      }}
      onBlur={(event) =>
        onBlur && onBlur(convertToEventParam(event.target.value))
      }
      freeSolo={freeSolo}
      id={id}
      value={value || null}
      options={options}
      renderInput={(params) => (
        <TextField
          {...params}
          disabled={disable}
          size="small"
          label={label}
          variant="outlined"
          {...(error && { error: true, helperText: error })}
        />
      )}
    />
  );
};

export default AutoSuggestion;
