import {
  apiProvider,
  indexedEndPoints,
} from "../../services/api/utilities/provider";

const {
  BILLABLE_PORTS,
  BILLABLE_CUSTOMERS_COUNT,
  FORD_COMPANY,
  COMPANY_PORTS,
  BILLING_DEPOTS,
  BILLING_COMPANIES,
  V3_BILLING_COMPANIES,
  BILLING_PACKAGE,
  ACTIVE_DEPOTS,
  BILLING_COMPANY_DETAILS,
} = indexedEndPoints;

export const getBillablePorts = async (payload = {}) =>
  await apiProvider.post(BILLABLE_PORTS, payload);

export const getBillableCustomersCount = async (payload = {}) =>
  await apiProvider.post(BILLABLE_CUSTOMERS_COUNT, payload);

export const getCompanyDetails = async (id) =>
  await apiProvider.getAll(`${FORD_COMPANY}/${id}`);

export const getBillingCompanyDepots = async (id) =>
  await apiProvider.getAll(COMPANY_PORTS.replace("id", id));

export const getBillingDepotsPlanDetails = async (id) =>
  await apiProvider.getAll(`${BILLING_DEPOTS}/${id}`);

export const getPortsCountAndBillingCycle = async () =>
  await apiProvider.getAll(BILLING_COMPANIES);

export const getBillingCompanies = async (payload = {}) =>
  await apiProvider.post(V3_BILLING_COMPANIES, payload);

export const getBasicCompaniesBillingDetails = async (id, payload) =>
  await apiProvider.getAll(BILLING_COMPANY_DETAILS.replace("id", id) + payload);

export const getPackageDetails = async () =>
  await apiProvider.getAll(BILLING_PACKAGE);

export const getActiveDepots = async () =>
  await apiProvider.getAll(ACTIVE_DEPOTS);
export default {
  getCompanyDetails,
  getBillingCompanyDepots,
  getBillingDepotsPlanDetails,
  getPortsCountAndBillingCycle,
  getPackageDetails,
  getActiveDepots,
};
