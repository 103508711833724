import React from "react";
import { Drawer } from "@mui/material";

import CloseIcon from "@mui/icons-material/Close";
import "./components-scss/SideDrawer.scss";
import Controls from "./controls/Controls";

const CommonFilterDrawer = (props) => {
  const { children, DrawerOC, toggleDrawer } = props;
  return (
    <Drawer
      anchor="right"
      open={DrawerOC.isOpen}
      onClose={() => toggleDrawer(false, "", {})}
      className="filtersideDrawerRoot"
    >
      <div className="filter_common_drawer">
        <div className="filter_title_section">
          <p className="filter_drawer_title">{DrawerOC.title}</p>

          <CloseIcon onClick={() => toggleDrawer(false, "", {})} />
        </div>
        {children}
      </div>
    </Drawer>
  );
};

export default CommonFilterDrawer;
