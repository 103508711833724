import { variables } from "../constants/Variables";
import jwt_decode from 'jwt-decode';
export const setData = (key, value) => {
  return localStorage.setItem(key, value);
};

export const removeData = (key) => {
  return localStorage.removeItem(key);
};

export const getData = (key) => {
  if (key === variables.USER_ROLE_PRIVILEGE && getData(variables.ACCESS_TOKEN)) {
    return JSON.stringify(jwt_decode(getData(variables.ACCESS_TOKEN)).modules);
  }
  else if (key === variables.USER_ROLE_ID && getData(variables.ACCESS_TOKEN)) {
    return (jwt_decode(getData(variables.ACCESS_TOKEN)).roleId);
  }
  return localStorage.getItem(key);
};

export const clearLocalStorage = () => {
  return localStorage.clear();
};
