import React, { useState } from "react";
import {
  Grid,
  MenuItem,
  Select,
  FormControl,
  FormGroup,
  FormControlLabel,
  FormHelperText,
  Checkbox,
} from "@mui/material";
import Controls from "../../../components/controls/Controls";
import { CSVLink } from "react-csv";
import "./../ChargerHealth.scss";

const ChargersExportCSV = (props) => {
  const { setOpenPopup, recordsAfterPagingAndSorting } = props;
  const [columnType, setColumnType] = useState("all");
  const [customHeader, setCustomHeader] = useState([]);

  const [headers, setheaders] = useState({
    chargerName: { label: "Charger name", checked: false },
    chargerId: { label: "Charger ID", checked: false },
    vendorManufacturer: { label: "Manufacturer (OEM)", checked: false },
    vendorModel: { label: "Model (OEM)", checked: false },
    chargerManufacturer: { label: "Sold as manufacturer", checked: false },
    chargerModel: { label: "Sold as model", checked: false },
    serialNumber: { label: "Serial number", checked: false },
    accountName: { label: "Account name", checked: false },
    depotName: { label: "Depot name", checked: false },
    accountId: { label: "Account ID", checked: false },
    depotId: { label: "Depot ID", checked: false },
    portsCount: { label: "Charge ports", checked: false },
    firmwareVersion: { label: "Firmware version", checked: false },
    connectivityStatus: { label: "Connectivity status", checked: false },
    networkType: { label: "Network type", checked: false },
    firmwareStatus: { label: "Firmware status", checked: false },
    chargingMode: { label: "Charging mode", checked: false },
    chargingStatus: { label: "Charging status", checked: false },
    firstCommunication: { label: "Charger commissioned on", checked: false },
    billingActivatedAt: { label: "Billing activated on", checked: false },
    lastAssemblyCheckinTime: {
      label: "Last assembly checkin time",
      checked: false,
    },
    maxChargingRate: {
      label: "Charger model max charging rate",
      checked: false,
    },
    minChargingRate: {
      label: "Charger model min charging rate",
      checked: false,
    },
    modelMaxAmps: { label: "Charger model max amps", checked: false },
    deratedMaxAmps: { label: "Derated max amps", checked: false },
    maxAmps: { label: "Software limited max amps", checked: false },
    powerType: { label: "Power type", checked: false },
    siteType: { label: "Site type", checked: false },
    chargerRfidStatus: { label: "RFID status", checked: false },
  });

  const allColumnsHeaders = [
    { label: "Charger name", key: "chargerName" },
    { label: "Charger ID", key: "chargerId" },
    { label: "Manufacturer (OEM)", key: "vendorManufacturer" },
    { label: "Model (OEM)", key: "vendorModel" },
    { label: "Sold as manufacturer", key: "chargerManufacturer" },
    { label: "Sold as model", key: "chargerModel" },
    { label: "Serial number", key: "serialNumber" },
    { label: "Account name", key: "accountName" },
    { label: "Depot name", key: "depotName" },
    { label: "Account ID", key: "accountId" },
    { label: "Depot ID", key: "depotId" },
    { label: "Charge ports", key: "portsCount" },
    { label: "Firmware version", key: "firmwareVersion" },
    { label: "Connectivity status", key: "connectivityStatus" },
    { label: "Network type", key: "networkType" },
    { label: "Firmware status", key: "firmwareStatus" },
    { label: "Charging mode", key: "chargingMode" },
    { label: "Charger model max charging rate", key: "maxChargingRate" },
    { label: "Charger model min charging rate", key: "minChargingRate" },
    { label: "Charger model max amps", key: "modelMaxAmps" },
    { label: "Derated max amps", key: "deratedMaxAmps" },
    { label: "Software limited max amps", key: "maxAmps" },
    { label: "Power type", key: "powerType" },
    { label: "Site type", key: "siteType" },
    { label: "Charging status", key: "chargingStatus" },
    { label: "RFID status", key: "chargerRfidStatus" },
    { label: "Charger commissioned on", key: "firstCommunication" },
    { label: "Billing activated on", key: "billingActivatedAt" },
    { label: "Last assembly checkin time", key: "lastAssemblyCheckinTime" },
  ];

  const handleChange = (event) => {
    setheaders({
      ...headers,
      [event.target.name]: {
        ...headers[event.target.name],
        checked: event.target.checked,
      },
    });
  };

  const {
    chargerName,
    chargerId,
    vendorManufacturer,
    vendorModel,
    chargerManufacturer,
    chargerModel,
    serialNumber,
    accountName,
    depotName,
    accountId,
    depotId,
    firmwareVersion,
    connectivityStatus,
    networkType,
    firmwareStatus,
    chargingMode,
    chargingStatus,
    portsCount,
    firstCommunication,
    billingActivatedAt,
    lastAssemblyCheckinTime,
    maxChargingRate,
    minChargingRate,
    modelMaxAmps,
    maxAmps,
    deratedMaxAmps,
    siteType,
    powerType,
    chargerRfidStatus,
  } = headers;

  React.useEffect(() => {
    let keys = Object.keys(headers);
    let filtered = keys.filter((key) => headers[key].checked);
    let temp = [];
    filtered?.map((single) => {
      let tempObj = {};
      tempObj["label"] = headers[single].label;
      tempObj["key"] = single;
      temp.push(tempObj);
    });
    setCustomHeader(temp);
  }, [headers]);

  const handleColumnType = (event) => {
    setColumnType(event.target.value);
  };

  return (
    <>
      <div className="popup_download_layout">
        <p>Columns</p>

        <div>
          <Select
            value={columnType}
            onChange={handleColumnType}
            className="chargerExportCSV"
          >
            <MenuItem value={"all"}>Default (29)</MenuItem>
            <MenuItem value={"custom"}>Custom</MenuItem>
          </Select>
        </div>
        {columnType === "custom" ? (
          <FormControl
            component="fieldset"
            variant="standard"
            className="fullWidth"
          >
            <FormGroup>
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={vendorManufacturer.checked}
                        onChange={handleChange}
                        name="vendorManufacturer"
                      />
                    }
                    label="Manufacturer (OEM)"
                    className="fullWidth"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={chargerManufacturer.checked}
                        onChange={handleChange}
                        name="chargerManufacturer"
                      />
                    }
                    label="Sold as manufacturer"
                    className="fullWidth"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={chargerName.checked}
                        onChange={handleChange}
                        name="chargerName"
                      />
                    }
                    label="Charger name"
                    className="fullWidth"
                  />

                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={chargerId.checked}
                        onChange={handleChange}
                        name="chargerId"
                      />
                    }
                    label="Charger ID"
                    className="fullWidth"
                  />

                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={firmwareVersion.checked}
                        onChange={handleChange}
                        name="firmwareVersion"
                      />
                    }
                    label="Firmware version"
                    className="fullWidth"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={connectivityStatus.checked}
                        onChange={handleChange}
                        name="connectivityStatus"
                      />
                    }
                    label="Connectivity status"
                    className="fullWidth"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={chargingStatus.checked}
                        onChange={handleChange}
                        name="chargingStatus"
                      />
                    }
                    label="Charging status"
                    className="fullWidth"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={accountName.checked}
                        onChange={handleChange}
                        name="accountName"
                      />
                    }
                    label="Account"
                    className="fullWidth"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={depotName.checked}
                        onChange={handleChange}
                        name="depotName"
                      />
                    }
                    label="Depot"
                    className="fullWidth"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={siteType.checked}
                        onChange={handleChange}
                        name="siteType"
                      />
                    }
                    label="Site type"
                    className="fullWidth"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={powerType.checked}
                        onChange={handleChange}
                        name="powerType"
                      />
                    }
                    label="Power type"
                    className="fullWidth"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={firstCommunication.checked}
                        onChange={handleChange}
                        name="firstCommunication"
                      />
                    }
                    label="Charger commissioned on"
                    className="fullWidth"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={minChargingRate.checked}
                        onChange={handleChange}
                        name="minChargingRate"
                      />
                    }
                    label="Charger model min charging rate"
                    className="fullWidth"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={modelMaxAmps.checked}
                        onChange={handleChange}
                        name="modelMaxAmps"
                      />
                    }
                    label="Charger model max amps"
                    className="fullWidth"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={maxAmps.checked}
                        onChange={handleChange}
                        name="maxAmps"
                      />
                    }
                    label="Software limited max amps"
                    className="fullWidth"
                  />
                </Grid>
                <Grid item xs={6}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={vendorModel.checked}
                        onChange={handleChange}
                        name="vendorModel"
                      />
                    }
                    label="Model (OEM)"
                    className="fullWidth"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={chargerModel.checked}
                        onChange={handleChange}
                        name="chargerModel"
                      />
                    }
                    label="Sold as model"
                    className="fullWidth"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={serialNumber.checked}
                        onChange={handleChange}
                        name="serialNumber"
                      />
                    }
                    label="ID(serial number)"
                    className="fullWidth"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={firmwareStatus.checked}
                        onChange={handleChange}
                        name="firmwareStatus"
                      />
                    }
                    label="Firmware status"
                    className="fullWidth"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={networkType.checked}
                        onChange={handleChange}
                        name="networkType"
                      />
                    }
                    label="Network"
                    className="fullWidth"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={portsCount.checked}
                        onChange={handleChange}
                        name="portsCount"
                      />
                    }
                    label="Charge ports"
                    className="fullWidth"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={accountId.checked}
                        onChange={handleChange}
                        name="accountId"
                      />
                    }
                    label="Account ID"
                    className="fullWidth"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={depotId.checked}
                        onChange={handleChange}
                        name="depotId"
                      />
                    }
                    label="Depot ID"
                    className="fullWidth"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={chargerRfidStatus.checked}
                        onChange={handleChange}
                        name="chargerRfidStatus"
                      />
                    }
                    label="RFID status"
                    style={{ width: "100%" }}
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={chargingMode.checked}
                        onChange={handleChange}
                        name="chargingMode"
                      />
                    }
                    label="Charging mode"
                    className="fullWidth"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={billingActivatedAt.checked}
                        onChange={handleChange}
                        name="billingActivatedAt"
                      />
                    }
                    label="Billing activated on"
                    className="fullWidth"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={maxChargingRate.checked}
                        onChange={handleChange}
                        name="maxChargingRate"
                      />
                    }
                    label="Charger model max charging Rate"
                    className="fullWidth"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={deratedMaxAmps.checked}
                        onChange={handleChange}
                        name="deratedMaxAmps"
                      />
                    }
                    label="Derated max amps"
                    className="fullWidth"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={lastAssemblyCheckinTime.checked}
                        onChange={handleChange}
                        name="lastAssemblyCheckinTime"
                      />
                    }
                    label="Last assembly checkin time"
                    className="fullWidth"
                  />
                </Grid>
              </Grid>
            </FormGroup>
            <FormHelperText>
              Only the checked columns will be downloaded, and minimum 2 columns
              must be selected
            </FormHelperText>
          </FormControl>
        ) : (
          <p>
            Manufacturer (OEM), Model (OEM), Sold as manufacturer, Sold as
            model, Charger name, ID(serial number), Firmware version, Firmware
            status, Connectivity status, Network, Charging status, Charge ports,
            Account, Account ID, Depot, Depot ID, Site type, RFID status, Power
            type, Charging mode, Charger commissioned on, Billing activated on,
            Charger model min charging rate, Charger model max amps, Derated max
            amps, Charger model max charging rate, Software limited max amps,
            Last assembly checkin time, Charger ID
          </p>
        )}
        <p className="exportCSVCount">
          Exporting{" "}
          <span className="exportCSVCountNumber">
            {recordsAfterPagingAndSorting.length}
          </span>{" "}
          records
        </p>
        <Grid container justifyContent="flex-end" columnGap="20px">
          <Grid item xs={2}>
            <div className="endTextAlign">
              <Controls.Button
                variant="outlined"
                text="Cancel"
                onClick={() => {
                  setOpenPopup({
                    isOpen: false,
                    title: "Export CSV",
                    child: "exportCSV",
                  });
                }}
              />
            </div>
          </Grid>
          <Grid item xs={2}>
            <div className="endTextAlign">
              {columnType === "all" ? (
                <CSVLink
                  data={recordsAfterPagingAndSorting}
                  headers={allColumnsHeaders}
                  filename="Charger-Details.csv"
                  onClick={() => {
                    setOpenPopup({
                      isOpen: false,
                      title: "Export CSV",
                      child: "exportCSV",
                    });
                  }}
                  className="noLinkUnderline"
                >
                  <Controls.Button text={"Export"} />
                </CSVLink>
              ) : (
                customHeader.length > 1 && (
                  <CSVLink
                    data={recordsAfterPagingAndSorting}
                    headers={customHeader}
                    filename="Charger-Details.csv"
                    onClick={() => {
                      setOpenPopup({
                        isOpen: false,
                        title: "Export CSV",
                        child: "exportCSV",
                      });
                    }}
                    className="noLinkUnderline"
                  >
                    <Controls.Button text={"Export"} />
                  </CSVLink>
                )
              )}
            </div>
          </Grid>
        </Grid>
      </div>
    </>
  );
};

export default ChargersExportCSV;
