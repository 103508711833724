export const chargerMinMaxAmpCheck = (value, modelMaxAmps) => {
  if (value == null || !modelMaxAmps) return true;
  const minAmps = 1;
  const maxAmps = modelMaxAmps;

  let errors = [];

  if (parseInt(value) < minAmps) {
    errors.push("Max amps of charger is less than 1");
  }
  if (parseInt(value) > parseInt(maxAmps)) {
    errors.push("Software limit cannot be greater than charger model max amps");
  }

  if (errors.length) {
    return errors;
  } else {
    return true;
  }
};

export const chargerMinRateCheck = (
  min,
  max,
  MaxValueOfMinChargingRateOfPort
) => {
  const minMin = 0;
  const maxMax = 500;
  let errors = [];
  if (min == null || max == null) return true; // using double equals to check for both null and undefined
  if (!(parseInt(min) >= minMin && parseInt(min) <= parseInt(max))) {
    errors.push(
      "Minimum charging rate of charger is greater than Maximum charging rate of charger or less than Minimum charging rate of charger"
    );
  }
  if (MaxValueOfMinChargingRateOfPort) {
    if (min > MaxValueOfMinChargingRateOfPort) {
      errors.push(
        "Minimum charging rate of charger is greater than minimum charging rate of one of the charge port"
      );
    }
  }

  if (errors.length) {
    return errors;
  } else {
    return true;
  }
};
export const chargerMaxRateCheck = (
  min,
  max,
  MinValueOfMaxChargingRateOfPort
) => {
  const minMin = 0;
  const maxMax = 500;
  let errors = [];
  if (min == null || max == null) return true; // using double equals to check for both null and undefined
  if (!(parseInt(max) >= parseInt(min) && parseInt(max) <= maxMax)) {
    errors.push(
      "Maximum charging rate of charger is less than Minimum charging rate of charger or greater than Maximum charging rate of charger"
    );
  }
  if (MinValueOfMaxChargingRateOfPort) {
    if (max < MinValueOfMaxChargingRateOfPort) {
      errors.push(
        "Maximum charging rate of charger is less than Maximum charging rate of one of the charge port"
      );
    }
  }
  if (errors.length) {
    return errors;
  } else {
    return true;
  }
};

export const chargerPortMinMaxAmpCheck = (value, chargerMaxAmp) => {
  const minAmps = 1;
  let errors = [];
  if (parseInt(value) < minAmps) {
    errors.push("Max amps of port is less than 1");
  }
  if (parseInt(value) > chargerMaxAmp) {
    errors.push(
      "Software limit at the port cannot be greater than charger max amps limit"
    );
  }
  if (errors.length) {
    return errors;
  } else {
    return true;
  }
};

export const chargerPortMinRateCheck = (
  val,
  min,
  max,
  allMinChargingConnected,
  data,
  c_maxChargingRate,
  action
) => {
  let errors = [];
  let remaining;

  if (action == "Add") {
    remaining = parseInt(allMinChargingConnected) + parseInt(val);
  }

  if (action == "Edit") {
    remaining =
      parseInt(allMinChargingConnected) -
      parseInt(data.minimumChargingRate) +
      parseInt(val);
  }

  if (!(parseInt(val) >= parseInt(min) && parseInt(val) <= parseInt(max))) {
    errors.push(
      "Minimum charging rate should be less then Maximum charging rate and greater than chargers Minimum charging rate "
    );
  }

  if (!(parseInt(remaining) <= parseInt(c_maxChargingRate))) {
    errors.push(
      "Total of minimum charging rate of all chargeports exceeded the total power of charger."
    );
  }

  if (errors.length) {
    return errors;
  } else {
    return true;
  }
};

export const chargerPortMaxRateCheck = (val, min, max) => {
  let errors = [];
  if (!(parseInt(val) >= parseInt(min) && parseInt(val) <= parseInt(max))) {
    errors.push(
      "Maximum charging rate should be greater than Minimum charging rate"
    );
  }

  if (errors.length) {
    return errors;
  } else {
    return true;
  }
};
