export const addUser = (payload) => {
  return {
    type: "ADD_USER",
    payload
  };
};

export const updateUser = (payload) => {
  return {
    type: "UPDATE_USER",
    payload
  };
};

export const removeUser = () => {
  return { type: "REMOVE_USER" };
};

export const getRolesPrivilege = (payload) => {
  return {
    type: "GET_ROLES",
    payload
  };
};
