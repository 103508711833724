import * as React from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import Box from "@mui/material/Box";
import Controls from "../../../../components/controls/Controls";
import AccountsAndDepotsFilter from "../../../../components/all-filter-types/accountAndDepot";
import ChargerMakeAndModelFilter from "../../../../components/all-filter-types/ChargerMakeAndModel";
import FaultImpactFilter from "../../../../components/all-filter-types/FaultImpact";
import FaultStatusFilter from "../../../../components/all-filter-types/FaultStatus";
import FaultOpenDuration from "../../../../components/all-filter-types/OpenDuration";
import TabPanel from "../../../../components/all-filter-types/FilterTabPanel";
import NestedFilterWithSearch from "../../../../components/all-filter-types/NestedFilter";
import {
  checkDepotSelected,
  checkChargersSelected,
  checkSingleFilterSelected,
  checkNestedFilterSelected,
} from "../../../../components/all-filter-types/isFilteredCheckFunctions";
import SingleTypeFilter from "../../../../components/all-filter-types/SingleFilter";
const FilterFaults = (props) => {
  const {
    allAccounts,
    allMakes,
    allPowerType,
    applyFilter,
    allFaultsImpact,
    allFaultStatus,
    allOpenDuration,
    allDepotType,
    countryCode,
  } = props;

  const [value, setValue] = React.useState("Account and depot");

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const [tempAllAccounts, setTempAllAccounts] = React.useState(
    JSON.parse(JSON.stringify(allAccounts))
  );
  const [tempAllMakeModel, setTempAllMakeModel] = React.useState(
    JSON.parse(JSON.stringify(allMakes))
  );
  const [tempAllPowerType, setTempAllPowerType] = React.useState(
    JSON.parse(JSON.stringify(allPowerType))
  );
  const [tempAllFaultsImpact, setTempAllFaultsImpact] = React.useState(
    JSON.parse(JSON.stringify(allFaultsImpact))
  );
  const [tempAllDepotType, setTempAllDepotType] = React.useState(
    JSON.parse(JSON.stringify(allDepotType))
  );
  const [tempAllFaultStatus, setTempAllFaultStatus] = React.useState(
    JSON.parse(JSON.stringify(allFaultStatus))
  );
  const [tempAllOpenDuration, setTempAllOpenDuration] = React.useState(
    JSON.parse(JSON.stringify(allOpenDuration))
  );
  const [tempCountryCode, setTempCountryCode] = React.useState(
    JSON.parse(JSON.stringify(countryCode))
  );

  const clearAll = () => {
    if (tempAllAccounts[0].children) {
      tempAllAccounts[0].checked = false;
      tempAllAccounts[0].children.map((child) => {
        child.checked = false;
        if (child.depotsList) {
          child.depotsList.map((grandChild) => {
            grandChild.checked = false;
          });
        }
      });
    }
    if (tempAllMakeModel[0].children) {
      tempAllMakeModel[0].checked = false;
      tempAllMakeModel[0].children.map((child) => {
        child.checked = false;
        if (child.chargers) {
          child.chargers.map((grandChild) => {
            grandChild.checked = false;
          });
        }
      });
    }
    if (tempAllPowerType[0].children) {
      tempAllPowerType[0].checked = false;
      tempAllPowerType[0].children.map((child) => {
        child.checked = false;
      });
    }
    if (tempCountryCode[0].children) {
      tempCountryCode[0].checked = false;
      tempCountryCode[0].children.map((child) => {
        child.checked = false;
        if (child.children) {
          child.children.map((grandChild) => {
            grandChild.checked = false;
          });
        }
      });
    }
    if (tempAllFaultsImpact[0].children) {
      tempAllFaultsImpact[0].checked = false;
      tempAllFaultsImpact[0].children.map((child) => {
        child.checked = false;
      });
    }
    if (tempAllDepotType[0].children) {
      tempAllDepotType[0].checked = false;
      tempAllDepotType[0].children.map((child) => {
        child.checked = false;
      });
    }
    if (tempAllFaultStatus[0].children) {
      tempAllFaultStatus[0].checked = false;
      tempAllFaultStatus[0].children.map((child) => {
        child.checked = false;
      });
    }
    if (tempAllOpenDuration[0].children) {
      tempAllOpenDuration[0].checked = false;
      tempAllOpenDuration[0].children.map((child) => {
        child.checked = false;
      });
    }
    setTempAllAccounts([...tempAllAccounts]);
    setTempAllMakeModel([...tempAllMakeModel]);
    setTempAllPowerType([...tempAllPowerType]);
    setTempAllDepotType([...tempAllDepotType]);
    setTempAllFaultsImpact([...tempAllFaultsImpact]);
    setTempAllFaultStatus([...tempAllFaultStatus]);
    setTempAllOpenDuration([...tempAllOpenDuration]);
    setTempCountryCode([...tempCountryCode]);
  };

  return (
    <>
      <div className="filters_with_vertical_tab">
        <Box sx={{ flexGrow: 1, bgcolor: "background.paper", display: "flex" }}>
          <Tabs
            orientation="vertical"
            variant="scrollable"
            value={value}
            onChange={handleChange}
            aria-label="Vertical tabs example"
            sx={{ borderRight: 1, borderColor: "divider" }}
          >
            <Tab
              label="Account and depot"
              value={"Account and depot"}
              icon={
                checkDepotSelected(tempAllAccounts) ? (
                  <FiberManualRecordIcon className="filter_dot" />
                ) : null
              }
              iconPosition="end"
            />
            <Tab
              label="Charger make and model"
              value={"Charger make and model"}
              icon={
                checkChargersSelected(tempAllMakeModel) ? (
                  <FiberManualRecordIcon className="filter_dot" />
                ) : null
              }
              iconPosition="end"
            />
            <Tab
              label="Power type"
              value={"Power type"}
              icon={
                checkSingleFilterSelected(tempAllPowerType) ? (
                  <FiberManualRecordIcon className="filter_dot" />
                ) : null
              }
              iconPosition="end"
            />
            <Tab
              label="Fault Impact"
              value={"Fault Impact"}
              icon={
                checkSingleFilterSelected(tempAllFaultsImpact) ? (
                  <FiberManualRecordIcon className="filter_dot" />
                ) : null
              }
              iconPosition="end"
            />
            <Tab
              label="Depot Type"
              value={"Depot Type"}
              icon={
                checkSingleFilterSelected(tempAllDepotType) ? (
                  <FiberManualRecordIcon className="filter_dot" />
                ) : null
              }
              iconPosition="end"
            />
            <Tab
              label="Status"
              value={"Status"}
              icon={
                checkSingleFilterSelected(tempAllFaultStatus) ? (
                  <FiberManualRecordIcon className="filter_dot" />
                ) : null
              }
              iconPosition="end"
            />
            <Tab
              label="Open Duration"
              value={"Open Duration"}
              icon={
                checkSingleFilterSelected(tempAllOpenDuration) ? (
                  <FiberManualRecordIcon className="filter_dot" />
                ) : null
              }
              iconPosition="end"
            />
            <Tab
              label="Country"
              value={"Country"}
              icon={
                checkNestedFilterSelected(tempCountryCode) ? (
                  <FiberManualRecordIcon className="filter_dot" />
                ) : null
              }
              iconPosition="end"
            />
          </Tabs>
          <TabPanel value={value} index={"Account and depot"}>
            <div className="filter_tab_vertical_body">
              <AccountsAndDepotsFilter
                _allAccounts={tempAllAccounts}
                setFuntion={setTempAllAccounts}
              />
            </div>
          </TabPanel>
          <TabPanel value={value} index={"Charger make and model"}>
            <div className="filter_tab_vertical_body">
              <ChargerMakeAndModelFilter
                _allMakes={tempAllMakeModel}
                setFuntion={setTempAllMakeModel}
              />
            </div>
          </TabPanel>
          <TabPanel value={value} index={"Power type"}>
            <div className="filter_tab_vertical_body">
              <SingleTypeFilter
                _stateVariable={tempAllPowerType}
                setFuntion={setTempAllPowerType}
              />
            </div>
          </TabPanel>
          <TabPanel value={value} index={"Fault Impact"}>
            <div className="filter_tab_vertical_body">
              <FaultImpactFilter
                _allFaultsImpact={tempAllFaultsImpact}
                setFuntion={setTempAllFaultsImpact}
              />
            </div>
          </TabPanel>
          <TabPanel value={value} index={"Depot Type"}>
            <div className="filter_tab_vertical_body">
              <SingleTypeFilter
                _stateVariable={tempAllDepotType}
                setFuntion={setTempAllDepotType}
              />
            </div>
          </TabPanel>
          <TabPanel value={value} index={"Status"}>
            <div className="filter_tab_vertical_body">
              <FaultStatusFilter
                _allFaultStatus={tempAllFaultStatus}
                setFuntion={setTempAllFaultStatus}
              />
            </div>
          </TabPanel>
          <TabPanel value={value} index={"Open Duration"}>
            <div className="filter_tab_vertical_body">
              <FaultOpenDuration
                _allOpenDuration={tempAllOpenDuration}
                setFuntion={setTempAllOpenDuration}
              />
            </div>
          </TabPanel>
          <TabPanel value={value} index={"Country"}>
            <div className="filter_tab_vertical_body">
              <NestedFilterWithSearch
                _allData={tempCountryCode}
                setFuntion={setTempCountryCode}
              />
            </div>
          </TabPanel>
        </Box>
        <div className="filter_action_btn">
          <Controls.Button
            disabled={
              !(
                checkDepotSelected(tempAllAccounts) ||
                checkChargersSelected(tempAllMakeModel) ||
                checkSingleFilterSelected(tempAllPowerType) ||
                checkSingleFilterSelected(tempAllFaultsImpact) ||
                checkSingleFilterSelected(tempAllDepotType) ||
                checkSingleFilterSelected(tempAllFaultStatus) ||
                checkSingleFilterSelected(tempAllOpenDuration) ||
                checkNestedFilterSelected(tempCountryCode)
              )
            }
            text="Clear All"
            variant="outlined"
            onClick={() => clearAll()}
            className="btn_font_size"
          />
          <Controls.Button
            text="Apply"
            color="primary"
            onClick={() =>
              applyFilter(
                tempAllAccounts,
                tempAllMakeModel,
                tempAllPowerType,
                tempAllFaultsImpact,
                tempAllDepotType,
                tempAllFaultStatus,
                tempAllOpenDuration,
                tempCountryCode
              )
            }
            className="btn_font_size"
          />
        </div>
      </div>
    </>
  );
};

export default FilterFaults;
