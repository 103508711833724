import * as React from "react";
import { InputAdornment } from "@mui/material";

import SearchIcon from "@mui/icons-material/Search";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import { Checkbox, Autocomplete, TextField } from "@mui/material";
import Controls from "../controls/Controls";

const NestedFilterWithSearch = (props) => {
  let { _allData, setFuntion, clearAll } = props;
  const [searchText, setSearchText] = React.useState("");
  const [allData, setAllData] = React.useState([]);
  React.useEffect(() => {
    if (_allData.length) {
      for (var i = 0; i < _allData.length; i++) {
        _allData[i].index = i;
        for (var j = 0; j < _allData[i].children.length; j++) {
          _allData[i].children[j].index = j;
          for (var k = 0; k < _allData[i].children[j].children.length; k++) {
            _allData[i].children[j].children[k].index = k;
          }
        }
      }
      setFuntion([..._allData]);
    }
  }, []);

  React.useEffect(() => {
    if (_allData.length) {
      let filtered = [];
      const _allData_ = JSON.parse(JSON.stringify(_allData));

      for (const name of _allData_[0].children) {
        const matchedNames = name.children?.filter((name) =>
          name.name.toLowerCase().includes(searchText.toLowerCase())
        );
        const matchedName = name.name
          .toLowerCase()
          .includes(searchText.toLowerCase());

        if (matchedNames.length) {
          filtered.push({
            ...name,
            children: matchedNames,
          });
        } else if (matchedName) {
          filtered.push(name);
        }
      }

      setAllData([
        {
          ..._allData_[0],
          children: filtered,
        },
      ]);
    }
  }, [searchText, setFuntion, _allData, clearAll]);

  const expandCollapse = (
    parentInd,
    firstChildIndex = null,
    secondChildIndex = null
  ) => {
    if (secondChildIndex !== null) {
      _allData[parentInd].children[firstChildIndex].children[
        secondChildIndex
      ].isExpanded =
        !_allData[parentInd].children[firstChildIndex].children[
          secondChildIndex
        ].isExpanded;
      setFuntion([..._allData]);
    } else if (firstChildIndex !== null) {
      _allData[parentInd].children[firstChildIndex].isExpanded =
        !_allData[parentInd].children[firstChildIndex].isExpanded;
      setFuntion([..._allData]);
    } else {
      _allData[parentInd].isExpanded = !_allData[parentInd].isExpanded;
      setFuntion([..._allData]);
    }
  };

  const parentClicked = (accountIndex, visAccountIndex) => {
    const isChecked = !_allData[accountIndex].checked;
    _allData[accountIndex].checked = isChecked;

    for (let j = 0; j < allData[visAccountIndex].children.length; j++) {
      const j_index = allData[visAccountIndex].children[j].index;
      _allData[accountIndex].children[j_index].checked = isChecked;

      for (
        let k = 0;
        k < allData[visAccountIndex].children[j].children.length;
        k++
      ) {
        const k_index = allData[visAccountIndex].children[j].children[k].index;
        _allData[accountIndex].children[j_index].children[k_index].checked =
          isChecked;
      }
    }
    setFuntion([..._allData]);
  };

  const childClicked = (
    accountIndex,
    visAccountIndex,
    companyIndex,
    visCompanyIndex
  ) => {
    const isChecked = !_allData[accountIndex].children[companyIndex].checked;
    _allData[accountIndex].children[companyIndex].checked = isChecked;
    _allData[accountIndex].checked = _allData[accountIndex].children.every(
      (company) => company.checked
    );

    for (
      let k = 0;
      k < allData[visAccountIndex].children[visCompanyIndex].children.length;
      k++
    ) {
      const k_index =
        allData[visAccountIndex].children[visCompanyIndex].children[k].index;
      _allData[accountIndex].children[companyIndex].children[k_index].checked =
        isChecked;
    }
    setFuntion([..._allData]);
  };

  const grandChildClicked = (
    accountIndex,
    visAccountIndex,
    companyIndex,
    visCompanyIndex,
    depotIndex,
    visDepotIndex
  ) => {
    _allData[accountIndex].children[companyIndex].children[depotIndex].checked =
      !_allData[accountIndex].children[companyIndex].children[depotIndex]
        .checked;

    // Check if all depots got selected by this click.
    const visibleDepots = allData[visAccountIndex].children[
      visCompanyIndex
    ].children.map((depot) => depot.name);
    const checkedDepots = _allData[accountIndex].children[
      companyIndex
    ].children.filter(
      (depot) => visibleDepots.includes(depot.name) && depot.checked
    );

    if (!checkedDepots.length) {
      // No depots selected, hence company.checked == false, account.checked == false
      _allData[accountIndex].children[companyIndex].checked = false;
      _allData[accountIndex].checked = false;
    }

    if (visibleDepots.length === checkedDepots.length) {
      // If all visible depots are checked, then company.checked == true
      _allData[accountIndex].children[companyIndex].checked = true;
    }

    if (_allData[accountIndex].children[companyIndex].checked) {
      const visibleCompanies = allData[visAccountIndex].children.map(
        (company) => company.name
      );
      const checkedCompanies = _allData[accountIndex].children.filter(
        (company) => visibleCompanies.includes(company.name) && company.checked
      );
      // If all visible companies are checked, then account.checked == true
      _allData[accountIndex].checked =
        visibleCompanies.length === checkedCompanies.length;
    }
    setFuntion([..._allData]);
  };

  const handleSearch = (e) => {
    let val = e.target.value;
    setSearchText(e.target.value?.trim() ?? "");
  };

  // const handleDepotType = (event, value) => {
  //   setDepotType(value);
  // };

  return (
    <>
      {/* <div className="filter_Depot_Type">
        <p>Depot Type</p>
        <Autocomplete
          multiple
          name="depotType"
          style={{ width: "100%" }}
          disableCloseOnSelect
          value={selectedDepotType}
          onChange={handleDepotType}
          options={depotType}
          renderInput={(params) => (
            <TextField
              {...params}
              variant="outlined"
              size="small"
              placeholder={selectedDepotType.length ? "" : "All"}
            />
          )}
        />
      </div> */}
      <div className="filter_Search">
        <Controls.Input
          label={"Search"}
          id={"nested_filter_search"}
          style={{ width: "100%" }}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
          }}
          onChange={handleSearch}
        />
      </div>
      <div>
        {allData[0]?.children.length > 0 ? (
          allData.length > 0 &&
          allData.map((acc, index) => (
            <div
              className="side_filter_new"
              key={`allData-${index}`}
              style={{
                border: "1px solid #DBE3EA",
                marginBottom: "15px",
                borderRadius: "2px",
              }}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-start",
                  alignItems: "center",
                }}
                className="filter_parent"
              >
                <Checkbox
                  checked={acc.checked}
                  indeterminate={
                    acc.children?.length >
                      acc.children?.filter((value) => value.checked == true)
                        ?.length &&
                    acc.children?.some((value) => value.checked == true)
                  }
                  onClick={() => parentClicked(acc.index, index)}
                  inputProps={{ "aria-label": "primary checkbox" }}
                  id={"nested-filter-checkbox" + acc?.name}
                />
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    width: "100%",
                    cursor: "pointer",
                  }}
                  onClick={() => expandCollapse(acc.index)}
                >
                  <p className="parent_p">{acc.name}</p>
                  {acc.isExpanded === false ? (
                    <ExpandMoreIcon className="accordion_arrows" />
                  ) : (
                    <ExpandLessIcon className="accordion_arrows" />
                  )}
                </div>
              </div>

              {acc.isExpanded && (
                <>
                  {acc.children &&
                    acc.children.length > 0 &&
                    acc.children.map((acch, ind) => (
                      <div key={ind} style={{ border: "1px solid #DBE3EA" }}>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "flex-start",
                            alignItems: "center",
                            cursor: "pointer",
                          }}
                          className="filter_child"
                        >
                          <Checkbox
                            checked={acch.checked}
                            id={"nested-filter-checkbox" + acch?.name}
                            indeterminate={
                              acch.children?.length >
                                acch.children?.filter(
                                  (value) => value.checked == true
                                )?.length &&
                              acch.children?.some(
                                (value) => value.checked == true
                              )
                            }
                            onClick={() =>
                              childClicked(acc.index, index, acch.index, ind)
                            }
                            inputProps={{ "aria-label": "primary checkbox" }}
                          />
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                              alignItems: "center",
                              width: "100%",
                            }}
                            className={acch.children && "manual_pointer"}
                            onClick={() =>
                              expandCollapse(acc.index, acch.index)
                            }
                          >
                            <p className="child_p"> {acch.name}</p>
                            {acch.children && (
                              <>
                                {acch.isExpanded === false ? (
                                  <AddIcon className="accordion_arrows" />
                                ) : (
                                  <RemoveIcon className="accordion_arrows" />
                                )}
                              </>
                            )}
                          </div>
                        </div>

                        {acch.isExpanded && (
                          <div
                            style={{
                              padding: "0px 30px",
                            }}
                            className="filter_grand_child"
                          >
                            {acch.children &&
                              acch.children.length > 0 &&
                              acch.children.map((name, indx) => (
                                <div key={`acch-${indx}`}>
                                  <div
                                    style={{
                                      display: "flex",
                                      justifyContent: "flex-start",
                                      alignItems: "center",
                                    }}
                                  >
                                    <Checkbox
                                      checked={name.checked}
                                      id={"nested-filter-checkbox" + name?.name}
                                      onClick={() =>
                                        grandChildClicked(
                                          acc.index,
                                          index,
                                          acch.index,
                                          ind,
                                          name.index,
                                          indx
                                        )
                                      }
                                      inputProps={{
                                        "aria-label": "primary checkbox",
                                      }}
                                    />
                                    <p className="grand_child_p">{name.name}</p>
                                  </div>
                                </div>
                              ))}
                          </div>
                        )}
                      </div>
                    ))}
                </>
              )}
            </div>
          ))
        ) : (
          <p className="no_data_in_filter">No Data</p>
        )}
      </div>
    </>
  );
};
export default NestedFilterWithSearch;
