import { Grid, InputAdornment, Toolbar, Tooltip, Zoom } from "@mui/material";
import { makeStyles } from "@mui/styles";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import {
  apiProvider,
  indexedEndPoints,
} from "../../../services/api/utilities/provider";
import SearchIcon from "@mui/icons-material/Search";
import EditOutlined from "@mui/icons-material/EditOutlined";
import DeleteIcon from "@mui/icons-material/Delete";
import ListIcon from "@mui/icons-material/List";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import VerifiedIcon from "@mui/icons-material/Verified";
import { useSelector } from "react-redux";
import useTable from "../../../components/UseTable";
import OpsBreadcrumb from "../../../components/NewBreadcrumbs";
import PageHeader from "../../../components/PageHeader";
import PageMainContent from "../../../components/PageMainContent";
import Loader from "../../../components/Loader";
import { routePaths } from "../../../constants/RoutePaths";
import Controls from "../../../components/controls/Controls";
import Popup from "../../../components/Popup";
import { useHistory, useLocation } from "react-router-dom";
import ConfirmDialog from "../../../components/ConfirmDialog";
import AddEditVehicle from "./AddEditVehicle";
import CommonDrawer from "../../../components/SideDrawer";
import ToastMessage from "../../../components/ToastMessage";
import { PrivilegedComponent } from "../../../utils/PrivilegedComponent";

const allBreadcrumbsLinks = [
  {
    link: routePaths.DATA_CATALOG,
    title: "Data Catalog",
  },
  {
    link: routePaths.VEHICLES_DASHBOARD,
    title: "Vehicles Dashboard",
  },
];

const useStyles = makeStyles((theme) => ({
  "@global": {
    "*::-webkit-scrollbar": {
      width: "0.4em",
    },
    "*::-webkit-scrollbar-track": {
      "-webkit-box-shadow": "inset 0 0 6px rgba(0,0,0,0.00)",
    },
    "*::-webkit-scrollbar-thumb": {
      backgroundColor: "rgba(0,0,0,.1)",
      outline: "0px solid slategrey",
    },
  },
}));

const Vehicles = () => {
  const [loading, setLoading] = useState(false);
  const [filterFn, setFilterFn] = useState({ fn: (items) => items });
  const [vehicleData, setVehicleData] = useState([]);
  const [data, setData] = useState([]);
  const [refreshVehicles, setRefreshVehicles] = useState(true);
  const [openPopup, setOpenPopup] = useState({
    isOpen: false,
    title: "",
    child: "",
    item: {},
  });
  const [confirmDialog, setConfirmDialog] = useState({
    isOpen: false,
    type: "primary",
  });
  const [toast, setToast] = useState({
    isOpen: false,
    message: "",
    type: "",
  });
  const [drawerObj, setDrawerObj] = useState({
    isOpen: false,
    title: "",
    data: {},
  });

  const componentPrivileges = useSelector(
    (state) => state?.user?.componentPrivilege?.dataCatalogVehicle
  );

  const classes = useStyles();

  const history = useHistory();
  const search = useLocation().search;
  const searchParams = new URLSearchParams(search);
  const manufacturer = searchParams.get("manufacturer");
  const [actionItemslength, setActionItemslength] = useState(0);
  const { GET_VEHICLES, VEHICLES } = indexedEndPoints;

  useEffect(() => {
    const fetchVehicles = async () => {
      setLoading(true);
      const response = await apiProvider.getAll(
        `${GET_VEHICLES}/${manufacturer}`
      );
      setLoading(false);
      setData(response?.data);
      setRefreshVehicles(false);
    };
    refreshVehicles && fetchVehicles();
  }, [refreshVehicles]);

  const handleSearch = (e) => {
    setFilterFn({
      fn: (items) => {
        if (e.target.value?.trim() === "") return items;
        else
          return items?.filter((row) =>
            Object.values(row)?.some((val) =>
              val
                ?.toString()
                ?.toLowerCase()
                ?.includes(e.target.value?.trim().toLowerCase())
            )
          );
      },
    });
  };

  const headCells = useMemo(() => {
    let hc = [
      {
        id: "model",
        label: "Model",
        render: (row, col) => (
          <span className="centerAligned">
            {row[col]}&nbsp;
            {row?.isVerified && (
              <Tooltip
                arrow
                TransitionComponent={Zoom}
                title="Vehicle Verified"
              >
                <VerifiedIcon fontSize="small" className="verifiedIcon" />
              </Tooltip>
            )}
          </span>
        ),
      },
      { id: "batteryCapacity", label: "Battery Capacity (kWh)" },
      { id: "efficiency", label: "Efficiency (kWh/mile)" },
      // { id: "vehicleMetaId", label: "Vehicle Meta Id" },
    ];
    if (actionItemslength > 0)
      hc.push({ disableSorting: true, id: "action", label: "Action" });
    return hc;
  }, [componentPrivileges, actionItemslength]);

  const toggleDrawer = (openClose, title, item) => {
    setDrawerObj({ isOpen: openClose, title: title, data: item, type: "" });
  };

  const handleViewMoreInfo = (row) => {
    setDrawerObj({
      isOpen: true,
      title: "Vehicle Details",
      data: row,
      type: "viewData",
    });
  };

  const handleEditVehicle = (row) => {
    setOpenPopup({
      isOpen: true,
      title: "Update Vehicle",
      child: "updateVehicle",
      item: row,
    });
  };

  const handleDeleteVehicle = (row) => {
    setConfirmDialog({
      ...confirmDialog,
      isOpen: true,
      title: "Are you sure ?",
      subTitle: "You want to Delete this Vehicle",
      type: "secondary",
      onConfirm: () => deleteVehicle(row.vehicleMetaId),
    });
  };
  const deleteVehicle = useCallback(async (vehicleMetaId) => {
    setLoading(true);
    const response = await apiProvider.del(`${VEHICLES}/${vehicleMetaId}`);
    setLoading(false);
    if (response.statusCode >= 200 && response.statusCode <= 299) {
      setToast({
        isOpen: true,
        message: "Vehicle deleted successfully",
        type: "success",
      });
      setConfirmDialog({ ...confirmDialog, isOpen: false });
      setRefreshVehicles(true);
    } else {
      setToast({
        isOpen: true,
        message: "Vehicle deletion failed",
        type: "error",
      });
    }
  }, []);

  const actionItems = useMemo(() => {
    let actions = [];
    if (componentPrivileges?.includes("Update Vehicle"))
      actions.push({
        type: "Edit",
        label: "Edit",
        testid: "editVehicleBtn",
        icon: () => (
          <Tooltip arrow TransitionComponent={Zoom} title="Edit">
            <EditOutlined fontSize="small" />
          </Tooltip>
        ),
        onClick: handleEditVehicle,
      });
    if (componentPrivileges?.includes("Delete Vehicle"))
      actions.push({
        type: "Delete",
        label: "Delete",
        testid: "deleteVehicleBtn",
        icon: () => (
          <Tooltip arrow TransitionComponent={Zoom} title="Delete">
            <DeleteIcon fontSize="small" />
          </Tooltip>
        ),
        onClick: handleDeleteVehicle,
      });
    setActionItemslength(actions?.length);
    return actions?.length > 0 ? actions : null;
  }, [componentPrivileges]);

  const {
    tableContainer: TableContainer,
    tableHead: TableHead,
    tableBody: TableBody,
    tablePagination: TablePagination,
  } = useTable(data, headCells, filterFn, false, false, actionItems);
  const ToolbarMemoised = useCallback(
    ({ handleSearch, classes }) => {
      return (
        <Toolbar className="table_toolbar">
          <Controls.Input
            label={"Search"}
            className="searchInput"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
            onChange={handleSearch}
          />
          <Grid sm item />
          <PrivilegedComponent
            permission="Bulk Upload"
            module="dataCatalogVehicle"
          >
            <Controls.Button
              text="Bulk Upload"
              variant="outlined"
              startIcon={<ArrowUpwardIcon />}
              onClick={() =>
                history.push(`${routePaths.BULK_UPLOAD_VEHICLES}${search}`)
              }
            />
          </PrivilegedComponent>
          <PrivilegedComponent
            permission="Add Vehicle"
            module="dataCatalogVehicle"
          >
            <Controls.Button
              text="Add Model"
              data-testid="addVehicleBtn"
              variant="outlined"
              startIcon={<AddCircleOutlineIcon />}
              onClick={() =>
                setOpenPopup({
                  isOpen: true,
                  title: "Add Vehicle",
                  child: "addVehicle",
                  item: {},
                })
              }
            />
          </PrivilegedComponent>
        </Toolbar>
      );
    },
    [data]
  );

  return (
    <>
      <OpsBreadcrumb
        AllBreadcrumbsLinks={allBreadcrumbsLinks}
        title="Vehicles"
      />
      <PageHeader title="Vehicles" />
      <PageMainContent>
        <div className="company_details" id="chargerManufacturerClipboard">
          <Grid container>
            <Grid item xs={5}>
              <div className="single_details">
                <p className="title">Manufacturer</p>
                <Controls.CopyToClipboard
                  name={manufacturer}
                  setToast={setToast}
                />
              </div>
            </Grid>
          </Grid>
        </div>
        <ToolbarMemoised
          handleSearch={handleSearch}
          classes={classes}
          setOpenPopup={setOpenPopup}
        />
        <Loader isLoading={loading} />
        <TableContainer>
          <TableHead />
          {TableBody}
        </TableContainer>
        {TablePagination}
      </PageMainContent>
      <Popup openPopup={openPopup} setOpenPopup={setOpenPopup}>
        {
          <AddEditVehicle
            type={openPopup.child}
            openPopup={openPopup}
            setOpenPopup={setOpenPopup}
            setRefreshVehicles={setRefreshVehicles}
            setToast={setToast}
            vehicleId={
              openPopup.child === "updateVehicle"
                ? openPopup.item.vehicleMetaId
                : null
            }
          />
        }
      </Popup>
      {confirmDialog.isOpen && (
        <ConfirmDialog
          confirmDialog={confirmDialog}
          setConfirmDialog={setConfirmDialog}
        />
      )}
      <ToastMessage toast={toast} setToast={setToast} />
    </>
  );
};

export default Vehicles;
