import * as React from "react";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import SingleTypeFilter from "../../../components/all-filter-types/SingleFilter";
import Controls from "../../../components/controls/Controls";
import AccountsAndDepotsFilter from "../../../components/all-filter-types/accountAndDepot";
import ChargerMakeAndModelFilter from "../../../components/all-filter-types/ChargerMakeAndModel";
import TabPanel from "../../../components/all-filter-types/FilterTabPanel";
import NestedFilterWithSearch from "../../../components/all-filter-types/NestedFilter";
import {
  checkDepotSelected,
  checkChargersSelected,
  checkSingleFilterSelected,
  checkNestedFilterSelected,
} from "../../../components/all-filter-types/isFilteredCheckFunctions";
import "../ChargerHealth.scss";
import { FormControlLabel, FormGroup, Switch } from "@mui/material";

const NewChargerFirmwareFilter = (props) => {
  const {
    applyFilter,
    firmwareStatusFilter,
    connectivityStatusFilter,
    chargingModeFilter,
    allAccounts,
    allMakes,
    allPowerType,
    countryCode,
    allDepotType,
    isRFIDcapable,
    commissionStatus,
    isReconfigureNeeded,
  } = props;
  const [value, setValue] = React.useState("Account and Depot");
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const [tempAllAccounts, setTempAllAccounts] = React.useState(
    JSON.parse(JSON.stringify(allAccounts))
  );
  const [tempAllMakeModel, setTempAllMakeModel] = React.useState(
    JSON.parse(JSON.stringify(allMakes))
  );
  const [tempAllPowerType, setTempAllPowerType] = React.useState(
    JSON.parse(JSON.stringify(allPowerType))
  );
  const [tempAllDepotType, setTempAllDepotType] = React.useState(
    JSON.parse(JSON.stringify(allDepotType))
  );
  const [tempAllFirmwareStatusFilter, setTempAllFirmwareStatusFilter] =
    React.useState(JSON.parse(JSON.stringify(firmwareStatusFilter)));
  const [tempAllConnectivityStatusFilter, setTempAllConnectivityStatusFilter] =
    React.useState(JSON.parse(JSON.stringify(connectivityStatusFilter)));
  const [tempIsRFIDcapable, setTempIsRFIDcapable] = React.useState(
    JSON.parse(JSON.stringify(isRFIDcapable))
  );
  const [tempChargingModeFilter, setTempChargingModeFilter] = React.useState(
    JSON.parse(JSON.stringify(chargingModeFilter))
  );
  const [tempAllCountryCode, setTempAllCountryCode] = React.useState(
    JSON.parse(JSON.stringify(countryCode))
  );
  const [tempAllCommissionStatus, setTempAllCommissionStatus] = React.useState(
    JSON.parse(JSON.stringify(commissionStatus))
  );
  const [tempIsReconfigureNeeded, setTempIsReconfigureNeeded] =
    React.useState(isReconfigureNeeded);

  const clearAll = () => {
    tempAllAccounts[0].checked = false;
    tempAllAccounts[0].children?.map((single) => {
      single.checked = false;
      single.depotsList?.map((ele) => {
        ele.checked = false;
      });
    });
    setTempAllAccounts([...tempAllAccounts]);

    tempAllMakeModel[0].checked = false;
    tempAllMakeModel[0].children?.map((single) => {
      single.checked = false;
      single.chargers?.map((ele) => {
        ele.checked = false;
      });
    });
    setTempAllMakeModel([...tempAllMakeModel]);

    tempAllPowerType[0].checked = false;
    tempAllPowerType[0].children?.map((single) => {
      single.checked = false;
      single.children?.map((ele) => {
        ele.checked = false;
      });
    });
    setTempAllPowerType([...tempAllPowerType]);

    tempAllFirmwareStatusFilter[0].checked = false;
    tempAllFirmwareStatusFilter[0].children?.map((single) => {
      single.checked = false;
      single.children?.map((ele) => {
        ele.checked = false;
      });
    });
    setTempAllFirmwareStatusFilter([...tempAllFirmwareStatusFilter]);

    tempAllDepotType[0].checked = false;
    tempAllDepotType[0].children?.map((single) => {
      single.checked = false;
      single.children?.map((ele) => {
        ele.checked = false;
      });
    });
    setTempAllDepotType([...tempAllDepotType]);

    tempAllConnectivityStatusFilter[0].checked = false;
    tempAllConnectivityStatusFilter[0].children?.map((single) => {
      single.checked = false;
      single.children?.map((ele) => {
        ele.checked = false;
      });
    });
    setTempAllConnectivityStatusFilter([...tempAllConnectivityStatusFilter]);

    tempIsRFIDcapable[0].checked = false;
    tempIsRFIDcapable[0].children?.map((single) => {
      single.checked = false;
      single.children?.map((ele) => {
        ele.checked = false;
      });
    });
    setTempIsRFIDcapable([...tempIsRFIDcapable]);

    tempChargingModeFilter[0].checked = false;
    tempChargingModeFilter[0].children?.map((single) => {
      single.checked = false;
      single.children?.map((ele) => {
        ele.checked = false;
      });
    });
    setTempChargingModeFilter([...tempChargingModeFilter]);

    tempAllCountryCode.forEach((parent) => {
      parent.checked = false;
      parent.children.forEach((child) => {
        if (child.children) {
          child.children.forEach((grandChild) => {
            grandChild.checked = false;
          });
        }
        child.checked = false;
      });
    });
    setTempAllCountryCode(tempAllCountryCode);

    tempAllCommissionStatus[0].checked = false;
    tempAllCommissionStatus[0].children?.map((single) => {
      single.checked = false;
      single.children?.map((ele) => {
        ele.checked = false;
      });
    });
    setTempAllCommissionStatus([...tempAllCommissionStatus]);

    setTempIsReconfigureNeeded(false);
  };

  return (
    <>
      <div className="filters_with_vertical_tab">
        <Box sx={{ flexGrow: 1, bgcolor: "background.paper", display: "flex" }}>
          <Tabs
            orientation="vertical"
            variant="scrollable"
            value={value}
            onChange={handleChange}
            aria-label="Vertical tabs example"
            sx={{ borderRight: 1, borderColor: "divider" }}
          >
            <Tab
              label="Account and Depot"
              value={"Account and Depot"}
              icon={
                checkDepotSelected(tempAllAccounts) ? (
                  <FiberManualRecordIcon className="filter_dot" />
                ) : null
              }
              iconPosition="end"
            />
            <Tab
              label="Charger Make And Model"
              value={"Charger Make And Model"}
              icon={
                checkChargersSelected(tempAllMakeModel) ? (
                  <FiberManualRecordIcon className="filter_dot" />
                ) : null
              }
              iconPosition="end"
            />
            <Tab
              label="Power type"
              value={"Power type"}
              icon={
                checkSingleFilterSelected(tempAllPowerType) ? (
                  <FiberManualRecordIcon className="filter_dot" />
                ) : null
              }
              iconPosition="end"
            />
            <Tab
              label="Depot Type"
              value={"Depot Type"}
              icon={
                checkSingleFilterSelected(tempAllDepotType) ? (
                  <FiberManualRecordIcon className="filter_dot" />
                ) : null
              }
              iconPosition="end"
            />
            <Tab
              label="Firmware Status"
              value={"Firmware Status"}
              icon={
                checkSingleFilterSelected(tempAllFirmwareStatusFilter) ? (
                  <FiberManualRecordIcon className="filter_dot" />
                ) : null
              }
              iconPosition="end"
            />
            <Tab
              label="Connectivity Status"
              value={"Connectivity Status"}
              icon={
                checkSingleFilterSelected(tempAllConnectivityStatusFilter) ? (
                  <FiberManualRecordIcon className="filter_dot" />
                ) : null
              }
              iconPosition="end"
            />
            <Tab
              label="Config flags"
              value={"Config flags"}
              icon={
                checkSingleFilterSelected(tempIsRFIDcapable) ? (
                  <FiberManualRecordIcon className="filter_dot" />
                ) : null
              }
              iconPosition="end"
            />
            <Tab
              label="Charging Mode"
              value={"Charging Mode"}
              icon={
                checkSingleFilterSelected(tempChargingModeFilter) ? (
                  <FiberManualRecordIcon className="filter_dot" />
                ) : null
              }
              iconPosition="end"
            />
            <Tab
              label="Country"
              value={"Country"}
              icon={
                checkNestedFilterSelected(tempAllCountryCode) ? (
                  <FiberManualRecordIcon className="filter_dot" />
                ) : null
              }
              iconPosition="end"
            />
            <Tab
              label="Commission Status"
              value={"Commission Status"}
              icon={
                checkSingleFilterSelected(tempAllCommissionStatus) ? (
                  <FiberManualRecordIcon className="filter_dot" />
                ) : null
              }
              iconPosition="end"
            />
          </Tabs>
          <TabPanel value={value} index={"Account and Depot"}>
            <div className="filter_tab_vertical_body">
              <AccountsAndDepotsFilter
                _allAccounts={tempAllAccounts}
                setFuntion={setTempAllAccounts}
              />
            </div>
          </TabPanel>
          <TabPanel value={value} index={"Charger Make And Model"}>
            <div className="filter_tab_vertical_body">
              <ChargerMakeAndModelFilter
                _allMakes={tempAllMakeModel}
                setFuntion={setTempAllMakeModel}
              />
            </div>
          </TabPanel>
          <TabPanel value={value} index={"Power type"}>
            <div className="filter_tab_vertical_body">
              <SingleTypeFilter
                _stateVariable={tempAllPowerType}
                setFuntion={setTempAllPowerType}
              />
            </div>
          </TabPanel>
          <TabPanel value={value} index={"Depot Type"}>
            <div className="filter_tab_vertical_body">
              <SingleTypeFilter
                _stateVariable={tempAllDepotType}
                setFuntion={setTempAllDepotType}
              />
            </div>
          </TabPanel>
          <TabPanel value={value} index={"Firmware Status"}>
            <div className="filter_tab_vertical_body">
              <SingleTypeFilter
                _stateVariable={tempAllFirmwareStatusFilter}
                setFuntion={setTempAllFirmwareStatusFilter}
              />
            </div>
          </TabPanel>
          <TabPanel value={value} index={"Connectivity Status"}>
            <div className="filter_tab_vertical_body">
              <SingleTypeFilter
                _stateVariable={tempAllConnectivityStatusFilter}
                setFuntion={setTempAllConnectivityStatusFilter}
              />
            </div>
          </TabPanel>
          <TabPanel value={value} index={"Config flags"}>
            <div className="filter_tab_vertical_body">
              <SingleTypeFilter
                _stateVariable={tempIsRFIDcapable}
                setFuntion={setTempIsRFIDcapable}
              />
              <div className="RFID_reconfigured">
                <div className="Content">
                  <p className="status">
                    Only show chargers which need to be reconfigured for RFID
                    access. Applicable for “Enabled” and “Capable” statuses.
                  </p>
                </div>
                <div className="RFID_switch">
                  <FormGroup>
                    <FormControlLabel
                      control={
                        <Switch
                          checked={tempIsReconfigureNeeded}
                          onChange={(e) => {
                            setTempIsReconfigureNeeded(
                              !tempIsReconfigureNeeded
                            );
                          }}
                          inputProps={{ "aria-label": "controlled" }}
                        />
                      }
                    />
                  </FormGroup>
                </div>
              </div>
            </div>
          </TabPanel>
          <TabPanel value={value} index={"Charging Mode"}>
            <div className="filter_tab_vertical_body">
              <SingleTypeFilter
                _stateVariable={tempChargingModeFilter}
                setFuntion={setTempChargingModeFilter}
              />
            </div>
          </TabPanel>
          <TabPanel value={value} index={"Country"}>
            <div className="filter_tab_vertical_body">
              <NestedFilterWithSearch
                _allData={tempAllCountryCode}
                setFuntion={setTempAllCountryCode}
                clearAll={clearAll}
              />
            </div>
          </TabPanel>
          <TabPanel value={value} index={"Commission Status"}>
            <div className="filter_tab_vertical_body">
              <SingleTypeFilter
                _stateVariable={tempAllCommissionStatus}
                setFuntion={setTempAllCommissionStatus}
              />
            </div>
          </TabPanel>
        </Box>
        <div className="filter_action_btn">
          <Controls.Button
            disabled={
              !(
                checkDepotSelected(tempAllAccounts) ||
                checkChargersSelected(tempAllMakeModel) ||
                checkSingleFilterSelected(tempAllPowerType) ||
                checkSingleFilterSelected(tempAllDepotType) ||
                checkSingleFilterSelected(tempAllFirmwareStatusFilter) ||
                checkSingleFilterSelected(tempAllConnectivityStatusFilter) ||
                checkSingleFilterSelected(tempChargingModeFilter) ||
                checkNestedFilterSelected(tempAllCountryCode) ||
                checkSingleFilterSelected(tempAllCommissionStatus) ||
                tempIsReconfigureNeeded
              )
            }
            text="Clear All"
            variant="outlined"
            onClick={() => clearAll()}
            className="btn_font_size"
          />
          <Controls.Button
            text="Apply"
            color="primary"
            onClick={() =>
              applyFilter(
                tempAllAccounts,
                tempAllMakeModel,
                tempAllPowerType,
                tempAllDepotType,
                tempAllFirmwareStatusFilter,
                tempAllConnectivityStatusFilter,
                tempIsRFIDcapable,
                tempChargingModeFilter,
                tempAllCountryCode,
                tempAllCommissionStatus,
                tempIsReconfigureNeeded
              )
            }
            className="btn_font_size"
          />
        </div>
      </div>
    </>
  );
};

export default NewChargerFirmwareFilter;
