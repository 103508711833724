export const CLLMDATA = () => [
  {
    time: "31 Dec, 11:59",
    currentValue: 0,
    currentUnit: "A",
    powerValue: 0,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 12:00",
    currentValue: 2.7,
    currentUnit: "A",
    powerValue: 0.58,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 12:00",
    currentValue: 2.7,
    currentUnit: "A",
    powerValue: 0.57,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 12:00",
    currentValue: 5,
    currentUnit: "A",
    powerValue: 1.06,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 12:00",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 12:01",
    currentValue: 2.7,
    currentUnit: "A",
    powerValue: 0.58,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 12:01",
    currentValue: 2.8,
    currentUnit: "A",
    powerValue: 0.6,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 12:01",
    currentValue: 6.8,
    currentUnit: "A",
    powerValue: 1.44,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 12:01",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 12:02",
    currentValue: 2.8,
    currentUnit: "A",
    powerValue: 0.6,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 12:02",
    currentValue: 2.8,
    currentUnit: "A",
    powerValue: 0.6,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 12:02",
    currentValue: 5,
    currentUnit: "A",
    powerValue: 1.06,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 12:02",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 12:03",
    currentValue: 2.7,
    currentUnit: "A",
    powerValue: 0.58,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 12:03",
    currentValue: 2.8,
    currentUnit: "A",
    powerValue: 0.6,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 12:03",
    currentValue: 1,
    currentUnit: "A",
    powerValue: 0.21,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 12:03",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 12:04",
    currentValue: 2.7,
    currentUnit: "A",
    powerValue: 0.58,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 12:04",
    currentValue: 2.8,
    currentUnit: "A",
    powerValue: 0.6,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 12:04",
    currentValue: 10.8,
    currentUnit: "A",
    powerValue: 2.29,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 12:04",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 12:05",
    currentValue: 7.8,
    currentUnit: "A",
    powerValue: 1.65,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 12:05",
    currentValue: 2.8,
    currentUnit: "A",
    powerValue: 0.6,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 12:05",
    currentValue: 1,
    currentUnit: "A",
    powerValue: 0.21,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 12:05",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 12:06",
    currentValue: 2.7,
    currentUnit: "A",
    powerValue: 0.58,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 12:06",
    currentValue: 2.8,
    currentUnit: "A",
    powerValue: 0.6,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 12:06",
    currentValue: 4.9,
    currentUnit: "A",
    powerValue: 1.04,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 12:06",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 12:07",
    currentValue: 2.8,
    currentUnit: "A",
    powerValue: 0.6,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 12:07",
    currentValue: 2.8,
    currentUnit: "A",
    powerValue: 0.6,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 12:07",
    currentValue: 6.8,
    currentUnit: "A",
    powerValue: 1.44,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 12:07",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 12:08",
    currentValue: 2.7,
    currentUnit: "A",
    powerValue: 0.58,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 12:08",
    currentValue: 2.7,
    currentUnit: "A",
    powerValue: 0.57,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 12:08",
    currentValue: 4.9,
    currentUnit: "A",
    powerValue: 1.04,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 12:08",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 12:09",
    currentValue: 2.7,
    currentUnit: "A",
    powerValue: 0.58,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 12:09",
    currentValue: 2.8,
    currentUnit: "A",
    powerValue: 0.6,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 12:09",
    currentValue: 1,
    currentUnit: "A",
    powerValue: 0.21,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 12:09",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 12:10",
    currentValue: 2.7,
    currentUnit: "A",
    powerValue: 0.58,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 12:10",
    currentValue: 2.7,
    currentUnit: "A",
    powerValue: 0.57,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 12:10",
    currentValue: 4.9,
    currentUnit: "A",
    powerValue: 1.04,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 12:10",
    currentValue: 57.1,
    currentUnit: "A",
    powerValue: 12.13,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 12:11",
    currentValue: 2.8,
    currentUnit: "A",
    powerValue: 0.6,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 12:11",
    currentValue: 2.8,
    currentUnit: "A",
    powerValue: 0.6,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 12:11",
    currentValue: 1,
    currentUnit: "A",
    powerValue: 0.21,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 12:11",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 12:12",
    currentValue: 2.7,
    currentUnit: "A",
    powerValue: 0.58,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 12:12",
    currentValue: 2.7,
    currentUnit: "A",
    powerValue: 0.57,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 12:12",
    currentValue: 46,
    currentUnit: "A",
    powerValue: 9.66,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 12:12",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 12:13",
    currentValue: 2.7,
    currentUnit: "A",
    powerValue: 0.58,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 12:13",
    currentValue: 2.8,
    currentUnit: "A",
    powerValue: 0.6,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 12:13",
    currentValue: 1,
    currentUnit: "A",
    powerValue: 0.21,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 12:13",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 12:14",
    currentValue: 7.8,
    currentUnit: "A",
    powerValue: 1.65,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 12:14",
    currentValue: 2.7,
    currentUnit: "A",
    powerValue: 0.57,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 12:14",
    currentValue: 5,
    currentUnit: "A",
    powerValue: 1.06,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 12:14",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 12:15",
    currentValue: 2.8,
    currentUnit: "A",
    powerValue: 0.6,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 12:15",
    currentValue: 2.8,
    currentUnit: "A",
    powerValue: 0.6,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 12:15",
    currentValue: 1,
    currentUnit: "A",
    powerValue: 0.21,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 12:15",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 12:16",
    currentValue: 2.8,
    currentUnit: "A",
    powerValue: 0.6,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 12:16",
    currentValue: 7.8,
    currentUnit: "A",
    powerValue: 1.65,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 12:16",
    currentValue: 5,
    currentUnit: "A",
    powerValue: 1.06,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 12:16",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 12:17",
    currentValue: 2.7,
    currentUnit: "A",
    powerValue: 0.58,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 12:17",
    currentValue: 2.7,
    currentUnit: "A",
    powerValue: 0.57,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 12:17",
    currentValue: 1,
    currentUnit: "A",
    powerValue: 0.21,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 12:17",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 12:18",
    currentValue: 2.7,
    currentUnit: "A",
    powerValue: 0.58,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 12:18",
    currentValue: 2.8,
    currentUnit: "A",
    powerValue: 0.6,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 12:18",
    currentValue: 7.1,
    currentUnit: "A",
    powerValue: 1.5,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 12:18",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 12:19",
    currentValue: 2.7,
    currentUnit: "A",
    powerValue: 0.58,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 12:19",
    currentValue: 7.8,
    currentUnit: "A",
    powerValue: 1.65,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 12:19",
    currentValue: 1,
    currentUnit: "A",
    powerValue: 0.21,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 12:19",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 12:20",
    currentValue: 2.7,
    currentUnit: "A",
    powerValue: 0.58,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 12:20",
    currentValue: 2.7,
    currentUnit: "A",
    powerValue: 0.57,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 12:20",
    currentValue: 1,
    currentUnit: "A",
    powerValue: 0.21,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 12:20",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 12:21",
    currentValue: 2.7,
    currentUnit: "A",
    powerValue: 0.58,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 12:21",
    currentValue: 2.7,
    currentUnit: "A",
    powerValue: 0.57,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 12:21",
    currentValue: 1.8,
    currentUnit: "A",
    powerValue: 0.38,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 12:21",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 12:22",
    currentValue: 2.7,
    currentUnit: "A",
    powerValue: 0.58,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 12:22",
    currentValue: 7.8,
    currentUnit: "A",
    powerValue: 1.65,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 12:22",
    currentValue: 1,
    currentUnit: "A",
    powerValue: 0.21,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 12:22",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 12:23",
    currentValue: 7.8,
    currentUnit: "A",
    powerValue: 1.65,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 12:23",
    currentValue: 2.7,
    currentUnit: "A",
    powerValue: 0.57,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 12:23",
    currentValue: 1,
    currentUnit: "A",
    powerValue: 0.21,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 12:23",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 12:24",
    currentValue: 2.7,
    currentUnit: "A",
    powerValue: 0.58,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 12:24",
    currentValue: 2.7,
    currentUnit: "A",
    powerValue: 0.57,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 12:24",
    currentValue: 1.7,
    currentUnit: "A",
    powerValue: 0.36,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 12:24",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 12:25",
    currentValue: 2.7,
    currentUnit: "A",
    powerValue: 0.58,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 12:25",
    currentValue: 7.8,
    currentUnit: "A",
    powerValue: 1.65,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 12:25",
    currentValue: 1,
    currentUnit: "A",
    powerValue: 0.21,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 12:25",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 12:26",
    currentValue: 2.7,
    currentUnit: "A",
    powerValue: 0.58,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 12:26",
    currentValue: 2.8,
    currentUnit: "A",
    powerValue: 0.59,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 12:26",
    currentValue: 1,
    currentUnit: "A",
    powerValue: 0.21,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 12:26",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 12:27",
    currentValue: 2.7,
    currentUnit: "A",
    powerValue: 0.58,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 12:27",
    currentValue: 2.8,
    currentUnit: "A",
    powerValue: 0.59,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 12:27",
    currentValue: 6.8,
    currentUnit: "A",
    powerValue: 1.44,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 12:27",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 12:28",
    currentValue: 2.7,
    currentUnit: "A",
    powerValue: 0.58,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 12:28",
    currentValue: 7.8,
    currentUnit: "A",
    powerValue: 3.33,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 12:28",
    currentValue: 1,
    currentUnit: "A",
    powerValue: 0.21,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 12:28",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 12:29",
    currentValue: 2.7,
    currentUnit: "A",
    powerValue: 0.58,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 12:29",
    currentValue: 2.8,
    currentUnit: "A",
    powerValue: 2.28,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 12:29",
    currentValue: 1,
    currentUnit: "A",
    powerValue: 0.21,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 12:29",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 12:30",
    currentValue: 7.8,
    currentUnit: "A",
    powerValue: 1.65,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 12:30",
    currentValue: 79.5,
    currentUnit: "A",
    powerValue: 16.3,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 12:30",
    currentValue: 6.8,
    currentUnit: "A",
    powerValue: 1.44,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 12:30",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 12:31",
    currentValue: 2.7,
    currentUnit: "A",
    powerValue: 0.57,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 12:31",
    currentValue: 79.5,
    currentUnit: "A",
    powerValue: 16.29,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 12:31",
    currentValue: 1,
    currentUnit: "A",
    powerValue: 0.21,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 12:31",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 12:32",
    currentValue: 2.7,
    currentUnit: "A",
    powerValue: 0.57,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 12:32",
    currentValue: 79.6,
    currentUnit: "A",
    powerValue: 16.32,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 12:32",
    currentValue: 1,
    currentUnit: "A",
    powerValue: 0.21,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 12:32",
    currentValue: 30.2,
    currentUnit: "A",
    powerValue: 6.42,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 12:33",
    currentValue: 2.7,
    currentUnit: "A",
    powerValue: 0.57,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 12:33",
    currentValue: 79.7,
    currentUnit: "A",
    powerValue: 16.32,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 12:33",
    currentValue: 6.8,
    currentUnit: "A",
    powerValue: 1.44,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 12:33",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 12:34",
    currentValue: 2.7,
    currentUnit: "A",
    powerValue: 0.57,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 12:34",
    currentValue: 79.7,
    currentUnit: "A",
    powerValue: 16.34,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 12:34",
    currentValue: 1,
    currentUnit: "A",
    powerValue: 0.21,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 12:34",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 12:35",
    currentValue: 2.7,
    currentUnit: "A",
    powerValue: 0.57,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 12:35",
    currentValue: 79.8,
    currentUnit: "A",
    powerValue: 16.34,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 12:35",
    currentValue: 1,
    currentUnit: "A",
    powerValue: 0.21,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 12:35",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 12:36",
    currentValue: 2.7,
    currentUnit: "A",
    powerValue: 0.57,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 12:36",
    currentValue: 79.9,
    currentUnit: "A",
    powerValue: 16.36,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 12:36",
    currentValue: 6.8,
    currentUnit: "A",
    powerValue: 1.44,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 12:36",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 12:37",
    currentValue: 7.8,
    currentUnit: "A",
    powerValue: 1.65,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 12:37",
    currentValue: 80,
    currentUnit: "A",
    powerValue: 16.37,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 12:37",
    currentValue: 1,
    currentUnit: "A",
    powerValue: 0.21,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 12:37",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 12:38",
    currentValue: 2.7,
    currentUnit: "A",
    powerValue: 0.57,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 12:38",
    currentValue: 80.1,
    currentUnit: "A",
    powerValue: 16.39,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 12:38",
    currentValue: 1,
    currentUnit: "A",
    powerValue: 0.21,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 12:38",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 12:39",
    currentValue: 7.8,
    currentUnit: "A",
    powerValue: 1.65,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 12:39",
    currentValue: 80.2,
    currentUnit: "A",
    powerValue: 16.41,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 12:39",
    currentValue: 1,
    currentUnit: "A",
    powerValue: 0.21,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 12:39",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 12:40",
    currentValue: 2.7,
    currentUnit: "A",
    powerValue: 0.57,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 12:40",
    currentValue: 80.1,
    currentUnit: "A",
    powerValue: 16.4,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 12:40",
    currentValue: 1,
    currentUnit: "A",
    powerValue: 0.21,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 12:40",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 12:41",
    currentValue: 2.8,
    currentUnit: "A",
    powerValue: 0.59,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 12:41",
    currentValue: 80.2,
    currentUnit: "A",
    powerValue: 16.41,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 12:41",
    currentValue: 1,
    currentUnit: "A",
    powerValue: 0.21,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 12:41",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 12:42",
    currentValue: 2.7,
    currentUnit: "A",
    powerValue: 0.57,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 12:42",
    currentValue: 78.9,
    currentUnit: "A",
    powerValue: 16.16,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 12:42",
    currentValue: 1,
    currentUnit: "A",
    powerValue: 0.21,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 12:42",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 12:43",
    currentValue: 2.7,
    currentUnit: "A",
    powerValue: 0.57,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 12:43",
    currentValue: 64,
    currentUnit: "A",
    powerValue: 13.19,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 12:43",
    currentValue: 1,
    currentUnit: "A",
    powerValue: 0.21,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 12:43",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 12:44",
    currentValue: 2.7,
    currentUnit: "A",
    powerValue: 0.57,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 12:44",
    currentValue: 53.8,
    currentUnit: "A",
    powerValue: 11.15,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 12:44",
    currentValue: 1,
    currentUnit: "A",
    powerValue: 0.21,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 12:44",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 12:45",
    currentValue: 2.7,
    currentUnit: "A",
    powerValue: 0.57,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 12:45",
    currentValue: 47,
    currentUnit: "A",
    powerValue: 9.75,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 12:45",
    currentValue: 3.6,
    currentUnit: "A",
    powerValue: 0.76,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 12:45",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 12:46",
    currentValue: 7.8,
    currentUnit: "A",
    powerValue: 1.65,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 12:46",
    currentValue: 40.9,
    currentUnit: "A",
    powerValue: 8.51,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 12:46",
    currentValue: 1,
    currentUnit: "A",
    powerValue: 0.21,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 12:46",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 12:47",
    currentValue: 2.7,
    currentUnit: "A",
    powerValue: 0.57,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 12:47",
    currentValue: 35.4,
    currentUnit: "A",
    powerValue: 7.39,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 12:47",
    currentValue: 3.8,
    currentUnit: "A",
    powerValue: 0.8,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 12:47",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 12:48",
    currentValue: 7.8,
    currentUnit: "A",
    powerValue: 1.65,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 12:48",
    currentValue: 37.5,
    currentUnit: "A",
    powerValue: 7.84,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 12:48",
    currentValue: 1,
    currentUnit: "A",
    powerValue: 0.21,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 12:48",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 12:49",
    currentValue: 2.7,
    currentUnit: "A",
    powerValue: 0.57,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 12:49",
    currentValue: 29.5,
    currentUnit: "A",
    powerValue: 6.17,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 12:49",
    currentValue: 3.1,
    currentUnit: "A",
    powerValue: 0.65,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 12:49",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 12:50",
    currentValue: 2.7,
    currentUnit: "A",
    powerValue: 0.57,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 12:50",
    currentValue: 25.9,
    currentUnit: "A",
    powerValue: 5.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 12:50",
    currentValue: 1,
    currentUnit: "A",
    powerValue: 0.21,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 12:50",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 12:51",
    currentValue: 2.7,
    currentUnit: "A",
    powerValue: 0.57,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 12:51",
    currentValue: 29.2,
    currentUnit: "A",
    powerValue: 6.13,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 12:51",
    currentValue: 1.8,
    currentUnit: "A",
    powerValue: 0.38,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 12:51",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 12:52",
    currentValue: 2.7,
    currentUnit: "A",
    powerValue: 0.57,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 12:52",
    currentValue: 80.5,
    currentUnit: "A",
    powerValue: 16.46,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 12:52",
    currentValue: 1,
    currentUnit: "A",
    powerValue: 0.21,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 12:52",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 12:53",
    currentValue: 2.7,
    currentUnit: "A",
    powerValue: 0.57,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 12:53",
    currentValue: 2.7,
    currentUnit: "A",
    powerValue: 0.57,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 12:53",
    currentValue: 1.8,
    currentUnit: "A",
    powerValue: 0.38,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 12:53",
    currentValue: 30,
    currentUnit: "A",
    powerValue: 6.37,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 12:54",
    currentValue: 2.7,
    currentUnit: "A",
    powerValue: 0.57,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 12:54",
    currentValue: 3,
    currentUnit: "A",
    powerValue: 0.64,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 12:54",
    currentValue: 1,
    currentUnit: "A",
    powerValue: 0.21,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 12:54",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 12:55",
    currentValue: 7.2,
    currentUnit: "A",
    powerValue: 1.52,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 12:55",
    currentValue: 2.7,
    currentUnit: "A",
    powerValue: 0.57,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 12:55",
    currentValue: 1.8,
    currentUnit: "A",
    powerValue: 0.38,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 12:55",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 12:56",
    currentValue: 2.7,
    currentUnit: "A",
    powerValue: 0.57,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 12:56",
    currentValue: 2.7,
    currentUnit: "A",
    powerValue: 0.57,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 12:56",
    currentValue: 1,
    currentUnit: "A",
    powerValue: 0.21,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 12:56",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 12:57",
    currentValue: 7.8,
    currentUnit: "A",
    powerValue: 1.65,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 12:57",
    currentValue: 2.7,
    currentUnit: "A",
    powerValue: 0.57,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 12:57",
    currentValue: 1.8,
    currentUnit: "A",
    powerValue: 0.38,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 12:57",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 12:58",
    currentValue: 2.7,
    currentUnit: "A",
    powerValue: 0.57,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 12:58",
    currentValue: 2.7,
    currentUnit: "A",
    powerValue: 0.57,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 12:58",
    currentValue: 1,
    currentUnit: "A",
    powerValue: 0.21,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 12:58",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.42,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 12:59",
    currentValue: 2.7,
    currentUnit: "A",
    powerValue: 0.57,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 12:59",
    currentValue: 2.7,
    currentUnit: "A",
    powerValue: 0.57,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 12:59",
    currentValue: 5,
    currentUnit: "A",
    powerValue: 1.05,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 12:59",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.42,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 01:00",
    currentValue: 2.7,
    currentUnit: "A",
    powerValue: 0.57,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 01:00",
    currentValue: 2.7,
    currentUnit: "A",
    powerValue: 0.57,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 01:00",
    currentValue: 1,
    currentUnit: "A",
    powerValue: 0.21,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 01:00",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 01:01",
    currentValue: 2.7,
    currentUnit: "A",
    powerValue: 0.57,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 01:01",
    currentValue: 2.7,
    currentUnit: "A",
    powerValue: 0.57,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 01:01",
    currentValue: 5,
    currentUnit: "A",
    powerValue: 1.05,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 01:01",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.42,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 01:02",
    currentValue: 2.7,
    currentUnit: "A",
    powerValue: 0.57,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 01:02",
    currentValue: 2.7,
    currentUnit: "A",
    powerValue: 0.57,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 01:02",
    currentValue: 1,
    currentUnit: "A",
    powerValue: 0.21,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 01:02",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.42,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 01:03",
    currentValue: 2.7,
    currentUnit: "A",
    powerValue: 0.57,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 01:03",
    currentValue: 2.7,
    currentUnit: "A",
    powerValue: 0.57,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 01:03",
    currentValue: 5,
    currentUnit: "A",
    powerValue: 1.05,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 01:03",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.42,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 01:04",
    currentValue: 2.7,
    currentUnit: "A",
    powerValue: 0.57,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 01:04",
    currentValue: 2.7,
    currentUnit: "A",
    powerValue: 0.57,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 01:04",
    currentValue: 1,
    currentUnit: "A",
    powerValue: 0.21,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 01:04",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.42,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 01:05",
    currentValue: 2.7,
    currentUnit: "A",
    powerValue: 0.57,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 01:05",
    currentValue: 2.7,
    currentUnit: "A",
    powerValue: 0.57,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 01:05",
    currentValue: 5,
    currentUnit: "A",
    powerValue: 1.05,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 01:05",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.42,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 01:06",
    currentValue: 7.8,
    currentUnit: "A",
    powerValue: 1.64,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 01:06",
    currentValue: 2.7,
    currentUnit: "A",
    powerValue: 0.57,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 01:06",
    currentValue: 1,
    currentUnit: "A",
    powerValue: 0.21,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 01:06",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.42,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 01:07",
    currentValue: 2.7,
    currentUnit: "A",
    powerValue: 0.57,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 01:07",
    currentValue: 2.7,
    currentUnit: "A",
    powerValue: 0.57,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 01:07",
    currentValue: 5,
    currentUnit: "A",
    powerValue: 1.05,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 01:07",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.42,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 01:08",
    currentValue: 2.8,
    currentUnit: "A",
    powerValue: 0.59,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 01:08",
    currentValue: 2.8,
    currentUnit: "A",
    powerValue: 0.59,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 01:08",
    currentValue: 1,
    currentUnit: "A",
    powerValue: 0.21,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 01:08",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.42,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 01:09",
    currentValue: 2.7,
    currentUnit: "A",
    powerValue: 0.57,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 01:09",
    currentValue: 2.7,
    currentUnit: "A",
    powerValue: 0.57,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 01:09",
    currentValue: 5,
    currentUnit: "A",
    powerValue: 1.05,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 01:09",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.42,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 01:10",
    currentValue: 2.7,
    currentUnit: "A",
    powerValue: 0.57,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 01:10",
    currentValue: 2.7,
    currentUnit: "A",
    powerValue: 0.57,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 01:10",
    currentValue: 1,
    currentUnit: "A",
    powerValue: 0.21,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 01:10",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.42,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 01:11",
    currentValue: 2.7,
    currentUnit: "A",
    powerValue: 0.57,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 01:11",
    currentValue: 2.8,
    currentUnit: "A",
    powerValue: 0.59,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 01:11",
    currentValue: 48.4,
    currentUnit: "A",
    powerValue: 10.1,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 01:11",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.42,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 01:12",
    currentValue: 2.7,
    currentUnit: "A",
    powerValue: 0.57,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 01:12",
    currentValue: 2.7,
    currentUnit: "A",
    powerValue: 0.57,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 01:12",
    currentValue: 1,
    currentUnit: "A",
    powerValue: 0.21,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 01:12",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.42,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 01:13",
    currentValue: 2.7,
    currentUnit: "A",
    powerValue: 0.57,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 01:13",
    currentValue: 2.7,
    currentUnit: "A",
    powerValue: 0.57,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 01:13",
    currentValue: 5,
    currentUnit: "A",
    powerValue: 1.05,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 01:13",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.42,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 01:14",
    currentValue: 2.7,
    currentUnit: "A",
    powerValue: 0.57,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 01:14",
    currentValue: 2.7,
    currentUnit: "A",
    powerValue: 0.57,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 01:14",
    currentValue: 1,
    currentUnit: "A",
    powerValue: 0.21,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 01:14",
    currentValue: 29.3,
    currentUnit: "A",
    powerValue: 6.21,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 01:15",
    currentValue: 7.8,
    currentUnit: "A",
    powerValue: 1.64,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 01:15",
    currentValue: 2.7,
    currentUnit: "A",
    powerValue: 0.57,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 01:15",
    currentValue: 5,
    currentUnit: "A",
    powerValue: 1.05,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 01:15",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.42,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 01:16",
    currentValue: 2.7,
    currentUnit: "A",
    powerValue: 0.57,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 01:16",
    currentValue: 2.7,
    currentUnit: "A",
    powerValue: 2.26,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 01:16",
    currentValue: 1,
    currentUnit: "A",
    powerValue: 0.21,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 01:16",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.42,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 01:17",
    currentValue: 2.8,
    currentUnit: "A",
    powerValue: 0.59,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 01:17",
    currentValue: 2.7,
    currentUnit: "A",
    powerValue: 0.57,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 01:17",
    currentValue: 1.8,
    currentUnit: "A",
    powerValue: 0.38,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 01:17",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.42,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 01:18",
    currentValue: 2.7,
    currentUnit: "A",
    powerValue: 0.57,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 01:18",
    currentValue: 2.7,
    currentUnit: "A",
    powerValue: 0.57,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 01:18",
    currentValue: 1,
    currentUnit: "A",
    powerValue: 0.21,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 01:18",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.42,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 01:19",
    currentValue: 2.7,
    currentUnit: "A",
    powerValue: 0.57,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 01:19",
    currentValue: 2.7,
    currentUnit: "A",
    powerValue: 0.57,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 01:19",
    currentValue: 1.7,
    currentUnit: "A",
    powerValue: 0.36,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 01:19",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.42,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 01:20",
    currentValue: 2.7,
    currentUnit: "A",
    powerValue: 0.57,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 01:20",
    currentValue: 7.8,
    currentUnit: "A",
    powerValue: 1.64,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 01:20",
    currentValue: 1,
    currentUnit: "A",
    powerValue: 0.21,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 01:20",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.42,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 01:21",
    currentValue: 2.7,
    currentUnit: "A",
    powerValue: 0.57,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 01:21",
    currentValue: 2.7,
    currentUnit: "A",
    powerValue: 0.57,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 01:21",
    currentValue: 5.2,
    currentUnit: "A",
    powerValue: 1.09,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 01:21",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.42,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 01:22",
    currentValue: 7.8,
    currentUnit: "A",
    powerValue: 1.64,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 01:22",
    currentValue: 2.7,
    currentUnit: "A",
    powerValue: 0.57,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 01:22",
    currentValue: 1,
    currentUnit: "A",
    powerValue: 0.21,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 01:22",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.42,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 01:23",
    currentValue: 2.7,
    currentUnit: "A",
    powerValue: 0.57,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 01:23",
    currentValue: 7.8,
    currentUnit: "A",
    powerValue: 3.33,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 01:23",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.42,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 01:23",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.42,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 01:24",
    currentValue: 7.8,
    currentUnit: "A",
    powerValue: 1.64,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 01:24",
    currentValue: 2.7,
    currentUnit: "A",
    powerValue: 0.57,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 01:24",
    currentValue: 1,
    currentUnit: "A",
    powerValue: 0.21,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 01:24",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.42,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 01:25",
    currentValue: 2.7,
    currentUnit: "A",
    powerValue: 0.57,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 01:25",
    currentValue: 2.7,
    currentUnit: "A",
    powerValue: 0.57,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 01:25",
    currentValue: 1.7,
    currentUnit: "A",
    powerValue: 0.36,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 01:25",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.42,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 01:26",
    currentValue: 2.7,
    currentUnit: "A",
    powerValue: 0.57,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 01:26",
    currentValue: 7.8,
    currentUnit: "A",
    powerValue: 1.64,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 01:26",
    currentValue: 1,
    currentUnit: "A",
    powerValue: 0.21,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 01:26",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.42,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 01:27",
    currentValue: 2.7,
    currentUnit: "A",
    powerValue: 0.57,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 01:27",
    currentValue: 2.7,
    currentUnit: "A",
    powerValue: 0.57,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 01:27",
    currentValue: 1.8,
    currentUnit: "A",
    powerValue: 0.38,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 01:27",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.42,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 01:28",
    currentValue: 2.7,
    currentUnit: "A",
    powerValue: 0.57,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 01:28",
    currentValue: 2.7,
    currentUnit: "A",
    powerValue: 0.57,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 01:28",
    currentValue: 1,
    currentUnit: "A",
    powerValue: 0.21,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 01:28",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.42,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 01:29",
    currentValue: 2.7,
    currentUnit: "A",
    powerValue: 0.57,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 01:29",
    currentValue: 7.8,
    currentUnit: "A",
    powerValue: 1.64,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 01:29",
    currentValue: 1.8,
    currentUnit: "A",
    powerValue: 0.38,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 01:29",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.42,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 01:30",
    currentValue: 2.7,
    currentUnit: "A",
    powerValue: 0.57,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 01:30",
    currentValue: 2.7,
    currentUnit: "A",
    powerValue: 0.57,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 01:30",
    currentValue: 1,
    currentUnit: "A",
    powerValue: 0.21,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 01:30",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.42,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 01:31",
    currentValue: 7.8,
    currentUnit: "A",
    powerValue: 1.64,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 01:31",
    currentValue: 2.7,
    currentUnit: "A",
    powerValue: 0.57,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 01:31",
    currentValue: 5,
    currentUnit: "A",
    powerValue: 1.05,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 01:31",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.42,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 01:32",
    currentValue: 2.7,
    currentUnit: "A",
    powerValue: 0.57,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 01:32",
    currentValue: 7.8,
    currentUnit: "A",
    powerValue: 1.64,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 01:32",
    currentValue: 1,
    currentUnit: "A",
    powerValue: 0.21,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 01:32",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.42,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 01:33",
    currentValue: 3.1,
    currentUnit: "A",
    powerValue: 0.66,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 01:33",
    currentValue: 2.8,
    currentUnit: "A",
    powerValue: 0.59,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 01:33",
    currentValue: 1.8,
    currentUnit: "A",
    powerValue: 0.38,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 01:33",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.42,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 01:34",
    currentValue: 2.7,
    currentUnit: "A",
    powerValue: 0.57,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 01:34",
    currentValue: 2.7,
    currentUnit: "A",
    powerValue: 0.57,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 01:34",
    currentValue: 1,
    currentUnit: "A",
    powerValue: 0.21,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 01:34",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.42,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 01:35",
    currentValue: 2.7,
    currentUnit: "A",
    powerValue: 0.57,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 01:35",
    currentValue: 2.7,
    currentUnit: "A",
    powerValue: 0.57,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 01:35",
    currentValue: 1.7,
    currentUnit: "A",
    powerValue: 0.36,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 01:35",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.42,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 01:36",
    currentValue: 2.7,
    currentUnit: "A",
    powerValue: 0.57,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 01:36",
    currentValue: 2.7,
    currentUnit: "A",
    powerValue: 0.57,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 01:36",
    currentValue: 1,
    currentUnit: "A",
    powerValue: 0.21,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 01:36",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.42,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 01:37",
    currentValue: 2.7,
    currentUnit: "A",
    powerValue: 0.57,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 01:37",
    currentValue: 2.7,
    currentUnit: "A",
    powerValue: 0.57,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 01:37",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.42,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 01:37",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.42,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 01:38",
    currentValue: 2.7,
    currentUnit: "A",
    powerValue: 0.57,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 01:38",
    currentValue: 2.7,
    currentUnit: "A",
    powerValue: 0.57,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 01:38",
    currentValue: 1,
    currentUnit: "A",
    powerValue: 0.21,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 01:38",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.42,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 01:39",
    currentValue: 2.7,
    currentUnit: "A",
    powerValue: 0.57,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 01:39",
    currentValue: 2.7,
    currentUnit: "A",
    powerValue: 0.57,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 01:39",
    currentValue: 1,
    currentUnit: "A",
    powerValue: 0.21,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 01:39",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.42,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 01:40",
    currentValue: 7.8,
    currentUnit: "A",
    powerValue: 1.64,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 01:40",
    currentValue: 2.7,
    currentUnit: "A",
    powerValue: 0.57,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 01:40",
    currentValue: 1,
    currentUnit: "A",
    powerValue: 0.21,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 01:40",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.42,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 01:41",
    currentValue: 2.7,
    currentUnit: "A",
    powerValue: 0.57,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 01:41",
    currentValue: 2.7,
    currentUnit: "A",
    powerValue: 0.57,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 01:41",
    currentValue: 1.8,
    currentUnit: "A",
    powerValue: 0.38,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 01:41",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.42,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 01:42",
    currentValue: 2.7,
    currentUnit: "A",
    powerValue: 0.57,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 01:42",
    currentValue: 2.7,
    currentUnit: "A",
    powerValue: 0.57,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 01:42",
    currentValue: 1,
    currentUnit: "A",
    powerValue: 0.21,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 01:42",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.42,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 01:43",
    currentValue: 2.7,
    currentUnit: "A",
    powerValue: 0.57,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 01:43",
    currentValue: 2.7,
    currentUnit: "A",
    powerValue: 0.57,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 01:43",
    currentValue: 1,
    currentUnit: "A",
    powerValue: 0.21,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 01:43",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.42,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 01:44",
    currentValue: 2.7,
    currentUnit: "A",
    powerValue: 0.57,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 01:44",
    currentValue: 2.7,
    currentUnit: "A",
    powerValue: 0.57,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 01:44",
    currentValue: 1.8,
    currentUnit: "A",
    powerValue: 0.38,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 01:44",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.42,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 01:45",
    currentValue: 2.7,
    currentUnit: "A",
    powerValue: 0.57,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 01:45",
    currentValue: 2.7,
    currentUnit: "A",
    powerValue: 2.26,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 01:45",
    currentValue: 1,
    currentUnit: "A",
    powerValue: 0.21,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 01:45",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.42,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 01:46",
    currentValue: 2.7,
    currentUnit: "A",
    powerValue: 0.57,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 01:46",
    currentValue: 2.8,
    currentUnit: "A",
    powerValue: 0.59,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 01:46",
    currentValue: 1,
    currentUnit: "A",
    powerValue: 0.21,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 01:46",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.42,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 01:47",
    currentValue: 7.8,
    currentUnit: "A",
    powerValue: 1.64,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 01:47",
    currentValue: 2.8,
    currentUnit: "A",
    powerValue: 0.59,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 01:47",
    currentValue: 6.8,
    currentUnit: "A",
    powerValue: 1.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 01:47",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.42,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 01:48",
    currentValue: 2.7,
    currentUnit: "A",
    powerValue: 0.57,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 01:48",
    currentValue: 2.7,
    currentUnit: "A",
    powerValue: 0.57,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 01:48",
    currentValue: 1,
    currentUnit: "A",
    powerValue: 0.21,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 01:48",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.42,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 01:49",
    currentValue: 7.8,
    currentUnit: "A",
    powerValue: 1.64,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 01:49",
    currentValue: 2.7,
    currentUnit: "A",
    powerValue: 0.57,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 01:49",
    currentValue: 1,
    currentUnit: "A",
    powerValue: 0.21,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 01:49",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.42,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 01:50",
    currentValue: 2.7,
    currentUnit: "A",
    powerValue: 0.57,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 01:50",
    currentValue: 2.7,
    currentUnit: "A",
    powerValue: 0.57,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 01:50",
    currentValue: 6.8,
    currentUnit: "A",
    powerValue: 1.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 01:50",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.42,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 01:51",
    currentValue: 2.7,
    currentUnit: "A",
    powerValue: 0.57,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 01:51",
    currentValue: 2.8,
    currentUnit: "A",
    powerValue: 0.59,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 01:51",
    currentValue: 1.8,
    currentUnit: "A",
    powerValue: 0.38,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 01:51",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.42,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 01:52",
    currentValue: 2.7,
    currentUnit: "A",
    powerValue: 0.57,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 01:52",
    currentValue: 2.8,
    currentUnit: "A",
    powerValue: 0.59,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 01:52",
    currentValue: 1,
    currentUnit: "A",
    powerValue: 0.21,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 01:52",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.42,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 01:53",
    currentValue: 2.7,
    currentUnit: "A",
    powerValue: 0.57,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 01:53",
    currentValue: 2.7,
    currentUnit: "A",
    powerValue: 0.57,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 01:53",
    currentValue: 6.8,
    currentUnit: "A",
    powerValue: 1.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 01:53",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.42,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 01:54",
    currentValue: 2.7,
    currentUnit: "A",
    powerValue: 0.57,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 01:54",
    currentValue: 2.7,
    currentUnit: "A",
    powerValue: 0.57,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 01:54",
    currentValue: 1,
    currentUnit: "A",
    powerValue: 0.21,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 01:54",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.42,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 01:55",
    currentValue: 2.7,
    currentUnit: "A",
    powerValue: 0.57,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 01:55",
    currentValue: 2.7,
    currentUnit: "A",
    powerValue: 0.57,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 01:55",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.42,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 01:55",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.42,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 01:56",
    currentValue: 7.8,
    currentUnit: "A",
    powerValue: 1.64,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 01:56",
    currentValue: 2.8,
    currentUnit: "A",
    powerValue: 0.59,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 01:56",
    currentValue: 6.8,
    currentUnit: "A",
    powerValue: 1.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 01:56",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.42,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 01:57",
    currentValue: 2.7,
    currentUnit: "A",
    powerValue: 0.57,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 01:57",
    currentValue: 2.8,
    currentUnit: "A",
    powerValue: 0.59,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 01:57",
    currentValue: 1.8,
    currentUnit: "A",
    powerValue: 0.38,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 01:57",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.42,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 01:58",
    currentValue: 2.7,
    currentUnit: "A",
    powerValue: 0.57,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 01:58",
    currentValue: 7.8,
    currentUnit: "A",
    powerValue: 1.64,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 01:58",
    currentValue: 1,
    currentUnit: "A",
    powerValue: 0.21,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 01:58",
    currentValue: 68.5,
    currentUnit: "A",
    powerValue: 14.31,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 01:59",
    currentValue: 2.7,
    currentUnit: "A",
    powerValue: 0.57,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 01:59",
    currentValue: 2.7,
    currentUnit: "A",
    powerValue: 0.57,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 01:59",
    currentValue: 7.6,
    currentUnit: "A",
    powerValue: 1.6,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 01:59",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.42,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 02:00",
    currentValue: 2.8,
    currentUnit: "A",
    powerValue: 0.59,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 02:00",
    currentValue: 2.8,
    currentUnit: "A",
    powerValue: 0.59,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 02:00",
    currentValue: 1,
    currentUnit: "A",
    powerValue: 0.21,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 02:00",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.42,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 02:01",
    currentValue: 2.7,
    currentUnit: "A",
    powerValue: 0.57,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 02:01",
    currentValue: 7.8,
    currentUnit: "A",
    powerValue: 1.64,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 02:01",
    currentValue: 1.7,
    currentUnit: "A",
    powerValue: 0.36,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 02:01",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.42,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 02:02",
    currentValue: 2.7,
    currentUnit: "A",
    powerValue: 0.57,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 02:02",
    currentValue: 2.8,
    currentUnit: "A",
    powerValue: 0.59,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 02:02",
    currentValue: 6.8,
    currentUnit: "A",
    powerValue: 1.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 02:02",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.42,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 02:03",
    currentValue: 7.8,
    currentUnit: "A",
    powerValue: 1.64,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 02:03",
    currentValue: 2.7,
    currentUnit: "A",
    powerValue: 0.57,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 02:03",
    currentValue: 4.5,
    currentUnit: "A",
    powerValue: 0.95,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 02:03",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.42,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 02:04",
    currentValue: 2.7,
    currentUnit: "A",
    powerValue: 0.57,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 02:04",
    currentValue: 7.8,
    currentUnit: "A",
    powerValue: 1.64,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 02:04",
    currentValue: 1,
    currentUnit: "A",
    powerValue: 0.21,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 02:04",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 02:05",
    currentValue: 2.7,
    currentUnit: "A",
    powerValue: 0.57,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 02:05",
    currentValue: 2.8,
    currentUnit: "A",
    powerValue: 0.59,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 02:05",
    currentValue: 53.1,
    currentUnit: "A",
    powerValue: 11.1,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 02:05",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.42,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 02:06",
    currentValue: 2.7,
    currentUnit: "A",
    powerValue: 0.57,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 02:06",
    currentValue: 2.8,
    currentUnit: "A",
    powerValue: 0.59,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 02:06",
    currentValue: 1,
    currentUnit: "A",
    powerValue: 0.21,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 02:06",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.42,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 02:07",
    currentValue: 2.7,
    currentUnit: "A",
    powerValue: 0.57,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 02:07",
    currentValue: 2.7,
    currentUnit: "A",
    powerValue: 0.57,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 02:07",
    currentValue: 5,
    currentUnit: "A",
    powerValue: 1.05,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 02:07",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 02:08",
    currentValue: 2.7,
    currentUnit: "A",
    powerValue: 0.57,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 02:08",
    currentValue: 2.7,
    currentUnit: "A",
    powerValue: 0.57,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 02:08",
    currentValue: 6.8,
    currentUnit: "A",
    powerValue: 1.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 02:08",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 02:09",
    currentValue: 2.7,
    currentUnit: "A",
    powerValue: 0.57,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 02:09",
    currentValue: 2.8,
    currentUnit: "A",
    powerValue: 0.59,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 02:09",
    currentValue: 4.9,
    currentUnit: "A",
    powerValue: 1.03,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 02:09",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.42,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 02:10",
    currentValue: 2.7,
    currentUnit: "A",
    powerValue: 0.57,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 02:10",
    currentValue: 2.7,
    currentUnit: "A",
    powerValue: 0.57,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 02:10",
    currentValue: 1,
    currentUnit: "A",
    powerValue: 0.21,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 02:10",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.42,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 02:11",
    currentValue: 2.7,
    currentUnit: "A",
    powerValue: 0.57,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 02:11",
    currentValue: 2.7,
    currentUnit: "A",
    powerValue: 0.57,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 02:11",
    currentValue: 10.8,
    currentUnit: "A",
    powerValue: 2.28,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 02:11",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.42,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 02:12",
    currentValue: 7.8,
    currentUnit: "A",
    powerValue: 1.64,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 02:12",
    currentValue: 2.7,
    currentUnit: "A",
    powerValue: 0.57,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 02:12",
    currentValue: 1,
    currentUnit: "A",
    powerValue: 0.21,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 02:12",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.42,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 02:13",
    currentValue: 2.8,
    currentUnit: "A",
    powerValue: 0.59,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 02:13",
    currentValue: 6.8,
    currentUnit: "A",
    powerValue: 1.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 02:13",
    currentValue: 5,
    currentUnit: "A",
    powerValue: 1.05,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 02:13",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.42,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 02:14",
    currentValue: 2.8,
    currentUnit: "A",
    powerValue: 0.59,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 02:14",
    currentValue: 2.8,
    currentUnit: "A",
    powerValue: 0.59,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 02:14",
    currentValue: 6.8,
    currentUnit: "A",
    powerValue: 1.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 02:14",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.42,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 02:15",
    currentValue: 2.7,
    currentUnit: "A",
    powerValue: 0.57,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 02:15",
    currentValue: 2.8,
    currentUnit: "A",
    powerValue: 0.59,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 02:15",
    currentValue: 5,
    currentUnit: "A",
    powerValue: 1.05,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 02:15",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.42,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 02:16",
    currentValue: 2.7,
    currentUnit: "A",
    powerValue: 0.57,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 02:16",
    currentValue: 2.7,
    currentUnit: "A",
    powerValue: 0.57,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 02:16",
    currentValue: 1,
    currentUnit: "A",
    powerValue: 0.21,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 02:16",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.42,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 02:17",
    currentValue: 2.7,
    currentUnit: "A",
    powerValue: 0.57,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 02:17",
    currentValue: 2.7,
    currentUnit: "A",
    powerValue: 0.57,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 02:17",
    currentValue: 10.8,
    currentUnit: "A",
    powerValue: 2.28,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 02:17",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.42,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 02:18",
    currentValue: 2.7,
    currentUnit: "A",
    powerValue: 0.57,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 02:18",
    currentValue: 2.8,
    currentUnit: "A",
    powerValue: 0.59,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 02:18",
    currentValue: 1,
    currentUnit: "A",
    powerValue: 0.21,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 02:18",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 02:19",
    currentValue: 2.7,
    currentUnit: "A",
    powerValue: 0.57,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 02:19",
    currentValue: 2.7,
    currentUnit: "A",
    powerValue: 0.57,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 02:19",
    currentValue: 5,
    currentUnit: "A",
    powerValue: 1.05,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 02:19",
    currentValue: 64.9,
    currentUnit: "A",
    powerValue: 13.58,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 02:20",
    currentValue: 2.7,
    currentUnit: "A",
    powerValue: 0.57,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 02:20",
    currentValue: 2.7,
    currentUnit: "A",
    powerValue: 0.57,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 02:20",
    currentValue: 6.8,
    currentUnit: "A",
    powerValue: 1.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 02:20",
    currentValue: 3,
    currentUnit: "A",
    powerValue: 0.64,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 02:21",
    currentValue: 7.8,
    currentUnit: "A",
    powerValue: 1.64,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 02:21",
    currentValue: 2.8,
    currentUnit: "A",
    powerValue: 0.59,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 02:21",
    currentValue: 5,
    currentUnit: "A",
    powerValue: 1.05,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 02:21",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 02:22",
    currentValue: 2.7,
    currentUnit: "A",
    powerValue: 0.57,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 02:22",
    currentValue: 2.7,
    currentUnit: "A",
    powerValue: 0.57,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 02:22",
    currentValue: 1,
    currentUnit: "A",
    powerValue: 0.21,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 02:22",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.42,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 02:23",
    currentValue: 2.7,
    currentUnit: "A",
    powerValue: 0.57,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 02:23",
    currentValue: 2.8,
    currentUnit: "A",
    powerValue: 0.59,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 02:23",
    currentValue: 10.8,
    currentUnit: "A",
    powerValue: 2.28,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 02:23",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.42,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 02:24",
    currentValue: 2.7,
    currentUnit: "A",
    powerValue: 0.57,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 02:24",
    currentValue: 2.8,
    currentUnit: "A",
    powerValue: 0.59,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 02:24",
    currentValue: 1,
    currentUnit: "A",
    powerValue: 0.21,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 02:24",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 02:25",
    currentValue: 2.7,
    currentUnit: "A",
    powerValue: 0.57,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 02:25",
    currentValue: 2.7,
    currentUnit: "A",
    powerValue: 0.57,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 02:25",
    currentValue: 5,
    currentUnit: "A",
    powerValue: 1.05,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 02:25",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 02:26",
    currentValue: 2.7,
    currentUnit: "A",
    powerValue: 0.57,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 02:26",
    currentValue: 2.7,
    currentUnit: "A",
    powerValue: 0.57,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 02:26",
    currentValue: 6.8,
    currentUnit: "A",
    powerValue: 1.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 02:26",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 02:27",
    currentValue: 2.7,
    currentUnit: "A",
    powerValue: 0.57,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 02:27",
    currentValue: 7.8,
    currentUnit: "A",
    powerValue: 1.64,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 02:27",
    currentValue: 5,
    currentUnit: "A",
    powerValue: 1.05,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 02:27",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 02:28",
    currentValue: 7.8,
    currentUnit: "A",
    powerValue: 1.65,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 02:28",
    currentValue: 2.8,
    currentUnit: "A",
    powerValue: 0.59,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 02:28",
    currentValue: 1,
    currentUnit: "A",
    powerValue: 0.21,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 02:28",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 02:29",
    currentValue: 2.7,
    currentUnit: "A",
    powerValue: 0.57,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 02:29",
    currentValue: 2.8,
    currentUnit: "A",
    powerValue: 0.59,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 02:29",
    currentValue: 10.8,
    currentUnit: "A",
    powerValue: 2.28,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 02:29",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 02:30",
    currentValue: 2.8,
    currentUnit: "A",
    powerValue: 0.59,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 02:30",
    currentValue: 7.8,
    currentUnit: "A",
    powerValue: 1.65,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 02:30",
    currentValue: 1,
    currentUnit: "A",
    powerValue: 0.21,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 02:30",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 02:31",
    currentValue: 2.7,
    currentUnit: "A",
    powerValue: 0.57,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 02:31",
    currentValue: 2.7,
    currentUnit: "A",
    powerValue: 0.57,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 02:31",
    currentValue: 5,
    currentUnit: "A",
    powerValue: 1.05,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 02:31",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 02:32",
    currentValue: 2.7,
    currentUnit: "A",
    powerValue: 0.57,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 02:32",
    currentValue: 2.8,
    currentUnit: "A",
    powerValue: 0.59,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 02:32",
    currentValue: 6.8,
    currentUnit: "A",
    powerValue: 1.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 02:32",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.42,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 02:33",
    currentValue: 2.7,
    currentUnit: "A",
    powerValue: 0.57,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 02:33",
    currentValue: 7.8,
    currentUnit: "A",
    powerValue: 1.64,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 02:33",
    currentValue: 5,
    currentUnit: "A",
    powerValue: 1.05,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 02:33",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.42,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 02:34",
    currentValue: 2.8,
    currentUnit: "A",
    powerValue: 0.59,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 02:34",
    currentValue: 2.7,
    currentUnit: "A",
    powerValue: 0.57,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 02:34",
    currentValue: 1,
    currentUnit: "A",
    powerValue: 0.21,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 02:34",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.42,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 02:35",
    currentValue: 7.8,
    currentUnit: "A",
    powerValue: 1.65,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 02:35",
    currentValue: 2.8,
    currentUnit: "A",
    powerValue: 0.59,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 02:35",
    currentValue: 1,
    currentUnit: "A",
    powerValue: 0.21,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 02:35",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 02:36",
    currentValue: 2.7,
    currentUnit: "A",
    powerValue: 0.57,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 02:36",
    currentValue: 7.8,
    currentUnit: "A",
    powerValue: 1.64,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 02:36",
    currentValue: 1,
    currentUnit: "A",
    powerValue: 0.21,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 02:36",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 02:37",
    currentValue: 3.2,
    currentUnit: "A",
    powerValue: 0.68,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 02:37",
    currentValue: 2.8,
    currentUnit: "A",
    powerValue: 0.59,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 02:37",
    currentValue: 1,
    currentUnit: "A",
    powerValue: 0.21,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 02:37",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 02:38",
    currentValue: 2.7,
    currentUnit: "A",
    powerValue: 0.57,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 02:38",
    currentValue: 2.7,
    currentUnit: "A",
    powerValue: 0.57,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 02:38",
    currentValue: 1,
    currentUnit: "A",
    powerValue: 0.21,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 02:38",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.42,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 02:39",
    currentValue: 2.7,
    currentUnit: "A",
    powerValue: 0.57,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 02:39",
    currentValue: 77.3,
    currentUnit: "A",
    powerValue: 16.32,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 02:39",
    currentValue: 1,
    currentUnit: "A",
    powerValue: 0.21,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 02:39",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.42,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 02:40",
    currentValue: 2.7,
    currentUnit: "A",
    powerValue: 0.57,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 02:40",
    currentValue: 72.3,
    currentUnit: "A",
    powerValue: 15.27,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 02:40",
    currentValue: 1,
    currentUnit: "A",
    powerValue: 0.21,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 02:40",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 02:41",
    currentValue: 2.7,
    currentUnit: "A",
    powerValue: 0.57,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 02:41",
    currentValue: 2.7,
    currentUnit: "A",
    powerValue: 0.57,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 02:41",
    currentValue: 6.8,
    currentUnit: "A",
    powerValue: 1.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 02:41",
    currentValue: 68.8,
    currentUnit: "A",
    powerValue: 14.4,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 02:42",
    currentValue: 5,
    currentUnit: "A",
    powerValue: 1.06,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 02:42",
    currentValue: 7.8,
    currentUnit: "A",
    powerValue: 1.65,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 02:42",
    currentValue: 1,
    currentUnit: "A",
    powerValue: 0.21,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 02:42",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.42,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 02:43",
    currentValue: 2.7,
    currentUnit: "A",
    powerValue: 0.57,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 02:43",
    currentValue: 2.7,
    currentUnit: "A",
    powerValue: 0.57,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 02:43",
    currentValue: 70.3,
    currentUnit: "A",
    powerValue: 14.6,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 02:43",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.42,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 02:44",
    currentValue: 7.8,
    currentUnit: "A",
    powerValue: 1.65,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 02:44",
    currentValue: 2.7,
    currentUnit: "A",
    powerValue: 0.57,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 02:44",
    currentValue: 76.2,
    currentUnit: "A",
    powerValue: 15.85,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 02:44",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.42,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 02:45",
    currentValue: 2.7,
    currentUnit: "A",
    powerValue: 0.57,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 02:45",
    currentValue: 7.8,
    currentUnit: "A",
    powerValue: 1.65,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 02:45",
    currentValue: 70.5,
    currentUnit: "A",
    powerValue: 14.65,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 02:45",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.42,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 02:46",
    currentValue: 2.7,
    currentUnit: "A",
    powerValue: 0.57,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 02:46",
    currentValue: 2.7,
    currentUnit: "A",
    powerValue: 0.57,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 02:46",
    currentValue: 71.3,
    currentUnit: "A",
    powerValue: 14.81,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 02:46",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 02:47",
    currentValue: 2.7,
    currentUnit: "A",
    powerValue: 0.57,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 02:47",
    currentValue: 2.7,
    currentUnit: "A",
    powerValue: 2.26,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 02:47",
    currentValue: 76.4,
    currentUnit: "A",
    powerValue: 15.9,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 02:47",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 02:48",
    currentValue: 2.7,
    currentUnit: "A",
    powerValue: 0.57,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 02:48",
    currentValue: 7.8,
    currentUnit: "A",
    powerValue: 1.64,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 02:48",
    currentValue: 73.9,
    currentUnit: "A",
    powerValue: 15.37,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 02:48",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 02:49",
    currentValue: 2.7,
    currentUnit: "A",
    powerValue: 0.57,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 02:49",
    currentValue: 2.7,
    currentUnit: "A",
    powerValue: 0.57,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 02:49",
    currentValue: 70.8,
    currentUnit: "A",
    powerValue: 14.71,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 02:49",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 02:50",
    currentValue: 2.7,
    currentUnit: "A",
    powerValue: 0.57,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 02:50",
    currentValue: 2.7,
    currentUnit: "A",
    powerValue: 0.57,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 02:50",
    currentValue: 74.2,
    currentUnit: "A",
    powerValue: 15.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 02:50",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.42,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 02:51",
    currentValue: 7.8,
    currentUnit: "A",
    powerValue: 1.65,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 02:51",
    currentValue: 2.7,
    currentUnit: "A",
    powerValue: 0.57,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 02:51",
    currentValue: 70.9,
    currentUnit: "A",
    powerValue: 14.72,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 02:51",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 02:52",
    currentValue: 2.7,
    currentUnit: "A",
    powerValue: 0.57,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 02:52",
    currentValue: 2.7,
    currentUnit: "A",
    powerValue: 0.57,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 02:52",
    currentValue: 74.1,
    currentUnit: "A",
    powerValue: 15.41,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 02:52",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 02:53",
    currentValue: 2.8,
    currentUnit: "A",
    powerValue: 0.59,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 02:53",
    currentValue: 2.7,
    currentUnit: "A",
    powerValue: 0.57,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 02:53",
    currentValue: 70.9,
    currentUnit: "A",
    powerValue: 14.74,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 02:53",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 02:54",
    currentValue: 2.7,
    currentUnit: "A",
    powerValue: 0.57,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 02:54",
    currentValue: 2.7,
    currentUnit: "A",
    powerValue: 0.57,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 02:54",
    currentValue: 75,
    currentUnit: "A",
    powerValue: 15.61,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 02:54",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 02:55",
    currentValue: 2.7,
    currentUnit: "A",
    powerValue: 0.57,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 02:55",
    currentValue: 2.7,
    currentUnit: "A",
    powerValue: 0.57,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 02:55",
    currentValue: 71,
    currentUnit: "A",
    powerValue: 14.76,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 02:55",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 02:56",
    currentValue: 2.7,
    currentUnit: "A",
    powerValue: 0.57,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 02:56",
    currentValue: 2.8,
    currentUnit: "A",
    powerValue: 0.59,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 02:56",
    currentValue: 61,
    currentUnit: "A",
    powerValue: 12.76,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 02:56",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 02:57",
    currentValue: 2.8,
    currentUnit: "A",
    powerValue: 0.6,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 02:57",
    currentValue: 2.7,
    currentUnit: "A",
    powerValue: 0.57,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 02:57",
    currentValue: 47.8,
    currentUnit: "A",
    powerValue: 10.03,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 02:57",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 02:58",
    currentValue: 7.8,
    currentUnit: "A",
    powerValue: 1.65,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 02:58",
    currentValue: 2.7,
    currentUnit: "A",
    powerValue: 0.57,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 02:58",
    currentValue: 87.9,
    currentUnit: "A",
    powerValue: 18.45,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 02:58",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 02:59",
    currentValue: 2.7,
    currentUnit: "A",
    powerValue: 0.57,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 02:59",
    currentValue: 2.7,
    currentUnit: "A",
    powerValue: 0.57,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 02:59",
    currentValue: 35.4,
    currentUnit: "A",
    powerValue: 7.47,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 02:59",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 03:00",
    currentValue: 2.8,
    currentUnit: "A",
    powerValue: 0.6,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 03:00",
    currentValue: 2.7,
    currentUnit: "A",
    powerValue: 0.57,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 03:00",
    currentValue: 35.6,
    currentUnit: "A",
    powerValue: 7.52,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 03:00",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 03:01",
    currentValue: 2.7,
    currentUnit: "A",
    powerValue: 0.57,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 03:01",
    currentValue: 72.2,
    currentUnit: "A",
    powerValue: 15.28,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 03:01",
    currentValue: 26.7,
    currentUnit: "A",
    powerValue: 5.65,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 03:01",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 03:02",
    currentValue: 2.7,
    currentUnit: "A",
    powerValue: 0.57,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 03:02",
    currentValue: 2.7,
    currentUnit: "A",
    powerValue: 0.57,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 03:02",
    currentValue: 28,
    currentUnit: "A",
    powerValue: 5.94,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 03:02",
    currentValue: 68.7,
    currentUnit: "A",
    powerValue: 14.39,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 03:03",
    currentValue: 2.7,
    currentUnit: "A",
    powerValue: 0.58,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 03:03",
    currentValue: 2.7,
    currentUnit: "A",
    powerValue: 0.57,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 03:03",
    currentValue: 71.1,
    currentUnit: "A",
    powerValue: 14.78,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 03:03",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 03:04",
    currentValue: 2.7,
    currentUnit: "A",
    powerValue: 0.57,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 03:04",
    currentValue: 2.7,
    currentUnit: "A",
    powerValue: 0.57,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 03:04",
    currentValue: 54.4,
    currentUnit: "A",
    powerValue: 11.42,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 03:04",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 03:05",
    currentValue: 7.8,
    currentUnit: "A",
    powerValue: 1.65,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 03:05",
    currentValue: 2.7,
    currentUnit: "A",
    powerValue: 0.57,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 03:05",
    currentValue: 1,
    currentUnit: "A",
    powerValue: 0.21,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 03:05",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 03:06",
    currentValue: 2.7,
    currentUnit: "A",
    powerValue: 0.58,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 03:06",
    currentValue: 2.7,
    currentUnit: "A",
    powerValue: 0.57,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 03:06",
    currentValue: 5,
    currentUnit: "A",
    powerValue: 1.06,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 03:06",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 03:07",
    currentValue: 7.7,
    currentUnit: "A",
    powerValue: 1.63,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 03:07",
    currentValue: 2.7,
    currentUnit: "A",
    powerValue: 0.57,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 03:07",
    currentValue: 1,
    currentUnit: "A",
    powerValue: 0.21,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 03:07",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 03:08",
    currentValue: 2.7,
    currentUnit: "A",
    powerValue: 0.58,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 03:08",
    currentValue: 2.7,
    currentUnit: "A",
    powerValue: 0.57,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 03:08",
    currentValue: 1,
    currentUnit: "A",
    powerValue: 0.21,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 03:08",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 03:09",
    currentValue: 2.7,
    currentUnit: "A",
    powerValue: 0.58,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 03:09",
    currentValue: 2.8,
    currentUnit: "A",
    powerValue: 0.6,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 03:09",
    currentValue: 1,
    currentUnit: "A",
    powerValue: 0.21,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 03:09",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 03:10",
    currentValue: 2.7,
    currentUnit: "A",
    powerValue: 0.58,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 03:10",
    currentValue: 2.7,
    currentUnit: "A",
    powerValue: 0.57,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 03:10",
    currentValue: 1,
    currentUnit: "A",
    powerValue: 0.21,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 03:10",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 03:11",
    currentValue: 2.7,
    currentUnit: "A",
    powerValue: 0.58,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 03:11",
    currentValue: 2.7,
    currentUnit: "A",
    powerValue: 0.57,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 03:11",
    currentValue: 6.8,
    currentUnit: "A",
    powerValue: 1.44,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 03:11",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 03:12",
    currentValue: 2.7,
    currentUnit: "A",
    powerValue: 0.58,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 03:12",
    currentValue: 2.7,
    currentUnit: "A",
    powerValue: 0.57,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 03:12",
    currentValue: 1,
    currentUnit: "A",
    powerValue: 0.21,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 03:12",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 03:13",
    currentValue: 2.7,
    currentUnit: "A",
    powerValue: 0.58,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 03:13",
    currentValue: 2.7,
    currentUnit: "A",
    powerValue: 0.57,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 03:13",
    currentValue: 1,
    currentUnit: "A",
    powerValue: 0.21,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 03:13",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 03:14",
    currentValue: 7.8,
    currentUnit: "A",
    powerValue: 1.65,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 03:14",
    currentValue: 7.8,
    currentUnit: "A",
    powerValue: 1.65,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 03:14",
    currentValue: 6.8,
    currentUnit: "A",
    powerValue: 1.44,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 03:14",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 03:15",
    currentValue: 2.7,
    currentUnit: "A",
    powerValue: 0.58,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 03:15",
    currentValue: 2.7,
    currentUnit: "A",
    powerValue: 0.57,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 03:15",
    currentValue: 1,
    currentUnit: "A",
    powerValue: 0.21,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 03:15",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 03:16",
    currentValue: 2.8,
    currentUnit: "A",
    powerValue: 0.6,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 03:16",
    currentValue: 2.7,
    currentUnit: "A",
    powerValue: 0.57,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 03:16",
    currentValue: 1,
    currentUnit: "A",
    powerValue: 0.21,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 03:16",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 03:17",
    currentValue: 2.7,
    currentUnit: "A",
    powerValue: 0.58,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 03:17",
    currentValue: 7.8,
    currentUnit: "A",
    powerValue: 1.65,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 03:17",
    currentValue: 6.8,
    currentUnit: "A",
    powerValue: 1.44,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 03:17",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 03:18",
    currentValue: 2.7,
    currentUnit: "A",
    powerValue: 0.58,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 03:18",
    currentValue: 2.7,
    currentUnit: "A",
    powerValue: 0.57,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 03:18",
    currentValue: 1,
    currentUnit: "A",
    powerValue: 0.21,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 03:18",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 03:19",
    currentValue: 2.7,
    currentUnit: "A",
    powerValue: 0.58,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 03:19",
    currentValue: 2.7,
    currentUnit: "A",
    powerValue: 0.57,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 03:19",
    currentValue: 1,
    currentUnit: "A",
    powerValue: 0.21,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 03:19",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 03:20",
    currentValue: 2.7,
    currentUnit: "A",
    powerValue: 0.58,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 03:20",
    currentValue: 7.8,
    currentUnit: "A",
    powerValue: 1.65,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 03:20",
    currentValue: 6.8,
    currentUnit: "A",
    powerValue: 1.44,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 03:20",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 03:21",
    currentValue: 7.8,
    currentUnit: "A",
    powerValue: 1.65,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 03:21",
    currentValue: 2.7,
    currentUnit: "A",
    powerValue: 0.57,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 03:21",
    currentValue: 1,
    currentUnit: "A",
    powerValue: 0.21,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 03:21",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 03:22",
    currentValue: 2.7,
    currentUnit: "A",
    powerValue: 0.58,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 03:22",
    currentValue: 70.6,
    currentUnit: "A",
    powerValue: 14.97,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 03:22",
    currentValue: 1,
    currentUnit: "A",
    powerValue: 0.21,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 03:22",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 03:23",
    currentValue: 7.8,
    currentUnit: "A",
    powerValue: 1.65,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 03:23",
    currentValue: 7.8,
    currentUnit: "A",
    powerValue: 1.65,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 03:23",
    currentValue: 6.7,
    currentUnit: "A",
    powerValue: 1.42,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 03:23",
    currentValue: 68.2,
    currentUnit: "A",
    powerValue: 14.32,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 03:24",
    currentValue: 2.7,
    currentUnit: "A",
    powerValue: 0.58,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 03:24",
    currentValue: 2.7,
    currentUnit: "A",
    powerValue: 0.57,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 03:24",
    currentValue: 1,
    currentUnit: "A",
    powerValue: 0.21,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 03:24",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 03:25",
    currentValue: 2.7,
    currentUnit: "A",
    powerValue: 0.58,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 03:25",
    currentValue: 2.8,
    currentUnit: "A",
    powerValue: 0.6,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 03:25",
    currentValue: 1,
    currentUnit: "A",
    powerValue: 0.21,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 03:25",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 03:26",
    currentValue: 2.7,
    currentUnit: "A",
    powerValue: 0.58,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 03:26",
    currentValue: 7.8,
    currentUnit: "A",
    powerValue: 1.65,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 03:26",
    currentValue: 6.8,
    currentUnit: "A",
    powerValue: 1.44,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 03:26",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 03:27",
    currentValue: 2.7,
    currentUnit: "A",
    powerValue: 0.58,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 03:27",
    currentValue: 2.7,
    currentUnit: "A",
    powerValue: 0.57,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 03:27",
    currentValue: 1,
    currentUnit: "A",
    powerValue: 0.21,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 03:27",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 03:28",
    currentValue: 4,
    currentUnit: "A",
    powerValue: 0.85,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 03:28",
    currentValue: 2.8,
    currentUnit: "A",
    powerValue: 0.6,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 03:28",
    currentValue: 1,
    currentUnit: "A",
    powerValue: 0.21,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 03:28",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 03:29",
    currentValue: 2.7,
    currentUnit: "A",
    powerValue: 0.58,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 03:29",
    currentValue: 7.8,
    currentUnit: "A",
    powerValue: 1.65,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 03:29",
    currentValue: 1.7,
    currentUnit: "A",
    powerValue: 0.36,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 03:29",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 03:30",
    currentValue: 7.8,
    currentUnit: "A",
    powerValue: 1.65,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 03:30",
    currentValue: 2.8,
    currentUnit: "A",
    powerValue: 0.6,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 03:30",
    currentValue: 1,
    currentUnit: "A",
    powerValue: 0.21,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 03:30",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 03:31",
    currentValue: 2.7,
    currentUnit: "A",
    powerValue: 0.58,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 03:31",
    currentValue: 2.7,
    currentUnit: "A",
    powerValue: 0.57,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 03:31",
    currentValue: 1,
    currentUnit: "A",
    powerValue: 0.21,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 03:31",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 03:32",
    currentValue: 2.7,
    currentUnit: "A",
    powerValue: 0.58,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 03:32",
    currentValue: 7.8,
    currentUnit: "A",
    powerValue: 1.65,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 03:32",
    currentValue: 1.9,
    currentUnit: "A",
    powerValue: 0.4,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 03:32",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 03:33",
    currentValue: 2.7,
    currentUnit: "A",
    powerValue: 0.58,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 03:33",
    currentValue: 2.7,
    currentUnit: "A",
    powerValue: 0.57,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 03:33",
    currentValue: 1,
    currentUnit: "A",
    powerValue: 0.21,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 03:33",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 03:34",
    currentValue: 2.7,
    currentUnit: "A",
    powerValue: 0.58,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 03:34",
    currentValue: 2.7,
    currentUnit: "A",
    powerValue: 0.57,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 03:34",
    currentValue: 1,
    currentUnit: "A",
    powerValue: 0.21,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 03:34",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 03:35",
    currentValue: 7.8,
    currentUnit: "A",
    powerValue: 1.65,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 03:35",
    currentValue: 2.7,
    currentUnit: "A",
    powerValue: 0.58,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 03:35",
    currentValue: 1.7,
    currentUnit: "A",
    powerValue: 0.36,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 03:35",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 03:36",
    currentValue: 2.7,
    currentUnit: "A",
    powerValue: 0.58,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 03:36",
    currentValue: 2.7,
    currentUnit: "A",
    powerValue: 0.58,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 03:36",
    currentValue: 1,
    currentUnit: "A",
    powerValue: 0.21,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 03:36",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 03:37",
    currentValue: 7.8,
    currentUnit: "A",
    powerValue: 1.65,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 03:37",
    currentValue: 2.7,
    currentUnit: "A",
    powerValue: 0.58,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 03:37",
    currentValue: 1,
    currentUnit: "A",
    powerValue: 0.21,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 03:37",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 03:38",
    currentValue: 2.7,
    currentUnit: "A",
    powerValue: 0.58,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 03:38",
    currentValue: 2.7,
    currentUnit: "A",
    powerValue: 0.58,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 03:38",
    currentValue: 6.8,
    currentUnit: "A",
    powerValue: 1.44,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 03:38",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 03:39",
    currentValue: 2.7,
    currentUnit: "A",
    powerValue: 0.58,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 03:39",
    currentValue: 2.8,
    currentUnit: "A",
    powerValue: 0.6,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 03:39",
    currentValue: 1,
    currentUnit: "A",
    powerValue: 0.21,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 03:39",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 03:40",
    currentValue: 2.7,
    currentUnit: "A",
    powerValue: 0.58,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 03:40",
    currentValue: 2.7,
    currentUnit: "A",
    powerValue: 0.57,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 03:40",
    currentValue: 1,
    currentUnit: "A",
    powerValue: 0.21,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 03:40",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 03:41",
    currentValue: 2.8,
    currentUnit: "A",
    powerValue: 0.6,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 03:41",
    currentValue: 2.7,
    currentUnit: "A",
    powerValue: 0.57,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 03:41",
    currentValue: 6.8,
    currentUnit: "A",
    powerValue: 1.44,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 03:41",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 03:42",
    currentValue: 2.7,
    currentUnit: "A",
    powerValue: 0.58,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 03:42",
    currentValue: 2.8,
    currentUnit: "A",
    powerValue: 0.6,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 03:42",
    currentValue: 1,
    currentUnit: "A",
    powerValue: 0.21,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 03:42",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 03:43",
    currentValue: 2.7,
    currentUnit: "A",
    powerValue: 0.58,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 03:43",
    currentValue: 2.8,
    currentUnit: "A",
    powerValue: 0.6,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 03:43",
    currentValue: 1,
    currentUnit: "A",
    powerValue: 0.21,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 03:43",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 03:44",
    currentValue: 7.8,
    currentUnit: "A",
    powerValue: 1.65,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 03:44",
    currentValue: 60.5,
    currentUnit: "A",
    powerValue: 12.87,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 03:44",
    currentValue: 6.8,
    currentUnit: "A",
    powerValue: 1.44,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 03:44",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 03:45",
    currentValue: 2.7,
    currentUnit: "A",
    powerValue: 0.58,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 03:45",
    currentValue: 2.7,
    currentUnit: "A",
    powerValue: 0.57,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 03:45",
    currentValue: 1,
    currentUnit: "A",
    powerValue: 0.21,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 03:45",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 03:46",
    currentValue: 2.7,
    currentUnit: "A",
    powerValue: 0.58,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 03:46",
    currentValue: 2.8,
    currentUnit: "A",
    powerValue: 0.6,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 03:46",
    currentValue: 1,
    currentUnit: "A",
    powerValue: 0.21,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 03:46",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 03:47",
    currentValue: 2.7,
    currentUnit: "A",
    powerValue: 0.58,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 03:47",
    currentValue: 2.7,
    currentUnit: "A",
    powerValue: 0.58,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 03:47",
    currentValue: 1.9,
    currentUnit: "A",
    powerValue: 0.4,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 03:47",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 03:48",
    currentValue: 2.7,
    currentUnit: "A",
    powerValue: 0.58,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 03:48",
    currentValue: 2.8,
    currentUnit: "A",
    powerValue: 0.6,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 03:48",
    currentValue: 1,
    currentUnit: "A",
    powerValue: 0.21,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 03:48",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 03:49",
    currentValue: 2.7,
    currentUnit: "A",
    powerValue: 0.58,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 03:49",
    currentValue: 2.8,
    currentUnit: "A",
    powerValue: 0.6,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 03:49",
    currentValue: 1,
    currentUnit: "A",
    powerValue: 0.21,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 03:49",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 03:50",
    currentValue: 2.8,
    currentUnit: "A",
    powerValue: 0.6,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 03:50",
    currentValue: 2.8,
    currentUnit: "A",
    powerValue: 0.6,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 03:50",
    currentValue: 5,
    currentUnit: "A",
    powerValue: 1.06,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 03:50",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 03:51",
    currentValue: 7.8,
    currentUnit: "A",
    powerValue: 1.65,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 03:51",
    currentValue: 2.8,
    currentUnit: "A",
    powerValue: 0.6,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 03:51",
    currentValue: 1,
    currentUnit: "A",
    powerValue: 0.21,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 03:51",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 03:52",
    currentValue: 2.7,
    currentUnit: "A",
    powerValue: 0.58,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 03:52",
    currentValue: 2.7,
    currentUnit: "A",
    powerValue: 0.58,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 03:52",
    currentValue: 44.3,
    currentUnit: "A",
    powerValue: 9.3,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 03:52",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 03:53",
    currentValue: 7.8,
    currentUnit: "A",
    powerValue: 1.65,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 03:53",
    currentValue: 2.7,
    currentUnit: "A",
    powerValue: 0.58,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 03:53",
    currentValue: 2.9,
    currentUnit: "A",
    powerValue: 0.61,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 03:53",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 03:54",
    currentValue: 2.7,
    currentUnit: "A",
    powerValue: 0.58,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 03:54",
    currentValue: 2.8,
    currentUnit: "A",
    powerValue: 0.6,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 03:54",
    currentValue: 1,
    currentUnit: "A",
    powerValue: 0.21,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 03:54",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 03:55",
    currentValue: 2.7,
    currentUnit: "A",
    powerValue: 0.58,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 03:55",
    currentValue: 2.8,
    currentUnit: "A",
    powerValue: 0.6,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 03:55",
    currentValue: 1.7,
    currentUnit: "A",
    powerValue: 0.36,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 03:55",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 03:56",
    currentValue: 2.7,
    currentUnit: "A",
    powerValue: 0.58,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 03:56",
    currentValue: 2.7,
    currentUnit: "A",
    powerValue: 0.58,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 03:56",
    currentValue: 1.8,
    currentUnit: "A",
    powerValue: 0.38,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 03:56",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 03:57",
    currentValue: 2.7,
    currentUnit: "A",
    powerValue: 0.58,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 03:57",
    currentValue: 2.8,
    currentUnit: "A",
    powerValue: 0.6,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 03:57",
    currentValue: 3.1,
    currentUnit: "A",
    powerValue: 0.66,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 03:57",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 03:58",
    currentValue: 2.7,
    currentUnit: "A",
    powerValue: 0.58,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 03:58",
    currentValue: 7.8,
    currentUnit: "A",
    powerValue: 1.65,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 03:58",
    currentValue: 1,
    currentUnit: "A",
    powerValue: 0.21,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 03:58",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 03:59",
    currentValue: 2.7,
    currentUnit: "A",
    powerValue: 0.58,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 03:59",
    currentValue: 2.8,
    currentUnit: "A",
    powerValue: 0.6,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 03:59",
    currentValue: 5.7,
    currentUnit: "A",
    powerValue: 1.21,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 03:59",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 04:00",
    currentValue: 7.8,
    currentUnit: "A",
    powerValue: 1.65,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 04:00",
    currentValue: 2.8,
    currentUnit: "A",
    powerValue: 0.6,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 04:00",
    currentValue: 1,
    currentUnit: "A",
    powerValue: 0.21,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 04:00",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 04:01",
    currentValue: 2.7,
    currentUnit: "A",
    powerValue: 0.58,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 04:01",
    currentValue: 7.8,
    currentUnit: "A",
    powerValue: 1.65,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 04:01",
    currentValue: 4.9,
    currentUnit: "A",
    powerValue: 1.04,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 04:01",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 04:02",
    currentValue: 2.7,
    currentUnit: "A",
    powerValue: 0.58,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 04:02",
    currentValue: 2.8,
    currentUnit: "A",
    powerValue: 0.6,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 04:02",
    currentValue: 1.8,
    currentUnit: "A",
    powerValue: 0.38,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 04:02",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 04:03",
    currentValue: 2.7,
    currentUnit: "A",
    powerValue: 0.58,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 04:03",
    currentValue: 2.7,
    currentUnit: "A",
    powerValue: 0.58,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 04:03",
    currentValue: 4.9,
    currentUnit: "A",
    powerValue: 1.04,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 04:03",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 04:04",
    currentValue: 2.7,
    currentUnit: "A",
    powerValue: 0.58,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 04:04",
    currentValue: 7.8,
    currentUnit: "A",
    powerValue: 1.65,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 04:04",
    currentValue: 1,
    currentUnit: "A",
    powerValue: 0.21,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 04:04",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 04:05",
    currentValue: 2.7,
    currentUnit: "A",
    powerValue: 0.58,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 04:05",
    currentValue: 59.9,
    currentUnit: "A",
    powerValue: 12.76,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 04:05",
    currentValue: 5.6,
    currentUnit: "A",
    powerValue: 1.19,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 04:05",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 04:06",
    currentValue: 2.7,
    currentUnit: "A",
    powerValue: 0.58,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 04:06",
    currentValue: 2.7,
    currentUnit: "A",
    powerValue: 0.58,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 04:06",
    currentValue: 1,
    currentUnit: "A",
    powerValue: 0.21,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 04:06",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 04:07",
    currentValue: 2.7,
    currentUnit: "A",
    powerValue: 0.58,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 04:07",
    currentValue: 7.8,
    currentUnit: "A",
    powerValue: 1.65,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 04:07",
    currentValue: 5,
    currentUnit: "A",
    powerValue: 1.06,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 04:07",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 04:08",
    currentValue: 2.7,
    currentUnit: "A",
    powerValue: 0.58,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 04:08",
    currentValue: 2.8,
    currentUnit: "A",
    powerValue: 0.6,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 04:08",
    currentValue: 1.7,
    currentUnit: "A",
    powerValue: 0.36,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 04:08",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 04:09",
    currentValue: 7.8,
    currentUnit: "A",
    powerValue: 1.65,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 04:09",
    currentValue: 2.7,
    currentUnit: "A",
    powerValue: 0.58,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 04:09",
    currentValue: 4.9,
    currentUnit: "A",
    powerValue: 1.04,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 04:09",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 04:10",
    currentValue: 2.7,
    currentUnit: "A",
    powerValue: 0.58,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 04:10",
    currentValue: 7.8,
    currentUnit: "A",
    powerValue: 1.65,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 04:10",
    currentValue: 1,
    currentUnit: "A",
    powerValue: 0.21,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 04:10",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 04:11",
    currentValue: 2.7,
    currentUnit: "A",
    powerValue: 0.58,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 04:11",
    currentValue: 2.7,
    currentUnit: "A",
    powerValue: 0.58,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 04:11",
    currentValue: 10.7,
    currentUnit: "A",
    powerValue: 2.27,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 04:11",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 04:12",
    currentValue: 2.7,
    currentUnit: "A",
    powerValue: 0.58,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 04:12",
    currentValue: 2.8,
    currentUnit: "A",
    powerValue: 0.6,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 04:12",
    currentValue: 1,
    currentUnit: "A",
    powerValue: 0.21,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 04:12",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 04:13",
    currentValue: 2.8,
    currentUnit: "A",
    powerValue: 0.6,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 04:13",
    currentValue: 7.8,
    currentUnit: "A",
    powerValue: 1.65,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 04:13",
    currentValue: 4.9,
    currentUnit: "A",
    powerValue: 1.04,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 04:13",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 04:14",
    currentValue: 2.7,
    currentUnit: "A",
    powerValue: 0.58,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 04:14",
    currentValue: 2.7,
    currentUnit: "A",
    powerValue: 0.58,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 04:14",
    currentValue: 6.8,
    currentUnit: "A",
    powerValue: 1.44,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 04:14",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 04:15",
    currentValue: 2.7,
    currentUnit: "A",
    powerValue: 0.58,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 04:15",
    currentValue: 2.8,
    currentUnit: "A",
    powerValue: 0.6,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 04:15",
    currentValue: 5,
    currentUnit: "A",
    powerValue: 1.06,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 04:15",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 04:16",
    currentValue: 7.8,
    currentUnit: "A",
    powerValue: 1.65,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 04:16",
    currentValue: 7.8,
    currentUnit: "A",
    powerValue: 1.65,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 04:16",
    currentValue: 1,
    currentUnit: "A",
    powerValue: 0.21,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 04:16",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 04:17",
    currentValue: 2.7,
    currentUnit: "A",
    powerValue: 0.58,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 04:17",
    currentValue: 2.8,
    currentUnit: "A",
    powerValue: 0.6,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 04:17",
    currentValue: 10.7,
    currentUnit: "A",
    powerValue: 2.27,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 04:17",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 04:18",
    currentValue: 2.7,
    currentUnit: "A",
    powerValue: 0.58,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 04:18",
    currentValue: 2.8,
    currentUnit: "A",
    powerValue: 0.6,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 04:18",
    currentValue: 1,
    currentUnit: "A",
    powerValue: 0.21,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 04:18",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 04:19",
    currentValue: 2.8,
    currentUnit: "A",
    powerValue: 0.6,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 04:19",
    currentValue: 2.7,
    currentUnit: "A",
    powerValue: 0.58,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 04:19",
    currentValue: 4.9,
    currentUnit: "A",
    powerValue: 1.04,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 04:19",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 04:20",
    currentValue: 2.7,
    currentUnit: "A",
    powerValue: 0.58,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 04:20",
    currentValue: 2.7,
    currentUnit: "A",
    powerValue: 0.58,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 04:20",
    currentValue: 6.8,
    currentUnit: "A",
    powerValue: 1.44,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 04:20",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 04:21",
    currentValue: 2.7,
    currentUnit: "A",
    powerValue: 0.58,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 04:21",
    currentValue: 2.8,
    currentUnit: "A",
    powerValue: 0.6,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 04:21",
    currentValue: 4.9,
    currentUnit: "A",
    powerValue: 1.04,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 04:21",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 04:22",
    currentValue: 2.7,
    currentUnit: "A",
    powerValue: 0.58,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 04:22",
    currentValue: 2.7,
    currentUnit: "A",
    powerValue: 0.58,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 04:22",
    currentValue: 1,
    currentUnit: "A",
    powerValue: 0.21,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 04:22",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 04:23",
    currentValue: 7.8,
    currentUnit: "A",
    powerValue: 1.65,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 04:23",
    currentValue: 2.7,
    currentUnit: "A",
    powerValue: 0.58,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 04:23",
    currentValue: 5.7,
    currentUnit: "A",
    powerValue: 1.21,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 04:23",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 04:24",
    currentValue: 2.7,
    currentUnit: "A",
    powerValue: 0.58,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 04:24",
    currentValue: 2.8,
    currentUnit: "A",
    powerValue: 0.6,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 04:24",
    currentValue: 1,
    currentUnit: "A",
    powerValue: 0.21,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 04:24",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 04:25",
    currentValue: 7.8,
    currentUnit: "A",
    powerValue: 1.65,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 04:25",
    currentValue: 2.8,
    currentUnit: "A",
    powerValue: 0.6,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 04:25",
    currentValue: 1,
    currentUnit: "A",
    powerValue: 0.21,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 04:25",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 04:26",
    currentValue: 2.7,
    currentUnit: "A",
    powerValue: 0.58,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 04:26",
    currentValue: 59.9,
    currentUnit: "A",
    powerValue: 12.76,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 04:26",
    currentValue: 3.2,
    currentUnit: "A",
    powerValue: 0.68,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 04:26",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 04:27",
    currentValue: 2.7,
    currentUnit: "A",
    powerValue: 0.58,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 04:27",
    currentValue: 4.2,
    currentUnit: "A",
    powerValue: 0.89,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 04:27",
    currentValue: 1,
    currentUnit: "A",
    powerValue: 0.21,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 04:27",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 04:28",
    currentValue: 2.7,
    currentUnit: "A",
    powerValue: 0.58,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 04:28",
    currentValue: 2.8,
    currentUnit: "A",
    powerValue: 0.6,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 04:28",
    currentValue: 1,
    currentUnit: "A",
    powerValue: 0.21,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 04:28",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 04:29",
    currentValue: 2.7,
    currentUnit: "A",
    powerValue: 0.58,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 04:29",
    currentValue: 2.7,
    currentUnit: "A",
    powerValue: 0.58,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 04:29",
    currentValue: 6.8,
    currentUnit: "A",
    powerValue: 1.44,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 04:29",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 04:30",
    currentValue: 2.7,
    currentUnit: "A",
    powerValue: 0.58,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 04:30",
    currentValue: 2.8,
    currentUnit: "A",
    powerValue: 0.6,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 04:30",
    currentValue: 1,
    currentUnit: "A",
    powerValue: 0.21,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 04:30",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 04:31",
    currentValue: 2.8,
    currentUnit: "A",
    powerValue: 0.6,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 04:31",
    currentValue: 2.8,
    currentUnit: "A",
    powerValue: 0.6,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 04:31",
    currentValue: 1,
    currentUnit: "A",
    powerValue: 0.21,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 04:31",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 04:32",
    currentValue: 7.8,
    currentUnit: "A",
    powerValue: 1.66,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 04:32",
    currentValue: 2.8,
    currentUnit: "A",
    powerValue: 0.6,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 04:32",
    currentValue: 6.8,
    currentUnit: "A",
    powerValue: 1.44,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 04:32",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 04:33",
    currentValue: 2.7,
    currentUnit: "A",
    powerValue: 0.58,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 04:33",
    currentValue: 5.4,
    currentUnit: "A",
    powerValue: 1.15,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 04:33",
    currentValue: 1,
    currentUnit: "A",
    powerValue: 0.21,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 04:33",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 04:34",
    currentValue: 2.7,
    currentUnit: "A",
    powerValue: 0.58,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 04:34",
    currentValue: 2.7,
    currentUnit: "A",
    powerValue: 0.58,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 04:34",
    currentValue: 1,
    currentUnit: "A",
    powerValue: 0.21,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 04:34",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 04:35",
    currentValue: 2.7,
    currentUnit: "A",
    powerValue: 0.58,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 04:35",
    currentValue: 2.8,
    currentUnit: "A",
    powerValue: 0.6,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 04:35",
    currentValue: 6.8,
    currentUnit: "A",
    powerValue: 1.44,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 04:35",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 04:36",
    currentValue: 2.7,
    currentUnit: "A",
    powerValue: 0.58,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 04:36",
    currentValue: 7.8,
    currentUnit: "A",
    powerValue: 1.66,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 04:36",
    currentValue: 1,
    currentUnit: "A",
    powerValue: 0.21,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 04:36",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 04:37",
    currentValue: 6.1,
    currentUnit: "A",
    powerValue: 1.3,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 04:37",
    currentValue: 2.8,
    currentUnit: "A",
    powerValue: 0.6,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 04:37",
    currentValue: 1,
    currentUnit: "A",
    powerValue: 0.21,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 04:37",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 04:38",
    currentValue: 2.7,
    currentUnit: "A",
    powerValue: 0.58,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 04:38",
    currentValue: 2.7,
    currentUnit: "A",
    powerValue: 0.58,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 04:38",
    currentValue: 6.8,
    currentUnit: "A",
    powerValue: 1.44,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 04:38",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 04:39",
    currentValue: 7.8,
    currentUnit: "A",
    powerValue: 1.66,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 04:39",
    currentValue: 7.8,
    currentUnit: "A",
    powerValue: 1.66,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 04:39",
    currentValue: 1,
    currentUnit: "A",
    powerValue: 0.21,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 04:39",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 04:40",
    currentValue: 2.7,
    currentUnit: "A",
    powerValue: 0.58,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 04:40",
    currentValue: 2.8,
    currentUnit: "A",
    powerValue: 0.6,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 04:40",
    currentValue: 1,
    currentUnit: "A",
    powerValue: 0.21,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 04:40",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 04:41",
    currentValue: 2.8,
    currentUnit: "A",
    powerValue: 0.6,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 04:41",
    currentValue: 2.8,
    currentUnit: "A",
    powerValue: 0.6,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 04:41",
    currentValue: 6.8,
    currentUnit: "A",
    powerValue: 1.44,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 04:41",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 04:42",
    currentValue: 2.8,
    currentUnit: "A",
    powerValue: 0.6,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 04:42",
    currentValue: 7.7,
    currentUnit: "A",
    powerValue: 1.63,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 04:42",
    currentValue: 1,
    currentUnit: "A",
    powerValue: 0.21,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 04:42",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 04:43",
    currentValue: 2.7,
    currentUnit: "A",
    powerValue: 0.58,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 04:43",
    currentValue: 2.7,
    currentUnit: "A",
    powerValue: 0.58,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 04:43",
    currentValue: 44.4,
    currentUnit: "A",
    powerValue: 9.33,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 04:43",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 04:44",
    currentValue: 2.7,
    currentUnit: "A",
    powerValue: 0.58,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 04:44",
    currentValue: 2.7,
    currentUnit: "A",
    powerValue: 0.58,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 04:44",
    currentValue: 6.8,
    currentUnit: "A",
    powerValue: 1.44,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 04:44",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 04:45",
    currentValue: 2.8,
    currentUnit: "A",
    powerValue: 0.6,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 04:45",
    currentValue: 7.8,
    currentUnit: "A",
    powerValue: 1.66,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 04:45",
    currentValue: 1,
    currentUnit: "A",
    powerValue: 0.21,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 04:45",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 04:46",
    currentValue: 2.7,
    currentUnit: "A",
    powerValue: 0.58,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 04:46",
    currentValue: 2.8,
    currentUnit: "A",
    powerValue: 0.6,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 04:46",
    currentValue: 1,
    currentUnit: "A",
    powerValue: 0.21,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 04:46",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 04:47",
    currentValue: 2.7,
    currentUnit: "A",
    powerValue: 0.58,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 04:47",
    currentValue: 2.8,
    currentUnit: "A",
    powerValue: 0.6,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 04:47",
    currentValue: 6.8,
    currentUnit: "A",
    powerValue: 1.44,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 04:47",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 04:48",
    currentValue: 7.8,
    currentUnit: "A",
    powerValue: 1.66,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 04:48",
    currentValue: 2.7,
    currentUnit: "A",
    powerValue: 0.58,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 04:48",
    currentValue: 1,
    currentUnit: "A",
    powerValue: 0.21,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 04:48",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 04:49",
    currentValue: 2.7,
    currentUnit: "A",
    powerValue: 0.58,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 04:49",
    currentValue: 2.8,
    currentUnit: "A",
    powerValue: 0.6,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 04:49",
    currentValue: 1,
    currentUnit: "A",
    powerValue: 0.21,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 04:49",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 04:50",
    currentValue: 2.7,
    currentUnit: "A",
    powerValue: 0.58,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 04:50",
    currentValue: 2.8,
    currentUnit: "A",
    powerValue: 0.6,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 04:50",
    currentValue: 6.8,
    currentUnit: "A",
    powerValue: 1.44,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 04:50",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 04:51",
    currentValue: 2.7,
    currentUnit: "A",
    powerValue: 0.58,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 04:51",
    currentValue: 2.7,
    currentUnit: "A",
    powerValue: 0.58,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 04:51",
    currentValue: 1,
    currentUnit: "A",
    powerValue: 0.21,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 04:51",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 04:52",
    currentValue: 2.7,
    currentUnit: "A",
    powerValue: 0.58,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 04:52",
    currentValue: 2.7,
    currentUnit: "A",
    powerValue: 0.58,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 04:52",
    currentValue: 1.8,
    currentUnit: "A",
    powerValue: 0.38,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 04:52",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 04:53",
    currentValue: 2.7,
    currentUnit: "A",
    powerValue: 0.58,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 04:53",
    currentValue: 2.8,
    currentUnit: "A",
    powerValue: 0.6,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 04:53",
    currentValue: 2.6,
    currentUnit: "A",
    powerValue: 0.55,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 04:53",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 04:54",
    currentValue: 2.8,
    currentUnit: "A",
    powerValue: 0.6,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 04:54",
    currentValue: 2.7,
    currentUnit: "A",
    powerValue: 0.58,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 04:54",
    currentValue: 1.8,
    currentUnit: "A",
    powerValue: 0.38,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 04:54",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 04:55",
    currentValue: 7.8,
    currentUnit: "A",
    powerValue: 1.66,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 04:55",
    currentValue: 2.8,
    currentUnit: "A",
    powerValue: 0.6,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 04:55",
    currentValue: 1,
    currentUnit: "A",
    powerValue: 0.21,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 04:55",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 04:56",
    currentValue: 2.7,
    currentUnit: "A",
    powerValue: 0.58,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 04:56",
    currentValue: 2.7,
    currentUnit: "A",
    powerValue: 0.58,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 04:56",
    currentValue: 9,
    currentUnit: "A",
    powerValue: 1.91,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 04:56",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 04:57",
    currentValue: 2.8,
    currentUnit: "A",
    powerValue: 0.6,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 04:57",
    currentValue: 2.8,
    currentUnit: "A",
    powerValue: 0.6,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 04:57",
    currentValue: 1,
    currentUnit: "A",
    powerValue: 0.21,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 04:57",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 04:58",
    currentValue: 2.8,
    currentUnit: "A",
    powerValue: 0.6,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 04:58",
    currentValue: 2.8,
    currentUnit: "A",
    powerValue: 0.6,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 04:58",
    currentValue: 4.9,
    currentUnit: "A",
    powerValue: 1.04,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 04:58",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 04:59",
    currentValue: 2.7,
    currentUnit: "A",
    powerValue: 0.58,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 04:59",
    currentValue: 2.8,
    currentUnit: "A",
    powerValue: 0.6,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 04:59",
    currentValue: 6.8,
    currentUnit: "A",
    powerValue: 1.45,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 04:59",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 05:00",
    currentValue: 2.7,
    currentUnit: "A",
    powerValue: 0.58,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 05:00",
    currentValue: 2.7,
    currentUnit: "A",
    powerValue: 0.58,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 05:00",
    currentValue: 4.9,
    currentUnit: "A",
    powerValue: 1.04,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 05:00",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 05:01",
    currentValue: 2.7,
    currentUnit: "A",
    powerValue: 0.58,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 05:01",
    currentValue: 2.8,
    currentUnit: "A",
    powerValue: 0.6,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 05:01",
    currentValue: 1,
    currentUnit: "A",
    powerValue: 0.21,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 05:01",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 05:02",
    currentValue: 2.7,
    currentUnit: "A",
    powerValue: 0.58,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 05:02",
    currentValue: 2.7,
    currentUnit: "A",
    powerValue: 0.58,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 05:02",
    currentValue: 10.7,
    currentUnit: "A",
    powerValue: 2.28,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 05:02",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 05:03",
    currentValue: 2.7,
    currentUnit: "A",
    powerValue: 0.58,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 05:03",
    currentValue: 2.7,
    currentUnit: "A",
    powerValue: 0.58,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 05:03",
    currentValue: 1,
    currentUnit: "A",
    powerValue: 0.21,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 05:03",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 05:04",
    currentValue: 7.8,
    currentUnit: "A",
    powerValue: 1.66,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 05:04",
    currentValue: 2.8,
    currentUnit: "A",
    powerValue: 0.6,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 05:04",
    currentValue: 4.9,
    currentUnit: "A",
    powerValue: 1.04,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 05:04",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 05:05",
    currentValue: 2.7,
    currentUnit: "A",
    powerValue: 0.58,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 05:05",
    currentValue: 2.7,
    currentUnit: "A",
    powerValue: 0.58,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 05:05",
    currentValue: 6.8,
    currentUnit: "A",
    powerValue: 1.45,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 05:05",
    currentValue: 55.9,
    currentUnit: "A",
    powerValue: 11.95,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 05:06",
    currentValue: 7.8,
    currentUnit: "A",
    powerValue: 1.66,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 05:06",
    currentValue: 60.5,
    currentUnit: "A",
    powerValue: 12.77,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 05:06",
    currentValue: 4.9,
    currentUnit: "A",
    powerValue: 1.04,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 05:06",
    currentValue: 111.4,
    currentUnit: "A",
    powerValue: 23.37,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 05:07",
    currentValue: 2.7,
    currentUnit: "A",
    powerValue: 0.58,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 05:07",
    currentValue: 53.6,
    currentUnit: "A",
    powerValue: 11.33,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 05:07",
    currentValue: 1,
    currentUnit: "A",
    powerValue: 0.21,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 05:07",
    currentValue: 110.7,
    currentUnit: "A",
    powerValue: 23.21,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 05:08",
    currentValue: 2.7,
    currentUnit: "A",
    powerValue: 0.58,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 05:08",
    currentValue: 43.8,
    currentUnit: "A",
    powerValue: 9.28,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 05:08",
    currentValue: 10.7,
    currentUnit: "A",
    powerValue: 2.27,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 05:08",
    currentValue: 109,
    currentUnit: "A",
    powerValue: 22.85,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 05:09",
    currentValue: 2.8,
    currentUnit: "A",
    powerValue: 0.6,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 05:09",
    currentValue: 43.2,
    currentUnit: "A",
    powerValue: 9.15,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 05:09",
    currentValue: 1,
    currentUnit: "A",
    powerValue: 0.21,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 05:09",
    currentValue: 105.4,
    currentUnit: "A",
    powerValue: 22.1,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 05:10",
    currentValue: 2.7,
    currentUnit: "A",
    powerValue: 0.58,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 05:10",
    currentValue: 43,
    currentUnit: "A",
    powerValue: 9.11,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 05:10",
    currentValue: 4.9,
    currentUnit: "A",
    powerValue: 1.04,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 05:10",
    currentValue: 105.3,
    currentUnit: "A",
    powerValue: 22.08,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 05:11",
    currentValue: 2.7,
    currentUnit: "A",
    powerValue: 0.58,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 05:11",
    currentValue: 2.8,
    currentUnit: "A",
    powerValue: 0.6,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 05:11",
    currentValue: 6.8,
    currentUnit: "A",
    powerValue: 1.44,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 05:11",
    currentValue: 72.8,
    currentUnit: "A",
    powerValue: 15.11,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 05:12",
    currentValue: 2.7,
    currentUnit: "A",
    powerValue: 0.58,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 05:12",
    currentValue: 2.8,
    currentUnit: "A",
    powerValue: 0.6,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 05:12",
    currentValue: 4.9,
    currentUnit: "A",
    powerValue: 1.04,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 05:12",
    currentValue: 72.8,
    currentUnit: "A",
    powerValue: 15.11,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 05:13",
    currentValue: 7.8,
    currentUnit: "A",
    powerValue: 1.66,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 05:13",
    currentValue: 2.8,
    currentUnit: "A",
    powerValue: 0.6,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 05:13",
    currentValue: 1,
    currentUnit: "A",
    powerValue: 0.21,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 05:13",
    currentValue: 72.7,
    currentUnit: "A",
    powerValue: 15.08,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 05:14",
    currentValue: 2.7,
    currentUnit: "A",
    powerValue: 0.58,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 05:14",
    currentValue: 2.8,
    currentUnit: "A",
    powerValue: 0.6,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 05:14",
    currentValue: 10.7,
    currentUnit: "A",
    powerValue: 2.27,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 05:14",
    currentValue: 72.5,
    currentUnit: "A",
    powerValue: 15.04,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 05:15",
    currentValue: 2.7,
    currentUnit: "A",
    powerValue: 0.58,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 05:15",
    currentValue: 2.8,
    currentUnit: "A",
    powerValue: 0.6,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 05:15",
    currentValue: 1,
    currentUnit: "A",
    powerValue: 0.21,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 05:15",
    currentValue: 43.4,
    currentUnit: "A",
    powerValue: 9.11,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 05:16",
    currentValue: 2.7,
    currentUnit: "A",
    powerValue: 0.58,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 05:16",
    currentValue: 2.8,
    currentUnit: "A",
    powerValue: 0.6,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 05:16",
    currentValue: 4.9,
    currentUnit: "A",
    powerValue: 1.04,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 05:16",
    currentValue: 33.8,
    currentUnit: "A",
    powerValue: 7.13,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 05:17",
    currentValue: 2.7,
    currentUnit: "A",
    powerValue: 0.58,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 05:17",
    currentValue: 4.2,
    currentUnit: "A",
    powerValue: 0.89,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 05:17",
    currentValue: 6.8,
    currentUnit: "A",
    powerValue: 1.44,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 05:17",
    currentValue: 30.9,
    currentUnit: "A",
    powerValue: 6.52,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 05:18",
    currentValue: 2.7,
    currentUnit: "A",
    powerValue: 0.58,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 05:18",
    currentValue: 2.8,
    currentUnit: "A",
    powerValue: 0.6,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 05:18",
    currentValue: 4.9,
    currentUnit: "A",
    powerValue: 1.04,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 05:18",
    currentValue: 29.1,
    currentUnit: "A",
    powerValue: 6.13,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 05:19",
    currentValue: 2.7,
    currentUnit: "A",
    powerValue: 0.58,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 05:19",
    currentValue: 2.8,
    currentUnit: "A",
    powerValue: 0.6,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 05:19",
    currentValue: 1,
    currentUnit: "A",
    powerValue: 0.21,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 05:19",
    currentValue: 26.6,
    currentUnit: "A",
    powerValue: 5.62,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 05:20",
    currentValue: 7.8,
    currentUnit: "A",
    powerValue: 1.66,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 05:20",
    currentValue: 2.8,
    currentUnit: "A",
    powerValue: 0.6,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 05:20",
    currentValue: 6.8,
    currentUnit: "A",
    powerValue: 1.44,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 05:20",
    currentValue: 26.5,
    currentUnit: "A",
    powerValue: 5.61,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 05:21",
    currentValue: 2.7,
    currentUnit: "A",
    powerValue: 0.58,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 05:21",
    currentValue: 2.8,
    currentUnit: "A",
    powerValue: 0.6,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 05:21",
    currentValue: 1,
    currentUnit: "A",
    powerValue: 0.21,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 05:21",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 05:22",
    currentValue: 2.7,
    currentUnit: "A",
    powerValue: 0.58,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 05:22",
    currentValue: 2.8,
    currentUnit: "A",
    powerValue: 0.6,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 05:22",
    currentValue: 1,
    currentUnit: "A",
    powerValue: 0.21,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 05:22",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 05:23",
    currentValue: 2.7,
    currentUnit: "A",
    powerValue: 0.58,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 05:23",
    currentValue: 7.8,
    currentUnit: "A",
    powerValue: 1.66,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 05:23",
    currentValue: 1.9,
    currentUnit: "A",
    powerValue: 0.4,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 05:23",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 05:24",
    currentValue: 2.7,
    currentUnit: "A",
    powerValue: 0.58,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 05:24",
    currentValue: 2.8,
    currentUnit: "A",
    powerValue: 0.6,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 05:24",
    currentValue: 1,
    currentUnit: "A",
    powerValue: 0.21,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 05:24",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 05:25",
    currentValue: 2.7,
    currentUnit: "A",
    powerValue: 0.58,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 05:25",
    currentValue: 2.8,
    currentUnit: "A",
    powerValue: 0.6,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 05:25",
    currentValue: 1,
    currentUnit: "A",
    powerValue: 0.21,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 05:25",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 05:26",
    currentValue: 2.7,
    currentUnit: "A",
    powerValue: 0.58,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 05:26",
    currentValue: 7.8,
    currentUnit: "A",
    powerValue: 1.66,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 05:26",
    currentValue: 1.7,
    currentUnit: "A",
    powerValue: 0.36,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 05:26",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 05:27",
    currentValue: 7.8,
    currentUnit: "A",
    powerValue: 1.66,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 05:27",
    currentValue: 2.7,
    currentUnit: "A",
    powerValue: 0.58,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 05:27",
    currentValue: 1,
    currentUnit: "A",
    powerValue: 0.21,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 05:27",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 05:28",
    currentValue: 2.7,
    currentUnit: "A",
    powerValue: 0.58,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 05:28",
    currentValue: 2.8,
    currentUnit: "A",
    powerValue: 0.6,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 05:28",
    currentValue: 1,
    currentUnit: "A",
    powerValue: 0.21,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 05:28",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 05:29",
    currentValue: 7.8,
    currentUnit: "A",
    powerValue: 1.66,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 05:29",
    currentValue: 7.8,
    currentUnit: "A",
    powerValue: 1.66,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 05:29",
    currentValue: 3.1,
    currentUnit: "A",
    powerValue: 0.66,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 05:29",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 05:30",
    currentValue: 2.7,
    currentUnit: "A",
    powerValue: 0.58,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 05:30",
    currentValue: 2.7,
    currentUnit: "A",
    powerValue: 0.58,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 05:30",
    currentValue: 1,
    currentUnit: "A",
    powerValue: 0.21,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 05:30",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 05:31",
    currentValue: 2.7,
    currentUnit: "A",
    powerValue: 0.58,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 05:31",
    currentValue: 59.5,
    currentUnit: "A",
    powerValue: 12.57,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 05:31",
    currentValue: 1.7,
    currentUnit: "A",
    powerValue: 0.36,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 05:31",
    currentValue: 38.3,
    currentUnit: "A",
    powerValue: 8.21,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 05:32",
    currentValue: 2.7,
    currentUnit: "A",
    powerValue: 0.58,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 05:32",
    currentValue: 48.2,
    currentUnit: "A",
    powerValue: 10.22,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 05:32",
    currentValue: 1.7,
    currentUnit: "A",
    powerValue: 0.36,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 05:32",
    currentValue: 36.8,
    currentUnit: "A",
    powerValue: 7.9,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 05:33",
    currentValue: 2.7,
    currentUnit: "A",
    powerValue: 0.58,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 05:33",
    currentValue: 37.3,
    currentUnit: "A",
    powerValue: 7.92,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 05:33",
    currentValue: 2.8,
    currentUnit: "A",
    powerValue: 0.59,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 05:33",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 05:34",
    currentValue: 2.7,
    currentUnit: "A",
    powerValue: 0.58,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 05:34",
    currentValue: 2.8,
    currentUnit: "A",
    powerValue: 0.6,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 05:34",
    currentValue: 1,
    currentUnit: "A",
    powerValue: 0.21,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 05:34",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 05:35",
    currentValue: 2.7,
    currentUnit: "A",
    powerValue: 0.58,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 05:35",
    currentValue: 7.8,
    currentUnit: "A",
    powerValue: 1.66,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 05:35",
    currentValue: 5.9,
    currentUnit: "A",
    powerValue: 1.26,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 05:35",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 05:36",
    currentValue: 7.8,
    currentUnit: "A",
    powerValue: 1.66,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 05:36",
    currentValue: 2.7,
    currentUnit: "A",
    powerValue: 0.58,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 05:36",
    currentValue: 43.4,
    currentUnit: "A",
    powerValue: 9.14,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 05:36",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 05:37",
    currentValue: 2.7,
    currentUnit: "A",
    powerValue: 0.58,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 05:37",
    currentValue: 2.8,
    currentUnit: "A",
    powerValue: 0.6,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 05:37",
    currentValue: 4.9,
    currentUnit: "A",
    powerValue: 1.04,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 05:37",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 05:38",
    currentValue: 7.8,
    currentUnit: "A",
    powerValue: 1.66,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 05:38",
    currentValue: 2.7,
    currentUnit: "A",
    powerValue: 0.58,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 05:38",
    currentValue: 3.1,
    currentUnit: "A",
    powerValue: 0.66,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 05:38",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 05:39",
    currentValue: 2.7,
    currentUnit: "A",
    powerValue: 0.58,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 05:39",
    currentValue: 2.8,
    currentUnit: "A",
    powerValue: 0.6,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 05:39",
    currentValue: 4.9,
    currentUnit: "A",
    powerValue: 1.04,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 05:39",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 05:40",
    currentValue: 2.7,
    currentUnit: "A",
    powerValue: 0.58,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 05:40",
    currentValue: 2.8,
    currentUnit: "A",
    powerValue: 0.6,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 05:40",
    currentValue: 1,
    currentUnit: "A",
    powerValue: 0.21,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 05:40",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 05:41",
    currentValue: 2.8,
    currentUnit: "A",
    powerValue: 0.6,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 05:41",
    currentValue: 2.7,
    currentUnit: "A",
    powerValue: 0.58,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 05:41",
    currentValue: 5.8,
    currentUnit: "A",
    powerValue: 1.23,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 05:41",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 05:42",
    currentValue: 2.8,
    currentUnit: "A",
    powerValue: 0.6,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 05:42",
    currentValue: 2.8,
    currentUnit: "A",
    powerValue: 0.6,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 05:42",
    currentValue: 1,
    currentUnit: "A",
    powerValue: 0.21,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 05:42",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 05:43",
    currentValue: 2.7,
    currentUnit: "A",
    powerValue: 0.58,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 05:43",
    currentValue: 2.8,
    currentUnit: "A",
    powerValue: 0.6,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 05:43",
    currentValue: 4.9,
    currentUnit: "A",
    powerValue: 1.04,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 05:43",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 05:44",
    currentValue: 2.8,
    currentUnit: "A",
    powerValue: 0.6,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 05:44",
    currentValue: 2.7,
    currentUnit: "A",
    powerValue: 0.58,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 05:44",
    currentValue: 1,
    currentUnit: "A",
    powerValue: 0.21,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 05:44",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 05:45",
    currentValue: 7.8,
    currentUnit: "A",
    powerValue: 1.66,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 05:45",
    currentValue: 2.8,
    currentUnit: "A",
    powerValue: 0.6,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 05:45",
    currentValue: 4.9,
    currentUnit: "A",
    powerValue: 1.04,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 05:45",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 05:46",
    currentValue: 2.7,
    currentUnit: "A",
    powerValue: 0.58,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 05:46",
    currentValue: 2.8,
    currentUnit: "A",
    powerValue: 0.6,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 05:46",
    currentValue: 1,
    currentUnit: "A",
    powerValue: 0.21,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 05:46",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 05:47",
    currentValue: 2.8,
    currentUnit: "A",
    powerValue: 0.6,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 05:47",
    currentValue: 2.7,
    currentUnit: "A",
    powerValue: 0.58,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 05:47",
    currentValue: 4.9,
    currentUnit: "A",
    powerValue: 1.04,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 05:47",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 05:48",
    currentValue: 2.7,
    currentUnit: "A",
    powerValue: 0.58,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 05:48",
    currentValue: 2.8,
    currentUnit: "A",
    powerValue: 0.6,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 05:48",
    currentValue: 1,
    currentUnit: "A",
    powerValue: 0.21,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 05:48",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 05:49",
    currentValue: 2.7,
    currentUnit: "A",
    powerValue: 0.58,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 05:49",
    currentValue: 2.8,
    currentUnit: "A",
    powerValue: 0.6,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 05:49",
    currentValue: 4.9,
    currentUnit: "A",
    powerValue: 1.04,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 05:49",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 05:50",
    currentValue: 2.8,
    currentUnit: "A",
    powerValue: 0.6,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 05:50",
    currentValue: 2.7,
    currentUnit: "A",
    powerValue: 0.58,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 05:50",
    currentValue: 2.1,
    currentUnit: "A",
    powerValue: 0.45,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 05:50",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 05:51",
    currentValue: 2.7,
    currentUnit: "A",
    powerValue: 0.58,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 05:51",
    currentValue: 2.7,
    currentUnit: "A",
    powerValue: 0.58,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 05:51",
    currentValue: 4.9,
    currentUnit: "A",
    powerValue: 1.04,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 05:51",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 05:52",
    currentValue: 2.7,
    currentUnit: "A",
    powerValue: 0.58,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 05:52",
    currentValue: 2.8,
    currentUnit: "A",
    powerValue: 0.6,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 05:52",
    currentValue: 1,
    currentUnit: "A",
    powerValue: 0.21,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 05:52",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 05:53",
    currentValue: 2.7,
    currentUnit: "A",
    powerValue: 0.58,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 05:53",
    currentValue: 2.8,
    currentUnit: "A",
    powerValue: 0.6,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 05:53",
    currentValue: 7.4,
    currentUnit: "A",
    powerValue: 1.58,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 05:53",
    currentValue: 34.7,
    currentUnit: "A",
    powerValue: 7.45,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 05:54",
    currentValue: 7.8,
    currentUnit: "A",
    powerValue: 1.66,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 05:54",
    currentValue: 37.3,
    currentUnit: "A",
    powerValue: 7.93,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 05:54",
    currentValue: 1,
    currentUnit: "A",
    powerValue: 0.21,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 05:54",
    currentValue: 68.7,
    currentUnit: "A",
    powerValue: 14.52,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 05:55",
    currentValue: 2.8,
    currentUnit: "A",
    powerValue: 0.6,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 05:55",
    currentValue: 2.8,
    currentUnit: "A",
    powerValue: 0.6,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 05:55",
    currentValue: 4.9,
    currentUnit: "A",
    powerValue: 1.04,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 05:55",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 05:56",
    currentValue: 2.8,
    currentUnit: "A",
    powerValue: 0.6,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 05:56",
    currentValue: 2.8,
    currentUnit: "A",
    powerValue: 0.6,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 05:56",
    currentValue: 1.8,
    currentUnit: "A",
    powerValue: 0.38,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 05:56",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 05:57",
    currentValue: 2.7,
    currentUnit: "A",
    powerValue: 0.58,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 05:57",
    currentValue: 2.8,
    currentUnit: "A",
    powerValue: 0.6,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 05:57",
    currentValue: 4.9,
    currentUnit: "A",
    powerValue: 1.04,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 05:57",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 05:58",
    currentValue: 2.7,
    currentUnit: "A",
    powerValue: 0.58,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 05:58",
    currentValue: 2.8,
    currentUnit: "A",
    powerValue: 0.6,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 05:58",
    currentValue: 1,
    currentUnit: "A",
    powerValue: 0.21,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 05:58",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 05:59",
    currentValue: 2.7,
    currentUnit: "A",
    powerValue: 0.58,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 05:59",
    currentValue: 2.8,
    currentUnit: "A",
    powerValue: 0.6,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 05:59",
    currentValue: 4.9,
    currentUnit: "A",
    powerValue: 1.04,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 05:59",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 06:00",
    currentValue: 2.7,
    currentUnit: "A",
    powerValue: 0.58,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 06:00",
    currentValue: 2.8,
    currentUnit: "A",
    powerValue: 0.6,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 06:00",
    currentValue: 1,
    currentUnit: "A",
    powerValue: 0.21,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 06:00",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 06:01",
    currentValue: 2.7,
    currentUnit: "A",
    powerValue: 0.58,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 06:01",
    currentValue: 7.8,
    currentUnit: "A",
    powerValue: 1.66,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 06:01",
    currentValue: 4.9,
    currentUnit: "A",
    powerValue: 1.04,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 06:01",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 06:02",
    currentValue: 2.8,
    currentUnit: "A",
    powerValue: 0.6,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 06:02",
    currentValue: 2.8,
    currentUnit: "A",
    powerValue: 0.6,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 06:02",
    currentValue: 1,
    currentUnit: "A",
    powerValue: 0.21,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 06:02",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 06:03",
    currentValue: 7.8,
    currentUnit: "A",
    powerValue: 1.66,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 06:03",
    currentValue: 2.8,
    currentUnit: "A",
    powerValue: 0.6,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 06:03",
    currentValue: 4.9,
    currentUnit: "A",
    powerValue: 1.04,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 06:03",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 06:04",
    currentValue: 2.8,
    currentUnit: "A",
    powerValue: 0.6,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 06:04",
    currentValue: 7.8,
    currentUnit: "A",
    powerValue: 1.66,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 06:04",
    currentValue: 1,
    currentUnit: "A",
    powerValue: 0.21,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 06:04",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 06:05",
    currentValue: 2.8,
    currentUnit: "A",
    powerValue: 0.6,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 06:05",
    currentValue: 2.8,
    currentUnit: "A",
    powerValue: 0.6,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 06:05",
    currentValue: 4.9,
    currentUnit: "A",
    powerValue: 1.04,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 06:05",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 06:06",
    currentValue: 2.7,
    currentUnit: "A",
    powerValue: 0.58,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 06:06",
    currentValue: 2.8,
    currentUnit: "A",
    powerValue: 0.6,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 06:06",
    currentValue: 1,
    currentUnit: "A",
    powerValue: 0.21,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 06:06",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 06:07",
    currentValue: 2.7,
    currentUnit: "A",
    powerValue: 0.58,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 06:07",
    currentValue: 7.8,
    currentUnit: "A",
    powerValue: 1.66,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 06:07",
    currentValue: 4.9,
    currentUnit: "A",
    powerValue: 1.04,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 06:07",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 06:08",
    currentValue: 2.7,
    currentUnit: "A",
    powerValue: 0.58,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 06:08",
    currentValue: 2.8,
    currentUnit: "A",
    powerValue: 0.6,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 06:08",
    currentValue: 1,
    currentUnit: "A",
    powerValue: 0.21,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 06:08",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 06:09",
    currentValue: 2.7,
    currentUnit: "A",
    powerValue: 0.58,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 06:09",
    currentValue: 2.8,
    currentUnit: "A",
    powerValue: 0.6,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 06:09",
    currentValue: 1,
    currentUnit: "A",
    powerValue: 0.21,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 06:09",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 06:10",
    currentValue: 2.7,
    currentUnit: "A",
    powerValue: 0.58,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 06:10",
    currentValue: 7.8,
    currentUnit: "A",
    powerValue: 1.66,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 06:10",
    currentValue: 1,
    currentUnit: "A",
    powerValue: 0.21,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 06:10",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 06:11",
    currentValue: 2.7,
    currentUnit: "A",
    powerValue: 0.58,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 06:11",
    currentValue: 2.8,
    currentUnit: "A",
    powerValue: 2.29,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 06:11",
    currentValue: 1.7,
    currentUnit: "A",
    powerValue: 0.36,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 06:11",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 06:12",
    currentValue: 7.8,
    currentUnit: "A",
    powerValue: 1.66,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 06:12",
    currentValue: 2.7,
    currentUnit: "A",
    powerValue: 0.58,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 06:12",
    currentValue: 1,
    currentUnit: "A",
    powerValue: 0.21,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 06:12",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 06:13",
    currentValue: 2.8,
    currentUnit: "A",
    powerValue: 0.6,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 06:13",
    currentValue: 7.8,
    currentUnit: "A",
    powerValue: 1.66,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 06:13",
    currentValue: 1,
    currentUnit: "A",
    powerValue: 0.21,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 06:13",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 06:14",
    currentValue: 2.8,
    currentUnit: "A",
    powerValue: 0.6,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 06:14",
    currentValue: 2.8,
    currentUnit: "A",
    powerValue: 0.6,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 06:14",
    currentValue: 4.7,
    currentUnit: "A",
    powerValue: 1,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 06:14",
    currentValue: 34.6,
    currentUnit: "A",
    powerValue: 7.45,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 06:15",
    currentValue: 2.8,
    currentUnit: "A",
    powerValue: 0.6,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 06:15",
    currentValue: 37.3,
    currentUnit: "A",
    powerValue: 7.94,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 06:15",
    currentValue: 1,
    currentUnit: "A",
    powerValue: 0.21,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 06:15",
    currentValue: 68.8,
    currentUnit: "A",
    powerValue: 14.56,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 06:16",
    currentValue: 2.7,
    currentUnit: "A",
    powerValue: 0.58,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 06:16",
    currentValue: 7.8,
    currentUnit: "A",
    powerValue: 1.66,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 06:16",
    currentValue: 1.7,
    currentUnit: "A",
    powerValue: 0.36,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 06:16",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 06:17",
    currentValue: 2.7,
    currentUnit: "A",
    powerValue: 0.58,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 06:17",
    currentValue: 2.8,
    currentUnit: "A",
    powerValue: 0.6,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 06:17",
    currentValue: 1,
    currentUnit: "A",
    powerValue: 0.21,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 06:17",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 06:18",
    currentValue: 2.8,
    currentUnit: "A",
    powerValue: 0.6,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 06:18",
    currentValue: 2.8,
    currentUnit: "A",
    powerValue: 0.6,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 06:18",
    currentValue: 1.8,
    currentUnit: "A",
    powerValue: 0.38,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 06:18",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 06:19",
    currentValue: 7.8,
    currentUnit: "A",
    powerValue: 1.66,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 06:19",
    currentValue: 7.8,
    currentUnit: "A",
    powerValue: 1.66,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 06:19",
    currentValue: 1,
    currentUnit: "A",
    powerValue: 0.21,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 06:19",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 06:20",
    currentValue: 2.7,
    currentUnit: "A",
    powerValue: 0.58,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 06:20",
    currentValue: 2.8,
    currentUnit: "A",
    powerValue: 0.6,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 06:20",
    currentValue: 1.8,
    currentUnit: "A",
    powerValue: 0.38,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 06:20",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 06:21",
    currentValue: 7.8,
    currentUnit: "A",
    powerValue: 1.66,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 06:21",
    currentValue: 2.8,
    currentUnit: "A",
    powerValue: 0.6,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 06:21",
    currentValue: 1,
    currentUnit: "A",
    powerValue: 0.21,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 06:21",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 06:22",
    currentValue: 2.8,
    currentUnit: "A",
    powerValue: 0.6,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 06:22",
    currentValue: 7.8,
    currentUnit: "A",
    powerValue: 1.66,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 06:22",
    currentValue: 1.8,
    currentUnit: "A",
    powerValue: 0.38,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 06:22",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 06:23",
    currentValue: 2.8,
    currentUnit: "A",
    powerValue: 0.6,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 06:23",
    currentValue: 2.8,
    currentUnit: "A",
    powerValue: 0.6,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 06:23",
    currentValue: 1,
    currentUnit: "A",
    powerValue: 0.21,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 06:23",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 06:24",
    currentValue: 2.7,
    currentUnit: "A",
    powerValue: 0.58,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 06:24",
    currentValue: 2.8,
    currentUnit: "A",
    powerValue: 0.6,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 06:24",
    currentValue: 44.5,
    currentUnit: "A",
    powerValue: 9.4,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 06:24",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 06:25",
    currentValue: 2.8,
    currentUnit: "A",
    powerValue: 0.6,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 06:25",
    currentValue: 7.8,
    currentUnit: "A",
    powerValue: 1.66,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 06:25",
    currentValue: 1,
    currentUnit: "A",
    powerValue: 0.21,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 06:25",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 06:26",
    currentValue: 2.7,
    currentUnit: "A",
    powerValue: 0.58,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 06:26",
    currentValue: 2.8,
    currentUnit: "A",
    powerValue: 0.6,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 06:26",
    currentValue: 3.2,
    currentUnit: "A",
    powerValue: 0.68,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 06:26",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 06:27",
    currentValue: 2.7,
    currentUnit: "A",
    powerValue: 0.58,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 06:27",
    currentValue: 2.8,
    currentUnit: "A",
    powerValue: 0.6,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 06:27",
    currentValue: 1,
    currentUnit: "A",
    powerValue: 0.21,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 06:27",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 06:28",
    currentValue: 7.8,
    currentUnit: "A",
    powerValue: 1.66,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 06:28",
    currentValue: 4,
    currentUnit: "A",
    powerValue: 0.86,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 06:28",
    currentValue: 3.4,
    currentUnit: "A",
    powerValue: 0.72,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 06:28",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 06:29",
    currentValue: 56,
    currentUnit: "A",
    powerValue: 11.97,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 06:29",
    currentValue: 2.7,
    currentUnit: "A",
    powerValue: 0.58,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 06:29",
    currentValue: 1,
    currentUnit: "A",
    powerValue: 0.21,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 06:29",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 06:30",
    currentValue: 58.6,
    currentUnit: "A",
    powerValue: 12.52,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 06:30",
    currentValue: 2.8,
    currentUnit: "A",
    powerValue: 0.6,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 06:30",
    currentValue: 4.1,
    currentUnit: "A",
    powerValue: 0.87,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 06:30",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 06:31",
    currentValue: 44.5,
    currentUnit: "A",
    powerValue: 9.53,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 06:31",
    currentValue: 2.7,
    currentUnit: "A",
    powerValue: 0.58,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 06:31",
    currentValue: 1,
    currentUnit: "A",
    powerValue: 0.21,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 06:31",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 06:32",
    currentValue: 38.6,
    currentUnit: "A",
    powerValue: 8.27,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 06:32",
    currentValue: 2.8,
    currentUnit: "A",
    powerValue: 0.6,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 06:32",
    currentValue: 4.9,
    currentUnit: "A",
    powerValue: 1.04,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 06:32",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 06:33",
    currentValue: 38.3,
    currentUnit: "A",
    powerValue: 8.2,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 06:33",
    currentValue: 2.8,
    currentUnit: "A",
    powerValue: 0.6,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 06:33",
    currentValue: 1,
    currentUnit: "A",
    powerValue: 0.21,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 06:33",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 06:34",
    currentValue: 37.9,
    currentUnit: "A",
    powerValue: 8.12,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 06:34",
    currentValue: 2.7,
    currentUnit: "A",
    powerValue: 0.58,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 06:34",
    currentValue: 4.9,
    currentUnit: "A",
    powerValue: 1.04,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 06:34",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 06:35",
    currentValue: 42.6,
    currentUnit: "A",
    powerValue: 9.11,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 06:35",
    currentValue: 2.8,
    currentUnit: "A",
    powerValue: 0.6,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 06:35",
    currentValue: 1,
    currentUnit: "A",
    powerValue: 0.21,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 06:35",
    currentValue: 43.5,
    currentUnit: "A",
    powerValue: 9.33,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 06:36",
    currentValue: 36.7,
    currentUnit: "A",
    powerValue: 7.88,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 06:36",
    currentValue: 37.4,
    currentUnit: "A",
    powerValue: 7.95,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 06:36",
    currentValue: 4.9,
    currentUnit: "A",
    powerValue: 1.04,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 06:36",
    currentValue: 68.4,
    currentUnit: "A",
    powerValue: 14.46,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 06:37",
    currentValue: 40.9,
    currentUnit: "A",
    powerValue: 8.77,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 06:37",
    currentValue: 2.7,
    currentUnit: "A",
    powerValue: 0.58,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 06:37",
    currentValue: 1,
    currentUnit: "A",
    powerValue: 0.21,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 06:37",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 06:38",
    currentValue: 30.5,
    currentUnit: "A",
    powerValue: 6.54,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 06:38",
    currentValue: 2.8,
    currentUnit: "A",
    powerValue: 0.6,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 06:38",
    currentValue: 4.9,
    currentUnit: "A",
    powerValue: 1.04,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 06:38",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 06:39",
    currentValue: 29.9,
    currentUnit: "A",
    powerValue: 6.41,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 06:39",
    currentValue: 2.8,
    currentUnit: "A",
    powerValue: 0.6,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 06:39",
    currentValue: 1,
    currentUnit: "A",
    powerValue: 0.21,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 06:39",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 06:40",
    currentValue: 29.7,
    currentUnit: "A",
    powerValue: 6.37,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 06:40",
    currentValue: 2.7,
    currentUnit: "A",
    powerValue: 0.58,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 06:40",
    currentValue: 4.9,
    currentUnit: "A",
    powerValue: 1.04,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 06:40",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 06:41",
    currentValue: 29.3,
    currentUnit: "A",
    powerValue: 6.29,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 06:41",
    currentValue: 2.8,
    currentUnit: "A",
    powerValue: 0.6,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 06:41",
    currentValue: 1,
    currentUnit: "A",
    powerValue: 0.21,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 06:41",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 06:42",
    currentValue: 2.7,
    currentUnit: "A",
    powerValue: 0.58,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 06:42",
    currentValue: 2.8,
    currentUnit: "A",
    powerValue: 0.6,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 06:42",
    currentValue: 10.7,
    currentUnit: "A",
    powerValue: 2.28,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 06:42",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 06:43",
    currentValue: 2.7,
    currentUnit: "A",
    powerValue: 0.58,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 06:43",
    currentValue: 2.8,
    currentUnit: "A",
    powerValue: 0.6,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 06:43",
    currentValue: 1,
    currentUnit: "A",
    powerValue: 0.21,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 06:43",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 06:44",
    currentValue: 7.8,
    currentUnit: "A",
    powerValue: 1.66,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 06:44",
    currentValue: 2.8,
    currentUnit: "A",
    powerValue: 0.6,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 06:44",
    currentValue: 4.9,
    currentUnit: "A",
    powerValue: 1.04,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 06:44",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 06:45",
    currentValue: 2.7,
    currentUnit: "A",
    powerValue: 0.58,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 06:45",
    currentValue: 2.8,
    currentUnit: "A",
    powerValue: 0.6,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 06:45",
    currentValue: 6.8,
    currentUnit: "A",
    powerValue: 1.45,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 06:45",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 06:46",
    currentValue: 6.3,
    currentUnit: "A",
    powerValue: 1.34,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 06:46",
    currentValue: 2.7,
    currentUnit: "A",
    powerValue: 0.58,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 06:46",
    currentValue: 4.9,
    currentUnit: "A",
    powerValue: 1.04,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 06:46",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 06:47",
    currentValue: 2.7,
    currentUnit: "A",
    powerValue: 0.58,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 06:47",
    currentValue: 2.8,
    currentUnit: "A",
    powerValue: 0.6,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 06:47",
    currentValue: 1,
    currentUnit: "A",
    powerValue: 0.21,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 06:47",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 06:48",
    currentValue: 2.7,
    currentUnit: "A",
    powerValue: 0.58,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 06:48",
    currentValue: 2.7,
    currentUnit: "A",
    powerValue: 0.58,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 06:48",
    currentValue: 1,
    currentUnit: "A",
    powerValue: 0.21,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 06:48",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 06:49",
    currentValue: 2.7,
    currentUnit: "A",
    powerValue: 0.58,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 06:49",
    currentValue: 2.7,
    currentUnit: "A",
    powerValue: 0.58,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 06:49",
    currentValue: 1,
    currentUnit: "A",
    powerValue: 0.21,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 06:49",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 06:50",
    currentValue: 2.7,
    currentUnit: "A",
    powerValue: 0.58,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 06:50",
    currentValue: 2.8,
    currentUnit: "A",
    powerValue: 0.6,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 06:50",
    currentValue: 1,
    currentUnit: "A",
    powerValue: 0.21,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 06:50",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 06:51",
    currentValue: 2.7,
    currentUnit: "A",
    powerValue: 0.58,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 06:51",
    currentValue: 2.7,
    currentUnit: "A",
    powerValue: 0.58,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 06:51",
    currentValue: 6.8,
    currentUnit: "A",
    powerValue: 1.45,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 06:51",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 06:52",
    currentValue: 2.7,
    currentUnit: "A",
    powerValue: 0.58,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 06:52",
    currentValue: 2.7,
    currentUnit: "A",
    powerValue: 0.58,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 06:52",
    currentValue: 1,
    currentUnit: "A",
    powerValue: 0.21,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 06:52",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 06:53",
    currentValue: 7.8,
    currentUnit: "A",
    powerValue: 1.66,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 06:53",
    currentValue: 2.8,
    currentUnit: "A",
    powerValue: 0.6,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 06:53",
    currentValue: 1,
    currentUnit: "A",
    powerValue: 0.21,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 06:53",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 06:54",
    currentValue: 2.7,
    currentUnit: "A",
    powerValue: 0.58,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 06:54",
    currentValue: 7.8,
    currentUnit: "A",
    powerValue: 1.66,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 06:54",
    currentValue: 1,
    currentUnit: "A",
    powerValue: 0.21,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 06:54",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 06:55",
    currentValue: 2.7,
    currentUnit: "A",
    powerValue: 0.58,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 06:55",
    currentValue: 2.8,
    currentUnit: "A",
    powerValue: 2.29,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 06:55",
    currentValue: 1,
    currentUnit: "A",
    powerValue: 0.21,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 06:55",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 06:56",
    currentValue: 2.7,
    currentUnit: "A",
    powerValue: 0.58,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 06:56",
    currentValue: 2.8,
    currentUnit: "A",
    powerValue: 0.6,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 06:56",
    currentValue: 1,
    currentUnit: "A",
    powerValue: 0.21,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 06:56",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 06:57",
    currentValue: 2.7,
    currentUnit: "A",
    powerValue: 0.58,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 06:57",
    currentValue: 41.8,
    currentUnit: "A",
    powerValue: 8.9,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 06:57",
    currentValue: 1,
    currentUnit: "A",
    powerValue: 0.21,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 06:57",
    currentValue: 89.5,
    currentUnit: "A",
    powerValue: 19.03,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 06:58",
    currentValue: 2.7,
    currentUnit: "A",
    powerValue: 0.58,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 06:58",
    currentValue: 2.8,
    currentUnit: "A",
    powerValue: 0.6,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 06:58",
    currentValue: 1,
    currentUnit: "A",
    powerValue: 0.21,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 06:58",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 06:59",
    currentValue: 2.7,
    currentUnit: "A",
    powerValue: 0.58,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 06:59",
    currentValue: 2.7,
    currentUnit: "A",
    powerValue: 0.58,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 06:59",
    currentValue: 1,
    currentUnit: "A",
    powerValue: 0.21,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 06:59",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 07:00",
    currentValue: 7.8,
    currentUnit: "A",
    powerValue: 1.66,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 07:00",
    currentValue: 7.8,
    currentUnit: "A",
    powerValue: 1.66,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 07:00",
    currentValue: 1,
    currentUnit: "A",
    powerValue: 0.21,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 07:00",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 07:01",
    currentValue: 2.7,
    currentUnit: "A",
    powerValue: 0.58,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 07:01",
    currentValue: 2.7,
    currentUnit: "A",
    powerValue: 0.58,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 07:01",
    currentValue: 1,
    currentUnit: "A",
    powerValue: 0.21,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 07:01",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 07:02",
    currentValue: 2.8,
    currentUnit: "A",
    powerValue: 0.6,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 07:02",
    currentValue: 2.7,
    currentUnit: "A",
    powerValue: 0.58,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 07:02",
    currentValue: 1,
    currentUnit: "A",
    powerValue: 0.21,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 07:02",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 07:03",
    currentValue: 2.7,
    currentUnit: "A",
    powerValue: 0.58,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 07:03",
    currentValue: 7.8,
    currentUnit: "A",
    powerValue: 1.66,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 07:03",
    currentValue: 1,
    currentUnit: "A",
    powerValue: 0.21,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 07:03",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 07:04",
    currentValue: 2.7,
    currentUnit: "A",
    powerValue: 0.58,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 07:04",
    currentValue: 2.8,
    currentUnit: "A",
    powerValue: 0.6,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 07:04",
    currentValue: 1,
    currentUnit: "A",
    powerValue: 0.21,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 07:04",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 07:05",
    currentValue: 2.7,
    currentUnit: "A",
    powerValue: 0.58,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 07:05",
    currentValue: 2.7,
    currentUnit: "A",
    powerValue: 0.58,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 07:05",
    currentValue: 1,
    currentUnit: "A",
    powerValue: 0.21,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 07:05",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 07:06",
    currentValue: 2.7,
    currentUnit: "A",
    powerValue: 0.58,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 07:06",
    currentValue: 7.8,
    currentUnit: "A",
    powerValue: 1.66,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 07:06",
    currentValue: 1,
    currentUnit: "A",
    powerValue: 0.21,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 07:06",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 07:07",
    currentValue: 7.8,
    currentUnit: "A",
    powerValue: 1.66,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 07:07",
    currentValue: 2.8,
    currentUnit: "A",
    powerValue: 0.6,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 07:07",
    currentValue: 1,
    currentUnit: "A",
    powerValue: 0.21,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 07:07",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 07:08",
    currentValue: 2.7,
    currentUnit: "A",
    powerValue: 0.58,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 07:08",
    currentValue: 2.8,
    currentUnit: "A",
    powerValue: 0.6,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 07:08",
    currentValue: 1,
    currentUnit: "A",
    powerValue: 0.21,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 07:08",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 07:09",
    currentValue: 1.8,
    currentUnit: "A",
    powerValue: 0.39,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 07:09",
    currentValue: 7.8,
    currentUnit: "A",
    powerValue: 1.66,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 07:09",
    currentValue: 1,
    currentUnit: "A",
    powerValue: 0.21,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 07:09",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 07:10",
    currentValue: 1.7,
    currentUnit: "A",
    powerValue: 0.36,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 07:10",
    currentValue: 2.8,
    currentUnit: "A",
    powerValue: 0.6,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 07:10",
    currentValue: 1,
    currentUnit: "A",
    powerValue: 0.21,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 07:10",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 07:11",
    currentValue: 1.7,
    currentUnit: "A",
    powerValue: 0.36,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 07:11",
    currentValue: 2.8,
    currentUnit: "A",
    powerValue: 0.6,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 07:11",
    currentValue: 1,
    currentUnit: "A",
    powerValue: 0.21,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 07:11",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 07:12",
    currentValue: 1.7,
    currentUnit: "A",
    powerValue: 0.36,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 07:12",
    currentValue: 7.8,
    currentUnit: "A",
    powerValue: 1.66,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 07:12",
    currentValue: 44.3,
    currentUnit: "A",
    powerValue: 9.35,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 07:12",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 07:13",
    currentValue: 1.8,
    currentUnit: "A",
    powerValue: 0.39,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 07:13",
    currentValue: 2.8,
    currentUnit: "A",
    powerValue: 0.6,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 07:13",
    currentValue: 1,
    currentUnit: "A",
    powerValue: 0.21,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 07:13",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 07:14",
    currentValue: 6.8,
    currentUnit: "A",
    powerValue: 1.45,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 07:14",
    currentValue: 2.8,
    currentUnit: "A",
    powerValue: 0.6,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 07:14",
    currentValue: 1,
    currentUnit: "A",
    powerValue: 0.21,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 07:14",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 07:15",
    currentValue: 1.7,
    currentUnit: "A",
    powerValue: 0.36,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 07:15",
    currentValue: 7.8,
    currentUnit: "A",
    powerValue: 1.66,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 07:15",
    currentValue: 1,
    currentUnit: "A",
    powerValue: 0.21,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 07:15",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 07:16",
    currentValue: 6.8,
    currentUnit: "A",
    powerValue: 1.45,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 07:16",
    currentValue: 2.8,
    currentUnit: "A",
    powerValue: 0.6,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 07:16",
    currentValue: 1,
    currentUnit: "A",
    powerValue: 0.21,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 07:16",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 07:17",
    currentValue: 1.7,
    currentUnit: "A",
    powerValue: 0.36,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 07:17",
    currentValue: 2.7,
    currentUnit: "A",
    powerValue: 0.58,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 07:17",
    currentValue: 1,
    currentUnit: "A",
    powerValue: 0.21,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 07:17",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 07:18",
    currentValue: 1.8,
    currentUnit: "A",
    powerValue: 0.39,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 07:18",
    currentValue: 43.4,
    currentUnit: "A",
    powerValue: 9.24,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 07:18",
    currentValue: 1,
    currentUnit: "A",
    powerValue: 0.21,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 07:18",
    currentValue: 26.8,
    currentUnit: "A",
    powerValue: 5.77,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 07:19",
    currentValue: 1.7,
    currentUnit: "A",
    powerValue: 0.36,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 07:19",
    currentValue: 71.7,
    currentUnit: "A",
    powerValue: 15.34,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 07:19",
    currentValue: 1,
    currentUnit: "A",
    powerValue: 0.21,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 07:19",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 07:20",
    currentValue: 1.7,
    currentUnit: "A",
    powerValue: 0.36,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 07:20",
    currentValue: 72.5,
    currentUnit: "A",
    powerValue: 15.5,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 07:20",
    currentValue: 1,
    currentUnit: "A",
    powerValue: 0.21,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 07:20",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 07:21",
    currentValue: 1.7,
    currentUnit: "A",
    powerValue: 0.36,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 07:21",
    currentValue: 7.8,
    currentUnit: "A",
    powerValue: 1.66,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 07:21",
    currentValue: 6.8,
    currentUnit: "A",
    powerValue: 1.45,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 07:21",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 07:22",
    currentValue: 1.7,
    currentUnit: "A",
    powerValue: 0.36,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 07:22",
    currentValue: 2.8,
    currentUnit: "A",
    powerValue: 0.6,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 07:22",
    currentValue: 1,
    currentUnit: "A",
    powerValue: 0.21,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 07:22",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 07:23",
    currentValue: 6.8,
    currentUnit: "A",
    powerValue: 1.45,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 07:23",
    currentValue: 2.8,
    currentUnit: "A",
    powerValue: 0.6,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 07:23",
    currentValue: 1,
    currentUnit: "A",
    powerValue: 0.21,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 07:23",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 07:24",
    currentValue: 1.8,
    currentUnit: "A",
    powerValue: 0.39,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 07:24",
    currentValue: 7.8,
    currentUnit: "A",
    powerValue: 1.66,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 07:24",
    currentValue: 6.8,
    currentUnit: "A",
    powerValue: 1.45,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 07:24",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 07:25",
    currentValue: 1.8,
    currentUnit: "A",
    powerValue: 0.39,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 07:25",
    currentValue: 2.8,
    currentUnit: "A",
    powerValue: 0.6,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 07:25",
    currentValue: 1,
    currentUnit: "A",
    powerValue: 0.21,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 07:25",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 07:26",
    currentValue: 1.7,
    currentUnit: "A",
    powerValue: 0.36,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 07:26",
    currentValue: 2.8,
    currentUnit: "A",
    powerValue: 0.6,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 07:26",
    currentValue: 1,
    currentUnit: "A",
    powerValue: 0.21,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 07:26",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 07:27",
    currentValue: 1.8,
    currentUnit: "A",
    powerValue: 0.39,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 07:27",
    currentValue: 7.8,
    currentUnit: "A",
    powerValue: 1.66,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 07:27",
    currentValue: 6.8,
    currentUnit: "A",
    powerValue: 1.45,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 07:27",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 07:28",
    currentValue: 1.8,
    currentUnit: "A",
    powerValue: 0.39,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 07:28",
    currentValue: 2.8,
    currentUnit: "A",
    powerValue: 0.6,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 07:28",
    currentValue: 1,
    currentUnit: "A",
    powerValue: 0.21,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 07:28",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 07:29",
    currentValue: 1.7,
    currentUnit: "A",
    powerValue: 0.36,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 07:29",
    currentValue: 2.8,
    currentUnit: "A",
    powerValue: 0.6,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 07:29",
    currentValue: 1,
    currentUnit: "A",
    powerValue: 0.21,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 07:29",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 07:30",
    currentValue: 1.7,
    currentUnit: "A",
    powerValue: 0.36,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 07:30",
    currentValue: 7.8,
    currentUnit: "A",
    powerValue: 1.66,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 07:30",
    currentValue: 6.8,
    currentUnit: "A",
    powerValue: 1.45,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 07:30",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 07:31",
    currentValue: 1.8,
    currentUnit: "A",
    powerValue: 0.39,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 07:31",
    currentValue: 2.8,
    currentUnit: "A",
    powerValue: 0.6,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 07:31",
    currentValue: 1,
    currentUnit: "A",
    powerValue: 0.21,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 07:31",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 07:32",
    currentValue: 6.8,
    currentUnit: "A",
    powerValue: 1.45,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 07:32",
    currentValue: 2.8,
    currentUnit: "A",
    powerValue: 0.6,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 07:32",
    currentValue: 1,
    currentUnit: "A",
    powerValue: 0.21,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 07:32",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 07:33",
    currentValue: 1.7,
    currentUnit: "A",
    powerValue: 0.36,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 07:33",
    currentValue: 7.8,
    currentUnit: "A",
    powerValue: 1.66,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 07:33",
    currentValue: 7.6,
    currentUnit: "A",
    powerValue: 1.62,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 07:33",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 07:34",
    currentValue: 1.7,
    currentUnit: "A",
    powerValue: 0.36,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 07:34",
    currentValue: 2.7,
    currentUnit: "A",
    powerValue: 0.58,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 07:34",
    currentValue: 1,
    currentUnit: "A",
    powerValue: 0.21,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 07:34",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 07:35",
    currentValue: 1.7,
    currentUnit: "A",
    powerValue: 0.37,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 07:35",
    currentValue: 2.8,
    currentUnit: "A",
    powerValue: 0.6,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 07:35",
    currentValue: 4.9,
    currentUnit: "A",
    powerValue: 1.05,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 07:35",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 07:36",
    currentValue: 1.7,
    currentUnit: "A",
    powerValue: 0.36,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 07:36",
    currentValue: 3.3,
    currentUnit: "A",
    powerValue: 0.71,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 07:36",
    currentValue: 1.7,
    currentUnit: "A",
    powerValue: 0.36,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 07:36",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 07:37",
    currentValue: 1.7,
    currentUnit: "A",
    powerValue: 0.36,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 07:37",
    currentValue: 2.7,
    currentUnit: "A",
    powerValue: 0.58,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 07:37",
    currentValue: 4.9,
    currentUnit: "A",
    powerValue: 1.04,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 07:37",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 07:38",
    currentValue: 1.7,
    currentUnit: "A",
    powerValue: 0.36,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 07:38",
    currentValue: 2.8,
    currentUnit: "A",
    powerValue: 0.6,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 07:38",
    currentValue: 1,
    currentUnit: "A",
    powerValue: 0.21,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 07:38",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 07:39",
    currentValue: 6.8,
    currentUnit: "A",
    powerValue: 1.45,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 07:39",
    currentValue: 50.5,
    currentUnit: "A",
    powerValue: 10.75,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 07:39",
    currentValue: 5.8,
    currentUnit: "A",
    powerValue: 1.24,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 07:39",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 07:40",
    currentValue: 1.7,
    currentUnit: "A",
    powerValue: 0.36,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 07:40",
    currentValue: 2.8,
    currentUnit: "A",
    powerValue: 0.6,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 07:40",
    currentValue: 1,
    currentUnit: "A",
    powerValue: 0.21,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 07:40",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 07:41",
    currentValue: 1.8,
    currentUnit: "A",
    powerValue: 0.39,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 07:41",
    currentValue: 71.5,
    currentUnit: "A",
    powerValue: 15.29,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 07:41",
    currentValue: 4.9,
    currentUnit: "A",
    powerValue: 1.04,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 07:41",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 07:42",
    currentValue: 1.7,
    currentUnit: "A",
    powerValue: 0.36,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 07:42",
    currentValue: 3.3,
    currentUnit: "A",
    powerValue: 0.71,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 07:42",
    currentValue: 1.8,
    currentUnit: "A",
    powerValue: 0.38,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 07:42",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 07:43",
    currentValue: 1.8,
    currentUnit: "A",
    powerValue: 0.39,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 07:43",
    currentValue: 2.8,
    currentUnit: "A",
    powerValue: 0.6,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 07:43",
    currentValue: 4.9,
    currentUnit: "A",
    powerValue: 1.04,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 07:43",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 07:44",
    currentValue: 1.7,
    currentUnit: "A",
    powerValue: 0.36,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 07:44",
    currentValue: 2.8,
    currentUnit: "A",
    powerValue: 0.6,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 07:44",
    currentValue: 1,
    currentUnit: "A",
    powerValue: 0.21,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 07:44",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 07:45",
    currentValue: 1.7,
    currentUnit: "A",
    powerValue: 0.36,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 07:45",
    currentValue: 2.7,
    currentUnit: "A",
    powerValue: 0.58,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 07:45",
    currentValue: 5.8,
    currentUnit: "A",
    powerValue: 1.24,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 07:45",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 07:46",
    currentValue: 6.8,
    currentUnit: "A",
    powerValue: 1.45,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 07:46",
    currentValue: 2.8,
    currentUnit: "A",
    powerValue: 0.6,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 07:46",
    currentValue: 1,
    currentUnit: "A",
    powerValue: 0.21,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 07:46",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 07:47",
    currentValue: 1.8,
    currentUnit: "A",
    powerValue: 0.39,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 07:47",
    currentValue: 2.8,
    currentUnit: "A",
    powerValue: 0.6,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 07:47",
    currentValue: 4.9,
    currentUnit: "A",
    powerValue: 1.04,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 07:47",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 07:48",
    currentValue: 6.8,
    currentUnit: "A",
    powerValue: 1.45,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 07:48",
    currentValue: 2.7,
    currentUnit: "A",
    powerValue: 0.58,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 07:48",
    currentValue: 1.8,
    currentUnit: "A",
    powerValue: 0.38,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 07:48",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 07:49",
    currentValue: 1.7,
    currentUnit: "A",
    powerValue: 0.36,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 07:49",
    currentValue: 2.8,
    currentUnit: "A",
    powerValue: 0.6,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 07:49",
    currentValue: 4.9,
    currentUnit: "A",
    powerValue: 1.05,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 07:49",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 07:50",
    currentValue: 1.7,
    currentUnit: "A",
    powerValue: 0.36,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 07:50",
    currentValue: 2.8,
    currentUnit: "A",
    powerValue: 0.6,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 07:50",
    currentValue: 1,
    currentUnit: "A",
    powerValue: 0.21,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 07:50",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 07:51",
    currentValue: 1.7,
    currentUnit: "A",
    powerValue: 0.36,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 07:51",
    currentValue: 2.7,
    currentUnit: "A",
    powerValue: 0.58,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 07:51",
    currentValue: 4.9,
    currentUnit: "A",
    powerValue: 1.05,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 07:51",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 07:52",
    currentValue: 1.8,
    currentUnit: "A",
    powerValue: 0.39,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 07:52",
    currentValue: 2.8,
    currentUnit: "A",
    powerValue: 0.6,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 07:52",
    currentValue: 1,
    currentUnit: "A",
    powerValue: 0.21,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 07:52",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 07:53",
    currentValue: 1.7,
    currentUnit: "A",
    powerValue: 0.37,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 07:53",
    currentValue: 2.8,
    currentUnit: "A",
    powerValue: 0.6,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 07:53",
    currentValue: 4.9,
    currentUnit: "A",
    powerValue: 1.04,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 07:53",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 07:54",
    currentValue: 1.8,
    currentUnit: "A",
    powerValue: 0.39,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 07:54",
    currentValue: 2.7,
    currentUnit: "A",
    powerValue: 0.58,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 07:54",
    currentValue: 1.7,
    currentUnit: "A",
    powerValue: 0.36,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 07:54",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 07:55",
    currentValue: 6.8,
    currentUnit: "A",
    powerValue: 1.45,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 07:55",
    currentValue: 2.8,
    currentUnit: "A",
    powerValue: 0.6,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 07:55",
    currentValue: 4.9,
    currentUnit: "A",
    powerValue: 1.05,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 07:55",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 07:56",
    currentValue: 1.7,
    currentUnit: "A",
    powerValue: 0.37,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 07:56",
    currentValue: 2.7,
    currentUnit: "A",
    powerValue: 0.58,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 07:56",
    currentValue: 1,
    currentUnit: "A",
    powerValue: 0.21,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 07:56",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 07:57",
    currentValue: 1.8,
    currentUnit: "A",
    powerValue: 0.39,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 07:57",
    currentValue: 2.7,
    currentUnit: "A",
    powerValue: 0.58,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 07:57",
    currentValue: 5.6,
    currentUnit: "A",
    powerValue: 1.2,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 07:57",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 07:58",
    currentValue: 1.7,
    currentUnit: "A",
    powerValue: 0.37,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 07:58",
    currentValue: 2.8,
    currentUnit: "A",
    powerValue: 0.6,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 07:58",
    currentValue: 43.3,
    currentUnit: "A",
    powerValue: 9.17,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 07:58",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 07:59",
    currentValue: 1.8,
    currentUnit: "A",
    powerValue: 0.39,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 07:59",
    currentValue: 2.8,
    currentUnit: "A",
    powerValue: 0.6,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 07:59",
    currentValue: 4.9,
    currentUnit: "A",
    powerValue: 1.05,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 07:59",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 08:00",
    currentValue: 1.7,
    currentUnit: "A",
    powerValue: 0.37,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 08:00",
    currentValue: 2.7,
    currentUnit: "A",
    powerValue: 0.58,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 08:00",
    currentValue: 1.8,
    currentUnit: "A",
    powerValue: 0.38,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 08:00",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 08:01",
    currentValue: 1.8,
    currentUnit: "A",
    powerValue: 0.39,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 08:01",
    currentValue: 2.8,
    currentUnit: "A",
    powerValue: 0.6,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 08:01",
    currentValue: 4.9,
    currentUnit: "A",
    powerValue: 1.05,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 08:01",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 08:02",
    currentValue: 6.8,
    currentUnit: "A",
    powerValue: 1.45,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 08:02",
    currentValue: 2.8,
    currentUnit: "A",
    powerValue: 0.6,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 08:02",
    currentValue: 1,
    currentUnit: "A",
    powerValue: 0.21,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 08:02",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 08:03",
    currentValue: 1.8,
    currentUnit: "A",
    powerValue: 0.39,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 08:03",
    currentValue: 65.2,
    currentUnit: "A",
    powerValue: 13.98,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 08:03",
    currentValue: 1.7,
    currentUnit: "A",
    powerValue: 0.36,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 08:03",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 08:04",
    currentValue: 7.7,
    currentUnit: "A",
    powerValue: 1.64,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 08:04",
    currentValue: 2.8,
    currentUnit: "A",
    powerValue: 0.6,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 08:04",
    currentValue: 1,
    currentUnit: "A",
    powerValue: 0.21,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 08:04",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 08:05",
    currentValue: 1.7,
    currentUnit: "A",
    powerValue: 0.37,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 08:05",
    currentValue: 2.8,
    currentUnit: "A",
    powerValue: 0.6,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 08:05",
    currentValue: 1,
    currentUnit: "A",
    powerValue: 0.21,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 08:05",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 08:06",
    currentValue: 1.8,
    currentUnit: "A",
    powerValue: 0.39,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 08:06",
    currentValue: 2.7,
    currentUnit: "A",
    powerValue: 0.58,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 08:06",
    currentValue: 1.7,
    currentUnit: "A",
    powerValue: 0.36,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 08:06",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 08:07",
    currentValue: 1.7,
    currentUnit: "A",
    powerValue: 0.37,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 08:07",
    currentValue: 2.8,
    currentUnit: "A",
    powerValue: 0.6,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 08:07",
    currentValue: 1,
    currentUnit: "A",
    powerValue: 0.21,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 08:07",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 08:08",
    currentValue: 1.8,
    currentUnit: "A",
    powerValue: 0.39,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 08:08",
    currentValue: 2.8,
    currentUnit: "A",
    powerValue: 0.6,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 08:08",
    currentValue: 1,
    currentUnit: "A",
    powerValue: 0.21,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 08:08",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 08:09",
    currentValue: 6.8,
    currentUnit: "A",
    powerValue: 1.45,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 08:09",
    currentValue: 2.7,
    currentUnit: "A",
    powerValue: 0.58,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 08:09",
    currentValue: 1.9,
    currentUnit: "A",
    powerValue: 0.41,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 08:09",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 08:10",
    currentValue: 1.7,
    currentUnit: "A",
    powerValue: 0.37,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 08:10",
    currentValue: 2.8,
    currentUnit: "A",
    powerValue: 0.6,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 08:10",
    currentValue: 1,
    currentUnit: "A",
    powerValue: 0.21,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 08:10",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 08:11",
    currentValue: 1.8,
    currentUnit: "A",
    powerValue: 0.39,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 08:11",
    currentValue: 2.8,
    currentUnit: "A",
    powerValue: 0.6,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 08:11",
    currentValue: 1,
    currentUnit: "A",
    powerValue: 0.21,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 08:11",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 08:12",
    currentValue: 1.8,
    currentUnit: "A",
    powerValue: 0.39,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 08:12",
    currentValue: 2.8,
    currentUnit: "A",
    powerValue: 0.6,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 08:12",
    currentValue: 1,
    currentUnit: "A",
    powerValue: 0.21,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 08:12",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 08:13",
    currentValue: 1.8,
    currentUnit: "A",
    powerValue: 0.39,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 08:13",
    currentValue: 2.8,
    currentUnit: "A",
    powerValue: 0.6,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 08:13",
    currentValue: 1,
    currentUnit: "A",
    powerValue: 0.21,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 08:13",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 08:14",
    currentValue: 1.7,
    currentUnit: "A",
    powerValue: 0.37,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 08:14",
    currentValue: 2.8,
    currentUnit: "A",
    powerValue: 0.6,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 08:14",
    currentValue: 1,
    currentUnit: "A",
    powerValue: 0.21,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 08:14",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 08:15",
    currentValue: 1.7,
    currentUnit: "A",
    powerValue: 0.37,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 08:15",
    currentValue: 2.8,
    currentUnit: "A",
    powerValue: 0.6,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 08:15",
    currentValue: 1,
    currentUnit: "A",
    powerValue: 0.21,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 08:15",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 08:16",
    currentValue: 6.8,
    currentUnit: "A",
    powerValue: 1.45,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 08:16",
    currentValue: 2.8,
    currentUnit: "A",
    powerValue: 0.6,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 08:16",
    currentValue: 1,
    currentUnit: "A",
    powerValue: 0.21,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 08:16",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 08:17",
    currentValue: 1.7,
    currentUnit: "A",
    powerValue: 0.37,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 08:17",
    currentValue: 2.8,
    currentUnit: "A",
    powerValue: 0.6,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 08:17",
    currentValue: 1,
    currentUnit: "A",
    powerValue: 0.21,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 08:17",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 08:18",
    currentValue: 1.8,
    currentUnit: "A",
    powerValue: 0.39,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 08:18",
    currentValue: 2.8,
    currentUnit: "A",
    powerValue: 0.6,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 08:18",
    currentValue: 1,
    currentUnit: "A",
    powerValue: 0.21,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 08:18",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 08:19",
    currentValue: 1.7,
    currentUnit: "A",
    powerValue: 0.37,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 08:19",
    currentValue: 2.8,
    currentUnit: "A",
    powerValue: 0.6,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 08:19",
    currentValue: 1,
    currentUnit: "A",
    powerValue: 0.21,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 08:19",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 08:20",
    currentValue: 1.8,
    currentUnit: "A",
    powerValue: 0.39,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 08:20",
    currentValue: 7.8,
    currentUnit: "A",
    powerValue: 1.67,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 08:20",
    currentValue: 3.4,
    currentUnit: "A",
    powerValue: 0.73,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 08:20",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 08:21",
    currentValue: 1.8,
    currentUnit: "A",
    powerValue: 0.39,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 08:21",
    currentValue: 2.8,
    currentUnit: "A",
    powerValue: 0.6,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 08:21",
    currentValue: 1,
    currentUnit: "A",
    powerValue: 0.21,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 08:21",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 08:22",
    currentValue: 1.7,
    currentUnit: "A",
    powerValue: 0.37,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 08:22",
    currentValue: 2.8,
    currentUnit: "A",
    powerValue: 0.6,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 08:22",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 08:22",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 08:23",
    currentValue: 1.7,
    currentUnit: "A",
    powerValue: 0.37,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 08:23",
    currentValue: 7.8,
    currentUnit: "A",
    powerValue: 1.67,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 08:23",
    currentValue: 1,
    currentUnit: "A",
    powerValue: 0.21,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 08:23",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 08:24",
    currentValue: 1.8,
    currentUnit: "A",
    powerValue: 0.39,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 08:24",
    currentValue: 71.2,
    currentUnit: "A",
    powerValue: 15.25,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 08:24",
    currentValue: 1.8,
    currentUnit: "A",
    powerValue: 0.38,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 08:24",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 08:25",
    currentValue: 6.8,
    currentUnit: "A",
    powerValue: 1.45,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 08:25",
    currentValue: 2.8,
    currentUnit: "A",
    powerValue: 0.6,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 08:25",
    currentValue: 1,
    currentUnit: "A",
    powerValue: 0.21,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 08:25",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 08:26",
    currentValue: 1.7,
    currentUnit: "A",
    powerValue: 0.37,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 08:26",
    currentValue: 7.7,
    currentUnit: "A",
    powerValue: 1.65,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 08:26",
    currentValue: 1.8,
    currentUnit: "A",
    powerValue: 0.38,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 08:26",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 08:27",
    currentValue: 1.8,
    currentUnit: "A",
    powerValue: 0.39,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 08:27",
    currentValue: 2.8,
    currentUnit: "A",
    powerValue: 0.6,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 08:27",
    currentValue: 1,
    currentUnit: "A",
    powerValue: 0.21,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 08:27",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 08:28",
    currentValue: 1.8,
    currentUnit: "A",
    powerValue: 0.39,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 08:28",
    currentValue: 2.8,
    currentUnit: "A",
    powerValue: 0.6,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 08:28",
    currentValue: 8.3,
    currentUnit: "A",
    powerValue: 1.77,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 08:28",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 08:29",
    currentValue: 1.8,
    currentUnit: "A",
    powerValue: 0.39,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 08:29",
    currentValue: 7.7,
    currentUnit: "A",
    powerValue: 1.65,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 08:29",
    currentValue: 1,
    currentUnit: "A",
    powerValue: 0.21,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 08:29",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 08:30",
    currentValue: 1.7,
    currentUnit: "A",
    powerValue: 0.37,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 08:30",
    currentValue: 2.8,
    currentUnit: "A",
    powerValue: 0.6,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 08:30",
    currentValue: 4.9,
    currentUnit: "A",
    powerValue: 1.05,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 08:30",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 08:31",
    currentValue: 1.8,
    currentUnit: "A",
    powerValue: 0.39,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 08:31",
    currentValue: 2.8,
    currentUnit: "A",
    powerValue: 0.6,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 08:31",
    currentValue: 6.8,
    currentUnit: "A",
    powerValue: 1.45,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 08:31",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 08:32",
    currentValue: 6.8,
    currentUnit: "A",
    powerValue: 1.45,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 08:32",
    currentValue: 7.7,
    currentUnit: "A",
    powerValue: 3.33,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 08:32",
    currentValue: 4.9,
    currentUnit: "A",
    powerValue: 1.05,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 08:32",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 08:33",
    currentValue: 1.8,
    currentUnit: "A",
    powerValue: 0.39,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 08:33",
    currentValue: 2.8,
    currentUnit: "A",
    powerValue: 0.6,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 08:33",
    currentValue: 1,
    currentUnit: "A",
    powerValue: 0.21,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 08:33",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 08:34",
    currentValue: 1.8,
    currentUnit: "A",
    powerValue: 0.39,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 08:34",
    currentValue: 2.8,
    currentUnit: "A",
    powerValue: 0.6,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 08:34",
    currentValue: 10.7,
    currentUnit: "A",
    powerValue: 2.29,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 08:34",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 08:35",
    currentValue: 1.7,
    currentUnit: "A",
    powerValue: 0.37,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 08:35",
    currentValue: 7.7,
    currentUnit: "A",
    powerValue: 1.65,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 08:35",
    currentValue: 1,
    currentUnit: "A",
    powerValue: 0.21,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 08:35",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 08:36",
    currentValue: 1.8,
    currentUnit: "A",
    powerValue: 0.39,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 08:36",
    currentValue: 2.8,
    currentUnit: "A",
    powerValue: 0.6,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 08:36",
    currentValue: 4.9,
    currentUnit: "A",
    powerValue: 1.05,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 08:36",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 08:37",
    currentValue: 1.7,
    currentUnit: "A",
    powerValue: 0.37,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 08:37",
    currentValue: 2.8,
    currentUnit: "A",
    powerValue: 0.6,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 08:37",
    currentValue: 1.8,
    currentUnit: "A",
    powerValue: 0.38,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 08:37",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 08:38",
    currentValue: 1.8,
    currentUnit: "A",
    powerValue: 0.39,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 08:38",
    currentValue: 7.7,
    currentUnit: "A",
    powerValue: 1.65,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 08:38",
    currentValue: 79.1,
    currentUnit: "A",
    powerValue: 16.55,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 08:38",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 08:39",
    currentValue: 6.8,
    currentUnit: "A",
    powerValue: 1.45,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 08:39",
    currentValue: 2.8,
    currentUnit: "A",
    powerValue: 0.6,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 08:39",
    currentValue: 74.7,
    currentUnit: "A",
    powerValue: 15.62,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 08:39",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 08:40",
    currentValue: 1.7,
    currentUnit: "A",
    powerValue: 0.37,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 08:40",
    currentValue: 2.8,
    currentUnit: "A",
    powerValue: 0.6,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 08:40",
    currentValue: 122.1,
    currentUnit: "A",
    powerValue: 25.66,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 08:40",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 08:41",
    currentValue: 2.8,
    currentUnit: "A",
    powerValue: 0.6,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 08:41",
    currentValue: 7.7,
    currentUnit: "A",
    powerValue: 1.64,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 08:41",
    currentValue: 74.9,
    currentUnit: "A",
    powerValue: 15.65,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 08:41",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 08:42",
    currentValue: 1.8,
    currentUnit: "A",
    powerValue: 0.39,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 08:42",
    currentValue: 2.8,
    currentUnit: "A",
    powerValue: 0.6,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 08:42",
    currentValue: 78.9,
    currentUnit: "A",
    powerValue: 16.51,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 08:42",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 08:43",
    currentValue: 1.7,
    currentUnit: "A",
    powerValue: 0.37,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 08:43",
    currentValue: 2.8,
    currentUnit: "A",
    powerValue: 0.6,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 08:43",
    currentValue: 76.3,
    currentUnit: "A",
    powerValue: 15.94,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 08:43",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 08:44",
    currentValue: 1.7,
    currentUnit: "A",
    powerValue: 0.37,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 08:44",
    currentValue: 7.7,
    currentUnit: "A",
    powerValue: 1.65,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 08:44",
    currentValue: 80.3,
    currentUnit: "A",
    powerValue: 16.8,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 08:44",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 08:45",
    currentValue: 1.8,
    currentUnit: "A",
    powerValue: 0.39,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 08:45",
    currentValue: 71,
    currentUnit: "A",
    powerValue: 15.22,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 08:45",
    currentValue: 75.3,
    currentUnit: "A",
    powerValue: 15.72,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 08:45",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 08:46",
    currentValue: 6.8,
    currentUnit: "A",
    powerValue: 1.45,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 08:46",
    currentValue: 2.8,
    currentUnit: "A",
    powerValue: 0.6,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 08:46",
    currentValue: 79.2,
    currentUnit: "A",
    powerValue: 16.57,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 08:46",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 08:47",
    currentValue: 1.8,
    currentUnit: "A",
    powerValue: 0.39,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 08:47",
    currentValue: 2.7,
    currentUnit: "A",
    powerValue: 0.58,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 08:47",
    currentValue: 75.3,
    currentUnit: "A",
    powerValue: 15.73,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 08:47",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 08:48",
    currentValue: 1.8,
    currentUnit: "A",
    powerValue: 0.39,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 08:48",
    currentValue: 2.8,
    currentUnit: "A",
    powerValue: 0.6,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 08:48",
    currentValue: 79.3,
    currentUnit: "A",
    powerValue: 16.58,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 08:48",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 08:49",
    currentValue: 1.7,
    currentUnit: "A",
    powerValue: 0.37,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 08:49",
    currentValue: 2.8,
    currentUnit: "A",
    powerValue: 0.6,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 08:49",
    currentValue: 75.5,
    currentUnit: "A",
    powerValue: 15.78,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 08:49",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 08:50",
    currentValue: 1.8,
    currentUnit: "A",
    powerValue: 0.39,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 08:50",
    currentValue: 7.7,
    currentUnit: "A",
    powerValue: 1.65,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 08:50",
    currentValue: 79.5,
    currentUnit: "A",
    powerValue: 16.62,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 08:50",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 08:51",
    currentValue: 1.7,
    currentUnit: "A",
    powerValue: 0.37,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 08:51",
    currentValue: 2.8,
    currentUnit: "A",
    powerValue: 0.6,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 08:51",
    currentValue: 75.6,
    currentUnit: "A",
    powerValue: 15.79,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 08:51",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 08:52",
    currentValue: 1.8,
    currentUnit: "A",
    powerValue: 0.39,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 08:52",
    currentValue: 2.8,
    currentUnit: "A",
    powerValue: 0.6,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 08:52",
    currentValue: 79.5,
    currentUnit: "A",
    powerValue: 16.63,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 08:52",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 08:53",
    currentValue: 6.8,
    currentUnit: "A",
    powerValue: 1.45,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 08:53",
    currentValue: 7.7,
    currentUnit: "A",
    powerValue: 1.65,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 08:53",
    currentValue: 75.4,
    currentUnit: "A",
    powerValue: 15.76,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 08:53",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 08:54",
    currentValue: 1.7,
    currentUnit: "A",
    powerValue: 0.37,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 08:54",
    currentValue: 2.8,
    currentUnit: "A",
    powerValue: 0.6,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 08:54",
    currentValue: 66.7,
    currentUnit: "A",
    powerValue: 14.04,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 08:54",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 08:55",
    currentValue: 6.8,
    currentUnit: "A",
    powerValue: 1.45,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 08:55",
    currentValue: 2.8,
    currentUnit: "A",
    powerValue: 0.6,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 08:55",
    currentValue: 52.5,
    currentUnit: "A",
    powerValue: 11.07,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 08:55",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 08:56",
    currentValue: 1.8,
    currentUnit: "A",
    powerValue: 0.39,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 08:56",
    currentValue: 7.7,
    currentUnit: "A",
    powerValue: 1.65,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 08:56",
    currentValue: 48.9,
    currentUnit: "A",
    powerValue: 10.36,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 08:56",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 08:57",
    currentValue: 1.7,
    currentUnit: "A",
    powerValue: 0.37,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 08:57",
    currentValue: 2.8,
    currentUnit: "A",
    powerValue: 0.6,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 08:57",
    currentValue: 38.3,
    currentUnit: "A",
    powerValue: 8.12,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 08:57",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 08:58",
    currentValue: 1.8,
    currentUnit: "A",
    powerValue: 0.39,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 08:58",
    currentValue: 2.8,
    currentUnit: "A",
    powerValue: 0.6,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 08:58",
    currentValue: 38.5,
    currentUnit: "A",
    powerValue: 8.19,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 08:58",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 08:59",
    currentValue: 1.8,
    currentUnit: "A",
    powerValue: 0.39,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 08:59",
    currentValue: 7.7,
    currentUnit: "A",
    powerValue: 1.65,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 08:59",
    currentValue: 30.4,
    currentUnit: "A",
    powerValue: 6.48,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 08:59",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 09:00",
    currentValue: 1.7,
    currentUnit: "A",
    powerValue: 0.37,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 09:00",
    currentValue: 2.8,
    currentUnit: "A",
    powerValue: 0.6,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 09:00",
    currentValue: 30.8,
    currentUnit: "A",
    powerValue: 6.57,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 09:00",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 09:01",
    currentValue: 1.7,
    currentUnit: "A",
    powerValue: 0.37,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 09:01",
    currentValue: 2.8,
    currentUnit: "A",
    powerValue: 0.6,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 09:01",
    currentValue: 24.5,
    currentUnit: "A",
    powerValue: 5.23,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 09:01",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 09:02",
    currentValue: 6.8,
    currentUnit: "A",
    powerValue: 1.45,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 09:02",
    currentValue: 7.7,
    currentUnit: "A",
    powerValue: 1.65,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 09:02",
    currentValue: 26,
    currentUnit: "A",
    powerValue: 5.56,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 09:02",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 09:03",
    currentValue: 1.7,
    currentUnit: "A",
    powerValue: 0.37,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 09:03",
    currentValue: 2.8,
    currentUnit: "A",
    powerValue: 0.6,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 09:03",
    currentValue: 22.7,
    currentUnit: "A",
    powerValue: 4.85,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 09:03",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 09:04",
    currentValue: 1.7,
    currentUnit: "A",
    powerValue: 0.37,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 09:04",
    currentValue: 2.8,
    currentUnit: "A",
    powerValue: 0.6,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 09:04",
    currentValue: 81.7,
    currentUnit: "A",
    powerValue: 17.08,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 09:04",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 09:05",
    currentValue: 1.7,
    currentUnit: "A",
    powerValue: 0.37,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 09:05",
    currentValue: 2.7,
    currentUnit: "A",
    powerValue: 0.58,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 09:05",
    currentValue: 6.8,
    currentUnit: "A",
    powerValue: 1.45,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 09:05",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 09:06",
    currentValue: 1.7,
    currentUnit: "A",
    powerValue: 0.37,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 09:06",
    currentValue: 9.4,
    currentUnit: "A",
    powerValue: 2.03,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 09:06",
    currentValue: 4.9,
    currentUnit: "A",
    powerValue: 1.05,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 09:06",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 09:07",
    currentValue: 1.7,
    currentUnit: "A",
    powerValue: 0.37,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 09:07",
    currentValue: 44.8,
    currentUnit: "A",
    powerValue: 9.64,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 09:07",
    currentValue: 1,
    currentUnit: "A",
    powerValue: 0.21,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 09:07",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 09:08",
    currentValue: 1.7,
    currentUnit: "A",
    powerValue: 0.37,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 09:08",
    currentValue: 2.7,
    currentUnit: "A",
    powerValue: 0.58,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 09:08",
    currentValue: 10.7,
    currentUnit: "A",
    powerValue: 2.29,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 09:08",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 09:09",
    currentValue: 6.8,
    currentUnit: "A",
    powerValue: 1.45,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 09:09",
    currentValue: 2.8,
    currentUnit: "A",
    powerValue: 0.6,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 09:09",
    currentValue: 1,
    currentUnit: "A",
    powerValue: 0.21,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 09:09",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 09:10",
    currentValue: 1.7,
    currentUnit: "A",
    powerValue: 0.37,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 09:10",
    currentValue: 2.8,
    currentUnit: "A",
    powerValue: 0.6,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 09:10",
    currentValue: 4.9,
    currentUnit: "A",
    powerValue: 1.05,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 09:10",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 09:11",
    currentValue: 1.8,
    currentUnit: "A",
    powerValue: 0.39,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 09:11",
    currentValue: 2.7,
    currentUnit: "A",
    powerValue: 0.58,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 09:11",
    currentValue: 6.8,
    currentUnit: "A",
    powerValue: 1.45,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 09:11",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 09:12",
    currentValue: 1.7,
    currentUnit: "A",
    powerValue: 0.37,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 09:12",
    currentValue: 2.8,
    currentUnit: "A",
    powerValue: 0.6,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 09:12",
    currentValue: 1.5,
    currentUnit: "A",
    powerValue: 0.32,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 09:12",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 09:13",
    currentValue: 1.7,
    currentUnit: "A",
    powerValue: 0.37,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 09:13",
    currentValue: 2.8,
    currentUnit: "A",
    powerValue: 0.6,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 09:13",
    currentValue: 1,
    currentUnit: "A",
    powerValue: 0.21,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 09:13",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 09:14",
    currentValue: 1.7,
    currentUnit: "A",
    powerValue: 0.37,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 09:14",
    currentValue: 2.7,
    currentUnit: "A",
    powerValue: 0.58,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 09:14",
    currentValue: 6.8,
    currentUnit: "A",
    powerValue: 1.46,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 09:14",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 09:15",
    currentValue: 1.7,
    currentUnit: "A",
    powerValue: 0.37,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 09:15",
    currentValue: 2.8,
    currentUnit: "A",
    powerValue: 0.6,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 09:15",
    currentValue: 1,
    currentUnit: "A",
    powerValue: 0.21,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 09:15",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 09:16",
    currentValue: 6.8,
    currentUnit: "A",
    powerValue: 1.45,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 09:16",
    currentValue: 2.8,
    currentUnit: "A",
    powerValue: 0.6,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 09:16",
    currentValue: 1,
    currentUnit: "A",
    powerValue: 0.21,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 09:16",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 09:17",
    currentValue: 1.7,
    currentUnit: "A",
    powerValue: 0.37,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 09:17",
    currentValue: 2.7,
    currentUnit: "A",
    powerValue: 0.58,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 09:17",
    currentValue: 4.7,
    currentUnit: "A",
    powerValue: 1,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 09:17",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 09:18",
    currentValue: 5.1,
    currentUnit: "A",
    powerValue: 1.09,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 09:18",
    currentValue: 2.8,
    currentUnit: "A",
    powerValue: 0.6,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 09:18",
    currentValue: 1,
    currentUnit: "A",
    powerValue: 0.21,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 09:18",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 09:19",
    currentValue: 1.8,
    currentUnit: "A",
    powerValue: 0.39,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 09:19",
    currentValue: 2.8,
    currentUnit: "A",
    powerValue: 0.6,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 09:19",
    currentValue: 1,
    currentUnit: "A",
    powerValue: 0.21,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 09:19",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 09:20",
    currentValue: 1.8,
    currentUnit: "A",
    powerValue: 0.39,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 09:20",
    currentValue: 2.7,
    currentUnit: "A",
    powerValue: 0.58,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 09:20",
    currentValue: 1.9,
    currentUnit: "A",
    powerValue: 0.41,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 09:20",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 09:21",
    currentValue: 1.7,
    currentUnit: "A",
    powerValue: 0.37,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 09:21",
    currentValue: 2.8,
    currentUnit: "A",
    powerValue: 0.6,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 09:21",
    currentValue: 1,
    currentUnit: "A",
    powerValue: 0.21,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 09:21",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 09:22",
    currentValue: 1.7,
    currentUnit: "A",
    powerValue: 0.37,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 09:22",
    currentValue: 2.8,
    currentUnit: "A",
    powerValue: 0.6,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 09:22",
    currentValue: 1,
    currentUnit: "A",
    powerValue: 0.21,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 09:22",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 09:23",
    currentValue: 6.8,
    currentUnit: "A",
    powerValue: 1.45,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 09:23",
    currentValue: 2.8,
    currentUnit: "A",
    powerValue: 0.6,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 09:23",
    currentValue: 1.8,
    currentUnit: "A",
    powerValue: 0.38,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 09:23",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 09:24",
    currentValue: 1.7,
    currentUnit: "A",
    powerValue: 0.37,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 09:24",
    currentValue: 2.8,
    currentUnit: "A",
    powerValue: 0.6,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 09:24",
    currentValue: 1,
    currentUnit: "A",
    powerValue: 0.21,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 09:24",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 09:25",
    currentValue: 1.8,
    currentUnit: "A",
    powerValue: 0.39,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 09:25",
    currentValue: 2.8,
    currentUnit: "A",
    powerValue: 0.6,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 09:25",
    currentValue: 42.5,
    currentUnit: "A",
    powerValue: 9.01,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 09:25",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 09:26",
    currentValue: 1.7,
    currentUnit: "A",
    powerValue: 0.37,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 09:26",
    currentValue: 2.8,
    currentUnit: "A",
    powerValue: 0.6,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 09:26",
    currentValue: 2.9,
    currentUnit: "A",
    powerValue: 0.62,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 09:26",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 09:27",
    currentValue: 1.8,
    currentUnit: "A",
    powerValue: 0.39,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 09:27",
    currentValue: 2.7,
    currentUnit: "A",
    powerValue: 0.58,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 09:27",
    currentValue: 1,
    currentUnit: "A",
    powerValue: 0.21,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 09:27",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 09:28",
    currentValue: 6.8,
    currentUnit: "A",
    powerValue: 1.45,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 09:28",
    currentValue: 60.9,
    currentUnit: "A",
    powerValue: 12.98,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 09:28",
    currentValue: 1,
    currentUnit: "A",
    powerValue: 0.21,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 09:28",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 09:29",
    currentValue: 1.7,
    currentUnit: "A",
    powerValue: 0.37,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 09:29",
    currentValue: 58.6,
    currentUnit: "A",
    powerValue: 12.49,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 09:29",
    currentValue: 1,
    currentUnit: "A",
    powerValue: 0.21,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 09:29",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 09:30",
    currentValue: 6.8,
    currentUnit: "A",
    powerValue: 1.45,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 09:30",
    currentValue: 43.6,
    currentUnit: "A",
    powerValue: 9.31,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 09:30",
    currentValue: 1,
    currentUnit: "A",
    powerValue: 0.21,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 09:30",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 09:31",
    currentValue: 1.8,
    currentUnit: "A",
    powerValue: 0.39,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 09:31",
    currentValue: 43.1,
    currentUnit: "A",
    powerValue: 9.21,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 09:31",
    currentValue: 1,
    currentUnit: "A",
    powerValue: 0.21,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 09:31",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 09:32",
    currentValue: 1.8,
    currentUnit: "A",
    powerValue: 0.39,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 09:32",
    currentValue: 42.9,
    currentUnit: "A",
    powerValue: 9.17,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 09:32",
    currentValue: 1,
    currentUnit: "A",
    powerValue: 0.21,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 09:32",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 09:33",
    currentValue: 1.7,
    currentUnit: "A",
    powerValue: 0.37,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 09:33",
    currentValue: 42.7,
    currentUnit: "A",
    powerValue: 9.12,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 09:33",
    currentValue: 1,
    currentUnit: "A",
    powerValue: 0.21,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 09:33",
    currentValue: 68.8,
    currentUnit: "A",
    powerValue: 14.62,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 09:34",
    currentValue: 1.8,
    currentUnit: "A",
    powerValue: 0.39,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 09:34",
    currentValue: 58.3,
    currentUnit: "A",
    powerValue: 12.47,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 09:34",
    currentValue: 1,
    currentUnit: "A",
    powerValue: 0.21,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 09:34",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 09:35",
    currentValue: 6.8,
    currentUnit: "A",
    powerValue: 1.45,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 09:35",
    currentValue: 56.4,
    currentUnit: "A",
    powerValue: 12.07,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 09:35",
    currentValue: 1,
    currentUnit: "A",
    powerValue: 0.21,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 09:35",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 09:36",
    currentValue: 1.8,
    currentUnit: "A",
    powerValue: 0.39,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 09:36",
    currentValue: 51.6,
    currentUnit: "A",
    powerValue: 11.05,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 09:36",
    currentValue: 1,
    currentUnit: "A",
    powerValue: 0.21,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 09:36",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 09:37",
    currentValue: 1.8,
    currentUnit: "A",
    powerValue: 0.39,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 09:37",
    currentValue: 51.3,
    currentUnit: "A",
    powerValue: 10.98,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 09:37",
    currentValue: 1,
    currentUnit: "A",
    powerValue: 0.21,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 09:37",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 09:38",
    currentValue: 1.8,
    currentUnit: "A",
    powerValue: 0.39,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 09:38",
    currentValue: 51.1,
    currentUnit: "A",
    powerValue: 10.94,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 09:38",
    currentValue: 1,
    currentUnit: "A",
    powerValue: 0.21,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 09:38",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 09:39",
    currentValue: 1.8,
    currentUnit: "A",
    powerValue: 0.39,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 09:39",
    currentValue: 50.7,
    currentUnit: "A",
    powerValue: 10.85,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 09:39",
    currentValue: 1,
    currentUnit: "A",
    powerValue: 0.21,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 09:39",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 09:40",
    currentValue: 1.7,
    currentUnit: "A",
    powerValue: 0.37,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 09:40",
    currentValue: 50,
    currentUnit: "A",
    powerValue: 10.71,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 09:40",
    currentValue: 1,
    currentUnit: "A",
    powerValue: 0.21,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 09:40",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 09:41",
    currentValue: 1.8,
    currentUnit: "A",
    powerValue: 0.39,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 09:41",
    currentValue: 49.1,
    currentUnit: "A",
    powerValue: 10.52,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 09:41",
    currentValue: 1,
    currentUnit: "A",
    powerValue: 0.21,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 09:41",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 09:42",
    currentValue: 1.7,
    currentUnit: "A",
    powerValue: 0.37,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 09:42",
    currentValue: 48.8,
    currentUnit: "A",
    powerValue: 10.45,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 09:42",
    currentValue: 1,
    currentUnit: "A",
    powerValue: 0.21,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 09:42",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 09:43",
    currentValue: 1.8,
    currentUnit: "A",
    powerValue: 0.39,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 09:43",
    currentValue: 48.3,
    currentUnit: "A",
    powerValue: 10.34,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 09:43",
    currentValue: 1,
    currentUnit: "A",
    powerValue: 0.21,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 09:43",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 09:44",
    currentValue: 6.8,
    currentUnit: "A",
    powerValue: 1.45,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 09:44",
    currentValue: 47.3,
    currentUnit: "A",
    powerValue: 10.13,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 09:44",
    currentValue: 1,
    currentUnit: "A",
    powerValue: 0.21,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 09:44",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 09:45",
    currentValue: 1.8,
    currentUnit: "A",
    powerValue: 0.39,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 09:45",
    currentValue: 46.8,
    currentUnit: "A",
    powerValue: 10.02,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 09:45",
    currentValue: 1,
    currentUnit: "A",
    powerValue: 0.21,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 09:45",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 09:46",
    currentValue: 1.8,
    currentUnit: "A",
    powerValue: 0.39,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 09:46",
    currentValue: 39.4,
    currentUnit: "A",
    powerValue: 8.45,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 09:46",
    currentValue: 2.5,
    currentUnit: "A",
    powerValue: 0.53,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 09:46",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 09:47",
    currentValue: 1.8,
    currentUnit: "A",
    powerValue: 0.39,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 09:47",
    currentValue: 39.5,
    currentUnit: "A",
    powerValue: 8.47,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 09:47",
    currentValue: 1,
    currentUnit: "A",
    powerValue: 0.21,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 09:47",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 09:48",
    currentValue: 1.8,
    currentUnit: "A",
    powerValue: 0.39,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 09:48",
    currentValue: 38.7,
    currentUnit: "A",
    powerValue: 8.3,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 09:48",
    currentValue: 4.9,
    currentUnit: "A",
    powerValue: 1.05,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 09:48",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 09:49",
    currentValue: 1.7,
    currentUnit: "A",
    powerValue: 0.37,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 09:49",
    currentValue: 38.2,
    currentUnit: "A",
    powerValue: 8.19,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 09:49",
    currentValue: 1,
    currentUnit: "A",
    powerValue: 0.21,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 09:49",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 09:50",
    currentValue: 1.8,
    currentUnit: "A",
    powerValue: 0.39,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 09:50",
    currentValue: 37.5,
    currentUnit: "A",
    powerValue: 8.05,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 09:50",
    currentValue: 4.9,
    currentUnit: "A",
    powerValue: 1.05,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 09:50",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 09:51",
    currentValue: 6.8,
    currentUnit: "A",
    powerValue: 1.45,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 09:51",
    currentValue: 37.2,
    currentUnit: "A",
    powerValue: 7.98,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 09:51",
    currentValue: 1,
    currentUnit: "A",
    powerValue: 0.21,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 09:51",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 09:52",
    currentValue: 1.8,
    currentUnit: "A",
    powerValue: 0.39,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 09:52",
    currentValue: 35.1,
    currentUnit: "A",
    powerValue: 7.53,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 09:52",
    currentValue: 1,
    currentUnit: "A",
    powerValue: 0.21,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 09:52",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 09:53",
    currentValue: 1.8,
    currentUnit: "A",
    powerValue: 0.39,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 09:53",
    currentValue: 12.5,
    currentUnit: "A",
    powerValue: 2.69,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 09:53",
    currentValue: 1,
    currentUnit: "A",
    powerValue: 0.21,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 09:53",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 09:54",
    currentValue: 1.8,
    currentUnit: "A",
    powerValue: 0.39,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 09:54",
    currentValue: 12.2,
    currentUnit: "A",
    powerValue: 2.62,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 09:54",
    currentValue: 1,
    currentUnit: "A",
    powerValue: 0.21,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 09:54",
    currentValue: 68.2,
    currentUnit: "A",
    powerValue: 14.51,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 09:55",
    currentValue: 1.8,
    currentUnit: "A",
    powerValue: 0.39,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 09:55",
    currentValue: 24,
    currentUnit: "A",
    powerValue: 5.14,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 09:55",
    currentValue: 1,
    currentUnit: "A",
    powerValue: 0.21,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 09:55",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 09:56",
    currentValue: 1.7,
    currentUnit: "A",
    powerValue: 0.37,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 09:56",
    currentValue: 16.1,
    currentUnit: "A",
    powerValue: 3.46,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 09:56",
    currentValue: 1,
    currentUnit: "A",
    powerValue: 0.21,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 09:56",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 09:57",
    currentValue: 1.8,
    currentUnit: "A",
    powerValue: 0.39,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 09:57",
    currentValue: 16.1,
    currentUnit: "A",
    powerValue: 3.46,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 09:57",
    currentValue: 1,
    currentUnit: "A",
    powerValue: 0.21,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 09:57",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 09:58",
    currentValue: 6.8,
    currentUnit: "A",
    powerValue: 1.45,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 09:58",
    currentValue: 14.8,
    currentUnit: "A",
    powerValue: 3.18,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 09:58",
    currentValue: 4.3,
    currentUnit: "A",
    powerValue: 0.92,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 09:58",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 09:59",
    currentValue: 1.8,
    currentUnit: "A",
    powerValue: 0.39,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 09:59",
    currentValue: 15.9,
    currentUnit: "A",
    powerValue: 3.42,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 09:59",
    currentValue: 1,
    currentUnit: "A",
    powerValue: 0.21,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 09:59",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 10:00",
    currentValue: 1.8,
    currentUnit: "A",
    powerValue: 0.39,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 10:00",
    currentValue: 14.5,
    currentUnit: "A",
    powerValue: 3.12,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 10:00",
    currentValue: 4.9,
    currentUnit: "A",
    powerValue: 1.05,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 10:00",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 10:01",
    currentValue: 1.7,
    currentUnit: "A",
    powerValue: 0.37,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 10:01",
    currentValue: 14,
    currentUnit: "A",
    powerValue: 3.01,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 10:01",
    currentValue: 1,
    currentUnit: "A",
    powerValue: 0.21,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 10:01",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 10:02",
    currentValue: 1.7,
    currentUnit: "A",
    powerValue: 0.37,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 10:02",
    currentValue: 13.4,
    currentUnit: "A",
    powerValue: 2.88,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 10:02",
    currentValue: 4.9,
    currentUnit: "A",
    powerValue: 1.05,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 10:02",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 10:03",
    currentValue: 6.8,
    currentUnit: "A",
    powerValue: 1.45,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 10:03",
    currentValue: 13.1,
    currentUnit: "A",
    powerValue: 2.82,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 10:03",
    currentValue: 1,
    currentUnit: "A",
    powerValue: 0.21,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 10:03",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 10:04",
    currentValue: 1.7,
    currentUnit: "A",
    powerValue: 0.37,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 10:04",
    currentValue: 12.9,
    currentUnit: "A",
    powerValue: 2.78,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 10:04",
    currentValue: 4.9,
    currentUnit: "A",
    powerValue: 1.05,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 10:04",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 10:05",
    currentValue: 1.8,
    currentUnit: "A",
    powerValue: 0.39,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 10:05",
    currentValue: 12,
    currentUnit: "A",
    powerValue: 2.58,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 10:05",
    currentValue: 1,
    currentUnit: "A",
    powerValue: 0.21,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 10:05",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 10:06",
    currentValue: 1.7,
    currentUnit: "A",
    powerValue: 0.37,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 10:06",
    currentValue: 12.2,
    currentUnit: "A",
    powerValue: 2.62,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 10:06",
    currentValue: 4.9,
    currentUnit: "A",
    powerValue: 1.05,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 10:06",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 10:07",
    currentValue: 1.8,
    currentUnit: "A",
    powerValue: 0.39,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 10:07",
    currentValue: 11.8,
    currentUnit: "A",
    powerValue: 2.54,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 10:07",
    currentValue: 1,
    currentUnit: "A",
    powerValue: 0.21,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 10:07",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 10:08",
    currentValue: 1.7,
    currentUnit: "A",
    powerValue: 0.37,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 10:08",
    currentValue: 11.4,
    currentUnit: "A",
    powerValue: 2.45,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 10:08",
    currentValue: 4.9,
    currentUnit: "A",
    powerValue: 1.05,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 10:08",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 10:09",
    currentValue: 1.7,
    currentUnit: "A",
    powerValue: 0.37,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 10:09",
    currentValue: 11.4,
    currentUnit: "A",
    powerValue: 2.45,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 10:09",
    currentValue: 1,
    currentUnit: "A",
    powerValue: 0.21,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 10:09",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 10:10",
    currentValue: 6.8,
    currentUnit: "A",
    powerValue: 1.45,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 10:10",
    currentValue: 10.7,
    currentUnit: "A",
    powerValue: 2.3,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 10:10",
    currentValue: 1,
    currentUnit: "A",
    powerValue: 0.21,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 10:10",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 10:11",
    currentValue: 52.2,
    currentUnit: "A",
    powerValue: 11.23,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 10:11",
    currentValue: 11.2,
    currentUnit: "A",
    powerValue: 2.41,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 10:11",
    currentValue: 1,
    currentUnit: "A",
    powerValue: 0.21,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 10:11",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 10:12",
    currentValue: 42.9,
    currentUnit: "A",
    powerValue: 9.24,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 10:12",
    currentValue: 10.8,
    currentUnit: "A",
    powerValue: 2.32,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 10:12",
    currentValue: 1,
    currentUnit: "A",
    powerValue: 0.21,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 10:12",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 10:13",
    currentValue: 37.6,
    currentUnit: "A",
    powerValue: 8.11,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 10:13",
    currentValue: 9.7,
    currentUnit: "A",
    powerValue: 2.09,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 10:13",
    currentValue: 1,
    currentUnit: "A",
    powerValue: 0.21,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 10:13",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 10:14",
    currentValue: 37,
    currentUnit: "A",
    powerValue: 7.98,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 10:14",
    currentValue: 9.8,
    currentUnit: "A",
    powerValue: 2.11,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 10:14",
    currentValue: 4.6,
    currentUnit: "A",
    powerValue: 0.99,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 10:14",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 10:15",
    currentValue: 36.3,
    currentUnit: "A",
    powerValue: 7.83,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 10:15",
    currentValue: 9.8,
    currentUnit: "A",
    powerValue: 2.11,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 10:15",
    currentValue: 1,
    currentUnit: "A",
    powerValue: 0.21,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 10:15",
    currentValue: 64.4,
    currentUnit: "A",
    powerValue: 13.72,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 10:16",
    currentValue: 35.5,
    currentUnit: "A",
    powerValue: 7.67,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 10:16",
    currentValue: 14.5,
    currentUnit: "A",
    powerValue: 3.11,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 10:16",
    currentValue: 4.9,
    currentUnit: "A",
    powerValue: 1.05,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 10:16",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 10:17",
    currentValue: 40.6,
    currentUnit: "A",
    powerValue: 8.75,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 10:17",
    currentValue: 9.8,
    currentUnit: "A",
    powerValue: 2.11,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 10:17",
    currentValue: 1,
    currentUnit: "A",
    powerValue: 0.21,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 10:17",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 10:18",
    currentValue: 34.7,
    currentUnit: "A",
    powerValue: 7.49,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 10:18",
    currentValue: 9.8,
    currentUnit: "A",
    powerValue: 2.11,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 10:18",
    currentValue: 4.9,
    currentUnit: "A",
    powerValue: 1.05,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 10:18",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 10:19",
    currentValue: 29.4,
    currentUnit: "A",
    powerValue: 6.35,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 10:19",
    currentValue: 13.3,
    currentUnit: "A",
    powerValue: 2.85,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 10:19",
    currentValue: 1,
    currentUnit: "A",
    powerValue: 0.21,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 10:19",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 10:20",
    currentValue: 29.3,
    currentUnit: "A",
    powerValue: 6.32,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 10:20",
    currentValue: 9.2,
    currentUnit: "A",
    powerValue: 1.98,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 10:20",
    currentValue: 4.9,
    currentUnit: "A",
    powerValue: 1.05,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 10:20",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 10:21",
    currentValue: 28.5,
    currentUnit: "A",
    powerValue: 6.15,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 10:21",
    currentValue: 8.4,
    currentUnit: "A",
    powerValue: 1.81,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 10:21",
    currentValue: 1,
    currentUnit: "A",
    powerValue: 0.21,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 10:21",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 10:22",
    currentValue: 27.8,
    currentUnit: "A",
    powerValue: 6,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 10:22",
    currentValue: 13.3,
    currentUnit: "A",
    powerValue: 2.85,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 10:22",
    currentValue: 4.9,
    currentUnit: "A",
    powerValue: 1.05,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 10:22",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 10:23",
    currentValue: 1.8,
    currentUnit: "A",
    powerValue: 0.39,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 10:23",
    currentValue: 8.4,
    currentUnit: "A",
    powerValue: 1.81,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 10:23",
    currentValue: 43.9,
    currentUnit: "A",
    powerValue: 9.32,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 10:23",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 10:24",
    currentValue: 6.8,
    currentUnit: "A",
    powerValue: 1.45,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 10:24",
    currentValue: 8.4,
    currentUnit: "A",
    powerValue: 1.81,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 10:24",
    currentValue: 4.9,
    currentUnit: "A",
    powerValue: 1.05,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 10:24",
    currentValue: 41.5,
    currentUnit: "A",
    powerValue: 8.96,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 10:25",
    currentValue: 1.8,
    currentUnit: "A",
    powerValue: 0.39,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 10:25",
    currentValue: 12.7,
    currentUnit: "A",
    powerValue: 2.73,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 10:25",
    currentValue: 1,
    currentUnit: "A",
    powerValue: 0.21,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 10:25",
    currentValue: 40.5,
    currentUnit: "A",
    powerValue: 8.74,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 10:26",
    currentValue: 1.8,
    currentUnit: "A",
    powerValue: 0.39,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 10:26",
    currentValue: 8,
    currentUnit: "A",
    powerValue: 1.72,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 10:26",
    currentValue: 1,
    currentUnit: "A",
    powerValue: 0.21,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 10:26",
    currentValue: 35.3,
    currentUnit: "A",
    powerValue: 7.62,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 10:27",
    currentValue: 1.8,
    currentUnit: "A",
    powerValue: 0.39,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 10:27",
    currentValue: 8,
    currentUnit: "A",
    powerValue: 1.72,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 10:27",
    currentValue: 1,
    currentUnit: "A",
    powerValue: 0.21,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 10:27",
    currentValue: 35,
    currentUnit: "A",
    powerValue: 7.56,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 10:28",
    currentValue: 1.8,
    currentUnit: "A",
    powerValue: 0.39,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 10:28",
    currentValue: 12.8,
    currentUnit: "A",
    powerValue: 2.75,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 10:28",
    currentValue: 1,
    currentUnit: "A",
    powerValue: 0.21,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 10:28",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 10:29",
    currentValue: 1.7,
    currentUnit: "A",
    powerValue: 0.37,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 10:29",
    currentValue: 8.2,
    currentUnit: "A",
    powerValue: 1.77,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 10:29",
    currentValue: 1,
    currentUnit: "A",
    powerValue: 0.21,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 10:29",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 10:30",
    currentValue: 1.8,
    currentUnit: "A",
    powerValue: 0.39,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 10:30",
    currentValue: 7.3,
    currentUnit: "A",
    powerValue: 1.57,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 10:30",
    currentValue: 1,
    currentUnit: "A",
    powerValue: 0.21,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 10:30",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 10:31",
    currentValue: 6.8,
    currentUnit: "A",
    powerValue: 1.46,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 10:31",
    currentValue: 12.2,
    currentUnit: "A",
    powerValue: 2.62,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 10:31",
    currentValue: 1,
    currentUnit: "A",
    powerValue: 0.21,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 10:31",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 10:32",
    currentValue: 1.8,
    currentUnit: "A",
    powerValue: 0.39,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 10:32",
    currentValue: 6.9,
    currentUnit: "A",
    powerValue: 1.49,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 10:32",
    currentValue: 1,
    currentUnit: "A",
    powerValue: 0.21,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 10:32",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 10:33",
    currentValue: 4.5,
    currentUnit: "A",
    powerValue: 0.96,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 10:33",
    currentValue: 7.3,
    currentUnit: "A",
    powerValue: 1.57,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 10:33",
    currentValue: 1.9,
    currentUnit: "A",
    powerValue: 0.41,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 10:33",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 10:34",
    currentValue: 1.8,
    currentUnit: "A",
    powerValue: 0.39,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 10:34",
    currentValue: 7,
    currentUnit: "A",
    powerValue: 1.51,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 10:34",
    currentValue: 1,
    currentUnit: "A",
    powerValue: 0.21,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 10:34",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 10:35",
    currentValue: 1.8,
    currentUnit: "A",
    powerValue: 0.39,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 10:35",
    currentValue: 6.9,
    currentUnit: "A",
    powerValue: 1.49,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 10:35",
    currentValue: 2.3,
    currentUnit: "A",
    powerValue: 0.49,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 10:35",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 10:36",
    currentValue: 1.8,
    currentUnit: "A",
    powerValue: 0.39,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 10:36",
    currentValue: 6.9,
    currentUnit: "A",
    powerValue: 1.49,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 10:36",
    currentValue: 1,
    currentUnit: "A",
    powerValue: 0.21,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 10:36",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 10:37",
    currentValue: 1.8,
    currentUnit: "A",
    powerValue: 0.39,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 10:37",
    currentValue: 6.8,
    currentUnit: "A",
    powerValue: 1.46,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 10:37",
    currentValue: 1,
    currentUnit: "A",
    powerValue: 0.21,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 10:37",
    currentValue: 61.9,
    currentUnit: "A",
    powerValue: 13.2,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 10:38",
    currentValue: 6.8,
    currentUnit: "A",
    powerValue: 1.46,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 10:38",
    currentValue: 6.6,
    currentUnit: "A",
    powerValue: 1.42,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 10:38",
    currentValue: 1,
    currentUnit: "A",
    powerValue: 0.21,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 10:38",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 10:39",
    currentValue: 1.7,
    currentUnit: "A",
    powerValue: 0.37,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 10:39",
    currentValue: 6.5,
    currentUnit: "A",
    powerValue: 1.4,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 10:39",
    currentValue: 6.8,
    currentUnit: "A",
    powerValue: 1.46,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 10:39",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 10:40",
    currentValue: 6.8,
    currentUnit: "A",
    powerValue: 1.46,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 10:40",
    currentValue: 6.8,
    currentUnit: "A",
    powerValue: 1.47,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 10:40",
    currentValue: 1,
    currentUnit: "A",
    powerValue: 0.21,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 10:40",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 10:41",
    currentValue: 1.7,
    currentUnit: "A",
    powerValue: 0.37,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 10:41",
    currentValue: 5.9,
    currentUnit: "A",
    powerValue: 1.27,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 10:41",
    currentValue: 1,
    currentUnit: "A",
    powerValue: 0.21,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 10:41",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 10:42",
    currentValue: 1.7,
    currentUnit: "A",
    powerValue: 0.37,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 10:42",
    currentValue: 5.7,
    currentUnit: "A",
    powerValue: 1.23,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 10:42",
    currentValue: 6.8,
    currentUnit: "A",
    powerValue: 1.46,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 10:42",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 10:43",
    currentValue: 1.8,
    currentUnit: "A",
    powerValue: 0.39,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 10:43",
    currentValue: 6.3,
    currentUnit: "A",
    powerValue: 1.36,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 10:43",
    currentValue: 1,
    currentUnit: "A",
    powerValue: 0.21,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 10:43",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 10:44",
    currentValue: 1.8,
    currentUnit: "A",
    powerValue: 0.39,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 10:44",
    currentValue: 6.2,
    currentUnit: "A",
    powerValue: 1.34,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 10:44",
    currentValue: 1,
    currentUnit: "A",
    powerValue: 0.21,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 10:44",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 10:45",
    currentValue: 6.7,
    currentUnit: "A",
    powerValue: 1.44,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 10:45",
    currentValue: 6.9,
    currentUnit: "A",
    powerValue: 1.49,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 10:45",
    currentValue: 6.8,
    currentUnit: "A",
    powerValue: 1.46,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 10:45",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 10:46",
    currentValue: 1.8,
    currentUnit: "A",
    powerValue: 0.39,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 10:46",
    currentValue: 6.8,
    currentUnit: "A",
    powerValue: 1.47,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 10:46",
    currentValue: 1,
    currentUnit: "A",
    powerValue: 0.21,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 10:46",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 10:47",
    currentValue: 1.8,
    currentUnit: "A",
    powerValue: 0.39,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 10:47",
    currentValue: 5.4,
    currentUnit: "A",
    powerValue: 1.16,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 10:47",
    currentValue: 1.8,
    currentUnit: "A",
    powerValue: 0.39,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 10:47",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 10:48",
    currentValue: 1.7,
    currentUnit: "A",
    powerValue: 0.37,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 10:48",
    currentValue: 6.5,
    currentUnit: "A",
    powerValue: 1.4,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 10:48",
    currentValue: 6.8,
    currentUnit: "A",
    powerValue: 1.46,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 10:48",
    currentValue: 58.3,
    currentUnit: "A",
    powerValue: 12.55,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 10:49",
    currentValue: 1.8,
    currentUnit: "A",
    powerValue: 0.39,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 10:49",
    currentValue: 6.5,
    currentUnit: "A",
    powerValue: 1.4,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 10:49",
    currentValue: 1.8,
    currentUnit: "A",
    powerValue: 0.39,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 10:49",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 10:50",
    currentValue: 1.7,
    currentUnit: "A",
    powerValue: 0.37,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 10:50",
    currentValue: 5.8,
    currentUnit: "A",
    powerValue: 2.94,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 10:50",
    currentValue: 1,
    currentUnit: "A",
    powerValue: 0.21,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 10:50",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 10:51",
    currentValue: 1.8,
    currentUnit: "A",
    powerValue: 0.39,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 10:51",
    currentValue: 5.8,
    currentUnit: "A",
    powerValue: 1.25,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 10:51",
    currentValue: 7.6,
    currentUnit: "A",
    powerValue: 1.63,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 10:51",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 10:52",
    currentValue: 6.8,
    currentUnit: "A",
    powerValue: 1.46,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 10:52",
    currentValue: 5.3,
    currentUnit: "A",
    powerValue: 1.14,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 10:52",
    currentValue: 1,
    currentUnit: "A",
    powerValue: 0.21,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 10:52",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 10:53",
    currentValue: 1.7,
    currentUnit: "A",
    powerValue: 0.37,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 10:53",
    currentValue: 6.3,
    currentUnit: "A",
    powerValue: 1.36,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 10:53",
    currentValue: 1.8,
    currentUnit: "A",
    powerValue: 0.38,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 10:53",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 10:54",
    currentValue: 5.3,
    currentUnit: "A",
    powerValue: 1.13,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 10:54",
    currentValue: 5.3,
    currentUnit: "A",
    powerValue: 1.14,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 10:54",
    currentValue: 6.8,
    currentUnit: "A",
    powerValue: 1.46,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 10:54",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 10:55",
    currentValue: 1.8,
    currentUnit: "A",
    powerValue: 0.39,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 10:55",
    currentValue: 5.5,
    currentUnit: "A",
    powerValue: 1.19,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 10:55",
    currentValue: 4.9,
    currentUnit: "A",
    powerValue: 1.05,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 10:55",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 10:56",
    currentValue: 1.7,
    currentUnit: "A",
    powerValue: 0.37,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 10:56",
    currentValue: 5.4,
    currentUnit: "A",
    powerValue: 1.16,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 10:56",
    currentValue: 1,
    currentUnit: "A",
    powerValue: 0.21,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 10:56",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 10:57",
    currentValue: 1.7,
    currentUnit: "A",
    powerValue: 0.37,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 10:57",
    currentValue: 5.4,
    currentUnit: "A",
    powerValue: 1.16,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 10:57",
    currentValue: 7.6,
    currentUnit: "A",
    powerValue: 1.63,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 10:57",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 10:58",
    currentValue: 1.8,
    currentUnit: "A",
    powerValue: 0.39,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 10:58",
    currentValue: 5.4,
    currentUnit: "A",
    powerValue: 1.16,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 10:58",
    currentValue: 1,
    currentUnit: "A",
    powerValue: 0.21,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 10:58",
    currentValue: 54.7,
    currentUnit: "A",
    powerValue: 11.69,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 10:59",
    currentValue: 6.8,
    currentUnit: "A",
    powerValue: 1.45,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 10:59",
    currentValue: 5.4,
    currentUnit: "A",
    powerValue: 1.16,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 10:59",
    currentValue: 1.8,
    currentUnit: "A",
    powerValue: 0.38,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 10:59",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 11:00",
    currentValue: 1.7,
    currentUnit: "A",
    powerValue: 0.37,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 11:00",
    currentValue: 5.4,
    currentUnit: "A",
    powerValue: 1.16,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 11:00",
    currentValue: 6.8,
    currentUnit: "A",
    powerValue: 1.46,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 11:00",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 11:01",
    currentValue: 1.8,
    currentUnit: "A",
    powerValue: 0.39,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 11:01",
    currentValue: 5.2,
    currentUnit: "A",
    powerValue: 1.12,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 11:01",
    currentValue: 4,
    currentUnit: "A",
    powerValue: 0.86,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 11:01",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 11:02",
    currentValue: 1.7,
    currentUnit: "A",
    powerValue: 0.37,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 11:02",
    currentValue: 6.4,
    currentUnit: "A",
    powerValue: 1.38,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 11:02",
    currentValue: 1,
    currentUnit: "A",
    powerValue: 0.21,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 11:02",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 11:03",
    currentValue: 1.7,
    currentUnit: "A",
    powerValue: 0.37,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 11:03",
    currentValue: 5.2,
    currentUnit: "A",
    powerValue: 1.12,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 11:03",
    currentValue: 7.5,
    currentUnit: "A",
    powerValue: 1.61,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 11:03",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 11:04",
    currentValue: 1.7,
    currentUnit: "A",
    powerValue: 0.37,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 11:04",
    currentValue: 5,
    currentUnit: "A",
    powerValue: 1.08,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 11:04",
    currentValue: 1,
    currentUnit: "A",
    powerValue: 0.21,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 11:04",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 11:05",
    currentValue: 1.8,
    currentUnit: "A",
    powerValue: 0.39,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 11:05",
    currentValue: 5.2,
    currentUnit: "A",
    powerValue: 1.12,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 11:05",
    currentValue: 1,
    currentUnit: "A",
    powerValue: 0.21,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 11:05",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 11:06",
    currentValue: 6.8,
    currentUnit: "A",
    powerValue: 1.45,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 11:06",
    currentValue: 5.4,
    currentUnit: "A",
    powerValue: 1.16,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 11:06",
    currentValue: 6.7,
    currentUnit: "A",
    powerValue: 1.44,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 11:06",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 11:07",
    currentValue: 1.7,
    currentUnit: "A",
    powerValue: 0.37,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 11:07",
    currentValue: 5.1,
    currentUnit: "A",
    powerValue: 1.1,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 11:07",
    currentValue: 1.8,
    currentUnit: "A",
    powerValue: 0.39,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 11:07",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 11:08",
    currentValue: 1.7,
    currentUnit: "A",
    powerValue: 0.37,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 11:08",
    currentValue: 4.8,
    currentUnit: "A",
    powerValue: 1.03,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 11:08",
    currentValue: 1,
    currentUnit: "A",
    powerValue: 0.21,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 11:08",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 11:09",
    currentValue: 1.8,
    currentUnit: "A",
    powerValue: 0.39,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 11:09",
    currentValue: 5,
    currentUnit: "A",
    powerValue: 1.08,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 11:09",
    currentValue: 5.6,
    currentUnit: "A",
    powerValue: 1.2,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 11:09",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 11:10",
    currentValue: 1.8,
    currentUnit: "A",
    powerValue: 0.39,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 11:10",
    currentValue: 4.7,
    currentUnit: "A",
    powerValue: 1.01,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 11:10",
    currentValue: 1,
    currentUnit: "A",
    powerValue: 0.21,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 11:10",
    currentValue: 34.7,
    currentUnit: "A",
    powerValue: 7.49,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 11:11",
    currentValue: 6.8,
    currentUnit: "A",
    powerValue: 1.46,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 11:11",
    currentValue: 5,
    currentUnit: "A",
    powerValue: 1.08,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 11:11",
    currentValue: 1.8,
    currentUnit: "A",
    powerValue: 0.39,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 11:11",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 11:12",
    currentValue: 1.8,
    currentUnit: "A",
    powerValue: 0.39,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 11:12",
    currentValue: 5,
    currentUnit: "A",
    powerValue: 1.08,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 11:12",
    currentValue: 1,
    currentUnit: "A",
    powerValue: 0.21,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 11:12",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 11:13",
    currentValue: 1.8,
    currentUnit: "A",
    powerValue: 0.39,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 11:13",
    currentValue: 4.4,
    currentUnit: "A",
    powerValue: 0.95,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 11:13",
    currentValue: 3.2,
    currentUnit: "A",
    powerValue: 0.69,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 11:13",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 11:14",
    currentValue: 1.8,
    currentUnit: "A",
    powerValue: 0.39,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 11:14",
    currentValue: 5.2,
    currentUnit: "A",
    powerValue: 1.12,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 11:14",
    currentValue: 1,
    currentUnit: "A",
    powerValue: 0.21,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 11:14",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 11:15",
    currentValue: 1.8,
    currentUnit: "A",
    powerValue: 0.39,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 11:15",
    currentValue: 4.6,
    currentUnit: "A",
    powerValue: 0.99,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 11:15",
    currentValue: 4.9,
    currentUnit: "A",
    powerValue: 1.05,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 11:15",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 11:16",
    currentValue: 1.8,
    currentUnit: "A",
    powerValue: 0.39,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 11:16",
    currentValue: 4.4,
    currentUnit: "A",
    powerValue: 0.95,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 11:16",
    currentValue: 1,
    currentUnit: "A",
    powerValue: 0.21,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 11:16",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 11:17",
    currentValue: 1.8,
    currentUnit: "A",
    powerValue: 0.39,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 11:17",
    currentValue: 4.7,
    currentUnit: "A",
    powerValue: 1.01,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 11:17",
    currentValue: 4.9,
    currentUnit: "A",
    powerValue: 1.05,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 11:17",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 11:18",
    currentValue: 1.7,
    currentUnit: "A",
    powerValue: 0.37,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 11:18",
    currentValue: 4.8,
    currentUnit: "A",
    powerValue: 1.03,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 11:18",
    currentValue: 44,
    currentUnit: "A",
    powerValue: 9.35,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 11:18",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 11:19",
    currentValue: 1.8,
    currentUnit: "A",
    powerValue: 0.39,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 11:19",
    currentValue: 4.9,
    currentUnit: "A",
    powerValue: 1.06,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 11:19",
    currentValue: 4.9,
    currentUnit: "A",
    powerValue: 1.05,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 11:19",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 11:20",
    currentValue: 6.8,
    currentUnit: "A",
    powerValue: 1.46,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 11:20",
    currentValue: 4.4,
    currentUnit: "A",
    powerValue: 0.95,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 11:20",
    currentValue: 1,
    currentUnit: "A",
    powerValue: 0.21,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 11:20",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 11:21",
    currentValue: 1.8,
    currentUnit: "A",
    powerValue: 0.39,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 11:21",
    currentValue: 8.2,
    currentUnit: "A",
    powerValue: 1.76,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 11:21",
    currentValue: 4.9,
    currentUnit: "A",
    powerValue: 1.05,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 11:21",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 11:22",
    currentValue: 1.7,
    currentUnit: "A",
    powerValue: 0.37,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 11:22",
    currentValue: 5.2,
    currentUnit: "A",
    powerValue: 1.12,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 11:22",
    currentValue: 1,
    currentUnit: "A",
    powerValue: 0.21,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 11:22",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 11:23",
    currentValue: 1.8,
    currentUnit: "A",
    powerValue: 0.39,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 11:23",
    currentValue: 4.4,
    currentUnit: "A",
    powerValue: 0.95,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 11:23",
    currentValue: 4.9,
    currentUnit: "A",
    powerValue: 1.05,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 11:23",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 11:24",
    currentValue: 69,
    currentUnit: "A",
    powerValue: 14.78,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 11:24",
    currentValue: 4.3,
    currentUnit: "A",
    powerValue: 0.93,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 11:24",
    currentValue: 1,
    currentUnit: "A",
    powerValue: 0.21,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 11:24",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 11:25",
    currentValue: 67,
    currentUnit: "A",
    powerValue: 14.36,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 11:25",
    currentValue: 4.4,
    currentUnit: "A",
    powerValue: 0.95,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 11:25",
    currentValue: 4.9,
    currentUnit: "A",
    powerValue: 1.05,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 11:25",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 11:26",
    currentValue: 61.2,
    currentUnit: "A",
    powerValue: 13.13,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 11:26",
    currentValue: 4.4,
    currentUnit: "A",
    powerValue: 0.95,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 11:26",
    currentValue: 1,
    currentUnit: "A",
    powerValue: 0.21,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 11:26",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 11:27",
    currentValue: 60.7,
    currentUnit: "A",
    powerValue: 13.03,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 11:27",
    currentValue: 4.3,
    currentUnit: "A",
    powerValue: 0.93,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 11:27",
    currentValue: 4.9,
    currentUnit: "A",
    powerValue: 1.05,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 11:27",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 11:28",
    currentValue: 60.8,
    currentUnit: "A",
    powerValue: 13.04,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 11:28",
    currentValue: 4.4,
    currentUnit: "A",
    powerValue: 0.95,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 11:28",
    currentValue: 1,
    currentUnit: "A",
    powerValue: 0.21,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 11:28",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 11:29",
    currentValue: 59.6,
    currentUnit: "A",
    powerValue: 12.78,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 11:29",
    currentValue: 4.4,
    currentUnit: "A",
    powerValue: 0.95,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 11:29",
    currentValue: 4.9,
    currentUnit: "A",
    powerValue: 1.05,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 11:29",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 11:30",
    currentValue: 57.7,
    currentUnit: "A",
    powerValue: 12.38,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 11:30",
    currentValue: 2.8,
    currentUnit: "A",
    powerValue: 0.6,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 11:30",
    currentValue: 1,
    currentUnit: "A",
    powerValue: 0.21,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 11:30",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 11:31",
    currentValue: 54,
    currentUnit: "A",
    powerValue: 11.6,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 11:31",
    currentValue: 2.8,
    currentUnit: "A",
    powerValue: 0.6,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 11:31",
    currentValue: 4.9,
    currentUnit: "A",
    powerValue: 1.05,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 11:31",
    currentValue: 34.1,
    currentUnit: "A",
    powerValue: 7.36,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 11:32",
    currentValue: 53.4,
    currentUnit: "A",
    powerValue: 11.46,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 11:32",
    currentValue: 2.8,
    currentUnit: "A",
    powerValue: 0.6,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 11:32",
    currentValue: 1,
    currentUnit: "A",
    powerValue: 0.21,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 11:32",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 11:33",
    currentValue: 52.8,
    currentUnit: "A",
    powerValue: 11.35,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 11:33",
    currentValue: 2.8,
    currentUnit: "A",
    powerValue: 0.6,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 11:33",
    currentValue: 4.9,
    currentUnit: "A",
    powerValue: 1.05,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 11:33",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 11:34",
    currentValue: 57.9,
    currentUnit: "A",
    powerValue: 12.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 11:34",
    currentValue: 2.8,
    currentUnit: "A",
    powerValue: 0.6,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 11:34",
    currentValue: 1,
    currentUnit: "A",
    powerValue: 0.21,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 11:34",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 11:35",
    currentValue: 52.7,
    currentUnit: "A",
    powerValue: 11.32,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 11:35",
    currentValue: 2.7,
    currentUnit: "A",
    powerValue: 0.58,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 11:35",
    currentValue: 4.9,
    currentUnit: "A",
    powerValue: 1.05,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 11:35",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 11:36",
    currentValue: 52.6,
    currentUnit: "A",
    powerValue: 11.3,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 11:36",
    currentValue: 8.4,
    currentUnit: "A",
    powerValue: 1.8,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 11:36",
    currentValue: 1,
    currentUnit: "A",
    powerValue: 0.21,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 11:36",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 11:37",
    currentValue: 52.2,
    currentUnit: "A",
    powerValue: 11.21,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 11:37",
    currentValue: 2.8,
    currentUnit: "A",
    powerValue: 0.6,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 11:37",
    currentValue: 1,
    currentUnit: "A",
    powerValue: 0.21,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 11:37",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 11:38",
    currentValue: 52.5,
    currentUnit: "A",
    powerValue: 11.28,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 11:38",
    currentValue: 2.8,
    currentUnit: "A",
    powerValue: 0.6,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 11:38",
    currentValue: 1,
    currentUnit: "A",
    powerValue: 0.21,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 11:38",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 11:39",
    currentValue: 52.1,
    currentUnit: "A",
    powerValue: 11.19,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 11:39",
    currentValue: 2.8,
    currentUnit: "A",
    powerValue: 0.6,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 11:39",
    currentValue: 4.9,
    currentUnit: "A",
    powerValue: 1.05,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 11:39",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 11:40",
    currentValue: 52,
    currentUnit: "A",
    powerValue: 11.17,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 11:40",
    currentValue: 2.8,
    currentUnit: "A",
    powerValue: 0.6,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 11:40",
    currentValue: 6.8,
    currentUnit: "A",
    powerValue: 1.46,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 11:40",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 11:41",
    currentValue: 56.9,
    currentUnit: "A",
    powerValue: 12.22,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 11:41",
    currentValue: 2.8,
    currentUnit: "A",
    powerValue: 0.6,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 11:41",
    currentValue: 4.9,
    currentUnit: "A",
    powerValue: 1.05,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 11:41",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 11:42",
    currentValue: 52,
    currentUnit: "A",
    powerValue: 11.17,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 11:42",
    currentValue: 7.7,
    currentUnit: "A",
    powerValue: 1.65,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 11:42",
    currentValue: 1,
    currentUnit: "A",
    powerValue: 0.21,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 11:42",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 11:43",
    currentValue: 51,
    currentUnit: "A",
    powerValue: 10.96,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 11:43",
    currentValue: 2.8,
    currentUnit: "A",
    powerValue: 0.6,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 11:43",
    currentValue: 10.7,
    currentUnit: "A",
    powerValue: 2.29,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 11:43",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 11:44",
    currentValue: 51.7,
    currentUnit: "A",
    powerValue: 11.11,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 11:44",
    currentValue: 2.8,
    currentUnit: "A",
    powerValue: 0.6,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 11:44",
    currentValue: 1,
    currentUnit: "A",
    powerValue: 0.21,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 11:44",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 11:45",
    currentValue: 50.9,
    currentUnit: "A",
    powerValue: 10.94,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 11:45",
    currentValue: 7.7,
    currentUnit: "A",
    powerValue: 1.65,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 11:45",
    currentValue: 4.9,
    currentUnit: "A",
    powerValue: 1.05,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 11:45",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 11:46",
    currentValue: 50.8,
    currentUnit: "A",
    powerValue: 10.92,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 11:46",
    currentValue: 2.8,
    currentUnit: "A",
    powerValue: 0.6,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 11:46",
    currentValue: 6.8,
    currentUnit: "A",
    powerValue: 1.46,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 11:46",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 11:47",
    currentValue: 43.8,
    currentUnit: "A",
    powerValue: 9.42,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 11:47",
    currentValue: 2.8,
    currentUnit: "A",
    powerValue: 0.6,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 11:47",
    currentValue: 4.9,
    currentUnit: "A",
    powerValue: 1.05,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 11:47",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 11:48",
    currentValue: 48.5,
    currentUnit: "A",
    powerValue: 10.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 11:48",
    currentValue: 7.7,
    currentUnit: "A",
    powerValue: 1.65,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 11:48",
    currentValue: 1,
    currentUnit: "A",
    powerValue: 0.21,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 11:48",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 11:49",
    currentValue: 44.2,
    currentUnit: "A",
    powerValue: 9.51,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 11:49",
    currentValue: 2.8,
    currentUnit: "A",
    powerValue: 0.6,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 11:49",
    currentValue: 10.7,
    currentUnit: "A",
    powerValue: 2.29,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 11:49",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 11:50",
    currentValue: 44.7,
    currentUnit: "A",
    powerValue: 9.62,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 11:50",
    currentValue: 2.8,
    currentUnit: "A",
    powerValue: 0.6,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 11:50",
    currentValue: 1,
    currentUnit: "A",
    powerValue: 0.21,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 11:50",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 11:51",
    currentValue: 44.9,
    currentUnit: "A",
    powerValue: 9.66,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 11:51",
    currentValue: 7.7,
    currentUnit: "A",
    powerValue: 1.65,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 11:51",
    currentValue: 4.9,
    currentUnit: "A",
    powerValue: 1.05,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 11:51",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 11:52",
    currentValue: 44.2,
    currentUnit: "A",
    powerValue: 9.51,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 11:52",
    currentValue: 2.8,
    currentUnit: "A",
    powerValue: 0.6,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 11:52",
    currentValue: 6.8,
    currentUnit: "A",
    powerValue: 1.46,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 11:52",
    currentValue: 34.1,
    currentUnit: "A",
    powerValue: 7.36,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 11:53",
    currentValue: 43.9,
    currentUnit: "A",
    powerValue: 9.45,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 11:53",
    currentValue: 2.8,
    currentUnit: "A",
    powerValue: 0.6,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 11:53",
    currentValue: 4.9,
    currentUnit: "A",
    powerValue: 1.05,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 11:53",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 11:54",
    currentValue: 44.1,
    currentUnit: "A",
    powerValue: 9.49,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 11:54",
    currentValue: 2.8,
    currentUnit: "A",
    powerValue: 0.6,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 11:54",
    currentValue: 1,
    currentUnit: "A",
    powerValue: 0.21,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 11:54",
    currentValue: 68.3,
    currentUnit: "A",
    powerValue: 14.53,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 11:55",
    currentValue: 48.6,
    currentUnit: "A",
    powerValue: 10.47,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 11:55",
    currentValue: 2.8,
    currentUnit: "A",
    powerValue: 0.6,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 11:55",
    currentValue: 6,
    currentUnit: "A",
    powerValue: 1.29,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 11:55",
    currentValue: 68.8,
    currentUnit: "A",
    powerValue: 14.62,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 11:56",
    currentValue: 42.9,
    currentUnit: "A",
    powerValue: 9.25,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 11:56",
    currentValue: 2.8,
    currentUnit: "A",
    powerValue: 0.6,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 11:56",
    currentValue: 1,
    currentUnit: "A",
    powerValue: 0.21,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 11:56",
    currentValue: 68.8,
    currentUnit: "A",
    powerValue: 14.62,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 11:57",
    currentValue: 39.2,
    currentUnit: "A",
    powerValue: 8.46,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 11:57",
    currentValue: 2.8,
    currentUnit: "A",
    powerValue: 0.6,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 11:57",
    currentValue: 4.9,
    currentUnit: "A",
    powerValue: 1.05,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 11:57",
    currentValue: 68.8,
    currentUnit: "A",
    powerValue: 14.63,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 11:58",
    currentValue: 39.3,
    currentUnit: "A",
    powerValue: 8.48,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 11:58",
    currentValue: 2.8,
    currentUnit: "A",
    powerValue: 0.6,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 11:58",
    currentValue: 6.8,
    currentUnit: "A",
    powerValue: 1.46,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 11:58",
    currentValue: 68.7,
    currentUnit: "A",
    powerValue: 14.58,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 11:59",
    currentValue: 38.9,
    currentUnit: "A",
    powerValue: 8.39,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 11:59",
    currentValue: 72.3,
    currentUnit: "A",
    powerValue: 15.55,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 11:59",
    currentValue: 4.9,
    currentUnit: "A",
    powerValue: 1.05,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 11:59",
    currentValue: 68.6,
    currentUnit: "A",
    powerValue: 14.57,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 12:00",
    currentValue: 1.7,
    currentUnit: "A",
    powerValue: 0.37,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 12:00",
    currentValue: 2.9,
    currentUnit: "A",
    powerValue: 0.62,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 12:00",
    currentValue: 1,
    currentUnit: "A",
    powerValue: 0.21,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 12:00",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 12:01",
    currentValue: 1.7,
    currentUnit: "A",
    powerValue: 0.37,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 12:01",
    currentValue: 2.8,
    currentUnit: "A",
    powerValue: 0.6,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 12:01",
    currentValue: 10.7,
    currentUnit: "A",
    powerValue: 2.29,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 12:01",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 12:02",
    currentValue: 6.8,
    currentUnit: "A",
    powerValue: 1.46,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 12:02",
    currentValue: 2.8,
    currentUnit: "A",
    powerValue: 0.6,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 12:02",
    currentValue: 1,
    currentUnit: "A",
    powerValue: 0.21,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 12:02",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 12:03",
    currentValue: 1.8,
    currentUnit: "A",
    powerValue: 0.39,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 12:03",
    currentValue: 2.8,
    currentUnit: "A",
    powerValue: 0.6,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 12:03",
    currentValue: 4.9,
    currentUnit: "A",
    powerValue: 1.05,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 12:03",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 12:04",
    currentValue: 1.8,
    currentUnit: "A",
    powerValue: 0.39,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 12:04",
    currentValue: 2.8,
    currentUnit: "A",
    powerValue: 0.6,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 12:04",
    currentValue: 6.8,
    currentUnit: "A",
    powerValue: 1.46,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 12:04",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 12:05",
    currentValue: 1.7,
    currentUnit: "A",
    powerValue: 0.37,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 12:05",
    currentValue: 2.8,
    currentUnit: "A",
    powerValue: 0.6,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 12:05",
    currentValue: 4.9,
    currentUnit: "A",
    powerValue: 1.05,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 12:05",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 12:06",
    currentValue: 1.8,
    currentUnit: "A",
    powerValue: 0.39,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 12:06",
    currentValue: 6.3,
    currentUnit: "A",
    powerValue: 1.35,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 12:06",
    currentValue: 1,
    currentUnit: "A",
    powerValue: 0.21,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 12:06",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 12:07",
    currentValue: 1.7,
    currentUnit: "A",
    powerValue: 0.37,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 12:07",
    currentValue: 2.8,
    currentUnit: "A",
    powerValue: 0.6,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 12:07",
    currentValue: 10.7,
    currentUnit: "A",
    powerValue: 2.29,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 12:07",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 12:08",
    currentValue: 1.8,
    currentUnit: "A",
    powerValue: 0.39,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 12:08",
    currentValue: 2.8,
    currentUnit: "A",
    powerValue: 0.6,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 12:08",
    currentValue: 9.6,
    currentUnit: "A",
    powerValue: 2.05,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 12:08",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 12:09",
    currentValue: 6.8,
    currentUnit: "A",
    powerValue: 1.45,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 12:09",
    currentValue: 2.8,
    currentUnit: "A",
    powerValue: 0.6,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 12:09",
    currentValue: 4.9,
    currentUnit: "A",
    powerValue: 1.05,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 12:09",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 12:10",
    currentValue: 1.8,
    currentUnit: "A",
    powerValue: 0.39,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 12:10",
    currentValue: 2.8,
    currentUnit: "A",
    powerValue: 0.6,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 12:10",
    currentValue: 6.8,
    currentUnit: "A",
    powerValue: 1.46,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 12:10",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 12:11",
    currentValue: 1.7,
    currentUnit: "A",
    powerValue: 0.37,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 12:11",
    currentValue: 2.8,
    currentUnit: "A",
    powerValue: 0.6,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 12:11",
    currentValue: 4.9,
    currentUnit: "A",
    powerValue: 1.05,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 12:11",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 12:12",
    currentValue: 1.8,
    currentUnit: "A",
    powerValue: 0.39,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 12:12",
    currentValue: 2.8,
    currentUnit: "A",
    powerValue: 0.6,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 12:12",
    currentValue: 1,
    currentUnit: "A",
    powerValue: 0.21,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 12:12",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 12:13",
    currentValue: 1.8,
    currentUnit: "A",
    powerValue: 0.39,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 12:13",
    currentValue: 2.8,
    currentUnit: "A",
    powerValue: 0.6,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 12:13",
    currentValue: 10.7,
    currentUnit: "A",
    powerValue: 2.29,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 12:13",
    currentValue: 35,
    currentUnit: "A",
    powerValue: 7.55,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 12:14",
    currentValue: 1.7,
    currentUnit: "A",
    powerValue: 0.37,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 12:14",
    currentValue: 2.8,
    currentUnit: "A",
    powerValue: 0.6,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 12:14",
    currentValue: 1,
    currentUnit: "A",
    powerValue: 0.21,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 12:14",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 12:15",
    currentValue: 1.7,
    currentUnit: "A",
    powerValue: 0.37,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 12:15",
    currentValue: 7.7,
    currentUnit: "A",
    powerValue: 1.65,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 12:15",
    currentValue: 4.9,
    currentUnit: "A",
    powerValue: 1.05,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 12:15",
    currentValue: 58.9,
    currentUnit: "A",
    powerValue: 12.66,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 12:16",
    currentValue: 6.8,
    currentUnit: "A",
    powerValue: 1.45,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 12:16",
    currentValue: 72.4,
    currentUnit: "A",
    powerValue: 15.53,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 12:16",
    currentValue: 6.8,
    currentUnit: "A",
    powerValue: 1.46,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 12:16",
    currentValue: 40.9,
    currentUnit: "A",
    powerValue: 8.81,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 12:17",
    currentValue: 1.8,
    currentUnit: "A",
    powerValue: 0.39,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 12:17",
    currentValue: 72.3,
    currentUnit: "A",
    powerValue: 15.51,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 12:17",
    currentValue: 4.9,
    currentUnit: "A",
    powerValue: 1.05,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 12:17",
    currentValue: 41,
    currentUnit: "A",
    powerValue: 8.83,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 12:18",
    currentValue: 1.8,
    currentUnit: "A",
    powerValue: 0.39,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 12:18",
    currentValue: 77.1,
    currentUnit: "A",
    powerValue: 16.54,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 12:18",
    currentValue: 1,
    currentUnit: "A",
    powerValue: 0.21,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 12:18",
    currentValue: 40.8,
    currentUnit: "A",
    powerValue: 8.79,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 12:19",
    currentValue: 1.8,
    currentUnit: "A",
    powerValue: 0.39,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 12:19",
    currentValue: 72.2,
    currentUnit: "A",
    powerValue: 15.49,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 12:19",
    currentValue: 10.7,
    currentUnit: "A",
    powerValue: 2.29,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 12:19",
    currentValue: 40.6,
    currentUnit: "A",
    powerValue: 8.75,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 12:20",
    currentValue: 1.8,
    currentUnit: "A",
    powerValue: 0.39,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 12:20",
    currentValue: 67,
    currentUnit: "A",
    powerValue: 14.4,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 12:20",
    currentValue: 1,
    currentUnit: "A",
    powerValue: 0.21,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 12:20",
    currentValue: 107.1,
    currentUnit: "A",
    powerValue: 22.85,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 12:21",
    currentValue: 1.7,
    currentUnit: "A",
    powerValue: 0.37,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 12:21",
    currentValue: 66.8,
    currentUnit: "A",
    powerValue: 14.35,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 12:21",
    currentValue: 4.9,
    currentUnit: "A",
    powerValue: 1.05,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 12:21",
    currentValue: 40.2,
    currentUnit: "A",
    powerValue: 8.67,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 12:22",
    currentValue: 1.8,
    currentUnit: "A",
    powerValue: 0.39,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 12:22",
    currentValue: 60.7,
    currentUnit: "A",
    powerValue: 13.05,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 12:22",
    currentValue: 6.8,
    currentUnit: "A",
    powerValue: 1.46,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 12:22",
    currentValue: 40,
    currentUnit: "A",
    powerValue: 8.62,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 12:23",
    currentValue: 6.8,
    currentUnit: "A",
    powerValue: 1.45,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 12:23",
    currentValue: 59.7,
    currentUnit: "A",
    powerValue: 12.83,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 12:23",
    currentValue: 4.9,
    currentUnit: "A",
    powerValue: 1.05,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 12:23",
    currentValue: 39.9,
    currentUnit: "A",
    powerValue: 8.6,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 12:24",
    currentValue: 1.7,
    currentUnit: "A",
    powerValue: 0.37,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 12:24",
    currentValue: 64.1,
    currentUnit: "A",
    powerValue: 13.78,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 12:24",
    currentValue: 1,
    currentUnit: "A",
    powerValue: 0.21,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 12:24",
    currentValue: 39.7,
    currentUnit: "A",
    powerValue: 8.56,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 12:25",
    currentValue: 1.7,
    currentUnit: "A",
    powerValue: 0.37,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 12:25",
    currentValue: 58.3,
    currentUnit: "A",
    powerValue: 12.53,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 12:25",
    currentValue: 10.7,
    currentUnit: "A",
    powerValue: 2.29,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 12:25",
    currentValue: 39.6,
    currentUnit: "A",
    powerValue: 8.53,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 12:26",
    currentValue: 1.8,
    currentUnit: "A",
    powerValue: 0.39,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 12:26",
    currentValue: 56.4,
    currentUnit: "A",
    powerValue: 12.14,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 12:26",
    currentValue: 1,
    currentUnit: "A",
    powerValue: 0.21,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 12:26",
    currentValue: 39.5,
    currentUnit: "A",
    powerValue: 8.51,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 12:27",
    currentValue: 1.8,
    currentUnit: "A",
    powerValue: 0.39,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 12:27",
    currentValue: 57.4,
    currentUnit: "A",
    powerValue: 12.34,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 12:27",
    currentValue: 1.8,
    currentUnit: "A",
    powerValue: 0.39,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 12:27",
    currentValue: 39.3,
    currentUnit: "A",
    powerValue: 8.47,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 12:28",
    currentValue: 1.7,
    currentUnit: "A",
    powerValue: 0.37,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 12:28",
    currentValue: 52,
    currentUnit: "A",
    powerValue: 11.18,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 12:28",
    currentValue: 1.7,
    currentUnit: "A",
    powerValue: 0.36,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 12:28",
    currentValue: 39.4,
    currentUnit: "A",
    powerValue: 8.5,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 12:29",
    currentValue: 1.8,
    currentUnit: "A",
    powerValue: 0.39,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 12:29",
    currentValue: 51.1,
    currentUnit: "A",
    powerValue: 11,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 12:29",
    currentValue: 1.8,
    currentUnit: "A",
    powerValue: 0.38,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 12:29",
    currentValue: 39.1,
    currentUnit: "A",
    powerValue: 8.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 12:30",
    currentValue: 6.8,
    currentUnit: "A",
    powerValue: 1.45,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 12:30",
    currentValue: 7.8,
    currentUnit: "A",
    powerValue: 1.67,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 12:30",
    currentValue: 1,
    currentUnit: "A",
    powerValue: 0.21,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 12:30",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 12:31",
    currentValue: 1.8,
    currentUnit: "A",
    powerValue: 0.39,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 12:31",
    currentValue: 72.9,
    currentUnit: "A",
    powerValue: 15.5,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 12:31",
    currentValue: 7.6,
    currentUnit: "A",
    powerValue: 1.63,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 12:31",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 12:32",
    currentValue: 1.9,
    currentUnit: "A",
    powerValue: 0.41,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 12:32",
    currentValue: 72,
    currentUnit: "A",
    powerValue: 15.31,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 12:32",
    currentValue: 1,
    currentUnit: "A",
    powerValue: 0.21,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 12:32",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 12:33",
    currentValue: 1.8,
    currentUnit: "A",
    powerValue: 0.39,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 12:33",
    currentValue: 76.2,
    currentUnit: "A",
    powerValue: 16.22,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 12:33",
    currentValue: 1,
    currentUnit: "A",
    powerValue: 0.21,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 12:33",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 12:34",
    currentValue: 1.8,
    currentUnit: "A",
    powerValue: 0.39,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 12:34",
    currentValue: 70.6,
    currentUnit: "A",
    powerValue: 15.01,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 12:34",
    currentValue: 6.8,
    currentUnit: "A",
    powerValue: 1.46,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 12:34",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 12:35",
    currentValue: 1.7,
    currentUnit: "A",
    powerValue: 0.37,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 12:35",
    currentValue: 70.7,
    currentUnit: "A",
    powerValue: 15.04,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 12:35",
    currentValue: 1,
    currentUnit: "A",
    powerValue: 0.21,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 12:35",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 12:36",
    currentValue: 1.8,
    currentUnit: "A",
    powerValue: 0.39,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 12:36",
    currentValue: 70.5,
    currentUnit: "A",
    powerValue: 15,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 12:36",
    currentValue: 1,
    currentUnit: "A",
    powerValue: 0.21,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 12:36",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 12:37",
    currentValue: 6.8,
    currentUnit: "A",
    powerValue: 1.45,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 12:37",
    currentValue: 66,
    currentUnit: "A",
    powerValue: 14.05,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 12:37",
    currentValue: 3,
    currentUnit: "A",
    powerValue: 0.64,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 12:37",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 12:38",
    currentValue: 1.8,
    currentUnit: "A",
    powerValue: 0.39,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 12:38",
    currentValue: 64.3,
    currentUnit: "A",
    powerValue: 13.7,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 12:38",
    currentValue: 1,
    currentUnit: "A",
    powerValue: 0.21,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 12:38",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 12:39",
    currentValue: 1.8,
    currentUnit: "A",
    powerValue: 0.39,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 12:39",
    currentValue: 63.1,
    currentUnit: "A",
    powerValue: 13.45,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 12:39",
    currentValue: 1,
    currentUnit: "A",
    powerValue: 0.21,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 12:39",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 12:40",
    currentValue: 1.8,
    currentUnit: "A",
    powerValue: 0.39,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 12:40",
    currentValue: 63.2,
    currentUnit: "A",
    powerValue: 13.48,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 12:40",
    currentValue: 6.8,
    currentUnit: "A",
    powerValue: 1.46,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 12:40",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 12:41",
    currentValue: 1.7,
    currentUnit: "A",
    powerValue: 0.37,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 12:41",
    currentValue: 61.5,
    currentUnit: "A",
    powerValue: 13.12,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 12:41",
    currentValue: 1,
    currentUnit: "A",
    powerValue: 0.21,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 12:41",
    currentValue: 68.4,
    currentUnit: "A",
    powerValue: 14.56,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 12:42",
    currentValue: 1.7,
    currentUnit: "A",
    powerValue: 0.37,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 12:42",
    currentValue: 60.2,
    currentUnit: "A",
    powerValue: 12.83,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 12:42",
    currentValue: 1,
    currentUnit: "A",
    powerValue: 0.21,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 12:42",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 12:43",
    currentValue: 1.8,
    currentUnit: "A",
    powerValue: 0.39,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 12:43",
    currentValue: 54.8,
    currentUnit: "A",
    powerValue: 11.7,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 12:43",
    currentValue: 6.8,
    currentUnit: "A",
    powerValue: 1.46,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 12:43",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 12:44",
    currentValue: 6.8,
    currentUnit: "A",
    powerValue: 1.45,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 12:44",
    currentValue: 54.6,
    currentUnit: "A",
    powerValue: 11.66,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 12:44",
    currentValue: 1,
    currentUnit: "A",
    powerValue: 0.21,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 12:44",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 12:45",
    currentValue: 1.8,
    currentUnit: "A",
    powerValue: 0.39,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 12:45",
    currentValue: 54.4,
    currentUnit: "A",
    powerValue: 11.61,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 12:45",
    currentValue: 1,
    currentUnit: "A",
    powerValue: 0.21,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 12:45",
    currentValue: 56.7,
    currentUnit: "A",
    powerValue: 12.21,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 12:46",
    currentValue: 1.7,
    currentUnit: "A",
    powerValue: 0.37,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 12:46",
    currentValue: 54.4,
    currentUnit: "A",
    powerValue: 11.6,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 12:46",
    currentValue: 57.8,
    currentUnit: "A",
    powerValue: 12.26,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 12:46",
    currentValue: 38.1,
    currentUnit: "A",
    powerValue: 8.22,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 12:47",
    currentValue: 1.8,
    currentUnit: "A",
    powerValue: 0.39,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 12:47",
    currentValue: 53.9,
    currentUnit: "A",
    powerValue: 11.49,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 12:47",
    currentValue: 50.7,
    currentUnit: "A",
    powerValue: 10.75,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 12:47",
    currentValue: 39,
    currentUnit: "A",
    powerValue: 8.42,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 12:48",
    currentValue: 1.7,
    currentUnit: "A",
    powerValue: 0.37,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 12:48",
    currentValue: 53.5,
    currentUnit: "A",
    powerValue: 11.41,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 12:48",
    currentValue: 50.6,
    currentUnit: "A",
    powerValue: 10.71,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 12:48",
    currentValue: 35.2,
    currentUnit: "A",
    powerValue: 7.6,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 12:49",
    currentValue: 1.7,
    currentUnit: "A",
    powerValue: 0.37,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 12:49",
    currentValue: 53.2,
    currentUnit: "A",
    powerValue: 11.34,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 12:49",
    currentValue: 54,
    currentUnit: "A",
    powerValue: 11.45,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 12:49",
    currentValue: 34.6,
    currentUnit: "A",
    powerValue: 7.47,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 12:50",
    currentValue: 1.7,
    currentUnit: "A",
    powerValue: 0.37,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 12:50",
    currentValue: 52.4,
    currentUnit: "A",
    powerValue: 12.86,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 12:50",
    currentValue: 50.5,
    currentUnit: "A",
    powerValue: 10.7,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 12:50",
    currentValue: 34.7,
    currentUnit: "A",
    powerValue: 7.49,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 12:51",
    currentValue: 6.8,
    currentUnit: "A",
    powerValue: 1.45,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 12:51",
    currentValue: 52.3,
    currentUnit: "A",
    powerValue: 11.15,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 12:51",
    currentValue: 43.7,
    currentUnit: "A",
    powerValue: 9.27,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 12:51",
    currentValue: 35.1,
    currentUnit: "A",
    powerValue: 7.58,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 12:52",
    currentValue: 1.7,
    currentUnit: "A",
    powerValue: 0.37,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 12:52",
    currentValue: 40.8,
    currentUnit: "A",
    powerValue: 8.72,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 12:52",
    currentValue: 44.1,
    currentUnit: "A",
    powerValue: 9.36,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 12:52",
    currentValue: 35.1,
    currentUnit: "A",
    powerValue: 7.58,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 12:53",
    currentValue: 1.7,
    currentUnit: "A",
    powerValue: 0.37,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 12:53",
    currentValue: 40.3,
    currentUnit: "A",
    powerValue: 8.61,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 12:53",
    currentValue: 42.9,
    currentUnit: "A",
    powerValue: 9.09,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 12:53",
    currentValue: 35.2,
    currentUnit: "A",
    powerValue: 7.6,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 12:54",
    currentValue: 1.8,
    currentUnit: "A",
    powerValue: 0.39,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 12:54",
    currentValue: 40.4,
    currentUnit: "A",
    powerValue: 8.64,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 12:54",
    currentValue: 43.1,
    currentUnit: "A",
    powerValue: 9.15,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 12:54",
    currentValue: 35.3,
    currentUnit: "A",
    powerValue: 7.62,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 12:55",
    currentValue: 1.7,
    currentUnit: "A",
    powerValue: 0.37,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 12:55",
    currentValue: 40.4,
    currentUnit: "A",
    powerValue: 8.64,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 12:55",
    currentValue: 49.8,
    currentUnit: "A",
    powerValue: 10.58,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 12:55",
    currentValue: 35.2,
    currentUnit: "A",
    powerValue: 7.6,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 12:56",
    currentValue: 1.7,
    currentUnit: "A",
    powerValue: 0.37,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 12:56",
    currentValue: 40.4,
    currentUnit: "A",
    powerValue: 8.64,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 12:56",
    currentValue: 43.3,
    currentUnit: "A",
    powerValue: 9.18,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 12:56",
    currentValue: 35.2,
    currentUnit: "A",
    powerValue: 7.6,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 12:57",
    currentValue: 1.8,
    currentUnit: "A",
    powerValue: 0.39,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 12:57",
    currentValue: 40.4,
    currentUnit: "A",
    powerValue: 8.64,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 12:57",
    currentValue: 42.3,
    currentUnit: "A",
    powerValue: 8.97,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 12:57",
    currentValue: 35.3,
    currentUnit: "A",
    powerValue: 7.62,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 12:58",
    currentValue: 6.8,
    currentUnit: "A",
    powerValue: 1.45,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 12:58",
    currentValue: 40.5,
    currentUnit: "A",
    powerValue: 8.65,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 12:58",
    currentValue: 43,
    currentUnit: "A",
    powerValue: 9.13,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 12:58",
    currentValue: 35.2,
    currentUnit: "A",
    powerValue: 7.6,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 12:59",
    currentValue: 1.7,
    currentUnit: "A",
    powerValue: 0.37,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 12:59",
    currentValue: 40.6,
    currentUnit: "A",
    powerValue: 8.67,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 12:59",
    currentValue: 41.9,
    currentUnit: "A",
    powerValue: 8.89,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 12:59",
    currentValue: 35.3,
    currentUnit: "A",
    powerValue: 7.61,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 01:00",
    currentValue: 1.7,
    currentUnit: "A",
    powerValue: 0.37,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 01:00",
    currentValue: 2.7,
    currentUnit: "A",
    powerValue: 0.58,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 01:00",
    currentValue: 41.5,
    currentUnit: "A",
    powerValue: 8.81,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 01:00",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 01:01",
    currentValue: 1.8,
    currentUnit: "A",
    powerValue: 0.39,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 01:01",
    currentValue: 2.8,
    currentUnit: "A",
    powerValue: 0.6,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 01:01",
    currentValue: 1.9,
    currentUnit: "A",
    powerValue: 0.41,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 01:01",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 01:02",
    currentValue: 1.8,
    currentUnit: "A",
    powerValue: 0.39,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 01:02",
    currentValue: 2.8,
    currentUnit: "A",
    powerValue: 0.6,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 01:02",
    currentValue: 1,
    currentUnit: "A",
    powerValue: 0.21,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 01:02",
    currentValue: 65.7,
    currentUnit: "A",
    powerValue: 13.97,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 01:03",
    currentValue: 6.8,
    currentUnit: "A",
    powerValue: 1.45,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 01:03",
    currentValue: 2.7,
    currentUnit: "A",
    powerValue: 0.58,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 01:03",
    currentValue: 1,
    currentUnit: "A",
    powerValue: 0.21,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 01:03",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 01:04",
    currentValue: 1.8,
    currentUnit: "A",
    powerValue: 0.39,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 01:04",
    currentValue: 2.8,
    currentUnit: "A",
    powerValue: 0.6,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 01:04",
    currentValue: 1.8,
    currentUnit: "A",
    powerValue: 0.38,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 01:04",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 01:05",
    currentValue: 6.8,
    currentUnit: "A",
    powerValue: 1.45,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 01:05",
    currentValue: 2.7,
    currentUnit: "A",
    powerValue: 0.58,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 01:05",
    currentValue: 1,
    currentUnit: "A",
    powerValue: 0.21,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 01:05",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 01:06",
    currentValue: 1.7,
    currentUnit: "A",
    powerValue: 0.37,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 01:06",
    currentValue: 2.8,
    currentUnit: "A",
    powerValue: 0.6,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 01:06",
    currentValue: 1,
    currentUnit: "A",
    powerValue: 0.21,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 01:06",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 01:07",
    currentValue: 1.7,
    currentUnit: "A",
    powerValue: 0.37,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 01:07",
    currentValue: 2.8,
    currentUnit: "A",
    powerValue: 0.6,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 01:07",
    currentValue: 1.7,
    currentUnit: "A",
    powerValue: 0.36,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 01:07",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 01:08",
    currentValue: 1.7,
    currentUnit: "A",
    powerValue: 0.37,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 01:08",
    currentValue: 2.8,
    currentUnit: "A",
    powerValue: 0.6,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 01:08",
    currentValue: 1,
    currentUnit: "A",
    powerValue: 0.21,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 01:08",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 01:09",
    currentValue: 1.7,
    currentUnit: "A",
    powerValue: 0.37,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 01:09",
    currentValue: 2.8,
    currentUnit: "A",
    powerValue: 0.6,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 01:09",
    currentValue: 1,
    currentUnit: "A",
    powerValue: 0.21,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 01:09",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 01:10",
    currentValue: 6.8,
    currentUnit: "A",
    powerValue: 1.45,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 01:10",
    currentValue: 2.8,
    currentUnit: "A",
    powerValue: 0.6,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 01:10",
    currentValue: 4.1,
    currentUnit: "A",
    powerValue: 0.88,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 01:10",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 01:11",
    currentValue: 1.7,
    currentUnit: "A",
    powerValue: 0.37,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 01:11",
    currentValue: 2.7,
    currentUnit: "A",
    powerValue: 0.58,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 01:11",
    currentValue: 1,
    currentUnit: "A",
    powerValue: 0.21,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 01:11",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 01:12",
    currentValue: 1.8,
    currentUnit: "A",
    powerValue: 0.39,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 01:12",
    currentValue: 2.8,
    currentUnit: "A",
    powerValue: 0.6,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 01:12",
    currentValue: 1,
    currentUnit: "A",
    powerValue: 0.21,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 01:12",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 01:13",
    currentValue: 1.7,
    currentUnit: "A",
    powerValue: 0.37,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 01:13",
    currentValue: 2.8,
    currentUnit: "A",
    powerValue: 0.6,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 01:13",
    currentValue: 1.9,
    currentUnit: "A",
    powerValue: 0.41,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 01:13",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 01:14",
    currentValue: 1.7,
    currentUnit: "A",
    powerValue: 0.37,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 01:14",
    currentValue: 2.7,
    currentUnit: "A",
    powerValue: 0.58,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 01:14",
    currentValue: 1,
    currentUnit: "A",
    powerValue: 0.21,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 01:14",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 01:15",
    currentValue: 1.7,
    currentUnit: "A",
    powerValue: 0.37,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 01:15",
    currentValue: 2.8,
    currentUnit: "A",
    powerValue: 0.6,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 01:15",
    currentValue: 1,
    currentUnit: "A",
    powerValue: 0.21,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 01:15",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 01:16",
    currentValue: 1.7,
    currentUnit: "A",
    powerValue: 0.37,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 01:16",
    currentValue: 2.8,
    currentUnit: "A",
    powerValue: 0.6,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 01:16",
    currentValue: 1,
    currentUnit: "A",
    powerValue: 0.21,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 01:16",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 01:17",
    currentValue: 6.8,
    currentUnit: "A",
    powerValue: 1.45,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 01:17",
    currentValue: 2.8,
    currentUnit: "A",
    powerValue: 0.6,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 01:17",
    currentValue: 1,
    currentUnit: "A",
    powerValue: 0.21,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 01:17",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 01:18",
    currentValue: 1.7,
    currentUnit: "A",
    powerValue: 0.37,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 01:18",
    currentValue: 2.8,
    currentUnit: "A",
    powerValue: 0.6,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 01:18",
    currentValue: 1,
    currentUnit: "A",
    powerValue: 0.21,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 01:18",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 01:19",
    currentValue: 1.7,
    currentUnit: "A",
    powerValue: 0.37,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 01:19",
    currentValue: 2.8,
    currentUnit: "A",
    powerValue: 0.6,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 01:19",
    currentValue: 1,
    currentUnit: "A",
    powerValue: 0.21,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 01:19",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 01:20",
    currentValue: 1.7,
    currentUnit: "A",
    powerValue: 0.37,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 01:20",
    currentValue: 2.7,
    currentUnit: "A",
    powerValue: 0.58,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 01:20",
    currentValue: 1,
    currentUnit: "A",
    powerValue: 0.21,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 01:20",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 01:21",
    currentValue: 1.7,
    currentUnit: "A",
    powerValue: 0.37,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 01:21",
    currentValue: 2.8,
    currentUnit: "A",
    powerValue: 0.6,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 01:21",
    currentValue: 1,
    currentUnit: "A",
    powerValue: 0.21,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 01:21",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 01:22",
    currentValue: 5.5,
    currentUnit: "A",
    powerValue: 1.18,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 01:22",
    currentValue: 7.8,
    currentUnit: "A",
    powerValue: 1.67,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 01:22",
    currentValue: 1,
    currentUnit: "A",
    powerValue: 0.21,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 01:22",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 01:23",
    currentValue: 1.7,
    currentUnit: "A",
    powerValue: 0.37,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 01:23",
    currentValue: 2.8,
    currentUnit: "A",
    powerValue: 0.6,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 01:23",
    currentValue: 1,
    currentUnit: "A",
    powerValue: 0.21,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 01:23",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 01:24",
    currentValue: 1.8,
    currentUnit: "A",
    powerValue: 0.39,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 01:24",
    currentValue: 2.8,
    currentUnit: "A",
    powerValue: 0.6,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 01:24",
    currentValue: 1,
    currentUnit: "A",
    powerValue: 0.21,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 01:24",
    currentValue: 36.5,
    currentUnit: "A",
    powerValue: 7.8,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 01:25",
    currentValue: 1.7,
    currentUnit: "A",
    powerValue: 0.37,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 01:25",
    currentValue: 2.8,
    currentUnit: "A",
    powerValue: 0.6,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 01:25",
    currentValue: 1,
    currentUnit: "A",
    powerValue: 0.21,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 01:25",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 01:26",
    currentValue: 1.7,
    currentUnit: "A",
    powerValue: 0.37,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 01:26",
    currentValue: 2.8,
    currentUnit: "A",
    powerValue: 0.6,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 01:26",
    currentValue: 1,
    currentUnit: "A",
    powerValue: 0.21,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 01:26",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 01:27",
    currentValue: 1.7,
    currentUnit: "A",
    powerValue: 0.37,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 01:27",
    currentValue: 2.8,
    currentUnit: "A",
    powerValue: 0.6,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 01:27",
    currentValue: 1,
    currentUnit: "A",
    powerValue: 0.21,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 01:27",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 01:28",
    currentValue: 1.7,
    currentUnit: "A",
    powerValue: 0.37,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 01:28",
    currentValue: 2.8,
    currentUnit: "A",
    powerValue: 0.6,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 01:28",
    currentValue: 1,
    currentUnit: "A",
    powerValue: 0.21,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 01:28",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 01:29",
    currentValue: 6.8,
    currentUnit: "A",
    powerValue: 1.45,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 01:29",
    currentValue: 2.8,
    currentUnit: "A",
    powerValue: 0.6,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 01:29",
    currentValue: 1,
    currentUnit: "A",
    powerValue: 0.21,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 01:29",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 01:30",
    currentValue: 1.7,
    currentUnit: "A",
    powerValue: 0.37,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 01:30",
    currentValue: 2.8,
    currentUnit: "A",
    powerValue: 0.6,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 01:30",
    currentValue: 1,
    currentUnit: "A",
    powerValue: 0.21,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 01:30",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 01:31",
    currentValue: 1.8,
    currentUnit: "A",
    powerValue: 0.39,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 01:31",
    currentValue: 2.8,
    currentUnit: "A",
    powerValue: 0.6,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 01:31",
    currentValue: 1,
    currentUnit: "A",
    powerValue: 0.21,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 01:31",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 01:32",
    currentValue: 1.7,
    currentUnit: "A",
    powerValue: 0.37,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 01:32",
    currentValue: 2.7,
    currentUnit: "A",
    powerValue: 0.58,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 01:32",
    currentValue: 1,
    currentUnit: "A",
    powerValue: 0.21,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 01:32",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 01:33",
    currentValue: 1.7,
    currentUnit: "A",
    powerValue: 0.37,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 01:33",
    currentValue: 2.8,
    currentUnit: "A",
    powerValue: 0.6,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 01:33",
    currentValue: 1,
    currentUnit: "A",
    powerValue: 0.21,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 01:33",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 01:34",
    currentValue: 1.7,
    currentUnit: "A",
    powerValue: 0.37,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 01:34",
    currentValue: 2.7,
    currentUnit: "A",
    powerValue: 0.58,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 01:34",
    currentValue: 1,
    currentUnit: "A",
    powerValue: 0.21,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 01:34",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 01:35",
    currentValue: 1.7,
    currentUnit: "A",
    powerValue: 0.37,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 01:35",
    currentValue: 2.8,
    currentUnit: "A",
    powerValue: 0.6,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 01:35",
    currentValue: 1,
    currentUnit: "A",
    powerValue: 0.21,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 01:35",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 01:36",
    currentValue: 6.8,
    currentUnit: "A",
    powerValue: 1.45,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 01:36",
    currentValue: 2.8,
    currentUnit: "A",
    powerValue: 0.6,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 01:36",
    currentValue: 1,
    currentUnit: "A",
    powerValue: 0.21,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 01:36",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 01:37",
    currentValue: 1.7,
    currentUnit: "A",
    powerValue: 0.37,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 01:37",
    currentValue: 2.7,
    currentUnit: "A",
    powerValue: 0.58,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 01:37",
    currentValue: 1,
    currentUnit: "A",
    powerValue: 0.21,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 01:37",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 01:38",
    currentValue: 1.7,
    currentUnit: "A",
    powerValue: 0.37,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 01:38",
    currentValue: 2.8,
    currentUnit: "A",
    powerValue: 0.6,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 01:38",
    currentValue: 1,
    currentUnit: "A",
    powerValue: 0.21,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 01:38",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 01:39",
    currentValue: 1.7,
    currentUnit: "A",
    powerValue: 0.37,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 01:39",
    currentValue: 2.9,
    currentUnit: "A",
    powerValue: 0.62,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 01:39",
    currentValue: 1,
    currentUnit: "A",
    powerValue: 0.21,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 01:39",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 01:40",
    currentValue: 1.8,
    currentUnit: "A",
    powerValue: 0.39,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 01:40",
    currentValue: 2.8,
    currentUnit: "A",
    powerValue: 0.6,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 01:40",
    currentValue: 1,
    currentUnit: "A",
    powerValue: 0.21,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 01:40",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 01:41",
    currentValue: 1.7,
    currentUnit: "A",
    powerValue: 0.37,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 01:41",
    currentValue: 2.8,
    currentUnit: "A",
    powerValue: 0.6,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 01:41",
    currentValue: 1,
    currentUnit: "A",
    powerValue: 0.21,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 01:41",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 01:42",
    currentValue: 1.7,
    currentUnit: "A",
    powerValue: 0.37,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 01:42",
    currentValue: 2.9,
    currentUnit: "A",
    powerValue: 0.62,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 01:42",
    currentValue: 1,
    currentUnit: "A",
    powerValue: 0.21,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 01:42",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 01:43",
    currentValue: 6.8,
    currentUnit: "A",
    powerValue: 1.45,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 01:43",
    currentValue: 2.8,
    currentUnit: "A",
    powerValue: 0.6,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 01:43",
    currentValue: 1,
    currentUnit: "A",
    powerValue: 0.21,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 01:43",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 01:44",
    currentValue: 1.7,
    currentUnit: "A",
    powerValue: 0.37,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 01:44",
    currentValue: 2.7,
    currentUnit: "A",
    powerValue: 0.58,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 01:44",
    currentValue: 1,
    currentUnit: "A",
    powerValue: 0.21,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 01:44",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 01:45",
    currentValue: 1.8,
    currentUnit: "A",
    powerValue: 0.39,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 01:45",
    currentValue: 2.8,
    currentUnit: "A",
    powerValue: 0.6,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 01:45",
    currentValue: 1,
    currentUnit: "A",
    powerValue: 0.21,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 01:45",
    currentValue: 36.3,
    currentUnit: "A",
    powerValue: 7.76,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 01:46",
    currentValue: 1.7,
    currentUnit: "A",
    powerValue: 0.37,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 01:46",
    currentValue: 2.8,
    currentUnit: "A",
    powerValue: 0.6,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 01:46",
    currentValue: 1,
    currentUnit: "A",
    powerValue: 0.21,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 01:46",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 01:47",
    currentValue: 1.8,
    currentUnit: "A",
    powerValue: 0.39,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 01:47",
    currentValue: 2.8,
    currentUnit: "A",
    powerValue: 0.6,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 01:47",
    currentValue: 1,
    currentUnit: "A",
    powerValue: 0.21,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 01:47",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 01:48",
    currentValue: 1.7,
    currentUnit: "A",
    powerValue: 0.37,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 01:48",
    currentValue: 7.8,
    currentUnit: "A",
    powerValue: 1.67,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 01:48",
    currentValue: 1,
    currentUnit: "A",
    powerValue: 0.21,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 01:48",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 01:49",
    currentValue: 1.7,
    currentUnit: "A",
    powerValue: 0.37,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 01:49",
    currentValue: 2.8,
    currentUnit: "A",
    powerValue: 0.6,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 01:49",
    currentValue: 1,
    currentUnit: "A",
    powerValue: 0.21,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 01:49",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 01:50",
    currentValue: 6.8,
    currentUnit: "A",
    powerValue: 1.45,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 01:50",
    currentValue: 2.8,
    currentUnit: "A",
    powerValue: 0.6,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 01:50",
    currentValue: 1,
    currentUnit: "A",
    powerValue: 0.21,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 01:50",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 01:51",
    currentValue: 1.7,
    currentUnit: "A",
    powerValue: 0.37,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 01:51",
    currentValue: 7.8,
    currentUnit: "A",
    powerValue: 1.67,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 01:51",
    currentValue: 1,
    currentUnit: "A",
    powerValue: 0.21,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 01:51",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 01:52",
    currentValue: 1.7,
    currentUnit: "A",
    powerValue: 0.37,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 01:52",
    currentValue: 2.8,
    currentUnit: "A",
    powerValue: 0.6,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 01:52",
    currentValue: 1,
    currentUnit: "A",
    powerValue: 0.21,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 01:52",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 01:53",
    currentValue: 1.7,
    currentUnit: "A",
    powerValue: 0.37,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 01:53",
    currentValue: 2.7,
    currentUnit: "A",
    powerValue: 0.58,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 01:53",
    currentValue: 1,
    currentUnit: "A",
    powerValue: 0.21,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 01:53",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 01:54",
    currentValue: 1.8,
    currentUnit: "A",
    powerValue: 0.39,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 01:54",
    currentValue: 7.8,
    currentUnit: "A",
    powerValue: 1.67,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 01:54",
    currentValue: 1,
    currentUnit: "A",
    powerValue: 0.21,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 01:54",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 01:55",
    currentValue: 6.8,
    currentUnit: "A",
    powerValue: 1.45,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 01:55",
    currentValue: 2.8,
    currentUnit: "A",
    powerValue: 0.6,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 01:55",
    currentValue: 1,
    currentUnit: "A",
    powerValue: 0.21,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 01:55",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 01:56",
    currentValue: 1.7,
    currentUnit: "A",
    powerValue: 0.36,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 01:56",
    currentValue: 2.8,
    currentUnit: "A",
    powerValue: 0.6,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 01:56",
    currentValue: 1,
    currentUnit: "A",
    powerValue: 0.21,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 01:56",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 01:57",
    currentValue: 1.8,
    currentUnit: "A",
    powerValue: 0.39,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 01:57",
    currentValue: 6.4,
    currentUnit: "A",
    powerValue: 1.37,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 01:57",
    currentValue: 1,
    currentUnit: "A",
    powerValue: 0.21,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 01:57",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 01:58",
    currentValue: 1.7,
    currentUnit: "A",
    powerValue: 0.37,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 01:58",
    currentValue: 2.8,
    currentUnit: "A",
    powerValue: 0.6,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 01:58",
    currentValue: 1,
    currentUnit: "A",
    powerValue: 0.21,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 01:58",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 01:59",
    currentValue: 1.8,
    currentUnit: "A",
    powerValue: 0.39,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 01:59",
    currentValue: 2.8,
    currentUnit: "A",
    powerValue: 0.6,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 01:59",
    currentValue: 1,
    currentUnit: "A",
    powerValue: 0.21,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 01:59",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 02:00",
    currentValue: 1.7,
    currentUnit: "A",
    powerValue: 0.36,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 02:00",
    currentValue: 7.8,
    currentUnit: "A",
    powerValue: 1.66,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 02:00",
    currentValue: 1,
    currentUnit: "A",
    powerValue: 0.21,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 02:00",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 02:01",
    currentValue: 1.8,
    currentUnit: "A",
    powerValue: 0.39,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 02:01",
    currentValue: 2.8,
    currentUnit: "A",
    powerValue: 0.6,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 02:01",
    currentValue: 1,
    currentUnit: "A",
    powerValue: 0.21,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 02:01",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 02:02",
    currentValue: 6.8,
    currentUnit: "A",
    powerValue: 1.45,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 02:02",
    currentValue: 2.8,
    currentUnit: "A",
    powerValue: 0.6,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 02:02",
    currentValue: 1,
    currentUnit: "A",
    powerValue: 0.21,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 02:02",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 02:03",
    currentValue: 1.7,
    currentUnit: "A",
    powerValue: 0.36,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 02:03",
    currentValue: 7.8,
    currentUnit: "A",
    powerValue: 1.66,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 02:03",
    currentValue: 1,
    currentUnit: "A",
    powerValue: 0.21,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 02:03",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 02:04",
    currentValue: 1.7,
    currentUnit: "A",
    powerValue: 0.36,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 02:04",
    currentValue: 2.8,
    currentUnit: "A",
    powerValue: 0.6,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 02:04",
    currentValue: 1,
    currentUnit: "A",
    powerValue: 0.21,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 02:04",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 02:05",
    currentValue: 1.7,
    currentUnit: "A",
    powerValue: 0.36,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 02:05",
    currentValue: 2.8,
    currentUnit: "A",
    powerValue: 0.6,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 02:05",
    currentValue: 1,
    currentUnit: "A",
    powerValue: 0.21,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 02:05",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 02:06",
    currentValue: 1.8,
    currentUnit: "A",
    powerValue: 0.39,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 02:06",
    currentValue: 7.8,
    currentUnit: "A",
    powerValue: 1.66,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 02:06",
    currentValue: 1,
    currentUnit: "A",
    powerValue: 0.21,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 02:06",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 02:07",
    currentValue: 6.8,
    currentUnit: "A",
    powerValue: 1.45,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 02:07",
    currentValue: 2.8,
    currentUnit: "A",
    powerValue: 2.29,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 02:07",
    currentValue: 1,
    currentUnit: "A",
    powerValue: 0.21,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 02:07",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 02:08",
    currentValue: 1.7,
    currentUnit: "A",
    powerValue: 0.36,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 02:08",
    currentValue: 2.7,
    currentUnit: "A",
    powerValue: 0.58,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 02:08",
    currentValue: 1,
    currentUnit: "A",
    powerValue: 0.21,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 02:08",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 02:09",
    currentValue: 1.8,
    currentUnit: "A",
    powerValue: 0.39,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 02:09",
    currentValue: 2.7,
    currentUnit: "A",
    powerValue: 0.58,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 02:09",
    currentValue: 1,
    currentUnit: "A",
    powerValue: 0.21,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 02:09",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 02:10",
    currentValue: 1.7,
    currentUnit: "A",
    powerValue: 0.36,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 02:10",
    currentValue: 2.8,
    currentUnit: "A",
    powerValue: 0.6,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 02:10",
    currentValue: 1,
    currentUnit: "A",
    powerValue: 0.21,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 02:10",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 02:11",
    currentValue: 1.7,
    currentUnit: "A",
    powerValue: 0.36,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 02:11",
    currentValue: 7.8,
    currentUnit: "A",
    powerValue: 1.66,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 02:11",
    currentValue: 1,
    currentUnit: "A",
    powerValue: 0.21,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 02:11",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 02:12",
    currentValue: 6.8,
    currentUnit: "A",
    powerValue: 1.45,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 02:12",
    currentValue: 2.8,
    currentUnit: "A",
    powerValue: 0.6,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 02:12",
    currentValue: 1,
    currentUnit: "A",
    powerValue: 0.21,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 02:12",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 02:13",
    currentValue: 1.7,
    currentUnit: "A",
    powerValue: 0.36,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 02:13",
    currentValue: 2.7,
    currentUnit: "A",
    powerValue: 0.58,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 02:13",
    currentValue: 1,
    currentUnit: "A",
    powerValue: 0.21,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 02:13",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 02:14",
    currentValue: 6.8,
    currentUnit: "A",
    powerValue: 1.45,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 02:14",
    currentValue: 2.8,
    currentUnit: "A",
    powerValue: 0.6,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 02:14",
    currentValue: 6.8,
    currentUnit: "A",
    powerValue: 1.45,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 02:14",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 02:15",
    currentValue: 1.7,
    currentUnit: "A",
    powerValue: 0.36,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 02:15",
    currentValue: 2.8,
    currentUnit: "A",
    powerValue: 0.6,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 02:15",
    currentValue: 1,
    currentUnit: "A",
    powerValue: 0.21,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 02:15",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 02:16",
    currentValue: 1.8,
    currentUnit: "A",
    powerValue: 0.39,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 02:16",
    currentValue: 7.8,
    currentUnit: "A",
    powerValue: 1.66,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 02:16",
    currentValue: 1,
    currentUnit: "A",
    powerValue: 0.21,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 02:16",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 02:17",
    currentValue: 1.7,
    currentUnit: "A",
    powerValue: 0.36,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 02:17",
    currentValue: 2.8,
    currentUnit: "A",
    powerValue: 0.6,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 02:17",
    currentValue: 6.8,
    currentUnit: "A",
    powerValue: 1.45,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 02:17",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 02:18",
    currentValue: 1.8,
    currentUnit: "A",
    powerValue: 0.39,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 02:18",
    currentValue: 2.8,
    currentUnit: "A",
    powerValue: 0.6,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 02:18",
    currentValue: 4.9,
    currentUnit: "A",
    powerValue: 1.04,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 02:18",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 02:19",
    currentValue: 6.8,
    currentUnit: "A",
    powerValue: 1.45,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 02:19",
    currentValue: 7.8,
    currentUnit: "A",
    powerValue: 1.66,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 02:19",
    currentValue: 1,
    currentUnit: "A",
    powerValue: 0.21,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 02:19",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 02:20",
    currentValue: 1.8,
    currentUnit: "A",
    powerValue: 0.39,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 02:20",
    currentValue: 2.8,
    currentUnit: "A",
    powerValue: 0.6,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 02:20",
    currentValue: 10.7,
    currentUnit: "A",
    powerValue: 2.28,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 02:20",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 02:21",
    currentValue: 6.8,
    currentUnit: "A",
    powerValue: 1.45,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 02:21",
    currentValue: 2.8,
    currentUnit: "A",
    powerValue: 0.6,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 02:21",
    currentValue: 1,
    currentUnit: "A",
    powerValue: 0.21,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 02:21",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 02:22",
    currentValue: 1.7,
    currentUnit: "A",
    powerValue: 0.36,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 02:22",
    currentValue: 7.8,
    currentUnit: "A",
    powerValue: 1.66,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 02:22",
    currentValue: 4.9,
    currentUnit: "A",
    powerValue: 1.04,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 02:22",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 02:23",
    currentValue: 1.7,
    currentUnit: "A",
    powerValue: 0.36,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 02:23",
    currentValue: 2.8,
    currentUnit: "A",
    powerValue: 0.6,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 02:23",
    currentValue: 1.8,
    currentUnit: "A",
    powerValue: 0.38,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 02:23",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 02:24",
    currentValue: 1.7,
    currentUnit: "A",
    powerValue: 0.36,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 02:24",
    currentValue: 2.8,
    currentUnit: "A",
    powerValue: 0.6,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 02:24",
    currentValue: 4.9,
    currentUnit: "A",
    powerValue: 1.04,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 02:24",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 02:25",
    currentValue: 1.7,
    currentUnit: "A",
    powerValue: 0.36,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 02:25",
    currentValue: 7.8,
    currentUnit: "A",
    powerValue: 1.66,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 02:25",
    currentValue: 1,
    currentUnit: "A",
    powerValue: 0.21,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 02:25",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 02:26",
    currentValue: 1.7,
    currentUnit: "A",
    powerValue: 0.36,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 02:26",
    currentValue: 2.8,
    currentUnit: "A",
    powerValue: 0.6,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 02:26",
    currentValue: 6.4,
    currentUnit: "A",
    powerValue: 1.36,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 02:26",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 02:27",
    currentValue: 1.8,
    currentUnit: "A",
    powerValue: 0.39,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 02:27",
    currentValue: 2.8,
    currentUnit: "A",
    powerValue: 0.6,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 02:27",
    currentValue: 1,
    currentUnit: "A",
    powerValue: 0.21,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 02:27",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 02:28",
    currentValue: 6.8,
    currentUnit: "A",
    powerValue: 1.45,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 02:28",
    currentValue: 7.8,
    currentUnit: "A",
    powerValue: 1.66,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 02:28",
    currentValue: 4.9,
    currentUnit: "A",
    powerValue: 1.04,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 02:28",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 02:29",
    currentValue: 1.7,
    currentUnit: "A",
    powerValue: 0.36,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 02:29",
    currentValue: 2.8,
    currentUnit: "A",
    powerValue: 0.6,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 02:29",
    currentValue: 1,
    currentUnit: "A",
    powerValue: 0.21,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 02:29",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 02:30",
    currentValue: 2.8,
    currentUnit: "A",
    powerValue: 0.6,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 02:30",
    currentValue: 2.7,
    currentUnit: "A",
    powerValue: 0.58,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 02:30",
    currentValue: 4.9,
    currentUnit: "A",
    powerValue: 1.04,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 02:30",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 02:31",
    currentValue: 1.7,
    currentUnit: "A",
    powerValue: 0.36,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 02:31",
    currentValue: 7.8,
    currentUnit: "A",
    powerValue: 1.66,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 02:31",
    currentValue: 1,
    currentUnit: "A",
    powerValue: 0.21,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 02:31",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 02:32",
    currentValue: 1.8,
    currentUnit: "A",
    powerValue: 0.39,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 02:32",
    currentValue: 2.7,
    currentUnit: "A",
    powerValue: 0.58,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 02:32",
    currentValue: 4.9,
    currentUnit: "A",
    powerValue: 1.04,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 02:32",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 02:33",
    currentValue: 1.7,
    currentUnit: "A",
    powerValue: 0.36,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 02:33",
    currentValue: 2.8,
    currentUnit: "A",
    powerValue: 0.6,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 02:33",
    currentValue: 1,
    currentUnit: "A",
    powerValue: 0.21,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 02:33",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 02:34",
    currentValue: 1.7,
    currentUnit: "A",
    powerValue: 0.36,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 02:34",
    currentValue: 7.8,
    currentUnit: "A",
    powerValue: 1.66,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 02:34",
    currentValue: 4.9,
    currentUnit: "A",
    powerValue: 1.04,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 02:34",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 02:35",
    currentValue: 6.8,
    currentUnit: "A",
    powerValue: 1.45,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 02:35",
    currentValue: 2.8,
    currentUnit: "A",
    powerValue: 0.6,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 02:35",
    currentValue: 1,
    currentUnit: "A",
    powerValue: 0.21,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 02:35",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 02:36",
    currentValue: 1.7,
    currentUnit: "A",
    powerValue: 0.36,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 02:36",
    currentValue: 2.8,
    currentUnit: "A",
    powerValue: 0.6,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 02:36",
    currentValue: 4.9,
    currentUnit: "A",
    powerValue: 1.04,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 02:36",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 02:37",
    currentValue: 1.7,
    currentUnit: "A",
    powerValue: 0.36,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 02:37",
    currentValue: 7.8,
    currentUnit: "A",
    powerValue: 1.66,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 02:37",
    currentValue: 1,
    currentUnit: "A",
    powerValue: 0.21,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 02:37",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 02:38",
    currentValue: 1.7,
    currentUnit: "A",
    powerValue: 0.36,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 02:38",
    currentValue: 2.8,
    currentUnit: "A",
    powerValue: 0.6,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 02:38",
    currentValue: 4.9,
    currentUnit: "A",
    powerValue: 1.04,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 02:38",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 02:39",
    currentValue: 1.7,
    currentUnit: "A",
    powerValue: 0.36,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 02:39",
    currentValue: 2.8,
    currentUnit: "A",
    powerValue: 0.6,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 02:39",
    currentValue: 6.8,
    currentUnit: "A",
    powerValue: 1.45,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 02:39",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 02:40",
    currentValue: 1.7,
    currentUnit: "A",
    powerValue: 0.36,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 02:40",
    currentValue: 7.8,
    currentUnit: "A",
    powerValue: 1.66,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 02:40",
    currentValue: 4.9,
    currentUnit: "A",
    powerValue: 1.04,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 02:40",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 02:41",
    currentValue: 1.8,
    currentUnit: "A",
    powerValue: 0.39,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 02:41",
    currentValue: 2.8,
    currentUnit: "A",
    powerValue: 0.6,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 02:41",
    currentValue: 1,
    currentUnit: "A",
    powerValue: 0.21,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 02:41",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 02:42",
    currentValue: 6.8,
    currentUnit: "A",
    powerValue: 1.44,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 02:42",
    currentValue: 2.7,
    currentUnit: "A",
    powerValue: 0.58,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 02:42",
    currentValue: 10.7,
    currentUnit: "A",
    powerValue: 2.28,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 02:42",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 02:43",
    currentValue: 1.7,
    currentUnit: "A",
    powerValue: 0.36,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 02:43",
    currentValue: 7.8,
    currentUnit: "A",
    powerValue: 1.66,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 02:43",
    currentValue: 1,
    currentUnit: "A",
    powerValue: 0.21,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 02:43",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 02:44",
    currentValue: 6.8,
    currentUnit: "A",
    powerValue: 1.45,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 02:44",
    currentValue: 2.8,
    currentUnit: "A",
    powerValue: 0.6,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 02:44",
    currentValue: 5,
    currentUnit: "A",
    powerValue: 1.06,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 02:44",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 02:45",
    currentValue: 1.7,
    currentUnit: "A",
    powerValue: 0.36,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 02:45",
    currentValue: 2.8,
    currentUnit: "A",
    powerValue: 0.6,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 02:45",
    currentValue: 6.8,
    currentUnit: "A",
    powerValue: 1.45,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 02:45",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 02:46",
    currentValue: 1.8,
    currentUnit: "A",
    powerValue: 0.39,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 02:46",
    currentValue: 7.8,
    currentUnit: "A",
    powerValue: 1.66,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 02:46",
    currentValue: 4.9,
    currentUnit: "A",
    powerValue: 1.04,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 02:46",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 02:47",
    currentValue: 1.7,
    currentUnit: "A",
    powerValue: 0.36,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 02:47",
    currentValue: 2.8,
    currentUnit: "A",
    powerValue: 0.6,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 02:47",
    currentValue: 1,
    currentUnit: "A",
    powerValue: 0.21,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 02:47",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 02:48",
    currentValue: 1.7,
    currentUnit: "A",
    powerValue: 0.36,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 02:48",
    currentValue: 2.8,
    currentUnit: "A",
    powerValue: 0.6,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 02:48",
    currentValue: 10.7,
    currentUnit: "A",
    powerValue: 2.28,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 02:48",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 02:49",
    currentValue: 1.7,
    currentUnit: "A",
    powerValue: 0.36,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 02:49",
    currentValue: 7.8,
    currentUnit: "A",
    powerValue: 1.66,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 02:49",
    currentValue: 1,
    currentUnit: "A",
    powerValue: 0.21,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 02:49",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 02:50",
    currentValue: 1.7,
    currentUnit: "A",
    powerValue: 0.36,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 02:50",
    currentValue: 2.8,
    currentUnit: "A",
    powerValue: 0.6,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 02:50",
    currentValue: 4.9,
    currentUnit: "A",
    powerValue: 1.04,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 02:50",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 02:51",
    currentValue: 6.8,
    currentUnit: "A",
    powerValue: 1.45,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 02:51",
    currentValue: 2.7,
    currentUnit: "A",
    powerValue: 0.58,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 02:51",
    currentValue: 6.8,
    currentUnit: "A",
    powerValue: 1.45,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 02:51",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 02:52",
    currentValue: 1.7,
    currentUnit: "A",
    powerValue: 0.36,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 02:52",
    currentValue: 2.7,
    currentUnit: "A",
    powerValue: 0.58,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 02:52",
    currentValue: 4.9,
    currentUnit: "A",
    powerValue: 1.04,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 02:52",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 02:53",
    currentValue: 1.7,
    currentUnit: "A",
    powerValue: 0.36,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 02:53",
    currentValue: 2.8,
    currentUnit: "A",
    powerValue: 0.6,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 02:53",
    currentValue: 1,
    currentUnit: "A",
    powerValue: 0.21,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 02:53",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 02:54",
    currentValue: 1.7,
    currentUnit: "A",
    powerValue: 0.36,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 02:54",
    currentValue: 2.8,
    currentUnit: "A",
    powerValue: 0.6,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 02:54",
    currentValue: 10.7,
    currentUnit: "A",
    powerValue: 2.28,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 02:54",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 02:55",
    currentValue: 1.8,
    currentUnit: "A",
    powerValue: 0.39,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 02:55",
    currentValue: 2.7,
    currentUnit: "A",
    powerValue: 0.58,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 02:55",
    currentValue: 1,
    currentUnit: "A",
    powerValue: 0.21,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 02:55",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 02:56",
    currentValue: 1.7,
    currentUnit: "A",
    powerValue: 0.36,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 02:56",
    currentValue: 2.7,
    currentUnit: "A",
    powerValue: 0.58,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 02:56",
    currentValue: 4.9,
    currentUnit: "A",
    powerValue: 1.04,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 02:56",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 02:57",
    currentValue: 1.7,
    currentUnit: "A",
    powerValue: 0.36,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 02:57",
    currentValue: 2.8,
    currentUnit: "A",
    powerValue: 0.6,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 02:57",
    currentValue: 1.8,
    currentUnit: "A",
    powerValue: 0.38,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 02:57",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 02:58",
    currentValue: 6.8,
    currentUnit: "A",
    powerValue: 1.44,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 02:58",
    currentValue: 2.7,
    currentUnit: "A",
    powerValue: 0.58,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 02:58",
    currentValue: 4.9,
    currentUnit: "A",
    powerValue: 1.04,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 02:58",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 02:59",
    currentValue: 1.8,
    currentUnit: "A",
    powerValue: 0.39,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 02:59",
    currentValue: 2.7,
    currentUnit: "A",
    powerValue: 0.58,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 02:59",
    currentValue: 1,
    currentUnit: "A",
    powerValue: 0.21,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 02:59",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 03:00",
    currentValue: 1.8,
    currentUnit: "A",
    powerValue: 0.38,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 03:00",
    currentValue: 2.7,
    currentUnit: "A",
    powerValue: 0.58,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 03:00",
    currentValue: 5.6,
    currentUnit: "A",
    powerValue: 1.19,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 03:00",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 03:01",
    currentValue: 1.7,
    currentUnit: "A",
    powerValue: 0.36,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 03:01",
    currentValue: 2.7,
    currentUnit: "A",
    powerValue: 0.58,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 03:01",
    currentValue: 1,
    currentUnit: "A",
    powerValue: 0.21,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 03:01",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 03:02",
    currentValue: 1.7,
    currentUnit: "A",
    powerValue: 0.36,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 03:02",
    currentValue: 2.8,
    currentUnit: "A",
    powerValue: 0.6,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 03:02",
    currentValue: 5,
    currentUnit: "A",
    powerValue: 1.06,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 03:02",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 03:03",
    currentValue: 1.7,
    currentUnit: "A",
    powerValue: 0.36,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 03:03",
    currentValue: 2.8,
    currentUnit: "A",
    powerValue: 0.6,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 03:03",
    currentValue: 1.8,
    currentUnit: "A",
    powerValue: 0.38,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 03:03",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 03:04",
    currentValue: 1.8,
    currentUnit: "A",
    powerValue: 0.38,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 03:04",
    currentValue: 2.7,
    currentUnit: "A",
    powerValue: 0.58,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 03:04",
    currentValue: 4.9,
    currentUnit: "A",
    powerValue: 1.04,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 03:04",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 03:05",
    currentValue: 6.8,
    currentUnit: "A",
    powerValue: 1.44,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 03:05",
    currentValue: 2.8,
    currentUnit: "A",
    powerValue: 0.6,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 03:05",
    currentValue: 1,
    currentUnit: "A",
    powerValue: 0.21,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 03:05",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 03:06",
    currentValue: 1.7,
    currentUnit: "A",
    powerValue: 0.36,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 03:06",
    currentValue: 2.8,
    currentUnit: "A",
    powerValue: 0.6,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 03:06",
    currentValue: 4.9,
    currentUnit: "A",
    powerValue: 1.04,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 03:06",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 03:07",
    currentValue: 1.7,
    currentUnit: "A",
    powerValue: 0.36,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 03:07",
    currentValue: 2.7,
    currentUnit: "A",
    powerValue: 0.58,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 03:07",
    currentValue: 1,
    currentUnit: "A",
    powerValue: 0.21,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 03:07",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 03:08",
    currentValue: 1.8,
    currentUnit: "A",
    powerValue: 0.39,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 03:08",
    currentValue: 2.8,
    currentUnit: "A",
    powerValue: 0.6,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 03:08",
    currentValue: 4.9,
    currentUnit: "A",
    powerValue: 1.04,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 03:08",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 03:09",
    currentValue: 1.8,
    currentUnit: "A",
    powerValue: 0.39,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 03:09",
    currentValue: 2.8,
    currentUnit: "A",
    powerValue: 0.6,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 03:09",
    currentValue: 1,
    currentUnit: "A",
    powerValue: 0.21,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 03:09",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 03:10",
    currentValue: 1.7,
    currentUnit: "A",
    powerValue: 0.36,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 03:10",
    currentValue: 7.8,
    currentUnit: "A",
    powerValue: 1.66,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 03:10",
    currentValue: 4.9,
    currentUnit: "A",
    powerValue: 1.04,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 03:10",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 03:11",
    currentValue: 1.7,
    currentUnit: "A",
    powerValue: 0.36,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 03:11",
    currentValue: 2.8,
    currentUnit: "A",
    powerValue: 0.6,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 03:11",
    currentValue: 1,
    currentUnit: "A",
    powerValue: 0.21,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 03:11",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 03:12",
    currentValue: 6.8,
    currentUnit: "A",
    powerValue: 1.44,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 03:12",
    currentValue: 2.8,
    currentUnit: "A",
    powerValue: 0.6,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 03:12",
    currentValue: 4.9,
    currentUnit: "A",
    powerValue: 1.04,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 03:12",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 03:13",
    currentValue: 1.7,
    currentUnit: "A",
    powerValue: 0.36,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 03:13",
    currentValue: 2.7,
    currentUnit: "A",
    powerValue: 0.58,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 03:13",
    currentValue: 1,
    currentUnit: "A",
    powerValue: 0.21,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 03:13",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 03:14",
    currentValue: 1.8,
    currentUnit: "A",
    powerValue: 0.39,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 03:14",
    currentValue: 2.8,
    currentUnit: "A",
    powerValue: 0.6,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 03:14",
    currentValue: 4.9,
    currentUnit: "A",
    powerValue: 1.04,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 03:14",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 03:15",
    currentValue: 1.7,
    currentUnit: "A",
    powerValue: 0.36,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 03:15",
    currentValue: 2.8,
    currentUnit: "A",
    powerValue: 0.6,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 03:15",
    currentValue: 1,
    currentUnit: "A",
    powerValue: 0.21,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 03:15",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 03:16",
    currentValue: 1.8,
    currentUnit: "A",
    powerValue: 0.38,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 03:16",
    currentValue: 2.7,
    currentUnit: "A",
    powerValue: 0.58,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 03:16",
    currentValue: 5,
    currentUnit: "A",
    powerValue: 1.06,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 03:16",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 03:17",
    currentValue: 1.7,
    currentUnit: "A",
    powerValue: 0.36,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 03:17",
    currentValue: 2.8,
    currentUnit: "A",
    powerValue: 0.6,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 03:17",
    currentValue: 1,
    currentUnit: "A",
    powerValue: 0.21,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 03:17",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 03:18",
    currentValue: 1.7,
    currentUnit: "A",
    powerValue: 0.36,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 03:18",
    currentValue: 2.8,
    currentUnit: "A",
    powerValue: 0.6,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 03:18",
    currentValue: 5,
    currentUnit: "A",
    powerValue: 1.06,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 03:18",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 03:19",
    currentValue: 6.8,
    currentUnit: "A",
    powerValue: 1.44,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 03:19",
    currentValue: 2.7,
    currentUnit: "A",
    powerValue: 0.58,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 03:19",
    currentValue: 1,
    currentUnit: "A",
    powerValue: 0.21,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 03:19",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 03:20",
    currentValue: 1.7,
    currentUnit: "A",
    powerValue: 0.36,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 03:20",
    currentValue: 2.8,
    currentUnit: "A",
    powerValue: 0.6,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 03:20",
    currentValue: 4.9,
    currentUnit: "A",
    powerValue: 1.04,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 03:20",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 03:21",
    currentValue: 1.7,
    currentUnit: "A",
    powerValue: 0.36,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 03:21",
    currentValue: 2.8,
    currentUnit: "A",
    powerValue: 0.6,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 03:21",
    currentValue: 1,
    currentUnit: "A",
    powerValue: 0.21,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 03:21",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 03:22",
    currentValue: 1.7,
    currentUnit: "A",
    powerValue: 0.36,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 03:22",
    currentValue: 2.7,
    currentUnit: "A",
    powerValue: 0.58,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 03:22",
    currentValue: 5,
    currentUnit: "A",
    powerValue: 1.06,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 03:22",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 03:23",
    currentValue: 1.7,
    currentUnit: "A",
    powerValue: 0.36,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 03:23",
    currentValue: 2.8,
    currentUnit: "A",
    powerValue: 0.6,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 03:23",
    currentValue: 1,
    currentUnit: "A",
    powerValue: 0.21,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 03:23",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 03:24",
    currentValue: 1.7,
    currentUnit: "A",
    powerValue: 0.36,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 03:24",
    currentValue: 2.7,
    currentUnit: "A",
    powerValue: 0.58,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 03:24",
    currentValue: 47.7,
    currentUnit: "A",
    powerValue: 10.06,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 03:24",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 03:25",
    currentValue: 1.7,
    currentUnit: "A",
    powerValue: 0.36,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 03:25",
    currentValue: 2.7,
    currentUnit: "A",
    powerValue: 0.58,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 03:25",
    currentValue: 40.8,
    currentUnit: "A",
    powerValue: 8.62,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 03:25",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 03:26",
    currentValue: 6.8,
    currentUnit: "A",
    powerValue: 1.44,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 03:26",
    currentValue: 2.8,
    currentUnit: "A",
    powerValue: 0.6,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 03:26",
    currentValue: 33.9,
    currentUnit: "A",
    powerValue: 7.17,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 03:26",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 03:27",
    currentValue: 1.7,
    currentUnit: "A",
    powerValue: 0.36,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 03:27",
    currentValue: 2.7,
    currentUnit: "A",
    powerValue: 0.58,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 03:27",
    currentValue: 28.2,
    currentUnit: "A",
    powerValue: 5.96,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 03:27",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 03:28",
    currentValue: 1.7,
    currentUnit: "A",
    powerValue: 0.36,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 03:28",
    currentValue: 2.7,
    currentUnit: "A",
    powerValue: 0.58,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 03:28",
    currentValue: 37.8,
    currentUnit: "A",
    powerValue: 8,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 03:28",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 03:29",
    currentValue: 1.7,
    currentUnit: "A",
    powerValue: 0.36,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 03:29",
    currentValue: 2.7,
    currentUnit: "A",
    powerValue: 0.58,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 03:29",
    currentValue: 27.9,
    currentUnit: "A",
    powerValue: 5.9,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 03:29",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 03:30",
    currentValue: 1.7,
    currentUnit: "A",
    powerValue: 0.36,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 03:30",
    currentValue: 2.8,
    currentUnit: "A",
    powerValue: 0.6,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 03:30",
    currentValue: 27.7,
    currentUnit: "A",
    powerValue: 5.86,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 03:30",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 03:31",
    currentValue: 1.7,
    currentUnit: "A",
    powerValue: 0.36,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 03:31",
    currentValue: 2.7,
    currentUnit: "A",
    powerValue: 0.58,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 03:31",
    currentValue: 29.5,
    currentUnit: "A",
    powerValue: 6.24,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 03:31",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 03:32",
    currentValue: 1.7,
    currentUnit: "A",
    powerValue: 0.36,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 03:32",
    currentValue: 2.8,
    currentUnit: "A",
    powerValue: 0.6,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 03:32",
    currentValue: 27.5,
    currentUnit: "A",
    powerValue: 5.82,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 03:32",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 03:33",
    currentValue: 6.8,
    currentUnit: "A",
    powerValue: 1.44,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 03:33",
    currentValue: 2.8,
    currentUnit: "A",
    powerValue: 0.6,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 03:33",
    currentValue: 23.5,
    currentUnit: "A",
    powerValue: 4.97,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 03:33",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 03:34",
    currentValue: 1.7,
    currentUnit: "A",
    powerValue: 0.36,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 03:34",
    currentValue: 2.7,
    currentUnit: "A",
    powerValue: 0.58,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 03:34",
    currentValue: 28,
    currentUnit: "A",
    powerValue: 5.93,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 03:34",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 03:35",
    currentValue: 1.7,
    currentUnit: "A",
    powerValue: 0.36,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 03:35",
    currentValue: 2.8,
    currentUnit: "A",
    powerValue: 0.6,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 03:35",
    currentValue: 23.2,
    currentUnit: "A",
    powerValue: 4.91,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 03:35",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 03:36",
    currentValue: 1.7,
    currentUnit: "A",
    powerValue: 0.36,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 03:36",
    currentValue: 2.8,
    currentUnit: "A",
    powerValue: 0.6,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 03:36",
    currentValue: 23.8,
    currentUnit: "A",
    powerValue: 5.03,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 03:36",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 03:37",
    currentValue: 1.8,
    currentUnit: "A",
    powerValue: 0.38,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 03:37",
    currentValue: 2.7,
    currentUnit: "A",
    powerValue: 0.58,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 03:37",
    currentValue: 25.6,
    currentUnit: "A",
    powerValue: 5.41,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 03:37",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 03:38",
    currentValue: 1.7,
    currentUnit: "A",
    powerValue: 0.36,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 03:38",
    currentValue: 2.8,
    currentUnit: "A",
    powerValue: 0.6,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 03:38",
    currentValue: 23.6,
    currentUnit: "A",
    powerValue: 4.99,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 03:38",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 03:39",
    currentValue: 1.7,
    currentUnit: "A",
    powerValue: 0.36,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 03:39",
    currentValue: 2.7,
    currentUnit: "A",
    powerValue: 0.58,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 03:39",
    currentValue: 22.5,
    currentUnit: "A",
    powerValue: 4.75,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 03:39",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 03:40",
    currentValue: 6.8,
    currentUnit: "A",
    powerValue: 1.44,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 03:40",
    currentValue: 2.8,
    currentUnit: "A",
    powerValue: 0.6,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 03:40",
    currentValue: 17.3,
    currentUnit: "A",
    powerValue: 3.66,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 03:40",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 03:41",
    currentValue: 1.8,
    currentUnit: "A",
    powerValue: 0.38,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 03:41",
    currentValue: 2.8,
    currentUnit: "A",
    powerValue: 0.6,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 03:41",
    currentValue: 16.4,
    currentUnit: "A",
    powerValue: 3.47,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 03:41",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 03:42",
    currentValue: 1.7,
    currentUnit: "A",
    powerValue: 0.36,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 03:42",
    currentValue: 2.8,
    currentUnit: "A",
    powerValue: 0.6,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 03:42",
    currentValue: 16.4,
    currentUnit: "A",
    powerValue: 3.47,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 03:42",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 03:43",
    currentValue: 1.8,
    currentUnit: "A",
    powerValue: 0.38,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 03:43",
    currentValue: 2.8,
    currentUnit: "A",
    powerValue: 0.6,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 03:43",
    currentValue: 1,
    currentUnit: "A",
    powerValue: 0.21,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 03:43",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 03:44",
    currentValue: 1.8,
    currentUnit: "A",
    powerValue: 0.38,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 03:44",
    currentValue: 2.8,
    currentUnit: "A",
    powerValue: 0.6,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 03:44",
    currentValue: 3.1,
    currentUnit: "A",
    powerValue: 0.66,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 03:44",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 03:45",
    currentValue: 1.7,
    currentUnit: "A",
    powerValue: 0.36,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 03:45",
    currentValue: 2.8,
    currentUnit: "A",
    powerValue: 0.6,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 03:45",
    currentValue: 1,
    currentUnit: "A",
    powerValue: 0.21,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 03:45",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 03:46",
    currentValue: 1.7,
    currentUnit: "A",
    powerValue: 0.36,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 03:46",
    currentValue: 71.8,
    currentUnit: "A",
    powerValue: 15.29,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 03:46",
    currentValue: 1.8,
    currentUnit: "A",
    powerValue: 0.38,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 03:46",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 03:47",
    currentValue: 6.8,
    currentUnit: "A",
    powerValue: 1.44,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 03:47",
    currentValue: 2.8,
    currentUnit: "A",
    powerValue: 0.6,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 03:47",
    currentValue: 1,
    currentUnit: "A",
    powerValue: 0.21,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 03:47",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 03:48",
    currentValue: 1.7,
    currentUnit: "A",
    powerValue: 0.36,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 03:48",
    currentValue: 2.7,
    currentUnit: "A",
    powerValue: 0.58,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 03:48",
    currentValue: 1,
    currentUnit: "A",
    powerValue: 0.21,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 03:48",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 03:49",
    currentValue: 1.8,
    currentUnit: "A",
    powerValue: 0.38,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 03:49",
    currentValue: 2.8,
    currentUnit: "A",
    powerValue: 0.6,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 03:49",
    currentValue: 1,
    currentUnit: "A",
    powerValue: 0.21,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 03:49",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 03:50",
    currentValue: 1.8,
    currentUnit: "A",
    powerValue: 0.38,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 03:50",
    currentValue: 2.8,
    currentUnit: "A",
    powerValue: 0.6,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 03:50",
    currentValue: 1,
    currentUnit: "A",
    powerValue: 0.21,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 03:50",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 03:51",
    currentValue: 1.7,
    currentUnit: "A",
    powerValue: 0.36,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 03:51",
    currentValue: 2.8,
    currentUnit: "A",
    powerValue: 0.6,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 03:51",
    currentValue: 1,
    currentUnit: "A",
    powerValue: 0.21,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 03:51",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 03:52",
    currentValue: 1.7,
    currentUnit: "A",
    powerValue: 0.36,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 03:52",
    currentValue: 2.8,
    currentUnit: "A",
    powerValue: 0.6,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 03:52",
    currentValue: 1,
    currentUnit: "A",
    powerValue: 0.21,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 03:52",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 03:53",
    currentValue: 1.7,
    currentUnit: "A",
    powerValue: 0.36,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 03:53",
    currentValue: 2.8,
    currentUnit: "A",
    powerValue: 0.6,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 03:53",
    currentValue: 1,
    currentUnit: "A",
    powerValue: 0.21,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 03:53",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 03:54",
    currentValue: 6.8,
    currentUnit: "A",
    powerValue: 1.44,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 03:54",
    currentValue: 43.7,
    currentUnit: "A",
    powerValue: 9.27,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 03:54",
    currentValue: 1,
    currentUnit: "A",
    powerValue: 0.21,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 03:54",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 03:55",
    currentValue: 1.7,
    currentUnit: "A",
    powerValue: 0.36,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 03:55",
    currentValue: 43.2,
    currentUnit: "A",
    powerValue: 9.15,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 03:55",
    currentValue: 1,
    currentUnit: "A",
    powerValue: 0.21,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 03:55",
    currentValue: 8.6,
    currentUnit: "A",
    powerValue: 1.83,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 03:56",
    currentValue: 6.8,
    currentUnit: "A",
    powerValue: 1.44,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 03:56",
    currentValue: 42.8,
    currentUnit: "A",
    powerValue: 9.07,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 03:56",
    currentValue: 6.8,
    currentUnit: "A",
    powerValue: 1.44,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 03:56",
    currentValue: 68.8,
    currentUnit: "A",
    powerValue: 14.51,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 03:57",
    currentValue: 1.7,
    currentUnit: "A",
    powerValue: 0.36,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 03:57",
    currentValue: 2.8,
    currentUnit: "A",
    powerValue: 0.6,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 03:57",
    currentValue: 1,
    currentUnit: "A",
    powerValue: 0.21,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 03:57",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 03:58",
    currentValue: 1.8,
    currentUnit: "A",
    powerValue: 0.38,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 03:58",
    currentValue: 2.7,
    currentUnit: "A",
    powerValue: 0.58,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 03:58",
    currentValue: 1,
    currentUnit: "A",
    powerValue: 0.21,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 03:58",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 03:59",
    currentValue: 1.7,
    currentUnit: "A",
    powerValue: 0.36,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 03:59",
    currentValue: 2.8,
    currentUnit: "A",
    powerValue: 0.6,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 03:59",
    currentValue: 6.8,
    currentUnit: "A",
    powerValue: 1.44,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 03:59",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 04:00",
    currentValue: 1.8,
    currentUnit: "A",
    powerValue: 0.38,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 04:00",
    currentValue: 2.7,
    currentUnit: "A",
    powerValue: 0.58,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 04:00",
    currentValue: 1,
    currentUnit: "A",
    powerValue: 0.21,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 04:00",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 04:01",
    currentValue: 6.8,
    currentUnit: "A",
    powerValue: 1.44,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 04:01",
    currentValue: 2.8,
    currentUnit: "A",
    powerValue: 0.6,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 04:01",
    currentValue: 1,
    currentUnit: "A",
    powerValue: 0.21,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 04:01",
    currentValue: 42.5,
    currentUnit: "A",
    powerValue: 9.07,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 04:02",
    currentValue: 1.7,
    currentUnit: "A",
    powerValue: 0.36,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 04:02",
    currentValue: 2.7,
    currentUnit: "A",
    powerValue: 0.58,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 04:02",
    currentValue: 6.8,
    currentUnit: "A",
    powerValue: 1.44,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 04:02",
    currentValue: 35.7,
    currentUnit: "A",
    powerValue: 7.63,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 04:03",
    currentValue: 6.8,
    currentUnit: "A",
    powerValue: 1.44,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 04:03",
    currentValue: 2.8,
    currentUnit: "A",
    powerValue: 0.6,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 04:03",
    currentValue: 1,
    currentUnit: "A",
    powerValue: 0.21,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 04:03",
    currentValue: 34.7,
    currentUnit: "A",
    powerValue: 7.42,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 04:04",
    currentValue: 1.7,
    currentUnit: "A",
    powerValue: 0.36,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 04:04",
    currentValue: 2.8,
    currentUnit: "A",
    powerValue: 0.6,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 04:04",
    currentValue: 1,
    currentUnit: "A",
    powerValue: 0.21,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 04:04",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 04:05",
    currentValue: 1.7,
    currentUnit: "A",
    powerValue: 0.36,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 04:05",
    currentValue: 2.8,
    currentUnit: "A",
    powerValue: 0.6,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 04:05",
    currentValue: 1.7,
    currentUnit: "A",
    powerValue: 0.36,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 04:05",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 04:06",
    currentValue: 1.7,
    currentUnit: "A",
    powerValue: 0.36,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 04:06",
    currentValue: 2.8,
    currentUnit: "A",
    powerValue: 0.6,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 04:06",
    currentValue: 1,
    currentUnit: "A",
    powerValue: 0.21,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 04:06",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 04:07",
    currentValue: 1.8,
    currentUnit: "A",
    powerValue: 0.38,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 04:07",
    currentValue: 2.7,
    currentUnit: "A",
    powerValue: 0.58,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 04:07",
    currentValue: 1,
    currentUnit: "A",
    powerValue: 0.21,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 04:07",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 04:08",
    currentValue: 1.7,
    currentUnit: "A",
    powerValue: 0.36,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 04:08",
    currentValue: 62.1,
    currentUnit: "A",
    powerValue: 13.22,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 04:08",
    currentValue: 1.8,
    currentUnit: "A",
    powerValue: 0.38,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 04:08",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 04:09",
    currentValue: 1.8,
    currentUnit: "A",
    powerValue: 0.38,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 04:09",
    currentValue: 2.8,
    currentUnit: "A",
    powerValue: 0.6,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 04:09",
    currentValue: 3.4,
    currentUnit: "A",
    powerValue: 0.72,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 04:09",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 04:10",
    currentValue: 56.7,
    currentUnit: "A",
    powerValue: 12.06,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 04:10",
    currentValue: 2.8,
    currentUnit: "A",
    powerValue: 0.6,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 04:10",
    currentValue: 1,
    currentUnit: "A",
    powerValue: 0.21,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 04:10",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 04:11",
    currentValue: 38,
    currentUnit: "A",
    powerValue: 8.1,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 04:11",
    currentValue: 2.8,
    currentUnit: "A",
    powerValue: 0.6,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 04:11",
    currentValue: 5.8,
    currentUnit: "A",
    powerValue: 1.23,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 04:11",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 04:12",
    currentValue: 37,
    currentUnit: "A",
    powerValue: 7.89,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 04:12",
    currentValue: 2.8,
    currentUnit: "A",
    powerValue: 0.6,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 04:12",
    currentValue: 1,
    currentUnit: "A",
    powerValue: 0.21,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 04:12",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 04:13",
    currentValue: 36.9,
    currentUnit: "A",
    powerValue: 7.87,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 04:13",
    currentValue: 2.8,
    currentUnit: "A",
    powerValue: 0.6,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 04:13",
    currentValue: 5,
    currentUnit: "A",
    powerValue: 1.06,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 04:13",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 04:14",
    currentValue: 35.9,
    currentUnit: "A",
    powerValue: 7.66,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 04:14",
    currentValue: 2.8,
    currentUnit: "A",
    powerValue: 0.6,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 04:14",
    currentValue: 6.8,
    currentUnit: "A",
    powerValue: 1.44,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 04:14",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 04:15",
    currentValue: 35.7,
    currentUnit: "A",
    powerValue: 7.61,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 04:15",
    currentValue: 2.8,
    currentUnit: "A",
    powerValue: 0.6,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 04:15",
    currentValue: 5,
    currentUnit: "A",
    powerValue: 1.06,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 04:15",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 04:16",
    currentValue: 34.9,
    currentUnit: "A",
    powerValue: 7.44,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 04:16",
    currentValue: 2.8,
    currentUnit: "A",
    powerValue: 0.6,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 04:16",
    currentValue: 1,
    currentUnit: "A",
    powerValue: 0.21,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 04:16",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 04:17",
    currentValue: 34.5,
    currentUnit: "A",
    powerValue: 7.36,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 04:17",
    currentValue: 42.6,
    currentUnit: "A",
    powerValue: 9,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 04:17",
    currentValue: 10.8,
    currentUnit: "A",
    powerValue: 2.29,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 04:17",
    currentValue: 68.7,
    currentUnit: "A",
    powerValue: 14.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 04:18",
    currentValue: 29.4,
    currentUnit: "A",
    powerValue: 6.29,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 04:18",
    currentValue: 37.7,
    currentUnit: "A",
    powerValue: 7.98,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 04:18",
    currentValue: 1,
    currentUnit: "A",
    powerValue: 0.21,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 04:18",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 04:19",
    currentValue: 29.1,
    currentUnit: "A",
    powerValue: 6.21,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 04:19",
    currentValue: 37,
    currentUnit: "A",
    powerValue: 7.83,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 04:19",
    currentValue: 5,
    currentUnit: "A",
    powerValue: 1.06,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 04:19",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 04:20",
    currentValue: 28.9,
    currentUnit: "A",
    powerValue: 6.17,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 04:20",
    currentValue: 37,
    currentUnit: "A",
    powerValue: 7.83,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 04:20",
    currentValue: 1.7,
    currentUnit: "A",
    powerValue: 0.36,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 04:20",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 04:21",
    currentValue: 28.4,
    currentUnit: "A",
    powerValue: 6.06,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 04:21",
    currentValue: 36.8,
    currentUnit: "A",
    powerValue: 7.78,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 04:21",
    currentValue: 5,
    currentUnit: "A",
    powerValue: 1.06,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 04:21",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 04:22",
    currentValue: 28,
    currentUnit: "A",
    powerValue: 5.98,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 04:22",
    currentValue: 36.5,
    currentUnit: "A",
    powerValue: 7.72,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 04:22",
    currentValue: 1,
    currentUnit: "A",
    powerValue: 0.21,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 04:22",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 04:23",
    currentValue: 23.8,
    currentUnit: "A",
    powerValue: 5.08,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 04:23",
    currentValue: 36.3,
    currentUnit: "A",
    powerValue: 7.68,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 04:23",
    currentValue: 5,
    currentUnit: "A",
    powerValue: 1.06,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 04:23",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 04:24",
    currentValue: 28.9,
    currentUnit: "A",
    powerValue: 6.16,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 04:24",
    currentValue: 36.2,
    currentUnit: "A",
    powerValue: 7.65,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 04:24",
    currentValue: 1,
    currentUnit: "A",
    powerValue: 0.21,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 04:24",
    currentValue: 41.8,
    currentUnit: "A",
    powerValue: 8.92,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 04:25",
    currentValue: 23.5,
    currentUnit: "A",
    powerValue: 5.02,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 04:25",
    currentValue: 35.9,
    currentUnit: "A",
    powerValue: 7.6,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 04:25",
    currentValue: 5,
    currentUnit: "A",
    powerValue: 1.06,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 04:25",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 04:26",
    currentValue: 23.4,
    currentUnit: "A",
    powerValue: 5,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 04:26",
    currentValue: 35.6,
    currentUnit: "A",
    powerValue: 7.53,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 04:26",
    currentValue: 2.2,
    currentUnit: "A",
    powerValue: 0.47,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 04:26",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 04:27",
    currentValue: 22.7,
    currentUnit: "A",
    powerValue: 4.85,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 04:27",
    currentValue: 28.6,
    currentUnit: "A",
    powerValue: 6.06,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 04:27",
    currentValue: 5,
    currentUnit: "A",
    powerValue: 1.06,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 04:27",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 04:28",
    currentValue: 21.5,
    currentUnit: "A",
    powerValue: 4.59,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 04:28",
    currentValue: 28.6,
    currentUnit: "A",
    powerValue: 6.06,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 04:28",
    currentValue: 1,
    currentUnit: "A",
    powerValue: 0.21,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 04:28",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 04:29",
    currentValue: 20.2,
    currentUnit: "A",
    powerValue: 4.31,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 04:29",
    currentValue: 87.8,
    currentUnit: "A",
    powerValue: 18.63,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 04:29",
    currentValue: 5,
    currentUnit: "A",
    powerValue: 1.06,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 04:29",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 04:30",
    currentValue: 18,
    currentUnit: "A",
    powerValue: 3.84,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 04:30",
    currentValue: 28.2,
    currentUnit: "A",
    powerValue: 5.98,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 04:30",
    currentValue: 1,
    currentUnit: "A",
    powerValue: 0.21,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 04:30",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 04:31",
    currentValue: 23.1,
    currentUnit: "A",
    powerValue: 4.92,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 04:31",
    currentValue: 28.1,
    currentUnit: "A",
    powerValue: 5.95,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 04:31",
    currentValue: 5,
    currentUnit: "A",
    powerValue: 1.06,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 04:31",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 04:32",
    currentValue: 17.9,
    currentUnit: "A",
    powerValue: 3.83,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 04:32",
    currentValue: 27.9,
    currentUnit: "A",
    powerValue: 5.91,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 04:32",
    currentValue: 1,
    currentUnit: "A",
    powerValue: 0.21,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 04:32",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 04:33",
    currentValue: 17.4,
    currentUnit: "A",
    powerValue: 3.72,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 04:33",
    currentValue: 27.6,
    currentUnit: "A",
    powerValue: 5.85,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 04:33",
    currentValue: 5,
    currentUnit: "A",
    powerValue: 1.06,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 04:33",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 04:34",
    currentValue: 17.3,
    currentUnit: "A",
    powerValue: 3.7,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 04:34",
    currentValue: 27.3,
    currentUnit: "A",
    powerValue: 5.79,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 04:34",
    currentValue: 1,
    currentUnit: "A",
    powerValue: 0.21,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 04:34",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 04:35",
    currentValue: 17.1,
    currentUnit: "A",
    powerValue: 3.66,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 04:35",
    currentValue: 23.3,
    currentUnit: "A",
    powerValue: 4.94,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 04:35",
    currentValue: 5,
    currentUnit: "A",
    powerValue: 1.06,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 04:35",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 04:36",
    currentValue: 26.3,
    currentUnit: "A",
    powerValue: 5.62,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 04:36",
    currentValue: 28.2,
    currentUnit: "A",
    powerValue: 5.97,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 04:36",
    currentValue: 1,
    currentUnit: "A",
    powerValue: 0.21,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 04:36",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 04:37",
    currentValue: 24.9,
    currentUnit: "A",
    powerValue: 5.32,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 04:37",
    currentValue: 23.2,
    currentUnit: "A",
    powerValue: 4.92,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 04:37",
    currentValue: 4.9,
    currentUnit: "A",
    powerValue: 1.04,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 04:37",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 04:38",
    currentValue: 27.8,
    currentUnit: "A",
    powerValue: 5.92,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 04:38",
    currentValue: 23.1,
    currentUnit: "A",
    powerValue: 4.89,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 04:38",
    currentValue: 1,
    currentUnit: "A",
    powerValue: 0.21,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 04:38",
    currentValue: 68.7,
    currentUnit: "A",
    powerValue: 14.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 04:39",
    currentValue: 1.7,
    currentUnit: "A",
    powerValue: 0.36,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 04:39",
    currentValue: 28.1,
    currentUnit: "A",
    powerValue: 5.95,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 04:39",
    currentValue: 5,
    currentUnit: "A",
    powerValue: 1.06,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 04:39",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 04:40",
    currentValue: 1.7,
    currentUnit: "A",
    powerValue: 0.36,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 04:40",
    currentValue: 22.9,
    currentUnit: "A",
    powerValue: 4.85,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 04:40",
    currentValue: 1,
    currentUnit: "A",
    powerValue: 0.21,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 04:40",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 04:41",
    currentValue: 1.7,
    currentUnit: "A",
    powerValue: 0.36,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 04:41",
    currentValue: 22.7,
    currentUnit: "A",
    powerValue: 4.81,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 04:41",
    currentValue: 5,
    currentUnit: "A",
    powerValue: 1.06,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 04:41",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 04:42",
    currentValue: 1.7,
    currentUnit: "A",
    powerValue: 0.36,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 04:42",
    currentValue: 27.6,
    currentUnit: "A",
    powerValue: 5.85,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 04:42",
    currentValue: 1,
    currentUnit: "A",
    powerValue: 0.21,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 04:42",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 04:43",
    currentValue: 1.7,
    currentUnit: "A",
    powerValue: 0.36,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 04:43",
    currentValue: 22.5,
    currentUnit: "A",
    powerValue: 4.77,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 04:43",
    currentValue: 5,
    currentUnit: "A",
    powerValue: 1.06,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 04:43",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 04:44",
    currentValue: 1.7,
    currentUnit: "A",
    powerValue: 0.36,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 04:44",
    currentValue: 22.3,
    currentUnit: "A",
    powerValue: 4.73,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 04:44",
    currentValue: 1,
    currentUnit: "A",
    powerValue: 0.21,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 04:44",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 04:45",
    currentValue: 6.8,
    currentUnit: "A",
    powerValue: 1.44,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 04:45",
    currentValue: 21.8,
    currentUnit: "A",
    powerValue: 4.62,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 04:45",
    currentValue: 5,
    currentUnit: "A",
    powerValue: 1.06,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 04:45",
    currentValue: 37.7,
    currentUnit: "A",
    powerValue: 8.05,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 04:46",
    currentValue: 1.7,
    currentUnit: "A",
    powerValue: 0.36,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 04:46",
    currentValue: 2.8,
    currentUnit: "A",
    powerValue: 0.6,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 04:46",
    currentValue: 1,
    currentUnit: "A",
    powerValue: 0.21,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 04:46",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 04:47",
    currentValue: 1.7,
    currentUnit: "A",
    powerValue: 0.36,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 04:47",
    currentValue: 2.8,
    currentUnit: "A",
    powerValue: 0.6,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 04:47",
    currentValue: 5,
    currentUnit: "A",
    powerValue: 1.06,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 04:47",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 04:48",
    currentValue: 1.7,
    currentUnit: "A",
    powerValue: 0.36,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 04:48",
    currentValue: 7.8,
    currentUnit: "A",
    powerValue: 1.65,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 04:48",
    currentValue: 6.8,
    currentUnit: "A",
    powerValue: 1.44,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 04:48",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 04:49",
    currentValue: 1.7,
    currentUnit: "A",
    powerValue: 0.36,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 04:49",
    currentValue: 2.7,
    currentUnit: "A",
    powerValue: 0.57,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 04:49",
    currentValue: 5,
    currentUnit: "A",
    powerValue: 1.06,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 04:49",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 04:50",
    currentValue: 6.8,
    currentUnit: "A",
    powerValue: 1.44,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 04:50",
    currentValue: 71.7,
    currentUnit: "A",
    powerValue: 15.24,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 04:50",
    currentValue: 1,
    currentUnit: "A",
    powerValue: 0.21,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 04:50",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 04:51",
    currentValue: 1.7,
    currentUnit: "A",
    powerValue: 0.36,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 04:51",
    currentValue: 7.8,
    currentUnit: "A",
    powerValue: 1.65,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 04:51",
    currentValue: 9.1,
    currentUnit: "A",
    powerValue: 1.93,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 04:51",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 04:52",
    currentValue: 6.8,
    currentUnit: "A",
    powerValue: 1.44,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 04:52",
    currentValue: 2.7,
    currentUnit: "A",
    powerValue: 0.57,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 04:52",
    currentValue: 1,
    currentUnit: "A",
    powerValue: 0.21,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 04:52",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 04:53",
    currentValue: 1.7,
    currentUnit: "A",
    powerValue: 0.36,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 04:53",
    currentValue: 2.7,
    currentUnit: "A",
    powerValue: 0.57,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 04:53",
    currentValue: 5,
    currentUnit: "A",
    powerValue: 1.06,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 04:53",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 04:54",
    currentValue: 1.8,
    currentUnit: "A",
    powerValue: 0.38,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 04:54",
    currentValue: 7.8,
    currentUnit: "A",
    powerValue: 1.65,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 04:54",
    currentValue: 6.8,
    currentUnit: "A",
    powerValue: 1.44,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 04:54",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 04:55",
    currentValue: 1.7,
    currentUnit: "A",
    powerValue: 0.36,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 04:55",
    currentValue: 2.8,
    currentUnit: "A",
    powerValue: 0.6,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 04:55",
    currentValue: 5,
    currentUnit: "A",
    powerValue: 1.06,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 04:55",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 04:56",
    currentValue: 1.7,
    currentUnit: "A",
    powerValue: 0.36,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 04:56",
    currentValue: 2.8,
    currentUnit: "A",
    powerValue: 0.6,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 04:56",
    currentValue: 1,
    currentUnit: "A",
    powerValue: 0.21,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 04:56",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 04:57",
    currentValue: 6.8,
    currentUnit: "A",
    powerValue: 1.44,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 04:57",
    currentValue: 7.8,
    currentUnit: "A",
    powerValue: 1.65,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 04:57",
    currentValue: 10.8,
    currentUnit: "A",
    powerValue: 2.29,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 04:57",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 04:58",
    currentValue: 1.7,
    currentUnit: "A",
    powerValue: 0.36,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 04:58",
    currentValue: 2.8,
    currentUnit: "A",
    powerValue: 0.6,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 04:58",
    currentValue: 1,
    currentUnit: "A",
    powerValue: 0.21,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 04:58",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 04:59",
    currentValue: 1.7,
    currentUnit: "A",
    powerValue: 0.36,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 04:59",
    currentValue: 2.8,
    currentUnit: "A",
    powerValue: 0.6,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 04:59",
    currentValue: 5,
    currentUnit: "A",
    powerValue: 1.06,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 04:59",
    currentValue: 67.4,
    currentUnit: "A",
    powerValue: 14.16,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 05:00",
    currentValue: 1.7,
    currentUnit: "A",
    powerValue: 0.36,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 05:00",
    currentValue: 7.8,
    currentUnit: "A",
    powerValue: 1.65,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 05:00",
    currentValue: 6.8,
    currentUnit: "A",
    powerValue: 1.44,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 05:00",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 05:01",
    currentValue: 1.7,
    currentUnit: "A",
    powerValue: 0.36,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 05:01",
    currentValue: 2.8,
    currentUnit: "A",
    powerValue: 0.6,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 05:01",
    currentValue: 5,
    currentUnit: "A",
    powerValue: 1.06,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 05:01",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 05:02",
    currentValue: 1.7,
    currentUnit: "A",
    powerValue: 0.36,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 05:02",
    currentValue: 2.8,
    currentUnit: "A",
    powerValue: 0.6,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 05:02",
    currentValue: 1,
    currentUnit: "A",
    powerValue: 0.21,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 05:02",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 05:03",
    currentValue: 1.7,
    currentUnit: "A",
    powerValue: 0.36,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 05:03",
    currentValue: 2.7,
    currentUnit: "A",
    powerValue: 0.57,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 05:03",
    currentValue: 10.8,
    currentUnit: "A",
    powerValue: 2.29,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 05:03",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 05:04",
    currentValue: 6.8,
    currentUnit: "A",
    powerValue: 1.44,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 05:04",
    currentValue: 2.8,
    currentUnit: "A",
    powerValue: 0.6,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 05:04",
    currentValue: 1,
    currentUnit: "A",
    powerValue: 0.21,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 05:04",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 05:05",
    currentValue: 1.7,
    currentUnit: "A",
    powerValue: 0.36,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 05:05",
    currentValue: 2.8,
    currentUnit: "A",
    powerValue: 0.6,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 05:05",
    currentValue: 5,
    currentUnit: "A",
    powerValue: 1.06,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 05:05",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 05:06",
    currentValue: 1.8,
    currentUnit: "A",
    powerValue: 0.38,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 05:06",
    currentValue: 2.7,
    currentUnit: "A",
    powerValue: 0.57,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 05:06",
    currentValue: 6.8,
    currentUnit: "A",
    powerValue: 1.44,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 05:06",
    currentValue: 66.5,
    currentUnit: "A",
    powerValue: 14.13,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 05:07",
    currentValue: 1.7,
    currentUnit: "A",
    powerValue: 0.36,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 05:07",
    currentValue: 2.8,
    currentUnit: "A",
    powerValue: 0.6,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 05:07",
    currentValue: 5,
    currentUnit: "A",
    powerValue: 1.06,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 05:07",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 05:08",
    currentValue: 1.7,
    currentUnit: "A",
    powerValue: 0.36,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 05:08",
    currentValue: 2.8,
    currentUnit: "A",
    powerValue: 0.6,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 05:08",
    currentValue: 1,
    currentUnit: "A",
    powerValue: 0.21,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 05:08",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 05:09",
    currentValue: 1.7,
    currentUnit: "A",
    powerValue: 0.36,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 05:09",
    currentValue: 2.7,
    currentUnit: "A",
    powerValue: 0.58,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 05:09",
    currentValue: 10.9,
    currentUnit: "A",
    powerValue: 2.31,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 05:09",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 05:10",
    currentValue: 1.7,
    currentUnit: "A",
    powerValue: 0.36,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 05:10",
    currentValue: 2.7,
    currentUnit: "A",
    powerValue: 0.58,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 05:10",
    currentValue: 1,
    currentUnit: "A",
    powerValue: 0.21,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 05:10",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 05:11",
    currentValue: 6.8,
    currentUnit: "A",
    powerValue: 1.44,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 05:11",
    currentValue: 56.1,
    currentUnit: "A",
    powerValue: 11.93,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 05:11",
    currentValue: 5,
    currentUnit: "A",
    powerValue: 1.06,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 05:11",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 05:12",
    currentValue: 1.7,
    currentUnit: "A",
    powerValue: 0.36,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 05:12",
    currentValue: 2.7,
    currentUnit: "A",
    powerValue: 0.58,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 05:12",
    currentValue: 6.8,
    currentUnit: "A",
    powerValue: 1.44,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 05:12",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 05:13",
    currentValue: 1.7,
    currentUnit: "A",
    powerValue: 0.36,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 05:13",
    currentValue: 2.7,
    currentUnit: "A",
    powerValue: 0.57,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 05:13",
    currentValue: 5,
    currentUnit: "A",
    powerValue: 1.06,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 05:13",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 05:14",
    currentValue: 1.7,
    currentUnit: "A",
    powerValue: 0.36,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 05:14",
    currentValue: 2.7,
    currentUnit: "A",
    powerValue: 0.58,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 05:14",
    currentValue: 1,
    currentUnit: "A",
    powerValue: 0.21,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 05:14",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 05:15",
    currentValue: 1.7,
    currentUnit: "A",
    powerValue: 0.36,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 05:15",
    currentValue: 2.7,
    currentUnit: "A",
    powerValue: 0.58,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 05:15",
    currentValue: 10.9,
    currentUnit: "A",
    powerValue: 2.31,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 05:15",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 05:16",
    currentValue: 6.8,
    currentUnit: "A",
    powerValue: 1.44,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 05:16",
    currentValue: 2.8,
    currentUnit: "A",
    powerValue: 0.6,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 05:16",
    currentValue: 1,
    currentUnit: "A",
    powerValue: 0.21,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 05:16",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 05:17",
    currentValue: 1.7,
    currentUnit: "A",
    powerValue: 0.36,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 05:17",
    currentValue: 2.7,
    currentUnit: "A",
    powerValue: 0.57,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 05:17",
    currentValue: 5,
    currentUnit: "A",
    powerValue: 1.06,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 05:17",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 05:18",
    currentValue: 1.7,
    currentUnit: "A",
    powerValue: 0.36,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 05:18",
    currentValue: 2.7,
    currentUnit: "A",
    powerValue: 0.58,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 05:18",
    currentValue: 6.9,
    currentUnit: "A",
    powerValue: 1.46,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 05:18",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 05:19",
    currentValue: 1.7,
    currentUnit: "A",
    powerValue: 0.36,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 05:19",
    currentValue: 2.7,
    currentUnit: "A",
    powerValue: 0.57,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 05:19",
    currentValue: 5,
    currentUnit: "A",
    powerValue: 1.06,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 05:19",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 05:20",
    currentValue: 1.7,
    currentUnit: "A",
    powerValue: 0.36,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 05:20",
    currentValue: 2.7,
    currentUnit: "A",
    powerValue: 0.57,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 05:20",
    currentValue: 1,
    currentUnit: "A",
    powerValue: 0.21,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 05:20",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 05:21",
    currentValue: 1.7,
    currentUnit: "A",
    powerValue: 0.36,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 05:21",
    currentValue: 2.7,
    currentUnit: "A",
    powerValue: 0.57,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 05:21",
    currentValue: 5.7,
    currentUnit: "A",
    powerValue: 1.21,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 05:21",
    currentValue: 35.9,
    currentUnit: "A",
    powerValue: 7.59,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 05:22",
    currentValue: 1.7,
    currentUnit: "A",
    powerValue: 0.36,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 05:22",
    currentValue: 2.7,
    currentUnit: "A",
    powerValue: 0.57,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 05:22",
    currentValue: 1,
    currentUnit: "A",
    powerValue: 0.21,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 05:22",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 05:23",
    currentValue: 6.8,
    currentUnit: "A",
    powerValue: 1.44,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 05:23",
    currentValue: 2.7,
    currentUnit: "A",
    powerValue: 0.57,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 05:23",
    currentValue: 5,
    currentUnit: "A",
    powerValue: 1.06,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 05:23",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 05:24",
    currentValue: 1.7,
    currentUnit: "A",
    powerValue: 0.36,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 05:24",
    currentValue: 2.7,
    currentUnit: "A",
    powerValue: 0.57,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 05:24",
    currentValue: 5.9,
    currentUnit: "A",
    powerValue: 1.25,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 05:24",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 05:25",
    currentValue: 1.7,
    currentUnit: "A",
    powerValue: 0.36,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 05:25",
    currentValue: 2.8,
    currentUnit: "A",
    powerValue: 0.6,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 05:25",
    currentValue: 5,
    currentUnit: "A",
    powerValue: 1.06,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 05:25",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 05:26",
    currentValue: 1.7,
    currentUnit: "A",
    powerValue: 0.36,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 05:26",
    currentValue: 2.7,
    currentUnit: "A",
    powerValue: 0.57,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 05:26",
    currentValue: 1,
    currentUnit: "A",
    powerValue: 0.21,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 05:26",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 05:27",
    currentValue: 1.7,
    currentUnit: "A",
    powerValue: 0.36,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 05:27",
    currentValue: 2.7,
    currentUnit: "A",
    powerValue: 0.57,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 05:27",
    currentValue: 5.7,
    currentUnit: "A",
    powerValue: 1.21,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 05:27",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 05:28",
    currentValue: 1.7,
    currentUnit: "A",
    powerValue: 0.36,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 05:28",
    currentValue: 2.7,
    currentUnit: "A",
    powerValue: 0.57,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 05:28",
    currentValue: 1,
    currentUnit: "A",
    powerValue: 0.21,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 05:28",
    currentValue: 27.2,
    currentUnit: "A",
    powerValue: 5.8,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 05:29",
    currentValue: 1.7,
    currentUnit: "A",
    powerValue: 0.36,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 05:29",
    currentValue: 2.7,
    currentUnit: "A",
    powerValue: 0.57,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 05:29",
    currentValue: 5,
    currentUnit: "A",
    powerValue: 1.06,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 05:29",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 05:30",
    currentValue: 32.6,
    currentUnit: "A",
    powerValue: 6.85,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 05:30",
    currentValue: 2.7,
    currentUnit: "A",
    powerValue: 0.57,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 05:30",
    currentValue: 4.5,
    currentUnit: "A",
    powerValue: 0.95,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 05:30",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 05:31",
    currentValue: 71.1,
    currentUnit: "A",
    powerValue: 14.69,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 05:31",
    currentValue: 2.7,
    currentUnit: "A",
    powerValue: 0.57,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 05:31",
    currentValue: 5,
    currentUnit: "A",
    powerValue: 1.06,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 05:31",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 05:32",
    currentValue: 71.1,
    currentUnit: "A",
    powerValue: 14.69,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 05:32",
    currentValue: 2.7,
    currentUnit: "A",
    powerValue: 0.57,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 05:32",
    currentValue: 1,
    currentUnit: "A",
    powerValue: 0.21,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 05:32",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 05:33",
    currentValue: 71.1,
    currentUnit: "A",
    powerValue: 14.7,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 05:33",
    currentValue: 39.5,
    currentUnit: "A",
    powerValue: 8.4,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 05:33",
    currentValue: 8.3,
    currentUnit: "A",
    powerValue: 1.76,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 05:33",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 05:34",
    currentValue: 71.3,
    currentUnit: "A",
    powerValue: 14.73,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 05:34",
    currentValue: 2.7,
    currentUnit: "A",
    powerValue: 0.57,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 05:34",
    currentValue: 1,
    currentUnit: "A",
    powerValue: 0.21,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 05:34",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 05:35",
    currentValue: 71.3,
    currentUnit: "A",
    powerValue: 14.73,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 05:35",
    currentValue: 2.7,
    currentUnit: "A",
    powerValue: 0.57,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 05:35",
    currentValue: 4.1,
    currentUnit: "A",
    powerValue: 0.87,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 05:35",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 05:36",
    currentValue: 71.4,
    currentUnit: "A",
    powerValue: 14.75,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 05:36",
    currentValue: 2.7,
    currentUnit: "A",
    powerValue: 0.57,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 05:36",
    currentValue: 1,
    currentUnit: "A",
    powerValue: 0.21,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 05:36",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 05:37",
    currentValue: 75.9,
    currentUnit: "A",
    powerValue: 15.7,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 05:37",
    currentValue: 2.8,
    currentUnit: "A",
    powerValue: 0.6,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 05:37",
    currentValue: 1.8,
    currentUnit: "A",
    powerValue: 0.38,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 05:37",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 05:38",
    currentValue: 70.8,
    currentUnit: "A",
    powerValue: 14.64,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 05:38",
    currentValue: 2.7,
    currentUnit: "A",
    powerValue: 0.57,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 05:38",
    currentValue: 1,
    currentUnit: "A",
    powerValue: 0.21,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 05:38",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 05:39",
    currentValue: 71,
    currentUnit: "A",
    powerValue: 14.66,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 05:39",
    currentValue: 2.8,
    currentUnit: "A",
    powerValue: 0.59,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 05:39",
    currentValue: 1.8,
    currentUnit: "A",
    powerValue: 0.38,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 05:39",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 05:40",
    currentValue: 71,
    currentUnit: "A",
    powerValue: 14.65,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 05:40",
    currentValue: 2.8,
    currentUnit: "A",
    powerValue: 0.59,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 05:40",
    currentValue: 1,
    currentUnit: "A",
    powerValue: 0.21,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 05:40",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 05:41",
    currentValue: 58.2,
    currentUnit: "A",
    powerValue: 12.08,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 05:41",
    currentValue: 2.8,
    currentUnit: "A",
    powerValue: 0.6,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 05:41",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.42,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 05:41",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 05:42",
    currentValue: 50,
    currentUnit: "A",
    powerValue: 10.41,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 05:42",
    currentValue: 2.8,
    currentUnit: "A",
    powerValue: 0.6,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 05:42",
    currentValue: 1,
    currentUnit: "A",
    powerValue: 0.21,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 05:42",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 05:43",
    currentValue: 42.8,
    currentUnit: "A",
    powerValue: 8.94,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 05:43",
    currentValue: 2.8,
    currentUnit: "A",
    powerValue: 0.59,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 05:43",
    currentValue: 1.8,
    currentUnit: "A",
    powerValue: 0.38,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 05:43",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 05:44",
    currentValue: 41.6,
    currentUnit: "A",
    powerValue: 8.72,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 05:44",
    currentValue: 2.7,
    currentUnit: "A",
    powerValue: 0.57,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 05:44",
    currentValue: 1,
    currentUnit: "A",
    powerValue: 0.21,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 05:44",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 05:45",
    currentValue: 32.3,
    currentUnit: "A",
    powerValue: 6.78,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 05:45",
    currentValue: 2.7,
    currentUnit: "A",
    powerValue: 0.57,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 05:45",
    currentValue: 2.9,
    currentUnit: "A",
    powerValue: 0.61,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 05:45",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 05:46",
    currentValue: 28.8,
    currentUnit: "A",
    powerValue: 6.05,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 05:46",
    currentValue: 2.8,
    currentUnit: "A",
    powerValue: 0.6,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 05:46",
    currentValue: 1,
    currentUnit: "A",
    powerValue: 0.21,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 05:46",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 05:47",
    currentValue: 26.9,
    currentUnit: "A",
    powerValue: 5.66,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 05:47",
    currentValue: 2.9,
    currentUnit: "A",
    powerValue: 0.62,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 05:47",
    currentValue: 1.8,
    currentUnit: "A",
    powerValue: 0.38,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 05:47",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 05:48",
    currentValue: 26.3,
    currentUnit: "A",
    powerValue: 5.53,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 05:48",
    currentValue: 2.7,
    currentUnit: "A",
    powerValue: 0.57,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 05:48",
    currentValue: 1,
    currentUnit: "A",
    powerValue: 0.21,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 05:48",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 05:49",
    currentValue: 50.7,
    currentUnit: "A",
    powerValue: 10.56,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 05:49",
    currentValue: 2.7,
    currentUnit: "A",
    powerValue: 0.57,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 05:49",
    currentValue: 4.9,
    currentUnit: "A",
    powerValue: 1.04,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 05:49",
    currentValue: 63.1,
    currentUnit: "A",
    powerValue: 13.37,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 05:50",
    currentValue: 1.7,
    currentUnit: "A",
    powerValue: 0.36,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 05:50",
    currentValue: 2.9,
    currentUnit: "A",
    powerValue: 0.62,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 05:50",
    currentValue: 1,
    currentUnit: "A",
    powerValue: 0.21,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 05:50",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 05:51",
    currentValue: 6.8,
    currentUnit: "A",
    powerValue: 1.44,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 05:51",
    currentValue: 2.8,
    currentUnit: "A",
    powerValue: 2.28,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 05:51",
    currentValue: 5,
    currentUnit: "A",
    powerValue: 1.06,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 05:51",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 05:52",
    currentValue: 1.7,
    currentUnit: "A",
    powerValue: 0.36,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 05:52",
    currentValue: 2.8,
    currentUnit: "A",
    powerValue: 0.6,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 05:52",
    currentValue: 1,
    currentUnit: "A",
    powerValue: 0.21,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 05:52",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 05:53",
    currentValue: 1.7,
    currentUnit: "A",
    powerValue: 0.36,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 05:53",
    currentValue: 7.8,
    currentUnit: "A",
    powerValue: 1.65,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 05:53",
    currentValue: 5,
    currentUnit: "A",
    powerValue: 1.06,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 05:53",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 05:54",
    currentValue: 1.7,
    currentUnit: "A",
    powerValue: 0.36,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 05:54",
    currentValue: 39.4,
    currentUnit: "A",
    powerValue: 8.38,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 05:54",
    currentValue: 1,
    currentUnit: "A",
    powerValue: 0.21,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 05:54",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 05:55",
    currentValue: 1.7,
    currentUnit: "A",
    powerValue: 0.36,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 05:55",
    currentValue: 2.7,
    currentUnit: "A",
    powerValue: 0.57,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 05:55",
    currentValue: 10.9,
    currentUnit: "A",
    powerValue: 2.3,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 05:55",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 05:56",
    currentValue: 1.7,
    currentUnit: "A",
    powerValue: 0.36,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 05:56",
    currentValue: 7.8,
    currentUnit: "A",
    powerValue: 1.65,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 05:56",
    currentValue: 1,
    currentUnit: "A",
    powerValue: 0.21,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 05:56",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 05:57",
    currentValue: 1.7,
    currentUnit: "A",
    powerValue: 0.36,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 05:57",
    currentValue: 2.8,
    currentUnit: "A",
    powerValue: 0.59,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 05:57",
    currentValue: 3.1,
    currentUnit: "A",
    powerValue: 0.65,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 05:57",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 05:58",
    currentValue: 6.8,
    currentUnit: "A",
    powerValue: 1.44,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 05:58",
    currentValue: 2.8,
    currentUnit: "A",
    powerValue: 0.59,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 05:58",
    currentValue: 6.9,
    currentUnit: "A",
    powerValue: 1.46,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 05:58",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 05:59",
    currentValue: 1.8,
    currentUnit: "A",
    powerValue: 0.38,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 05:59",
    currentValue: 2.8,
    currentUnit: "A",
    powerValue: 0.6,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 05:59",
    currentValue: 1.8,
    currentUnit: "A",
    powerValue: 0.38,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 05:59",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 06:00",
    currentValue: 1.7,
    currentUnit: "A",
    powerValue: 0.36,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 06:00",
    currentValue: 2.8,
    currentUnit: "A",
    powerValue: 0.6,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 06:00",
    currentValue: 1,
    currentUnit: "A",
    powerValue: 0.21,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 06:00",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 06:01",
    currentValue: 1.7,
    currentUnit: "A",
    powerValue: 0.36,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 06:01",
    currentValue: 2.8,
    currentUnit: "A",
    powerValue: 0.6,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 06:01",
    currentValue: 7.8,
    currentUnit: "A",
    powerValue: 1.65,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 06:01",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 06:02",
    currentValue: 1.7,
    currentUnit: "A",
    powerValue: 0.36,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 06:02",
    currentValue: 7.8,
    currentUnit: "A",
    powerValue: 1.65,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 06:02",
    currentValue: 1,
    currentUnit: "A",
    powerValue: 0.21,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 06:02",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 06:03",
    currentValue: 1.7,
    currentUnit: "A",
    powerValue: 0.36,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 06:03",
    currentValue: 2.8,
    currentUnit: "A",
    powerValue: 0.6,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 06:03",
    currentValue: 5,
    currentUnit: "A",
    powerValue: 1.06,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 06:03",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 06:04",
    currentValue: 1.7,
    currentUnit: "A",
    powerValue: 0.36,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 06:04",
    currentValue: 2.8,
    currentUnit: "A",
    powerValue: 0.6,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 06:04",
    currentValue: 6.9,
    currentUnit: "A",
    powerValue: 1.46,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 06:04",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 06:05",
    currentValue: 6.8,
    currentUnit: "A",
    powerValue: 1.44,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 06:05",
    currentValue: 7.8,
    currentUnit: "A",
    powerValue: 1.65,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 06:05",
    currentValue: 1.7,
    currentUnit: "A",
    powerValue: 0.36,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 06:05",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 06:06",
    currentValue: 1.7,
    currentUnit: "A",
    powerValue: 0.36,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 06:06",
    currentValue: 2.7,
    currentUnit: "A",
    powerValue: 0.57,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 06:06",
    currentValue: 1,
    currentUnit: "A",
    powerValue: 0.21,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 06:06",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 06:07",
    currentValue: 1.7,
    currentUnit: "A",
    powerValue: 0.36,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 06:07",
    currentValue: 2.8,
    currentUnit: "A",
    powerValue: 0.6,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 06:07",
    currentValue: 6.9,
    currentUnit: "A",
    powerValue: 1.46,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 06:07",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 06:08",
    currentValue: 1.7,
    currentUnit: "A",
    powerValue: 0.36,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 06:08",
    currentValue: 7.8,
    currentUnit: "A",
    powerValue: 1.65,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 06:08",
    currentValue: 1,
    currentUnit: "A",
    powerValue: 0.21,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 06:08",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 06:09",
    currentValue: 1.8,
    currentUnit: "A",
    powerValue: 0.38,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 06:09",
    currentValue: 2.8,
    currentUnit: "A",
    powerValue: 0.6,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 06:09",
    currentValue: 1,
    currentUnit: "A",
    powerValue: 0.21,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 06:09",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 06:10",
    currentValue: 6.8,
    currentUnit: "A",
    powerValue: 1.44,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 06:10",
    currentValue: 2.7,
    currentUnit: "A",
    powerValue: 0.57,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 06:10",
    currentValue: 6.9,
    currentUnit: "A",
    powerValue: 1.46,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 06:10",
    currentValue: 27.2,
    currentUnit: "A",
    powerValue: 5.8,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 06:11",
    currentValue: 1.7,
    currentUnit: "A",
    powerValue: 0.36,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 06:11",
    currentValue: 7.8,
    currentUnit: "A",
    powerValue: 1.65,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 06:11",
    currentValue: 1,
    currentUnit: "A",
    powerValue: 0.21,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 06:11",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 06:12",
    currentValue: 1.9,
    currentUnit: "A",
    powerValue: 0.4,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 06:12",
    currentValue: 2.8,
    currentUnit: "A",
    powerValue: 0.6,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 06:12",
    currentValue: 1,
    currentUnit: "A",
    powerValue: 0.21,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 06:12",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 06:13",
    currentValue: 1.8,
    currentUnit: "A",
    powerValue: 0.38,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 06:13",
    currentValue: 2.8,
    currentUnit: "A",
    powerValue: 0.6,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 06:13",
    currentValue: 6.9,
    currentUnit: "A",
    powerValue: 1.46,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 06:13",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 06:14",
    currentValue: 1.8,
    currentUnit: "A",
    powerValue: 0.38,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 06:14",
    currentValue: 7.8,
    currentUnit: "A",
    powerValue: 1.65,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 06:14",
    currentValue: 1,
    currentUnit: "A",
    powerValue: 0.21,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 06:14",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 06:15",
    currentValue: 1.7,
    currentUnit: "A",
    powerValue: 0.36,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 06:15",
    currentValue: 46.5,
    currentUnit: "A",
    powerValue: 9.89,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 06:15",
    currentValue: 1,
    currentUnit: "A",
    powerValue: 0.21,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 06:15",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 06:16",
    currentValue: 1.7,
    currentUnit: "A",
    powerValue: 0.36,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 06:16",
    currentValue: 2.8,
    currentUnit: "A",
    powerValue: 0.6,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 06:16",
    currentValue: 6.9,
    currentUnit: "A",
    powerValue: 1.46,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 06:16",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 06:17",
    currentValue: 6.8,
    currentUnit: "A",
    powerValue: 1.44,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 06:17",
    currentValue: 2.8,
    currentUnit: "A",
    powerValue: 0.59,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 06:17",
    currentValue: 1,
    currentUnit: "A",
    powerValue: 0.21,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 06:17",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 06:18",
    currentValue: 1.8,
    currentUnit: "A",
    powerValue: 0.38,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 06:18",
    currentValue: 2.8,
    currentUnit: "A",
    powerValue: 0.59,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 06:18",
    currentValue: 1,
    currentUnit: "A",
    powerValue: 0.21,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 06:18",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 06:19",
    currentValue: 6.8,
    currentUnit: "A",
    powerValue: 1.44,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 06:19",
    currentValue: 2.8,
    currentUnit: "A",
    powerValue: 0.6,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 06:19",
    currentValue: 6.8,
    currentUnit: "A",
    powerValue: 1.44,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 06:19",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 06:20",
    currentValue: 1.7,
    currentUnit: "A",
    powerValue: 0.36,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 06:20",
    currentValue: 2.8,
    currentUnit: "A",
    powerValue: 0.6,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 06:20",
    currentValue: 1,
    currentUnit: "A",
    powerValue: 0.21,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 06:20",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 06:21",
    currentValue: 1.7,
    currentUnit: "A",
    powerValue: 0.36,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 06:21",
    currentValue: 2.8,
    currentUnit: "A",
    powerValue: 0.6,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 06:21",
    currentValue: 1,
    currentUnit: "A",
    powerValue: 0.21,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 06:21",
    currentValue: 37,
    currentUnit: "A",
    powerValue: 7.81,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 06:22",
    currentValue: 1.7,
    currentUnit: "A",
    powerValue: 0.36,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 06:22",
    currentValue: 2.7,
    currentUnit: "A",
    powerValue: 0.57,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 06:22",
    currentValue: 6.9,
    currentUnit: "A",
    powerValue: 1.46,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 06:22",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 06:23",
    currentValue: 1.7,
    currentUnit: "A",
    powerValue: 0.36,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 06:23",
    currentValue: 2.8,
    currentUnit: "A",
    powerValue: 0.6,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 06:23",
    currentValue: 1,
    currentUnit: "A",
    powerValue: 0.21,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 06:23",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 06:24",
    currentValue: 1.7,
    currentUnit: "A",
    powerValue: 0.36,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 06:24",
    currentValue: 2.7,
    currentUnit: "A",
    powerValue: 0.57,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 06:24",
    currentValue: 1,
    currentUnit: "A",
    powerValue: 0.21,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 06:24",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 06:25",
    currentValue: 1.7,
    currentUnit: "A",
    powerValue: 0.36,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 06:25",
    currentValue: 2.8,
    currentUnit: "A",
    powerValue: 0.6,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 06:25",
    currentValue: 6.9,
    currentUnit: "A",
    powerValue: 1.46,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 06:25",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 06:26",
    currentValue: 6.8,
    currentUnit: "A",
    powerValue: 1.44,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 06:26",
    currentValue: 2.8,
    currentUnit: "A",
    powerValue: 0.6,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 06:26",
    currentValue: 1,
    currentUnit: "A",
    powerValue: 0.21,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 06:26",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 06:27",
    currentValue: 1.7,
    currentUnit: "A",
    powerValue: 0.36,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 06:27",
    currentValue: 2.8,
    currentUnit: "A",
    powerValue: 0.6,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 06:27",
    currentValue: 1,
    currentUnit: "A",
    powerValue: 0.21,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 06:27",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 06:28",
    currentValue: 1.7,
    currentUnit: "A",
    powerValue: 0.36,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 06:28",
    currentValue: 2.8,
    currentUnit: "A",
    powerValue: 0.6,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 06:28",
    currentValue: 6.9,
    currentUnit: "A",
    powerValue: 1.46,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 06:28",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 06:29",
    currentValue: 1.7,
    currentUnit: "A",
    powerValue: 0.36,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 06:29",
    currentValue: 2.7,
    currentUnit: "A",
    powerValue: 0.57,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 06:29",
    currentValue: 1,
    currentUnit: "A",
    powerValue: 0.21,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 06:29",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 06:30",
    currentValue: 1.7,
    currentUnit: "A",
    powerValue: 0.36,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 06:30",
    currentValue: 2.7,
    currentUnit: "A",
    powerValue: 0.57,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 06:30",
    currentValue: 1,
    currentUnit: "A",
    powerValue: 0.21,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 06:30",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 06:31",
    currentValue: 1.7,
    currentUnit: "A",
    powerValue: 0.36,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 06:31",
    currentValue: 2.8,
    currentUnit: "A",
    powerValue: 0.6,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 06:31",
    currentValue: 6.9,
    currentUnit: "A",
    powerValue: 1.46,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 06:31",
    currentValue: 32.8,
    currentUnit: "A",
    powerValue: 7,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 06:32",
    currentValue: 1.7,
    currentUnit: "A",
    powerValue: 0.36,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 06:32",
    currentValue: 2.9,
    currentUnit: "A",
    powerValue: 0.62,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 06:32",
    currentValue: 5,
    currentUnit: "A",
    powerValue: 1.06,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 06:32",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 06:33",
    currentValue: 6.8,
    currentUnit: "A",
    powerValue: 1.44,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 06:33",
    currentValue: 2.8,
    currentUnit: "A",
    powerValue: 0.6,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 06:33",
    currentValue: 1,
    currentUnit: "A",
    powerValue: 0.21,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 06:33",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 06:34",
    currentValue: 1.7,
    currentUnit: "A",
    powerValue: 0.36,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 06:34",
    currentValue: 2.8,
    currentUnit: "A",
    powerValue: 0.6,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 06:34",
    currentValue: 10.8,
    currentUnit: "A",
    powerValue: 2.29,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 06:34",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 06:35",
    currentValue: 1.7,
    currentUnit: "A",
    powerValue: 0.36,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 06:35",
    currentValue: 2.7,
    currentUnit: "A",
    powerValue: 0.57,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 06:35",
    currentValue: 1,
    currentUnit: "A",
    powerValue: 0.21,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 06:35",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 06:36",
    currentValue: 1.8,
    currentUnit: "A",
    powerValue: 0.38,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 06:36",
    currentValue: 2.8,
    currentUnit: "A",
    powerValue: 0.6,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 06:36",
    currentValue: 5,
    currentUnit: "A",
    powerValue: 1.06,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 06:36",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 06:37",
    currentValue: 1.7,
    currentUnit: "A",
    powerValue: 0.36,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 06:37",
    currentValue: 2.8,
    currentUnit: "A",
    powerValue: 0.6,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 06:37",
    currentValue: 6.9,
    currentUnit: "A",
    powerValue: 1.46,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 06:37",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 06:38",
    currentValue: 1.7,
    currentUnit: "A",
    powerValue: 0.36,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 06:38",
    currentValue: 38.9,
    currentUnit: "A",
    powerValue: 8.3,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 06:38",
    currentValue: 5,
    currentUnit: "A",
    powerValue: 1.06,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 06:38",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 06:39",
    currentValue: 1.7,
    currentUnit: "A",
    powerValue: 0.36,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 06:39",
    currentValue: 2.8,
    currentUnit: "A",
    powerValue: 0.6,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 06:39",
    currentValue: 1,
    currentUnit: "A",
    powerValue: 0.21,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 06:39",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 06:40",
    currentValue: 6.8,
    currentUnit: "A",
    powerValue: 1.44,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 06:40",
    currentValue: 2.8,
    currentUnit: "A",
    powerValue: 0.6,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 06:40",
    currentValue: 7.6,
    currentUnit: "A",
    powerValue: 1.61,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 06:40",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 06:41",
    currentValue: 1.8,
    currentUnit: "A",
    powerValue: 0.38,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 06:41",
    currentValue: 2.8,
    currentUnit: "A",
    powerValue: 0.6,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 06:41",
    currentValue: 1,
    currentUnit: "A",
    powerValue: 0.21,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 06:41",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 06:42",
    currentValue: 1.7,
    currentUnit: "A",
    powerValue: 0.36,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 06:42",
    currentValue: 2.8,
    currentUnit: "A",
    powerValue: 0.6,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 06:42",
    currentValue: 1.7,
    currentUnit: "A",
    powerValue: 0.36,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 06:42",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 06:43",
    currentValue: 1.7,
    currentUnit: "A",
    powerValue: 0.36,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 06:43",
    currentValue: 2.8,
    currentUnit: "A",
    powerValue: 0.6,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 06:43",
    currentValue: 1.7,
    currentUnit: "A",
    powerValue: 0.36,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 06:43",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 06:44",
    currentValue: 1.8,
    currentUnit: "A",
    powerValue: 0.38,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 06:44",
    currentValue: 2.9,
    currentUnit: "A",
    powerValue: 0.62,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 06:44",
    currentValue: 1,
    currentUnit: "A",
    powerValue: 0.21,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 06:44",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 06:45",
    currentValue: 1.7,
    currentUnit: "A",
    powerValue: 0.36,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 06:45",
    currentValue: 2.7,
    currentUnit: "A",
    powerValue: 2.26,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 06:45",
    currentValue: 1,
    currentUnit: "A",
    powerValue: 0.21,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 06:45",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 06:46",
    currentValue: 1.7,
    currentUnit: "A",
    powerValue: 0.36,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 06:46",
    currentValue: 2.8,
    currentUnit: "A",
    powerValue: 0.6,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 06:46",
    currentValue: 1.8,
    currentUnit: "A",
    powerValue: 0.38,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 06:46",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 06:47",
    currentValue: 6.8,
    currentUnit: "A",
    powerValue: 1.44,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 06:47",
    currentValue: 7.8,
    currentUnit: "A",
    powerValue: 1.65,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 06:47",
    currentValue: 1,
    currentUnit: "A",
    powerValue: 0.21,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 06:47",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 06:48",
    currentValue: 1.7,
    currentUnit: "A",
    powerValue: 0.36,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 06:48",
    currentValue: 2.8,
    currentUnit: "A",
    powerValue: 0.6,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 06:48",
    currentValue: 1,
    currentUnit: "A",
    powerValue: 0.21,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 06:48",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 06:49",
    currentValue: 1.8,
    currentUnit: "A",
    powerValue: 0.38,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 06:49",
    currentValue: 2.8,
    currentUnit: "A",
    powerValue: 0.6,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 06:49",
    currentValue: 1.9,
    currentUnit: "A",
    powerValue: 0.4,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 06:49",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 06:50",
    currentValue: 1.7,
    currentUnit: "A",
    powerValue: 0.36,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 06:50",
    currentValue: 7.8,
    currentUnit: "A",
    powerValue: 1.65,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 06:50",
    currentValue: 1,
    currentUnit: "A",
    powerValue: 0.21,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 06:50",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 06:51",
    currentValue: 1.8,
    currentUnit: "A",
    powerValue: 0.38,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 06:51",
    currentValue: 2.8,
    currentUnit: "A",
    powerValue: 0.6,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 06:51",
    currentValue: 1,
    currentUnit: "A",
    powerValue: 0.21,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 06:51",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 06:52",
    currentValue: 1.7,
    currentUnit: "A",
    powerValue: 0.36,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 06:52",
    currentValue: 2.8,
    currentUnit: "A",
    powerValue: 0.6,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 06:52",
    currentValue: 1.7,
    currentUnit: "A",
    powerValue: 0.36,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 06:52",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 06:53",
    currentValue: 1.8,
    currentUnit: "A",
    powerValue: 0.38,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 06:53",
    currentValue: 7.8,
    currentUnit: "A",
    powerValue: 1.65,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 06:53",
    currentValue: 1,
    currentUnit: "A",
    powerValue: 0.21,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 06:53",
    currentValue: 26,
    currentUnit: "A",
    powerValue: 5.56,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 06:54",
    currentValue: 6.8,
    currentUnit: "A",
    powerValue: 1.44,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 06:54",
    currentValue: 2.8,
    currentUnit: "A",
    powerValue: 0.6,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 06:54",
    currentValue: 1,
    currentUnit: "A",
    powerValue: 0.21,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 06:54",
    currentValue: 36.9,
    currentUnit: "A",
    powerValue: 7.79,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 06:55",
    currentValue: 1.7,
    currentUnit: "A",
    powerValue: 0.36,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 06:55",
    currentValue: 2.7,
    currentUnit: "A",
    powerValue: 0.58,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 06:55",
    currentValue: 3.3,
    currentUnit: "A",
    powerValue: 0.7,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 06:55",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 06:56",
    currentValue: 6.8,
    currentUnit: "A",
    powerValue: 1.44,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 06:56",
    currentValue: 7.8,
    currentUnit: "A",
    powerValue: 1.65,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 06:56",
    currentValue: 1,
    currentUnit: "A",
    powerValue: 0.21,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 06:56",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 06:57",
    currentValue: 1.7,
    currentUnit: "A",
    powerValue: 0.36,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 06:57",
    currentValue: 2.7,
    currentUnit: "A",
    powerValue: 0.58,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 06:57",
    currentValue: 1,
    currentUnit: "A",
    powerValue: 0.21,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 06:57",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 06:58",
    currentValue: 1.7,
    currentUnit: "A",
    powerValue: 0.36,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 06:58",
    currentValue: 2.8,
    currentUnit: "A",
    powerValue: 0.6,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 06:58",
    currentValue: 1.8,
    currentUnit: "A",
    powerValue: 0.38,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 06:58",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 06:59",
    currentValue: 1.8,
    currentUnit: "A",
    powerValue: 0.38,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 06:59",
    currentValue: 7.8,
    currentUnit: "A",
    powerValue: 1.65,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 06:59",
    currentValue: 1,
    currentUnit: "A",
    powerValue: 0.21,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 06:59",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 07:00",
    currentValue: 1.7,
    currentUnit: "A",
    powerValue: 0.36,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 07:00",
    currentValue: 2.7,
    currentUnit: "A",
    powerValue: 0.58,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 07:00",
    currentValue: 1,
    currentUnit: "A",
    powerValue: 0.21,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 07:00",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 07:01",
    currentValue: 6.8,
    currentUnit: "A",
    powerValue: 1.44,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 07:01",
    currentValue: 2.8,
    currentUnit: "A",
    powerValue: 0.6,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 07:01",
    currentValue: 1,
    currentUnit: "A",
    powerValue: 0.21,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 07:01",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 07:02",
    currentValue: 1.7,
    currentUnit: "A",
    powerValue: 0.36,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 07:02",
    currentValue: 7.8,
    currentUnit: "A",
    powerValue: 1.65,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 07:02",
    currentValue: 1,
    currentUnit: "A",
    powerValue: 0.21,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 07:02",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 07:03",
    currentValue: 1.8,
    currentUnit: "A",
    powerValue: 0.38,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 07:03",
    currentValue: 2.8,
    currentUnit: "A",
    powerValue: 0.6,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 07:03",
    currentValue: 1,
    currentUnit: "A",
    powerValue: 0.21,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 07:03",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 07:04",
    currentValue: 1.7,
    currentUnit: "A",
    powerValue: 0.36,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 07:04",
    currentValue: 2.8,
    currentUnit: "A",
    powerValue: 0.6,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 07:04",
    currentValue: 1,
    currentUnit: "A",
    powerValue: 0.21,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 07:04",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 07:05",
    currentValue: 1.7,
    currentUnit: "A",
    powerValue: 0.36,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 07:05",
    currentValue: 38.7,
    currentUnit: "A",
    powerValue: 8.26,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 07:05",
    currentValue: 5,
    currentUnit: "A",
    powerValue: 1.06,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 07:05",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 07:06",
    currentValue: 1.7,
    currentUnit: "A",
    powerValue: 0.36,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 07:06",
    currentValue: 2.8,
    currentUnit: "A",
    powerValue: 0.6,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 07:06",
    currentValue: 1,
    currentUnit: "A",
    powerValue: 0.21,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 07:06",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 07:07",
    currentValue: 1.8,
    currentUnit: "A",
    powerValue: 0.38,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 07:07",
    currentValue: 2.8,
    currentUnit: "A",
    powerValue: 0.6,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 07:07",
    currentValue: 5,
    currentUnit: "A",
    powerValue: 1.06,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 07:07",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 07:08",
    currentValue: 1.7,
    currentUnit: "A",
    powerValue: 0.36,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 07:08",
    currentValue: 2.7,
    currentUnit: "A",
    powerValue: 0.58,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 07:08",
    currentValue: 1,
    currentUnit: "A",
    powerValue: 0.21,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 07:08",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 07:09",
    currentValue: 1.7,
    currentUnit: "A",
    powerValue: 0.36,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 07:09",
    currentValue: 2.8,
    currentUnit: "A",
    powerValue: 0.6,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 07:09",
    currentValue: 5,
    currentUnit: "A",
    powerValue: 1.06,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 07:09",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 07:10",
    currentValue: 6.8,
    currentUnit: "A",
    powerValue: 1.44,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 07:10",
    currentValue: 2.8,
    currentUnit: "A",
    powerValue: 0.6,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 07:10",
    currentValue: 1,
    currentUnit: "A",
    powerValue: 0.21,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 07:10",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 07:11",
    currentValue: 1.7,
    currentUnit: "A",
    powerValue: 0.36,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 07:11",
    currentValue: 2.7,
    currentUnit: "A",
    powerValue: 0.58,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 07:11",
    currentValue: 5,
    currentUnit: "A",
    powerValue: 1.06,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 07:11",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 07:12",
    currentValue: 1.7,
    currentUnit: "A",
    powerValue: 0.36,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 07:12",
    currentValue: 2.8,
    currentUnit: "A",
    powerValue: 0.6,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 07:12",
    currentValue: 1,
    currentUnit: "A",
    powerValue: 0.21,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 07:12",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 07:13",
    currentValue: 1.7,
    currentUnit: "A",
    powerValue: 0.36,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 07:13",
    currentValue: 2.8,
    currentUnit: "A",
    powerValue: 0.6,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 07:13",
    currentValue: 5,
    currentUnit: "A",
    powerValue: 1.06,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 07:13",
    currentValue: 72.1,
    currentUnit: "A",
    powerValue: 14.94,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 07:14",
    currentValue: 1.7,
    currentUnit: "A",
    powerValue: 0.36,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 07:14",
    currentValue: 2.7,
    currentUnit: "A",
    powerValue: 0.58,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 07:14",
    currentValue: 1,
    currentUnit: "A",
    powerValue: 0.21,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 07:14",
    currentValue: 71.7,
    currentUnit: "A",
    powerValue: 14.86,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 07:15",
    currentValue: 1.7,
    currentUnit: "A",
    powerValue: 0.36,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 07:15",
    currentValue: 2.7,
    currentUnit: "A",
    powerValue: 0.58,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 07:15",
    currentValue: 5,
    currentUnit: "A",
    powerValue: 1.06,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 07:15",
    currentValue: 105.2,
    currentUnit: "A",
    powerValue: 22.02,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 07:16",
    currentValue: 1.7,
    currentUnit: "A",
    powerValue: 0.36,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 07:16",
    currentValue: 2.7,
    currentUnit: "A",
    powerValue: 0.58,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 07:16",
    currentValue: 1,
    currentUnit: "A",
    powerValue: 0.21,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 07:16",
    currentValue: 72.9,
    currentUnit: "A",
    powerValue: 15.09,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 07:17",
    currentValue: 6.8,
    currentUnit: "A",
    powerValue: 1.44,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 07:17",
    currentValue: 2.7,
    currentUnit: "A",
    powerValue: 0.58,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 07:17",
    currentValue: 5,
    currentUnit: "A",
    powerValue: 1.06,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 07:17",
    currentValue: 72.6,
    currentUnit: "A",
    powerValue: 15.03,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 07:18",
    currentValue: 1.7,
    currentUnit: "A",
    powerValue: 0.36,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 07:18",
    currentValue: 2.7,
    currentUnit: "A",
    powerValue: 0.58,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 07:18",
    currentValue: 1,
    currentUnit: "A",
    powerValue: 0.21,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 07:18",
    currentValue: 72.4,
    currentUnit: "A",
    powerValue: 14.99,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 07:19",
    currentValue: 1.8,
    currentUnit: "A",
    powerValue: 0.38,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 07:19",
    currentValue: 2.8,
    currentUnit: "A",
    powerValue: 0.6,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 07:19",
    currentValue: 5,
    currentUnit: "A",
    powerValue: 1.06,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 07:19",
    currentValue: 72.4,
    currentUnit: "A",
    powerValue: 14.97,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 07:20",
    currentValue: 1.7,
    currentUnit: "A",
    powerValue: 0.36,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 07:20",
    currentValue: 2.7,
    currentUnit: "A",
    powerValue: 0.58,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 07:20",
    currentValue: 1,
    currentUnit: "A",
    powerValue: 0.21,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 07:20",
    currentValue: 72.4,
    currentUnit: "A",
    powerValue: 14.99,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 07:21",
    currentValue: 1.7,
    currentUnit: "A",
    powerValue: 0.36,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 07:21",
    currentValue: 2.7,
    currentUnit: "A",
    powerValue: 0.58,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 07:21",
    currentValue: 5,
    currentUnit: "A",
    powerValue: 1.06,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 07:21",
    currentValue: 46.5,
    currentUnit: "A",
    powerValue: 9.72,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 07:22",
    currentValue: 1.7,
    currentUnit: "A",
    powerValue: 0.36,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 07:22",
    currentValue: 2.8,
    currentUnit: "A",
    powerValue: 0.6,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 07:22",
    currentValue: 1,
    currentUnit: "A",
    powerValue: 0.21,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 07:22",
    currentValue: 33.9,
    currentUnit: "A",
    powerValue: 7.13,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 07:23",
    currentValue: 1.7,
    currentUnit: "A",
    powerValue: 0.36,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 07:23",
    currentValue: 2.7,
    currentUnit: "A",
    powerValue: 0.58,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 07:23",
    currentValue: 9.1,
    currentUnit: "A",
    powerValue: 1.93,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 07:23",
    currentValue: 31,
    currentUnit: "A",
    powerValue: 6.53,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 07:24",
    currentValue: 6.8,
    currentUnit: "A",
    powerValue: 1.44,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 07:24",
    currentValue: 2.8,
    currentUnit: "A",
    powerValue: 0.6,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 07:24",
    currentValue: 1,
    currentUnit: "A",
    powerValue: 0.21,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 07:24",
    currentValue: 29.2,
    currentUnit: "A",
    powerValue: 6.16,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 07:25",
    currentValue: 1.7,
    currentUnit: "A",
    powerValue: 0.36,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 07:25",
    currentValue: 2.7,
    currentUnit: "A",
    powerValue: 0.58,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 07:25",
    currentValue: 3,
    currentUnit: "A",
    powerValue: 0.64,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 07:25",
    currentValue: 29.1,
    currentUnit: "A",
    powerValue: 6.14,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 07:26",
    currentValue: 1.8,
    currentUnit: "A",
    powerValue: 0.38,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 07:26",
    currentValue: 2.7,
    currentUnit: "A",
    powerValue: 0.58,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 07:26",
    currentValue: 6.9,
    currentUnit: "A",
    powerValue: 1.46,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 07:26",
    currentValue: 26.6,
    currentUnit: "A",
    powerValue: 5.61,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 07:27",
    currentValue: 1.7,
    currentUnit: "A",
    powerValue: 0.36,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 07:27",
    currentValue: 2.7,
    currentUnit: "A",
    powerValue: 0.58,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 07:27",
    currentValue: 5,
    currentUnit: "A",
    powerValue: 1.06,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 07:27",
    currentValue: 26.6,
    currentUnit: "A",
    powerValue: 5.61,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 07:28",
    currentValue: 1.7,
    currentUnit: "A",
    powerValue: 0.36,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 07:28",
    currentValue: 2.7,
    currentUnit: "A",
    powerValue: 0.58,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 07:28",
    currentValue: 1,
    currentUnit: "A",
    powerValue: 0.21,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 07:28",
    currentValue: 36.8,
    currentUnit: "A",
    powerValue: 7.78,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 07:29",
    currentValue: 1.7,
    currentUnit: "A",
    powerValue: 0.36,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 07:29",
    currentValue: 2.7,
    currentUnit: "A",
    powerValue: 0.58,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 07:29",
    currentValue: 2.4,
    currentUnit: "A",
    powerValue: 0.51,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 07:29",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 07:30",
    currentValue: 1.7,
    currentUnit: "A",
    powerValue: 0.36,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 07:30",
    currentValue: 2.7,
    currentUnit: "A",
    powerValue: 0.58,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 07:30",
    currentValue: 1,
    currentUnit: "A",
    powerValue: 0.21,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 07:30",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 07:31",
    currentValue: 6.8,
    currentUnit: "A",
    powerValue: 1.44,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 07:31",
    currentValue: 2.8,
    currentUnit: "A",
    powerValue: 0.6,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 07:31",
    currentValue: 4.2,
    currentUnit: "A",
    powerValue: 0.89,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 07:31",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 07:32",
    currentValue: 1.7,
    currentUnit: "A",
    powerValue: 0.36,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 07:32",
    currentValue: 48.9,
    currentUnit: "A",
    powerValue: 10.42,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 07:32",
    currentValue: 6.6,
    currentUnit: "A",
    powerValue: 1.4,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 07:32",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 07:33",
    currentValue: 1.8,
    currentUnit: "A",
    powerValue: 0.38,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 07:33",
    currentValue: 2.8,
    currentUnit: "A",
    powerValue: 0.6,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 07:33",
    currentValue: 5,
    currentUnit: "A",
    powerValue: 1.06,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 07:33",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 07:34",
    currentValue: 1.7,
    currentUnit: "A",
    powerValue: 0.36,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 07:34",
    currentValue: 2.8,
    currentUnit: "A",
    powerValue: 0.6,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 07:34",
    currentValue: 1,
    currentUnit: "A",
    powerValue: 0.21,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 07:34",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 07:35",
    currentValue: 1.7,
    currentUnit: "A",
    powerValue: 0.36,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 07:35",
    currentValue: 2.8,
    currentUnit: "A",
    powerValue: 0.6,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 07:35",
    currentValue: 5,
    currentUnit: "A",
    powerValue: 1.06,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 07:35",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 07:36",
    currentValue: 1.7,
    currentUnit: "A",
    powerValue: 0.36,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 07:36",
    currentValue: 2.7,
    currentUnit: "A",
    powerValue: 0.58,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 07:36",
    currentValue: 1,
    currentUnit: "A",
    powerValue: 0.21,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 07:36",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 07:37",
    currentValue: 1.7,
    currentUnit: "A",
    powerValue: 0.36,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 07:37",
    currentValue: 7.8,
    currentUnit: "A",
    powerValue: 1.65,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 07:37",
    currentValue: 5,
    currentUnit: "A",
    powerValue: 1.06,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 07:37",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 07:38",
    currentValue: 6.8,
    currentUnit: "A",
    powerValue: 1.44,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 07:38",
    currentValue: 2.8,
    currentUnit: "A",
    powerValue: 0.6,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 07:38",
    currentValue: 1,
    currentUnit: "A",
    powerValue: 0.21,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 07:38",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 07:39",
    currentValue: 1.7,
    currentUnit: "A",
    powerValue: 0.36,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 07:39",
    currentValue: 2.8,
    currentUnit: "A",
    powerValue: 0.6,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 07:39",
    currentValue: 4.2,
    currentUnit: "A",
    powerValue: 0.89,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 07:39",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 07:40",
    currentValue: 6.8,
    currentUnit: "A",
    powerValue: 1.44,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 07:40",
    currentValue: 7.8,
    currentUnit: "A",
    powerValue: 1.65,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 07:40",
    currentValue: 1,
    currentUnit: "A",
    powerValue: 0.21,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 07:40",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 07:41",
    currentValue: 1.8,
    currentUnit: "A",
    powerValue: 0.38,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 07:41",
    currentValue: 2.8,
    currentUnit: "A",
    powerValue: 0.6,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 07:41",
    currentValue: 6.5,
    currentUnit: "A",
    powerValue: 1.38,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 07:41",
    currentValue: 26,
    currentUnit: "A",
    powerValue: 5.57,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 07:42",
    currentValue: 2.2,
    currentUnit: "A",
    powerValue: 0.47,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 07:42",
    currentValue: 2.8,
    currentUnit: "A",
    powerValue: 0.6,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 07:42",
    currentValue: 1,
    currentUnit: "A",
    powerValue: 0.21,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 07:42",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 07:43",
    currentValue: 1.7,
    currentUnit: "A",
    powerValue: 0.36,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 07:43",
    currentValue: 7.8,
    currentUnit: "A",
    powerValue: 1.66,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 07:43",
    currentValue: 1.9,
    currentUnit: "A",
    powerValue: 0.4,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 07:43",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 07:44",
    currentValue: 1.7,
    currentUnit: "A",
    powerValue: 0.36,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 07:44",
    currentValue: 2.8,
    currentUnit: "A",
    powerValue: 0.6,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 07:44",
    currentValue: 6.8,
    currentUnit: "A",
    powerValue: 1.44,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 07:44",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 07:45",
    currentValue: 1.7,
    currentUnit: "A",
    powerValue: 0.36,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 07:45",
    currentValue: 2.7,
    currentUnit: "A",
    powerValue: 0.58,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 07:45",
    currentValue: 1.8,
    currentUnit: "A",
    powerValue: 0.38,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 07:45",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 07:46",
    currentValue: 1.8,
    currentUnit: "A",
    powerValue: 0.38,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 07:46",
    currentValue: 7.8,
    currentUnit: "A",
    powerValue: 1.65,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 07:46",
    currentValue: 1,
    currentUnit: "A",
    powerValue: 0.21,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 07:46",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 07:47",
    currentValue: 6.8,
    currentUnit: "A",
    powerValue: 1.44,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 07:47",
    currentValue: 2.8,
    currentUnit: "A",
    powerValue: 0.6,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 07:47",
    currentValue: 10.7,
    currentUnit: "A",
    powerValue: 2.27,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 07:47",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 07:48",
    currentValue: 1.7,
    currentUnit: "A",
    powerValue: 0.36,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 07:48",
    currentValue: 2.8,
    currentUnit: "A",
    powerValue: 0.6,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 07:48",
    currentValue: 1,
    currentUnit: "A",
    powerValue: 0.21,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 07:48",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 07:49",
    currentValue: 6.8,
    currentUnit: "A",
    powerValue: 1.44,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 07:49",
    currentValue: 2.7,
    currentUnit: "A",
    powerValue: 0.58,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 07:49",
    currentValue: 1.8,
    currentUnit: "A",
    powerValue: 0.38,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 07:49",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 07:50",
    currentValue: 1.7,
    currentUnit: "A",
    powerValue: 0.36,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 07:50",
    currentValue: 2.8,
    currentUnit: "A",
    powerValue: 0.6,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 07:50",
    currentValue: 6.8,
    currentUnit: "A",
    powerValue: 1.44,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 07:50",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 07:51",
    currentValue: 1.7,
    currentUnit: "A",
    powerValue: 0.36,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 07:51",
    currentValue: 2.8,
    currentUnit: "A",
    powerValue: 0.6,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 07:51",
    currentValue: 1.8,
    currentUnit: "A",
    powerValue: 0.38,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 07:51",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 07:52",
    currentValue: 1.7,
    currentUnit: "A",
    powerValue: 0.36,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 07:52",
    currentValue: 7.8,
    currentUnit: "A",
    powerValue: 1.66,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 07:52",
    currentValue: 1,
    currentUnit: "A",
    powerValue: 0.21,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 07:52",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 07:53",
    currentValue: 1.7,
    currentUnit: "A",
    powerValue: 0.36,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 07:53",
    currentValue: 2.8,
    currentUnit: "A",
    powerValue: 0.6,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 07:53",
    currentValue: 8.5,
    currentUnit: "A",
    powerValue: 1.8,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 07:53",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 07:54",
    currentValue: 6.8,
    currentUnit: "A",
    powerValue: 1.44,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 07:54",
    currentValue: 2.8,
    currentUnit: "A",
    powerValue: 0.6,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 07:54",
    currentValue: 1,
    currentUnit: "A",
    powerValue: 0.21,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 07:54",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 07:55",
    currentValue: 1.7,
    currentUnit: "A",
    powerValue: 0.36,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 07:55",
    currentValue: 7.8,
    currentUnit: "A",
    powerValue: 1.65,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 07:55",
    currentValue: 4.8,
    currentUnit: "A",
    powerValue: 1.02,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 07:55",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 07:56",
    currentValue: 5.3,
    currentUnit: "A",
    powerValue: 1.12,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 07:56",
    currentValue: 2.8,
    currentUnit: "A",
    powerValue: 0.6,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 07:56",
    currentValue: 6.9,
    currentUnit: "A",
    powerValue: 1.46,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 07:56",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 07:57",
    currentValue: 1.7,
    currentUnit: "A",
    powerValue: 0.36,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 07:57",
    currentValue: 2.8,
    currentUnit: "A",
    powerValue: 0.6,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 07:57",
    currentValue: 4.7,
    currentUnit: "A",
    powerValue: 1,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 07:57",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 07:58",
    currentValue: 1.7,
    currentUnit: "A",
    powerValue: 0.36,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 07:58",
    currentValue: 7.8,
    currentUnit: "A",
    powerValue: 1.66,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 07:58",
    currentValue: 1,
    currentUnit: "A",
    powerValue: 0.21,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 07:58",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 07:59",
    currentValue: 1.7,
    currentUnit: "A",
    powerValue: 0.36,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 07:59",
    currentValue: 2.8,
    currentUnit: "A",
    powerValue: 0.6,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 07:59",
    currentValue: 10.9,
    currentUnit: "A",
    powerValue: 2.31,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 07:59",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 08:00",
    currentValue: 84.2,
    currentUnit: "A",
    powerValue: 17.3,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 08:00",
    currentValue: 2.8,
    currentUnit: "A",
    powerValue: 0.6,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 08:00",
    currentValue: 1,
    currentUnit: "A",
    powerValue: 0.21,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 08:00",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 08:01",
    currentValue: 84.2,
    currentUnit: "A",
    powerValue: 17.31,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 08:01",
    currentValue: 2.7,
    currentUnit: "A",
    powerValue: 0.58,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 08:01",
    currentValue: 5,
    currentUnit: "A",
    powerValue: 1.06,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 08:01",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 08:02",
    currentValue: 84.4,
    currentUnit: "A",
    powerValue: 17.33,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 08:02",
    currentValue: 2.8,
    currentUnit: "A",
    powerValue: 0.6,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 08:02",
    currentValue: 6.9,
    currentUnit: "A",
    powerValue: 1.46,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 08:02",
    currentValue: 36.2,
    currentUnit: "A",
    powerValue: 7.65,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 08:03",
    currentValue: 89.5,
    currentUnit: "A",
    powerValue: 18.4,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 08:03",
    currentValue: 2.8,
    currentUnit: "A",
    powerValue: 0.6,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 08:03",
    currentValue: 5,
    currentUnit: "A",
    powerValue: 1.06,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 08:03",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 08:04",
    currentValue: 84.6,
    currentUnit: "A",
    powerValue: 17.38,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 08:04",
    currentValue: 2.7,
    currentUnit: "A",
    powerValue: 0.58,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 08:04",
    currentValue: 1,
    currentUnit: "A",
    powerValue: 0.21,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 08:04",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 08:05",
    currentValue: 62.8,
    currentUnit: "A",
    powerValue: 13.01,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 08:05",
    currentValue: 2.8,
    currentUnit: "A",
    powerValue: 0.6,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 08:05",
    currentValue: 10.9,
    currentUnit: "A",
    powerValue: 2.31,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 08:05",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 08:06",
    currentValue: 84.7,
    currentUnit: "A",
    powerValue: 17.39,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 08:06",
    currentValue: 2.8,
    currentUnit: "A",
    powerValue: 0.6,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 08:06",
    currentValue: 1,
    currentUnit: "A",
    powerValue: 0.21,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 08:06",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 08:07",
    currentValue: 84.9,
    currentUnit: "A",
    powerValue: 17.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 08:07",
    currentValue: 7.8,
    currentUnit: "A",
    powerValue: 1.65,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 08:07",
    currentValue: 4.2,
    currentUnit: "A",
    powerValue: 0.89,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 08:07",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 08:08",
    currentValue: 84.8,
    currentUnit: "A",
    powerValue: 17.41,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 08:08",
    currentValue: 2.7,
    currentUnit: "A",
    powerValue: 0.58,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 08:08",
    currentValue: 6.9,
    currentUnit: "A",
    powerValue: 1.46,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 08:08",
    currentValue: 31.5,
    currentUnit: "A",
    powerValue: 6.76,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 08:09",
    currentValue: 82.2,
    currentUnit: "A",
    powerValue: 16.9,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 08:09",
    currentValue: 2.8,
    currentUnit: "A",
    powerValue: 0.6,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 08:09",
    currentValue: 3,
    currentUnit: "A",
    powerValue: 0.64,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 08:09",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 08:10",
    currentValue: 72.7,
    currentUnit: "A",
    powerValue: 15.06,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 08:10",
    currentValue: 7.8,
    currentUnit: "A",
    powerValue: 1.66,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 08:10",
    currentValue: 1,
    currentUnit: "A",
    powerValue: 0.21,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 08:10",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 08:11",
    currentValue: 58.1,
    currentUnit: "A",
    powerValue: 12.07,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 08:11",
    currentValue: 2.8,
    currentUnit: "A",
    powerValue: 0.6,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 08:11",
    currentValue: 10.9,
    currentUnit: "A",
    powerValue: 2.31,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 08:11",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 08:12",
    currentValue: 48.3,
    currentUnit: "A",
    powerValue: 10.08,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 08:12",
    currentValue: 2.8,
    currentUnit: "A",
    powerValue: 0.6,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 08:12",
    currentValue: 1,
    currentUnit: "A",
    powerValue: 0.21,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 08:12",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 08:13",
    currentValue: 45.9,
    currentUnit: "A",
    powerValue: 9.59,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 08:13",
    currentValue: 7.8,
    currentUnit: "A",
    powerValue: 1.66,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 08:13",
    currentValue: 4.6,
    currentUnit: "A",
    powerValue: 0.97,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 08:13",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 08:14",
    currentValue: 38.8,
    currentUnit: "A",
    powerValue: 8.13,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 08:14",
    currentValue: 2.8,
    currentUnit: "A",
    powerValue: 0.6,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 08:14",
    currentValue: 6.9,
    currentUnit: "A",
    powerValue: 1.46,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 08:14",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 08:15",
    currentValue: 33.3,
    currentUnit: "A",
    powerValue: 6.99,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 08:15",
    currentValue: 2.8,
    currentUnit: "A",
    powerValue: 0.6,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 08:15",
    currentValue: 5,
    currentUnit: "A",
    powerValue: 1.06,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 08:15",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 08:16",
    currentValue: 36.3,
    currentUnit: "A",
    powerValue: 7.62,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 08:16",
    currentValue: 7.8,
    currentUnit: "A",
    powerValue: 1.66,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 08:16",
    currentValue: 1,
    currentUnit: "A",
    powerValue: 0.21,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 08:16",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 08:17",
    currentValue: 36.8,
    currentUnit: "A",
    powerValue: 7.74,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 08:17",
    currentValue: 2.8,
    currentUnit: "A",
    powerValue: 0.6,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 08:17",
    currentValue: 9.4,
    currentUnit: "A",
    powerValue: 1.99,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 08:17",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 08:18",
    currentValue: 26.4,
    currentUnit: "A",
    powerValue: 5.56,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 08:18",
    currentValue: 2.8,
    currentUnit: "A",
    powerValue: 0.6,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 08:18",
    currentValue: 1,
    currentUnit: "A",
    powerValue: 0.21,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 08:18",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 08:19",
    currentValue: 21.9,
    currentUnit: "A",
    powerValue: 4.62,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 08:19",
    currentValue: 2.7,
    currentUnit: "A",
    powerValue: 0.58,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 08:19",
    currentValue: 4.1,
    currentUnit: "A",
    powerValue: 0.87,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 08:19",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 08:20",
    currentValue: 63.1,
    currentUnit: "A",
    powerValue: 13.1,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 08:20",
    currentValue: 2.8,
    currentUnit: "A",
    powerValue: 0.6,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 08:20",
    currentValue: 6.8,
    currentUnit: "A",
    powerValue: 1.44,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 08:20",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 08:21",
    currentValue: 1.7,
    currentUnit: "A",
    powerValue: 0.36,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 08:21",
    currentValue: 2.8,
    currentUnit: "A",
    powerValue: 0.6,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 08:21",
    currentValue: 1.8,
    currentUnit: "A",
    powerValue: 0.38,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 08:21",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 08:22",
    currentValue: 1.7,
    currentUnit: "A",
    powerValue: 0.36,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 08:22",
    currentValue: 2.7,
    currentUnit: "A",
    powerValue: 0.58,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 08:22",
    currentValue: 1,
    currentUnit: "A",
    powerValue: 0.21,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 08:22",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 08:23",
    currentValue: 1.7,
    currentUnit: "A",
    powerValue: 0.36,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 08:23",
    currentValue: 2.8,
    currentUnit: "A",
    powerValue: 0.6,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 08:23",
    currentValue: 7.7,
    currentUnit: "A",
    powerValue: 1.63,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 08:23",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 08:24",
    currentValue: 6.8,
    currentUnit: "A",
    powerValue: 1.44,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 08:24",
    currentValue: 2.8,
    currentUnit: "A",
    powerValue: 0.6,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 08:24",
    currentValue: 1,
    currentUnit: "A",
    powerValue: 0.21,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 08:24",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 08:25",
    currentValue: 1.8,
    currentUnit: "A",
    powerValue: 0.38,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 08:25",
    currentValue: 2.7,
    currentUnit: "A",
    powerValue: 0.58,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 08:25",
    currentValue: 2.2,
    currentUnit: "A",
    powerValue: 0.47,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 08:25",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 08:26",
    currentValue: 1.8,
    currentUnit: "A",
    powerValue: 0.38,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 08:26",
    currentValue: 2.8,
    currentUnit: "A",
    powerValue: 0.6,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 08:26",
    currentValue: 6.8,
    currentUnit: "A",
    powerValue: 1.44,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 08:26",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 08:27",
    currentValue: 1.7,
    currentUnit: "A",
    powerValue: 0.36,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 08:27",
    currentValue: 2.8,
    currentUnit: "A",
    powerValue: 0.6,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 08:27",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.42,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 08:27",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 08:28",
    currentValue: 58.8,
    currentUnit: "A",
    powerValue: 12.51,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 08:28",
    currentValue: 2.7,
    currentUnit: "A",
    powerValue: 0.58,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 08:28",
    currentValue: 13.5,
    currentUnit: "A",
    powerValue: 2.85,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 08:28",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 08:29",
    currentValue: 40.7,
    currentUnit: "A",
    powerValue: 8.68,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 08:29",
    currentValue: 2.8,
    currentUnit: "A",
    powerValue: 0.6,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 08:29",
    currentValue: 6.8,
    currentUnit: "A",
    powerValue: 1.44,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 08:29",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 08:30",
    currentValue: 37,
    currentUnit: "A",
    powerValue: 7.9,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 08:30",
    currentValue: 2.8,
    currentUnit: "A",
    powerValue: 0.6,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 08:30",
    currentValue: 1,
    currentUnit: "A",
    powerValue: 0.21,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 08:30",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 08:31",
    currentValue: 36.5,
    currentUnit: "A",
    powerValue: 7.8,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 08:31",
    currentValue: 2.7,
    currentUnit: "A",
    powerValue: 0.58,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 08:31",
    currentValue: 2.2,
    currentUnit: "A",
    powerValue: 0.47,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 08:31",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 08:32",
    currentValue: 36.1,
    currentUnit: "A",
    powerValue: 7.71,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 08:32",
    currentValue: 2.8,
    currentUnit: "A",
    powerValue: 0.6,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 08:32",
    currentValue: 6.8,
    currentUnit: "A",
    powerValue: 1.44,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 08:32",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 08:33",
    currentValue: 41,
    currentUnit: "A",
    powerValue: 8.75,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 08:33",
    currentValue: 2.8,
    currentUnit: "A",
    powerValue: 0.6,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 08:33",
    currentValue: 1,
    currentUnit: "A",
    powerValue: 0.21,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 08:33",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 08:34",
    currentValue: 34.7,
    currentUnit: "A",
    powerValue: 7.41,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 08:34",
    currentValue: 2.7,
    currentUnit: "A",
    powerValue: 0.58,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 08:34",
    currentValue: 1,
    currentUnit: "A",
    powerValue: 0.21,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 08:34",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 08:35",
    currentValue: 34.5,
    currentUnit: "A",
    powerValue: 7.36,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 08:35",
    currentValue: 2.8,
    currentUnit: "A",
    powerValue: 0.6,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 08:35",
    currentValue: 7.5,
    currentUnit: "A",
    powerValue: 1.59,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 08:35",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 08:36",
    currentValue: 28.9,
    currentUnit: "A",
    powerValue: 6.18,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 08:36",
    currentValue: 2.7,
    currentUnit: "A",
    powerValue: 0.58,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 08:36",
    currentValue: 1,
    currentUnit: "A",
    powerValue: 0.21,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 08:36",
    currentValue: 61,
    currentUnit: "A",
    powerValue: 12.97,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 08:37",
    currentValue: 28.7,
    currentUnit: "A",
    powerValue: 6.15,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 08:37",
    currentValue: 2.7,
    currentUnit: "A",
    powerValue: 0.58,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 08:37",
    currentValue: 1,
    currentUnit: "A",
    powerValue: 0.21,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 08:37",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 08:38",
    currentValue: 28.4,
    currentUnit: "A",
    powerValue: 6.08,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 08:38",
    currentValue: 2.8,
    currentUnit: "A",
    powerValue: 0.6,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 08:38",
    currentValue: 6.8,
    currentUnit: "A",
    powerValue: 1.44,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 08:38",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 08:39",
    currentValue: 27.1,
    currentUnit: "A",
    powerValue: 5.8,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 08:39",
    currentValue: 2.8,
    currentUnit: "A",
    powerValue: 0.6,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 08:39",
    currentValue: 1,
    currentUnit: "A",
    powerValue: 0.21,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 08:39",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 08:40",
    currentValue: 1.7,
    currentUnit: "A",
    powerValue: 0.36,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 08:40",
    currentValue: 2.8,
    currentUnit: "A",
    powerValue: 0.6,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 08:40",
    currentValue: 1,
    currentUnit: "A",
    powerValue: 0.21,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 08:40",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 08:41",
    currentValue: 1.7,
    currentUnit: "A",
    powerValue: 0.36,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 08:41",
    currentValue: 2.8,
    currentUnit: "A",
    powerValue: 0.6,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 08:41",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.42,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 08:41",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 08:42",
    currentValue: 6.8,
    currentUnit: "A",
    powerValue: 1.44,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 08:42",
    currentValue: 2.8,
    currentUnit: "A",
    powerValue: 0.6,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 08:42",
    currentValue: 1,
    currentUnit: "A",
    powerValue: 0.21,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 08:42",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 08:43",
    currentValue: 1.7,
    currentUnit: "A",
    powerValue: 0.36,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 08:43",
    currentValue: 2.8,
    currentUnit: "A",
    powerValue: 0.6,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 08:43",
    currentValue: 1.9,
    currentUnit: "A",
    powerValue: 0.4,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 08:43",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 08:44",
    currentValue: 1.9,
    currentUnit: "A",
    powerValue: 0.41,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 08:44",
    currentValue: 2.8,
    currentUnit: "A",
    powerValue: 0.6,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 08:44",
    currentValue: 1,
    currentUnit: "A",
    powerValue: 0.21,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 08:44",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 08:45",
    currentValue: 1.7,
    currentUnit: "A",
    powerValue: 0.36,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 08:45",
    currentValue: 2.8,
    currentUnit: "A",
    powerValue: 0.6,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 08:45",
    currentValue: 3.3,
    currentUnit: "A",
    powerValue: 0.7,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 08:45",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 08:46",
    currentValue: 1.7,
    currentUnit: "A",
    powerValue: 0.36,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 08:46",
    currentValue: 2.8,
    currentUnit: "A",
    powerValue: 0.6,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 08:46",
    currentValue: 1,
    currentUnit: "A",
    powerValue: 0.21,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 08:46",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 08:47",
    currentValue: 1.7,
    currentUnit: "A",
    powerValue: 0.36,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 08:47",
    currentValue: 2.7,
    currentUnit: "A",
    powerValue: 0.58,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 08:47",
    currentValue: 1.7,
    currentUnit: "A",
    powerValue: 0.36,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 08:47",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 08:48",
    currentValue: 1.7,
    currentUnit: "A",
    powerValue: 0.36,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 08:48",
    currentValue: 7.8,
    currentUnit: "A",
    powerValue: 1.66,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 08:48",
    currentValue: 1,
    currentUnit: "A",
    powerValue: 0.21,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 08:48",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 08:49",
    currentValue: 6.8,
    currentUnit: "A",
    powerValue: 1.44,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 08:49",
    currentValue: 2.8,
    currentUnit: "A",
    powerValue: 0.6,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 08:49",
    currentValue: 1.8,
    currentUnit: "A",
    powerValue: 0.38,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 08:49",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 08:50",
    currentValue: 1.7,
    currentUnit: "A",
    powerValue: 0.36,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 08:50",
    currentValue: 2.7,
    currentUnit: "A",
    powerValue: 0.58,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 08:50",
    currentValue: 1,
    currentUnit: "A",
    powerValue: 0.21,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 08:50",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 08:51",
    currentValue: 1.8,
    currentUnit: "A",
    powerValue: 0.38,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 08:51",
    currentValue: 7.8,
    currentUnit: "A",
    powerValue: 1.66,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 08:51",
    currentValue: 1.8,
    currentUnit: "A",
    powerValue: 0.38,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 08:51",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 08:52",
    currentValue: 1.7,
    currentUnit: "A",
    powerValue: 0.36,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 08:52",
    currentValue: 2.8,
    currentUnit: "A",
    powerValue: 0.6,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 08:52",
    currentValue: 1,
    currentUnit: "A",
    powerValue: 0.21,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 08:52",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 08:53",
    currentValue: 1.7,
    currentUnit: "A",
    powerValue: 0.36,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 08:53",
    currentValue: 2.7,
    currentUnit: "A",
    powerValue: 0.58,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 08:53",
    currentValue: 5,
    currentUnit: "A",
    powerValue: 1.06,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 08:53",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 08:54",
    currentValue: 1.7,
    currentUnit: "A",
    powerValue: 0.36,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 08:54",
    currentValue: 7.8,
    currentUnit: "A",
    powerValue: 1.66,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 08:54",
    currentValue: 1,
    currentUnit: "A",
    powerValue: 0.21,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 08:54",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 08:55",
    currentValue: 1.7,
    currentUnit: "A",
    powerValue: 0.36,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 08:55",
    currentValue: 2.7,
    currentUnit: "A",
    powerValue: 0.58,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 08:55",
    currentValue: 1.8,
    currentUnit: "A",
    powerValue: 0.38,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 08:55",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 08:56",
    currentValue: 6.8,
    currentUnit: "A",
    powerValue: 1.44,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 08:56",
    currentValue: 2.8,
    currentUnit: "A",
    powerValue: 0.6,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 08:56",
    currentValue: 1,
    currentUnit: "A",
    powerValue: 0.21,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 08:56",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 08:57",
    currentValue: 1.7,
    currentUnit: "A",
    powerValue: 0.36,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 08:57",
    currentValue: 7.8,
    currentUnit: "A",
    powerValue: 1.66,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 08:57",
    currentValue: 3,
    currentUnit: "A",
    powerValue: 0.64,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 08:57",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 08:58",
    currentValue: 6.8,
    currentUnit: "A",
    powerValue: 1.44,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 08:58",
    currentValue: 2.8,
    currentUnit: "A",
    powerValue: 0.6,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 08:58",
    currentValue: 1,
    currentUnit: "A",
    powerValue: 0.21,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 08:58",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 08:59",
    currentValue: 1.7,
    currentUnit: "A",
    powerValue: 0.36,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 08:59",
    currentValue: 2.7,
    currentUnit: "A",
    powerValue: 0.58,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 08:59",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.42,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 08:59",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 09:00",
    currentValue: 1.7,
    currentUnit: "A",
    powerValue: 0.36,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 09:00",
    currentValue: 2.7,
    currentUnit: "A",
    powerValue: 0.58,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 09:00",
    currentValue: 1,
    currentUnit: "A",
    powerValue: 0.21,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 09:00",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 09:01",
    currentValue: 1.7,
    currentUnit: "A",
    powerValue: 0.36,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 09:01",
    currentValue: 2.7,
    currentUnit: "A",
    powerValue: 0.58,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 09:01",
    currentValue: 3.7,
    currentUnit: "A",
    powerValue: 0.79,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 09:01",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 09:02",
    currentValue: 1.8,
    currentUnit: "A",
    powerValue: 0.38,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 09:02",
    currentValue: 70,
    currentUnit: "A",
    powerValue: 14.73,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 09:02",
    currentValue: 1,
    currentUnit: "A",
    powerValue: 0.21,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 09:02",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 09:03",
    currentValue: 6.8,
    currentUnit: "A",
    powerValue: 1.44,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 09:03",
    currentValue: 58.8,
    currentUnit: "A",
    powerValue: 12.4,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 09:03",
    currentValue: 1.8,
    currentUnit: "A",
    powerValue: 0.38,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 09:03",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 09:04",
    currentValue: 1.7,
    currentUnit: "A",
    powerValue: 0.36,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 09:04",
    currentValue: 43.8,
    currentUnit: "A",
    powerValue: 9.26,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 09:04",
    currentValue: 1,
    currentUnit: "A",
    powerValue: 0.21,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 09:04",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 09:05",
    currentValue: 6.8,
    currentUnit: "A",
    powerValue: 1.44,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 09:05",
    currentValue: 43.3,
    currentUnit: "A",
    powerValue: 9.15,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 09:05",
    currentValue: 1.8,
    currentUnit: "A",
    powerValue: 0.38,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 09:05",
    currentValue: 25.5,
    currentUnit: "A",
    powerValue: 5.47,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 09:06",
    currentValue: 1.7,
    currentUnit: "A",
    powerValue: 0.36,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 09:06",
    currentValue: 43,
    currentUnit: "A",
    powerValue: 9.09,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 09:06",
    currentValue: 1,
    currentUnit: "A",
    powerValue: 0.21,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 09:06",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 09:07",
    currentValue: 1.7,
    currentUnit: "A",
    powerValue: 0.36,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 09:07",
    currentValue: 2.8,
    currentUnit: "A",
    powerValue: 0.6,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 09:07",
    currentValue: 1.8,
    currentUnit: "A",
    powerValue: 0.38,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 09:07",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 09:08",
    currentValue: 1.7,
    currentUnit: "A",
    powerValue: 0.36,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 09:08",
    currentValue: 2.7,
    currentUnit: "A",
    powerValue: 0.58,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 09:08",
    currentValue: 1,
    currentUnit: "A",
    powerValue: 0.21,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 09:08",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 09:09",
    currentValue: 1.7,
    currentUnit: "A",
    powerValue: 0.36,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 09:09",
    currentValue: 2.7,
    currentUnit: "A",
    powerValue: 0.58,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 09:09",
    currentValue: 5,
    currentUnit: "A",
    powerValue: 1.06,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 09:09",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 09:10",
    currentValue: 1.7,
    currentUnit: "A",
    powerValue: 0.36,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 09:10",
    currentValue: 2.8,
    currentUnit: "A",
    powerValue: 0.6,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 09:10",
    currentValue: 1,
    currentUnit: "A",
    powerValue: 0.21,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 09:10",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 09:11",
    currentValue: 1.7,
    currentUnit: "A",
    powerValue: 0.36,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 09:11",
    currentValue: 2.7,
    currentUnit: "A",
    powerValue: 0.58,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 09:11",
    currentValue: 4.3,
    currentUnit: "A",
    powerValue: 0.91,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 09:11",
    currentValue: 2.2,
    currentUnit: "A",
    powerValue: 0.47,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 09:12",
    currentValue: 6.8,
    currentUnit: "A",
    powerValue: 1.44,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 09:12",
    currentValue: 2.7,
    currentUnit: "A",
    powerValue: 0.58,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 09:12",
    currentValue: 1,
    currentUnit: "A",
    powerValue: 0.21,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 09:12",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 09:13",
    currentValue: 1.7,
    currentUnit: "A",
    powerValue: 0.36,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 09:13",
    currentValue: 2.7,
    currentUnit: "A",
    powerValue: 0.58,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 09:13",
    currentValue: 5,
    currentUnit: "A",
    powerValue: 1.06,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 09:13",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 09:14",
    currentValue: 1.7,
    currentUnit: "A",
    powerValue: 0.36,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 09:14",
    currentValue: 2.7,
    currentUnit: "A",
    powerValue: 0.58,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 09:14",
    currentValue: 1,
    currentUnit: "A",
    powerValue: 0.21,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 09:14",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 09:15",
    currentValue: 1.7,
    currentUnit: "A",
    powerValue: 0.36,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 09:15",
    currentValue: 2.7,
    currentUnit: "A",
    powerValue: 0.58,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 09:15",
    currentValue: 5,
    currentUnit: "A",
    powerValue: 1.06,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 09:15",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 09:16",
    currentValue: 1.7,
    currentUnit: "A",
    powerValue: 0.36,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 09:16",
    currentValue: 2.8,
    currentUnit: "A",
    powerValue: 0.6,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 09:16",
    currentValue: 1,
    currentUnit: "A",
    powerValue: 0.21,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 09:16",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 09:17",
    currentValue: 1.7,
    currentUnit: "A",
    powerValue: 0.36,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 09:17",
    currentValue: 7.8,
    currentUnit: "A",
    powerValue: 1.66,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 09:17",
    currentValue: 5,
    currentUnit: "A",
    powerValue: 1.06,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 09:17",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 09:18",
    currentValue: 1.7,
    currentUnit: "A",
    powerValue: 0.36,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 09:18",
    currentValue: 2.7,
    currentUnit: "A",
    powerValue: 0.58,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 09:18",
    currentValue: 1,
    currentUnit: "A",
    powerValue: 0.21,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 09:18",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 09:19",
    currentValue: 1.7,
    currentUnit: "A",
    powerValue: 0.36,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 09:19",
    currentValue: 2.7,
    currentUnit: "A",
    powerValue: 0.58,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 09:19",
    currentValue: 5.8,
    currentUnit: "A",
    powerValue: 1.23,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 09:19",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 09:20",
    currentValue: 33.2,
    currentUnit: "A",
    powerValue: 7.1,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 09:20",
    currentValue: 7.8,
    currentUnit: "A",
    powerValue: 1.66,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 09:20",
    currentValue: 1,
    currentUnit: "A",
    powerValue: 0.21,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 09:20",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 09:21",
    currentValue: 43.2,
    currentUnit: "A",
    powerValue: 9.22,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 09:21",
    currentValue: 2.7,
    currentUnit: "A",
    powerValue: 0.58,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 09:21",
    currentValue: 5,
    currentUnit: "A",
    powerValue: 1.06,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 09:21",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 09:22",
    currentValue: 30.9,
    currentUnit: "A",
    powerValue: 6.61,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 09:22",
    currentValue: 2.7,
    currentUnit: "A",
    powerValue: 0.58,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 09:22",
    currentValue: 1,
    currentUnit: "A",
    powerValue: 0.21,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 09:22",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 09:23",
    currentValue: 28.8,
    currentUnit: "A",
    powerValue: 6.16,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 09:23",
    currentValue: 7.8,
    currentUnit: "A",
    powerValue: 1.66,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 09:23",
    currentValue: 4.9,
    currentUnit: "A",
    powerValue: 1.04,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 09:23",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 09:24",
    currentValue: 26.7,
    currentUnit: "A",
    powerValue: 5.71,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 09:24",
    currentValue: 2.8,
    currentUnit: "A",
    powerValue: 0.6,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 09:24",
    currentValue: 1,
    currentUnit: "A",
    powerValue: 0.21,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 09:24",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 09:25",
    currentValue: 24.5,
    currentUnit: "A",
    powerValue: 5.25,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 09:25",
    currentValue: 2.7,
    currentUnit: "A",
    powerValue: 0.58,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 09:25",
    currentValue: 48.1,
    currentUnit: "A",
    powerValue: 10.15,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 09:25",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 09:26",
    currentValue: 23.1,
    currentUnit: "A",
    powerValue: 4.95,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 09:26",
    currentValue: 7.8,
    currentUnit: "A",
    powerValue: 1.66,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 09:26",
    currentValue: 1,
    currentUnit: "A",
    powerValue: 0.21,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 09:26",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 09:27",
    currentValue: 22.5,
    currentUnit: "A",
    powerValue: 4.82,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 09:27",
    currentValue: 2.8,
    currentUnit: "A",
    powerValue: 0.6,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 09:27",
    currentValue: 5,
    currentUnit: "A",
    powerValue: 1.06,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 09:27",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 09:28",
    currentValue: 26.6,
    currentUnit: "A",
    powerValue: 5.69,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 09:28",
    currentValue: 50.9,
    currentUnit: "A",
    powerValue: 10.74,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 09:28",
    currentValue: 1.7,
    currentUnit: "A",
    powerValue: 0.36,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 09:28",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 09:29",
    currentValue: 22,
    currentUnit: "A",
    powerValue: 4.71,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 09:29",
    currentValue: 7.8,
    currentUnit: "A",
    powerValue: 1.66,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 09:29",
    currentValue: 5,
    currentUnit: "A",
    powerValue: 1.06,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 09:29",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 09:30",
    currentValue: 21.6,
    currentUnit: "A",
    powerValue: 4.63,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 09:30",
    currentValue: 2.8,
    currentUnit: "A",
    powerValue: 0.6,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 09:30",
    currentValue: 1,
    currentUnit: "A",
    powerValue: 0.21,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 09:30",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 09:31",
    currentValue: 21.1,
    currentUnit: "A",
    powerValue: 4.52,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 09:31",
    currentValue: 2.7,
    currentUnit: "A",
    powerValue: 0.58,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 09:31",
    currentValue: 6.3,
    currentUnit: "A",
    powerValue: 1.34,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 09:31",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 09:32",
    currentValue: 20.1,
    currentUnit: "A",
    powerValue: 4.31,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 09:32",
    currentValue: 2.7,
    currentUnit: "A",
    powerValue: 0.58,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 09:32",
    currentValue: 1,
    currentUnit: "A",
    powerValue: 0.21,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 09:32",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 09:33",
    currentValue: 18.6,
    currentUnit: "A",
    powerValue: 3.99,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 09:33",
    currentValue: 2.7,
    currentUnit: "A",
    powerValue: 0.58,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 09:33",
    currentValue: 5,
    currentUnit: "A",
    powerValue: 1.06,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 09:33",
    currentValue: 26.3,
    currentUnit: "A",
    powerValue: 5.64,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 09:34",
    currentValue: 17.8,
    currentUnit: "A",
    powerValue: 3.81,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 09:34",
    currentValue: 2.7,
    currentUnit: "A",
    powerValue: 0.58,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 09:34",
    currentValue: 1.7,
    currentUnit: "A",
    powerValue: 0.36,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 09:34",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 09:35",
    currentValue: 22.2,
    currentUnit: "A",
    powerValue: 4.74,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 09:35",
    currentValue: 2.7,
    currentUnit: "A",
    powerValue: 0.58,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 09:35",
    currentValue: 4.9,
    currentUnit: "A",
    powerValue: 1.04,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 09:35",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 09:36",
    currentValue: 11.8,
    currentUnit: "A",
    powerValue: 2.53,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 09:36",
    currentValue: 2.7,
    currentUnit: "A",
    powerValue: 0.58,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 09:36",
    currentValue: 1,
    currentUnit: "A",
    powerValue: 0.21,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 09:36",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 09:37",
    currentValue: 16.9,
    currentUnit: "A",
    powerValue: 3.61,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 09:37",
    currentValue: 2.8,
    currentUnit: "A",
    powerValue: 0.6,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 09:37",
    currentValue: 5.7,
    currentUnit: "A",
    powerValue: 1.21,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 09:37",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 09:38",
    currentValue: 11.9,
    currentUnit: "A",
    powerValue: 2.55,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 09:38",
    currentValue: 2.7,
    currentUnit: "A",
    powerValue: 0.58,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 09:38",
    currentValue: 1,
    currentUnit: "A",
    powerValue: 0.21,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 09:38",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 09:39",
    currentValue: 11.8,
    currentUnit: "A",
    powerValue: 2.53,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 09:39",
    currentValue: 2.8,
    currentUnit: "A",
    powerValue: 0.6,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 09:39",
    currentValue: 1,
    currentUnit: "A",
    powerValue: 0.21,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 09:39",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 09:40",
    currentValue: 11.9,
    currentUnit: "A",
    powerValue: 2.55,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 09:40",
    currentValue: 2.7,
    currentUnit: "A",
    powerValue: 0.58,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 09:40",
    currentValue: 1.8,
    currentUnit: "A",
    powerValue: 0.38,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 09:40",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 09:41",
    currentValue: 11.8,
    currentUnit: "A",
    powerValue: 2.53,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 09:41",
    currentValue: 2.8,
    currentUnit: "A",
    powerValue: 0.6,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 09:41",
    currentValue: 1,
    currentUnit: "A",
    powerValue: 0.21,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 09:41",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 09:42",
    currentValue: 11.7,
    currentUnit: "A",
    powerValue: 2.51,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 09:42",
    currentValue: 2.8,
    currentUnit: "A",
    powerValue: 0.6,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 09:42",
    currentValue: 1,
    currentUnit: "A",
    powerValue: 0.21,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 09:42",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 09:43",
    currentValue: 11.7,
    currentUnit: "A",
    powerValue: 2.51,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 09:43",
    currentValue: 2.8,
    currentUnit: "A",
    powerValue: 0.6,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 09:43",
    currentValue: 1.7,
    currentUnit: "A",
    powerValue: 0.36,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 09:43",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 09:44",
    currentValue: 16.8,
    currentUnit: "A",
    powerValue: 3.59,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 09:44",
    currentValue: 2.8,
    currentUnit: "A",
    powerValue: 0.6,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 09:44",
    currentValue: 1,
    currentUnit: "A",
    powerValue: 0.21,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 09:44",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 09:45",
    currentValue: 11.6,
    currentUnit: "A",
    powerValue: 2.49,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 09:45",
    currentValue: 2.8,
    currentUnit: "A",
    powerValue: 0.6,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 09:45",
    currentValue: 1,
    currentUnit: "A",
    powerValue: 0.21,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 09:45",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 09:46",
    currentValue: 11.5,
    currentUnit: "A",
    powerValue: 2.47,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 09:46",
    currentValue: 2.7,
    currentUnit: "A",
    powerValue: 0.58,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 09:46",
    currentValue: 8.5,
    currentUnit: "A",
    powerValue: 1.81,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 09:46",
    currentValue: 36.8,
    currentUnit: "A",
    powerValue: 7.79,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 09:47",
    currentValue: 1.7,
    currentUnit: "A",
    powerValue: 0.36,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 09:47",
    currentValue: 2.7,
    currentUnit: "A",
    powerValue: 0.58,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 09:47",
    currentValue: 1,
    currentUnit: "A",
    powerValue: 0.21,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 09:47",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 09:48",
    currentValue: 1.7,
    currentUnit: "A",
    powerValue: 0.36,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 09:48",
    currentValue: 2.8,
    currentUnit: "A",
    powerValue: 0.6,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 09:48",
    currentValue: 1.7,
    currentUnit: "A",
    powerValue: 0.36,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 09:48",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 09:49",
    currentValue: 1.7,
    currentUnit: "A",
    powerValue: 0.36,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 09:49",
    currentValue: 51.9,
    currentUnit: "A",
    powerValue: 10.96,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 09:49",
    currentValue: 1.7,
    currentUnit: "A",
    powerValue: 0.36,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 09:49",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 09:50",
    currentValue: 1.8,
    currentUnit: "A",
    powerValue: 0.38,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 09:50",
    currentValue: 2.8,
    currentUnit: "A",
    powerValue: 0.6,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 09:50",
    currentValue: 1,
    currentUnit: "A",
    powerValue: 0.21,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 09:50",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 09:51",
    currentValue: 1.7,
    currentUnit: "A",
    powerValue: 0.36,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 09:51",
    currentValue: 2.7,
    currentUnit: "A",
    powerValue: 0.58,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 09:51",
    currentValue: 1,
    currentUnit: "A",
    powerValue: 0.21,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 09:51",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 09:52",
    currentValue: 1.7,
    currentUnit: "A",
    powerValue: 0.36,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 09:52",
    currentValue: 7,
    currentUnit: "A",
    powerValue: 1.49,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 09:52",
    currentValue: 4.6,
    currentUnit: "A",
    powerValue: 0.98,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 09:52",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 09:53",
    currentValue: 6.8,
    currentUnit: "A",
    powerValue: 1.44,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 09:53",
    currentValue: 2.8,
    currentUnit: "A",
    powerValue: 0.6,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 09:53",
    currentValue: 1,
    currentUnit: "A",
    powerValue: 0.21,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 09:53",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 09:54",
    currentValue: 1.7,
    currentUnit: "A",
    powerValue: 0.36,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 09:54",
    currentValue: 2.8,
    currentUnit: "A",
    powerValue: 0.6,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 09:54",
    currentValue: 1,
    currentUnit: "A",
    powerValue: 0.21,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 09:54",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 09:55",
    currentValue: 6.8,
    currentUnit: "A",
    powerValue: 1.44,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 09:55",
    currentValue: 7.8,
    currentUnit: "A",
    powerValue: 1.66,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 09:55",
    currentValue: 1.7,
    currentUnit: "A",
    powerValue: 0.36,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 09:55",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 09:56",
    currentValue: 1.7,
    currentUnit: "A",
    powerValue: 0.36,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 09:56",
    currentValue: 2.8,
    currentUnit: "A",
    powerValue: 0.6,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 09:56",
    currentValue: 1,
    currentUnit: "A",
    powerValue: 0.21,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 09:56",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 09:57",
    currentValue: 1.7,
    currentUnit: "A",
    powerValue: 0.36,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 09:57",
    currentValue: 2.8,
    currentUnit: "A",
    powerValue: 0.6,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 09:57",
    currentValue: 1,
    currentUnit: "A",
    powerValue: 0.21,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 09:57",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 09:58",
    currentValue: 1.7,
    currentUnit: "A",
    powerValue: 0.36,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 09:58",
    currentValue: 7.8,
    currentUnit: "A",
    powerValue: 1.66,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 09:58",
    currentValue: 1.9,
    currentUnit: "A",
    powerValue: 0.4,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 09:58",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 09:59",
    currentValue: 1.7,
    currentUnit: "A",
    powerValue: 0.36,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 09:59",
    currentValue: 2.7,
    currentUnit: "A",
    powerValue: 0.58,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 09:59",
    currentValue: 1,
    currentUnit: "A",
    powerValue: 0.21,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 09:59",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 10:00",
    currentValue: 1.7,
    currentUnit: "A",
    powerValue: 0.36,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 10:00",
    currentValue: 2.8,
    currentUnit: "A",
    powerValue: 0.6,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 10:00",
    currentValue: 1,
    currentUnit: "A",
    powerValue: 0.21,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 10:00",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 10:01",
    currentValue: 1.8,
    currentUnit: "A",
    powerValue: 0.38,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 10:01",
    currentValue: 7.8,
    currentUnit: "A",
    powerValue: 1.66,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 10:01",
    currentValue: 1.7,
    currentUnit: "A",
    powerValue: 0.36,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 10:01",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 10:02",
    currentValue: 6.8,
    currentUnit: "A",
    powerValue: 1.44,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 10:02",
    currentValue: 2.8,
    currentUnit: "A",
    powerValue: 0.6,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 10:02",
    currentValue: 3.6,
    currentUnit: "A",
    powerValue: 0.76,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 10:02",
    currentValue: 25.6,
    currentUnit: "A",
    powerValue: 5.49,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 10:03",
    currentValue: 1.7,
    currentUnit: "A",
    powerValue: 0.36,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 10:03",
    currentValue: 2.8,
    currentUnit: "A",
    powerValue: 0.6,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 10:03",
    currentValue: 1,
    currentUnit: "A",
    powerValue: 0.21,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 10:03",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 10:04",
    currentValue: 1.8,
    currentUnit: "A",
    powerValue: 0.38,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 10:04",
    currentValue: 7.8,
    currentUnit: "A",
    powerValue: 1.66,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 10:04",
    currentValue: 7.6,
    currentUnit: "A",
    powerValue: 1.61,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 10:04",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 10:05",
    currentValue: 1.7,
    currentUnit: "A",
    powerValue: 0.36,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 10:05",
    currentValue: 2.8,
    currentUnit: "A",
    powerValue: 0.6,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 10:05",
    currentValue: 1,
    currentUnit: "A",
    powerValue: 0.21,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 10:05",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 10:06",
    currentValue: 1.8,
    currentUnit: "A",
    powerValue: 0.38,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 10:06",
    currentValue: 2.7,
    currentUnit: "A",
    powerValue: 0.58,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 10:06",
    currentValue: 1,
    currentUnit: "A",
    powerValue: 0.21,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 10:06",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 10:07",
    currentValue: 1.7,
    currentUnit: "A",
    powerValue: 0.36,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 10:07",
    currentValue: 7.8,
    currentUnit: "A",
    powerValue: 1.66,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 10:07",
    currentValue: 6.8,
    currentUnit: "A",
    powerValue: 1.44,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 10:07",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 10:08",
    currentValue: 1.8,
    currentUnit: "A",
    powerValue: 0.38,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 10:08",
    currentValue: 2.7,
    currentUnit: "A",
    powerValue: 0.58,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 10:08",
    currentValue: 3.9,
    currentUnit: "A",
    powerValue: 0.83,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 10:08",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 10:09",
    currentValue: 1.7,
    currentUnit: "A",
    powerValue: 0.36,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 10:09",
    currentValue: 2.8,
    currentUnit: "A",
    powerValue: 0.6,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 10:09",
    currentValue: 1,
    currentUnit: "A",
    powerValue: 0.21,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 10:09",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 10:10",
    currentValue: 1.7,
    currentUnit: "A",
    powerValue: 0.36,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 10:10",
    currentValue: 75.9,
    currentUnit: "A",
    powerValue: 15.98,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 10:10",
    currentValue: 7.5,
    currentUnit: "A",
    powerValue: 1.59,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 10:10",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 10:11",
    currentValue: 6.8,
    currentUnit: "A",
    powerValue: 1.44,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 10:11",
    currentValue: 2.8,
    currentUnit: "A",
    powerValue: 0.6,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 10:11",
    currentValue: 1,
    currentUnit: "A",
    powerValue: 0.21,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 10:11",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 10:12",
    currentValue: 1.7,
    currentUnit: "A",
    powerValue: 0.36,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 10:12",
    currentValue: 2.7,
    currentUnit: "A",
    powerValue: 0.58,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 10:12",
    currentValue: 1.8,
    currentUnit: "A",
    powerValue: 0.38,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 10:12",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 10:13",
    currentValue: 6.8,
    currentUnit: "A",
    powerValue: 1.44,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 10:13",
    currentValue: 7.8,
    currentUnit: "A",
    powerValue: 1.66,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 10:13",
    currentValue: 6.8,
    currentUnit: "A",
    powerValue: 1.44,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 10:13",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 10:14",
    currentValue: 1.7,
    currentUnit: "A",
    powerValue: 0.36,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 10:14",
    currentValue: 2.8,
    currentUnit: "A",
    powerValue: 0.6,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 10:14",
    currentValue: 5,
    currentUnit: "A",
    powerValue: 1.06,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 10:14",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 10:15",
    currentValue: 1.8,
    currentUnit: "A",
    powerValue: 0.38,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 10:15",
    currentValue: 2.8,
    currentUnit: "A",
    powerValue: 0.6,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 10:15",
    currentValue: 1,
    currentUnit: "A",
    powerValue: 0.21,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 10:15",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 10:16",
    currentValue: 1.7,
    currentUnit: "A",
    powerValue: 0.36,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 10:16",
    currentValue: 20.5,
    currentUnit: "A",
    powerValue: 4.38,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 10:16",
    currentValue: 10.8,
    currentUnit: "A",
    powerValue: 2.29,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 10:16",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 10:17",
    currentValue: 1.7,
    currentUnit: "A",
    powerValue: 0.36,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 10:17",
    currentValue: 63.3,
    currentUnit: "A",
    powerValue: 13.49,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 10:17",
    currentValue: 1,
    currentUnit: "A",
    powerValue: 0.21,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 10:17",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 10:18",
    currentValue: 1.7,
    currentUnit: "A",
    powerValue: 0.36,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 10:18",
    currentValue: 2.8,
    currentUnit: "A",
    powerValue: 0.6,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 10:18",
    currentValue: 4.9,
    currentUnit: "A",
    powerValue: 1.04,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 10:18",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 10:19",
    currentValue: 1.8,
    currentUnit: "A",
    powerValue: 0.38,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 10:19",
    currentValue: 2.7,
    currentUnit: "A",
    powerValue: 0.58,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 10:19",
    currentValue: 1,
    currentUnit: "A",
    powerValue: 0.21,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 10:19",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 10:20",
    currentValue: 1.7,
    currentUnit: "A",
    powerValue: 0.36,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 10:20",
    currentValue: 2.7,
    currentUnit: "A",
    powerValue: 0.58,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 10:20",
    currentValue: 4.9,
    currentUnit: "A",
    powerValue: 1.04,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 10:20",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 10:21",
    currentValue: 1.7,
    currentUnit: "A",
    powerValue: 0.36,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 10:21",
    currentValue: 2.8,
    currentUnit: "A",
    powerValue: 0.6,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 10:21",
    currentValue: 1,
    currentUnit: "A",
    powerValue: 0.21,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 10:21",
    currentValue: 39.6,
    currentUnit: "A",
    powerValue: 8.38,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 10:22",
    currentValue: 6.8,
    currentUnit: "A",
    powerValue: 1.44,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 10:22",
    currentValue: 2.7,
    currentUnit: "A",
    powerValue: 0.58,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 10:22",
    currentValue: 4.9,
    currentUnit: "A",
    powerValue: 1.04,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 10:22",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 10:23",
    currentValue: 1.7,
    currentUnit: "A",
    powerValue: 0.36,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 10:23",
    currentValue: 2.8,
    currentUnit: "A",
    powerValue: 0.6,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 10:23",
    currentValue: 1,
    currentUnit: "A",
    powerValue: 0.21,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 10:23",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 10:24",
    currentValue: 1.7,
    currentUnit: "A",
    powerValue: 0.36,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 10:24",
    currentValue: 2.8,
    currentUnit: "A",
    powerValue: 0.6,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 10:24",
    currentValue: 4.9,
    currentUnit: "A",
    powerValue: 1.04,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 10:24",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 10:25",
    currentValue: 1.8,
    currentUnit: "A",
    powerValue: 0.38,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 10:25",
    currentValue: 2.7,
    currentUnit: "A",
    powerValue: 0.58,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 10:25",
    currentValue: 6.9,
    currentUnit: "A",
    powerValue: 1.47,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 10:25",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 10:26",
    currentValue: 1.7,
    currentUnit: "A",
    powerValue: 0.36,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 10:26",
    currentValue: 2.7,
    currentUnit: "A",
    powerValue: 0.58,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 10:26",
    currentValue: 4.9,
    currentUnit: "A",
    powerValue: 1.04,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 10:26",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 10:27",
    currentValue: 1.7,
    currentUnit: "A",
    powerValue: 0.36,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 10:27",
    currentValue: 2.8,
    currentUnit: "A",
    powerValue: 0.6,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 10:27",
    currentValue: 1,
    currentUnit: "A",
    powerValue: 0.21,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 10:27",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 10:28",
    currentValue: 1.8,
    currentUnit: "A",
    powerValue: 0.38,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 10:28",
    currentValue: 2.7,
    currentUnit: "A",
    powerValue: 0.58,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 10:28",
    currentValue: 10.8,
    currentUnit: "A",
    powerValue: 2.29,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 10:28",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 10:29",
    currentValue: 6.8,
    currentUnit: "A",
    powerValue: 1.44,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 10:29",
    currentValue: 2.8,
    currentUnit: "A",
    powerValue: 0.6,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 10:29",
    currentValue: 1,
    currentUnit: "A",
    powerValue: 0.21,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 10:29",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 10:30",
    currentValue: 1.7,
    currentUnit: "A",
    powerValue: 0.36,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 10:30",
    currentValue: 2.8,
    currentUnit: "A",
    powerValue: 0.6,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 10:30",
    currentValue: 4.9,
    currentUnit: "A",
    powerValue: 1.04,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 10:30",
    currentValue: 34.1,
    currentUnit: "A",
    powerValue: 7.29,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 10:31",
    currentValue: 1.8,
    currentUnit: "A",
    powerValue: 0.38,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 10:31",
    currentValue: 2.7,
    currentUnit: "A",
    powerValue: 0.58,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 10:31",
    currentValue: 6.8,
    currentUnit: "A",
    powerValue: 1.44,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 10:31",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 10:32",
    currentValue: 1.7,
    currentUnit: "A",
    powerValue: 0.36,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 10:32",
    currentValue: 37.3,
    currentUnit: "A",
    powerValue: 7.9,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 10:32",
    currentValue: 4.9,
    currentUnit: "A",
    powerValue: 1.04,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 10:32",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 10:33",
    currentValue: 1.7,
    currentUnit: "A",
    powerValue: 0.36,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 10:33",
    currentValue: 2.7,
    currentUnit: "A",
    powerValue: 0.58,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 10:33",
    currentValue: 1,
    currentUnit: "A",
    powerValue: 0.21,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 10:33",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 10:34",
    currentValue: 1.7,
    currentUnit: "A",
    powerValue: 0.36,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 10:34",
    currentValue: 2.8,
    currentUnit: "A",
    powerValue: 0.6,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 10:34",
    currentValue: 4.9,
    currentUnit: "A",
    powerValue: 1.04,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 10:34",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 10:35",
    currentValue: 1.7,
    currentUnit: "A",
    powerValue: 0.36,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 10:35",
    currentValue: 2.8,
    currentUnit: "A",
    powerValue: 0.6,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 10:35",
    currentValue: 1,
    currentUnit: "A",
    powerValue: 0.21,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 10:35",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 10:36",
    currentValue: 6.8,
    currentUnit: "A",
    powerValue: 1.44,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 10:36",
    currentValue: 7.8,
    currentUnit: "A",
    powerValue: 1.66,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 10:36",
    currentValue: 5,
    currentUnit: "A",
    powerValue: 1.06,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 10:36",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 10:37",
    currentValue: 1.7,
    currentUnit: "A",
    powerValue: 0.36,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 10:37",
    currentValue: 2.7,
    currentUnit: "A",
    powerValue: 0.58,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 10:37",
    currentValue: 1,
    currentUnit: "A",
    powerValue: 0.21,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 10:37",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 10:38",
    currentValue: 6.8,
    currentUnit: "A",
    powerValue: 1.44,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 10:38",
    currentValue: 62.7,
    currentUnit: "A",
    powerValue: 13.34,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 10:38",
    currentValue: 1,
    currentUnit: "A",
    powerValue: 0.21,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 10:38",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 10:39",
    currentValue: 1.7,
    currentUnit: "A",
    powerValue: 0.36,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 10:39",
    currentValue: 7.8,
    currentUnit: "A",
    powerValue: 1.66,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 10:39",
    currentValue: 1,
    currentUnit: "A",
    powerValue: 0.21,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 10:39",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 10:40",
    currentValue: 1.8,
    currentUnit: "A",
    powerValue: 0.38,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 10:40",
    currentValue: 2.8,
    currentUnit: "A",
    powerValue: 0.6,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 10:40",
    currentValue: 1,
    currentUnit: "A",
    powerValue: 0.21,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 10:40",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 10:41",
    currentValue: 1.7,
    currentUnit: "A",
    powerValue: 0.36,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 10:41",
    currentValue: 2.7,
    currentUnit: "A",
    powerValue: 0.58,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 10:41",
    currentValue: 1,
    currentUnit: "A",
    powerValue: 0.21,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 10:41",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 10:42",
    currentValue: 1.7,
    currentUnit: "A",
    powerValue: 0.36,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 10:42",
    currentValue: 7.8,
    currentUnit: "A",
    powerValue: 1.65,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 10:42",
    currentValue: 1,
    currentUnit: "A",
    powerValue: 0.21,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 10:42",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 10:43",
    currentValue: 1.7,
    currentUnit: "A",
    powerValue: 0.36,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 10:43",
    currentValue: 2.8,
    currentUnit: "A",
    powerValue: 0.6,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 10:43",
    currentValue: 1,
    currentUnit: "A",
    powerValue: 0.21,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 10:43",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 10:44",
    currentValue: 1.7,
    currentUnit: "A",
    powerValue: 0.36,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 10:44",
    currentValue: 2.8,
    currentUnit: "A",
    powerValue: 0.6,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 10:44",
    currentValue: 1,
    currentUnit: "A",
    powerValue: 0.21,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 10:44",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 10:45",
    currentValue: 1.7,
    currentUnit: "A",
    powerValue: 0.36,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 10:45",
    currentValue: 7.8,
    currentUnit: "A",
    powerValue: 1.66,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 10:45",
    currentValue: 1,
    currentUnit: "A",
    powerValue: 0.21,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 10:45",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 10:46",
    currentValue: 1.7,
    currentUnit: "A",
    powerValue: 0.36,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 10:46",
    currentValue: 2.7,
    currentUnit: "A",
    powerValue: 0.58,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 10:46",
    currentValue: 1,
    currentUnit: "A",
    powerValue: 0.21,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 10:46",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 10:47",
    currentValue: 6.8,
    currentUnit: "A",
    powerValue: 1.44,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 10:47",
    currentValue: 2.8,
    currentUnit: "A",
    powerValue: 0.6,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 10:47",
    currentValue: 1,
    currentUnit: "A",
    powerValue: 0.21,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 10:47",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 10:48",
    currentValue: 1.7,
    currentUnit: "A",
    powerValue: 0.36,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 10:48",
    currentValue: 7.8,
    currentUnit: "A",
    powerValue: 1.66,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 10:48",
    currentValue: 1.9,
    currentUnit: "A",
    powerValue: 0.4,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 10:48",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 10:49",
    currentValue: 1.7,
    currentUnit: "A",
    powerValue: 0.36,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 10:49",
    currentValue: 2.7,
    currentUnit: "A",
    powerValue: 0.58,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 10:49",
    currentValue: 3.1,
    currentUnit: "A",
    powerValue: 0.66,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 10:49",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 10:50",
    currentValue: 1.7,
    currentUnit: "A",
    powerValue: 0.36,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 10:50",
    currentValue: 2.7,
    currentUnit: "A",
    powerValue: 0.58,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 10:50",
    currentValue: 1,
    currentUnit: "A",
    powerValue: 0.21,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 10:50",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 10:51",
    currentValue: 1.7,
    currentUnit: "A",
    powerValue: 0.36,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 10:51",
    currentValue: 7.8,
    currentUnit: "A",
    powerValue: 1.66,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 10:51",
    currentValue: 8.9,
    currentUnit: "A",
    powerValue: 1.89,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 10:51",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 10:52",
    currentValue: 1.7,
    currentUnit: "A",
    powerValue: 0.36,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 10:52",
    currentValue: 2.7,
    currentUnit: "A",
    powerValue: 0.58,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 10:52",
    currentValue: 1,
    currentUnit: "A",
    powerValue: 0.21,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 10:52",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 10:53",
    currentValue: 1.7,
    currentUnit: "A",
    powerValue: 0.36,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 10:53",
    currentValue: 36.7,
    currentUnit: "A",
    powerValue: 7.77,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 10:53",
    currentValue: 5,
    currentUnit: "A",
    powerValue: 1.06,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 10:53",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 10:54",
    currentValue: 6.8,
    currentUnit: "A",
    powerValue: 1.44,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 10:54",
    currentValue: 2.7,
    currentUnit: "A",
    powerValue: 0.58,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 10:54",
    currentValue: 6.8,
    currentUnit: "A",
    powerValue: 1.44,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 10:54",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 10:55",
    currentValue: 1.8,
    currentUnit: "A",
    powerValue: 0.38,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 10:55",
    currentValue: 2.7,
    currentUnit: "A",
    powerValue: 0.58,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 10:55",
    currentValue: 4.9,
    currentUnit: "A",
    powerValue: 1.04,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 10:55",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 10:56",
    currentValue: 1.8,
    currentUnit: "A",
    powerValue: 0.38,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 10:56",
    currentValue: 2.8,
    currentUnit: "A",
    powerValue: 0.6,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 10:56",
    currentValue: 1,
    currentUnit: "A",
    powerValue: 0.21,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 10:56",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 10:57",
    currentValue: 1.8,
    currentUnit: "A",
    powerValue: 0.38,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 10:57",
    currentValue: 2.7,
    currentUnit: "A",
    powerValue: 0.58,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 10:57",
    currentValue: 10.7,
    currentUnit: "A",
    powerValue: 2.27,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 10:57",
    currentValue: 36.6,
    currentUnit: "A",
    powerValue: 7.74,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 10:58",
    currentValue: 1.8,
    currentUnit: "A",
    powerValue: 0.38,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 10:58",
    currentValue: 2.7,
    currentUnit: "A",
    powerValue: 0.58,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 10:58",
    currentValue: 1,
    currentUnit: "A",
    powerValue: 0.21,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 10:58",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 10:59",
    currentValue: 1.7,
    currentUnit: "A",
    powerValue: 0.36,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 10:59",
    currentValue: 71.2,
    currentUnit: "A",
    powerValue: 15.14,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 10:59",
    currentValue: 4.9,
    currentUnit: "A",
    powerValue: 1.04,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 10:59",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 11:00",
    currentValue: 1.7,
    currentUnit: "A",
    powerValue: 0.36,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 11:00",
    currentValue: 2.7,
    currentUnit: "A",
    powerValue: 0.58,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 11:00",
    currentValue: 6.8,
    currentUnit: "A",
    powerValue: 1.44,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 11:00",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 11:01",
    currentValue: 6.8,
    currentUnit: "A",
    powerValue: 1.44,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 11:01",
    currentValue: 2.7,
    currentUnit: "A",
    powerValue: 0.58,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 11:01",
    currentValue: 4.9,
    currentUnit: "A",
    powerValue: 1.04,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 11:01",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 11:02",
    currentValue: 1.7,
    currentUnit: "A",
    powerValue: 0.36,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 11:02",
    currentValue: 2.7,
    currentUnit: "A",
    powerValue: 0.58,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 11:02",
    currentValue: 1,
    currentUnit: "A",
    powerValue: 0.21,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 11:02",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 11:03",
    currentValue: 6.8,
    currentUnit: "A",
    powerValue: 1.44,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 11:03",
    currentValue: 2.7,
    currentUnit: "A",
    powerValue: 0.58,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 11:03",
    currentValue: 10.7,
    currentUnit: "A",
    powerValue: 2.27,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 11:03",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 11:04",
    currentValue: 1.8,
    currentUnit: "A",
    powerValue: 0.38,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 11:04",
    currentValue: 2.7,
    currentUnit: "A",
    powerValue: 0.58,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 11:04",
    currentValue: 1,
    currentUnit: "A",
    powerValue: 0.21,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 11:04",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 11:05",
    currentValue: 1.7,
    currentUnit: "A",
    powerValue: 0.36,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 11:05",
    currentValue: 2.8,
    currentUnit: "A",
    powerValue: 0.6,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 11:05",
    currentValue: 4.9,
    currentUnit: "A",
    powerValue: 1.04,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 11:05",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 11:06",
    currentValue: 1.7,
    currentUnit: "A",
    powerValue: 0.36,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 11:06",
    currentValue: 2.7,
    currentUnit: "A",
    powerValue: 0.58,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 11:06",
    currentValue: 6.8,
    currentUnit: "A",
    powerValue: 1.44,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 11:06",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 11:07",
    currentValue: 1.7,
    currentUnit: "A",
    powerValue: 0.36,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 11:07",
    currentValue: 2.7,
    currentUnit: "A",
    powerValue: 0.58,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 11:07",
    currentValue: 4.9,
    currentUnit: "A",
    powerValue: 1.04,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 11:07",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 11:08",
    currentValue: 1.7,
    currentUnit: "A",
    powerValue: 0.36,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 11:08",
    currentValue: 2.7,
    currentUnit: "A",
    powerValue: 0.58,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 11:08",
    currentValue: 1,
    currentUnit: "A",
    powerValue: 0.21,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 11:08",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 11:09",
    currentValue: 1.7,
    currentUnit: "A",
    powerValue: 0.36,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 11:09",
    currentValue: 2.7,
    currentUnit: "A",
    powerValue: 0.58,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 11:09",
    currentValue: 10.7,
    currentUnit: "A",
    powerValue: 2.27,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 11:09",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 11:10",
    currentValue: 6.8,
    currentUnit: "A",
    powerValue: 1.44,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 11:10",
    currentValue: 2.7,
    currentUnit: "A",
    powerValue: 0.58,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 11:10",
    currentValue: 1,
    currentUnit: "A",
    powerValue: 0.21,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 11:10",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 11:11",
    currentValue: 1.7,
    currentUnit: "A",
    powerValue: 0.36,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 11:11",
    currentValue: 2.8,
    currentUnit: "A",
    powerValue: 0.6,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 11:11",
    currentValue: 1,
    currentUnit: "A",
    powerValue: 0.21,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 11:11",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 11:12",
    currentValue: 6.8,
    currentUnit: "A",
    powerValue: 1.44,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 11:12",
    currentValue: 2.8,
    currentUnit: "A",
    powerValue: 0.6,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 11:12",
    currentValue: 1,
    currentUnit: "A",
    powerValue: 0.21,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 11:12",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 11:13",
    currentValue: 1.7,
    currentUnit: "A",
    powerValue: 0.36,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 11:13",
    currentValue: 2.7,
    currentUnit: "A",
    powerValue: 0.58,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 11:13",
    currentValue: 4.9,
    currentUnit: "A",
    powerValue: 1.04,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 11:13",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 11:14",
    currentValue: 1.7,
    currentUnit: "A",
    powerValue: 0.36,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 11:14",
    currentValue: 41.5,
    currentUnit: "A",
    powerValue: 8.8,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 11:14",
    currentValue: 1,
    currentUnit: "A",
    powerValue: 0.21,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 11:14",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 11:15",
    currentValue: 1.7,
    currentUnit: "A",
    powerValue: 0.36,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 11:15",
    currentValue: 2.7,
    currentUnit: "A",
    powerValue: 0.58,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 11:15",
    currentValue: 8.5,
    currentUnit: "A",
    powerValue: 1.81,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 11:15",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 11:16",
    currentValue: 1.7,
    currentUnit: "A",
    powerValue: 0.36,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 11:16",
    currentValue: 2.7,
    currentUnit: "A",
    powerValue: 0.58,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 11:16",
    currentValue: 1,
    currentUnit: "A",
    powerValue: 0.21,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 11:16",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 11:17",
    currentValue: 1.7,
    currentUnit: "A",
    powerValue: 0.36,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 11:17",
    currentValue: 7.8,
    currentUnit: "A",
    powerValue: 1.66,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 11:17",
    currentValue: 44,
    currentUnit: "A",
    powerValue: 9.27,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 11:17",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 11:18",
    currentValue: 1.7,
    currentUnit: "A",
    powerValue: 0.36,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 11:18",
    currentValue: 2.8,
    currentUnit: "A",
    powerValue: 0.6,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 11:18",
    currentValue: 3,
    currentUnit: "A",
    powerValue: 0.64,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 11:18",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 11:19",
    currentValue: 6.8,
    currentUnit: "A",
    powerValue: 1.44,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 11:19",
    currentValue: 2.8,
    currentUnit: "A",
    powerValue: 0.6,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 11:19",
    currentValue: 1,
    currentUnit: "A",
    powerValue: 0.21,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 11:19",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 11:20",
    currentValue: 1.7,
    currentUnit: "A",
    powerValue: 0.36,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 11:20",
    currentValue: 74,
    currentUnit: "A",
    powerValue: 15.74,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 11:20",
    currentValue: 1,
    currentUnit: "A",
    powerValue: 0.21,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 11:20",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 11:21",
    currentValue: 6.8,
    currentUnit: "A",
    powerValue: 1.44,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 11:21",
    currentValue: 2.8,
    currentUnit: "A",
    powerValue: 0.6,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 11:21",
    currentValue: 1,
    currentUnit: "A",
    powerValue: 0.21,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 11:21",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 11:22",
    currentValue: 1.7,
    currentUnit: "A",
    powerValue: 0.36,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 11:22",
    currentValue: 2.7,
    currentUnit: "A",
    powerValue: 0.58,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 11:22",
    currentValue: 1,
    currentUnit: "A",
    powerValue: 0.21,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 11:22",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 11:23",
    currentValue: 1.7,
    currentUnit: "A",
    powerValue: 0.36,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 11:23",
    currentValue: 7.8,
    currentUnit: "A",
    powerValue: 1.66,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 11:23",
    currentValue: 1,
    currentUnit: "A",
    powerValue: 0.21,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 11:23",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 11:24",
    currentValue: 1.7,
    currentUnit: "A",
    powerValue: 0.36,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 11:24",
    currentValue: 2.7,
    currentUnit: "A",
    powerValue: 0.58,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 11:24",
    currentValue: 1,
    currentUnit: "A",
    powerValue: 0.21,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 11:24",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 11:25",
    currentValue: 1.7,
    currentUnit: "A",
    powerValue: 0.36,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 11:25",
    currentValue: 2.8,
    currentUnit: "A",
    powerValue: 0.6,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 11:25",
    currentValue: 1,
    currentUnit: "A",
    powerValue: 0.21,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 11:25",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 11:26",
    currentValue: 1.7,
    currentUnit: "A",
    powerValue: 0.36,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 11:26",
    currentValue: 2.8,
    currentUnit: "A",
    powerValue: 0.6,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 11:26",
    currentValue: 1,
    currentUnit: "A",
    powerValue: 0.21,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 11:26",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 11:27",
    currentValue: 1.7,
    currentUnit: "A",
    powerValue: 0.36,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 11:27",
    currentValue: 2.7,
    currentUnit: "A",
    powerValue: 0.58,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 11:27",
    currentValue: 1,
    currentUnit: "A",
    powerValue: 0.21,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 11:27",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 11:28",
    currentValue: 6.8,
    currentUnit: "A",
    powerValue: 1.44,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 11:28",
    currentValue: 2.7,
    currentUnit: "A",
    powerValue: 0.58,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 11:28",
    currentValue: 1,
    currentUnit: "A",
    powerValue: 0.21,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 11:28",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 11:29",
    currentValue: 1.7,
    currentUnit: "A",
    powerValue: 0.36,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 11:29",
    currentValue: 2.7,
    currentUnit: "A",
    powerValue: 0.58,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 11:29",
    currentValue: 1,
    currentUnit: "A",
    powerValue: 0.21,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 11:29",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 11:30",
    currentValue: 6.8,
    currentUnit: "A",
    powerValue: 1.44,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 11:30",
    currentValue: 2.8,
    currentUnit: "A",
    powerValue: 0.6,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 11:30",
    currentValue: 1,
    currentUnit: "A",
    powerValue: 0.21,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 11:30",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 11:31",
    currentValue: 1.7,
    currentUnit: "A",
    powerValue: 0.36,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 11:31",
    currentValue: 2.8,
    currentUnit: "A",
    powerValue: 0.6,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 11:31",
    currentValue: 1,
    currentUnit: "A",
    powerValue: 0.21,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 11:31",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 11:32",
    currentValue: 1.7,
    currentUnit: "A",
    powerValue: 0.36,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 11:32",
    currentValue: 2.7,
    currentUnit: "A",
    powerValue: 0.58,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 11:32",
    currentValue: 4.8,
    currentUnit: "A",
    powerValue: 1.02,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 11:32",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 11:33",
    currentValue: 1.7,
    currentUnit: "A",
    powerValue: 0.36,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 11:33",
    currentValue: 2.8,
    currentUnit: "A",
    powerValue: 0.6,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 11:33",
    currentValue: 1,
    currentUnit: "A",
    powerValue: 0.21,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 11:33",
    currentValue: 38.5,
    currentUnit: "A",
    powerValue: 8.16,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 11:34",
    currentValue: 1.7,
    currentUnit: "A",
    powerValue: 0.36,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 11:34",
    currentValue: 2.8,
    currentUnit: "A",
    powerValue: 0.6,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 11:34",
    currentValue: 4.9,
    currentUnit: "A",
    powerValue: 1.04,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 11:34",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 11:35",
    currentValue: 1.7,
    currentUnit: "A",
    powerValue: 0.36,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 11:35",
    currentValue: 2.7,
    currentUnit: "A",
    powerValue: 0.58,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 11:35",
    currentValue: 1,
    currentUnit: "A",
    powerValue: 0.21,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 11:35",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 11:36",
    currentValue: 1.7,
    currentUnit: "A",
    powerValue: 0.36,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 11:36",
    currentValue: 2.8,
    currentUnit: "A",
    powerValue: 0.6,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 11:36",
    currentValue: 4.9,
    currentUnit: "A",
    powerValue: 1.04,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 11:36",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 11:37",
    currentValue: 1.7,
    currentUnit: "A",
    powerValue: 0.36,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 11:37",
    currentValue: 2.7,
    currentUnit: "A",
    powerValue: 0.58,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 11:37",
    currentValue: 1,
    currentUnit: "A",
    powerValue: 0.21,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 11:37",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 11:38",
    currentValue: 1.7,
    currentUnit: "A",
    powerValue: 0.36,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 11:38",
    currentValue: 2.7,
    currentUnit: "A",
    powerValue: 0.58,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 11:38",
    currentValue: 4.9,
    currentUnit: "A",
    powerValue: 1.04,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 11:38",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 11:39",
    currentValue: 6.8,
    currentUnit: "A",
    powerValue: 1.44,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 11:39",
    currentValue: 2.8,
    currentUnit: "A",
    powerValue: 0.6,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 11:39",
    currentValue: 1,
    currentUnit: "A",
    powerValue: 0.21,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 11:39",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 11:40",
    currentValue: 1.7,
    currentUnit: "A",
    powerValue: 0.36,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 11:40",
    currentValue: 2.7,
    currentUnit: "A",
    powerValue: 0.58,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 11:40",
    currentValue: 4.9,
    currentUnit: "A",
    powerValue: 1.04,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 11:40",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 11:41",
    currentValue: 1.7,
    currentUnit: "A",
    powerValue: 0.36,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 11:41",
    currentValue: 2.8,
    currentUnit: "A",
    powerValue: 0.6,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 11:41",
    currentValue: 1.9,
    currentUnit: "A",
    powerValue: 0.4,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 11:41",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 11:42",
    currentValue: 1.7,
    currentUnit: "A",
    powerValue: 0.36,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 11:42",
    currentValue: 39.5,
    currentUnit: "A",
    powerValue: 8.44,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 11:42",
    currentValue: 4.9,
    currentUnit: "A",
    powerValue: 1.04,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 11:42",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 11:43",
    currentValue: 1.7,
    currentUnit: "A",
    powerValue: 0.36,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 11:43",
    currentValue: 7.8,
    currentUnit: "A",
    powerValue: 1.66,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 11:43",
    currentValue: 1,
    currentUnit: "A",
    powerValue: 0.21,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 11:43",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 11:44",
    currentValue: 1.7,
    currentUnit: "A",
    powerValue: 0.36,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 11:44",
    currentValue: 2.8,
    currentUnit: "A",
    powerValue: 0.6,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 11:44",
    currentValue: 5.8,
    currentUnit: "A",
    powerValue: 1.23,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 11:44",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 11:45",
    currentValue: 1.7,
    currentUnit: "A",
    powerValue: 0.36,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 11:45",
    currentValue: 2.7,
    currentUnit: "A",
    powerValue: 0.58,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 11:45",
    currentValue: 1,
    currentUnit: "A",
    powerValue: 0.21,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 11:45",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 11:46",
    currentValue: 6.8,
    currentUnit: "A",
    powerValue: 1.44,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 11:46",
    currentValue: 7.8,
    currentUnit: "A",
    powerValue: 1.66,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 11:46",
    currentValue: 4,
    currentUnit: "A",
    powerValue: 0.85,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 11:46",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 11:47",
    currentValue: 1.7,
    currentUnit: "A",
    powerValue: 0.36,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 11:47",
    currentValue: 2.7,
    currentUnit: "A",
    powerValue: 0.58,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 11:47",
    currentValue: 6.8,
    currentUnit: "A",
    powerValue: 1.44,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 11:47",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 11:48",
    currentValue: 6.8,
    currentUnit: "A",
    powerValue: 1.44,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 11:48",
    currentValue: 2.8,
    currentUnit: "A",
    powerValue: 0.6,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 11:48",
    currentValue: 1,
    currentUnit: "A",
    powerValue: 0.21,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 11:48",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 11:49",
    currentValue: 1.7,
    currentUnit: "A",
    powerValue: 0.36,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 11:49",
    currentValue: 7.8,
    currentUnit: "A",
    powerValue: 1.66,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 11:49",
    currentValue: 1,
    currentUnit: "A",
    powerValue: 0.21,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 11:49",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 11:50",
    currentValue: 1.8,
    currentUnit: "A",
    powerValue: 0.38,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 11:50",
    currentValue: 2.7,
    currentUnit: "A",
    powerValue: 0.58,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 11:50",
    currentValue: 6.8,
    currentUnit: "A",
    powerValue: 1.44,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 11:50",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 11:51",
    currentValue: 1.8,
    currentUnit: "A",
    powerValue: 0.38,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 11:51",
    currentValue: 2.8,
    currentUnit: "A",
    powerValue: 0.6,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 11:51",
    currentValue: 1,
    currentUnit: "A",
    powerValue: 0.21,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 11:51",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 11:52",
    currentValue: 1.8,
    currentUnit: "A",
    powerValue: 0.38,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 11:52",
    currentValue: 2.7,
    currentUnit: "A",
    powerValue: 0.58,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 11:52",
    currentValue: 1,
    currentUnit: "A",
    powerValue: 0.21,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 11:52",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 11:53",
    currentValue: 1.7,
    currentUnit: "A",
    powerValue: 0.36,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 11:53",
    currentValue: 2.8,
    currentUnit: "A",
    powerValue: 0.6,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 11:53",
    currentValue: 6.8,
    currentUnit: "A",
    powerValue: 1.44,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 11:53",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 11:54",
    currentValue: 1.8,
    currentUnit: "A",
    powerValue: 0.38,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 11:54",
    currentValue: 2.8,
    currentUnit: "A",
    powerValue: 0.6,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 11:54",
    currentValue: 1,
    currentUnit: "A",
    powerValue: 0.21,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 11:54",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 11:55",
    currentValue: 6.8,
    currentUnit: "A",
    powerValue: 1.44,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 11:55",
    currentValue: 2.7,
    currentUnit: "A",
    powerValue: 0.58,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 11:55",
    currentValue: 3.8,
    currentUnit: "A",
    powerValue: 0.81,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 11:55",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 11:56",
    currentValue: 1.7,
    currentUnit: "A",
    powerValue: 0.36,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 11:56",
    currentValue: 2.7,
    currentUnit: "A",
    powerValue: 0.58,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 11:56",
    currentValue: 1,
    currentUnit: "A",
    powerValue: 0.21,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 11:56",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 11:57",
    currentValue: 6.8,
    currentUnit: "A",
    powerValue: 1.44,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 11:57",
    currentValue: 2.8,
    currentUnit: "A",
    powerValue: 0.6,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 11:57",
    currentValue: 1.8,
    currentUnit: "A",
    powerValue: 0.38,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 11:57",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 11:58",
    currentValue: 1.8,
    currentUnit: "A",
    powerValue: 0.38,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 11:58",
    currentValue: 2.7,
    currentUnit: "A",
    powerValue: 0.58,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 11:58",
    currentValue: 1,
    currentUnit: "A",
    powerValue: 0.21,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 11:58",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 11:59",
    currentValue: 1.7,
    currentUnit: "A",
    powerValue: 0.36,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 11:59",
    currentValue: 2.7,
    currentUnit: "A",
    powerValue: 0.58,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 11:59",
    currentValue: 5,
    currentUnit: "A",
    powerValue: 1.06,
    powerUnit: "kW",
  },
  {
    time: "01 Jan, 11:59",
    currentValue: 2,
    currentUnit: "A",
    powerValue: 0.43,
    powerUnit: "kW",
  },
];
