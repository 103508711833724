import React from "react";
import { useHistory } from "react-router-dom";
import { titleLabels } from "../../../constants/TitleLabels";
import ReviewAndConfirmForm from "../ReviewAndConfirmForm";
import PageHeader from "../../../components/PageHeader";
import PageMainContent from "../../../components/PageMainContent";
import OpsBreadcrumb from "../../../components/NewBreadcrumbs";
import DeleteDepotReviewForm from "../DeleteDepotReviewForm";

const AllBreadcrumbsLinks = [
  {
    link: "/requests",
    title: "Requests",
  },
];

const ReviewConfirm = (props) => {
  const history = useHistory();
  if (!history.location.state) {
    history.push("/requests");
    return null;
  }

  return (
    <>
      <OpsBreadcrumb
        AllBreadcrumbsLinks={AllBreadcrumbsLinks}
        title="Review and Confirm"
      />
      <PageHeader title={titleLabels.REVIEW_AND_CONFIRM} />
      <PageMainContent>
        {history?.location?.state?.data?.status === "delete" ? (
          <DeleteDepotReviewForm recordForEdit={history.location.state.data} />
        ) : (
          <ReviewAndConfirmForm recordForEdit={history.location.state.data} />
        )}
      </PageMainContent>
    </>
  );
};

export default ReviewConfirm;
