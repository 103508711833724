import React, { useEffect, useMemo, useState } from "react";
import { useHistory } from "react-router-dom";
import Loader from "../../../components/Loader";
import Popup from "../../../components/Popup";
import useTable from "../../../components/UseTable";
import { routePaths } from "../../../constants/RoutePaths";
import {
  apiProvider,
  indexedEndPoints,
} from "../../../services/api/utilities/provider";

const headers = [
  { id: "providerName", label: "Provider Name" },
  { id: "plan", label: "Plan Name" },
  {
    id: "expiryDate",
    label: "Expiry Date",
    render: (row, col) =>
      row[col] ? row[col]?.split(" ")?.slice(0, 4)?.join(" ") : "",
  },
  {
    id: "validity",
    label: "Validity",
    render: (row, col) => (
      <div
        style={{
          color: row[col]?.toLowerCase()?.includes("expired") ? "red" : "green",
        }}
      >
        {row[col]}
      </div>
    ),
  },
];

export default function UtilityPlanExpiryStatus() {
  const { UTILITY_PLAN_EXPIRY_STATUS } = indexedEndPoints;
  const filterFn = useMemo(() => ({ fn: (items) => items }), []);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [openPopup, setOpenPopup] = useState({
    isOpen: true,
    title: "Utility Plans Expiry Status",
    child: "",
    item: {},
  });
  const history = useHistory();

  const onTableRowClick = (row) => {
    history.push(
      `${routePaths.UTILITY_PROVIDER_PLANS}?providerId=${row?.providerMetaId}&providerName=${row?.providerName}&planId=${row?.planMetaId}`
    );
  };

  useEffect(() => {
    const fetchExpiryStatus = async () => {
      setLoading(true);
      const response = await apiProvider.getAll(UTILITY_PLAN_EXPIRY_STATUS);
      setLoading(false);
      setData(response?.data);
    };
    fetchExpiryStatus();
  }, []);

  const {
    tableContainer: TableContainer,
    tableHead: TableHead,
    tableBody: TableBody,
  } = useTable(data, headers, filterFn, false, true, null, onTableRowClick);

  return (
    <>
      {data?.length > 0 && (
        <Popup openPopup={openPopup} setOpenPopup={setOpenPopup} maxWidth="xl">
          <Loader isLoading={loading} />
          <TableContainer>
            <TableHead />
            {TableBody}
          </TableContainer>
        </Popup>
      )}
    </>
  );
}
