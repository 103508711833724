import React, { useCallback, useEffect, useMemo, useState } from "react";
import Loader from "../../../components/Loader";
import OpsBreadcrumb from "../../../components/NewBreadcrumbs";
import PageHeader from "../../../components/PageHeader";
import useTable from "../../../components/UseTable";
import {
  apiProvider,
  indexedEndPoints,
} from "../../../services/api/utilities/provider";
import SearchIcon from "@mui/icons-material/Search";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import { Grid, InputAdornment, Toolbar, Tooltip, Zoom } from "@mui/material";
import { makeStyles } from "@mui/styles";
import Controls from "../../../components/controls/Controls";
import PageMainContent from "../../../components/PageMainContent";
import { Link, useHistory } from "react-router-dom";
import { routePaths } from "../../../constants/RoutePaths";
import { PrivilegedComponent } from "../../../utils/PrivilegedComponent";
import Popup from "../../../components/Popup";
import ToastMessage from "../../../components/ToastMessage";
import AddEditManufacturer from "./AddEditManufacturers";
import EditOutlined from "@mui/icons-material/EditOutlined";
import DeleteIcon from "@mui/icons-material/Delete";
import ConfirmDialog from "../../../components/ConfirmDialog";
import { useSelector } from "react-redux";
const allBreadcrumbsLinks = [
  {
    link: routePaths.DATA_CATALOG,
    title: "Data Catalog",
  },
];

const useStyles = makeStyles(() => ({
  "@global": {
    "*::-webkit-scrollbar": {
      width: "0.4em",
    },
    "*::-webkit-scrollbar-track": {
      "-webkit-box-shadow": "inset 0 0 6px rgba(0,0,0,0.00)",
    },
    "*::-webkit-scrollbar-thumb": {
      backgroundColor: "rgba(0,0,0,.1)",
      outline: "0px solid slategrey",
    },
  },
}));

const VehiclesIndex = () => {
  const [loading, setLoading] = useState(false);
  const [filterFn, setFilterFn] = useState({ fn: (items) => items });
  const [refreshVehicles, setRefreshVehicles] = useState(true);
  const [data, setData] = useState([]);
  const [actionItemslength, setActionItemslength] = useState(0);
  const componentPrivileges = useSelector(
    (state) => state?.user?.componentPrivilege?.dataCatalogVehicle
  );
  const [confirmDialog, setConfirmDialog] = useState({
    isOpen: false,
    type: "primary",
  });
  const [openPopup, setOpenPopup] = useState({
    isOpen: false,
    title: "",
    child: "",
    item: {},
  });
  const [toast, setToast] = useState("");
  const history = useHistory();

  const classes = useStyles();

  const headCells = useMemo(() => {
    let columns = [
      {
        id: "manufacturer",
        label: "Manufacturer",
        render: (row, col) => (
          <Link
            to={`${routePaths.VEHICLES}?manufacturer=${encodeURIComponent(
              row.manufacturer
            )}`}
          >
            {row[col]}
          </Link>
        ),
      },
      { id: "count", label: "Vehicles Count" },
    ];
    if (actionItemslength > 0)
      columns.push({ disableSorting: true, id: "action", label: "Action" });
    return columns;
  }, [actionItemslength]);

  const { GET_VEHICLES, ADD_VEHICLE_MANUFACTURER } = indexedEndPoints;
  const handleDeleteVehicle = (row) => {
    setConfirmDialog({
      ...confirmDialog,
      isOpen: true,
      title: "Are you sure ?",
      subTitle: "You want to Delete this Manufacturer",
      type: "secondary",
      onConfirm: () => deleteVehicle(row.manufacturer),
    });
  };
  const deleteVehicle = useCallback(async (manufacturer) => {
    setLoading(true);
    const response = await apiProvider.del(
      `${ADD_VEHICLE_MANUFACTURER}/${manufacturer}`
    );
    setLoading(false);
    if (response.statusCode >= 200 && response.statusCode <= 299) {
      setToast({
        isOpen: true,
        message: "Manufacturer deleted successfully",
        type: "success",
      });
      setConfirmDialog({ ...confirmDialog, isOpen: false });
      setRefreshVehicles(true);
    } else {
      setToast({
        isOpen: true,
        message: "Manufacturer deletion failed",
        type: "error",
      });
    }
  }, []);

  useEffect(() => {
    const fetchVehicles = async () => {
      setLoading(true);
      const response = await apiProvider.getAll(GET_VEHICLES);
      setLoading(false);
      setData(response?.data);
      setRefreshVehicles(false);
    };
    refreshVehicles && fetchVehicles();
  }, [refreshVehicles]);

  const handleEditManufacturer = (row) => {
    setOpenPopup({
      isOpen: true,
      title: "Update Manufacturer",
      child: "updateManufacturer",
      item: row,
    });
  };

  const actionItems = useMemo(() => {
    let actions = [];
    if (componentPrivileges?.includes("Update Manufacturer"))
      actions.push({
        type: "Edit",
        label: "Edit",
        testid: "editVehicleManufacturerBtn",
        icon: () => (
          <Tooltip arrow TransitionComponent={Zoom} title="Edit">
            <EditOutlined fontSize="small" />
          </Tooltip>
        ),
        onClick: handleEditManufacturer,
      });
    if (componentPrivileges?.includes("Delete Vehicle"))
      actions.push({
        type: "Delete",
        label: "Delete",
        testid: "deleteVehicleManufacturerBtn",
        icon: () => (
          <Tooltip arrow TransitionComponent={Zoom} title="Delete">
            <DeleteIcon fontSize="small" />
          </Tooltip>
        ),
        onClick: handleDeleteVehicle,
      });
    setActionItemslength(actions?.length);
    return actions?.length > 0 ? actions : null;
  }, [componentPrivileges]);
  const handleSearch = (e) => {
    setFilterFn({
      fn: (items) => {
        if (e.target.value?.trim() === "") return items;
        else
          return items?.filter((row) =>
            Object.values(row)?.some((val) =>
              val
                ?.toString()
                ?.toLowerCase()
                ?.includes(e.target.value?.trim().toLowerCase())
            )
          );
      },
    });
  };

  const {
    tableContainer: TableContainer,
    tableHead: TableHead,
    tableBody: TableBody,
    tablePagination: TablePagination,
  } = useTable(data, headCells, filterFn, false, false, actionItems);

  const ToolbarMemoised = useCallback(({ handleSearch, classes }) => {
    return (
      <Toolbar className="table_toolbar">
        <Controls.Input
          label={"Search"}
          className="searchInput"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
          }}
          onChange={handleSearch}
        />
        <Grid sm item />
        {componentPrivileges?.includes("Add Manufacturer") && (
          <Controls.Button
            text="Add Manufacturer"
            data-testid="addVehicleManufacturerBtn"
            variant="outlined"
            startIcon={<AddCircleOutlineIcon />}
            onClick={() =>
              setOpenPopup({
                isOpen: true,
                title: "Add Manufacturer",
                child: "addManufacturer",
                item: {},
              })
            }
          />
        )}
      </Toolbar>
    );
  }, []);

  return (
    <>
      <OpsBreadcrumb
        AllBreadcrumbsLinks={allBreadcrumbsLinks}
        title="Vehicles Dashboard"
      />
      <PageHeader title="Vehicles" />
      <PageMainContent>
        <ToolbarMemoised handleSearch={handleSearch} classes={classes} />
        <Loader isLoading={loading} />
        <TableContainer>
          <TableHead />
          {TableBody}
        </TableContainer>
        {TablePagination}
      </PageMainContent>
      <Popup openPopup={openPopup} setOpenPopup={setOpenPopup}>
        {
          <AddEditManufacturer
            type={openPopup.child}
            openPopup={openPopup}
            setOpenPopup={setOpenPopup}
            setRefreshVehicles={setRefreshVehicles}
            setToast={setToast}
            manufacturerName={
              openPopup.child === "updateManufacturer"
                ? openPopup.item.manufacturer
                : null
            }
          />
        }
      </Popup>
      {confirmDialog.isOpen && (
        <ConfirmDialog
          confirmDialog={confirmDialog}
          setConfirmDialog={setConfirmDialog}
        />
      )}
      <ToastMessage toast={toast} setToast={setToast} />
    </>
  );
};

export default VehiclesIndex;
