import React from "react";
import apiBilling from "../../services/api/Billing";
import { messages } from "../../constants/Messages";

import { UseForm, Form } from "../../components/UseForm";
import Controls from "../../components/controls/Controls";

import { Grid } from "@mui/material";
import { makeStyles } from "@mui/styles";
import NewBillingPackage from "../request/NewBillingPackage";
import { NewCountryCode } from "../request/Countries";
import { indexedEndPoints } from "../../services/api/utilities/provider";

const useStyles = makeStyles((theme) => ({
  typography: {
    marginTop: theme.spacing(1),
  },
  buttonAlign: {
    marginTop: theme.spacing(1),
    marginLeft: theme.spacing(0.5),
  },
}));

const UpdateBillingPackageForm = (props) => {
  const { UPDATE_BILLING_DEPOTS } = indexedEndPoints;
  const classes = useStyles();
  const [RatePlans, setRatePlans] = React.useState([]);
  const {
    setLoading,
    setToast,
    DrawerOC,
    setDrawerOC,
    AllRatePlans,
    companyInfo,
    packageDetails,
    values,
    setValues,
    getCompanyDepotsInfo,
  } = props;

  React.useEffect(() => {
    setRatePlanValues();
  }, []);

  React.useEffect(() => {
    if (DrawerOC?.data.countryCode) {
      let countryCodeToSearch = DrawerOC?.data?.countryCode;
      const euroCountrys = [
        "POL",
        "AUT",
        "IRL",
        "ESP",
        "LUX",
        "NLD",
        "NOR",
        "DNK",
        "BEL",
        "DEU",
        "FRA",
        "ITA",
      ];
      if (euroCountrys.includes(countryCodeToSearch)) {
        countryCodeToSearch = "GBR";
      }
      if (countryCodeToSearch === undefined) {
        countryCodeToSearch = "USA";
      }
      setRatePlans(
        AllRatePlans.filter((x) => x.countryCode === countryCodeToSearch)
      );
    }
  }, []);

  const constructPayload = (newRatePlanList) => {
    let tempRateplansArray = [];
    newRatePlanList.management.forEach((ele) => {
      tempRateplansArray.push({ sku: ele[0], ratePlanName: ele[1] });
    });
    newRatePlanList.preventativeMaintenance.forEach((ele) => {
      tempRateplansArray.push({ sku: ele[0], ratePlanName: ele[1] });
    });
    newRatePlanList.premiumWarranty.forEach((ele) => {
      tempRateplansArray.push({ sku: ele[0], ratePlanName: ele[1] });
    });
    return {
      companyId: DrawerOC.data.companyId,
      depotId: DrawerOC.data.depotId,
      ratePlan: tempRateplansArray,
    };
  };
  const setRatePlanValues = () => {
    let constructRatePlans = {
      management: [],
      preventativeMaintenance: [],
      premiumWarranty: [],
    };
    let skuBillingTypePlanmap = {};
    packageDetails.forEach((ele) => {
      skuBillingTypePlanmap[ele.sku] = ele.package;
    });
    DrawerOC.data.ratePlan.forEach((ele) => {
      constructRatePlans[skuBillingTypePlanmap[ele.sku]].push([
        ele.sku,
        ele.ratePlanName,
      ]);
    });
    setValues(constructRatePlans);
  };

  const handleSubmit = (type, value) => {
    let newRatePlanList = {
      management: [],
      preventativeMaintenance: [],
      premiumWarranty: [],
    };
    let skuBillingTypePlanmap = {};
    let uniqueListFlag = 1;
    packageDetails.forEach((ele) => {
      skuBillingTypePlanmap[ele.sku] = ele.package;
    });
    if (type === "delete") {
      newRatePlanList = {
        ...newRatePlanList,
        management: values["management"].filter((item) => item[0] !== value),
      };
      newRatePlanList = {
        ...newRatePlanList,
        preventativeMaintenance: values["preventativeMaintenance"].filter(
          (item) => item[0] !== value
        ),
      };
      newRatePlanList = {
        ...newRatePlanList,
        premiumWarranty: values["premiumWarranty"].filter(
          (item) => item[0] !== value
        ),
      };
      setValues(newRatePlanList);
    } else if (type === "add") {
      let addArray = values[skuBillingTypePlanmap[value[0]]];
      const billingType = skuBillingTypePlanmap[value[0]];
      addArray.forEach((ele) => {
        if (ele[0] === value[0]) uniqueListFlag = 0;
      });
      if (uniqueListFlag) {
        addArray.push(value);
        newRatePlanList = { ...values, [billingType]: addArray };
        setValues(newRatePlanList);
      }
    }
    if (uniqueListFlag) {
      const payLoad = constructPayload(newRatePlanList);
      apiBilling
        .updateBillingPackage2(UPDATE_BILLING_DEPOTS, payLoad)
        .then((res) => {
          getCompanyDepotsInfo();
          if (res.statusCode === 201 || res.statusCode === 200) {
            setToast({
              isOpen: true,
              message: "Billing package update successful",
              type: "success",
            });
          } else {
            setToast({
              isOpen: true,
              message: "Billing package update failed",
              type: "error",
            });
          }
        })
        .catch((error) => console.log(error));
    } else {
      setToast({
        isOpen: true,
        message: value[0] + " is already added",
        type: "error",
      });
    }
  };

  return (
    <div>
      <Grid container>
        <NewBillingPackage
          values={values}
          handleSubmit={handleSubmit}
          setValues={setValues}
          AllRatePlans={RatePlans}
          sideDrawerData={DrawerOC.data}
          packageDetails={packageDetails}
        />
      </Grid>
    </div>
  );
};

export default UpdateBillingPackageForm;
