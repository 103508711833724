import "./../billing/MoreInfo.scss";
import {
  Grid,
  MenuItem,
  Select as MuiSelect,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TextField,
  TableHead,
  TableRow,
  FormControl,
  Tooltip,
  Typography,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import React, { memo, useEffect, useState } from "react";
import { getDepotStatusHistory, updateDepotDetails } from "./RequestServices";
import Autocomplete from "@mui/material/Autocomplete";
import Controls from "../../components/controls/Controls";
import { PrivilegedComponent } from "../../utils/PrivilegedComponent";
import { useSelector } from "react-redux";
import { currencies } from "./Countries";
import { dateFormats } from "./DateFormats";
import { format, getTime } from "date-fns";
import { useHistory } from "react-router-dom";
import Select from "../../components/controls/Select";
import { indexedEndPoints } from "../../services/api/utilities/provider";
import { getTimeZones } from "../../services/TimeZoneService";
import { getTimezoneAndZipcode } from "../../utils/PlacesAPI";
import CircularProgress from "@mui/material/CircularProgress";
import { UseForm } from "../../components/UseForm";
import { messages } from "../../constants/Messages";
import "./requests.scss";

const useStyles = makeStyles((theme) => ({
  typography: {
    marginTop: theme.spacing(1),
  },
  buttonAlign: {
    marginTop: theme.spacing(1),
    marginLeft: theme.spacing(0.5),
  },
}));

const CompanyDetails = (props) => {
  const classes = useStyles();
  const history = useHistory();
  const componentPrivilege = useSelector(
    (state) => state.user.componentPrivilege.requests
  );
  const [rows, setRows] = useState([]);
  const { setToast, DrawerOC, getDepotInfo, setDrawerOC, users } = props;
  const [preference, setPreference] = useState({
    currency: null,
    distanceUnit: null,
    dateFormat: null,
  });
  const [isSimulated, setIsSimulated] = useState("");
  const [isHomeChargingDepot, setIsHomeChargingDepot] = useState("");
  const [isRFIDDepot, setIsRFIDDepot] = useState("");
  const [isCircuitEnabled, setIsCircuitEnabled] = useState(false);
  const [isCDR, setIsCDR] = useState("");
  const [isSharedCharging, setIsSharedCharging] = useState(false);
  const [isMinaEnrolledDepot, setIsMinaEnrolledDepot] = useState(false);
  const [depotType, setDepotType] = useState("");

  const [cloudProvider, setCloudProvider] = useState("");
  const [selectedUser, setSelectedUsers] = React.useState(
    DrawerOC.data.depotCSM
      ? DrawerOC.data.depotCSM.map((single) => {
          return (single = users?.find((x) => x.includes(single)));
        })
      : []
  );
  const [additionalInfoLoader, setAdditionalInfoLoader] = React.useState(false);
  const depotTypeList = ["TEST", "CUSTOMER", "PILOT", "INTERNAL", "DEALER"];
  const [isUpdating, setIsUpdating] = useState(false);
  const [isUpdatingCSMusers, setIsUpdatingCSMusers] = useState(false);
  const flagValuesMenu = ["True", "False"];
  const [address, setAddress] = useState("");
  const initialFieldValues = {
    timeZoneId: DrawerOC.data.depotDetails.depotInfo.address.timeZoneId,
    streetAddress:
      DrawerOC?.data?.depotDetails?.depotInfo?.address?.streetAddress,
    city: DrawerOC.data.depotDetails.depotInfo.address.city,
    postalCode: DrawerOC?.data?.depotDetails?.depotInfo?.address?.postalCode,
    state: DrawerOC?.data?.depotDetails?.depotInfo?.address?.state,
    timeZoneOffset:
      DrawerOC?.data?.depotDetails?.depotInfo?.address?.timeZoneOffset,
    latitude: DrawerOC?.data?.depotDetails?.depotInfo?.address?.latitude,
    longitude: DrawerOC?.data?.depotDetails?.depotInfo?.address?.longitude,
  };
  if (DrawerOC?.data?.isSharedChargingDepot) {
    initialFieldValues.publicLocationName =
      DrawerOC?.data?.depotDetails?.depotInfo?.address?.publicLocationName;
  }
  const { UPDATE_BILLING_DEPOTS } = indexedEndPoints;

  const columns = [
    { id: "status", label: "Status" },
    { id: "user", label: "User" },
    { id: "updatedAt", label: "Updated At" },
  ];
  useEffect(() => {
    if (DrawerOC && DrawerOC.data) {
      getDepotStatusHistoryData();
      const {
        preferences: { currency, distanceUnit, dateFormat },
      } = DrawerOC.data;
      setIsHomeChargingDepot(DrawerOC.data.isHomeChargingDepot);
      setIsRFIDDepot(DrawerOC.data.isRFIDDepot);
      setIsMinaEnrolledDepot(DrawerOC.data.isMinaEnrolledDepot);
      setIsSimulated(DrawerOC.data.isSimulated);
      setIsCDR(DrawerOC.data.isCDR);
      setIsSharedCharging(DrawerOC.data.isSharedChargingDepot);
      setIsCircuitEnabled(DrawerOC.data.isCircuitEnabled);
      setDepotType(DrawerOC.data.depotType);
      setPreference({ currency, distanceUnit, dateFormat });
    }
  }, []);

  const handleDepotDetailsChange = (name) => (event) => {
    if (event.target.value === "True") event.target.value = true;
    else if (event.target.value === "False") event.target.value = false;
    if (name === "CDR") setIsCDR(event.target.value);
    if (name === "SharedCharging") setIsSharedCharging(event.target.value);
    else if (name === "isSimulated") setIsSimulated(event.target.value);
    else if (name === "isHomeChargingDepot") {
      if (event.target.value) {
        setIsCircuitEnabled(false);
      }
      setIsHomeChargingDepot(event.target.value);
    } else if (name === "isRFIDDepot") setIsRFIDDepot(event.target.value);
    else if (name === "isMinaEnrolledDepot")
      setIsMinaEnrolledDepot(event.target.value);
    else if (name === "isCircuitEnabled")
      setIsCircuitEnabled(event.target.value);
    else if (event.target.name === "address") setAddress(event.target.value);
    else if (name === "depotType") setDepotType(event.target.value);
    else if (
      event.target.name === "timeZoneId" ||
      event.target.name === "timeZoneOffset" ||
      event.target.name === "latitude" ||
      event.target.name === "longitude" ||
      event.target.name === "city" ||
      event.target.name === "state" ||
      event.target.name === "postalCode" ||
      event.target.name === "streetAddress" ||
      event.target.name === "publicLocationName"
    )
      setValues({ ...values, [event.target.name]: event.target.value });
    else setPreference({ ...preference, [name]: event.target.value });
  };

  const handleSelectedUser = (event, value) => {
    // let temp = value?.map(single =>{
    //   return single.split('-')[0]
    // })

    setSelectedUsers(value);
  };

  React.useEffect(() => {
    if (address && address.place_id) {
      getTimezoneAndZipcode(
        address.place_id,
        setValues,
        setAdditionalInfoLoader
      );
      setValues((prevState) => ({
        ...prevState,
        postalCode: "",
      }));
    }
  }, [address]);
  const TableComponent = memo(({ rows, columns }) => (
    <>
      <TableContainer className={classes.container}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <TableCell
                  key={column.id}
                  align={column.align}
                  style={{ minWidth: column.minWidth }}
                  className={classes.tableHead}
                >
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row) => {
              if (row.update && row.update.status) {
                return (
                  <TableRow hover role="checkbox" tabIndex={-1} key={row.code}>
                    {columns.map((column) => {
                      const value = row[column.id];
                      return (
                        <TableCell key={column.id} align={column.align}>
                          {column.format && typeof value === "number"
                            ? column.format(value)
                            : value}
                        </TableCell>
                      );
                    })}
                  </TableRow>
                );
              }
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  ));

  const getDepotStatusHistoryData = async () => {
    const res = await getDepotStatusHistory(DrawerOC.data.depotId);
    if (res?.statusCode === 200) {
      const data = res?.data;
      setRows(
        data.map((ele) => {
          ele.updatedAt =
            format(
              new Date(ele.updatedAt.slice(0, -3)),
              "dd-MMM-yyyy , h.mm a"
            ) +
            " " +
            "UTC";
          ele.status = ele.update.status;
          ele.user = ele.user;

          return ele;
        })
      );
    }
  };

  const validate = (fieldValues = values) => {
    let temp = { ...errors };
    if ("address" in fieldValues)
      temp.address = fieldValues.address ? "" : messages.FIELD_REQUIRED;
    // Check additional fields only when address is given

    if ("timeZoneId" in fieldValues)
      temp.timeZoneId = fieldValues.timeZoneId
        ? fieldValues.timeZoneId != ""
          ? ""
          : messages.FIELD_REQUIRED
        : messages.FIELD_REQUIRED;
    if ("timeZoneOffset" in fieldValues)
      temp.timeZoneOffset = fieldValues.timeZoneOffset
        ? ""
        : messages.FIELD_REQUIRED;
    if ("latitude" in fieldValues)
      temp.latitude = fieldValues.latitude ? "" : messages.FIELD_REQUIRED;
    if ("longitude" in fieldValues)
      temp.longitude = fieldValues.longitude ? "" : messages.FIELD_REQUIRED;
    if ("city" in fieldValues)
      temp.city = fieldValues.city ? "" : messages.FIELD_REQUIRED;
    if ("country" in fieldValues)
      temp.country = fieldValues.country ? "" : messages.FIELD_REQUIRED;
    if ("state" in fieldValues)
      temp.state = fieldValues.state ? "" : messages.FIELD_REQUIRED;
    if ("postalCode" in fieldValues)
      temp.postalCode = fieldValues.postalCode ? "" : messages.FIELD_REQUIRED;
    if (isSharedCharging && "publicLocationName" in fieldValues)
      temp.publicLocationName = fieldValues.publicLocationName
        ? ""
        : messages.FIELD_REQUIRED;
    if ("streetAddress" in fieldValues)
      temp.streetAddress = fieldValues.streetAddress
        ? ""
        : messages.FIELD_REQUIRED;
    setErrors({ ...temp });
    if (fieldValues === values)
      return Object.values(temp).every((x) => x === "");
    else return false;
  };

  const { values, setValues, errors, setErrors, handleFormInputChange } =
    UseForm(initialFieldValues, true, validate);

  const updatePreferenceDetails = async () => {
    if (validate()) {
      setIsUpdating(true);
      const payload = {
        depotId: DrawerOC.data.depotId,
        preferences: { ...preference },
        depotType: depotType,
        isSimulated: isSimulated,
        isHomeChargingDepot: isHomeChargingDepot,
        isRFIDDepot: isRFIDDepot,
        isMinaEnrolledDepot: isMinaEnrolledDepot,
        isCDR: isCDR,
        isSharedChargingDepot: isSharedCharging,
        isCircuitEnabled: isCircuitEnabled,
        address: {
          address: address.description,
          timeZoneId: values.timeZoneId,
          timeZoneOffset: values.timeZoneOffset,
          latitude: values.latitude,
          longitude: values.longitude,
          city: values.city,
          country: DrawerOC?.data?.depotDetails?.depotInfo?.address?.country,
          countryCode:
            DrawerOC?.data?.depotDetails?.depotInfo?.address?.countryCode,
          state: values.state,
          postalCode: values.postalCode,
          streetAddress: values.streetAddress,
        },
        depotCSM: selectedUser?.map((single) => {
          return single.split("-")[0];
        }),
      };
      if (isSharedCharging) {
        payload.address.publicLocationName = values.publicLocationName;
      }
      const res = await updateDepotDetails(payload);
      if (res?.statusCode === 200) {
        getDepotInfo();
        setToast({
          isOpen: true,
          message: "Preferences update successful",
          type: "success",
        });
        setTimeout(() => {
          setIsUpdating(false);
          setDrawerOC({
            isOpen: false,
            tilte: "",
            data: {},
          });
        }, 2000);
      } else {
        setToast({
          isOpen: true,
          message: "Something went wrong",
          type: "error",
        });
        setIsUpdating(false);
      }
    }
  };
  return (
    <div className="depot_details_main">
      {/* <p className="section_title">Depot Details</p> */}
      <Grid container spacing={2}>
        <Grid item xs={4}>
          <div className="single_details">
            <p className="title">Depot Name</p>
            <p className="title_value">{DrawerOC.data.depotName}</p>
          </div>
        </Grid>
        <Grid item xs={4}></Grid>
        <Grid item xs={6}>
          <div className="single_details">
            <p className="title">Depot Id</p>
            <Controls.CopyToClipboard
              name={DrawerOC.data.depotId}
              setToast={setToast}
            />
          </div>
        </Grid>

        <Grid item xs={6}>
          <PrivilegedComponent permission="Show Access Key" module="companies">
            <div className="single_details">
              <p className="title">Access Key</p>
              <Controls.CopyToClipboard
                name={DrawerOC.data.accessKey}
                setToast={setToast}
              />
            </div>
          </PrivilegedComponent>
        </Grid>
      </Grid>
      <p className="section_title">Feature Group</p>
      <Grid container spacing={2}>
        <Grid item xs={4}>
          <div className="single_details">
            <p className="title">CDR</p>
            {DrawerOC.data &&
            DrawerOC.data.isEdit &&
            DrawerOC.data.depotType === "TEST" ? (
              <Select
                value={isCDR ? "True" : "False"}
                onChange={handleDepotDetailsChange("CDR")}
                name={"CDR"}
                fullWidth
                size="small"
                variant="outlined"
                options={flagValuesMenu}
              />
            ) : (
              <p className="title_value">
                {DrawerOC.data.isCDR ? "True" : "False"}
              </p>
            )}
          </div>
        </Grid>
        <Grid item xs={4}>
          <div className="single_details">
            <p className="title">Shared Charging</p>
            {DrawerOC.data && DrawerOC.data.isEdit ? (
              <Select
                value={isSharedCharging ? "True" : "False"}
                onChange={handleDepotDetailsChange("SharedCharging")}
                disabled={
                  componentPrivilege &&
                  componentPrivilege.includes("Shared Charging Toggle")
                    ? false
                    : true
                }
                name={"SharedCharging"}
                fullWidth
                variant="outlined"
                options={flagValuesMenu}
              />
            ) : (
              <p className="title_value">
                {DrawerOC.data.isSharedChargingDepot ? "True" : "False"}
              </p>
            )}
          </div>
        </Grid>

        <Grid item xs={4}>
          <div className="single_details">
            <p className="title">Is Simulated</p>
            {DrawerOC.data &&
            DrawerOC.data.isEdit &&
            DrawerOC.data.depotType === "TEST" ? (
              <Select
                value={isSimulated ? "True" : "False"}
                onChange={handleDepotDetailsChange("isSimulated")}
                name={"isSimulated"}
                fullWidth
                variant="outlined"
                options={flagValuesMenu}
              />
            ) : (
              <p className="title_value" id="capitalizeText">
                {JSON.stringify(DrawerOC.data.isSimulated)}
              </p>
            )}
          </div>
        </Grid>
        <Grid item xs={4}>
          <div className="single_details">
            <p className="title">Is Home Charging</p>
            {DrawerOC.data &&
            DrawerOC.data.isEdit &&
            DrawerOC.data.depotType === "TEST" ? (
              <Select
                value={isHomeChargingDepot ? "True" : "False"}
                onChange={handleDepotDetailsChange("isHomeChargingDepot")}
                name={"isHomeChargingDepot"}
                fullWidth
                variant="outlined"
                options={flagValuesMenu}
              />
            ) : (
              <p className="title_value" id="capitalizeText">
                {JSON.stringify(
                  DrawerOC.data.isHomeChargingDepot ? true : false
                )}
              </p>
            )}
          </div>
        </Grid>
        <Grid item xs={4}>
          <div className="single_details">
            <p className="title">RFID</p>
            {DrawerOC.data && DrawerOC.data.isEdit ? (
              <Select
                value={isRFIDDepot ? "True" : "False"}
                onChange={handleDepotDetailsChange("isRFIDDepot")}
                name={"isRFIDDepot"}
                fullWidth
                variant="outlined"
                options={flagValuesMenu}
              />
            ) : (
              <p className="title_value" id="capitalizeText">
                {JSON.stringify(DrawerOC.data.isRFIDDepot ? true : false)}
              </p>
            )}
          </div>
        </Grid>
        <Grid item xs={4}>
          <div className="single_details">
            <p className="title">Enable Circuits</p>
            {DrawerOC.data &&
            DrawerOC.data.isEdit &&
            DrawerOC.data.depotType === "TEST" ? (
              <Select
                value={isCircuitEnabled ? "True" : "False"}
                onChange={handleDepotDetailsChange("isCircuitEnabled")}
                name={"isCircuitEnabled"}
                disabled={isHomeChargingDepot}
                fullWidth
                variant="outlined"
                options={flagValuesMenu}
              />
            ) : (
              <p className="title_value">
                {DrawerOC.data.isCircuitEnabled ? "True" : "False"}
              </p>
            )}
          </div>
        </Grid>
        {DrawerOC.data.isHomeChargingDepot && (
          <Grid item xs={4}>
            <div className="single_details">
              <p className="title">Mina Enrolled</p>
              {DrawerOC.data && DrawerOC.data.isEdit ? (
                <Select
                  value={isMinaEnrolledDepot ? "True" : "False"}
                  onChange={handleDepotDetailsChange("isMinaEnrolledDepot")}
                  name={"isMinaEnrolledDepot"}
                  fullWidth
                  variant="outlined"
                  options={flagValuesMenu}
                />
              ) : (
                <p className="title_value" id="capitalizeText">
                  {JSON.stringify(
                    DrawerOC.data.isMinaEnrolledDepot ? true : false
                  )}
                </p>
              )}
            </div>
          </Grid>
        )}
      </Grid>

      <p className="section_title">Preferences</p>
      <Grid container spacing={2}>
        <Grid item xs={DrawerOC.data && DrawerOC.data.isEdit ? 4 : 4}>
          <div className="single_details">
            <p className="title">Currency</p>
            {DrawerOC.data && DrawerOC.data.isEdit ? (
              <MuiSelect
                value={preference.currency || ""}
                onChange={handleDepotDetailsChange("currency")}
                name={"currency"}
                fullWidth
                size="small"
                variant="outlined"
              >
                {currencies.map((single) => {
                  return (
                    <MenuItem key={single.value} value={single.value}>
                      {single.label}
                    </MenuItem>
                  );
                })}
              </MuiSelect>
            ) : (
              <p className="title_value">
                {preference.currency ? preference.currency : "-"}
              </p>
            )}
          </div>
        </Grid>
        <Grid item xs={4}>
          <div className="single_details">
            <p className="title">Distance Unit</p>
            {DrawerOC.data && DrawerOC.data.isEdit ? (
              <MuiSelect
                value={preference.distanceUnit || ""}
                onChange={handleDepotDetailsChange("distanceUnit")}
                name={"distanceUnit"}
                variant="outlined"
                size="small"
              >
                {["KM", "MILES"].map((single) => {
                  return (
                    <MenuItem key={single} value={single}>
                      {single}
                    </MenuItem>
                  );
                })}
              </MuiSelect>
            ) : (
              <p className="title_value">
                {preference.distanceUnit ? preference.distanceUnit : "-"}
              </p>
            )}
          </div>
        </Grid>
        <Grid item xs={4}>
          <div className="single_details">
            <p className="title">Date Format</p>
            {DrawerOC.data && DrawerOC.data.isEdit ? (
              <MuiSelect
                value={preference.dateFormat || ""}
                onChange={handleDepotDetailsChange("dateFormat")}
                name={"dateFormat"}
                variant="outlined"
                size="small"
              >
                {dateFormats.map((single) => {
                  return (
                    <MenuItem key={single.dateFormat} value={single.dateFormat}>
                      {single.dateFormat}
                    </MenuItem>
                  );
                })}
              </MuiSelect>
            ) : (
              <p className="title_value">
                {preference.dateFormat ? preference.dateFormat : "-"}
              </p>
            )}
          </div>
        </Grid>

        <Grid item xs={4}>
          <div className="single_details">
            <p className="title">Depot Type</p>
            {DrawerOC.data && DrawerOC.data.isEdit ? (
              <Select
                value={depotType || ""}
                onChange={handleDepotDetailsChange("depotType")}
                name={"depotType"}
                fullWidth
                variant="outlined"
                options={depotTypeList}
                size="small"
              />
            ) : (
              <p className="title_value">{DrawerOC.data.depotType}</p>
            )}
          </div>
        </Grid>
        <Grid item xs={4}>
          <div className="single_details">
            <p className="title">Cloud Provider</p>
            <p className="title_value" id="capitalizeText">
              {DrawerOC.data.cloudProvider
                ? DrawerOC.data.cloudProvider
                : "AWS"}
            </p>
          </div>
        </Grid>
        <Grid item xs={4}>
          <div className="single_details">
            <p className="title">CSM Users</p>
            {DrawerOC.data && DrawerOC.data.isEdit && users ? (
              <FormControl variant="outlined" size="small" id="completeWidth">
                <Autocomplete
                  multiple
                  id="combo-box-demo"
                  name="CMSUsers"
                  disableCloseOnSelect
                  value={selectedUser}
                  className="currency_autocomplete"
                  onChange={handleSelectedUser}
                  options={users}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="outlined"
                      // label="CSM Users"
                    />
                  )}
                />
              </FormControl>
            ) : (
              <>
                {DrawerOC.data?.depotCSM?.length > 0 ? (
                  DrawerOC.data.depotCSM.map((single) => {
                    return <p className="title_value">{single}</p>;
                  })
                ) : (
                  <p className="title_value">N/A</p>
                )}
              </>
            )}
          </div>
        </Grid>
        {DrawerOC.data.expiryDate && (
          <Grid item xs={4}>
            <div className="single_details">
              <p className="title">Expiry Date</p>
              <p className="title_value">
                {DrawerOC.data ? DrawerOC.data.expiryDate : "-"}
              </p>
            </div>
          </Grid>
        )}
        {DrawerOC.data.description && (
          <Grid item xs={4}>
            <div className="single_details">
              <p className="title">Description</p>
              <p className="title_value">
                {DrawerOC.data.depotDetails ? DrawerOC.data.description : "-"}
              </p>
            </div>
          </Grid>
        )}
      </Grid>
      <p className="section_title">Location</p>
      {DrawerOC.data.isEdit ? (
        <>
          {isSharedCharging ? (
            <div className="bottomMargin">
              <Controls.Input
                name="publicLocationName"
                label="Public Location Name"
                value={values.publicLocationName}
                onChange={handleDepotDetailsChange()}
                error={errors.publicLocationName}
                fullWidth={true}
              />
            </div>
          ) : null}
          <div className="bottomMargin">
            <Controls.AddressInput
              id="google-map-suggestion"
              label="Search Address"
              name="address"
              value={address || ""}
              onChange={handleDepotDetailsChange()}
            />
          </div>
          {address && additionalInfoLoader ? (
            <Grid container direction="row">
              <CircularProgress size={20} className="leftMargin" />
              <Typography
                variant="subtitle2"
                align="center"
                className="leftMargin"
              >
                Fetching Additional Info . . .
              </Typography>
            </Grid>
          ) : DrawerOC?.data?.depotDetails?.depotInfo?.address ? (
            <Grid container spacing={2}>
              <Grid item md={8}>
                <Controls.Input
                  id="streetAddress"
                  label="Street Address"
                  name="streetAddress"
                  value={values.streetAddress}
                  error={errors.streetAddress}
                  onChange={handleDepotDetailsChange()}
                  fullWidth={true}
                />
              </Grid>
              <Grid item xs={4}>
                <Controls.AutoSuggestion
                  id="timeZoneId"
                  label="Time Zone"
                  name="timeZoneId"
                  error={errors.timeZoneId}
                  value={values.timeZoneId}
                  onChange={handleDepotDetailsChange()}
                  options={getTimeZones()}
                />
              </Grid>
              <Grid item xs={4}>
                <Controls.Input
                  name="timeZoneOffset"
                  label="Time Zone Offset"
                  value={values.timeZoneOffset}
                  onChange={handleDepotDetailsChange()}
                  error={errors.timeZoneOffset}
                  fullWidth={true}
                />
              </Grid>
              <Grid item xs={4}>
                <Controls.Input
                  name="latitude"
                  label="Latitude"
                  value={values.latitude}
                  onChange={handleDepotDetailsChange()}
                  error={errors.latitude}
                  fullWidth={true}
                />
              </Grid>
              <Grid item xs={4}>
                <Controls.Input
                  name="longitude"
                  label="Longitude"
                  value={values.longitude}
                  onChange={handleDepotDetailsChange()}
                  error={errors.longitude}
                  fullWidth={true}
                />
              </Grid>
              <Grid item xs={4}>
                <Controls.Input
                  name="city"
                  label="City"
                  value={values.city}
                  onChange={handleDepotDetailsChange()}
                  error={errors.city}
                  fullWidth={true}
                />
              </Grid>
              <Grid item xs={4}>
                <Controls.Input
                  name="state"
                  label="State"
                  value={values.state}
                  onChange={handleDepotDetailsChange()}
                  error={errors.state}
                  fullWidth={true}
                />
              </Grid>
              <Grid item xs={4}>
                <Controls.Input
                  name="postalCode"
                  label="Postal Code"
                  value={values.postalCode}
                  onChange={handleDepotDetailsChange()}
                  error={errors.postalCode}
                  fullWidth={true}
                />
              </Grid>
              <Grid item xs={4}>
                <div className="single_details">
                  <p className="title">Country</p>
                  <p className="title_value">
                    {DrawerOC.data.depotDetails
                      ? DrawerOC.data.depotDetails.depotInfo.address.country
                      : "-"}
                  </p>
                </div>
              </Grid>
              <Grid item xs={4}>
                <div className="single_details">
                  <p className="title">Country Code</p>
                  <p className="title_value">
                    {DrawerOC.data.depotDetails
                      ? DrawerOC.data.depotDetails.depotInfo.address.countryCode
                      : "-"}
                  </p>
                </div>
              </Grid>
              <Grid item xs={2}>
                <Tooltip title="Update preferences">
                  <Controls.Button
                    text={isUpdating ? "Updating..." : "Update"}
                    color="primary"
                    onClick={() => updatePreferenceDetails()}
                    disabled={isUpdating}
                  ></Controls.Button>
                </Tooltip>
              </Grid>
            </Grid>
          ) : null}
        </>
      ) : (
        <Grid container>
          <Grid item xs={4}>
            <div className="single_details">
              <p className="title">Address</p>
              <p className="title_value">
                {DrawerOC.data.depotDetails
                  ? DrawerOC.data.depotDetails.depotInfo.address.streetAddress
                  : "-"}
              </p>
            </div>
          </Grid>
          <Grid item xs={4}>
            <div className="single_details">
              <p className="title">City</p>
              <p className="title_value">
                {DrawerOC.data.depotDetails
                  ? DrawerOC.data.depotDetails.depotInfo.address.city
                  : "-"}
              </p>
            </div>
          </Grid>
          <Grid item xs={4}>
            <div className="single_details">
              <p className="title">Country</p>
              <p className="title_value">
                {DrawerOC.data.depotDetails
                  ? DrawerOC.data.depotDetails.depotInfo.address.country
                  : "-"}
              </p>
            </div>
          </Grid>
          <Grid item xs={4}>
            <div className="single_details">
              <p className="title">PostalCode</p>
              <p className="title_value">
                {DrawerOC.data.depotDetails
                  ? DrawerOC.data.depotDetails.depotInfo.address.postalCode
                  : "-"}
              </p>
            </div>
          </Grid>
          <Grid item xs={4}>
            <div className="single_details">
              <p className="title">Country Code</p>
              <p className="title_value">
                {DrawerOC.data.depotDetails
                  ? DrawerOC.data.depotDetails.depotInfo.address.countryCode
                  : "-"}
              </p>
            </div>
          </Grid>
          <Grid item xs={4}>
            <div className="single_details">
              <p className="title">State</p>
              <p className="title_value">
                {DrawerOC.data.depotDetails
                  ? DrawerOC.data.depotDetails.depotInfo.address.state
                  : "-"}
              </p>
            </div>
          </Grid>
          <Grid item xs={4}>
            <div className="single_details">
              <p className="title">Timezone Offset</p>
              <p className="title_value">
                {DrawerOC.data.depotDetails
                  ? DrawerOC.data.depotDetails.depotInfo.address.timeZoneOffset
                  : "-"}
              </p>
            </div>
          </Grid>
          <Grid item xs={4}>
            <div className="single_details">
              <p className="title">Timezone</p>
              <p className="title_value">
                {DrawerOC.data.depotDetails
                  ? DrawerOC.data.depotDetails.depotInfo.address.timeZoneId
                  : "-"}
              </p>
            </div>
          </Grid>
          <Grid item xs={4}>
            <div className="single_details">
              <p className="title">Latitude</p>
              <p className="title_value">
                {DrawerOC.data.depotDetails
                  ? DrawerOC.data.depotDetails.depotInfo.address.latitude
                  : "-"}
              </p>
            </div>
          </Grid>
          <Grid item xs={4}>
            <div className="single_details">
              <p className="title">Longitude</p>
              <p className="title_value">
                {DrawerOC.data.depotDetails
                  ? DrawerOC.data.depotDetails.depotInfo.address.longitude
                  : "-"}
              </p>
            </div>
          </Grid>
        </Grid>
      )}
      <p className="section_title">URLs</p>
      <Grid container>
        <Grid item xs={12}>
          <div className="single_details">
            <p className="title">Dashboard URL</p>
            <Controls.CopyToClipboard
              name={DrawerOC.data.dashboardUrl}
              setToast={setToast}
            />
          </div>
        </Grid>
        <Grid item xs={12}>
          <div className="single_details">
            <p className="title">Api Url</p>
            <Controls.CopyToClipboard
              name={DrawerOC.data.apiUrl}
              setToast={setToast}
            />
          </div>
        </Grid>
        <Grid item xs={12}>
          <div className="single_details">
            <p className="title">Charger Config URL</p>
            <Controls.CopyToClipboard
              name={DrawerOC.data.chargerConfigUrl}
              setToast={setToast}
            />
          </div>
        </Grid>
      </Grid>
      <p className="section_title">Logs</p>
      <Grid item xs={12}>
        <div className="single_details">
          <a
            target="_blank"
            href={`https://app.datadoghq.com/logs?query=depot_id%3A${DrawerOC.data.depotId}%20service%3Acentral-server&cols=host%2Cservice&index=%2A&messageDisplay=inline&stream_sort=desc&live=false
`}
            className="title"
          >
            Central Server
          </a>
        </div>
      </Grid>
      <Grid item xs={12}>
        <div className="single_details">
          <a
            target="_blank"
            href={`https://app.datadoghq.com/logs?query=depot_id%3A${DrawerOC.data.depotId}%20service%3Acms-api&cols=host%2Cservice&index=&messageDisplay=inline&stream_sort=desc&live=false`}
            className="title"
          >
            CMS Api{" "}
          </a>
        </div>
      </Grid>
      <Grid item xs={12}>
        <PrivilegedComponent permission="Status History" module="companies">
          {rows && rows.length ? (
            <div>
              <p className="section_title">Status History</p>
              <Grid item xs={12}>
                <TableComponent rows={rows} columns={columns} />
              </Grid>
            </div>
          ) : (
            <div></div>
          )}
        </PrivilegedComponent>
      </Grid>
    </div>
  );
};

export default CompanyDetails;
