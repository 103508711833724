import React from "react";
import {
  Dialog,
  DialogContent,
  DialogActions,
  Typography,
} from "@mui/material";
import { makeStyles } from "@mui/styles";

import Controls from "./controls/Controls";
import Slide from "@mui/material/Slide";
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

import CheckCircleIcon from "@mui/icons-material/CheckCircle";
const useStyles = makeStyles((theme) => ({
  dialog: {
    padding: theme.spacing(2),
    position: "absolute",
    top: theme.spacing(15),
    borderRadius: "4px",
    minWidth: "500px",
    borderTop: "4px solid #009434",
    boxShadow:
      "0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 29px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12)",
  },
  dialogContent: {
    textAlign: "left",
    padding: "0",
    marginBottom: "10px",
  },
  dialogAction: {
    justifyContent: "end",
  },
  dialogTitle: {
    textAlign: "center",
  },
  title: {
    marginBottom: "10px",
    color: "#00095B",
    fontSize: "18px",
    fontWeight: "600",
    display: "flex",
  },
  subTitle: {
    color: "#38393F",
    fontWeight: "600",
    fontSize: "16px",
  },
  titleIcon: {
    backgroundColor: ({ type }) => theme.palette[type].light,
    color: ({ type }) => theme.palette[type].main,
    "&:hover": {
      backgroundColor: ({ type }) => theme.palette[type].light,
      cursor: "default",
    },
    "& .MuiSvgIcon-root": {
      fontSize: "8rem",
    },
  },
}));

const SuccessDialog = (props) => {
  const { successDialog, setSuccessDialog } = props;
  const classes = useStyles({ type: successDialog.type });
  return (
    <Dialog
      TransitionComponent={Transition}
      open={successDialog.isOpen}
      classes={{ paper: classes.dialog }}
    >
      <DialogContent className={classes.dialogContent}>
        <Typography variant="h6" className={classes.title}>
          <span style={{ color: "#009434", marginRight: "10px" }}>
            <CheckCircleIcon />
          </span>{" "}
          {successDialog.title}
        </Typography>
        <Typography variant="subtitle2" className={classes.subTitle}>
          {successDialog.subTitle}
        </Typography>
      </DialogContent>
      <DialogActions className={classes.dialogAction}>
        <Controls.Button
          variant="outlined"
          text="Cancel"
          color="primary"
          onClick={() => setSuccessDialog({ ...successDialog, isOpen: false })}
        ></Controls.Button>
        <Controls.Button
          text={successDialog.btnTitle}
          onClick={successDialog.onConfirm}
        ></Controls.Button>
      </DialogActions>
    </Dialog>
  );
};

export default SuccessDialog;
