export const variables = {
  ACCESS_TOKEN: "access_token",
  IS_USER_LOGGED_IN: "is_logged_in",
  USER_FIRST_NAME: "user_first_name",
  USER_LAST_NAME: "user_last_name",
  USER_EMAIL: "user_email",
  USER_PHONE_NUMBER: "user_phone_number",
  USER_ROLE: "user_role",
  USER_ROLE_ID: "user_role_id",
  USER_ROLE_PRIVILEGE: "user_role_privilege",
  USER_COMPONENT_PRIVILEGE: "user_component_privilege",
};
export const DepotType = {
  TEST: "TEST",
  INTERNAL: "INTERNAL",
  PILOT: "PILOT",
  CUSTOMER: "CUSTOMER",
  DEALER: "DEALER",
};
