import React from "react";
import { Backdrop, CircularProgress, LinearProgress } from "@mui/material";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer - 1,
    // color: "#fff",
  },
}));
const Loader = (props) => {
  const classes = useStyles();
  const { isLoading } = props;
  return (
    <div style={{ position: "absolute", top: "64px", width: "100%", right: 0 }}>
      {/* // <Backdrop open={isLoading} className={classes.backdrop}> */}
      {/* <CircularProgress style={{ color: "white" }} /> */}
      {isLoading && (
        <LinearProgress
          className="linear_progress_styles"
          style={{ width: "100%", height: "8px" }}
        />
      )}

      {/* </Backdrop> */}
    </div>
  );
};

export default Loader;
