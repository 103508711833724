import React from "react";
import FileCopyOutlinedIcon from "@mui/icons-material/FileCopyOutlined";
import { Tooltip } from "@mui/material";
import JSONPretty from "react-json-pretty";
import ReactJson from "react-json-view";
import { Margin } from "@mui/icons-material";

const CopyToClipboard = (props) => {
  const { name, setToast, color, isJson, max_Height, ...other } = props;
  return (
    <div className="copy_to_clipboard">
      {!isJson ? (
        <p style={{ wordBreak: "break-all", color, paddding: "10px" }}>
          {name}
        </p>
      ) : (
        <ReactJson
          src={name}
          enableClipboard={false}
          style={{
            maxHeight: max_Height !== undefined ? max_Height : "280px",
            marginTop: "20px",
          }}
        />
      )}
      <div className="copy-icon">
        <Tooltip title="Copy">
          <FileCopyOutlinedIcon
            onClick={() => {
              !isJson
                ? navigator.clipboard.writeText(name)
                : navigator.clipboard.writeText(JSON.stringify(name));
              setToast({
                isOpen: true,
                message: "Copied",
                type: "success",
              });
            }}
          />
        </Tooltip>
      </div>
    </div>
  );
};

export default CopyToClipboard;
