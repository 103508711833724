import React from "react";
import { TextField } from "@mui/material";

const TextArea = (props) => {
  const {
    name,
    label,
    onChange,
    error = null,
    value,
    editable = true,
    variant,
    rows = 2,
    rowsMax = 4,
    className = "",
    ...other
  } = props;

  return (
    <TextField
      {...other}
      className={className}
      variant={variant ? variant : "outlined"}
      multiline
      name={name}
      label={label}
      value={value}
      size="small"
      onChange={onChange}
      inputProps={{ readOnly: !editable }}
      InputProps={editable ? {} : { className: "Mui-disabled" }}
      {...(error && { error: true, helperText: error })}
      rows={rows}
      maxRows={rowsMax}
    />
  );
};

export default TextArea;
