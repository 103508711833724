import PropTypes from "prop-types";
import { connect } from "react-redux";

const ShowForPermissionComponent = (props) => {
  const couldShow = props?.userPermissions?.[props?.module]?.includes(
    props?.permission
  );
  return couldShow ? props?.children : null;
};

ShowForPermissionComponent.propTypes = {
  permission: PropTypes.string.isRequired,
  module: PropTypes.string.isRequired,
  userPermissions: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => {
  return {
    userPermissions: state.user.componentPrivilege, //<--- here you will get permissions for your user from Redux store
  };
};

export const PrivilegedComponent = connect(mapStateToProps)(
  ShowForPermissionComponent
);
