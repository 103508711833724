import { Grid } from "@mui/material";
import { useEffect, useState } from "react";
import { getChargersListForDepot } from "../charger-health/charger-firmware-details/chargerFirmwareService";
import Loader from "../../components/Loader";
import Controls from "../../components/controls/Controls";
import Popup from "../../components/Popup";
import apiDepots from "../../services/api/Depots";
import ToastMessage from "../../components/ToastMessage";
import { useHistory } from "react-router-dom";

const DeleteDepotReviewForm = (props) => {
  const history = useHistory();
  const { recordForEdit } = props;
  const [isLoading, setIsLoading] = useState(false);
  const [chargersCount, setChargersCount] = useState();
  useEffect(async () => {
    setIsLoading(true);
    let isActiveDepot = false;
    const depotChargersResponse = await getChargersListForDepot(
      recordForEdit.depotId
    );

    if (depotChargersResponse.statusCode === 200) {
      isActiveDepot = depotChargersResponse?.data?.isActiveDepot;
      setChargersCount({
        chargers: depotChargersResponse?.data?.chargerCount,
        isActiveDepot: isActiveDepot,
      });
    }
    setIsLoading(false);
  }, []);
  const [openPopup, setOpenPopup] = useState({
    isOpen: false,
    title: "",
  });
  const [toast, setToast] = useState({
    isOpen: false,
    message: "",
    type: "",
  });
  const handleDeleteDepot = async () => {
    const res = await apiDepots.deleteDepot(recordForEdit.depotId);
    if (res.code === 201) {
      setToast({
        isOpen: true,
        message: "Depot deleted successfully",
        type: "success",
      });
    } else if (res.message) {
      setToast({
        isOpen: true,
        message: res?.message,
        type: "error",
      });
    } else {
      setToast({
        isOpen: true,
        message: "Internal Server Error",
        type: "error",
      });
    }
    history.push("/requests");
  };

  const handleDelineDeleteRequest = async () => {
    const res = await apiDepots.rejectDeleteDepot(recordForEdit.depotId);
    if (res.code === 201) {
      setToast({
        isOpen: true,
        message: "Depot delete rejected successfully",
        type: "success",
      });
    } else if (res.message) {
      setToast({
        isOpen: true,
        message: res?.message,
        type: "error",
      });
    } else {
      setToast({
        isOpen: true,
        message: "Internal Server Error",
        type: "error",
      });
    }
    history.push("/requests");
  };
  return (
    <div className="ReviewAndConfirmForm">
      {<Loader isLoading={isLoading} />}
      <Grid container>
        <Grid item xs={6}>
          <div className="single_details">
            <p className="title">Company Name</p>
            <p className="title_value">{recordForEdit.companyName}</p>
          </div>
        </Grid>
        <Grid item xs={6}>
          <div className="single_details">
            <p className="title">Company ID</p>
            <p className="title_value">{recordForEdit.companyId}</p>
          </div>
        </Grid>
      </Grid>
      <Grid container>
        <Grid item xs={6}>
          <div className="single_details">
            <p className="title">Depot Name</p>
            <p className="title_value">{recordForEdit.depotName}</p>
          </div>
        </Grid>
        <Grid item xs={6}>
          <div className="single_details">
            <p className="title">Depot ID</p>
            <p className="title_value">{recordForEdit.depotId}</p>
          </div>
        </Grid>
      </Grid>
      <Grid container>
        <Grid item xs={6}>
          <div className="single_details">
            <p className="title">Chargers Count</p>
            <p className="title_value">
              {chargersCount?.chargers ? chargersCount?.chargers : 0}
            </p>
          </div>
        </Grid>
        <Grid item xs={6}>
          <div className="single_details">
            <p className="title">Active Depot</p>
            <p className="title_value">
              {chargersCount?.isActiveDepot ? "Yes" : "No"}
            </p>
          </div>
        </Grid>
      </Grid>
      <Grid container>
        <Grid sm item />
        <Grid>
          <div className="actionButtonGroup">
            <Controls.Button
              color="primary"
              text={"Cancel"}
              onClick={() => {
                history.push("/requests");
              }}
            />
            <Controls.Button
              text={"Delete"}
              onClick={() => {
                setOpenPopup({
                  isOpen: true,
                  title: "Delete Depot",
                });
              }}
            />
          </div>
        </Grid>
      </Grid>
      <Popup openPopup={openPopup} setOpenPopup={setOpenPopup}>
        {openPopup.title === "Delete Depot" &&
        chargersCount?.chargers > 0 &&
        process.env.REACT_APP_NODE_ENV === "PROD" ? (
          <p style={{ fontSize: "larger" }}>
            Deletion cannot be performed because there are active chargers.
          </p>
        ) : openPopup.title === "Delete Depot" ? (
          <p style={{ fontSize: "larger" }}>
            Are sure you want to delete the depot ?
          </p>
        ) : (
          <p style={{ fontSize: "larger" }}>
            Are sure you want to decline the delete depot request?
          </p>
        )}
        <Grid container justifyContent="flex-end">
          <Grid>
            <div className="delete_pop_buttonAlign">
              <Controls.Button
                color="primary"
                text={"Cancel"}
                onClick={() => {
                  setOpenPopup({
                    isOpen: false,
                  });
                }}
              />
            </div>
          </Grid>
          <Grid>
            <div className="delete_pop_buttonAlign">
              <Controls.Button
                text={"Confirm"}
                disabled={
                  chargersCount?.chargers > 0 &&
                  process.env.REACT_APP_NODE_ENV === "PROD"
                }
                onClick={() =>
                  openPopup.title === "Delete Depot"
                    ? handleDeleteDepot()
                    : handleDelineDeleteRequest()
                }
              />
            </div>
          </Grid>
        </Grid>
      </Popup>
      <ToastMessage toast={toast} setToast={setToast} />
    </div>
  );
};
export default DeleteDepotReviewForm;
