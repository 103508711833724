import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useLocation } from "react-router-dom";
import {
  Box,
  Collapse,
  Grid,
  IconButton,
  ToggleButton,
  ToggleButtonGroup,
  Toolbar,
  Tooltip,
  Zoom,
} from "@mui/material";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import GetAppOutlinedIcon from "@mui/icons-material/GetAppOutlined";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import EditOutlined from "@mui/icons-material/EditOutlined";
import DeleteIcon from "@mui/icons-material/Delete";
import ManageHistoryIcon from "@mui/icons-material/ManageHistory";
import Controls from "../../../components/controls/Controls";
import Loader from "../../../components/Loader";
import OpsBreadcrumb from "../../../components/NewBreadcrumbs";
import PageHeader from "../../../components/PageHeader";
import PageMainContent from "../../../components/PageMainContent";
import { routePaths } from "../../../constants/RoutePaths";
import {
  apiProvider,
  indexedEndPoints,
} from "../../../services/api/utilities/provider";
import AddEditIntegrationTestReport, {
  ViewReportHistory,
} from "./AddEditIntegrationTestReport";
import Popup from "../../../components/Popup";
import ToastMessage from "../../../components/ToastMessage";
import { PDFDownloadLink } from "@react-pdf/renderer";
import IntegrationTestReportPDF from "./IntegrationTestReportPDF";
import "../dataCatalog.scss";

const statusList = [
  { value: "true", label: "PASS" },
  { value: "false", label: "FAIL" },
];

export default function IntegrationTestReport() {
  const search = useLocation().search;
  const searchParams = new URLSearchParams(search);
  const manufacturer = searchParams.get("manufacturer");
  const model = searchParams.get("model");
  const chargerId = searchParams.get("chargerId");
  const firmware = searchParams.get("firmware");
  const [data, setData] = useState([]);
  const [lastReportData, setLastReportData] = useState([]);
  const [lastUpdatedAt, setLastUpdatedAt] = useState("");
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState([]);
  const [toast, setToast] = useState({
    isOpen: false,
    message: "",
    type: "",
  });
  const [openPopup, setOpenPopup] = useState({
    isOpen: false,
    title: "",
    child: "",
    item: {},
  });

  const { CHARGER_INTEGRATION_TEST } = indexedEndPoints;

  const allBreadcrumbsLinks = useMemo(
    () => [
      {
        link: routePaths.DATA_CATALOG,
        title: "Data Catalog",
      },
      {
        link: routePaths.CHARGERS_DASHBOARD,
        title: "Chargers Dashboard",
      },
      {
        link: `${routePaths.CHARGERS}?manufacturer=${manufacturer}`,
        title: "Chargers",
      },
    ],
    []
  );

  useEffect(() => {
    const fetchTestSuites = async () => {
      setLoading(true);
      const response = await apiProvider.getAll(
        `${CHARGER_INTEGRATION_TEST}/${chargerId}`
      );
      setLoading(false);
      setData(response?.data?.testSuites);
      setLastReportData(response?.data?.testSuites);
      setLastUpdatedAt(response?.data?.performedAt);
      let testSuiteCount = response?.data?.length;
      let openClose = [true];
      for (let i = 1; i < testSuiteCount; i++) openClose.push(false);
      setOpen(openClose);
    };
    fetchTestSuites();
  }, []);

  const handleSubmit = useCallback(async () => {
    const payload = {
      firmwareVersion: firmware,
      testSuites: data?.map((row) => ({
        testSuiteName: row.testSuiteName,
        observation: row.observation,
        isDefault: row.isDefault,
        testCases: row.testCases,
      })),
    };
    const result = data?.some((row) =>
      row.testCases?.some(
        (item) =>
          item.isMandatory && item.status !== true && item.status !== false
      )
    );
    if (result) {
      setToast({
        isOpen: true,
        message: "Please mark all mandatory test cases as Pass/Fail",
        type: "error",
      });
      return;
    }

    setLoading(true);
    const response = await apiProvider.post(
      `${CHARGER_INTEGRATION_TEST}/${chargerId}`,
      payload
    );
    setLoading(false);
    if (response?.statusCode >= 200 && response?.statusCode <= 299) {
      setToast({
        isOpen: true,
        message: "Integration test report submitted successfully",
        type: "success",
      });
    } else {
      setToast({
        isOpen: true,
        message: response?.data?.data,
        type: "error",
      });
    }
  }, [data]);

  const ToolbarMemoised = useCallback(
    ({ setOpenPopup }) => {
      return (
        <Toolbar className="table_toolbar">
          <Grid sm item />
          <Controls.Button
            text="Report History"
            variant="outlined"
            startIcon={<ManageHistoryIcon />}
            onClick={() =>
              setOpenPopup({
                isOpen: true,
                title: "Report History",
                child: "viewReportHistory",
                item: chargerId,
              })
            }
          />
          <PDFDownloadLink
            key={model}
            document={
              <IntegrationTestReportPDF
                model={model}
                data={lastReportData}
                updatedAt={
                  lastUpdatedAt
                    ? lastUpdatedAt.replace("GMT", "UTC")
                    : lastUpdatedAt
                }
              />
            }
            fileName={`IntegrationTestReport-${new Date()
              .toString()
              .split(" GMT")[0]
              .split(" ")
              .join("-")}`}
          >
            <Controls.Button
              text="Export Test Report"
              variant="outlined"
              startIcon={<GetAppOutlinedIcon />}
            />
          </PDFDownloadLink>
          <Controls.Button
            text="Add TestSuite"
            variant="outlined"
            startIcon={<AddCircleOutlineIcon />}
            onClick={() =>
              setOpenPopup({
                isOpen: true,
                title: "Add TestSuite",
                child: "addTestSuite",
                item: {},
              })
            }
          />
        </Toolbar>
      );
    },
    [data, lastReportData, lastUpdatedAt]
  );

  return (
    <>
      <OpsBreadcrumb
        AllBreadcrumbsLinks={allBreadcrumbsLinks}
        title="Integration Test Report"
      />
      <PageHeader title="Integration Test Report" />
      <PageMainContent>
        <Loader isLoading={loading} />
        <Grid container spacing={5}>
          <Grid item xs={6}>
            <div className="single_details">
              <p className="title">Manufacturer</p>
              <Controls.CopyToClipboard
                name={manufacturer}
                setToast={setToast}
              />
            </div>
          </Grid>
          <Grid item xs={6}>
            <div className="single_details">
              <p className="title">Model</p>
              <Controls.CopyToClipboard name={model} setToast={setToast} />
            </div>
          </Grid>
        </Grid>
        <ToolbarMemoised setOpenPopup={setOpenPopup} />
        <Grid container className="integrationReport">
          {data?.map((row, ind) => {
            return (
              <Grid container key={ind} className="container">
                <Grid item xs={12}>
                  <div className="single_details testSuite">
                    <IconButton
                      aria-label="expand row"
                      size="small"
                      onClick={() => {
                        let openClose = [...open];
                        openClose[ind] = !open[ind];
                        setOpen(openClose);
                      }}
                    >
                      <p className="title testSuiteName">
                        {row?.testSuiteName?.toUpperCase()}
                      </p>
                      {open[ind] ? (
                        <KeyboardArrowUpIcon />
                      ) : (
                        <KeyboardArrowDownIcon />
                      )}
                    </IconButton>
                    {row.isDefault ? (
                      <Controls.ActionButton>
                        <Tooltip
                          arrow
                          TransitionComponent={Zoom}
                          title="This is a default test suite and cannot be modified or deleted"
                        >
                          <InfoOutlinedIcon fontSize="small" />
                        </Tooltip>
                      </Controls.ActionButton>
                    ) : (
                      <>
                        <Controls.ActionButton
                          onClick={() => {
                            setOpenPopup({
                              isOpen: true,
                              title: "Update TestSuite",
                              child: "updateTestSuite",
                              item: { ...row, index: ind },
                            });
                          }}
                          className="updateTestSuite"
                        >
                          <Tooltip
                            arrow
                            TransitionComponent={Zoom}
                            title="Update Testsuite"
                          >
                            <EditOutlined fontSize="small" />
                          </Tooltip>
                        </Controls.ActionButton>
                        <Controls.ActionButton
                          onClick={() => {
                            let newData = [...data];
                            newData.splice(ind, 1);
                            setData(newData);
                          }}
                          className="updateTestSuite"
                        >
                          <Tooltip
                            arrow
                            TransitionComponent={Zoom}
                            title="Delete Testsuite"
                          >
                            <DeleteIcon fontSize="small" />
                          </Tooltip>
                        </Controls.ActionButton>
                      </>
                    )}
                  </div>
                </Grid>
                <Grid item xs={12} className="testCases">
                  <Collapse in={open[ind]} timeout="auto" unmountOnExit>
                    {row?.testCases?.map((item, i) => (
                      <Grid container key={i} alignItems="center" spacing={1}>
                        <Grid item xs={6}>
                          {item.name}
                          {item?.isMandatory ? "*" : ""}
                        </Grid>
                        <Grid item xs={2}>
                          <ToggleButtonGroup
                            color="primary"
                            value={item?.status?.toString()}
                            exclusive
                            onChange={(e) => {
                              let newData = [...data];
                              newData[ind].testCases[i].status =
                                e.target.value === "true";
                              setData(newData);
                            }}
                            aria-label="Platform"
                          >
                            {statusList?.map((row, i) => (
                              <ToggleButton key={i} value={row.value}>
                                {row.label}
                              </ToggleButton>
                            ))}
                          </ToggleButtonGroup>
                        </Grid>
                        <Grid item xs={3}>
                          <Controls.Input
                            label="Observations"
                            value={item.observation}
                            onChange={(e) => {
                              let newData = [...data];
                              newData[ind].testCases[i].observation =
                                e.target.value;
                              setData(newData);
                            }}
                          />
                        </Grid>
                        <Grid item xs={1}>
                          {!row.isDefault && (
                            <Controls.ActionButton
                              color="primary"
                              onClick={() => {
                                let newData = [...data];
                                newData[ind].testCases.splice(i, 1);
                                setData(newData);
                              }}
                              className="deleteTestCases"
                            >
                              <Tooltip
                                arrow
                                TransitionComponent={Zoom}
                                title="Delete"
                              >
                                <DeleteIcon />
                              </Tooltip>
                            </Controls.ActionButton>
                          )}
                        </Grid>
                      </Grid>
                    ))}
                  </Collapse>
                </Grid>
              </Grid>
            );
          })}
        </Grid>
        <div className="integrationReport">
          <div className="submit">
            <Controls.Button
              type="submit"
              text={"Submit"}
              onClick={handleSubmit}
            />
          </div>
        </div>
      </PageMainContent>
      <Popup
        openPopup={openPopup}
        setOpenPopup={setOpenPopup}
        maxWidth={openPopup?.child === "viewReportHistory" ? "xl" : "sm"}
      >
        {openPopup?.child === "viewReportHistory" ? (
          <ViewReportHistory openPopup={openPopup} model={model} />
        ) : (
          <AddEditIntegrationTestReport
            type={openPopup.child}
            openPopup={openPopup}
            setOpenPopup={setOpenPopup}
            data={data}
            setData={setData}
          />
        )}
      </Popup>
      <ToastMessage toast={toast} setToast={setToast} />
    </>
  );
}
