import axios from "axios";
import { getData } from "../../../utils/Storage";
import { clearLocalStorage } from "../../../utils/Storage";
export const AxiosInterceptor = () => {
  axios.interceptors.request.use(
    (config) => {
      const token = getData('access_token');
      if (token && !config.url.includes('8001')) {
        config.headers.Authorization = `Bearer ${token}`;
      }
      return config;
    },
    (err) => {
      return Promise.reject(err);
    }
  );
  axios.interceptors.response.use(
    (res) => {
      return res;
    },
    function (err) {
      {
        if (err.response.status === 401) {
          try {
            clearLocalStorage();
            window.location.href = '/';
          } catch (error) {
            console.log("error signing out:", error);
          }
        }
        return Promise.reject(err);
      }
    });
};

export default AxiosInterceptor;

