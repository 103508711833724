import {
  apiProvider,
  indexedEndPoints,
} from "../../services/api/utilities/provider";

const { UPDATE_DEPOTS, DEPOT_STATUS_HISTORY } = indexedEndPoints;

export const updateDepotDetails = async (payload) =>
  await apiProvider.put(`depots/${UPDATE_DEPOTS}`, payload);

export const getDepotStatusHistory = async (depotId) =>
  await apiProvider.getAll(DEPOT_STATUS_HISTORY.replace("depotId", depotId));

export default { updateDepotDetails };
