import React from "react";
import { Backdrop } from "@mui/material";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
}));
const LoaderForLazy = (props) => {
  const classes = useStyles();
  const { isLoading } = props;
  return (
    <Backdrop open={isLoading} className={classes.backdrop}>
      <div>Loading...</div>
    </Backdrop>
  );
};

export default LoaderForLazy;
