import React, { useEffect, useCallback } from "react";
import {
  InputAdornment,
  TableBody,
  TableCell,
  TableRow,
  Toolbar,
  Grid,
  Chip,
  Tooltip,
  Zoom,
  Badge,
  Button,
  Menu,
  MenuItem,
  IconButton,
} from "@mui/material";
import WarningIcon from "@mui/icons-material/Warning";
import AddIcon from "@mui/icons-material/Add";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { makeStyles } from "@mui/styles";
import clsx from "clsx";
import no_Data from "./../../../assets/images/png/Vector.png";
import { isEqual } from "lodash";
import SearchIcon from "@mui/icons-material/Search";
import useTable from "../../../components/UseTable";
import Controls from "../../../components/controls/Controls";
import { titleLabels } from "../../../constants/TitleLabels";
import TuneIcon from "@mui/icons-material/Tune";
import CommonDrawer from "../../../components/SideDrawer";
import ChargerdetailsSideBar from "./charger-sideBars/ChargerdetailsSideBar";
import FirmwareHistorySideBar from "./charger-sideBars/FirmwareHistorySideBar";
import Popup from "../../../components/Popup";
import ChargersExportCSV from "./exportCSV";
import { chargingModeEnum, firmwareStatusEnum } from "../CHDEnum";
import InfoIcon from "@mui/icons-material/Info";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import CableOutlinedIcon from "@mui/icons-material/CableOutlined";
import FindReplaceIcon from "@mui/icons-material/FindReplace";
import { useHistory } from "react-router-dom";
import { routePaths } from "../../../constants/RoutePaths";
import "./../ChargerHealth.scss";
import ChargerDetailDrawer from "../../../components/SideDrawerForChargerDetails";
import { PrivilegedComponent } from "../../../utils/PrivilegedComponent";
import EditChargerSideBar from "./charger-sideBars/EditChargerSideBar";
import NewCommonFilterDrawer from "../../../components/NewSideFIlterDrawer";
import DeleteConfirmDialog from "../../../components/NewDeleteConfirmDialog";
import AlertDialog from "../../../components/NewAlertDialog";
import {
  apiProvider,
  indexedEndPoints,
} from "../../../services/api/utilities/provider";

const useStyles = makeStyles((theme) => ({
  bar: {
    height: "100%",
    "&.low": {
      backgroundColor: "#f44336",
    },
    "&.medium": {
      backgroundColor: "#efbb5aa3",
    },
    "&.high": {
      backgroundColor: "#088208a3",
    },
  },
}));

const colors = { Enabled: "#00765E", Capable: "#22539B", Incapable: "#18191E" };
const backgrounds = {
  Enabled: "#E5F1EF",
  Capable: "#E7EBF3",
  Incapable: "#F0F1F8",
};

const rfidColors = (rfidObj) => {
  if (rfidObj?.rfidConfigMode && rfidObj?.isConfigMatched == false) {
    return "#916D1F";
  } else {
    return colors[rfidObj?.status];
  }
};

const rfidBGColor = (rfidObj) => {
  if (rfidObj?.rfidConfigMode && rfidObj?.isConfigMatched == false) {
    return "#FEF8EB";
  } else {
    return backgrounds[rfidObj?.status];
  }
};

const headCells = [
  {
    id: "chargerName",
    label: "Charger name",
    styles: {
      position: "sticky",
      zIndex: 801,
      left: "0px",
      boxShadow: "2px 0px 7px rgba(0, 0, 0, 0.0867024)",
    },
  },
  {
    id: "vendorManufacturer",
    label: "Manufacturer (OEM)",
    styles: {
      position: "sticky",
      zIndex: 800,
      left: "173px",
      boxShadow: "2px 0px 7px rgba(0, 0, 0, 0.0867024)",
    },
  },
  { id: "vendorModel", label: "Model (OEM)" },
  {
    id: "chargerManufacturer",
    label: "Sold as manufacturer",
  },
  { id: "chargerModel", label: "Sold as model" },
  { id: "serialNumber", label: "ID (Serial number)" },
  { id: "accountName", label: "Account" },
  { id: "depotName", label: "Depot" },

  { id: "connectivityStatus", label: "Connectivity status" },
  { id: "chargeports", label: "Ports" },
  { id: "networkType", label: "Network" },
  { id: "firmwareVersion", label: "Firmware version" },
  { id: "firmwareStatus", label: "Firmware status" },
  { id: "chargingMode", label: "Charging mode" },
  { id: "chargingStatus", label: "Charging status" },
  {
    id: "chargerRfidStatus",
    label: (
      <div className="rfidStatus">
        <p>RFID status </p>
        <Tooltip
          arrow
          TransitionComponent={Zoom}
          title=<ul>
            <li>
              <b>Enabled:</b> This charger currently requires the tap of an
              authorized RFID card to start dispensing energy in a charge
              session.
            </li>
            <li>
              <b>Capable:</b> This charger does have a RFID reader, and will be
              RFID-enabled when the RFID controls are enabled for the profile.
            </li>
            <li>
              <b>Incapable:</b> This charger does not have a RFID reader, and
              will not be RFID-enabled, even if the profile has RFID access
              controls enabled.
            </li>
          </ul>
        >
          <InfoIcon fontSize="small" className="rfidStatusInfoIcon" />
        </Tooltip>
      </div>
    ),
  },
  { disableSorting: true, id: "action", label: "Action" },
];

const Rows = React.memo((props) => {
  const {
    item,
    toggleDetailDrawer,
    toggleChargerDetailDrawer,
    setConfirmDialog,
    setDeleteConfirmDialog,
    setAlertConfirmDialog,
    registerCharger,
    replaceCharger,
    deleteCharger,
    ProgressBar,
  } = props;
  const classes = useStyles();

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <TableRow hover={true} className="chargerDetailsTable">
        <TableCell
          id={"charger_name_cell" + item?.chargerId}
          align="center"
          className="chargerNameCell"
        >
          <a
            onClick={() => {
              toggleChargerDetailDrawer(true, "Details", item);
            }}
            className="chargerNameLink"
          >
            {item.chargerName || titleLabels.NOT_AVAILABLE}
          </a>
        </TableCell>
        <TableCell align="center" className="manufacturerCell">
          {item.vendorManufacturer || titleLabels.NOT_AVAILABLE}
        </TableCell>
        <TableCell>{item.vendorModel || titleLabels.NOT_AVAILABLE}</TableCell>
        <TableCell>
          {item.chargerManufacturer || titleLabels.NOT_AVAILABLE}
        </TableCell>
        <TableCell>{item.chargerModel || titleLabels.NOT_AVAILABLE}</TableCell>
        <TableCell>{item.serialNumber || titleLabels.NOT_AVAILABLE}</TableCell>
        <TableCell>{item.accountName || titleLabels.NOT_AVAILABLE}</TableCell>
        <TableCell>{item.depotName || titleLabels.NOT_AVAILABLE}</TableCell>

        <TableCell>
          <div className="connectivityStatusCell">
            <div
              style={{
                padding:
                  item.connectivityStatus === "Connected"
                    ? "0px 60px 5px 0px"
                    : "0px 30px 5px 0px",
              }}
            >
              {item.connectivityStatus || titleLabels.NOT_AVAILABLE}
            </div>
            {item.chargeports.length > 0 && (
              <ProgressBar
                connectedPorts={item.connectedChargerPortsCount}
                totalPorts={item.chargeports.length}
              />
            )}
          </div>
        </TableCell>
        <TableCell align="center">
          <Chip
            label={
              item.chargeports.length === 0
                ? 0
                : item.chargeports.length
                ? item.chargeports.length
                : titleLabels.NOT_AVAILABLE
            }
            className="chargePortsCell"
          />
        </TableCell>
        <TableCell>{item.networkType || titleLabels.NOT_AVAILABLE}</TableCell>
        <TableCell>
          <a
            onClick={() =>
              toggleDetailDrawer(true, "Firmware History", item, "")
            }
            className="firmwareHistoryCell"
          >
            {item.firmwareVersion || titleLabels.NOT_AVAILABLE}
          </a>
        </TableCell>
        <TableCell>
          <div
            className={
              item.firmwareStatus === "upToDate"
                ? "up_to_date"
                : item.firmwareStatus === "updateAvailable"
                ? "update_available"
                : "NA_or_failed"
            }
          >
            {firmwareStatusEnum[item.firmwareStatus] ||
              titleLabels.NOT_AVAILABLE}
          </div>
        </TableCell>
        <TableCell>
          {chargingModeEnum[item.chargingMode] || titleLabels.NOT_AVAILABLE}
        </TableCell>
        <TableCell>
          {item.chargingStatus || titleLabels.NOT_AVAILABLE}
        </TableCell>
        <TableCell>
          <Chip
            avatar={
              item?.rfidDetails?.rfidConfigMode &&
              item?.rfidDetails?.isConfigMatched == false && (
                <WarningIcon fontSize="small" style={{ color: "#916D1F" }} />
              )
            }
            label={item?.rfidDetails?.status}
            style={{
              color: rfidColors(item?.rfidDetails),
              background: rfidBGColor(item?.rfidDetails),
            }}
          />
          {item?.rfidDetails?.rfidConfigMode &&
            item?.rfidDetails?.isConfigMatched == false && (
              <p className="RFID_color_sub_title">
                {item?.rfidDetails?.rfidConfigMode} config needed
              </p>
            )}
        </TableCell>
        <TableCell>
          <Tooltip
            arrow
            TransitionComponent={Zoom}
            title="View last known OCPP settings"
          >
            <span>
              <Controls.ActionButton
                disabled={!item?.lastKnownOcppSettings}
                color="primary"
                onClick={() =>
                  toggleDetailDrawer(true, "Last known OCPP settings", item, "")
                }
              >
                <InfoOutlinedIcon fontSize="small" />
              </Controls.ActionButton>
            </span>
          </Tooltip>
          <Tooltip
            arrow
            TransitionComponent={Zoom}
            title={
              item?.registeredToChargerMicroservice === true
                ? "Charger already registered"
                : item?.isHomeChargingDepot == false
                ? "Not a Home Charger"
                : "Register Charger"
            }
          >
            <span>
              <Controls.ActionButton
                disabled={
                  item?.registeredToChargerMicroservice === true ||
                  item?.isHomeChargingDepot == false
                }
                color="primary"
                onClick={() =>
                  setConfirmDialog({
                    isOpen: true,
                    title: "Are you sure ?",
                    subTitle:
                      "You want to register this Charger to Charger Microservice",
                    type: "secondary",
                    onConfirm: () => registerCharger(item),
                  })
                }
              >
                <CableOutlinedIcon fontSize="small" />
              </Controls.ActionButton>
            </span>
          </Tooltip>
          <PrivilegedComponent
            permission="Replace Charger"
            module="chargerHealth"
          >
            <Tooltip
              arrow
              TransitionComponent={Zoom}
              title={
                item?.isChargerReplaced
                  ? "Charger already replaced"
                  : "Mark as replacement charger. This will prevent generation of an onboarding fee for the associated ports."
              }
            >
              <span>
                <Controls.ActionButton
                  disabled={item?.isChargerReplaced}
                  color="primary"
                  onClick={() =>
                    setConfirmDialog({
                      isOpen: true,
                      title: "Are you sure ?",
                      subTitle:
                        "This charger is a replacement for an existing Ford Pro charger under warranty?",
                      type: "secondary",
                      onConfirm: () => replaceCharger(item),
                    })
                  }
                >
                  <FindReplaceIcon
                    fontSize="small"
                    color={item?.isChargerReplaced ? "#1DB954" : "#6A6B71"}
                  />
                </Controls.ActionButton>
              </span>
            </Tooltip>
          </PrivilegedComponent>
          <PrivilegedComponent
            permission="Manage Charger"
            module="chargerHealth"
          >
            <Tooltip
              arrow
              TransitionComponent={Zoom}
              title={"Edit or Delete Charger"}
            >
              <span>
                <Controls.ActionButton color="primary" onClick={handleClick}>
                  <MoreVertIcon fontSize="small" color={"#1DB954"} />
                </Controls.ActionButton>
              </span>
            </Tooltip>
          </PrivilegedComponent>
          <Menu
            id="basic-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            MenuListProps={{
              "aria-labelledby": "basic-button",
            }}
          >
            <MenuItem
              onClick={() => {
                toggleDetailDrawer(true, "EDIT CHARGER", item, "EDIT"),
                  handleClose();
              }}
            >
              Edit charger details
            </MenuItem>
            <MenuItem
              onClick={() => {
                if (item.chargingStatus === "Charging") {
                  setAlertConfirmDialog({
                    isOpen: true,
                    title:
                      "This charger cannot be deleted while a vehicle is plugged into it.",
                    subTitle:
                      "One or more ports connected to this charger is plugged into a vehicle. To delete this charger, please unplug all vehicles from its ports and try again.",
                    type: "secondary",
                  });
                } else {
                  setDeleteConfirmDialog({
                    isOpen: true,
                    title: "Delete Charger",
                    subTitle:
                      "This charger is a replacement for an existing Ford Pro charger under warranty?The associated ports will also be deleted. Are you sure you want to delete the selected charger?",
                    type: "secondary",
                    onConfirm: () => deleteCharger(item),
                  });
                }

                handleClose();
              }}
            >
              Delete charger
            </MenuItem>
          </Menu>
        </TableCell>
      </TableRow>
    </>
  );
});

const ToolbarMemoised = React.memo(
  ({
    handleSearch,
    className,
    toggleDrawer,
    setOpenPopup,
    recordsAfterPagingAndSorting,
    isFiltered,
    toggleDetailDrawer,
    data,
  }) => {
    const history = useHistory();
    const options = ["None", "Atria", "Callisto", "Dione"];
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
      setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
      setAnchorEl(null);
    };
    return (
      <Toolbar className="table_toolbar">
        <Controls.Input
          id="charger_details_search"
          label={"Search"}
          className={className}
          inputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
          }}
          onChange={handleSearch}
        />
        <Grid sm item />

        <IconButton
          aria-label="more"
          id="long-button"
          className="moreVertIcon_btn"
          aria-controls={open ? "long-menu" : undefined}
          aria-expanded={open ? "true" : undefined}
          aria-haspopup="true"
          onClick={handleClick}
        >
          <MoreVertIcon />
        </IconButton>
        <Menu
          id="long-menu"
          MenuListProps={{
            "aria-labelledby": "long-button",
          }}
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          PaperProps={{
            style: {
              width: "20ch",
            },
          }}
        >
          <MenuItem
            key="Export CSV"
            id="export_csv_button"
            disabled={data.length === 0}
            onClick={() => {
              setOpenPopup({
                isOpen: true,
                title: "Export CSV",
                child: "exportCSV",
                // item: item,
              });
            }}
          >
            Export CSV
          </MenuItem>
          <MenuItem
            key="Charger History"
            id="charger_history_button"
            onClick={() => history.push(routePaths.CHARGER_HISTORY)}
          >
            Charger History
          </MenuItem>
          <MenuItem
            key="Registration History"
            id="registration_history_button"
            onClick={() =>
              history.push(routePaths.CHARGER_REGISTRATION_HISTORY)
            }
          >
            Registration History
          </MenuItem>
          <MenuItem
            key="Replacement History"
            id="replacement_history_button"
            onClick={() => history.push(routePaths.CHARGER_REPLACEMENT_HISTORY)}
          >
            Replacement History
          </MenuItem>
        </Menu>

        <Badge
          color="primary"
          variant="dot"
          invisible={isFiltered ? false : true}
        >
          <Controls.Button
            text="Filter"
            id="charger_details_filter"
            variant="outlined"
            startIcon={
              <TuneIcon
                style={{
                  color: isFiltered ? "#2770D8" : "#A3B6C7",
                }}
              />
            }
            onClick={() => {
              toggleDrawer(true, "FILTER", {});
            }}
            className="filter"
            style={{
              border: isFiltered ? "1px solid #2770D8" : "1px solid #A3B6C7",
            }}
          />
        </Badge>
        <PrivilegedComponent permission="Manage Charger" module="chargerHealth">
          <Controls.Button
            text="Add Charger"
            id="add_charger_btn"
            variant="outlined"
            startIcon={<AddIcon />}
            onClick={() => {
              toggleDetailDrawer(true, "ADD CHARGER", {}, "ADD"), handleClose();
            }}
          />
        </PrivilegedComponent>
      </Toolbar>
    );
  }
);

const RowsMemoised = React.memo((props) => {
  return <Rows {...props} />;
});

const TableBodyMemoised = React.memo(
  ({
    data,
    toggleDetailDrawer,
    toggleChargerDetailDrawer,
    setConfirmDialog,
    setDeleteConfirmDialog,
    setAlertConfirmDialog,
    registerCharger,
    replaceCharger,
    deleteCharger,
    ProgressBar,
  }) => {
    return (
      <TableBody>
        {data.map((item, index) => (
          <RowsMemoised
            item={item}
            toggleDetailDrawer={toggleDetailDrawer}
            toggleChargerDetailDrawer={toggleChargerDetailDrawer}
            setConfirmDialog={setConfirmDialog}
            setDeleteConfirmDialog={setDeleteConfirmDialog}
            setAlertConfirmDialog={setAlertConfirmDialog}
            registerCharger={registerCharger}
            replaceCharger={replaceCharger}
            deleteCharger={deleteCharger}
            ProgressBar={ProgressBar}
          />
        ))}
      </TableBody>
    );
  },
  function (prevProps, nextProps) {
    if (isEqual(prevProps.data, nextProps.data)) {
      return true;
    }
    return false;
  }
);

const ChargerFirmwareTable = (props) => {
  const {
    allChargers,
    toggleDrawer,
    setConfirmDialog,
    registerCharger,
    replaceCharger,
    isFiltered,
    allMakes,
    allAccounts,
    setToast,
    getAllChargersAndFirmware,
    filterObject,
    setLoading,
  } = props;
  const classes = useStyles();
  const [filterFn, setFilterFn] = React.useState({ fn: (items) => items });
  const toggleDetailDrawer = (openClose, title, item, forWhat) => {
    setDrawerOC({
      isOpen: openClose,
      title: title,
      data: item,
      reason: forWhat,
    });
  };
  const { CHARGER_PROFILE } = indexedEndPoints;
  const toggleChargerDetailDrawer = (openClose, title, item) => {
    setChargerDrawerOC({ isOpen: openClose, title: title, data: item });
  };
  const [TableContainer, setTableContainer] = React.useState("table");
  const [TableHead, setTableHead] = React.useState("thead");
  const [TablePagination, setTablePagination] = React.useState(() => <></>);
  const [recordsAfterPagingAndSorting, setRecordsAfterPagingAndSorting] =
    React.useState([]);
  const [DrawerOC, setDrawerOC] = React.useState({
    isOpen: false,
    title: "",
    data: {},
  });

  const [DraweChargerDetails, setChargerDrawerOC] = React.useState({
    isOpen: false,
    title: "",
    data: {},
  });

  const [deleteConfirmDialog, setDeleteConfirmDialog] = React.useState({
    isOpen: false,
    type: "primary",
  });

  const [alertConfirmDialog, setAlertConfirmDialog] = React.useState({
    isOpen: false,
    type: "primary",
  });

  const [openPopup, setOpenPopup] = React.useState({
    isOpen: false,
    title: "",
    child: "",
    item: {},
  });
  useEffect(() => {
    allChargers.forEach((element) => {
      let connectedCount = 0;
      element.chargeports.forEach((chargePort) => {
        if (chargePort.status === "Connected") connectedCount++;
      });
      element.connectedChargerPortsCount = connectedCount;
    });
  }, [allChargers]);
  const result = useTable(allChargers, headCells, filterFn, true);
  const ProgressBar = React.memo(function ProgressBar(props) {
    const { connectedPorts, totalPorts } = props;
    const valueInPercent = (connectedPorts / totalPorts) * 100;
    const classes = useStyles();
    return (
      <div className="barElement">
        <div className="value">{`${connectedPorts}/${totalPorts}`}</div>
        <div
          className={clsx(classes.bar, {
            low: valueInPercent < 30,
            medium: valueInPercent >= 30 && valueInPercent < 100,
            high: valueInPercent === 100,
          })}
          style={{ maxWidth: `${valueInPercent}%` }}
        />
      </div>
    );
  });
  React.useEffect(() => {
    setTableContainer(() => result.tableContainer);
  }, [result.tableContainer]);
  React.useEffect(() => {
    setTableHead(() => result.tableHead);
  }, [result.tableHead]);
  React.useEffect(() => {
    setTablePagination(result.tablePagination);
  }, [result.tablePagination]);
  React.useEffect(() => {
    setRecordsAfterPagingAndSorting(result.recordsAfterPagingAndSorting);
  }, [result.recordsAfterPagingAndSorting]);
  const handleSearch = (e) => {
    setFilterFn({
      fn: (items) => {
        if (e.target.value?.trim() === "") return items;
        else
          return items.filter((row) =>
            Object.values(row)?.some((val) =>
              val
                ?.toString()
                .toLowerCase()
                .includes(e.target.value?.trim().toLowerCase())
            )
          );
      },
    });
  };

  const deleteCharger = useCallback(async (item) => {
    const response = await apiProvider.del(
      `${CHARGER_PROFILE}/${item.depotId}/${item.objectId}`,
      { chargerId: item.chargerId }
    );

    if (response.statusCode >= 200 && response.statusCode <= 299) {
      setToast({
        isOpen: true,
        message: "Charger Deleted",
        type: "success",
      });
      getAllChargersAndFirmware();
    } else {
      setToast({
        isOpen: true,
        message: "Charger deleting failed",
        type: "error",
      });
    }
    setDeleteConfirmDialog({ ...deleteConfirmDialog, isOpen: false });
  }, []);

  return (
    <>
      <ToolbarMemoised
        handleSearch={handleSearch}
        className="searchInput"
        toggleDrawer={toggleDrawer}
        setOpenPopup={setOpenPopup}
        isFiltered={isFiltered}
        recordsAfterPagingAndSorting={recordsAfterPagingAndSorting}
        toggleDetailDrawer={toggleDetailDrawer}
        data={result?.searchData}
      />

      <TableContainer>
        <TableHead />
        <React.Fragment>
          <TableBodyMemoised
            data={recordsAfterPagingAndSorting}
            toggleDetailDrawer={toggleDetailDrawer}
            toggleChargerDetailDrawer={toggleChargerDetailDrawer}
            setConfirmDialog={setConfirmDialog}
            setDeleteConfirmDialog={setDeleteConfirmDialog}
            setAlertConfirmDialog={setAlertConfirmDialog}
            registerCharger={registerCharger}
            replaceCharger={replaceCharger}
            deleteCharger={deleteCharger}
            ProgressBar={ProgressBar}
          />
        </React.Fragment>
      </TableContainer>
      {recordsAfterPagingAndSorting.length === 0 && (
        <div className="no_data_in_table">
          <div className="img_title">
            <img src={no_Data} alt="No data available" />
            <p className="no_data_title">No data available</p>
          </div>
        </div>
      )}
      {TablePagination}

      <CommonDrawer DrawerOC={DrawerOC} toggleDrawer={toggleDetailDrawer}>
        {DrawerOC.title === "Firmware History" && (
          <FirmwareHistorySideBar DrawerOC={DrawerOC} />
        )}
        {DrawerOC.title === "Last known OCPP settings" && (
          <div>
            <div className="single_details">
              <p className="title">Charger Name</p>
              <p className="title_value">{DrawerOC.data.chargerName}</p>
            </div>
            <Controls.CopyToClipboard
              name={DrawerOC?.data?.lastKnownOcppSettings}
              isJson={true}
              setToast={setToast}
              max_Height={"600px"}
            />
          </div>
        )}
      </CommonDrawer>
      {(DrawerOC.title === "EDIT CHARGER" ||
        DrawerOC.title === "ADD CHARGER") && (
        <NewCommonFilterDrawer
          DrawerOC={DrawerOC}
          toggleDrawer={toggleDetailDrawer}
        >
          <EditChargerSideBar
            DrawerOC={DrawerOC}
            allMakes={allMakes}
            allAccounts={allAccounts}
            setToast={setToast}
            setLoading={setLoading}
            getAllChargersAndFirmware={getAllChargersAndFirmware}
            filterObject={filterObject}
            toggleDetailDrawer={toggleDetailDrawer}
          />
        </NewCommonFilterDrawer>
      )}
      <ChargerDetailDrawer
        DraweChargerDetails={DraweChargerDetails}
        toggleDrawer={toggleChargerDetailDrawer}
      >
        {DraweChargerDetails.title === "Details" && (
          <ChargerdetailsSideBar
            DraweChargerDetails={DraweChargerDetails}
            setLoading={setLoading}
          />
        )}
      </ChargerDetailDrawer>

      {deleteConfirmDialog.isOpen && (
        <DeleteConfirmDialog
          deleteConfirmDialog={deleteConfirmDialog}
          setDeleteConfirmDialog={setDeleteConfirmDialog}
        />
      )}

      {alertConfirmDialog.isOpen && (
        <AlertDialog
          alertConfirmDialog={alertConfirmDialog}
          setAlertConfirmDialog={setAlertConfirmDialog}
        />
      )}

      <Popup openPopup={openPopup} setOpenPopup={setOpenPopup}>
        {openPopup.child === "exportCSV" ? (
          <ChargersExportCSV
            setOpenPopup={setOpenPopup}
            recordsAfterPagingAndSorting={result?.searchData?.map((row) => ({
              ...row,
              vendorManufacturer:
                row.vendorManufacturer || titleLabels.NOT_AVAILABLE,
              vendorModel: row.vendorModel || titleLabels.NOT_AVAILABLE,
              deratedMaxAmps:
                row.deratedMaxAmps >= 0
                  ? row.deratedMaxAmps
                  : titleLabels.NOT_AVAILABLE,
              chargingMode:
                chargingModeEnum[row.chargingMode] || titleLabels.NOT_AVAILABLE,
              firmwareStatus:
                firmwareStatusEnum[row.firmwareStatus] ||
                titleLabels.NOT_AVAILABLE,
              portsCount:
                row?.chargeports?.length >= 0
                  ? row?.chargeports?.length
                  : titleLabels.NOT_AVAILABLE,
              firstCommunication: row.firstCommunication
                ? row.firstCommunication?.replace("GMT", "UTC")
                : titleLabels.NOT_AVAILABLE,
              billingActivatedAt: row.billingActivatedAt
                ? row.billingActivatedAt.replace("GMT", "UTC")
                : titleLabels.NOT_AVAILABLE,
              lastAssemblyCheckinTime: row.lastAssemblyCheckinTime
                ? row.lastAssemblyCheckinTime.replace("GMT", "UTC")
                : titleLabels.NOT_AVAILABLE,
            }))}
          />
        ) : null}
      </Popup>
    </>
  );
};

export default ChargerFirmwareTable;
