import React from "react";
import DeleteIcon from "@mui/icons-material/Delete";
import Controls from "../../components/controls/Controls";
import { titleLabels } from "../../constants/TitleLabels";
import "./services.scss";

export default function ScheduledServicesDrawer({ drawerObj }) {
  return (
    <div className="chargerModelsDrawer">
      <div className="chargeDetailsSideDrawer">
        <div className="charger_details_section">
          <div className="charge_name_section">
            <p>OPERATION DETAILS</p>
            <Controls.Button
              text="Cancel"
              variant="outlined"
              startIcon={<DeleteIcon className="filter_icon" />}
              onClick={() => {}}
              id="cancelScheduledServices"
            />
          </div>
          <div className="all_details">
            <div className="single_detail">
              <p className="c_title">Operation:</p>
              <p className="description">
                {drawerObj.operation || titleLabels.NOT_AVAILABLE}
              </p>
            </div>
            <div className="single_detail">
              <p className="c_title">Company:</p>
              <p className="description">
                {drawerObj.accountName || titleLabels.NOT_AVAILABLE}
              </p>
            </div>
            <div className="single_detail">
              <p className="c_title">Depot:</p>
              <p className="description">
                {drawerObj.depotName || titleLabels.NOT_AVAILABLE}
              </p>
            </div>
            <div className="single_detail">
              <p className="c_title">Charger manufacturer:</p>
              <p className="description">
                {drawerObj.meta?.manufacturer || titleLabels.NOT_AVAILABLE}
              </p>
            </div>
            <div className="single_detail">
              <p className="c_title">Charger model:</p>
              <p className="description">
                {drawerObj.meta?.model || titleLabels.NOT_AVAILABLE}
              </p>
            </div>
            <div className="single_detail">
              <p className="c_title">Updates on:</p>
              <p className="description">
                {drawerObj.meta?.scheduledAt || titleLabels.NOT_AVAILABLE}
              </p>
            </div>
            <div className="single_detail">
              <p className="c_title">Firmware version:</p>
              <p className="description">
                {drawerObj.meta?.firmwareVersion || titleLabels.NOT_AVAILABLE}
              </p>
            </div>
          </div>
          <div className="charger_details_section">
            <div className="charger_heading">
              <p>CHARGERS SELECTED</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
