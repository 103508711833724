import React from "react";
import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  Font,
} from "@react-pdf/renderer";

Font.registerEmojiSource({
  format: "png",
  url: "https://twemoji.maxcdn.com/2/72x72/",
});

// Create styles
const styles = StyleSheet.create({
  body: {
    paddingTop: 35,
    paddingBottom: 65,
    paddingHorizontal: 35,
  },
  title: {
    fontSize: 24,
    textAlign: "center",
  },
  date: {
    fontSize: 12,
    textAlign: "center",
    marginBottom: 25,
    color: "#02818B",
  },
  lastCheck: {
    fontSize: 12,
    textAlign: "justify",
    marginTop: 5,
    marginLeft: 14,
    color: "#917002",
  },
  subtitle: {
    fontSize: 18,
    margin: 12,
  },
  text: {
    marginLeft: 15,
    marginBottom: 10,
    marginTop: 5,
    fontSize: 14,
    textAlign: "justify",
  },
  link: {
    color: "#004FCA",
  },
  pageNumber: {
    position: "absolute",
    fontSize: 12,
    bottom: 30,
    left: 0,
    right: 0,
    textAlign: "center",
    color: "grey",
  },
  info: {
    position: "absolute",
    fontSize: 10,
    bottom: 30,
    left: 450,
    textAlign: "center",
    color: "gray",
  },
  comments: {
    margin: 12,
    fontSize: 14,
    textAlign: "justify",
    fontFamily: "Times-Roman",
  },
});

// create Document Component
export default function IntegrationTestReportPDF(props) {
  const { model, data, updatedAt } = props;

  return (
    <Document>
      <Page size="A4" style={styles.body}>
        <Text style={styles.title}>Charger Integration Test Report</Text>
        <Text style={{ ...styles.text, paddingVertical: "10px" }}>
          Charger Model: {model}
        </Text>
        <Text style={styles.date}>
          {updatedAt ? `Report Generated At: ${updatedAt}` : ""}
        </Text>
        <Text style={styles.subtitle}>Test Cases</Text>
        {data?.map((row, ind) => (
          <div
            key={ind}
            style={{
              marginVertical: "5px",
              border: row?.testCases?.some(
                (item) => item.status === true || item.status === false
              )
                ? "0.5px solid black"
                : "none",
            }}
          >
            {row?.testCases?.some(
              (item) => item.status === true || item.status === false
            ) && (
              <Text style={{ ...styles.subtitle, fontFamily: "Times-Bold" }}>
                {`Phase ${ind + 1}: ${row.testSuiteName}`}
              </Text>
            )}
            {row?.testCases
              ?.filter((tc) => tc.status === true || tc.status === false)
              ?.map((item, i) => (
                <div>
                  <div key={`testCase-${ind}-${i}`}>
                    <View style={{ flexDirection: "row" }}>
                      <Text
                        style={{ marginHorizontal: "5px", paddingVertical: 3 }}
                      >
                        •
                      </Text>
                      <Text
                        style={{
                          ...styles.text,

                          width: "80%",
                          marginLeft: "5px",
                        }}
                      >{`${item.name}`}</Text>
                      <Text
                        style={{
                          ...styles.text,
                          color: item.status ? "green" : "red",
                          width: "10%",
                        }}
                      >{`${item.status ? "PASS" : "FAIL"}`}</Text>
                    </View>
                  </div>
                  {item?.observation?.length > 0 && (
                    <Text
                      style={{ ...styles.text, fontFamily: "Times-Italic" }}
                    >
                      {`Observation: ${item.observation}`}
                    </Text>
                  )}
                </div>
              ))}
          </div>
        ))}
        <Text
          style={styles.pageNumber}
          render={({ pageNumber, totalPages }) =>
            `${pageNumber} / ${totalPages}`
          }
          fixed
        />
        <Text style={styles.info} fixed>
          Powered by Electriphi Inc.
        </Text>
      </Page>
    </Document>
  );
}
