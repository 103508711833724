export const dateFormats = [
  {
    dateFormat: "dd-mmm-yyyy"
  }
  //   {
  //     dateFormat: "yyyy-MM-dd"
  //   },
  //   {
  //     dateFormat: "dd/MM/yyyy"
  //   },
  //   {
  //     dateFormat: "dd/MM/yyyy"
  //   },
  //   {
  //     dateFormat: "d/MM/yyyy"
  //   },
  //   {
  //     dateFormat: "dd.MM.yyyy"
  //   },
  //   {
  //     dateFormat: "d/MM/yyyy"
  //   },
  //   {
  //     dateFormat: "d/MM/yyyy"
  //   },
  //   {
  //     dateFormat: "yyyy-M-d"
  //   },
  //   {
  //     dateFormat: "dd/MM/yyyy"
  //   },
  //   {
  //     dateFormat: "yyyy-MM-dd"
  //   },
  //   {
  //     dateFormat: "d.M.yyyy"
  //   },
  //   {
  //     dateFormat: "dd-MM-yyyy"
  //   },
  //   {
  //     dateFormat: "dd/MM/yyyy"
  //   },
  //   {
  //     dateFormat: "yyyy-MM-dd"
  //   },
  //   {
  //     dateFormat: "dd/MM/yyyy"
  //   },
  //   {
  //     dateFormat: "dd.MM.yyyy"
  //   },
  //   {
  //     dateFormat: "dd.MM.yyyy"
  //   },
  //   {
  //     dateFormat: "dd.MM.yyyy"
  //   },
  //   {
  //     dateFormat: "dd-MM-yyyy"
  //   },
  //   {
  //     dateFormat: "yyyy-M-d"
  //   },
  //   {
  //     dateFormat: "d/MM/yyyy"
  //   },
  //   {
  //     dateFormat: "dd/MM/yyyy"
  //   },
  //   {
  //     dateFormat: "dd/MM/yyyy"
  //   },
  //   {
  //     dateFormat: "d.M.yyyy"
  //   },
  //   {
  //     dateFormat: "dd.MM.yyyy"
  //   },
  //   {
  //     dateFormat: "dd-MM-yyyy"
  //   },
  //   {
  //     dateFormat: "MM/dd/yyyy"
  //   },
  //   {
  //     dateFormat: "dd/MM/yyyy"
  //   },
  //   {
  //     dateFormat: "dd/MM/yyyy"
  //   },
  //   {
  //     dateFormat: "dd/MM/yyyy"
  //   },
  //   {
  //     dateFormat: "d/MM/yyyy"
  //   },
  //   {
  //     dateFormat: "dd/MM/yyyy"
  //   },
  //   {
  //     dateFormat: "d.MM.yyyy"
  //   },
  //   {
  //     dateFormat: "d.M.yyyy"
  //   },
  //   {
  //     dateFormat: "dd/MM/yyyy"
  //   },
  //   {
  //     dateFormat: "dd/MM/yyyy"
  //   },
  //   {
  //     dateFormat: "d/M/yyyy"
  //   },
  //   {
  //     dateFormat: "d/MM/yyyy"
  //   },
  //   {
  //     dateFormat: "yyyy年M月d日"
  //   },
  //   {
  //     dateFormat: "MM-dd-yyyy"
  //   },
  //   {
  //     dateFormat: "dd.MM.yyyy."
  //   },
  //   {
  //     dateFormat: "yyyy.MM.dd."
  //   },
  //   {
  //     dateFormat: "dd/MM/yyyy"
  //   },
  //   {
  //     dateFormat: "३/६/१२"
  //   },
  //   {
  //     dateFormat: "d/M/yyyy"
  //   },
  //   {
  //     dateFormat: "dd/MM/yyyy"
  //   },
  //   {
  //     dateFormat: "dd/MM/yyyy"
  //   },
  //   {
  //     dateFormat: "dd/MM/yyyy"
  //   },
  //   {
  //     dateFormat: "d.M.yyyy"
  //   },
  //   {
  //     dateFormat: "dd/MM/yyyy"
  //   },
  //   {
  //     dateFormat: "dd/MM/yyyy"
  //   },
  //   {
  //     dateFormat: "dd/MM/yyyy"
  //   },
  //   {
  //     dateFormat: "yyyy/MM/dd"
  //   },
  //   {
  //     dateFormat: "H24.MM.dd"
  //   },
  //   {
  //     dateFormat: "yyyy. M. d"
  //   },
  //   {
  //     dateFormat: "dd/MM/yyyy"
  //   },
  //   {
  //     dateFormat: "dd/MM/yyyy"
  //   },
  //   {
  //     dateFormat: "dd/MM/yyyy"
  //   },
  //   {
  //     dateFormat: "yyyy.M.d"
  //   },
  //   {
  //     dateFormat: "dd/MM/yyyy"
  //   },
  //   {
  //     dateFormat: "dd.MM.yyyy"
  //   },
  //   {
  //     dateFormat: "yyyy.d.M"
  //   },
  //   {
  //     dateFormat: "dd/MM/yyyy"
  //   },
  //   {
  //     dateFormat: "d/MM/yyyy"
  //   },
  //   {
  //     dateFormat: "d.M.yyyy"
  //   },
  //   {
  //     dateFormat: "dd/MM/yyyy"
  //   },
  //   {
  //     dateFormat: "dd/MM/yyyy"
  //   },
  //   {
  //     dateFormat: "d.M.yyyy."
  //   },
  //   {
  //     dateFormat: "dd/MM/yyyy"
  //   },
  //   {
  //     dateFormat: "MM-dd-yyyy"
  //   },
  //   {
  //     dateFormat: "d-M-yyyy"
  //   },
  //   {
  //     dateFormat: "dd.MM.yyyy"
  //   },
  //   {
  //     dateFormat: "dd.MM.yyyy"
  //   },
  //   {
  //     dateFormat: "d/MM/yyyy"
  //   },
  //   {
  //     dateFormat: "dd/MM/yyyy"
  //   },
  //   {
  //     dateFormat: "MM/dd/yyyy"
  //   },
  //   {
  //     dateFormat: "dd/MM/yyyy"
  //   },
  //   {
  //     dateFormat: "M/d/yyyy"
  //   },
  //   {
  //     dateFormat: "dd.MM.yyyy"
  //   },
  //   {
  //     dateFormat: "MM-dd-yyyy"
  //   },
  //   {
  //     dateFormat: "dd-MM-yyyy"
  //   },
  //   {
  //     dateFormat: "dd/MM/yyyy"
  //   },
  //   {
  //     dateFormat: "dd/MM/yyyy"
  //   },
  //   {
  //     dateFormat: "dd.MM.yyyy"
  //   },
  //   {
  //     dateFormat: "dd.MM.yyyy"
  //   },
  //   {
  //     dateFormat: "dd/MM/yyyy"
  //   },
  //   {
  //     dateFormat: "d.M.yyyy."
  //   },
  //   {
  //     dateFormat: "dd/MM/yyyy"
  //   },
  //   {
  //     dateFormat: "dd/MM/yyyy"
  //   },
  //   {
  //     dateFormat: "M/d/yyyy"
  //   },
  //   {
  //     dateFormat: "MM-dd-yyyy"
  //   },
  //   {
  //     dateFormat: "d.M.yyyy."
  //   },
  //   {
  //     dateFormat: "d.M.yyyy"
  //   },
  //   {
  //     dateFormat: "d.M.yyyy"
  //   },
  //   {
  //     dateFormat: "yyyy-MM-dd"
  //   },
  //   {
  //     dateFormat: "dd/MM/yyyy"
  //   },
  //   {
  //     dateFormat: "d/M/2555"
  //   },
  //   {
  //     dateFormat: "๓/๖/๒๕๕๕"
  //   },
  //   {
  //     dateFormat: "dd/MM/yyyy"
  //   },
  //   {
  //     dateFormat: "dd.MM.yyyy"
  //   },
  //   {
  //     dateFormat: "yyyy/M/d"
  //   },
  //   {
  //     dateFormat: "dd.MM.yyyy"
  //   },
  //   {
  //     dateFormat: "dd/MM/yyyy"
  //   },
  //   {
  //     dateFormat: "M/d/yyyy"
  //   },
  //   {
  //     dateFormat: "M/d/yyyy"
  //   },
  //   {
  //     dateFormat: "dd/MM/yyyy"
  //   },
  //   {
  //     dateFormat: "dd/MM/yyyy"
  //   },
  //   {
  //     dateFormat: "dd/MM/yyyy"
  //   },
  //   {
  //     dateFormat: "yyyy/MM/dd"
  //   }
];
