import environment from "../../environment";
import {
  CHD_BASE_URL,
  apiProvider,
  indexedEndPoints,
} from "../../services/api/utilities/provider";
import { format } from "date-fns";

const {
  CHD_FAULTS_COUNT,
  CHD_OPEN_FAULTS,
  GET_FAULTS_DETAILS,
  GET_MANAGED_PORTS,
  GET_ALL_TROUBLESHOOT,
  UPDATE_FAULTS_STATUS,
  CHD_DOWNLOAD_REPORT,
  // CHD_TEMPERATURE,
  CHD_CONNECTIVITY_NOTES,
  CHD_FAULT_NOTES,
  CHARGERS,
  SOLD_AS_CHARGERS,
  CHARGER_UPTIME_METRICS,
  CHARGER_UTILIZATION,
  CHARGER_CONNECTIVITY_V2,
  CHARGER_UTILIZATION_HOURLY,
} = indexedEndPoints;

export const getOpenFaultsCount = async (filterObj = {}) =>
  await apiProvider.post(CHD_FAULTS_COUNT, filterObj);

export const getOpenFaults = async (filterObj = {}) =>
  await apiProvider.post(CHD_OPEN_FAULTS, filterObj);

export const getAllConnectivityDetails = async (filterObj = {}) =>
  await apiProvider.post(CHARGER_CONNECTIVITY_V2, filterObj);

export const getAllFaultsDetails = async (filterObj = {}) =>
  await apiProvider.post(GET_FAULTS_DETAILS, filterObj, CHD_BASE_URL);

export const getAllOemMakeAndModels = async () =>
  await apiProvider.getAll(CHARGERS);

export const getAllMakeAndModels = async () =>
  await apiProvider.getAll(
    SOLD_AS_CHARGERS,
    environment?.[process.env.REACT_APP_NODE_ENV]?.MASTER_DATA_DOMAIN,
    environment?.[process.env.REACT_APP_NODE_ENV]?.MASTER_DATA_XAPI_KEY
  );

// export const getManagedPorts = async () =>
//   await apiProvider.getAll(GET_MANAGED_PORTS);

export const getManagedPorts = async (body) =>
  body
    ? await apiProvider.post(GET_MANAGED_PORTS, body)
    : await apiProvider.post(GET_MANAGED_PORTS, {});

export const getFaultsTroubleShoot = async () =>
  await apiProvider.getAll(GET_ALL_TROUBLESHOOT);

export const updateFaultStatus = async (payload = {}) =>
  await apiProvider.put(UPDATE_FAULTS_STATUS, payload);

export const downloadCHDReport = async (payload) => {
  let dataSize = 0;
  return fetch(`${CHD_DOWNLOAD_REPORT}`, {
    method: "POST",
    body: JSON.stringify(payload),
    headers: apiProvider.getHeaders().headers,
  })
    .then(function (response) {
      if (response.status !== 200) {
        return "";
      } else {
        return response.blob();
      }
    })

    .then((data) => {
      dataSize = data.size;
      if (dataSize > 0) {
        var a = document.createElement("a");
        a.href = window.URL.createObjectURL(data);
        a.download = `Charger Health Data ${format(
          new Date(),
          "dd/MM/yyyy"
        )}.csv`;
        a.click();
        return dataSize;
      } else return 0;
    });
};
export const faultNotes = async () => apiProvider.getAll(CHD_FAULT_NOTES);

export const updateNotes = async (faultId, payload) =>
  apiProvider.put(`${CHD_FAULT_NOTES}/${faultId}`, payload);

export const bulkUpdateNotes = async (payload = {}) =>
  apiProvider.post(CHD_FAULT_NOTES, payload);

export const offlineNotes = async () =>
  apiProvider.getAll(CHD_CONNECTIVITY_NOTES);

export const updateOfflineNotes = async (connectivityId, payload) =>
  apiProvider.put(`${CHD_CONNECTIVITY_NOTES}/${connectivityId}`, payload);

// export const getChargerTemperature = async (body) =>
//   body
//     ? await apiProvider.post(CHD_TEMPERATURE, body)
//     : await apiProvider.post(CHD_TEMPERATURE, {});

// CHD_TEMPERATURE

export async function getChargerUptimeMetrics(period = "WEEKLY", filterData) {
  const result = await apiProvider.post(
    `${CHARGER_UPTIME_METRICS}?period=${period}`,
    filterData
  );

  if (result?.code !== 200) {
    return {};
  }

  return result.data;
}
export const chargerUtilizationApiCall = async (body) =>
  body
    ? await apiProvider.post(CHARGER_UTILIZATION, body)
    : await apiProvider.post(CHARGER_UTILIZATION, {});

export async function getHourlyChargerUtilisation(body) {
  return await apiProvider.post(CHARGER_UTILIZATION_HOURLY, body);
}

export default {
  getOpenFaultsCount,
  getOpenFaults,
  getAllConnectivityDetails,
  getAllFaultsDetails,
  getManagedPorts,
  getFaultsTroubleShoot,
  updateFaultStatus,
  faultNotes,
  // getChargerTemperature,
  getAllMakeAndModels,
  chargerUtilizationApiCall,
};
