import axios from "axios";

export const handleResponse = (response) => {
  if (response.results) {
    return response.results;
  }

  if (response.data) {
    return { ...response.data, statusCode: response.status };
  }
  return response;
};

export function handleError(error) {
  if (axios.isCancel(error)) {
    console.log("Request cancelled");
  }
  if (error.data) {
    return error.data;
  }
  if (error.response) {
    return {
      data: error.response.data,
      status: error.response.status,
      statusCode: error.response.status,
      code: error.response.status,
    };
  }
  return error;
}
