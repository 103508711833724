import React from "react";
import NewPageMainContent from "../components/NewPageMainContent";
import error_something from "./../assets/images/png/error-bound.png";
class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    // You can also log the error to an error reporting service
    //logErrorToMyService(error, errorInfo);
    console.log("Error boundary::", error, errorInfo);
  }

  render() {
    const goHome = () => {
      setTimeout(() => (window.location.href = "/"), 800000);
    };

    if (this.state.hasError) {
      // You can render any custom fallback UI
      return (
        <>
          <NewPageMainContent>
            <div className="error-bound-main">
              <div className="error-content">
                <img
                  className="error-img"
                  src={error_something}
                  alt="Something went wrong"
                />
                <h1 className="error-title">
                  Something went wrong. Please contact our team for support.
                </h1>
                <p className="error-des">
                  You will be redirected to the Home page automatically in a few
                  seconds.
                </p>

                {goHome()}
              </div>
            </div>
          </NewPageMainContent>
        </>
      );
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
