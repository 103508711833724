import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Box, Grid, Tooltip, Zoom } from "@mui/material";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import DeleteIcon from "@mui/icons-material/Delete";
import GetAppOutlinedIcon from "@mui/icons-material/GetAppOutlined";
import Controls from "../../../components/controls/Controls";
import { Form, UseForm } from "../../../components/UseForm";
import { validateNonEmptyFields } from "../../../utils/FieldValidations";
import {
  apiProvider,
  indexedEndPoints,
} from "../../../services/api/utilities/provider";
import useTable from "../../../components/UseTable";
import Loader from "../../../components/Loader";
import { PDFDownloadLink } from "@react-pdf/renderer";
import IntegrationTestReportPDF from "./IntegrationTestReportPDF";
import ConfirmDialog from "../../../components/ConfirmDialog";
import ToastMessage from "../../../components/ToastMessage";
import { PrivilegedComponent } from "../../../utils/PrivilegedComponent";
import no_Data from "../../../assets/images/png/Vector.png";
import "../dataCatalog.scss";

export default function AddEditIntegrationTestReport({
  type,
  openPopup,
  setOpenPopup,
  data,
  setData,
}) {
  const addEditInitialValues = useMemo(
    () => ({
      testSuiteName:
        type === "updateTestSuite" ? openPopup?.item?.testSuiteName : "",
      testCase:
        type === "updateTestSuite"
          ? openPopup?.item?.testCases?.map((row) => row.name)
          : [""],
    }),
    [openPopup]
  );

  const { values, setValues, errors, setErrors, handleFormInputChange } =
    UseForm(addEditInitialValues);

  const handleSubmit = useCallback(
    (e) => {
      e.preventDefault();
      let fields = Object.keys(addEditInitialValues);
      const validData = validateNonEmptyFields(fields, values, setErrors);
      if (validData) setErrors({});
      else return;
      let newData = [...data];
      let ind = openPopup?.item?.index;
      let testCases = values?.testCase
        ?.filter((item) => item?.length)
        ?.map((item) => ({
          name: item,
          status: null,
          observation: "",
          isMandatory: false,
        }));
      if (type === "updateTestSuite") {
        newData[ind].testSuiteName = values.testSuiteName;
        newData[ind].testCases = testCases;
      } else {
        newData.push({
          testSuiteName: values.testSuiteName,
          isDefault: false,
          testCases: testCases,
        });
      }
      setData(newData);
      setOpenPopup((op) => ({ ...op, isOpen: false }));
    },
    [values]
  );

  return (
    <Form onSubmit={handleSubmit}>
      <Grid container>
        <Grid item xs={12}>
          <Controls.Input
            name="testSuiteName"
            label="Test Suite Name"
            value={values.testSuiteName}
            error={errors.testSuiteName}
            onChange={handleFormInputChange()}
          />
        </Grid>
        <Grid item xs={11}>
          <Controls.Input
            name="testCases"
            label="Test Case"
            value={values.testCase?.[0]}
            error={errors.testCase}
            onChange={(e) => {
              let res = [...values.testCase];
              res[0] = e.target.value;
              setValues({ ...values, testCase: res });
            }}
          />
        </Grid>
        <Grid item xs={1}>
          <Controls.ActionButton
            disabled={
              values.testCase[values.testCase.length - 1]?.trim()?.length === 0
            }
            onClick={() =>
              setValues({
                ...values,
                testCase: [...values.testCase, ""],
              })
            }
          >
            <AddCircleOutlineIcon />
          </Controls.ActionButton>
        </Grid>

        {values.testCase?.length > 1 &&
          values.testCase?.slice(1)?.map((item, i) => (
            <Grid container alignItems="center" key={"testCase" + i}>
              <Grid item xs={11}>
                <Controls.Input
                  name="testCase"
                  label="Test Case"
                  value={item}
                  onChange={(e) => {
                    let res = [...values.testCase];
                    res[i + 1] = e.target.value;
                    setValues({
                      ...values,
                      testCase: res,
                    });
                  }}
                />
              </Grid>
              <Grid item xs={1}>
                <Controls.ActionButton
                  onClick={() =>
                    setValues({
                      ...values,
                      testCase: values.testCase?.filter(
                        (_, ind) => ind !== i + 1
                      ),
                    })
                  }
                >
                  <DeleteIcon />
                </Controls.ActionButton>
              </Grid>
            </Grid>
          ))}
        <Grid item xs={12}>
          <Box display="flex" justifyContent="flex-end">
            <Controls.Button
              type="submit"
              text={
                type === "addTestSuite" ? "Add Test Suite" : "Update Test Suite"
              }
            />
          </Box>
        </Grid>
      </Grid>
    </Form>
  );
}

export function ViewReportHistory({ openPopup, model }) {
  const { CHARGER_INTEGRATION_HISTORY } = indexedEndPoints;
  const filterFn = useMemo(() => ({ fn: (items) => items }), []);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [refreshReports, setRefreshReports] = useState(true);
  const [confirmDialog, setConfirmDialog] = useState({
    isOpen: false,
    type: "primary",
  });
  const [toast, setToast] = useState({
    isOpen: false,
    message: "",
    type: "",
  });

  useEffect(() => {
    const fetchHistory = async () => {
      setLoading(true);
      const response = await apiProvider.getAll(
        `${CHARGER_INTEGRATION_HISTORY}/${openPopup?.item}`
      );
      setLoading(false);
      setData(response?.data);
      setRefreshReports(false);
    };
    refreshReports && fetchHistory();
  }, [openPopup?.item, refreshReports]);

  const handleDeleteReport = (row) => {
    setConfirmDialog({
      ...confirmDialog,
      isOpen: true,
      title: "Are you sure ?",
      subTitle: "You want to Delete this Report",
      type: "secondary",
      onConfirm: () => deleteReport(row.reportId),
    });
  };
  const deleteReport = useCallback(async (reportId) => {
    setLoading(true);
    const response = await apiProvider.del(
      `${CHARGER_INTEGRATION_HISTORY}/${reportId}`
    );
    setLoading(false);
    if (response.statusCode >= 200 && response.statusCode <= 299) {
      setToast({
        isOpen: true,
        message: "Report deleted successfully",
        type: "success",
      });
      setConfirmDialog({ ...confirmDialog, isOpen: false });
      setRefreshReports(true);
    } else {
      setToast({
        isOpen: true,
        message: "Report deletion failed",
        type: "error",
      });
    }
  }, []);

  const headers = useMemo(
    () => [
      { id: "performedBy", label: "User" },
      {
        id: "performedAt",
        label: "Report Generated At",
        render: (row, col) => row[col]?.replace("GMT", "UTC"),
      },
      {
        id: "firmwareVersion",
        label: "Firmware Version",
        render: (row, col) => row[col] || "N/A",
      },
      {
        id: "result",
        label: "Result",
        disableSorting: true,
        render: (row, col) =>
          `${row[col]?.passed}/${
            row[col]?.passed + row[col]?.failed + row[col]?.skipped
          } test cases passed`,
      },
      {
        id: "testSuites",
        label: "Action",
        disableSorting: true,
        render: (row, col) => (
          <div className="baselineAligned">
            <PDFDownloadLink
              document={
                <IntegrationTestReportPDF
                  model={model}
                  data={row[col]}
                  updatedAt={row?.performedAt?.replace("GMT", "UTC")}
                />
              }
              fileName={`IntegrationTestReport-${new Date()
                .toString()
                .split(" GMT")[0]
                .split(" ")
                .join("-")}`}
            >
              <Tooltip arrow TransitionComponent={Zoom} title="Download Report">
                <GetAppOutlinedIcon fontSize="small" />
              </Tooltip>
            </PDFDownloadLink>
            <PrivilegedComponent
              permission="Delete Charger Integration Report"
              module="dataCatalogCharger"
            >
              <Controls.ActionButton
                color="primary"
                onClick={() => handleDeleteReport(row)}
              >
                <Tooltip arrow TransitionComponent={Zoom} title="Delete Report">
                  <DeleteIcon fontSize="small" />
                </Tooltip>
              </Controls.ActionButton>
            </PrivilegedComponent>
          </div>
        ),
      },
    ],
    [model]
  );

  const {
    tableContainer: TableContainer,
    tableHead: TableHead,
    tableBody: TableBody,
    tablePagination: TablePagination,
  } = useTable(data, headers, filterFn);

  return (
    <>
      <Loader isLoading={loading} />
      {data?.length ? (
        <>
          <TableContainer>
            <TableHead />
            {TableBody}
          </TableContainer>
          {TablePagination}
        </>
      ) : (
        !loading && (
          <div className="no_data_in_table" id="noIntegrationReportData">
            <img src={no_Data} alt="No data available" />
            <p className="no_data_title">No data available</p>
          </div>
        )
      )}
      {confirmDialog.isOpen && (
        <ConfirmDialog
          confirmDialog={confirmDialog}
          setConfirmDialog={setConfirmDialog}
        />
      )}
      <ToastMessage toast={toast} setToast={setToast} />
    </>
  );
}
