import { messages } from "../constants/Messages";

export const validateEmail = (email) => {
  const re =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
};

export const validateName = (name) => {
  return name.match(/\D/g) && name.match(/\w/g);
};

export const validatePhoneNumber = (phoneNumber) => {
  const re = /^[+]?[(]?[0-9]{3}[)]?[-\s.]?[0-9]{3}[-\s.]?[0-9]{4,6}$/;
  return re.test(phoneNumber);
};

export const validateCountryCode = (countryCode) => {
  const re = /^\+?\d+$/;
  return re.test(countryCode);
};

export const validateNonEmptyFields = (fields = [], values = {}, setErrors) => {
  return fields.every((field) => {
    if (
      typeof values[field] === "boolean" &&
      (values[field] == true || values[field] == false)
    ) {
      setErrors((err) => ({ ...err, [field]: "" }));
      return true;
    } else if (
      typeof values[field] === "number" ||
      (typeof values[field] === "string" && values[field]?.trim()?.length > 0)
    ) {
      setErrors((err) => ({ ...err, [field]: "" }));
      return true;
    } else if (
      (Array.isArray(values[field]) &&
        values[field]?.length > 0 &&
        values[field][0]) ||
      (!Array.isArray(values[field]) &&
        typeof values[field] === "object" &&
        values[field])
    ) {
      setErrors((err) => ({ ...err, [field]: "" }));
      return true;
    } else {
      setErrors((err) => ({ ...err, [field]: messages.FIELD_REQUIRED }));
      return false;
    }
  });
};
