import React, { useState } from "react";
import {
  checkDepotSelected,
  checkSingleFilterSelected,
} from "../../components/all-filter-types/isFilteredCheckFunctions";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import { Box, Tab, Tabs } from "@mui/material";
import TabPanel from "../../components/all-filter-types/FilterTabPanel";
import SingleTypeFilter from "../../components/all-filter-types/SingleFilter";
import Controls from "../../components/controls/Controls";
import AccountsAndDepotsFilter from "../../components/all-filter-types/accountAndDepot";

export default function ServicesFilter({
  allDepotTypes,
  setAllDepotTypes,
  allAccounts,
  setAllAccounts,
  clearAll,
  applyFilter,
}) {
  const [value, setValue] = useState("Depot Type");
  const handleChange = (_, newValue) => {
    setValue(newValue);
  };

  return (
    <div className="filters_with_vertical_tab">
      <Box sx={{ flexGrow: 1, bgcolor: "background.paper", display: "flex" }}>
        <Tabs
          orientation="vertical"
          variant="scrollable"
          value={value}
          onChange={handleChange}
          aria-label="Vertical tabs example"
          sx={{ borderRight: 1, borderColor: "divider" }}
        >
          <Tab
            label="Depot Type"
            value={"Depot Type"}
            icon={
              checkSingleFilterSelected(allDepotTypes) ? (
                <FiberManualRecordIcon className="filter_dot" />
              ) : null
            }
            iconPosition="end"
          />
          <Tab
            label="Account and depot"
            value={"Account and depot"}
            icon={
              checkDepotSelected(allAccounts) ? (
                <FiberManualRecordIcon className="filter_dot" />
              ) : null
            }
            iconPosition="end"
          />
        </Tabs>
        <TabPanel value={value} index={"Depot Type"}>
          <div className="filter_tab_vertical_body">
            <SingleTypeFilter
              _stateVariable={allDepotTypes}
              setFuntion={setAllDepotTypes}
            />
          </div>
        </TabPanel>
        <TabPanel value={value} index={"Account and depot"}>
          <div className="filter_tab_vertical_body">
            <AccountsAndDepotsFilter
              _allAccounts={allAccounts}
              setFuntion={setAllAccounts}
            />
          </div>
        </TabPanel>
      </Box>
      <div className="filter_action_btn">
        <Controls.Button
          disabled={
            !(
              checkSingleFilterSelected(allDepotTypes) ||
              checkDepotSelected(allAccounts)
            )
          }
          text="Clear All"
          variant="outlined"
          onClick={() => clearAll()}
          className="btn_font_size"
        />
        <Controls.Button
          text="Apply"
          color="primary"
          onClick={() => applyFilter()}
          className="btn_font_size"
        />
      </div>
    </div>
  );
}
