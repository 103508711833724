import React from "react";

import Controls from "./controls/Controls";

import { Dialog, DialogTitle, DialogContent, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import CloseIcon from "@mui/icons-material/Close";

const useStyles = makeStyles((theme) => ({
  dialogWraper: ({ top, bottom, minWidth }) => ({
    padding: theme.spacing(2),
    position: "absolute",
    top: theme.spacing(top),
    margin: theme.spacing(1),
    borderRadius: "10px",
    bottom: bottom ? theme.spacing(bottom) : "",
    maxHeight: "90vh",
    minWidth: minWidth,
  }),
  dialogTitle: {
    paddingRight: "0px",
  },
}));

const Popup = (props) => {
  const {
    children,
    openPopup,
    setOpenPopup,
    top = 10,
    bottom = 0,
    maxWidth = "sm",
    minWidth = "40%",
  } = props;
  const classes = useStyles({ top, bottom, minWidth });
  return (
    <Dialog
      open={openPopup.isOpen}
      maxWidth={maxWidth}
      classes={{ paper: classes.dialogWraper }}
    >
      <DialogTitle className={classes.dialogTitle}>
        <div style={{ display: "flex" }}>
          <Typography variant="h5" component="div" style={{ flexGrow: 1 }}>
            {openPopup.title}
          </Typography>
          <Controls.ActionButton
            color="secondary"
            data-testid="closePopupBtn"
            onClick={() => {
              setOpenPopup({ ...openPopup, isOpen: false });
            }}
          >
            <CloseIcon />
          </Controls.ActionButton>
        </div>
      </DialogTitle>
      <DialogContent dividers>{children}</DialogContent>
    </Dialog>
  );
};

export default Popup;
