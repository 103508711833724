import * as React from "react";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import Controls from "../../components/controls/Controls";
import AccountsAndDepotsFilter from "../../components/all-filter-types/accountAndDepot";
import ChargerMakeAndModelFilter from "../../components/all-filter-types/ChargerMakeAndModel";
import TabPanel from "../../components/all-filter-types/FilterTabPanel";
import NestedFilterWithSearch from "../../components/all-filter-types/NestedFilter";
import {
  checkDepotSelected,
  checkChargersSelected,
  checkNestedFilterSelected,
  checkSingleFilterSelected,
} from "../../components/all-filter-types/isFilteredCheckFunctions";
import SingleTypeFilter from "../../components/all-filter-types/SingleFilter";
import { useVehiclesDiscovery } from "./VehiclesDiscoveryContext";

const VehicleFilter = (props) => {
  const {
    allAccounts,
    allMakes,
    allPowerType,
    applyFilter,
    allDepotType,
    countryCode,
    fromDate,
    toDate,
  } = props;

  const {
    setAllAccounts,
    setAllMakes,
    setAllPowerType,
    setAllDepotType,
    setCountryCodes,
    setFromDate,
    setToDate,
  } = useVehiclesDiscovery();

  const [value, setValue] = React.useState("Account and depot");

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const [tempAllAccounts, setTempAllAccounts] = React.useState(
    JSON.parse(JSON.stringify(allAccounts))
  );
  const [tempAllMakeModel, setTempAllMakeModel] = React.useState(
    JSON.parse(JSON.stringify(allMakes))
  );
  const [tempAllPowerType, setTempAllPowerType] = React.useState(
    JSON.parse(JSON.stringify(allPowerType))
  );
  const [tempAllCountryCode, setTempAllCountryCode] = React.useState(
    JSON.parse(JSON.stringify(countryCode))
  );
  const [tempAllDepotType, setTempAllDepotType] = React.useState(
    JSON.parse(JSON.stringify(allDepotType))
  );

  const clearAll = () => {
    if (tempAllAccounts[0].children) {
      tempAllAccounts[0].checked = false;
      tempAllAccounts[0].children.map((child) => {
        child.checked = false;
        if (child.depotsList) {
          child.depotsList.map((grandChild) => {
            grandChild.checked = false;
          });
        }
      });
    }
    if (tempAllMakeModel[0].children) {
      tempAllMakeModel[0].checked = false;
      tempAllMakeModel[0].children.map((child) => {
        child.checked = false;
        if (child.chargers) {
          child.chargers.map((grandChild) => {
            grandChild.checked = false;
          });
        }
      });
    }
    if (tempAllPowerType[0].children) {
      tempAllPowerType[0].checked = false;
      tempAllPowerType[0].children.map((child) => {
        child.checked = false;
      });
    }
    if (tempAllCountryCode[0].children) {
      tempAllCountryCode[0].checked = false;
      tempAllCountryCode[0].children.map((child) => {
        child.checked = false;
        if (child.children) {
          child.children.map((grandChild) => {
            grandChild.checked = false;
          });
        }
      });
    }

    if (tempAllDepotType[0].children) {
      tempAllDepotType[0].checked = false;
      tempAllDepotType[0].children.map((child) => {
        child.checked = false;
      });
    }
    setTempAllAccounts([...tempAllAccounts]);
    setTempAllMakeModel([...tempAllMakeModel]);
    setTempAllPowerType([...tempAllPowerType]);
    setTempAllDepotType([...tempAllDepotType]);
    setTempAllCountryCode([...tempAllCountryCode]);
  };

  return (
    <>
      <div className="filters_with_vertical_tab">
        <Box sx={{ flexGrow: 1, bgcolor: "background.paper", display: "flex" }}>
          <Tabs
            orientation="vertical"
            variant="scrollable"
            value={value}
            onChange={handleChange}
            aria-label="Vertical tabs example"
            sx={{ borderRight: 1, borderColor: "divider" }}
          >
            <Tab
              label="Account and depot"
              value={"Account and depot"}
              icon={
                checkDepotSelected(tempAllAccounts) ? (
                  <FiberManualRecordIcon className="filter_dot" />
                ) : null
              }
              iconPosition="end"
            />
            <Tab
              label="Charger make and model"
              value={"Charger make and model"}
              icon={
                checkChargersSelected(tempAllMakeModel) ? (
                  <FiberManualRecordIcon className="filter_dot" />
                ) : null
              }
              iconPosition="end"
            />
            <Tab
              label="Charger type"
              value={"Charger type"}
              icon={
                checkSingleFilterSelected(tempAllPowerType) ? (
                  <FiberManualRecordIcon className="filter_dot" />
                ) : null
              }
              iconPosition="end"
            />
            <Tab
              label="Depot Type"
              value={"Depot Type"}
              icon={
                checkSingleFilterSelected(tempAllDepotType) ? (
                  <FiberManualRecordIcon className="filter_dot" />
                ) : null
              }
              iconPosition="end"
            />
            <Tab
              label="Country"
              value={"Country"}
              icon={
                checkNestedFilterSelected(tempAllCountryCode) ? (
                  <FiberManualRecordIcon className="filter_dot" />
                ) : null
              }
              iconPosition="end"
            />
          </Tabs>
          <TabPanel value={value} index={"Account and depot"}>
            <div className="filter_tab_vertical_body">
              <AccountsAndDepotsFilter
                _allAccounts={tempAllAccounts}
                setFuntion={setTempAllAccounts}
              />
            </div>
          </TabPanel>
          <TabPanel value={value} index={"Charger make and model"}>
            <div className="filter_tab_vertical_body">
              <ChargerMakeAndModelFilter
                _allMakes={tempAllMakeModel}
                setFuntion={setTempAllMakeModel}
              />
            </div>
          </TabPanel>
          <TabPanel value={value} index={"Charger type"}>
            <div className="filter_tab_vertical_body">
              <SingleTypeFilter
                _stateVariable={tempAllPowerType}
                setFuntion={setTempAllPowerType}
              />
            </div>
          </TabPanel>
          <TabPanel value={value} index={"Depot Type"}>
            <div className="filter_tab_vertical_body">
              <SingleTypeFilter
                _stateVariable={tempAllDepotType}
                setFuntion={setTempAllDepotType}
              />
            </div>
          </TabPanel>
          <TabPanel value={value} index={"Country"}>
            <div className="filter_tab_vertical_body">
              <NestedFilterWithSearch
                _allData={tempAllCountryCode}
                setFuntion={setTempAllCountryCode}
              />
            </div>
          </TabPanel>
        </Box>
        <div className="filter_action_btn">
          <Controls.Button
            text="Clear All"
            variant="outlined"
            onClick={() => clearAll()}
            className="btn_font_size"
          />
          <Controls.Button
            text="Apply"
            color="primary"
            onClick={() => {
              applyFilter(
                tempAllAccounts,
                tempAllMakeModel,
                tempAllPowerType,
                tempAllCountryCode,
                tempAllDepotType,
                fromDate,
                toDate
              );
              setAllAccounts(tempAllAccounts);
              setAllMakes(tempAllMakeModel);
              setAllPowerType(tempAllPowerType);
              setAllDepotType(tempAllDepotType);
              setCountryCodes(tempAllCountryCode);
            }}
            className="btn_font_size"
          />
        </div>
      </div>
    </>
  );
};

export default VehicleFilter;
