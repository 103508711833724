import React from "react";
import { Grid } from "@mui/material";
import Controls from "../../components/controls/Controls";

const BillingPackageForProcess = (props) => {
  const { values, handleFormInputChange, setValues, errors, RatePlans } = props;

  const [productRatePlanListOptions, setProductRatePlanListOptions] =
    React.useState(RatePlans);

  React.useEffect(() => {
    if (values.productSku) {
      setProductRatePlanListOptions(
        RatePlans.filter((item) => item.sku === values.productSku)
      );
      if (values.productSku !== "SKU-00000296")
        setValues((prev) => ({ ...prev, productChargeId: null }));
    } else setProductRatePlanListOptions(RatePlans);
  }, [values.productSku]);

  return (
    <>
      <Grid item xs={6}>
        <Controls.AutoSuggestion
          label="Product SKU"
          value={values.productSku}
          name="productSku"
          onChange={handleFormInputChange({
            dependent: "productSku",
            resetField: "productChargeId",
          })}
          options={RatePlans.reduce((acc, item) => {
            !acc.includes(item.sku) && acc.push(item.sku);
            return acc;
          }, [])}
        />
      </Grid>
      <Grid item xs={6}>
        <Controls.AutoSuggestion
          label="Rate Plan Name"
          value={values.productChargeId}
          name="productChargeId"
          onChange={handleFormInputChange({
            dependent: "productSku",
            resetField: "productChargeId",
          })}
          options={productRatePlanListOptions
            .filter((ele) => ele.sku === values.productSku)
            .map((item) => item.productRatePlanChargeName)}
          error={errors.productChargeId}
        />
      </Grid>
    </>
  );
};

export default BillingPackageForProcess;
