import {
  getBillableCustomersCount,
  getBillablePorts,
} from "../../pages/analytics/AnalyticsService";
import { getPackageDetails } from "../../pages/billing/BillingServices";
import apiDepots from "../../services/api/Depots";

export const saveAllBillablePortsGlobal = () => {
  return async (dispatch) => {
    const res = await getBillablePorts();
    if (res.statusCode >= 200 && res.statusCode <= 299) {
      dispatch({
        type: "Billable_Ports_Count",
        payload: {
          allBillingPortsResponse: res,
          isBilliablePortsCountLoaded: true,
        },
      });
    } else {
      dispatch({
        type: "Billable_Ports_Count",
        payload: {
          allBillingPortsResponse: [],
          isBilliablePortsCountLoaded: true,
        },
      });
    }
    return res;
  };
};

export const saveAllBillableCustomersGlobal = () => {
  return async (dispatch) => {
    const res = await getBillableCustomersCount();
    if (res.statusCode >= 200 && res.statusCode <= 299) {
      dispatch({
        type: "Billable_Customers_Count",
        payload: {
          allBillingCustomersResponse: res,
          isBillableCustomersLoaded: true,
        },
      });
    } else {
      dispatch({
        type: "Billable_Customers_Count",
        payload: {
          allBillingCustomersResponse: [],
          isBillableCustomersLoaded: true,
        },
      });
    }
    return res;
  };
};
export const saveAllBillingPackageGlobal = () => {
  return async (dispatch) => {
    const res = await getPackageDetails();
    if (res.statusCode >= 200 && res.statusCode <= 299) {
      dispatch({
        type: "Billing_Package",
        payload: {
          allBillingPackageResponse: res,
          isBillingPackageLoaded: true,
        },
      });
    } else {
      dispatch({
        type: "Billing_Package",
        payload: {
          allBillingPackageResponse: [],
          isBillingPackageLoaded: true,
        },
      });
    }
    return res;
  };
};
export const saveAllBillingRatePlansGlobal = () => {
  return async (dispatch) => {
    const res = await apiDepots.getRatePlans();
    if (res.statusCode >= 200 && res.statusCode <= 299) {
      dispatch({
        type: "Billing_RatePlans",
        payload: {
          allBillingRatePlansResponse: res,
          isBillingRatePlansLoaded: true,
        },
      });
    } else {
      dispatch({
        type: "Billing_RatePlans",
        payload: {
          allBillingRatePlansResponse: [],
          isBillingRatePlansLoaded: true,
        },
      });
    }
    return res;
  };
};
