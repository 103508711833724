import React from "react";
import { Button as MuiButton } from "@mui/material";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    margin: theme.spacing(0.5),
  },
  label: {
    textTransform: "none",
  },
}));

const Button = (props) => {
  const classes = useStyles();
  const { text, size, color, variant, onClick, ...other } = props;
  return (
    <div className="btns">
      <MuiButton
        variant={variant || "contained"}
        size={size || "large"}
        onClick={onClick}
        data-testid={other.testid}
        {...other}
        color={color || "primary"}
      >
        {text}
      </MuiButton>
    </div>
  );
};

export default Button;
