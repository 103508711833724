import React from "react";
import { makeStyles } from "@mui/styles";

import PageMainContent from "../../components/PageMainContent";
import EnergyConsumptionAnalytics from "./EnergyConsumptionAnalytics";

const useStyles = makeStyles((theme) => ({
  "@global": {
    "*::-webkit-scrollbar": {
      width: "0.4em",
    },
    "*::-webkit-scrollbar-track": {
      "-webkit-box-shadow": "inset 0 0 6px rgba(0,0,0,0.00)",
    },
    "*::-webkit-scrollbar-thumb": {
      backgroundColor: "rgba(0,0,0,.1)",
      outline: "0px solid slategrey",
    },
  },
}));

const Analytics = () => {
  useStyles();
  return (
    <PageMainContent
      extraStyles={{
        boxShadow: "none",
        backgroundColor: "#F5F5F5",
        margin: 0,
      }}
    >
      <EnergyConsumptionAnalytics />
    </PageMainContent>
  );
};

export default Analytics;
