import React, { useState, useEffect } from "react";
import Controls from "../../../components/controls/Controls";
import {
  Grid,
  InputAdornment,
  TableBody,
  TableCell,
  TableRow,
  Toolbar,
  Typography,
  Badge,
  ListItemText,
  Chip,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import PageMainContent from "../../../components/PageMainContent";
import { isEqual } from "lodash";
import Loader from "../../../components/Loader";
import { titleLabels } from "../../../constants/TitleLabels";
import Popup from "../../../components/Popup";
import ExportCSV from "../../../components/ExportCSV";
import useTable from "../../../components/UseTable";
import {
  ALL_SESSIONS_TABLE_HEADERS,
  ALL_SESSIONS_TABLE_HEAD_CELLS,
  ALL_SESSIONS_COLUMN_HEADERS,
} from "../constants";
import no_Data from "./../../../assets/images/png/Vector.png";
import "../vehicleDiscovery.scss";

const ToolbarMemoised = React.memo(({ handleSearch, setOpenPopup }) => {
  return (
    <Toolbar className="table_toolbar">
      <Controls.Input
        label={"Search"}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <SearchIcon />
            </InputAdornment>
          ),
        }}
        onChange={handleSearch}
      />
      <Grid item sm />
      <Badge color="primary">
        <Controls.Button
          text="Export CSV"
          variant="outlined"
          id="vehicleExportCSV"
          onClick={() => {
            setOpenPopup({
              isOpen: true,
              title: "Export CSV",
              child: "exportCSV",
            });
          }}
        />
      </Badge>
    </Toolbar>
  );
});

const DepotSessions = ({ isLoading, vehicleDiscoveryData }) => {
  const [filterFn, setFilterFn] = React.useState({ fn: (items) => items });
  const [TableContainer, setTableContainer] = React.useState("table");
  const [TableHead, setTableHead] = React.useState("thead");
  const [TableBody, setTableBody] = React.useState("tbody");
  const [TablePagination, setTablePagination] = React.useState(() => <></>);
  const [recordsAfterPagingAndSorting, setRecordsAfterPagingAndSorting] =
    React.useState([]);
  const [headers, setHeaders] = React.useState(ALL_SESSIONS_TABLE_HEADERS);
  const [tableHeadCells, setTableHeadCells] = useState(
    ALL_SESSIONS_TABLE_HEAD_CELLS
  );
  const allColumnsHeaders = ALL_SESSIONS_COLUMN_HEADERS;

  // Table component related variables
  const result = useTable(vehicleDiscoveryData, tableHeadCells, filterFn);
  useEffect(() => {
    setTableContainer(() => result.tableContainer);
  }, [result.tableContainer]);
  useEffect(() => {
    setTableHead(() => result.tableHead);
  }, [result.tableHead]);
  useEffect(() => {
    setTableBody(() => result.tableBody);
  }, [result.tableBody]);
  useEffect(() => {
    setTablePagination(result.tablePagination);
  }, [result.tablePagination]);

  useEffect(() => {
    setRecordsAfterPagingAndSorting(result.recordsAfterPagingAndSorting);
  }, [result.recordsAfterPagingAndSorting]);

  const [openPopup, setOpenPopup] = React.useState({
    isOpen: false,
    title: "",
    child: "",
    item: {},
  });

  const handleSearch = (e) => {
    let target = e.target.value.trim();
    setFilterFn({
      fn: (items) => {
        if (target === "") return items;
        else
          return items.filter(
            (x) =>
              x.depotName.toLowerCase().includes(target.toLowerCase()) ||
              x.depotId.toLowerCase().includes(target.toLowerCase()) ||
              x.sessionId.toLowerCase().includes(target.toLowerCase()) ||
              x.chargerId.toLowerCase().includes(target.toLowerCase()) ||
              x.chargerModel.toLowerCase().includes(target.toLowerCase()) ||
              x.chargerMake.toLowerCase().includes(target.toLowerCase()) ||
              x.powerType.toLowerCase().includes(target.toLowerCase()) ||
              x.vin.toLowerCase().includes(target.toLowerCase())
          );
      },
    });
  };

  return (
    <>
      {<Loader isLoading={isLoading} />}
      <PageMainContent>
        <ToolbarMemoised
          handleSearch={handleSearch}
          setOpenPopup={setOpenPopup}
        />
        <TableContainer>
          <TableHead />
          {TableBody}
        </TableContainer>
        {recordsAfterPagingAndSorting.length === 0 && (
          <div className="no_data_in_table">
            <div className="img_title">
              <img src={no_Data} alt="No data available" />
              <p className="no_data_title">No data available</p>
            </div>
          </div>
        )}
        {TablePagination}
      </PageMainContent>
      <Popup openPopup={openPopup} setOpenPopup={setOpenPopup} minWidth="50%">
        {openPopup.child === "exportCSV" ? (
          <ExportCSV
            setOpenPopup={setOpenPopup}
            data={vehicleDiscoveryData}
            allColumnsHeaders={allColumnsHeaders}
            headers={headers}
            setHeaders={setHeaders}
            fileName={`Vehicle-Discovery.csv`}
          />
        ) : null}
      </Popup>
    </>
  );
};

export default DepotSessions;
