import React from "react";

import { Grid, Paper, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import Loader from "../../components/Loader";
import Controls from "../../components/controls/Controls";

import { UseForm } from "../../components/UseForm";

import { messages } from "../../constants/Messages";
import { validateEmail } from "../../utils/FieldValidations";

import ElectriphiLoginLogo from "../../assets/images/png/ford-logo-dark.png";
import apiUsers from "../../services/api/Users";

const useStyles = makeStyles(() => ({
  paper: {
    padding: 20,
    width: 350,
    margin: "100px auto",
  },
  logo: {
    margin: "1em 20px",
  },
  loginButton: {
    margin: "2em 0px",
  },
}));

const initialFieldValues = {
  email: "",
  //   password: ""
};

const ForgotPasswordEmail = (props) => {
  const classes = useStyles();
  const [isLoading, setLoading] = React.useState(false);
  const [isEmailSent, setEmailSent] = React.useState(false);
  const [message, setMessage] = React.useState("");
  const validate = (fieldValues = values) => {
    let temp = { ...errors };
    if ("email" in fieldValues)
      temp.email = fieldValues.email
        ? validateEmail(fieldValues.email)
          ? ""
          : messages.INVALID_EMAIL
        : messages.FIELD_REQUIRED;

    setErrors({ ...temp });
    if (fieldValues === values)
      return Object.values(temp).every((x) => x === "");
    else return false;
  };

  const { values, errors, setErrors, handleFormInputChange } = UseForm(
    initialFieldValues,
    true,
    validate
  );

  React.useEffect(() => {
    setEmailSent(false);
  }, []);

  const handleSubmit = () => {
    if (validate()) {
      setLoading(true);
      apiUsers
        .forgotPasswordEmail({ email: values.email })
        .then((res) => {
          if (res.statusCode === 201) {
            setEmailSent(true);
            setMessage("");
          } else {
            setMessage("This is not a valid Mail ID");
          }
          setLoading(false);
        })
        .catch((error) => {
          setLoading(false);
        });
    }
  };

  const handleBackToLogin = () => {
    window.location.href = "/";
  };

  return (
    <Grid>
      {<Loader isLoading={isLoading} />}
      <Paper elevation={10} className={classes.paper}>
        <Grid align="center" className={classes.logo}>
          <img
            style={{ width: "100%" }}
            alt="Electriphi Login Logo"
            src={ElectriphiLoginLogo}
          />
        </Grid>
        {isEmailSent === false ? (
          <>
            <Controls.Input
              name="email"
              label="Email"
              value={values.email}
              onChange={handleFormInputChange()}
              error={errors.email}
              required
              fullWidth
              autoComplete="username"
            />
            <Grid className={classes.loginButton}>
              <Controls.Button
                color="primary"
                text="Get Reset Link"
                onClick={handleSubmit}
                fullWidth
              />
            </Grid>
          </>
        ) : (
          <>
            <Grid align="center">
              <p>Email Sent Successfully To the Requested Mail ID.</p>
            </Grid>
            <Grid className={classes.loginButton}>
              <Controls.Button
                color="primary"
                text="Back To Login"
                onClick={handleBackToLogin}
                fullWidth
              />
            </Grid>
          </>
        )}

        <Typography align="center" variant="subtitle2" color="error">
          {message != "" && message}
        </Typography>
      </Paper>
    </Grid>
  );
};

export default ForgotPasswordEmail;
