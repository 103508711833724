import React, { useEffect, useState, useCallback, useMemo } from "react";
import { getScheduledServices } from "./InternalApisService";
import OpsBreadcrumb from "../../components/NewBreadcrumbs";
import PageMainContent from "../../components/PageMainContent";
import Loader from "../../components/Loader";
import useTable from "../../components/UseTable";
import {
  Badge,
  Grid,
  InputAdornment,
  Toolbar,
  Tooltip,
  Zoom,
} from "@mui/material";
import Controls from "../../components/controls/Controls";
import SearchIcon from "@mui/icons-material/Search";
import TuneIcon from "@mui/icons-material/Tune";
import DeleteIcon from "@mui/icons-material/Delete";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import "./services.scss";
import NewCommonFilterDrawer from "../../components/NewSideFIlterDrawer";
import ScheduledServicesDrawer from "./ScheduledServicesDrawer";
import ServicesFilter from "./ServicesFilter";
import { useDispatch, useSelector } from "react-redux";
import { commonApiActionCreator } from "../../redux-state/actions";
import { allDepotTypesForFilter } from "../../constants/FilterConstants";
import { bindActionCreators } from "redux";
import differenceInDays from "date-fns/differenceInDays";
import ConfirmDialog from "../../components/ConfirmDialog";

const allBreadcrumbsLinks = [
  {
    link: "/services",
    title: "Services",
  },
];

const headCells = [
  { id: "createdBy", label: "Requested by" },
  { id: "description", label: "Description" },
  { id: "scheduledAt", label: "Scheduled at" },
  {
    id: "depotDetails",
    label: "Depot",
    render: (row, col) => row[col]?.depotName,
  },
  { disableSorting: true, id: "action", label: "Action" },
];

export default function ScheduledServices() {
  const [loading, setLoading] = useState(false);
  const [filterFn, setFilterFn] = useState({ fn: (items) => items });
  const [data, setData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [isFiltered, setIsFiltered] = useState(false);
  const [drawerObj, setDrawerObj] = useState({
    isOpen: false,
    title: "",
    data: {},
  });
  const [confirmDialog, setConfirmDialog] = React.useState({
    isOpen: false,
    type: "primary",
  });
  const [allDepotTypes, setAllDepotTypes] = useState(
    JSON.parse(JSON.stringify(allDepotTypesForFilter))
  );
  const [allAccounts, setAllAccounts] = useState([
    {
      name: "Account and Depot",
      isExpanded: true,
      checked: false,
      children: [],
    },
  ]);
  const dispatch = useDispatch();
  const { saveAllCompaniesV2Global } = bindActionCreators(
    commonApiActionCreator,
    dispatch
  );
  const { allCompaniesV2, isCompaniesLoaded } = useSelector(
    (state) => state.getAllCompaniesV2_Global
  );

  useEffect(() => {
    if (isCompaniesLoaded === false) {
      saveAllCompaniesV2Global();
    }
    getAllCompaniesData();
  }, [isCompaniesLoaded]);

  const getAllCompaniesData = async () => {
    let tempAllCompaniesV2 = JSON.parse(JSON.stringify(allCompaniesV2));
    if (tempAllCompaniesV2.length !== 0) {
      const accData = tempAllCompaniesV2.map((row) => {
        row.checked = false;
        row.isExpanded = true;
        if ("depotsList" in row) {
          row.depotsList?.map((item) => {
            item.checked = false;
          });
        }
        return row;
      });
      setAllAccounts([{ ...allAccounts[0], children: accData }]);
    }
  };

  const getScheduledServicesData = async () => {
    setLoading(true);
    const res = await getScheduledServices();
    setLoading(false);
    if (res?.statusCode === 200) {
      setData(res?.data);
      setFilteredData(res?.data);
    }
  };

  useEffect(() => getScheduledServicesData(), []);

  const toggleDrawer = (openClose, title, item) => {
    setDrawerObj({ isOpen: openClose, title: title, data: item, type: "" });
  };

  const applyFilter = () => {
    toggleDrawer(false, "FILTER", {});
    let selectedDepotTypesFilter = [];
    let selectedAccountsFilter = [];
    if (allDepotTypes[0].children.length) {
      selectedDepotTypesFilter = allDepotTypes[0].children.reduce(
        (acc, cur) => {
          if (cur.checked) {
            acc.push(cur.value);
          }
          return acc;
        },
        []
      );
    }
    if (allAccounts[0].children.length) {
      selectedAccountsFilter = allAccounts[0].children.reduce((acc, cur) => {
        const selectedChildElements = cur.depotsList.reduce((accCh, curCh) => {
          if (curCh.checked) {
            accCh.push(curCh.depotId);
          }
          return accCh;
        }, []);
        acc.push(...selectedChildElements);
        return acc;
      }, []);
    }

    let filterData = [];
    data?.forEach((row) => {
      (!selectedDepotTypesFilter?.length ||
        selectedDepotTypesFilter?.includes(row?.depotType)) &&
        (!selectedAccountsFilter?.length ||
          selectedAccountsFilter?.includes(row?.depotDetails?.depotId)) &&
        filterData.push(row);
    });
    setFilteredData(filterData);
    if (selectedDepotTypesFilter?.length || selectedAccountsFilter?.length)
      setIsFiltered(true);
    else setIsFiltered(false);
  };

  const clearAll = () => {
    allDepotTypes[0].checked = false;
    allDepotTypes[0].children.map((child) => {
      child.checked = false;
    });
    setAllDepotTypes([...allDepotTypes]);
    if (allAccounts[0].children) {
      allAccounts[0].checked = false;
      allAccounts[0].children.map((child) => {
        child.checked = false;
        if (child.depotsList) {
          child.depotsList.map((grandChild) => {
            grandChild.checked = false;
          });
        }
      });
    }
    setAllAccounts([...allAccounts]);
  };

  const handleViewMoreInfo = (row) => {
    setDrawerObj({
      isOpen: true,
      title: "Scheduled service details",
      data: row,
      type: "viewData",
    });
  };

  const handleCancelScheduledService = (row) => {
    const dateDiff =
      new Date(new Date(row.scheduledAt).toDateString()) -
      new Date(new Date().toDateString()) / 86400000;
    const notifyUser =
      dateDiff < 2 || (dateDiff === 2 && new Date().getHours() > 7);
    setConfirmDialog({
      ...confirmDialog,
      isOpen: true,
      title: "Are you sure you want to cancel this scheduled firmware update ?",
      subTitle: notifyUser
        ? "This customer has already been notified of the scheduled firmware update and may have planned for associated downtime. Cancelling this update will send the user a cancellation notification and a new firmware update will need to be scheduled from the Services page."
        : "You can schedule a new firmware update from the Services page. The customer will not be notified of this cancellation.",
      type: "secondary",
      onConfirm: () => cancelScheduledService(row.chargerMetaId),
    });
  };

  const cancelScheduledService = () => {};

  const handleSearch = (e) => {
    setFilterFn({
      fn: (items) => {
        if (e.target.value?.trim() === "") return items;
        else
          return items?.filter((row) =>
            Object.values(row)?.some((val) =>
              val
                ?.toString()
                ?.toLowerCase()
                ?.includes(e.target.value?.trim().toLowerCase())
            )
          );
      },
    });
  };

  const ToolbarMemoised = useCallback(
    ({ handleSearch }) => {
      return (
        <Toolbar className="table_toolbar">
          <Controls.Input
            label={"Search"}
            className="searchInput"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
            onChange={handleSearch}
          />
          <Grid sm item />
          <Badge color="primary" variant="dot" invisible={!isFiltered}>
            <Controls.Button
              text="Filter"
              variant="outlined"
              startIcon={<TuneIcon className="filter_icon" />}
              onClick={() => {
                toggleDrawer(true, "FILTER", {});
              }}
              className="filter"
              style={{
                border: isFiltered ? "1px solid #2770D8" : "1px solid #A3B6C7",
              }}
            />
          </Badge>
        </Toolbar>
      );
    },
    [isFiltered]
  );

  const actionItems = useMemo(() => {
    let actions = [
      {
        type: "View More",
        label: "View More",
        icon: () => (
          <Tooltip arrow TransitionComponent={Zoom} title="View More Details">
            <InfoOutlinedIcon fontSize="small" />
          </Tooltip>
        ),
        onClick: handleViewMoreInfo,
      },
    ];
    actions.push({
      type: "Delete",
      label: "Delete",
      icon: () => (
        <Tooltip arrow TransitionComponent={Zoom} title="Cancel">
          <DeleteIcon fontSize="small" />
        </Tooltip>
      ),
      onClick: handleCancelScheduledService,
    });
    return actions?.length > 0 ? actions : null;
  }, []);

  const {
    tableContainer: TableContainer,
    tableHead: TableHead,
    tableBody: TableBody,
    tablePagination: TablePagination,
  } = useTable(filteredData, headCells, filterFn, false, false, actionItems);

  return (
    <PageMainContent
      extraStyles={{
        boxShadow: "none",
        backgroundColor: "#F5F5F5",
        margin: 0,
      }}
    >
      <OpsBreadcrumb
        AllBreadcrumbsLinks={allBreadcrumbsLinks}
        title="Scheduled Services"
      />
      <Loader isLoading={loading} />
      <p className="section_title">Scheduled services</p>
      <div className="serviceResponseTable">
        <ToolbarMemoised handleSearch={handleSearch} />
        <TableContainer>
          <TableHead />
          {TableBody}
        </TableContainer>
        {TablePagination}
      </div>
      <NewCommonFilterDrawer DrawerOC={drawerObj} toggleDrawer={toggleDrawer}>
        {drawerObj?.title === "FILTER" ? (
          <ServicesFilter
            allDepotTypes={allDepotTypes}
            setAllDepotTypes={setAllDepotTypes}
            allAccounts={allAccounts}
            setAllAccounts={setAllAccounts}
            clearAll={clearAll}
            applyFilter={applyFilter}
          />
        ) : (
          <ScheduledServicesDrawer drawerObj={drawerObj?.data} />
        )}
      </NewCommonFilterDrawer>
      <ConfirmDialog
        confirmDialog={confirmDialog}
        setConfirmDialog={setConfirmDialog}
        buttonsPosition="reverse"
      />
    </PageMainContent>
  );
}
