import React, { useEffect, useState, PureComponent, useRef } from "react";
import {
  Grid,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
  FormLabel,
  Chip,
  Stack,
  InputLabel,
  TextField,
  CircularProgress,
  Select as MuiSelect,
  MenuItem,
  IconButton,
} from "@mui/material";
import DownloadTwoToneIcon from "@mui/icons-material/DownloadTwoTone";
import Controls from "../../components/controls/Controls";
import { useDispatch, useSelector } from "react-redux";
import { commonApiActionCreator } from "../../redux-state/actions";
import { bindActionCreators } from "redux";
import Loader from "../../components/Loader";
import { LocalizationProvider, DatePicker } from "@mui/x-date-pickers";
import format from "date-fns/format";
import sub from "date-fns/sub";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import MultilineChartIcon from "@mui/icons-material/MultilineChart";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Brush,
  Tooltip,
  Legend,
  ReferenceLine,
  ResponsiveContainer,
} from "recharts";
import {
  getAllCircuits,
  getDepotMaxValues,
  getLoadMonitoring,
} from "./circuitService";
import { getChargerProfile } from "../charger-health/charger-firmware-details/chargerFirmwareService";
import { fromUnixTime } from "date-fns";
import ShowChartIcon from "@mui/icons-material/ShowChart";
import HorizontalRuleIcon from "@mui/icons-material/HorizontalRule";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import { CLLMDATA } from "./CLLMdata";
import { scaleLog, scaleSymlog, scalePow } from "d3-scale";
import { TimerFunction, useDebounce } from "./hooks";
import ZoomOutMapIcon from "@mui/icons-material/ZoomOutMap";
import ZoomInMapIcon from "@mui/icons-material/ZoomInMap";
import { CSVDownload, CSVLink } from "react-csv";
import { Palette } from "@mui/icons-material";

const CircuitMonitorModel = () => {
  const dispatch = useDispatch();
  const { saveAllCompaniesV2Global } = bindActionCreators(
    commonApiActionCreator,
    dispatch
  );
  const { allCompaniesV2, isCompaniesLoaded } = useSelector(
    (state) => state.getAllCompaniesV2_Global
  );

  const [allAccounts, setAllAccounts] = useState([]);
  const [allAccountNames, setAllAccountNames] = useState([]);
  const [allDepotNames, setAllDepotNames] = useState([]);
  const [companiesDataLoading, setCompaniesDataLoading] = useState(false);
  const [selectedAccountName, setSelectedAccountName] = useState("");
  const [selectedDepotName, setSelectedDepotName] = useState("");
  const [selectedDepotID, setSelectedDepotID] = useState("");
  const [circuitChipData, setCircuitChipData] = useState([]);
  const [profileChipData, setProfileChipData] = useState([]);
  const [cORp, setCORp] = useState("DEPOT");
  const [interval, setInterval] = useState(60);
  const [debounceState, setDebounceState] = useState(0);
  const debounceValue = useDebounce(debounceState);
  const [chips, setChips] = useState([]);
  const [fromDate, setFromDate] = useState(null);
  const [toDate, setToDate] = useState(null);
  const [chartData, setchartData] = useState([]);
  const [dateType, setDatetype] = useState("");
  const [siteCapacityLimit, setSiteCapacityLimit] = useState(0);
  const [powerLimit, setPowerLimit] = useState(0);
  const [multiPowerLimit, setMultiPowerLimit] = useState(false);

  const [gridSizes, setGridSizes] = useState([6]);

  const toggleGridSize = (index) => {
    setGridSizes((prevSizes) => {
      const updatedSizes = [...prevSizes];
      updatedSizes[index] = updatedSizes[index] === 6 ? 12 : 6;
      return updatedSizes;
    });
  };

  const intervalOptions = [
    {
      label: "15 Sec",
      value: 15,
    },
    {
      label: "1 Min",
      value: 60,
    },
    {
      label: "5 Min",
      value: 300,
    },
    {
      label: "15 Min",
      value: 900,
    },
    {
      label: "1 Hour",
      value: 3600,
    },
  ];

  const monitorByOptions = [
    {
      label: "DEPOT",
      label: "DEPOT",
    },
    {
      label: "CIRCUIT",
      label: "CIRCUIT",
    },
    {
      label: "PROFILES",
      label: "PROFILES",
    },
  ];

  const dateOptions = [
    {
      label: "Live Tail",
      value: "Live Tail",
    },
    {
      label: "Past 15 Minutes",
      value: "Past 15 Minutes",
    },
    {
      label: "Past 1 Hour",
      value: "Past 1 Hour",
    },
    {
      label: "Past 4 Hour",
      value: "Past 4 Hour",
    },
    {
      label: "Past 1 Day",
      value: "Past 1 Day",
    },
    {
      label: "Past 3 Days",
      value: "Past 3 Days",
    },
    {
      label: "Past 7 Days",
      value: "Past 7 Days",
    },
    {
      label: "Past 15 Days",
      value: "Past 15 Days",
    },
    {
      label: "Past 1 Month",
      value: "Past 1 Month",
    },
  ];

  React.useEffect(() => {
    if (isCompaniesLoaded === false) {
      saveAllCompaniesV2Global();
    }
    getCompaniesRawData();
  }, [isCompaniesLoaded]);

  const getCompaniesRawData = async () => {
    setCompaniesDataLoading(true);
    let tempAllCompaniesV2 = JSON.parse(JSON.stringify(allCompaniesV2));
    if (tempAllCompaniesV2.length !== 0) {
      const onlyDepotsList = [];
      tempAllCompaniesV2.map((single) => {
        if (single.depotsList) {
          onlyDepotsList.push(...single.depotsList);
        }
      });
      let tempAccounts = [
        {
          name: "Account and Depot",
          children: tempAllCompaniesV2,
        },
      ];
      setCompaniesDataLoading(false);
      setAllAccounts(tempAccounts);
    }
  };
  React.useEffect(() => {
    setAllAccountNames(
      allAccounts[0]?.children?.map((el) => ({
        label: el.companyName,
        value: el.companyName,
      }))
    );
  }, [allAccounts]);

  React.useEffect(() => {
    if (selectedAccountName) {
      setAllDepotNames(
        allAccounts[0]?.children
          .find((obj) => obj.companyName === selectedAccountName)
          ?.depotsList?.map((el) => ({
            label: el.depotName,
            value: el.depotName,
          }))
      );
    }
  }, [selectedAccountName]);

  React.useEffect(async () => {
    if (selectedDepotID) {
      setchartData([]);

      const res = await getAllCircuits(selectedDepotID);
      if (res.statusCode >= 200 && res.statusCode <= 299) {
        setCircuitChipData([
          {
            circuitName: "ALL",
            circuitId: "ALL",
          },
          ...res.data,
        ]);
      } else {
        setCircuitChipData([]);
      }

      const resProfile = await getChargerProfile(selectedDepotID);
      if (resProfile.statusCode >= 200 && resProfile.statusCode <= 299) {
        setProfileChipData([
          {
            name: "ALL",
            profileId: "ALL",
          },
          ...resProfile.data,
        ]);
      } else {
        setProfileChipData([]);
      }

      const resMaxValues = await getDepotMaxValues(selectedDepotID);
      if (resMaxValues.statusCode >= 200 && resMaxValues.statusCode <= 299) {
        setSiteCapacityLimit(resMaxValues?.data?.siteCapacity);
        setPowerLimit(resMaxValues?.data?.powerLimit);
        setMultiPowerLimit(resMaxValues?.data?.hasMultiplePowerLimits);
      } else {
        setSiteCapacityLimit(0);
        setPowerLimit(0);
        setMultiPowerLimit(false);
      }
    } else {
      setCircuitChipData([]);
      setProfileChipData([]);
    }
  }, [selectedDepotID]);

  React.useEffect(() => {
    if (cORp) {
      if (cORp == "CIRCUIT") {
        if (circuitChipData.length) {
          setChips([
            ...circuitChipData?.map((item) => ({
              ...item,
              name: item.circuitName,
              id: item.circuitId,
              clicked: false,
            })),
          ]);
        }
      } else if (cORp == "DEPOT") {
        setChips([]);
      } else {
        if (profileChipData.length) {
          setChips([
            ...profileChipData?.map(({ name, profileId }) => ({
              name: name,
              id: profileId,
              clicked: false,
            })),
          ]);
        }
      }
    } else {
      setChips([]);
    }
  }, [cORp]);

  const getDepotID = (name) => {
    if (name) {
      const depotId = allAccounts[0]?.children?.reduce((result, item) => {
        const depot = item?.depotsList?.find((dep) => dep?.depotName === name);
        return depot ? depot?.depotId : result;
      }, null);
      setSelectedDepotID(depotId);
    } else {
      setSelectedDepotID("");
    }
  };

  const chipFilter = (e) => {
    setChips((prevData) => {
      const newClickedValue = e === "ALL" ? !prevData[0]?.clicked : undefined;

      const updatedChips = prevData.map((item) =>
        e === "ALL"
          ? { ...item, clicked: newClickedValue }
          : item.id === e
          ? { ...item, clicked: !item.clicked }
          : item
      );
      const allClickedTrueExceptALL = updatedChips
        .slice(1)
        .every((item) => item.clicked);
      const anyClickedFalseExceptALL = updatedChips
        .slice(1)
        .some((item) => !item.clicked);
      const allClickedValue = !anyClickedFalseExceptALL;
      updatedChips[0].clicked = allClickedValue;

      return updatedChips;
    });
  };

  const handleFromDate = (e) => {
    setFromDate(new Date(e).toISOString());
  };

  const handleToDate = (e) => {
    setToDate(new Date(e).toISOString());
  };

  const getProfileOrCircuteName = (id) => {
    if (cORp == "PROFILES" || cORp == "CIRCUIT") {
      return chips?.find((item) => item?.id === id)?.name;
    } else {
      return null;
    }
  };
  const yourFunctionToCall = async () => {
    if (dateType == "Live Tail") {
      if (canExcute(selectedDepotID, cORp, fromDate, toDate, interval)) {
        // Add your logic here
        let body = {
          siteID: selectedDepotID,
          startDate: Math.floor(
            sub(new Date(Math.floor(new Date().getTime())), {
              seconds: 90,
            }).getTime() / 1000
          ),
          endDate: Math.floor(new Date().getTime() / 1000),
          groupByKey: cORp,
          intervals: interval,
        };

        Math.floor(
          sub(new Date(Math.floor(new Date().getTime())), {
            minutes: 1,
          }).getTime() / 1000
        );

        if (chips.length !== 0) {
          if (cORp == "CIRCUIT") {
            body["groupByValue"] = chips
              ?.filter((obj) => obj.id !== "ALL" && obj.clicked)
              ?.map((obj) => obj.id);
          } else if (cORp == "PROFILES") {
            body["groupByValue"] = chips
              ?.filter((obj) => obj.id !== "ALL" && obj.clicked)
              ?.map((obj) => obj.id);
          } else if (cORp == "DEPOT") {
            body["groupByValue"] = [selectedDepotID];
          }
        } else {
          body["groupByValue"] = [selectedDepotID];
        }
        const res = await getLoadMonitoring(body);
        {
          if (res.statusCode >= 200 && res.statusCode <= 299) {
            if (res?.data?.length) {
              const getTime = (obj) => obj?.time;
              res?.data?.forEach((obj) =>
                obj?.data?.sort((a, b) => getTime(a) - getTime(b))
              );
              const newData = res?.data?.map((item) => {
                const newDataItem = {
                  data: item?.data?.map((dataItem) => {
                    const formattedTime = dataItem?.time
                      ? format(fromUnixTime(dataItem?.time), "dd MMM, hh:mm a")
                      : "";

                    const mappedData = {
                      currentImport:
                        dataItem?.plotValue?.currentImport?.value <= 0
                          ? ""
                          : dataItem?.plotValue?.currentImport?.value,
                      currentOffered:
                        dataItem?.plotValue?.currentOffered?.value <= 0
                          ? ""
                          : dataItem?.plotValue?.currentOffered?.value,
                      powerActiveImport:
                        dataItem?.plotValue?.powerActiveImport?.value <= 0
                          ? ""
                          : dataItem?.plotValue?.powerActiveImport?.value,
                      powerOffered:
                        dataItem?.plotValue?.powerOffered?.value <= 0
                          ? ""
                          : dataItem?.plotValue?.powerOffered?.value,
                      time: formattedTime,
                    };

                    // Conditionally add "powerLimit" if it is not undefined
                    if (dataItem?.powerLimit !== undefined) {
                      mappedData.powerLimit =
                        dataItem?.powerLimit == 0
                          ? ""
                          : (dataItem?.powerLimit * 1000) / 240;
                    }

                    return mappedData;
                  }),
                  group: item?.group,
                };

                return newDataItem;
              });

              setchartData((prevData) => {
                // Check if newData is empty or doesn't have the 'data' key
                if (
                  !newData ||
                  !newData.length ||
                  !newData[0].data ||
                  !newData[0].data.length
                ) {
                  return prevData;
                }

                return prevData.map((group) => {
                  const matchingNewData = newData.find(
                    (newGroup) => newGroup.group === group.group
                  );

                  if (
                    matchingNewData &&
                    matchingNewData.data &&
                    matchingNewData.data.length
                  ) {
                    // Limit the length of data array to a maximum of ten items
                    const updatedData = [
                      ...group.data,
                      ...matchingNewData.data,
                    ].slice(-10);

                    // Append new data's data to old data's data for the matching group
                    return {
                      ...group,
                      data: updatedData,
                    };
                  }
                  return group;
                });
              });
            }
          } else {
          }
        }
      }
    }
  };
  TimerFunction(yourFunctionToCall, 60000);

  React.useEffect(async () => {
    if (canExcute(selectedDepotID, cORp, fromDate, toDate, interval)) {
      setCompaniesDataLoading(true);
      let body = {
        siteID: selectedDepotID,
        startDate: fromDate,
        endDate: toDate,
        groupByKey: cORp,
        intervals: interval,
      };

      if (chips.length !== 0) {
        if (cORp == "CIRCUIT") {
          body["groupByValue"] = chips
            ?.filter((obj) => obj.id !== "ALL" && obj.clicked)
            ?.map((obj) => obj.id);
        } else if (cORp == "PROFILES") {
          body["groupByValue"] = chips
            ?.filter((obj) => obj.id !== "ALL" && obj.clicked)
            ?.map((obj) => obj.id);
        } else if (cORp == "DEPOT") {
          body["groupByValue"] = [selectedDepotID];
        }
      } else {
        body["groupByValue"] = [selectedDepotID];
      }
      const res = await getLoadMonitoring(body);
      {
        if (res.statusCode >= 200 && res.statusCode <= 299) {
          if (res?.data?.length) {
            const getTime = (obj) => obj?.time;
            res?.data?.forEach((obj) =>
              obj?.data?.sort((a, b) => getTime(a) - getTime(b))
            );
            const newData = res?.data?.map((item) => {
              const newDataItem = {
                data: item?.data?.map((dataItem) => {
                  const formattedTime = dataItem?.time
                    ? format(fromUnixTime(dataItem?.time), "dd MMM, hh:mm a")
                    : "";

                  const mappedData = {
                    currentImport:
                      dataItem?.plotValue?.currentImport?.value <= 0
                        ? ""
                        : dataItem?.plotValue?.currentImport?.value,
                    currentOffered:
                      dataItem?.plotValue?.currentOffered?.value <= 0
                        ? ""
                        : dataItem?.plotValue?.currentOffered?.value,
                    powerActiveImport:
                      dataItem?.plotValue?.powerActiveImport?.value <= 0
                        ? ""
                        : dataItem?.plotValue?.powerActiveImport?.value,
                    powerOffered:
                      dataItem?.plotValue?.powerOffered?.value <= 0
                        ? ""
                        : dataItem?.plotValue?.powerOffered?.value,
                    time: formattedTime,
                  };

                  // Conditionally add "powerLimit" if it is not undefined
                  if (dataItem?.powerLimit !== undefined) {
                    mappedData.powerLimit =
                      dataItem?.powerLimit == 0
                        ? ""
                        : (dataItem?.powerLimit * 1000) / 240;
                  }

                  return mappedData;
                }),
                group: item?.group,
              };

              return newDataItem;
            });
            setchartData(newData);
          } else {
            setchartData([]);
          }

          setCompaniesDataLoading(false);
        } else {
          setchartData([]);

          setCompaniesDataLoading(false);
        }
      }
    }
  }, [debounceValue]);

  React.useEffect(async () => {
    if (canExcute(selectedDepotID, cORp, fromDate, toDate, interval)) {
      setDebounceState((prevCounter) => prevCounter + 1);
    }
  }, [selectedDepotID, cORp, fromDate, interval, chips]);

  const canExcute = (selectedDepotID, cORp, fromDate, toDate, interval) => {
    if (selectedDepotID && cORp && fromDate && toDate && interval) {
      if (cORp == "CIRCUIT" || cORp == "PROFILES") {
        if (
          chips?.filter((obj) => obj.clicked)?.map((obj) => obj.id).length == 0
        ) {
          return false;
        } else {
          return true;
        }
      } else {
        return true;
      }
    } else {
      return false;
    }
  };

  React.useEffect(() => {
    if (dateType) {
      let tempFromDate = [];
      let tempToDate = [];
      if (dateType == "Past 15 Minutes") {
        tempFromDate.push(
          Math.floor(
            sub(new Date(Math.floor(new Date().getTime())), {
              minutes: 15,
            }).getTime() / 1000
          )
        );
        setFromDate(tempFromDate[0]);
        setToDate(Math.floor(new Date().getTime() / 1000));
      } else if (dateType == "Past 1 Hour") {
        tempFromDate.push(
          Math.floor(
            sub(new Date(Math.floor(new Date().getTime())), {
              hours: 1,
            }).getTime() / 1000
          )
        );
        setFromDate(tempFromDate[0]);
        setToDate(Math.floor(new Date().getTime() / 1000));
      } else if (dateType == "Past 4 Hour") {
        tempFromDate.push(
          Math.floor(
            sub(new Date(Math.floor(new Date().getTime())), {
              hours: 4,
            }).getTime() / 1000
          )
        );
        setFromDate(tempFromDate[0]);
        setToDate(Math.floor(new Date().getTime() / 1000));
      } else if (dateType == "Past 1 Day") {
        tempFromDate.push(
          Math.floor(
            sub(new Date(Math.floor(new Date().getTime())), {
              hours: 24,
            }).getTime() / 1000
          )
        );
        setFromDate(tempFromDate[0]);
        setToDate(Math.floor(new Date().getTime() / 1000));
      } else if (dateType == "Past 3 Days") {
        tempFromDate.push(
          Math.floor(
            sub(new Date(Math.floor(new Date().getTime())), {
              days: 3,
            }).getTime() / 1000
          )
        );
        setFromDate(tempFromDate[0]);
        setToDate(Math.floor(new Date().getTime() / 1000));
      } else if (dateType == "Past 7 Days") {
        tempFromDate.push(
          Math.floor(
            sub(new Date(Math.floor(new Date().getTime())), {
              days: 7,
            }).getTime() / 1000
          )
        );
        setFromDate(tempFromDate[0]);
        setToDate(Math.floor(new Date().getTime() / 1000));
      } else if (dateType == "Past 15 Days") {
        tempFromDate.push(
          Math.floor(
            sub(new Date(Math.floor(new Date().getTime())), {
              days: 15,
            }).getTime() / 1000
          )
        );
        setFromDate(tempFromDate[0]);
        setToDate(Math.floor(new Date().getTime() / 1000));
      } else if (dateType == "Past 1 Month") {
        tempFromDate.push(
          Math.floor(
            sub(new Date(Math.floor(new Date().getTime())), {
              months: 1,
            }).getTime() / 1000
          )
        );
        setFromDate(tempFromDate[0]);
        setToDate(Math.floor(new Date().getTime() / 1000));
      } else if (dateType == "Live Tail") {
        tempFromDate.push(
          Math.floor(
            sub(new Date(Math.floor(new Date().getTime())), {
              minutes: 10,
            }).getTime() / 1000
          )
        );
        setFromDate(tempFromDate[0]);
        setToDate(Math.floor(new Date().getTime() / 1000));
      }
    }
  }, [dateType, selectedDepotID, cORp, fromDate, interval, chips]);

  class CustomizedAxisTick extends PureComponent {
    render() {
      const { x, y, stroke, payload } = this.props;

      return (
        <g transform={`translate(${x},${y})`}>
          <text
            x={0}
            y={0}
            dy={10}
            fontSize={10}
            color="#3c5164"
            textAnchor="middle"
            fill="#666"
            transform="rotate(0)"
          >
            {payload.value}
          </text>
        </g>
      );
    }
  }

  const CustomTooltipCurrent = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
      return (
        <div className="custom-tooltip">
          <p className="label">{`Time : ${label}`}</p>
          <p className="dis">{`Current offered : ${
            payload[0].value == "" ? 0 : payload[0].value
          }A`}</p>
          <p className="dis">{`Current import : ${
            payload[1].value == "" ? 0 : payload[1].value
          }A`}</p>
        </div>
      );
    }

    return null;
  };

  const CustomTooltipPower = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
      return (
        <div className="custom-tooltip">
          <p className="label">{`Time : ${label}`}</p>
          <p className="dis">{`Power offered : ${
            payload[0].value == "" ? 0 : payload[0].value
          }kW`}</p>
          <p className="dis">{`Power active import : ${
            payload[1].value == "" ? 0 : payload[1].value
          }kW`}</p>
          {payload[2]?.value && (
            <p className="dis">{`${
              cORp == "DEPOT" ? "Site capacity" : "Power limit"
            } : ${payload[2].value == "" ? 0 : payload[2].value}kW`}</p>
          )}
        </div>
      );
    }

    return null;
  };

  const DHeaders = [
    { label: "Current Import(A)", key: "currentImport" },
    { label: "Current Offered(A)", key: "currentOffered" },
    { label: "Power Active Import(kW)", key: "powerActiveImport" },
    { label: "Power Offered(kW)", key: "powerOffered" },
    { label: "Time", key: "time" },
  ];

  const formatDataForExcel = (data) => {
    const result = data.map(
      ({
        currentImport,
        currentOffered,
        powerActiveImport,
        powerOffered,
        time,
      }) => ({
        currentImport: currentImport === "" ? 0 : currentImport,
        currentOffered: currentOffered === "" ? 0 : currentOffered,
        powerActiveImport: powerActiveImport === "" ? 0 : powerActiveImport,
        powerOffered: powerOffered === "" ? 0 : powerOffered,
        time,
      })
    );

    return result;
  };

  return (
    <>
      <div>
        <div className="circuit_monitor">
          <Grid container spacing={2}>
            <Grid item xs={2}>
              <div>
                <Controls.NewSelect
                  className="width100"
                  label="Account *"
                  name="accountName"
                  options={allAccountNames?.map((item) => item.label)}
                  value={selectedAccountName}
                  onChange={(e) => {
                    setSelectedAccountName(e.target.value);
                    setSelectedDepotName("");
                    setSelectedDepotID("");
                    setCORp("DEPOT");
                  }}
                />
              </div>
            </Grid>
            <Grid item xs={2}>
              <div>
                <Controls.NewSelect
                  label="Depot *"
                  name="depotName"
                  options={allDepotNames?.map((item) => item.label)}
                  value={selectedDepotName}
                  onChange={(e) => {
                    setSelectedDepotName(e.target.value);
                    getDepotID(e.target.value);
                    setCORp("DEPOT");
                  }}
                />
              </div>
            </Grid>
            <Grid item xs={2}>
              <div>
                <FormControl
                  variant="outlined"
                  fullWidth="true"
                  size="small"
                  className="newSelect"
                >
                  <InputLabel>Monitor By</InputLabel>
                  <MuiSelect
                    label="Monitor By"
                    style={{ fontSize: "0.8rem", lineHeight: "1.2" }}
                    name={cORp}
                    value={cORp}
                    onChange={(e) => {
                      setCORp(e.target.value);
                    }}
                    size="small"
                    MenuProps={{ "data-testid": `${name}Options` }}
                  >
                    <MenuItem value={"DEPOT"}>Depot</MenuItem>
                    <MenuItem
                      value={"CIRCUIT"}
                      disabled={circuitChipData?.length < 2}
                    >
                      Circuit
                    </MenuItem>
                    <MenuItem
                      value={"PROFILES"}
                      disabled={profileChipData?.length < 2}
                    >
                      Profiles
                    </MenuItem>
                  </MuiSelect>
                </FormControl>
              </div>
            </Grid>

            <Grid item xs={2}></Grid>
            <Grid item xs={2}>
              <div>
                <Controls.NewSelect
                  className="width100"
                  label="Interval"
                  name="interval"
                  options={intervalOptions?.map((item) => item.label)}
                  value={
                    intervalOptions.find((item) => item.value === interval)
                      ?.label
                  }
                  disabled={dateType == "Live Tail" ? true : false}
                  onChange={(e) => {
                    let x = intervalOptions.find(
                      (item) => item.label === e.target.value
                    )?.value;
                    setInterval(x);
                  }}
                />
              </div>
            </Grid>
            <Grid item xs={2}>
              <div>
                <div>
                  <Controls.NewSelect
                    className="width100"
                    label="Duration"
                    name="duration"
                    options={dateOptions?.map((item) => item.label)}
                    value={dateType}
                    onChange={(e) => {
                      setDatetype(e.target.value);
                      if (e.target.value == "Live Tail") {
                        setInterval(60);
                      }
                    }}
                  />
                </div>
                {/* {dateType == "Select From Calendar" && (
                  <div style={{ display: "flex" }}>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <DatePicker
                        className="mui-date-picked-reports"
                        id="date-picker-dialog"
                        label="From Date"
                        style={{ margin: "0 5px 0 0" }}
                        inputVariant="outlined"
                        format="MM/dd/yyyy"
                        value={fromDate}
                        onChange={(e) => handleFromDate(e)}
                        InputLabelProps={{
                          shrink: fromDate ? true : false,
                        }}
                        minDate={new Date(sub(new Date(Math.floor(new Date().getTime())), {
                          days: 30,
                        }).toISOString())}
                        maxDate={toDate ? new Date(toDate) : new Date()}
                        renderInput={(params) => (
                          <TextField
                            size="small"
                            fullWidth
                            {...params}
                            inputProps={{
                              ...params.inputProps,
                              readOnly: true,
                            }}
                          />
                        )}
                      />

                      <DatePicker
                        className="mui-date-picked-reports"
                        id="date-picker-dialog"
                        label="To Date"
                        inputVariant="outlined"
                        style={{ margin: "0 0 0 5px" }}
                        format="MM/dd/yyyy"
                        value={toDate}
                        onChange={(e) => handleToDate(e)}
                        InputLabelProps={{
                          shrink: toDate ? true : false,
                        }}
                        minDate={fromDate ? new Date(fromDate) : undefined}
                        maxDate={new Date()}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            size="small"
                            fullWidth
                            inputProps={{
                              ...params.inputProps,
                              readOnly: true,
                            }}
                          />
                        )}
                      />
                    </LocalizationProvider>
                  </div>
                )} */}
              </div>
            </Grid>
          </Grid>
          <Grid container>
            <Grid item xs={12}>
              <div className="circuit_chips">
                {chips?.length ? (
                  <>
                    <div className="chip_sec">
                      <p className="chipTitle">{cORp}</p>
                      <Stack direction="row" flexWrap="wrap" spacing={1}>
                        {chips?.map((e, index) => (
                          <Chip
                            style={{ marginBottom: "5px", fontSize: "0.7rem" }}
                            variant={e.clicked ? "filled" : "outlined"}
                            label={e.name}
                            key={e.id}
                            clickable
                            onClick={() => chipFilter(e.id)}
                          />
                        ))}
                      </Stack>
                    </div>
                  </>
                ) : null}
              </div>
            </Grid>
          </Grid>
        </div>

        {chartData.length != 0 &&
          chartData.map((single, index) => {
            return (
              <div className="circuit_monitor_chart" key={index}>
                <div className="DCP_export">
                  <p className="DCP_name">
                    {single.group == "all"
                      ? "Depot"
                      : getProfileOrCircuteName(single.group)}
                  </p>

                  {single?.data.length != 0 && (
                    <CSVLink
                      data={formatDataForExcel(single?.data)}
                      headers={DHeaders}
                      filename={
                        single?.group == "all"
                          ? "Depot"
                          : getProfileOrCircuteName(single.group)
                      }
                      data-testid="csvLink"
                    >
                      <IconButton>
                        <DownloadTwoToneIcon />
                      </IconButton>
                      {/* <Controls.Button className="exportBtn" text={"Export"} /> */}
                    </CSVLink>
                  )}
                </div>
                <Grid container spacing={2}>
                  <Grid item xs={gridSizes[index] ? gridSizes[index] : 6}>
                    {companiesDataLoading ? (
                      <div className="loading_main">
                        <div className="loading_cllm">
                          <CircularProgress size={30} />
                        </div>
                      </div>
                    ) : single?.data.length != 0 ? (
                      <div className="chart_layout">
                        <div className="legendsOfGraph">
                          <div className="legends">
                            <p className="custom-paragraph currentImport">
                              <span>Current import(A)</span>
                            </p>

                            <p className="custom-paragraph currentOffered">
                              <span>Current offered(A)</span>
                            </p>
                          </div>
                          {gridSizes[index] == 6 ? (
                            <ZoomOutMapIcon
                              onClick={() => toggleGridSize(index)}
                              className="zoomInOut"
                            />
                          ) : (
                            <ZoomInMapIcon
                              onClick={() => toggleGridSize(index)}
                              className="zoomInOut"
                            />
                          )}
                        </div>

                        <ResponsiveContainer width="100%" height="100%">
                          <LineChart
                            data={single?.data}
                            margin={{
                              top: 20,
                              right: 20,
                              left: 10,
                              bottom: 20,
                            }}
                          >
                            <XAxis
                              dataKey="time"
                              tick={<CustomizedAxisTick />}
                              label={{
                                value: `Time Duration`,
                                style: {
                                  textAnchor: "middle",
                                  fontSize: "10px",
                                  fill: "#3c5164",
                                },
                                position: "bottom",
                                offset: 0,
                              }}
                            />
                            <YAxis
                              label={{
                                value: `Current(A)`,
                                style: {
                                  textAnchor: "middle",
                                  fontSize: "10px",
                                  fill: "#3c5164",
                                },
                                angle: -90,
                                position: "left",
                                offset: 0,
                              }}
                              scale="log"
                              domain={["auto", "auto"]}
                              type="number"
                              id="currentImport"
                              tickFormatter={(value) => {
                                if (value % 1 !== 0) {
                                  // Apply formatting only for decimal values
                                  return value.toFixed(1);
                                }
                                return value;
                              }}
                              style={{ fontSize: "10px", color: "#3c5164" }}
                            />
                            <Tooltip content={<CustomTooltipCurrent />} />
                            {cORp == "DEPOT" ? (
                              <>
                                {siteCapacityLimit && (
                                  <ReferenceLine
                                    ifOverflow="extendDomain"
                                    y={
                                      siteCapacityLimit
                                        ? (siteCapacityLimit * 1000) / 240
                                        : 0
                                    }
                                    stroke="red"
                                    strokeDasharray="3 3"
                                    label={{
                                      value: `Site Capacity: ${
                                        siteCapacityLimit
                                          ? (
                                              (siteCapacityLimit * 1000) /
                                              240
                                            ).toFixed(2)
                                          : 0
                                      }A`,
                                      fontWeight: "500",
                                      fontSize: "10px",
                                      position: "insideRight",
                                      dy: -10,
                                      dx: 2,
                                      fill: "red",
                                    }}
                                  />
                                )}
                              </>
                            ) : cORp == "CIRCUIT" ? (
                              <>
                                (
                                <ReferenceLine
                                  ifOverflow="extendDomain"
                                  y={
                                    chips?.find(
                                      (item) => item.id === single.group
                                    )?.maxAmps
                                  }
                                  stroke="red"
                                  strokeDasharray="3 3"
                                  label={{
                                    value: `Total Circuit Capacity: ${
                                      chips?.find(
                                        (item) => item.id === single.group
                                      )?.maxAmps
                                    }A`,
                                    fontWeight: "500",
                                    fontSize: "10px",
                                    position: "insideLeft",
                                    dy: -10,
                                    dx: 2,
                                    fill: "red",
                                  }}
                                />
                                ) (
                                <ReferenceLine
                                  ifOverflow="extendDomain"
                                  y={
                                    chips?.find(
                                      (item) => item.id === single.group
                                    )?.maxAmps * 0.8
                                  }
                                  stroke="#c7b300"
                                  strokeDasharray="3 3"
                                  label={{
                                    value: `Available Circuit Capacity: ${
                                      chips?.find(
                                        (item) => item.id === single.group
                                      )?.maxAmps * 0.8
                                    }A`,
                                    fontWeight: "500",
                                    fontSize: "10px",
                                    position: "insideRight",
                                    dy: -10,
                                    dx: 2,
                                    fill: "#c7b300",
                                  }}
                                />
                                )
                              </>
                            ) : cORp == "PROFILES" ? (
                              <>
                                {powerLimit && (
                                  <ReferenceLine
                                    ifOverflow="extendDomain"
                                    y={
                                      powerLimit ? (powerLimit * 1000) / 240 : 0
                                    }
                                    stroke="red"
                                    strokeDasharray="3 3"
                                    label={{
                                      value: `Power limit: ${
                                        powerLimit
                                          ? (powerLimit * 1000) / 240
                                          : 0
                                      }A`,
                                      fontWeight: "500",
                                      fontSize: "10px",
                                      position: "insideLeft",
                                      dy: -10,
                                      dx: 2,
                                      fill: "red",
                                    }}
                                  />
                                )}
                              </>
                            ) : null}

                            <Brush
                              dataKey="time"
                              height={10}
                              stroke="#0051A2"
                            />
                            <Line
                              type="monotone"
                              dataKey="currentOffered"
                              stroke="#0051A2"
                              dot={false}
                            />
                            <Line
                              type="monotone"
                              dataKey="currentImport"
                              stroke="#82ca9d"
                              dot={false}
                            />
                          </LineChart>
                        </ResponsiveContainer>
                      </div>
                    ) : (
                      <div className="no_data_main">
                        <div className="no_data">
                          <MultilineChartIcon />
                          <p>No Data</p>
                        </div>
                      </div>
                    )}
                  </Grid>
                  <Grid item xs={gridSizes[index] ? gridSizes[index] : 6}>
                    {companiesDataLoading ? (
                      <div className="loading_main">
                        <div className="loading_cllm">
                          <CircularProgress size={30} />
                        </div>
                      </div>
                    ) : single?.data.length != 0 ? (
                      <div className="chart_layout">
                        <div className="legendsOfGraph">
                          <div className="legends">
                            <p className="custom-paragraph powerActiveImport">
                              <span>Power active import(kW)</span>
                            </p>
                            <p className="custom-paragraph powerOffered ">
                              <span>Power offered(kW)</span>
                            </p>
                          </div>
                          {gridSizes[index] == 6 ? (
                            <ZoomOutMapIcon
                              onClick={() => toggleGridSize(index)}
                              className="zoomInOut"
                            />
                          ) : (
                            <ZoomInMapIcon
                              onClick={() => toggleGridSize(index)}
                              className="zoomInOut"
                            />
                          )}
                        </div>

                        <ResponsiveContainer width="100%" height="100%">
                          <LineChart
                            data={single?.data}
                            margin={{
                              top: 20,
                              right: 20,
                              left: 10,
                              bottom: 20,
                            }}
                          >
                            <XAxis
                              dataKey="time"
                              tick={<CustomizedAxisTick />}
                              label={{
                                value: `Time Duration`,
                                style: {
                                  textAnchor: "middle",
                                  fontSize: "10px",
                                  fill: "#3c5164",
                                },
                                position: "bottom",
                                offset: 0,
                              }}
                            />
                            <YAxis
                              label={{
                                value: `Power(kW)`,
                                style: {
                                  textAnchor: "middle",
                                  fontSize: "10px",
                                  fill: "#3c5164",
                                },
                                angle: -90,
                                position: "left",
                                offset: 0,
                              }}
                              scale="log"
                              domain={["auto", "auto"]}
                              type="number"
                              id="powerActiveImport"
                              style={{ fontSize: "10px", color: "#3c5164" }}
                              tickFormatter={(value) => {
                                if (value % 1 !== 0) {
                                  // Apply formatting only for decimal values
                                  return value.toFixed(1);
                                }
                                return value;
                              }}
                            />
                            {cORp == "DEPOT" ? (
                              <>
                                {siteCapacityLimit && (
                                  <ReferenceLine
                                    ifOverflow="extendDomain"
                                    y={parseInt(siteCapacityLimit)}
                                    stroke="red"
                                    strokeDasharray="3 3"
                                    label={{
                                      value: `Site capacity: ${parseInt(
                                        siteCapacityLimit
                                      )}kW`,
                                      fontWeight: "500",
                                      fontSize: "10px",
                                      position: "insideRight",
                                      dy: -10,
                                      dx: 2,
                                      fill: "red",
                                    }}
                                  />
                                )}
                              </>
                            ) : cORp == "CIRCUIT" ? (
                              <>
                                (
                                <ReferenceLine
                                  ifOverflow="extendDomain"
                                  y={
                                    (chips?.find(
                                      (item) => item.id === single.group
                                    )?.maxAmps *
                                      chips?.find(
                                        (item) => item.id === single.group
                                      )?.maxVolts) /
                                    1000
                                  }
                                  stroke="red"
                                  strokeDasharray="3 3"
                                  label={{
                                    value: `Total Circuit Capacity: ${(
                                      (chips?.find(
                                        (item) => item.id === single.group
                                      )?.maxAmps *
                                        chips?.find(
                                          (item) => item.id === single.group
                                        )?.maxVolts) /
                                      1000
                                    )?.toFixed(2)}kW`,
                                    fontWeight: "500",
                                    fontSize: "10px",
                                    position: "insideLeft",
                                    dy: -10,
                                    dx: 2,
                                    fill: "red",
                                  }}
                                />
                                ) (
                                <ReferenceLine
                                  ifOverflow="extendDomain"
                                  y={
                                    ((chips?.find(
                                      (item) => item.id === single.group
                                    )?.maxAmps *
                                      chips?.find(
                                        (item) => item.id === single.group
                                      )?.maxVolts) /
                                      1000) *
                                    0.8
                                  }
                                  stroke="#c7b300"
                                  strokeDasharray="3 3"
                                  label={{
                                    value: `Available Circuit Capacity: ${(
                                      ((chips?.find(
                                        (item) => item.id === single.group
                                      )?.maxAmps *
                                        chips?.find(
                                          (item) => item.id === single.group
                                        )?.maxVolts) /
                                        1000) *
                                      0.8
                                    )?.toFixed(2)}kW`,
                                    fontWeight: "500",
                                    fontSize: "10px",
                                    position: "insideRight",
                                    dy: -10,
                                    dx: 2,
                                    fill: "#c7b300",
                                  }}
                                />
                                )
                              </>
                            ) : cORp == "PROFILES" && powerLimit ? (
                              <>
                                {multiPowerLimit == false && (
                                  <ReferenceLine
                                    ifOverflow="extendDomain"
                                    y={powerLimit}
                                    stroke="red"
                                    strokeDasharray="3 3"
                                    label={{
                                      value: `Power limit: ${powerLimit}kW`,
                                      fontWeight: "500",
                                      fontSize: "10px",
                                      position: "insideLeft",
                                      dy: -10,
                                      dx: 2,
                                      fill: "red",
                                    }}
                                  />
                                )}
                              </>
                            ) : null}
                            <Tooltip content={<CustomTooltipPower />} />
                            <Brush
                              dataKey="time"
                              height={10}
                              stroke="#0051A2"
                            />

                            <Line
                              type="monotone"
                              dataKey="powerOffered"
                              stroke="#0051A2"
                              dot={false}
                            />
                            <Line
                              type="monotone"
                              dataKey="powerActiveImport"
                              stroke="#82ca9d"
                              dot={false}
                            />
                            {multiPowerLimit == true && cORp == "PROFILES" && (
                              <Line
                                type="monotone"
                                dataKey="powerLimit"
                                stroke="red"
                                strokeDasharray="3 3"
                                dot={false}
                              />
                            )}
                          </LineChart>
                        </ResponsiveContainer>
                      </div>
                    ) : (
                      <div className="no_data_main">
                        <div className="no_data">
                          <MultilineChartIcon />
                          <p>No Data</p>
                        </div>
                      </div>
                    )}
                  </Grid>
                </Grid>
              </div>
            );
          })}
      </div>
      <Loader isLoading={companiesDataLoading} />
    </>
  );
};

export default CircuitMonitorModel;
