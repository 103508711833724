import ApiCore from "./utilities/core.js";
import { apiProvider, indexedEndPoints } from "./utilities/provider";

const url = "auth/users";
const single = "user/customers";
const {
  AUTH_USERS,
  ROLES,
  USER_LOGIN,
  USER_REGISTER,
  USER_UPDATE_PASSWORD,
  USER_FORGOT_PASSWORD,
} = indexedEndPoints;

const apiUsers = new ApiCore({
  getAll: true,
  post: true,
  url,
  single,
});

apiUsers.forgotPasswordVerifyToken = (payload) =>
  apiProvider.forgotPasswordVerifyToken(
    `${USER_FORGOT_PASSWORD}${payload.email}?pt=${payload.pt}`
  );

apiUsers.forgotPasswordUpdate = (payload) =>
  apiProvider.forgotPasswordUpdate(USER_FORGOT_PASSWORD, payload);

apiUsers.forgotPasswordEmail = (payload) =>
  apiProvider.forgotPasswordEmail(USER_FORGOT_PASSWORD, payload);

apiUsers.login = (payload) => apiProvider.login(USER_LOGIN, payload);

apiUsers.listUsers = () => apiProvider.getAll(`${AUTH_USERS}/`);

apiUsers.roleAllPrivilege = (id) => apiProvider.getAll(`${ROLES}/${id}`);

apiUsers.addUser = (payload) => apiProvider.post(USER_REGISTER, payload);

apiUsers.updateUser = (id, payload) =>
  apiProvider.put(`${AUTH_USERS}/${id}`, payload);

apiUsers.deleteUser = (id) => apiProvider.del(`${AUTH_USERS}/${id}`);

export const updatePassword = async (payload) =>
  await apiProvider.put(USER_UPDATE_PASSWORD, payload);

export default apiUsers;
