import {
  apiProvider,
  indexedEndPoints,
} from "../../services/api/utilities/provider";

const {
  GET_FAULTS_COUNT,
  GET_ALL_DEPOT_FAULTS,
  GET_ALL_V2_COMPANIES,
  DEPOTS,
  ACTIVE_DEPOTS,
  OVERALL_ANALYTICS,
  PERIODICAL_ANALYTICS,
  CHARGING_MODE,
  PORT_SUMMARY,
  INSIGHTS,
  CHARGER_TYPE_COUNT,
  ANALYTICS_DASHBOARD,
  BILLABLE_PORTS,
  BILLABLE_CUSTOMERS_COUNT,
  GET_MAP_DEPOTS,
  FDC_USER_ALERT_METRICS,
} = indexedEndPoints;

export const getFaultsCount = async (payload = {}) =>
  await apiProvider.post(GET_FAULTS_COUNT, payload);

export const getDepotFaults = async (payload = {}) =>
  await apiProvider.post(GET_ALL_DEPOT_FAULTS, payload);

export const getTotalCount = async (payload = {}) =>
  await apiProvider.post(OVERALL_ANALYTICS, payload);

export const getAllCompanies = async () =>
  await apiProvider.getAll(GET_ALL_V2_COMPANIES);

export const getAllTheCompanies = async (status) =>
  await apiProvider.getAll(DEPOTS);

export const getActiveDepots = async () =>
  await apiProvider.getAll(ACTIVE_DEPOTS);

export const getMonthlyAnalytics = async (payload = {}) =>
  await apiProvider.post(PERIODICAL_ANALYTICS, payload);

export const getWeeklyAnalytics = async (payload = {}) =>
  await apiProvider.post(PERIODICAL_ANALYTICS, payload);

export const getChargingMode = async (payload = {}) =>
  await apiProvider.post(CHARGING_MODE, payload);

export const getPortCountEachDay = async (payload = {}) =>
  await apiProvider.post(PORT_SUMMARY, payload);

export const getAnalyticsInsights = async (payload = {}) =>
  await apiProvider.post(INSIGHTS, payload);

export const chargersTypeCountApiCall = async (payload = {}) =>
  await apiProvider.post(CHARGER_TYPE_COUNT, payload);

export const getDashboardData = async () =>
  await apiProvider.getAll(ANALYTICS_DASHBOARD);

export const getBillablePorts = async (payload = {}) =>
  await apiProvider.post(BILLABLE_PORTS, payload);

export const getBillableCustomersCount = async (payload = {}) =>
  await apiProvider.post(BILLABLE_CUSTOMERS_COUNT, payload);

export const getMapData = async () => await apiProvider.getAll(GET_MAP_DEPOTS);

export const getFDCUserAlertSubsciptionMetrics = async (payload = {}) =>
  await apiProvider.post(FDC_USER_ALERT_METRICS, payload);

export default {
  getFaultsCount,
  getDepotFaults,
  getTotalCount,
  getMonthlyAnalytics,
  getWeeklyAnalytics,
  getChargingMode,
  getAllCompanies,
  chargersTypeCountApiCall,
  getDashboardData,
  getBillablePorts,
  getBillableCustomersCount,
  getMapData,
  getFDCUserAlertSubsciptionMetrics,
};
