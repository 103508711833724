import { Grid } from "@mui/material";
import { Box } from "@mui/system";
import React, { useMemo, useState, useCallback } from "react";
import Controls from "../../../components/controls/Controls";
import Loader from "../../../components/Loader";
import { Form, UseForm } from "../../../components/UseForm";
import {
  apiProvider,
  indexedEndPoints,
} from "../../../services/api/utilities/provider";
import { validateNonEmptyFields } from "../../../utils/FieldValidations";

export default function AddEditVinVidMapping({
  type,
  openPopup,
  setOpenPopup,
  setRefreshVinVidMappings,
  setToast,
}) {
  const { ADD_VIN_VID_MAPPING, VIN_VID } = indexedEndPoints;
  const [loading, setLoading] = useState(false);
  const addEditInitialValues = useMemo(
    () => ({
      vin: type === "updateVinVidMapping" ? openPopup?.item?.vin : "",
      obccMacAddress:
        type === "updateVinVidMapping" ? openPopup?.item?.obccMacAddress : "",
      modelYear:
        type === "updateVinVidMapping" ? openPopup?.item?.modelYear : "",
      obccESN: type === "updateVinVidMapping" ? openPopup?.item?.obccESN : "",
      programCode:
        type === "updateVinVidMapping" ? openPopup?.item?.programCode : "",
      region: type === "updateVinVidMapping" ? openPopup?.item?.region : "",
    }),
    [openPopup]
  );

  const { values, setValues, errors, setErrors, handleFormInputChange } =
    UseForm(addEditInitialValues);

  const validateMacAddress = (mac) => {
    const re = /^([0-9A-Fa-f]{2}[:]){5}([0-9A-Fa-f]{2})$/;
    return re.test(mac);
  };

  const handleSubmit = useCallback(
    async (e) => {
      e.preventDefault();
      let fields = Object.keys(addEditInitialValues);
      const validData = validateNonEmptyFields(fields, values, setErrors);
      const validMac = validateMacAddress(values?.obccMacAddress);
      if (!validMac)
        setErrors({
          ...errors,
          obccMacAddress:
            "Please enter valid hex values in the format XX:XX:XX:XX:XX:XX",
        });
      if (validData && validMac) setErrors({});
      else return;

      const payload = {
        vin: values.vin?.trim(),
        obccMacAddress: values.obccMacAddress?.trim(),
        modelYear: values.modelYear?.trim(),
        obccESN: values.obccESN?.trim(),
        programCode: values.programCode?.trim(),
        region: values.region?.trim(),
      };
      setLoading(true);
      if (type === "addVinVidMapping") {
        const response = await apiProvider.post(ADD_VIN_VID_MAPPING, [payload]);
        if (response.statusCode >= 200 && response.statusCode <= 299) {
          setToast({
            isOpen: true,
            message: "VIN-VID Mapping added successfully",
            type: "success",
          });
          setOpenPopup((op) => ({ ...op, isOpen: false }));
          setRefreshVinVidMappings(true);
        } else {
          setToast({
            isOpen: true,
            message: response?.data?.data,
            type: "error",
          });
        }
      } else {
        const response = await apiProvider.put(
          `${VIN_VID}/${payload.vin}`,
          payload
        );
        if (response.statusCode >= 200 && response.statusCode <= 299) {
          setToast({
            isOpen: true,
            message: "VIN-VID Mapping updated successfully",
            type: "success",
          });
          setOpenPopup((op) => ({ ...op, isOpen: false }));
          setRefreshVinVidMappings(true);
        } else {
          setToast({
            isOpen: true,
            message: response?.data?.data,
            type: "error",
          });
        }
      }
      setLoading(false);
    },
    [values]
  );

  return (
    <Form onSubmit={handleSubmit}>
      <Loader isLoading={loading} />
      <Grid container>
        <Grid item xs={6}>
          <Controls.Input
            disabled={type === "updateVinVidMapping"}
            name="vin"
            label="VIN"
            value={values.vin}
            error={errors.vin}
            onChange={handleFormInputChange()}
          />
        </Grid>
        <Grid item xs={6}>
          <Controls.Input
            name="obccMacAddress"
            label="OBCC MAC Address"
            value={values.obccMacAddress}
            error={errors.obccMacAddress}
            onChange={handleFormInputChange()}
          />
        </Grid>
        <Grid item xs={6}>
          <Controls.Input
            type="number"
            name="modelYear"
            label="Model Year"
            value={values.modelYear}
            error={errors.modelYear}
            onChange={handleFormInputChange()}
          />
        </Grid>
        <Grid item xs={6}>
          <Controls.Input
            name="obccESN"
            label="OBCC ESN"
            value={values.obccESN}
            error={errors.obccESN}
            onChange={handleFormInputChange()}
          />
        </Grid>
        <Grid item xs={6}>
          <Controls.Input
            name="programCode"
            label="Program Code"
            value={values.programCode}
            error={errors.programCode}
            onChange={handleFormInputChange()}
          />
        </Grid>
        <Grid item xs={6}>
          <Controls.Input
            name="region"
            label="Region"
            value={values.region}
            error={errors.region}
            onChange={handleFormInputChange()}
          />
        </Grid>
        <Grid item xs={12}>
          <Box display="flex" justifyContent="flex-end">
            <Controls.Button
              type="submit"
              data-testid={
                type === "addVinVidMapping"
                  ? "addVinVidSubmitBtn"
                  : "updateVinVidSubmitBtn"
              }
              text={
                type === "addVinVidMapping"
                  ? "Add VIN-VID Mapping"
                  : "Update VIN-VID Mapping"
              }
            />
          </Box>
        </Grid>
      </Grid>
    </Form>
  );
}
