import React from "react";
import { CSVLink } from "react-csv";
import GetAppOutlinedIcon from "@mui/icons-material/GetAppOutlined";
import LinearStepper from "../../components/LinearStepper";

export default function BulkUploadSteps({ headers, filename }) {
  const stepsLabel = [
    <p className="centerAligned">
      Download this CSV template file
      <CSVLink
        filename={filename}
        headers={headers}
        data={[]}
        className="downloadTemplateLink"
      >
        <GetAppOutlinedIcon fontSize="small" />
      </CSVLink>
    </p>,
    <p>Open the template in a spreadsheet program such as Excel</p>,
    <p>Follow the template and add records</p>,
    <p>Upload the CSV below</p>,
    <p>Preview uploaded data and confirm or re-upload file</p>,
  ];

  return <LinearStepper stepsLabel={stepsLabel} />;
}
