export const chdFilterReducer = (
  state = {
    depotId: [],
    model: [],
    powerType: [],
    depotType: ["CUSTOMER", "PILOT", "DEALER"],
    make: [],
    countryCode: [],
  },
  action
) => {
  switch (action.type) {
    case "CHD_FILTER":
      return { ...action.payload };
    default:
      return state;
  }
};

export const chdFilterListReducer = (
  state = { connectivity: {}, faults: {} },
  action
) => {
  switch (action.type) {
    case "CHD_FILTER_LIST":
      return { ...state, [action["name"]]: { ...action.payload } };
    default:
      return state;
  }
};

export const allAccountsAndDepots = (state = { allAccounts: [] }, action) => {
  switch (action.type) {
    case "CHD_FILTER_ALL_ACCOUNTS_DEPOTS":
      return { allAccounts: action.payload };
    default:
      return state;
  }
};

export const allMakeAndModel = (state = { allMakeModel: [] }, action) => {
  switch (action.type) {
    case "CHD_FILTER_ALL_MAKE_MODELS":
      return { allMakeModel: action.payload };
    default:
      return state;
  }
};

export const allPowerType = (state = { allPowerType: [] }, action) => {
  switch (action.type) {
    case "CHD_FILTER_ALL_POWER_TYPES":
      return { allPowerType: action.payload };
    default:
      return state;
  }
};

export const allCountryCode = (state = { allCountryCode: [] }, action) => {
  switch (action.type) {
    case "CHD_FILTER_ALL_COUNTRY_CODE":
      return { allCountryCode: action.payload };
    default:
      return state;
  }
};

export const allDepotType = (state = { allDepotType: [] }, action) => {
  switch (action.type) {
    case "CHD_FILTER_ALL_DEPOT_TYPE":
      return { allDepotType: action.payload };
    default:
      return state;
  }
};

export default {
  chdFilterReducer,
  chdFilterListReducer,
  allAccountsAndDepots,
  allMakeAndModel,
  allDepotType,
};
