import React from "react";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiFormControl-root": {
      margin: theme.spacing(1),
      width: "95%",
    },
  },
}));

const UseForm = (initialFieldValues, validateOnChange = false, validate) => {
  const [values, setValues] = React.useState(initialFieldValues);
  const [errors, setErrors] = React.useState({});
  const handleFormInputChange =
    (dependencies = null) =>
    (e) => {
      const { name, value } = e.target;
      if (dependencies) {
        if (name === dependencies.dependent) {
          setValues({
            ...values,
            [name]: value,
            [dependencies["resetField"]]: null,
          });
        } else {
          setValues({ ...values, [name]: value });
        }
      } else {
        setValues({ ...values, [name]: value });
      }
      if (validateOnChange) validate({ [name]: value });
    };

  const resetForm = () => {
    setValues(initialFieldValues);
    setErrors({});
  };

  return {
    values,
    setValues,
    handleFormInputChange,
    errors,
    setErrors,
    resetForm,
  };
};

const Form = (props) => {
  const classes = useStyles();
  const { children, ...other } = props;
  return (
    <form
      className={classes.root}
      autoComplete="off"
      style={props.extraStyles}
      {...other}
    >
      {children}
    </form>
  );
};

export { UseForm, Form };
