import {
  apiProvider,
  indexedEndPoints,
} from "../../services/api/utilities/provider";

const { VEHICLES_DISCOVERY_METRICS } = indexedEndPoints;

export const getVehiclesDiscovery = async (payload) =>
  await apiProvider.post(VEHICLES_DISCOVERY_METRICS, payload);

export default {
  getVehiclesDiscovery,
};
