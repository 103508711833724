import React from "react";
import apiBilling from "../../services/api/Billing";
import { messages } from "../../constants/Messages";

import { UseForm, Form } from "../../components/UseForm";
import Controls from "../../components/controls/Controls";

import { Grid } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { NewCountryCode } from "../request/Countries";

const useStyles = makeStyles((theme) => ({
  typography: {
    marginTop: theme.spacing(1),
  },
  buttonAlign: {
    marginTop: theme.spacing(1),
    marginLeft: theme.spacing(0.5),
  },
}));

const initialFieldValues = {
  productRatePlanName: null,
  productSku: null,
};

const UpdateCustomerBillingPlan = (props) => {
  const classes = useStyles();
  const [RatePlans, setRatePlans] = React.useState([]);
  const {
    setLoading,
    setToast,
    setOpenPopup,
    openPopup,
    refreshTable,
    AllRatePlans,
    companyInfo,
  } = props;

  const validate = (fieldValues = values) => {
    let temp = { ...errors };
    if (
      "productRatePlanName" in fieldValues &&
      fieldValues.productSku !== "Non Billable"
    )
      temp.productRatePlanName = fieldValues.productRatePlanName
        ? ""
        : messages.FIELD_REQUIRED;
    if ("productSku" in fieldValues)
      temp.productSku = fieldValues.productSku ? "" : messages.FIELD_REQUIRED;

    setErrors({ ...temp });

    if (fieldValues === values)
      return Object.values(temp).every((x) => x === "");
    else return false;
  };

  const [productRatePlanListSKUs, setProductRatePlanListSKUs] = React.useState(
    []
  );
  const { values, setValues, errors, setErrors, handleFormInputChange } =
    UseForm(initialFieldValues, true, validate);

  React.useEffect(() => {
    // setValues({
    //   productChargeId: openPopup.data.productRatePlanChargeName
    //     ? openPopup.data.productRatePlanChargeName
    //     : null,
    //   productSku: openPopup.data.productSku ? openPopup.data.productSku : null
    // });
  }, []);

  React.useEffect(() => {
    if (companyInfo.countryCode) {
      let countryCodeToSearch = companyInfo.countryCode;
      const euroCountrys = [
        "POL",
        "AUT",
        "IRL",
        "ESP",
        "LUX",
        "NLD",
        "NOR",
        "DNK",
        "BEL",
        "DEU",
        "FRA",
        "ITA",
      ];
      if (euroCountrys.includes(countryCodeToSearch)) {
        countryCodeToSearch = "GBR";
      }
      if (countryCodeToSearch === undefined) {
        countryCodeToSearch = "USA";
      }
      setRatePlans(
        AllRatePlans.filter((x) => x.countryCode === countryCodeToSearch)
      );
    }
  }, []);

  React.useEffect(() => {
    let originalOptions = RatePlans.reduce((acc, item) => {
      if (item.countryCode === "USA" || item.countryCode === "CAN") {
        item.sku == "SKU-00000296" &&
          !acc.includes(item.sku) &&
          acc.push(item.sku);
        return acc;
      } else if (item.countryCode === "GBR") {
        (item.sku == "SKU-P0000510" || item.sku == "SKU-P0000506") &&
          !acc.includes(item.sku) &&
          acc.push(item.sku);
        return acc;
      }
    }, []);
    // Add non billable
    setProductRatePlanListSKUs(originalOptions);
  }, [RatePlans]);

  const handleSubmit = (e) => {
    if (validate()) {
      const payLoad = {
        ratePlans: [
          { sku: values.productSku, ratePlanName: values.productRatePlanName },
        ],
      };

      setLoading(true);
      setOpenPopup((prevState) => ({ ...prevState, isOpen: false }));
      apiBilling
        .updateCustomerRatePlan(companyInfo.companyId, payLoad)
        .then((res) => {
          if (res.statusCode === 201 || res.statusCode === 200) {
            refreshTable();
            setLoading(false);
            setToast({
              isOpen: true,
              message: "Customer rate plan updated successfully",
              type: "success",
            });
          } else {
            setToast({
              isOpen: true,
              message: "Customer rate plan update failed",
              type: "error",
            });
          }
        })
        .catch((error) => console.log(error));
    }
  };

  return (
    <div>
      <Grid container>
        <Grid container spacing={1}>
          <Grid item xs={6}>
            <Controls.AutoSuggestion
              label="Product SKU"
              value={values.productSku}
              name="productSku"
              onChange={handleFormInputChange({
                dependent: "productSku",
                resetField: "productRatePlanName",
              })}
              options={productRatePlanListSKUs}
              error={errors.productSku}
            />
          </Grid>
          <Grid item xs={6}>
            <Controls.AutoSuggestion
              label="Product Charge ID"
              value={values.productRatePlanName}
              disable={!values.productSku}
              name="productRatePlanName"
              onChange={handleFormInputChange({
                dependent: "productSku",
                resetField: "productRatePlanName",
              })}
              options={RatePlans.filter(
                (ele) => ele.sku === values.productSku
              ).map((item) => item.productRatePlanChargeName)}
              error={errors.productRatePlanName}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid container justifyContent="flex-end">
        <div className={classes.buttonAlign}>
          <Controls.Button
            type="button"
            text="Update"
            onClick={() => handleSubmit()}
          />
        </div>
      </Grid>
    </div>
  );
};

export default UpdateCustomerBillingPlan;
