import React, { useCallback, useMemo, useState } from "react";
import { Box, Grid } from "@mui/material";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import Controls from "../../../components/controls/Controls";
import { Form, UseForm } from "../../../components/UseForm";
import {
  apiProvider,
  indexedEndPoints,
} from "../../../services/api/utilities/provider";
import Loader from "../../../components/Loader";
import { validateNonEmptyFields } from "../../../utils/FieldValidations";
import { useLocation } from "react-router-dom";
import { Autocomplete, TextField, Button } from "@mui/material";

const AddEditVehicles = ({
  type,
  openPopup,
  setOpenPopup,
  setRefreshVehicles,
  setToast,
  vehicleId,
}) => {
  const { VEHICLES } = indexedEndPoints;
  const [loading, setLoading] = useState(false);

  const search = useLocation().search;
  const searchParams = new URLSearchParams(search);
  const manufacturer = searchParams.get("manufacturer");

  const addEditInitialValues = useMemo(
    () => ({
      manufacturer:
        type === "updateVehicle" ? openPopup?.item?.manufacturer : manufacturer,
      model: type === "updateVehicle" ? openPopup?.item?.model : "",
      batteryCapacity:
        type === "updateVehicle" ? openPopup?.item?.batteryCapacity : "",
      efficiency: type === "updateVehicle" ? openPopup?.item?.efficiency : "",
    }),
    [openPopup]
  );

  const validateNumberFields = (field) => {
    for (let [key, value] of Object.entries(field)) {
      if (value < 0) setValues({ ...values, [key]: Math.abs(value) });
    }
  };

  const { values, setValues, errors, setErrors, handleFormInputChange } =
    UseForm(addEditInitialValues, true, validateNumberFields);

  const handleSubmit = useCallback(
    async (e) => {
      e.preventDefault();
      const fields = Object.keys(addEditInitialValues);
      const validData = validateNonEmptyFields(fields, values, setErrors);
      if (validData) setErrors({});
      else return;

      const payload = {
        manufacturer: manufacturer,
        model: values.model?.trim(),
        networkType: values.networkType?.trim(),
        batteryCapacity: values.batteryCapacity?.trim(),
        efficiency: values.efficiency?.trim(),
      };
      setLoading(true);
      if (type === "addVehicle") {
        const response = await apiProvider.post(VEHICLES, [payload]);
        if (response.statusCode >= 200 && response.statusCode <= 299) {
          setToast({
            isOpen: true,
            message: "Vehicles added successfully",
            type: "success",
          });
          setOpenPopup((op) => ({ ...op, isOpen: false }));
          setRefreshVehicles(true);
        } else {
          setToast({
            isOpen: true,
            message: response?.data?.data,
            type: "error",
          });
        }
      } else {
        const response = await apiProvider.put(
          `${VEHICLES}/${vehicleId}`,
          payload
        );
        if (response.statusCode >= 200 && response.statusCode <= 299) {
          setToast({
            isOpen: true,
            message: "Vehicle updated successfully",
            type: "success",
          });
          setOpenPopup((op) => ({ ...op, isOpen: false }));
          setRefreshVehicles(true);
        } else {
          setToast({
            isOpen: true,
            message: response?.data?.data,
            type: "error",
          });
        }
      }
      setLoading(false);
    },
    [values]
  );

  return (
    <Form onSubmit={handleSubmit}>
      <Loader isLoading={loading} />
      <Grid container>
        <Grid item xs={6}>
          <Controls.Input
            disabled
            name="manufacturer"
            label="Manufacturer"
            value={values.manufacturer}
          />
        </Grid>
        <Grid item xs={6}>
          <Controls.Input
            name="model"
            label="Model"
            value={values.model}
            error={errors.model}
            onChange={handleFormInputChange()}
          />
        </Grid>
        <Grid item xs={6}>
          <Controls.Input
            type="number"
            name="batteryCapacity"
            label="Battery Capacity"
            value={values.batteryCapacity}
            onChange={handleFormInputChange()}
            error={errors.batteryCapacity}
          />
        </Grid>
        <Grid item xs={6}>
          <Controls.Input
            type="number"
            name="efficiency"
            label="Efficiency"
            value={values.efficiency}
            onChange={handleFormInputChange()}
            error={errors.efficiency}
          />
        </Grid>
        <Grid item xs={12}>
          <Box display="flex" justifyContent="flex-end">
            <Controls.Button
              type="submit"
              data-testid={
                type === "addVehicle"
                  ? "addVehicleSubmitBtn"
                  : "updateVehicleSubmitBtn"
              }
              text={type === "addVehicle" ? "Add Vehicle" : "Update Vehicle"}
            />
          </Box>
        </Grid>
      </Grid>
    </Form>
  );
};

export default AddEditVehicles;
