import React from "react";
import { Paper } from "@mui/material";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  pageContent: {
    margin: theme.spacing(2),
    padding: "0px 16px",
    minHeight: "calc(100vh - 170px)",
  },
}));

const PageMainContent = (props) => {
  const classes = useStyles();
  return (
    <Paper className={classes.pageContent} style={{ ...props.extraStyles }}>
      {props.children}
    </Paper>
  );
};

export default PageMainContent;
