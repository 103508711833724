import React, { useMemo, useState, useCallback, useEffect } from "react";
import { Autocomplete, Chip, Grid, TextField } from "@mui/material";
import { Box } from "@mui/system";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import Controls from "../../../components/controls/Controls";
import Loader from "../../../components/Loader";
import { Form, UseForm } from "../../../components/UseForm";
import {
  apiProvider,
  indexedEndPoints,
} from "../../../services/api/utilities/provider";
import { validateNonEmptyFields } from "../../../utils/FieldValidations";
import { CSVLink } from "react-csv";
import GetAppOutlinedIcon from "@mui/icons-material/GetAppOutlined";
import UploadAndPreviewCSV from "../../../components/UploadAndPreviewCSV";
import { makeStyles } from "@mui/styles";

export let setTestTableData; // used for unit testing only

export default function AddEditProvider({
  type,
  openPopup,
  setOpenPopup,
  setRefreshProviders,
  setToast,
  providerMetaId,
  regions,
}) {
  const { UTILITY_PROVIDERS } = indexedEndPoints;
  const [loading, setLoading] = useState(false);
  const addEditInitialValues = useMemo(
    () => ({
      name: type === "updateProvider" ? openPopup?.item?.providerName : "",
      region: type === "updateProvider" ? openPopup?.item?.region : [],
      address: type === "updateProvider" ? openPopup?.item?.address?.city : "",
    }),
    [openPopup]
  );

  const { values, setValues, errors, setErrors, handleFormInputChange } =
    UseForm(addEditInitialValues);

  const handleSubmit = useCallback(
    async (e) => {
      e.preventDefault();
      let fields = Object.keys(addEditInitialValues);
      const validData = validateNonEmptyFields(fields, values, setErrors);
      if (validData) setErrors({});
      else return;

      const payload = {
        name: values.name?.trim(),
        region: values.region?.join(","),
        address: { city: values.address?.trim() },
      };
      setLoading(true);
      if (type === "addProvider") {
        const response = await apiProvider.post(UTILITY_PROVIDERS, [payload]);
        if (response.statusCode >= 200 && response.statusCode <= 299) {
          setToast({
            isOpen: true,
            message: "Utility Provider added successfully",
            type: "success",
          });
          setOpenPopup((op) => ({ ...op, isOpen: false }));
          setRefreshProviders(true);
        } else {
          setToast({
            isOpen: true,
            message: response?.data?.data || "Utility Provider addition failed",
            type: "error",
          });
        }
      } else {
        const response = await apiProvider.put(
          `${UTILITY_PROVIDERS}/${providerMetaId}`,
          payload
        );
        if (response.statusCode >= 200 && response.statusCode <= 299) {
          setToast({
            isOpen: true,
            message: "Utility Provider updated successfully",
            type: "success",
          });
          setOpenPopup((op) => ({ ...op, isOpen: false }));
          setRefreshProviders(true);
        } else {
          setToast({
            isOpen: true,
            message: response?.data?.data || "Utility Provider updation failed",
            type: "error",
          });
        }
      }
      setLoading(false);
    },
    [values]
  );

  return (
    <Form onSubmit={handleSubmit}>
      <Loader isLoading={loading} />
      <Grid container>
        <Grid item xs={12}>
          <Controls.Input
            name="name"
            label="Name"
            value={values.name}
            error={errors.name}
            onChange={handleFormInputChange()}
          />
        </Grid>
        <Grid item xs={12}>
          <Autocomplete
            size="small"
            multiple
            value={values.region}
            options={regions}
            onChange={(_, value) => setValues({ ...values, region: value })}
            renderInput={(params) => (
              <TextField
                {...params}
                variant="outlined"
                label="Regions"
                placeholder="Regions"
                error={errors.region}
                helperText={errors.region}
              />
            )}
          />
        </Grid>
        <Grid item xs={12}>
          <Controls.Input
            name="address"
            label="Address"
            value={values.address}
            error={errors.address}
            onChange={handleFormInputChange()}
          />
        </Grid>
        <Grid item xs={12}>
          <Box display="flex" justifyContent="flex-end">
            <Controls.Button
              type="submit"
              data-testid={
                type === "addProvider"
                  ? "addUtilityProviderSubmitBtn"
                  : "updateUtilityProviderSubmitBtn"
              }
              text={type === "addProvider" ? "Add Provider" : "Update Provider"}
            />
          </Box>
        </Grid>
      </Grid>
    </Form>
  );
}

const headers = [
  { id: "startDate", key: "startDate", label: "startDate" },
  { id: "startTime", key: "startTime", label: "startTime" },
  { id: "endDate", key: "endDate", label: "endDate" },
  { id: "endTime", key: "endTime", label: "endTime" },
  { id: "price", key: "price", label: "price" },
  { id: "demandCharges", key: "demandCharges", label: "demandCharges" },
];

const useStyles = makeStyles(() => ({
  utilityPlanZipcode: {
    // to hide step arrows in plan zipcode of type number
    "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button": {
      display: "none",
    },
    "& input[type=number]": {
      MozAppearance: "textfield",
    },
  },
}));

export function AddEditPlan({
  type,
  openPopup,
  setOpenPopup,
  setRefreshPlans,
  setToast,
  providerName,
  providerMetaId,
  planMetaId,
}) {
  const classes = useStyles();
  const { UTILITY_PLANS } = indexedEndPoints;
  const [loading, setLoading] = useState(false);
  const addEditInitialValues = useMemo(
    () => ({
      planName: type === "updatePlan" ? openPopup?.item?.planName : "",
      zipcode: type === "updatePlan" ? openPopup?.item?.zipcode : [],
      expiryDate:
        type === "updatePlan"
          ? openPopup?.item?.expiryDate?.split(" ")?.slice(0, 4)?.join(" ")
          : "",
    }),
    [openPopup]
  );

  const { values, setValues, errors, setErrors, handleFormInputChange } =
    UseForm(addEditInitialValues);

  const {
    tableData,
    setTableData,
    setErrors: setFileErrors,
    UploadPreviewCSV,
  } = UploadAndPreviewCSV();
  setTestTableData = setTableData; // used for unit testing only

  const handleZipcodeChange = (_, value) => {
    // Validate zip code format (alphanumeric with a certain length, you can adjust as needed)
    const isValid = value.every((zip) =>
      /^[a-zA-Z0-9]{3}(\s?[a-zA-Z0-9]{2,7})?$/.test(zip)
    );
    if (!isValid) {
      setErrors({ ...errors, zipcode: "Invalid zip code format" });
    } else {
      setValues({ ...values, zipcode: value });
      setErrors({ ...errors, zipcode: "" });
    }
  };

  const handleSubmit = useCallback(
    async (e) => {
      e.preventDefault();
      let fields = Object.keys(addEditInitialValues);
      const validData = validateNonEmptyFields(fields, values, setErrors);
      if (validData) {
        if (type === "updatePlan" || (type === "addPlan" && tableData?.length))
          setErrors({});
        else {
          setFileErrors("Please upload a CSV file");
          return;
        }
      } else return;
      const payload = {
        planName: values.planName?.trim(),
        zipcode: values.zipcode?.join(","),
        expiryDate: new Date(values.expiryDate).toLocaleString().split(",")[0],
        planRates: tableData,
      };
      setLoading(true);
      if (type === "addPlan") {
        const response = await apiProvider.post(
          `${UTILITY_PLANS}/${providerMetaId}`,
          [payload]
        );
        if (response.statusCode >= 200 && response.statusCode <= 299) {
          setToast({
            isOpen: true,
            message: "Utility Plan and rates added successfully",
            type: "success",
          });
          setOpenPopup((op) => ({ ...op, isOpen: false }));
          setRefreshPlans(true);
        } else {
          setToast({
            isOpen: true,
            message:
              response?.data?.data || "Utility Plan and rates addition failed",
            type: "error",
          });
        }
      } else {
        const response = await apiProvider.put(
          `${UTILITY_PLANS}/${planMetaId}`,
          payload
        );
        if (response.statusCode >= 200 && response.statusCode <= 299) {
          setToast({
            isOpen: true,
            message: "Utility Plan and rates updated successfully",
            type: "success",
          });
          setOpenPopup((op) => ({ ...op, isOpen: false }));
          setRefreshPlans(true);
        } else {
          setToast({
            isOpen: true,
            message:
              response?.data?.data || "Utility Plan and rates updation failed",
            type: "error",
          });
        }
      }
      setLoading(false);
    },
    [values, tableData]
  );

  return (
    <Form onSubmit={handleSubmit}>
      <Loader isLoading={loading} />
      <Grid container>
        <Grid item xs={6}>
          <Controls.Input
            disabled
            name="providerName"
            label="Provider Name"
            value={providerName}
          />
        </Grid>
        <Grid item xs={6}>
          <Controls.Input
            name="planName"
            label="Plan Name"
            value={values.planName}
            error={errors.planName}
            onChange={handleFormInputChange()}
          />
        </Grid>
        <Grid item xs={6}>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DatePicker
              label="Expiry Date"
              inputFormat="dd/MM/yyyy"
              value={values.expiryDate}
              disablePast={true}
              onChange={(newValue) =>
                setValues({
                  ...values,
                  expiryDate: newValue,
                })
              }
              renderInput={(params) => (
                <TextField
                  {...params}
                  size="small"
                  inputProps={{ ...params.inputProps, readOnly: true }}
                  error={errors.expiryDate}
                  {...(errors.expiryDate && {
                    helperText: "This field is required",
                  })}
                />
              )}
            />
          </LocalizationProvider>
        </Grid>
        <Grid item xs={6} className={classes.utilityPlanZipcode}>
          <Autocomplete
            size="small"
            multiple
            freeSolo
            value={values.zipcode}
            options={[]}
            onChange={handleZipcodeChange}
            renderTags={(value, getTagProps) =>
              value.map((option, index) => (
                <Chip
                  variant="outlined"
                  label={option}
                  {...getTagProps({ index })}
                />
              ))
            }
            renderInput={(params) => (
              <TextField
                {...params}
                variant="outlined"
                label="Zipcode"
                placeholder="Input Zipcode and Press Enter"
                error={errors.zipcode}
                helperText={errors.zipcode}
              />
            )}
          />
        </Grid>
        <Grid item xs={12}>
          <p className="utilityRatesTemplate">
            Download this CSV template file
            <CSVLink
              filename={"UtilityRates.csv"}
              headers={headers}
              data={[
                {
                  startDate: "dd/mm/yyyy",
                  startTime: "hh",
                  endDate: "dd/mm/yyyy",
                  endTime: "hh",
                  price: "number",
                  demandCharges: "number",
                },
              ]}
              className="downloadTemplateLink"
            >
              <GetAppOutlinedIcon fontSize="small" />
            </CSVLink>
          </p>
        </Grid>
        <Grid item xs={12} marginLeft={"10px"}>
          <UploadPreviewCSV headers={headers?.map((item) => item.key)} />
        </Grid>
        <Grid item xs={12}>
          <Box display="flex" justifyContent="flex-end">
            <Controls.Button
              type="submit"
              data-testid={
                type === "addPlan"
                  ? "addUtilityPlanSubmitBtn"
                  : "updateUtilityPlanSubmitBtn"
              }
              text={type === "addPlan" ? "Add Plan" : "Update Plan"}
            />
          </Box>
        </Grid>
      </Grid>
    </Form>
  );
}
