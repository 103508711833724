const environment = {
  LOCAL: {
    BACK_END_URL: "http://127.0.0.1:5000",
    FFM_URL: "https://api.stg01e.gcp.ford.com/",
    CHD_URL: "https://microservices.electriphi.dev/dev-test/",
    PLACE_DETAILS_MICROSERVICE:
      "https://placedetails.electriphidevelopment.com/get-place-details",
    FDC_URL: "https://qa.electriphi.dev/",
    REGION: {
      EUROPE: "C449085D-3008-470A-B161-D7917E92DEE8",
      USA: "8AF2B95A-3D3E-42D4-91B8-FCC1617C1E57",
      CANADA: "8AF2B95A-3D3E-42D4-91B8-FCC1617C1E57",
    },
    DEPOT_TYPE: "TEST",
    SIMULATEDDEPOT: true,
    PRE_PROVISIONING_DEPOTS: [
      {
        depotName: "FDC QA depot 1",
        depotId: "91ae5f46-4270-4a30-8372-5261254a9089",
      },
      {
        depotName: "Central Server Testing Depot",
        depotId: "b530125a-56e2-4d93-a43e-35164a0e53e5",
      },
    ],
    ENABLE_FDC_AUTH_BYPASS: false,
    MASTER_DATA_DOMAIN: "https://api.us1.electriphi.dev/master-data",
    MASTER_DATA_XAPI_KEY:
      "2tL5EAK5w6NuNzcNMYvgVZkdNKFT812J4O1oVDAaVBjpb42ocZ8D351BCIooXkz4",
  },
  DEV: {
    // BACK_END_URL: "http://127.0.0.1:5000",
    BACK_END_URL:
      "https://api.us1.electriphi.dev/ops-console-cllm-monitor-test-instance",
    // BACK_END_URL: "https://api.us1.electriphi.dev/ops-console/",
    FFM_URL: "https://fleetaccount-stage.ford.com/",
    CHD_URL: "https://microservices.electriphi.dev/staging/",
    PLACE_DETAILS_MICROSERVICE:
      "https://placedetails.electriphidevelopment.com/get-place-details",
    FDC_URL: "https://qa.electriphi.dev/",
    REGION: {
      EUROPE: "9ce345c4-7d75-4ae5-8db2-8d8648e774b0",
      USA: "a21ad654-5160-4723-b3b9-6058656703cf",
      CANADA: "a21ad654-5160-4723-b3b9-6058656703cf",
    },
    DEPOT_TYPE: "TEST",
    SIMULATEDDEPOT: true,
    PRE_PROVISIONING_DEPOTS: [
      {
        depotName: "FDC QA Depot-1",
        depotId: "91ae5f46-4270-4a30-8372-5261254a9089",
      },
      {
        depotName: "Central Server Testing Depot",
        depotId: "b530125a-56e2-4d93-a43e-35164a0e53e5",
      },
    ],
    ENABLE_FDC_AUTH_BYPASS: true,
    MASTER_DATA_DOMAIN: "https://api.us1.electriphi.dev/master-data",
    MASTER_DATA_XAPI_KEY:
      "2tL5EAK5w6NuNzcNMYvgVZkdNKFT812J4O1oVDAaVBjpb42ocZ8D351BCIooXkz4",
  },
  STAGE: {
    // BACK_END_URL: "http://127.0.0.1:5000/",
    BACK_END_URL: "https://api.us1.electriphi.dev/ops-console-stage/",
    FFM_URL: "https://fleetaccount-stage.ford.com/",
    CHD_URL: "https://microservices.electriphi.dev/staging/",
    PLACE_DETAILS_MICROSERVICE:
      "https://placedetails.electriphidevelopment.com/get-place-details",
    FDC_URL: "https://stage.electriphi.dev/",
    REGION: {
      EUROPE: "9ce345c4-7d75-4ae5-8db2-8d8648e774b0",
      USA: "a21ad654-5160-4723-b3b9-6058656703cf",
      CANADA: "a21ad654-5160-4723-b3b9-6058656703cf",
    },
    DEPOT_TYPE: "TEST",
    SIMULATEDDEPOT: true,
    PRE_PROVISIONING_DEPOTS: [
      {
        depotName: "FDC QA Depot-1",
        depotId: "91ae5f46-4270-4a30-8372-5261254a9089",
      },
      {
        depotName: "Central Server Testing Depot",
        depotId: "b530125a-56e2-4d93-a43e-35164a0e53e5",
      },
    ],
    ENABLE_FDC_AUTH_BYPASS: false,
    MASTER_DATA_DOMAIN: "https://api.us1.electriphi.dev/master-data",
    MASTER_DATA_XAPI_KEY:
      "2tL5EAK5w6NuNzcNMYvgVZkdNKFT812J4O1oVDAaVBjpb42ocZ8D351BCIooXkz4",
  },

  PROD: {
    BACK_END_URL: "https://api.us1.services.electriphi.io/ops-console/",
    FFM_URL: "https://fleetaccount.ford.com/",
    CHD_URL: "https://microservices.electriphi.dev",
    PLACE_DETAILS_MICROSERVICE:
      "https://placedetails.electriphidevelopment.com/get-place-details",
    FDC_URL: "https://fordprocharging.electriphi.io/",

    REGION: {
      EUROPE: "FD35B0E0-1B8B-43D5-BB8B-3939EA5DE6BE",
      USA: "61CEA636-B930-41DB-BF1F-D5F0419767AD",
      CANADA: "61CEA636-B930-41DB-BF1F-D5F0419767AD",
    },
    DEPOT_TYPE: "CUSTOMER",
    SIMULATEDDEPOT: false,
    PRE_PROVISIONING_DEPOTS: [
      {
        depotName: "Provisioning - Ford Pro AC 19.5 kW chargers",
        depotId: "55b102f8-4176-43d6-837c-6ec47014987c",
      },
      {
        depotName: "Provisioning - Ford Pro DC Chargers",
        depotId: "31330d1a-5bfe-4ad7-9d81-f3ac0e5b1ca7",
      },
      {
        depotName: "Provisioning - Ford Pro AC 11.5 kW chargers",
        depotId: "c70b45ab-6411-483f-bce8-f4b1ecf756f0",
      },
    ],
    // In prod the ENABLE_FDC_AUTH_BYPASS should always be false
    ENABLE_FDC_AUTH_BYPASS: false,
    MASTER_DATA_DOMAIN: "https://api.us1.services.electriphi.io/master-data",
    MASTER_DATA_XAPI_KEY:
      "JtpVnxE1F4APXS1kVeeB23V5m4WOV2510wMzBxNzmixz8WNYV2uuAZBBtWW9N0kG",
  },
  CLLM: {
    BACK_END_URL:
      "https://api.us1.electriphi.dev/ops-console-cllm-monitor-test-instance/",
    FFM_URL: "https://fleetaccount.ford.com/",
    CHD_URL: "https://microservices.electriphi.dev",
    PLACE_DETAILS_MICROSERVICE:
      "https://placedetails.electriphidevelopment.com/get-place-details",
    FDC_URL: "https://fordprocharging.electriphi.io/",

    REGION: {
      EUROPE: "FD35B0E0-1B8B-43D5-BB8B-3939EA5DE6BE",
      USA: "61CEA636-B930-41DB-BF1F-D5F0419767AD",
      CANADA: "61CEA636-B930-41DB-BF1F-D5F0419767AD",
    },
    DEPOT_TYPE: "CUSTOMER",
    SIMULATEDDEPOT: false,
    PRE_PROVISIONING_DEPOTS: [
      {
        depotName: "Provisioning - Ford Pro AC 19.5 kW chargers",
        depotId: "55b102f8-4176-43d6-837c-6ec47014987c",
      },
      {
        depotName: "Provisioning - Ford Pro DC Chargers",
        depotId: "31330d1a-5bfe-4ad7-9d81-f3ac0e5b1ca7",
      },
      {
        depotName: "Provisioning - Ford Pro AC 11.5 kW chargers",
        depotId: "c70b45ab-6411-483f-bce8-f4b1ecf756f0",
      },
    ],
    // In prod the ENABLE_FDC_AUTH_BYPASS should always be false
    ENABLE_FDC_AUTH_BYPASS: false,
    MASTER_DATA_DOMAIN: "https://api.us1.services.electriphi.io/master-data",
    MASTER_DATA_XAPI_KEY:
      "JtpVnxE1F4APXS1kVeeB23V5m4WOV2510wMzBxNzmixz8WNYV2uuAZBBtWW9N0kG",
  },
};

export default environment;
