import React from "react";
import { Grid } from "@mui/material";
import Controls from "../../components/controls/Controls";

const BillingPackage = (props) => {
  const {
    values,
    handleFormInputChange,
    setValues,
    errors,
    isZuoraVisible,
    AllRatePlans,
  } = props;

  const [productRatePlanListOptions, setProductRatePlanListOptions] =
    React.useState(AllRatePlans);
  const [productRatePlanListSKUs, setProductRatePlanListSKUs] = React.useState(
    []
  );
  const [isBillable, setIsBillable] = React.useState(true);

  React.useEffect(() => {
    let originalOptions = AllRatePlans.reduce((acc, item) => {
      item.sku !== "SKU-00000296" &&
        !acc.includes(item.sku) &&
        acc.push(item.sku);
      return acc;
    }, []);
    // Add non billable
    originalOptions = [...originalOptions, "Non Billable"];

    setProductRatePlanListSKUs(originalOptions);
  }, [AllRatePlans]);

  React.useEffect(() => {
    setIsBillable(true);
    if (values.productSku && values.productSku !== "Non Billable") {
      setProductRatePlanListOptions(
        getUniqueListBy(
          AllRatePlans.filter(
            (item) =>
              item.sku === values.productSku && item.sku !== "SKU-00000296"
          ),
          "productRatePlanChargeName"
        )
      );
    } else if (values.productSku === "Non Billable") {
      setIsBillable(false);
    } else
      setProductRatePlanListOptions(
        getUniqueListBy(
          AllRatePlans.filter((item) => item.sku !== "SKU-00000296"),
          "productRatePlanChargeName"
        )
      );
  }, [values.productSku]);

  const getUniqueListBy = (arr, key) => {
    return [...new Map(arr.map((item) => [item[key], item])).values()];
  };

  return (
    <>
      <Grid container>
        <Grid item xs={6}>
          {isZuoraVisible && (
            <Controls.Input
              label="Zuora Customer ID"
              value={values.zuoraId || ""}
              name={"zuoraId"}
              onChange={handleFormInputChange()}
              error={errors.zuoraId}
              disabled={!values.isZuoraEditable}
            />
          )}
        </Grid>
      </Grid>
      <Grid container spacing={1}>
        <Grid item xs={6}>
          <Controls.AutoSuggestion
            label="Product SKU"
            value={values.productSku}
            name="productSku"
            onChange={handleFormInputChange({
              dependent: "productSku",
              resetField: "productChargeId",
            })}
            options={productRatePlanListSKUs}
            error={errors.productSku}
          />
        </Grid>
        {isBillable ? (
          <Grid item xs={6}>
            <Controls.AutoSuggestion
              label="Product Charge ID"
              value={values.productChargeId}
              disable={!values.productSku}
              name="productChargeId"
              onChange={handleFormInputChange({
                dependent: "productSku",
                resetField: "productChargeId",
              })}
              options={productRatePlanListOptions
                .filter((ele) => ele.sku === values.productSku)
                .map((item) => item.productRatePlanChargeName)}
              error={errors.productChargeId}
            />
          </Grid>
        ) : null}
      </Grid>
    </>
  );
};

export default BillingPackage;
